import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-featuring-requests',
  templateUrl: './featuring-requests.component.html',
  styleUrls: ['./featuring-requests.component.css']
})
export class FeaturingRequestsComponent implements OnInit {
  isVisible = false;
  radioValue = null;
  page=1;
  public pageSize = 5;
  public total = 0 ;
  selectedProd ;
  requests = [];
  listPacks = [];
  userProds = [];
  date :Date ;
  dayAv =true;
  weekAv =true;
  monthAv =true;
  dateAv:Date;
  baseUrl = environment.baseUrl + '/';
  constructor(private profileRouteService: ProfileRouteService,
    private toaster: ToastrService,private b2bService: B2bProductService
    ,private datePipe: DatePipe) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("featuringRequests");
    this.getCategories();
    this.myProductSearch();
    this.getAV1();
    this.getRequests();
  }
  myProductSearch() {
    let  data = {
      isFeatured:false
    }
    this.b2bService.myProductSearch(data).subscribe((resp: any) => {
      console.log("getteing all : " , resp);
      this.userProds = resp.content
      console.log("getteing all : " , this.userProds);
    })
  }
  cancelRequest(reqId) {
    this.b2bService.cancelFeaturedRequest(reqId).subscribe((resp: any) => {

      this.getRequests();
    })
  }

  getCategories(){
    let today = new Date();
    let data = {
      date : this.datePipe.transform(today, 'yyyy-MM-ddTHH:mm'),
    }
    this.b2bService.getPacksByUser(data).subscribe((resp: any) => {
      console.log("PACKS",resp);
      this.listPacks=resp
    })
  }
  handleOk(): void {
    this.isVisible = false;
    this.radioValue = null;
    this.selectedProd = null;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  showModal(): void {
    this.isVisible = true;
  }
  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: Date | Date[] | null): void {
    console.log('onOk', result);
  }
  getAV(slctDate){
    this.date = slctDate;
    if(this.date){
      let  data = {
        date : this.datePipe.transform(this.date, 'yyyy-MM-ddTHH:mm')
      }
      this.b2bService.getAvDate(data).subscribe((resp: any) => {
        this.dateAv = resp.date;
        this.dayAv = resp.day;
        this.weekAv = resp.week;
        this.monthAv = resp.month;
        console.log("Available",resp)


      })
    }
  }

  submitOffer(){
    if(this.date){
      let  data = {
        date : this.datePipe.transform(this.date, 'yyyy-MM-ddTHH:mm'),
        productId : this.selectedProd.id,
        offerId : this.radioValue,
      }
      this.b2bService.createFeaturedRequest(data).subscribe((resp: any) => {
        // this.date = resp.date;
        // this.dayAv = resp.day;
        // this.weekAv = resp.week;
        // this.monthAv = resp.month;
        console.log("Available",resp)
        this.handleOk();
        this.toaster.success("your Featuring Request Has Been Submitted", "Success", {
          closeButton: true,
          timeOut: 4000,
        });
        this.getRequests();
      })
    }
  }
  getAV1(){
      this.b2bService.getAvDate(null).subscribe((resp: any) => {
        this.date = resp.date;
        this.dayAv = resp.day;
        this.weekAv = resp.week;
        this.monthAv = resp.month;
        console.log("Available",resp)


      })
  }
  showCollapse (index) {
    this.requests[index].isShown = !this.requests[index].isShown
  }

  pageIndexChanged(page) {
    console.log("page" , page);
    this.getRequests()

  }

  addShownRequests() {
    this.requests.forEach(element => {
        element.isShown = false;
    })
  }

  getRequests(){
      let dataToSend = {
    page : this.page-1,
    size : this.pageSize,
    sortBy : "id",
    sort : "DESC",
  }
      this.b2bService.getAllMyFeatureRequest(dataToSend).subscribe((resp: any) => {
       this.requests =  resp.content;
       this.total =  resp.totalElements;


      })
  }
}
