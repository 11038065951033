import { Component, OnInit, Input } from '@angular/core';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
import { zh_CN } from 'ng-zorro-antd/i18n';
import {CountryISO, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';
import { FormGroup, FormControl,FormBuilder, Validators } from '@angular/forms';
import { FinanceOpportunitiesService } from 'src/app/commons/services/financing-opportunities/finance-opportunities.service';
import * as countriesJson from '../../../../../assets/js/countries.json';
import { SubscriptionService } from 'src/app/commons/services/subscription/subscription.service';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Console } from 'console';
registerLocaleData(zh);
@Component({
  selector: 'app-post-finance-request',
  templateUrl: './post-finance-request.component.html',
  styleUrls: ['./post-finance-request.component.css']
})
export class PostFinanceRequestComponent implements OnInit {
  natureBuissnes :any[]=[];
  form: FormGroup;
  submitted = false;
  uploading = false;
  introductionTitle
  introduction:any={}
  postFinanceRequest:any={}
  financesTypes: any[];
  typeOfInstitutions: any[];
  countries: any = (countriesJson as any).default;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  selectedISO = CountryISO.Morocco;
  TooltipLabel = TooltipLabel;
  separateDialCode = true;
  CountryISO = CountryISO;
  fileList: UploadFile[]=[] ;
  selectedCountry: any;
  idPost:any;
  disabled : boolean =false;
  checkDisaledRang : boolean=false;
  checkDisaledTotal : boolean=false;
  isCheckedButton :boolean=false;
  initFiananceAmount :number =0;
  initRangFinanceAmount :number=0;
  ActivRangMsg :boolean= false;
  constructor(  private financeOpportunitiesService:FinanceOpportunitiesService,
    private message :NzMessageService,private financeService:FinanceService,
    private fb: FormBuilder,  private subscriptionService:SubscriptionService
   ) {
    this.financeService = financeService;



   }
  saveIntroduction(value){
     this.introductionTitle  = value;
     this.updateIntroduction( this.introductionTitle)
  }

  ngOnInit() {
    this.getIntroduction()
    this.getNatureBuisness();
    this.initForm();
    this.getAllFinanceTypes();
    this.getAllTypeOfInstitution();

  }
  initForm(){
    this.selectedCountry = {
      label: "Morocco",
      value: "Morocco",
      id: "34",
      type_pays: "Country",
      isocode: "MAR",
      region: "Africa",
      flag: "https://restcountries.eu/data/mar.svg"
    }
    this.form = this.fb.group({
      /* country:[this.selectedCountry,[Validators.required]], */
        country:[this.selectedCountry,[Validators.required]],
        applicantRequesterName:[null,[Validators.required]],
        mansaId :[null,[Validators.required,this.confirmationMansaId]],
        contactName:[null,[Validators.required]],
        contactPhone:[null,[Validators.required]],
        contactEmail: [null, [Validators.required, Validators.email]],
        institutionType:[null,[Validators.required]],
        financeFacilityType:[null,[Validators.required]],
        natureBusinessActivity:[null,[Validators.required]],
        financingAmount:[this.initFiananceAmount,[Validators.required]],
        financingAmountRangLower:[this.initRangFinanceAmount,[Validators.required]],
        financingAmountRangUpper:[this.initRangFinanceAmount,[Validators.required]],
        checkFinanceAmount :[false],
        currenciesFinance:[null,[Validators.required]],
        descriptionFinancingRequired:[null,[Validators.required]],
        webSite:[null],
        // file:[null],
        additionalInformation:[null],
      })

  }
  // [this.finaneAmountValidation]
  save(){
    this.submitted = true;
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

   this.postFinanceRequest.country = this.form.value.country.label;
   this.postFinanceRequest.applicantRequesterName =  this.form.value.applicantRequesterName
   this.postFinanceRequest.mansaId = this.form.value.mansaId
   this.postFinanceRequest.contactName =  this.form.value.contactName
   this.postFinanceRequest.contactPhone =  this.form.value.contactPhone.internationalNumber
   this.postFinanceRequest.contactEmail =  this.form.value.contactEmail
   this.postFinanceRequest.institutionType =  this.form.value.institutionType
   this.postFinanceRequest.financeFacilityType = this.form.value.financeFacilityType
   //Test
   console.log("finance amout value :",this.form.value.financingAmount);
   console.log("financingAmountRangUpper value :",this.form.value.financingAmountRangUpper);
   console.log("financingAmountRangLower value :",this.form.value.financingAmountRangLower);
   // validateur opptionnel
   if(this.isCheckedButton==false && this.form.value.financingAmount==null){
    this.form.controls.financingAmount.setValue(null);
   }
   if (this.isCheckedButton==true && (this.form.value.financingAmountRangUpper==null || this.form.value.financingAmountRangLower ==null) ){
    this.form.controls.financingAmount.setValue(null);
    this.form.controls.financingAmount.setValue(null);
   }
   if(this.isCheckedButton==false && this.form.value.financingAmount=="0"){
     console.log("test success");
     //this.form.patchValue({"financingAmount" :null});
     this.form.controls.financingAmount.setValue(null);
    this.initFiananceAmount=null;
  }
  if (this.isCheckedButton==true && (this.form.value.financingAmountRangUpper=="0" || this.form.value.financingAmountRangLower =="0") ){
    console.log("test success");
    this.form.controls.financingAmountRangUpper.setValue(null);
    this.form.controls.financingAmountRangLower.setValue(null);
  }
   if (this.isCheckedButton==true && (this.form.value.financingAmountRangUpper < this.form.value.financingAmountRangLower) ){
    console.log("test success");
    this.ActivRangMsg =true;
    this.form.controls.financingAmountRangUpper.setValue(null);
    this.form.controls.financingAmountRangLower.setValue(null);
   }

   //les cas pour remplir les champs rang lower et upper dans le cas null
  if(this.form.value.financingAmountRangUpper=="0" && this.form.value.financingAmountRangLower=="0"){
    this.postFinanceRequest.amountUpperLimit =this.form.value.financingAmount ;
    this.postFinanceRequest.amountLowerLimit =this.form.value.financingAmount ;
   }else{
    this.postFinanceRequest.amountUpperLimit =this.form.value.financingAmountRangUpper;
    this.postFinanceRequest.amountLowerLimit =this.form.value.financingAmountRangLower;
  }


   this.postFinanceRequest.currenciesFinance = this.form.value.currenciesFinance
   this.postFinanceRequest.descriptionFinancingRequired = this.form.value.descriptionFinancingRequired
   this.postFinanceRequest.webSite =  this.form.value.webSite
   this.postFinanceRequest.additionalInformation =  this.form.value.additionalInformation
   this.postFinanceRequest.natureBusinessActivity=this.form.value.natureBusinessActivity
   console.log("Upper :"+this.form.value.financingAmountRangUpper);
   console.log("post Finance Request ",this.postFinanceRequest);
   console.log(this.form.valid);

   if(this.form.valid == true){
    this.financeOpportunitiesService.create(this.postFinanceRequest).subscribe((data:any)=>{
      if(this.fileList.length > 0){
        console.log('file selected!!!!!!!!!')
        this.idPost  = data.id;
        this.handleUpload(this.idPost);
      }
    });
    console.log('data saved')
    this.message.create('success', `Your Post request seved successufly`);
    this.form.reset();
    this.initForm();
    this.submitted = false;

    }else{
      this.message.create('error', `Please Check Your Informations`);
    }

  }
  getIntroduction(){
    this.financeService.getTitleIntroductionPostRequest().subscribe(
      (data:any)=>{
      this.introductionTitle=data.text
    },
    (error) => console.log(error))
  }
  finaneAmountValidation = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value  && this.isCheckedButton==false) {
      return { required: true ,error : true};
    } else if(this.isCheckedButton==true){
      return { required: false ,error : false};
    }
    return {};
  }
  beforeUpload = (file: UploadFile): boolean => {
    console.log("beforeUpload function is called !!!")
    this.fileList = this.fileList.concat(file);
    console.log("file list taill",this.fileList.length);
    return false;
  };
  selectChangeMode (){
    this.checkDisaledRang=false;
    console.log("rang",this.isCheckedButton)
     if(this.isCheckedButton ==true){
        this.disabled=true;
        this.checkDisaledRang=true;
     }else {

     this.disabled=false;
}
  console.log(this.disabled);
}


selectChangeModeTotal (value){
  console.log("total amount input :",value);
  if(value==false){
     this.disabled=false;
     this.checkDisaledRang=true;
     this.form.controls.checkFinanceAmount.reset(false);
  }else{
     this.disabled=true;
}
console.log("total amount output :",value);
}

getNatureBuisness(){
  this.financeOpportunitiesService.getNatureBusiness().subscribe((data :any[])=>{
    this.natureBuissnes =data;
    console.log('nature buisness',data);
  })
}

handleUpload(id: any) {

  console.log("Id Post===============> ",id)

  const formData = new FormData();
  this.fileList.forEach((file: any) => {
    console.log(file);
    formData.append('files', file);
  });
  console.log("file liste ==============> ",this.fileList)
  console.log("form Data=======================",formData)
  this.uploading = true;
  // this.financeService.uploadFile(id,formData)
  //   .subscribe(
  //     () => {
  //       this.uploading = false;
  //       this.fileList = [];

  //       console.log("My File")


  //       this.message.success('upload successfully.');



  //     },
  //     () => {
  //       this.uploading = false;
  //       this.message.error('upload failed.');
  //     }
  //   );
}

  updateIntroduction(value:any){
    this.introduction.id=1
    this.introduction.text=value
    this.financeService.updateTitle(this.introduction).subscribe(
      (data:any)=>{
      this.introductionTitle=data.text
    },
    (error) => console.log(error))
  }

  // countryChange(value: string): void {
  //   this.selectedCountry = value;
  //   console.log(this.selectedCountry)
  // }
  countryChange(country) {
    Object.entries(this.CountryISO).forEach(([key, value]) => {
       if (key == country.label) {
         this.selectedISO = value;
       }
    });
    //console.log(this.selectedISO);
 }
  getAllFinanceTypes() {
    this.financeOpportunitiesService.getFinanceTypes()
      .subscribe((res: any[]) => {
        this.financesTypes = res;
      })
  }

  getAllTypeOfInstitution() {
    this.financeOpportunitiesService.getInstitutionTypes().subscribe(
      (res: any[]) => {
        this.typeOfInstitutions = res;
      }
    )
  }
  getAllCountries(){
    this.subscriptionService
    .getPreferredMarkets().subscribe(
      (res : any[])=>{
        this.countries = res;
      }
    )
  }
  errorMsg :any;
  confirmationFinanceAmountUpper = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      this.errorMsg="erreur input";
      return { required: true };
    } else if (control.value < this.form.controls.financingAmountRangLower.value) {
      this.errorMsg="erreur rang";
      console.log("erreur rang");
      return { confirm: true, error: true };
    }
    return {};
  }
  confirmationMansaId = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value.length <20) {
      return { required: true,error:true };
    }
    return {};
  }
  confirmationFinanceamount = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value && this.form.value.checkFinanceAmount==false) {
      return { required: true };
    }else  {
     return{required : false};
    }
    return {};
  }
  handleChange(info: UploadChangeParam): void {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-10);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.fileList = fileList;
  }





}
