import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import * as countriesJson from "src/assets/js/countries.json";
import {FormGroup, FormBuilder, Validators, FormControl, FormArray} from '@angular/forms';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { SubscriptionService } from 'src/app/commons/services/subscription/subscription.service';
import { NzMessageService, NzTreeSelectComponent } from "ng-zorro-antd";
import * as newArraySectors from "src/assets/js/new_sectors.json";
import { services } from "../../../../assets/js/service";
import { products } from "../../../../assets/js/products";
import { FinanceOpportunitiesService } from '../../../commons/services/financing-opportunities/finance-opportunities.service';
import { Router } from "@angular/router";
import { UserService } from "src/app/commons/services/user.service";
import { environment } from "../../../../environments/environment";
import { FreightService } from "src/app/commons/services/service freight/freight.service";

export interface Sectors {
  key: string;
  title: string;
}
@Component({
  selector: 'app-post-freight',
  templateUrl: './post-freight.component.html',
  styleUrls: ['./post-freight.component.css']
})
export class PostFreightComponent implements OnInit {
  @ViewChild('nzTreeComponentSectors', { static: false }) nzTreeComponentSectors!: NzTreeSelectComponent;
  @ViewChild('nzTreeComponentProducts', { static: false }) nzTreeComponentProducts!: NzTreeSelectComponent;
  @ViewChild('nzTreeComponentServices', { static: false }) nzTreeComponentServices!: NzTreeSelectComponent;
  @ViewChildren('nzTreeComponentCountryTo') nzTreeComponentCountryTo!: QueryList<NzTreeSelectComponent>;
  @ViewChildren('nzTreeComponentCountryFrom') nzTreeComponentCountryFrom !: QueryList<NzTreeSelectComponent>;
  @ViewChildren('nzTreeComponentExtraCountryTo') nzTreeComponentExtraCountryTo!: QueryList<NzTreeSelectComponent>;
  @ViewChildren('nzTreeComponentExtraCountryFrom') nzTreeComponentExtraCountryFrom!: QueryList<NzTreeSelectComponent>;
  @ViewChildren('nzTreeComponentCountryFromIntermodal') nzTreeComponentCountryFromIntermodal!: QueryList<NzTreeSelectComponent>;
  @ViewChildren('nzTreeComponentCountryToIntermodal') nzTreeComponentCountryToIntermodal!: QueryList<NzTreeSelectComponent>;
  allsectors: any = (newArraySectors as any).default;
  allproduct = products;
  allService = services;
  postFreight = new FormGroup({});
  FreightHubPost: any = {};
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  submitted = false;
  isLoading = false;
  SearchCountryField = SearchCountryField;
  selectedISO = CountryISO.Morocco;
  CountryISO = CountryISO;
  TooltipLabel = TooltipLabel;
  separateDialCode = true;
  natureOfBusinessActivity: any = "natureOfBusinessActivity";
  selectedCountry: any = {
    currency_name: "Moroccan Dirham",
    flag: "assets/countries/MAR.svg",
    id: "34",
    in_africa: true,
    isocode: "MAR",
    label: "Morocco",
    region: "Africa",
    type_pays: "Country",
    value: "Morocco"
  };
  products: any[] = [];
  services: any[] = [];
  natureOfBuiss =
    [
      {
        label: 'Exporter',
        value: 'Exporter',
      },
      {
        label: 'Importer',
        value: 'Importer',
      },
      {
        label: 'Exporter and Importer',
        value: 'Exporter_and_Importer',
      },
      {
        label: 'Domestic Trade',
        value: 'Domestic_trade',
      },
      {
        label: 'Distribution',
        value: 'Distribution',
      }
    ];
  allcountries: any = (countriesJson as any).default;
  isVisible: boolean = false;
  selectedSectorValue: any;
  selectedServiceValue: any;
  selectedProductValue: any;
  freightTypes = ["Seaport", "Freight forwarder", "Air Cargo", "Railway", "Couriers", "Logistics", "Warehousing", "Trucking", "Forwarding", "Inspection", "Customs brokers","Customs"];
  coverage: any[] = []
  selectedSector = ["A"];
  sectors: any;
  newSectors: any[] = [];
  newArry: Array<any> = [];
  interestedToFollow = [
    {
      title: 'All',
      key: '0-0',
      disabled: false,
      children: [
      ]
    },
  ];
  interestedToFollowServices = [
    {
      disabled: false,
      title: 'All',
      key: '0-0',
      children: []
    },
  ];
  currentarray: any[];
  freightHubList: any;
  dataTable: any;
  companyNames: any = [];
  selectedCompany: any;
  total: number;
  pageIndex: number = 1;
  pageSize: number = 10;
  cretaria: any = {};
  key: string = "";
  finance: any = [];
  listOfDisplayData: any = [];
  customStyle: any = {
    background: '#f7f7f7',
    'border-radius': '4px',
    'margin-bottom': '24px',
    border: '0px'
  }
  selectedCompanyInformation: any;
  CompanyActive: boolean = false;
  initProduct: String[] = [""];
  initService: String[] = [];
  company: any;
  idCompany: any;
  contributorCompanyName: any;
  userId: any;
  role: any[] = [];
  user;
  TestLisr: any[] = [];
  Revenuevisible: boolean = false
  EmplyeSize: boolean = false
  trurnoverVisible: boolean = false;
  modalFactVisibile: boolean = false;

  shippingMode =
    [
      {
        label: 'Air Freight',
        value: 'air'
      },
      {
        label: 'Sea Freight',
        value: 'sea'
      },
      {
        label: 'Land Freight',
        value: 'land'
      },
      {
        label: 'Intermodal',
        value: 'intermodal'
      }
    ];
  shippingIntermodel =
    [
      {
        label: 'Air Freight',
        value: 'air'
      },
      {
        label: 'Sea Freight',
        value: 'sea'
      },
      {
        label: 'Land Freight',
        value: 'land'
      }
    ];
  allPosition = [];
  intermodelFrom;
  intermodelTo;
  textPosition = "Airport";
  textPositionTo = "";
  IsinterModel = false;
  allAfricanCountries = this.allcountries.filter(x => x.in_africa == true);
  seaAfricanCountries = [];
  seaAfricanCountriesInterModel = [];
  showAfrica = this.allAfricanCountries;
  showAfricaInterModel = this.allAfricanCountries;
  listSeaPort = ["BWA", "BFA", "BDI", "CAF", "TCD", "ETH", "LSO", "MWI", "MLI", "NER", "RWA", "SSD", "SWZ", "UGA", "ZMB", "MOZ"];
  listPositionFrom:any[][]=[];
  listPositionTo :any[][]=[];
  listPositionIntermodelFrom :any[][]=[];
  listPositionIntermodelTo :any[][]=[];
  listPositionSecondIntermodelFrom :any[][]=[];
  listPositionSecondIntermodelTo :any[][]=[];
  isVisibleDetails: boolean = false;
  listDataDetail: any;
  shippingInfoVisibile:boolean=false;
  criteria:any={}
  shippingRoutes:any[]=[];
  shippingIntermodelTo :any[][]=[]
  labelShipping:any[]=["Airport"];
  labelShippingFirst:any[]=["Airport"];
  labelShippingSecond:any[]=["Port"];
  typeOfEntities = ["SME", "Corporate", "Financial Institution", "Government", "Trade Supporting Institution"];
  natureBuissnes: any[] = ['Importer', 'Exporter', 'Domestic trade', 'Service provider', 'Manufacturer', 'Producer', 'Wholesaler', 'Distributor', 'Dealer', 'Retailer'];
  constructor(
    private freightService: FreightService,private userService: UserService, private financeOpportunitiesService: FinanceOpportunitiesService, private fb: FormBuilder, private subscriptionService: SubscriptionService, private msg: NzMessageService, private router: Router) {
  }

  ngOnInit() {

    this.allAfricanCountries.forEach(element => {
      const portFound = this.listSeaPort.find(port => port === element.isocode)
      if (!portFound) {
        this.seaAfricanCountries.push(element);
        this.seaAfricanCountriesInterModel.push(element);
      }
    });
    this.role = this.userService.currentUser && this.userService.currentUser.role;
    this.getUser();
    this.initForm();
    this.getSectors();
    //this.getAll();
    this.searchAccesTofinance("");
    //this.checkValue("air",0);
    //this.checkValue2("air",0);
  }

  countryChangeModel(country) {
    Object.entries(this.CountryISO).forEach(([key, value]) => {
      if (key.toLowerCase() == (country.label as String).split(' ').join('').toLowerCase()) {
        this.selectedISO = value;
      }
    });
  }

  getSectors() {
    this.newSectors = this.allsectors;
  }

  recursiveService(children) {
    if (children.sublevels) {
      const sublvs = [];
      children.sublevels.forEach(element => {
        let childs = [];
        if (element.sublevels) {
          childs = this.recursiveService(element);
        }
        let elm;
        elm = {
          key: element.service,
          title: element.service,
          children: childs
        };
        if (childs.length == 0) {
          elm = {
            key: element.service,
            title: element.service,
            isLeaf: true
          };
        }

        sublvs.push(elm);
      });
      return sublvs;
    } else {
      return [];
    }
  }

  recursive(children) {
    if (children.sublevels) {
      const sublvs = [];
      children.sublevels.forEach(element => {
        let childs = [];
        if (element.sublevels) {
          childs = this.recursive(element);
        }
        let elm;
        elm = {
          key: element.code + " - " + element.product_name,
          title: element.product_name,
          children: childs
        };
        if (childs.length == 0) {
          elm = {
            key: element.code + " - " + element.product_name,
            title: element.product_name,
            isLeaf: true
          };
        }

        sublvs.push(elm);
      });
      return sublvs;
    } else {
      return [];
    }
  }

  initForm() {
    this.postFreight = this.fb.group({
      freightActivityType: [null, [Validators.required]],
      serviceEstimateCost: [null],
      coverage: [null,[Validators.required]],
      companyName: [null, [Validators.required]],
      country: [null],
      phone: [null, [Validators.required]],
      companyType: [null],
      contactEmail: [
        null,
        [
          Validators.required,
          Validators.email,
        ],
      ],
      sector: [null, [Validators.required]],
      product: [this.initProduct],
      service: [this.initService],
      natureOfBusinessActivity: [null],
      website: [null],
      productDescription: [null],
      serviceDescription: [null],
      adress: [null],
      intraAfricaPresence: [null],
      companyID: [null],
      isoCode: [null],
      sectorCompany: [null],
      sectorCompanyName: [null],
      emailCompany: [null],
      phoneCompany: [null],
      shippingInfo: this.fb.array([this.createFormGroup()]),
      establishmentYear: [null],
      companyDescription: [null],
    });
  }
  getEligibleCountriesIso(value: any[], attr: String) {
    if (attr == "isocode") {
      return value.map((item: any) => item.isocode)
    }
    if (attr == "label") {
      return value.map((item: any) => item.label)
    }
  }
  submitForm() {
    this.submitted=true;
    for (const i in this.postFreight.controls) {
      this.postFreight.controls[i].markAsDirty();
      this.postFreight.controls[i].updateValueAndValidity();
    }
    if (this.postFreight.value.product == this.initProduct && this.postFreight.value.service == this.initService) {
      this.postFreight.controls.product.setValue(null);
      this.postFreight.controls.service.setValue(null);
    }
    this.FreightHubPost.freightActivityType = this.postFreight.value.freightActivityType;
    // this.FreightHubPost.serviceEstimateCost = this.postFreight.value.serviceEstimateCost;
    this.FreightHubPost.coverage = this.getEligibleCountriesIso(this.postFreight.value.coverage, "label");
    this.FreightHubPost.coverageIso = this.getEligibleCountriesIso(this.postFreight.value.coverage, "isocode");
    this.FreightHubPost.companyName = this.postFreight.value.companyName;
    this.FreightHubPost.phone = this.postFreight.value.phone.internationalNumber;
    this.FreightHubPost.companyType = this.postFreight.value.companyType;
    this.FreightHubPost.contactEmail = this.postFreight.value.contactEmail;
    const arrSector: Sectors[] = [];
    this.nzTreeComponentSectors.getCheckedNodeList().map((sector) => {
      arrSector.push({
        key: sector.origin.key,
        title: sector.origin.title
      });
    });
    this.FreightHubPost.subSector = arrSector.map((item) => item.key);
    this.FreightHubPost.sectorName = arrSector.map((item) => item.title);
    this.FreightHubPost.services = this.postFreight.value.service.map((item) => item.service);
    this.FreightHubPost.natureOfBusinessActivity = this.postFreight.value.natureOfBusinessActivity;
    this.FreightHubPost.website = this.postFreight.value.website;
    this.FreightHubPost.productDescription = this.postFreight.value.productDescription;
    this.FreightHubPost.serviceDescription = this.postFreight.value.serviceDescription;
    this.FreightHubPost.adress = this.postFreight.value.adress;
    this.FreightHubPost.country = this.postFreight.value.country.label;
    this.FreightHubPost.isoCode = this.postFreight.value.country.isocode;
    this.FreightHubPost.companyDescription = this.postFreight.value.companyDescription;
    this.FreightHubPost.establishmentYear = this.postFreight.value.establishmentYear;
    this.FreightHubPost.intraAfricaPresence = this.postFreight.value.intraAfricaPresence;
    this.FreightHubPost.epobsCode = this.postFreight.value.service.map((item) => item.code);
    const arrHsCode: Sectors[] = [];
    this.nzTreeComponentProducts.getCheckedNodeList().map((checkedTree) => {
      console.log("checkedTree", checkedTree)
      arrHsCode.push({
        key: checkedTree.origin.key,
        title: checkedTree.origin.title
      });

    });
    this.FreightHubPost.hsCode = arrHsCode.map((item) => item.key);
    this.FreightHubPost.products = arrHsCode.map((item) => item.title);

    this.FreightHubPost.shippingMode = null;
    this.FreightHubPost.shippingInfos =this.shippingRoutes;
    console.log(this.postFreight.value);
    console.log(this.postFreight.controls);
    if (this.postFreight.valid == true) {
      this.isLoading = true;
      this.freightService.postFreightHub(this.FreightHubPost).subscribe((res: any[]) => {
        this.isLoading = false;
        this.msg.create('success', `Your Post request saved successufly ,Await admin validation`);
        //this.postFreight.reset();
        this.handleCancel();
        this.searchAccesTofinance("");
        this.resetFormAttr();
        this.initForm();
      });

    } else {
      this.isLoading = false;
      this.msg.create('error', `Please Check Your Informations`);
    }

  }

  resetFormAttr() {
    this.selectedCompanyInformation = null;
    this.CompanyActive = false;
    this.submitted=false;
    this.shippingRoutes = [];
    this.companyNames = [];
  }

  getAll() {
    this.freightService.getAllFreightHub().subscribe((res: any) => {
      this.freightHubList = res;
      this.dataTable = this.freightHubList.content;
    })
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {

    this.isVisible = false;
  }

  sectorChange(secotrs: any) {
    this.selectedSectorValue = this.nzTreeComponentSectors.getCheckedNodeList()[0] && this.nzTreeComponentSectors.getCheckedNodeList()[0].key
  }
  disabledRequired: boolean = true;
  onChangeProduct(event) {
    let str = "";
    this.nzTreeComponentProducts.getCheckedNodeList().map((checkedTree) => {
      str += checkedTree.origin.title + " \n ";
    });
    this.postFreight.controls['productDescription'].setValue(str);

  }
  disabledServiceRequired: boolean = true;
  onChangeService(event) {
    console.log("event Service: ", event);
    let str = "";
    let arr = [];
    arr = event;
    arr.forEach(element => {
      console.log("Service", element);
      str += element.service + " \n ";
      console.log(str);
    });
    console.log(str);

    this.postFreight.controls['serviceDescription'].setValue(str);

    console.log("code service :", event.map((item) => item.code));
  }

  /////////////////////////////////////////////////////////



  getCompanyByName(value) {
    if (this.role[0] != 'ROLE_PROVIDER') {
      if (value != null) {
        console.log("provider in",)
        this.subscriptionService.getCompanyName(value).subscribe((resp: any) => {
          this.companyNames = resp;
          if (this.companyNames.length > 0) {
            this.selectedCompany = this.companyNames[0];
          }

          //
          console.log("companyNames", this.selectedCompany);
        })
      }
    } else {
      if (value != null) {
        console.log("provider in",)
        this.subscriptionService.getCompanyName(value.name).subscribe((resp: any) => {
          this.companyNames = resp;
          if (this.companyNames.length > 0) {
            this.selectedCompany = this.companyNames[0];
          } else {
            this.selectedCompany = this.companyNames[0];
            this.companyNames = this.selectedCompany;
          }

          //
          console.log("companyNames from elese :", this.selectedCompany);
        })
      }
      this.companyNames.push(this.selectedCompany);
    }
  }

  getCountryByIsoCode(isoCode) {
    return this.allcountries.filter((item: any) => item.isocode == isoCode)[0]
  }
  getNatureBusinessActivity(value) {
    console.log("getNatureBusinessActivity", this.natureBuissnes.filter((item: any) => item.nature_business_activity.toLowerCase().includes(value.toLowerCase()))[0])
    return this.natureBuissnes.filter((item: any) => item.nature_business_activity.toLowerCase().includes(value.toLowerCase()))[0]
  }
  searchAccesTofinance(value: string) {
    console.log("key : ", value);
    this.listOfDisplayData = []
    this.initCretaria();
    if (value != null) {
      this.key = value;
      this.cretaria.key = value;
      this.cretaria.page = this.pageIndex - 1
      this.cretaria.size = this.pageSize
      console.log("criterea :", this.cretaria);
      this.freightService.searchBykeyFreightHub(this.cretaria).subscribe((data: any) => {
        console.log("marketing with cretaria:", data);
        this.listOfDisplayData = data.content;
        this.total = data.totalElements;
      });
    } else {
      this.selectedCompanyInformation = null;
      this.CompanyActive = false;
    }
  }
  private initCretaria() {
    for (let attr in this.cretaria) {
      if (!(attr === "page" || attr === "size")) {
        delete this.cretaria[attr];
      }
    }
  }
  resetPage() {
    this.pageIndex = 1
    this.cretaria.page = this.pageIndex;
    this.cretaria.size = this.pageSize;

    this.finance = [];
  }
  search() {
    this.resetPage()
    this.searchAccesTofinance(this.key)
  }
  currentPageIndexChange(value: any): void {
    /*this.listOfDisplayData = $event;
    this.refreshStatus();*/
    this.pageIndex = value
    this.searchAccesTofinance(this.key)
    console.log("page index change", value)
  }
  pageSizeChange(value: any): void {
    /*this.listOfDisplayData = $event;
    this.refreshStatus();*/
    this.pageSize = value
    this.resetPage()
    this.searchAccesTofinance(this.key)
    console.log("page size change", value)
  }
  gotLoink() {
    this.router.navigateByUrl("/myProfile/company/post-company");
  }
  getUser() {
    this.userId = this.userService.currentUser && this.userService.currentUser.id;
    this.userService.findById(this.userId).subscribe((user: any) => {
      if (user.userCompanyId != null) {
        console.log("user data second : ", user);
        this.user = user;
        this.idCompany = user.userCompanyId;
      }
    })
    console.log("user company :", this.user);
    console.log("company id :", this.idCompany);
  }
  showModalFact(type: string): void {
    this.modalFactVisibile = true;
    if (type == "turnover") {
      this.trurnoverVisible = true;
      this.EmplyeSize = false;
      this.Revenuevisible = false;
    }
    if (type == "revenue") {
      this.trurnoverVisible = false;
      this.EmplyeSize = false;
      this.Revenuevisible = true;
    }
    if (type == "size") {
      this.trurnoverVisible = false;
      this.EmplyeSize = true;
      this.Revenuevisible = false;
    }
  }
  list :any[]=[];
  handleCancelModalFact(): void {
    this.modalFactVisibile = false;
  }
  onChangeCountries(shippingMode :any, value, i, type:string) {
    let list = [];
    list.push(value.isocode);
    switch (shippingMode) {
      case 'air':
        this.textPosition = "Airport";
        this.criteria.type = 'airport';
        this.list = [];
        this.list.push(value.isocode);
        this.freightService.getLocationFreight(this.criteria, this.list).subscribe((res: any) => {
          // type==='from' ? this.listPositionFrom[i] =res ? type=='fromIntermodal' ? this.listPositionTo[i] =res : ;
          this.setPosition(type,res,i);
        });
        this.showAfrica = this.allAfricanCountries;
        break;
      case 'sea':
        this.textPosition = "Port";
        this.criteria.type = 'port'
        this.showAfrica = this.seaAfricanCountries;
        this.list = [];
        this.list.push(value.isocode);
        this.freightService.getLocationFreight(this.criteria, this.list).subscribe((res:any)=>{
          this.setPosition(type,res,i);
        });
        break;
      case 'land':
        this.textPosition = "City";
        this.criteria.type = 'city';
        this.list = [];
        this.list.push(value.isocode);
        this.showAfrica = this.allAfricanCountries;
        this.freightService.getLocationFreightCities(this.criteria, this.list).subscribe((res:any)=>{
          this.setPosition(type,res,i);
        });
        break;
      case 'intermodal':
        this.showAfrica = this.allAfricanCountries;
        //this.checkValue("air",0);
        //this.checkValue2("air",0);
        // this.checkValue( )
        break;
      default:
        this.textPosition = "Air";
        break;
    }
  }
  checkValue(value,i:number) {
    //this.postFreight.controls['CountryFromIntermodel'].setValue(null);
    //this.postFreight.controls['CountryToIntermodel'].setValue(null);
    switch (value) {
      case 'air':
        this.textPositionTo = "Airport";
        this.showAfricaInterModel = this.allAfricanCountries;
        this.freightService.getCountriesAirportsFreight().subscribe((res:any)=>{
          this.listPositionIntermodelFrom[i] =res;
          this.listPositionIntermodelTo[i]=res;

        });
        break;
      case 'sea':
        this.textPositionTo = "Port";
        this.freightService.getCountriesSeaportsFreight().subscribe((res:any)=>{
          this.listPositionIntermodelFrom[i] =res;
          this.listPositionIntermodelTo[i]=res;
        });
        this.showAfricaInterModel = this.seaAfricanCountriesInterModel;
        break;
      case 'land':
        this.textPositionTo = "City";
        this.freightService.getCountriesCitiesFreight().subscribe((res:any)=>{
          this.listPositionIntermodelFrom[i] =res;
          this.listPositionIntermodelTo[i]=res;
        });
        this.showAfricaInterModel = this.allAfricanCountries;
        break;
      default:
        this.textPositionTo = "Airport";
        this.freightService.getCountriesAirportsFreight().subscribe((res:any)=>{
          this.listPositionIntermodelFrom[i] =res;
          this.listPositionIntermodelTo[i]=res;
        });
        this.showAfricaInterModel = this.allAfricanCountries;
        break;
    }
  }
  checkValue2(value,i) {
    this.intermodelTo = "";
    console.log(value);
    this.intermodelFrom = value;
    console.log(this.intermodelFrom);

    // this.postFreight.controls['intermodelTo'].setValue(null);

    switch (value) {
      case 'air':
        this.textPosition = "Airport";
        this.freightService.getCountriesAirportsFreight().subscribe((res:any)=>{
          this.listPositionFrom[i] =res;
          this.listPositionTo[i]=res;
        });
        this.showAfricaInterModel = this.allAfricanCountries;
        break;
      case 'sea':
        this.textPosition = "Port";
        this.showAfricaInterModel = this.allAfricanCountries;
        this.freightService.getCountriesSeaportsFreight().subscribe((res:any)=>{
          this.listPositionFrom[i] =res;
          this.listPositionTo[i]=res;
        });
        break;
      case 'land':
        this.textPosition = "City";
        this.freightService.getCountriesCitiesFreight().subscribe((res:any)=>{
          this.listPositionFrom[i] =res;
          this.listPositionTo[i]=res;
        });
        this.showAfricaInterModel = this.seaAfricanCountries;
        break;
      default:
        this.textPosition = "Airport";
        this.freightService.getCountriesAirportsFreight().subscribe((res:any)=>{
          this.listPositionFrom[i] =res;
          this.listPositionTo[i]=res;
        });
        this.showAfricaInterModel = this.allAfricanCountries;
        break;
    }
  }

  // airport port land

  onChangeCountryFrom(event) {
    if (this.IsinterModel) {
      if (this.postFreight.get("intermodelFrom").value == "land") {
        // this.listPositionFrom = this.getCitiesByCountry("land", event);
        let cretaria: any = {};
        cretaria.type = "land";
        this.freightService.getLocationFreightCities(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionFrom = data;
        })
      } else {
        let dataTxt = "";
        if (this.postFreight.get("intermodelFrom").value == 'air') {
          dataTxt = "airport"
        }
        else if (this.postFreight.get("intermodelFrom").value == 'sea') {
          dataTxt = "port"
        }
        else {
          dataTxt = this.postFreight.get("intermodelFrom").value;
        }
        let cretaria: any = {};
        cretaria.type = dataTxt;
        this.freightService.getLocationFreight(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionFrom = data;
        })
      }
    } else {
      if (this.postFreight.get("shippingMode").value == "land") {
        // this.listPositionFrom = this.getCitiesByCountry("land", event);
        let cretaria: any = {};
        cretaria.type = "land";
        this.freightService.getLocationFreightCities(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionFrom = data;
        });
      } else {
        let dataTxt = "";
        if (this.postFreight.get("shippingMode").value == 'air') {
          dataTxt = "airport"
        }
        else if (this.postFreight.get("shippingMode").value == 'sea') {
          dataTxt = "port"
        }
        else {
          dataTxt = this.postFreight.get("shippingMode").value;
        }
        let cretaria: any = {};
        cretaria.type = dataTxt;
        this.freightService.getLocationFreight(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionFrom = data;
        })
      }
    }

  }
  onChangeCountryTo(event) {
    if (this.IsinterModel) {
      if (this.postFreight.get("intermodelFrom").value == "land") {
        // this.listPositionTo = this.getCitiesByCountry("land", event);
        let cretaria: any = {};
        cretaria.type = "land";
        this.freightService.getLocationFreightCities(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionTo = data;
        })

      } else {
        // this.listPositionTo = this.getPortAirportByCountry(this.postFreight.get("shippingMode").value, event);
        let dataTxt = "";
        if (this.postFreight.get("intermodelFrom").value == 'air') {
          dataTxt = "airport"
        }
        else if (this.postFreight.get("intermodelFrom").value == 'sea') {
          dataTxt = "port"
        }
        else {
          dataTxt = this.postFreight.get("intermodelFrom").value;
        }
        let cretaria: any = {};
        cretaria.type = dataTxt;
        this.freightService.getLocationFreight(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionTo = data;
        })
      }
    } else {
      if (this.postFreight.get("shippingMode").value == "land") {
        // this.listPositionTo = this.getCitiesByCountry("land", event);
        let cretaria: any = {};
        cretaria.type = "land";
        this.freightService.getLocationFreightCities(cretaria, event).subscribe((data: any) => {
          console.log(data);
          this.listPositionTo = data;
        })

      } else {
        // this.listPositionTo = this.getPortAirportByCountry(this.postFreight.get("shippingMode").value, event);
        let dataTxt = "";
        if (this.postFreight.get("shippingMode").value == 'air') {
          dataTxt = "airport"
        }
        else if (this.postFreight.get("shippingMode").value == 'sea') {
          dataTxt = "port"
        }
        else {
          dataTxt = this.postFreight.get("shippingMode").value;
        }
        let cretaria: any = {};
        cretaria.type = dataTxt;
        this.freightService.getLocationFreight(cretaria, event).subscribe((data: any) => {
          this.listPositionTo = data;
        })
      }
    }
  }
  onChangeCountryIntermodelFrom(event,i:number) {

    if (this.postFreight.get("intermodelTo").value == "land") {
      let cretaria: any = {};
      cretaria.type = "land";
      this.freightService.getLocationFreightCities(cretaria, event).subscribe((data: any) => {
        this.listPositionIntermodelFrom = data;
      })
    } else {
      // this.listPositionIntermodelFrom = this.getPortAirportByCountry(this.postFreight.get("shippingMode").value, event);
      let dataTxt = "";
      if (this.postFreight.get("intermodelTo").value == 'air') {
        dataTxt = "airport"
      }
      else if (this.postFreight.get("intermodelTo").value == 'sea') {
        dataTxt = "port"
      }
      else {
        dataTxt = this.postFreight.get("intermodelTo").value;
      }
      let cretaria: any = {};
      cretaria.type = dataTxt;
      this.freightService.getLocationFreight(cretaria, event).subscribe((data: any) => {
        this.listPositionIntermodelFrom[i] = data;
      })
    }
  }
  onChangeCountryIntermodelTo(event) {
    if (this.postFreight.get("intermodelTo").value == "land") {
      let cretaria: any = {};
      cretaria.type = "land";
      this.freightService.getLocationFreightCities(cretaria, event).subscribe((data: any) => {
        this.listPositionIntermodelTo = data;
      })
    } else {
      let dataTxt = "";
      if (this.postFreight.get("intermodelTo").value == 'air') {
        dataTxt = "airport"
      }
      else if (this.postFreight.get("intermodelTo").value == 'sea') {
        dataTxt = "port"
      }
      else {
        dataTxt = this.postFreight.get("intermodelTo").value;
      }
      let cretaria: any = {};
      cretaria.type = dataTxt;

      this.freightService.getLocationFreight(cretaria, event).subscribe((data: any) => {
        this.listPositionIntermodelTo = data;
      })
    }
  }

  getPortAirportByCountry(type, countries) {
    let datas = []
    this.freightService.getLocationFreight(type, countries).subscribe((data: any) => {
      datas = data;
      return datas;
    })
  }
  getCitiesByCountry(type, countries) {
    let datas = []
    this.freightService.getLocationFreightCities(type, countries).subscribe((data: any) => {
      datas = data;
      return datas;
    });

  }

  handleCancelDetails() {
    this.isVisibleDetails = false
  }
  detailInformation(data) {
    this.listDataDetail = data;
    this.isVisibleDetails = true
  }

  getCountryByIso(value) {
    let country = "";
    this.allAfricanCountries.forEach(element => {
      if (element.isocode == value)
        country = element.label
    });
    return country;
  }
  getSelectedNode(nzTreeSelectComponent :NzTreeSelectComponent){
    let result = nzTreeSelectComponent.getCheckedNodeList().map((element:any)=>{
      let children = element.parentNode !=null ? element.parentNode.children.filter( x => x.isChecked === true).map(v => {
          return {
            key: v.key,
            title: v.title,
            isLeaf:true,
          }
        }) :
        element.children.filter( x => x.isChecked === true).map(v => ({key: v.key, title: v.title}));
      return {
        title :element.parentNode != null ? element.parentNode.title : element.title,
        isoCode:element.parentNode != null ? element.parentNode.origin.isoCode : element.origin.isoCode,
        isLeaf:false,
        children :children,
      }
    })
    result = result.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.title === value.title
        ))
    )
    return result
  }
  private createFormGroup(): FormGroup {
    return new FormGroup({
      From:new FormControl(null),
      intermodelFrom: new FormControl('air'),
      intermodelTo: new FormControl('air'),
      shippingMode: new FormControl('air'),
      CountryFrom: new FormControl(null),
      CountryTo: new FormControl(null),
      CountryToIntermodel: new FormControl(null),
      CountrySecondToIntermodel: new FormControl(null),
      CountryFromIntermodel: new FormControl(null),
      CountrySecondFromIntermodel: new FormControl(null),
      PositionFrom: new FormControl(null),
      PositionTo: new FormControl(null),
      PositionFromIntermodel: new FormControl(null),
      PositionSecondFromIntermodel: new FormControl(null),
      PositionToIntermodel: new FormControl(null),
      PositionSecondToIntermodel: new FormControl(null),
      IsinterModel:new FormControl(false),
    });
  }
  handleOkShippingInfo(){
    this.shippingInfoVisibile=false;
    const formModules: any[] = this.postFreight.value.shippingInfo;
    let ind =0;
    this.shippingRoutes=[];
    formModules.map((module,index) => {
      console.log('module',module);
      if(module.IsinterModel == false) {
        let arrayFromCountries:any[]=[];
        arrayFromCountries.push(module.CountryFrom.isocode);
        let arrayToCountries:any[]=[];
        arrayToCountries.push(module.CountryTo.isocode);
        this.shippingRoutes.push({
          originCountries: arrayFromCountries ,
          originPositions: module.PositionFrom,
          destinationCountries:  arrayToCountries ,
          destinationPositions: module.PositionTo,
          shippingModeFrom:  module.shippingMode,
          shippingModeTo:  module.shippingMode,
          shippingOrder: null,
          shipmentKey:null,
        })
      }
      if(module.IsinterModel!=null && module.IsinterModel == true) {
        ind=ind+1
        let arrayFromFirstCountries:any[]=[];
        arrayFromFirstCountries.push(module.CountryFromIntermodel.isocode);
        let arrayToFirstCountries:any[]=[];
        arrayToFirstCountries.push(module.CountryToIntermodel.isocode);
        let arrayFromSecondCountries:any[]=[];
        arrayFromSecondCountries.push(module.CountrySecondFromIntermodel.isocode);
        let arrayToSecondCountries:any[]=[];
        arrayToSecondCountries.push(module.CountrySecondToIntermodel.isocode);
        this.shippingRoutes.push({
          originCountries: arrayFromFirstCountries ,
          originPositions: module.PositionFromIntermodel,
          destinationCountries:  arrayToFirstCountries ,
          destinationPositions: module.PositionToIntermodel,
          shippingModeFrom:  module.intermodelFrom,
          shippingModeTo:  module.intermodelFrom,
          shippingOrder: 'start',
          shipmentKey:'SI-'+ind as String,
        });
        this.shippingRoutes.push({
          originCountries: arrayFromSecondCountries ,
          originPositions: module.PositionSecondFromIntermodel,
          destinationCountries:  arrayToSecondCountries ,
          destinationPositions: module.PositionSecondToIntermodel,
          shippingModeFrom:  module.intermodelTo,
          shippingModeTo:  module.intermodelTo,
          shippingOrder: 'finish',
          shipmentKey:'SI-'+ind as String,
        })
      }
    });
    console.log('this is ',this.shippingRoutes);
  }
  handleCancelShippingInfo(){
    this.shippingInfoVisibile=false;
  }
  showShippingInfo(){
    this.shippingInfoVisibile=true;
  }
  addFormGroup(): void {
    const shippingInfo = this.postFreight.get("shippingInfo") as FormArray;
    shippingInfo.push(this.createFormGroup());
    this.addRowForArrays();
    this.labelShipping.push('Airport')
    this.labelShippingFirst.push('Airport')
    this.labelShippingSecond.push('Port')
  }
  addRowForArrays(){
    this.listPositionFrom.push(new Array());
    this.listPositionTo.push(new Array());
    this.listPositionIntermodelFrom.push(new Array());
    this.listPositionIntermodelTo.push(new Array());
  }

  removeFormGroup(index: number): void {
    const shippingInfo = this.postFreight.get("shippingInfo") as FormArray;
    shippingInfo.length > 1 ? shippingInfo.removeAt(index) : shippingInfo.reset();
    this.removeRowForArrays(index);
  }

  removeRowForArrays(index:number):void{
    this.listPositionFrom.splice(index, 1);
    this.listPositionTo.splice(index, 1);
    this.listPositionIntermodelFrom.splice(index, 1);
    this.listPositionIntermodelTo.splice(index, 1);
  }
  filterValues: any = {};

  getAllTendersOpportunitiesFilters(field, value :QueryList<NzTreeSelectComponent>) {
    let obj = {};
    obj[field] = value;
    this.filterValues[field]=[];
    value.toArray().forEach((item) => {
      this.filterValues[field].push(this.getSelectedNode(item)) as Array<any>
    })
  }

  private setPosition(type:string, result:any, i:number){
    switch (type) {
      case 'from':
        this.listPositionFrom[i] = result;
        break;
      case 'to':
        this.listPositionTo[i]=result;
        break;
      case 'fromFirstInterModal':
        this.listPositionIntermodelFrom[i]=result;
        break;
      case 'toFirstIntermodal':
        this.listPositionIntermodelTo[i]=result;
        this.postFreight.controls.shippingInfo['controls'][i].controls['CountrySecondFromIntermodel'].setValue(this.postFreight.value.shippingInfo[i].CountryToIntermodel);

        break;
      case 'fromSecondIntermodal' :
        this.listPositionSecondIntermodelFrom[i]=result;
        break;
      case 'toSecondInterModal' :
        this.listPositionSecondIntermodelTo[i]=result;
        break;
      default:break;
    }
  }

  onChangeShippingMode(value,i:number){
    console.log(this.postFreight.value.shippingInfo[i].IsinterModel);
    this.postFreight.controls.shippingInfo['controls'][i].controls['CountryFrom'].setValue(null)
    this.postFreight.controls.shippingInfo['controls'][i].controls['CountryTo'].setValue(null)
    this.listPositionFrom[i] =[];
    this.listPositionTo[i] =[];
    switch (value) {
      case 'air' :
        this.postFreight.controls.shippingInfo['controls'][i].controls['IsinterModel'].setValue(false)
        this.labelShipping[i]='Airport';
        break;
      case 'sea' :
        this.postFreight.controls.shippingInfo['controls'][i].controls['IsinterModel'].setValue(false)
        this.labelShipping[i]='Port';
        break;
      case 'land':
        this.postFreight.controls.shippingInfo['controls'][i].controls['IsinterModel'].setValue(false)
        this.labelShipping[i]='City';
        break;
      case 'intermodal':
        this.postFreight.controls.shippingInfo['controls'][i].controls['IsinterModel'].setValue(true);
        this.shippingIntermodelTo[i]=[]
        this.shippingIntermodelTo[i]=[
          {
            label: 'Sea Freight',
            value: 'sea'
          },
          {
            label: 'Land Freight',
            value: 'land'
          }
        ]
        this.labelShipping[i]='Airport';
        break;
      default :
        break;
    }
  }
  removeMode(i : number,value:string): boolean{
    return this.postFreight.value.shippingInfo[i].intermodelFrom == value ? true : false;
  }
  onChangeShippingModeFirstIntermodal(i:number,value:string){
    this.setShippingModeList(value,i);
    this.postFreight.controls.shippingInfo['controls'][i].controls['CountryFromIntermodel'].setValue(null)
    this.postFreight.controls.shippingInfo['controls'][i].controls['CountryToIntermodel'].setValue(null)
    this.listPositionIntermodelFrom[i]=[];
    this.listPositionIntermodelTo[i] =[];
    switch (value) {
      case 'air' :
        this.labelShippingFirst[i] = 'Airport';
        break;
      case 'sea' :
        this.labelShippingFirst[i] = 'Port';
        break;
      case 'land':
        this.labelShippingFirst[i] = 'City';
        break;
    }
  }
  onChangeShippingModeSecondIntermodal(i:number){
    this.postFreight.controls.shippingInfo['controls'][i].controls['CountrySecondFromIntermodel'].setValue(null)
    this.postFreight.controls.shippingInfo['controls'][i].controls['CountrySecondToIntermodel'].setValue(null)
    this.listPositionSecondIntermodelFrom[i] =[];
    this.listPositionSecondIntermodelTo[i] =[];

  }
  setShippingModeList(item :string,i){
    this.shippingIntermodelTo[i]=[]
    this.shippingIntermodelTo[i]=this.shippingIntermodel.filter(x=> x.value != item);
  }
  shippingSecondMode(i:number,type:string){
    if(this.postFreight.value.shippingInfo[i].CountrySecondFromIntermodel!= null) this.onChangeCountries(type,this.postFreight.value.shippingInfo[i].CountrySecondFromIntermodel,i,'fromSecondIntermodal')
    switch (type) {
      case 'air' :
        this.labelShippingSecond[i] = 'Airport';
        break;
      case 'sea' :
        this.labelShippingSecond[i] = 'Port';
        break;
      case 'land':
        this.labelShippingSecond[i] = 'City';
        break;
    }
  }
}
