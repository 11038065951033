import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-freight-lead-by-id',
  templateUrl: './freight-lead-by-id.component.html',
  styleUrls: ['./freight-lead-by-id.component.css']
})
export class FreightLeadByIdComponent implements OnInit {
  public freight:any;
  public id;
  effect = 'scrollx';
  baseUrl = environment.baseUrl+'/';
  intrestsProds =[];
  interested ;
  email;
  public messageToSend = "";
  public Zoomimage;
  isVisible = false;
  isVisible2 = false;
  agree = false;
  constructor(private b2bProductService : B2bProductService, private sharedService: SharedService,private freightService: FreightService,
    private route : ActivatedRoute,private userService: UserService,
    private toaster : ToastrService,
    private router:Router, private mailService: MailService) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.id = data.id;
      this.getByID(this.id);
    });
    this.getUser();
  }
   Zoom(img){
    this.isVisible2=true;
    this.Zoomimage =img
  }
  getByID(id){
    this.freightService.getPostFreightById(id).subscribe((resp: any) => {
      console.log(resp);
       this.freight=resp;
    },
    (err) => {
      console.log(err);
    })
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.email= res.email
        console.log("intererere",this.email)
        this.intrestsProds= res.subscription.interestPostFreightRequestIds
        let prod =res.subscription.interestPostFreightRequestIds.find(x => x === this.freight.toString())
         if(prod==null||prod==undefined){
           this.interested = false
         }
         else {
           this.interested = true
         }
        console.log("intrestsProds",res)
        console.log("intrestsProds",this.intrestsProds)
      })
  }
  }
  goToLiveChat(item) {
    console.log("---------- item  --------- : " , item);
    this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
  }
  addTointer(){
    let type = "postFreightRequest"
    this.b2bProductService.addToInterest(this.freight.id,type).subscribe((resp: any) => {
      this.toaster.success(
        "This freight lead has been added to your interests",
        "",
        {
          closeButton: true,
          timeOut: 5000
        })
        this.interested=true;
    })
  }
  removeFrominter(){
    let type = "postFreightRequest"
    this.b2bProductService.removeInterest(this.freight.id,type).subscribe((resp: any) => {
      this.toaster.info(
        "This freight lead has been removed from your interests",
        "",
        {
          closeButton: true,
          timeOut: 5000
        })
        this.interested=false;
    })
  }
  verifyInterest(){

    console.log("intererere",this.intrestsProds)
    let prod =this.intrestsProds.find(x => x === this.freight.id.toString())
    console.log("intererere",prod)
     if(prod==null||prod==undefined){
       this.interested = false
     }
     else {
       this.interested = true
     }

    console.log("intererere",this.interested)
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.isVisible2 = false;
  }

  log(item){
    this.messageToSend=item;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.isVisible2 = false;
  }
  showModal(): void {
    this.isVisible = true;
  }
  sendMail(item) {

    let data = {
     receiver:item.subscription.email,
     message: this.messageToSend,
     subject:  this.freight.commodity,
     isBuyLeadInquiry: false,
     isWebInquiry: true ,
     isAllCountry: false ,
     isAllCompany: false ,
     location: item.subscription.country,
    }

    console.log("datatat",item);
    console.log("datatat",data);

    this.mailService.sendMessage(data).subscribe(resp => {
      console.log("resap : " , resp);
      this.toaster.success(
        "The inquiry has been sent succefully",
        "Success",
        {
          closeButton: true,
          timeOut: 5000
        })
        this.isVisible = false;
    },(err) => {
    })

   }
  }

