export const regioData=[
  {
    "disabled": false,
    "title": "Economic Region",
    "key": "0-0",
    "children": [
      {
        "key":"AMU",
        "title":"Arab Maghreb Union"
      },
      {
        "key":"CEN-SAD",
        "title":"Community of Sahel-Saharan States"
      },
      {
        "key":"COMESA",
        "title":"Common Market for Eastern and Southern Africa"
      },
      {
        "key":"EAC",
        "title":"East African Community"
      },
      {
        "key":"ECCAS",
        "title":"Economic Community of Central African States"
      },
      {
        "key":"IGAD",
        "title":"Intergovernmental Authority on Development"
      },
      {
        "key":"SADC",
        "title":"Southern African Development Community"
      },
      {
        "key":"ECOWAS",
        "title":"Economic Community of West African States"
      }
    ],
  },
  {
    "disabled": false,
    "title": "Geographic Region",
    "key": "0-1",
    "children": [
      {
        "key":"West Africa",
        "title":"West Africa"
      },
      {
        "key":"Southern Africa",
        "title":"Southern Africa"
      },
      {
        "key":"East Africa",
        "title":"East Africa"
      },
      {
        "key":"North Africa",
        "title":"North Africa"
      },
      {
        "key":"Central Africa",
        "title":"Central Africa"
      }
    ]
  }
]
