import { Component, OnDestroy, OnInit } from '@angular/core';
import { LiveChatService } from 'src/app/commons/services/live-chat-service/live-chat.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { WebSocketService } from 'src/app/commons/services/websocket-service/web-socket.service';
import { UploadFile } from 'ng-zorro-antd';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.css']
})
export class LiveChatComponent implements OnInit, OnDestroy {

  receiverMail: string;
  roomId: string;
  searchTxt: string = "";
  listRoom: any;
  isSpinning: boolean = false;
  public user: any;
  public baseUrl = environment.baseUrl + "/";
  messages: any = [];
  message = '';
  oldRoom: string;
  subscription: any;
  public textArea: string = '';
  public isEmojiPickerVisible: boolean;
  fileList = [];
  public menuInfo: boolean = false;
  public contactUser: any;
  datNow = new Date();

  constructor(private liveChatService: LiveChatService, private activatedRoute: ActivatedRoute, private userService: UserService,
    private webSocketService: WebSocketService, private profileRouteService: ProfileRouteService, private domSanitizer: DomSanitizer) { }

  ngOnDestroy(): void {
    this.disconnect();
  }

  ngOnInit() {
    this.profileRouteService.changeRoute("livechat");
    this.getUser();
  }
  showMenu() {
    console.log("showMenu");

    this.menuInfo = true;
  }
  hideMenu() {
    console.log("hideMenu");

    this.menuInfo = false;
  }
  getParams() {
    this.activatedRoute.queryParams.subscribe(res => {
      this.receiverMail = res.email;
      if (this.receiverMail != null || this.receiverMail != undefined) {
        this.getRoomId();
      } else {
        this.getAllRooms();
      }
    })
  }
  getUser() {

    let id = localStorage.getItem("USER_ID")
    if (id) {
      this.isSpinning = true;
      this.userService.getAuthUser(id).subscribe(
        (res: any) => {
          console.log("userService", res);
          this.user = res;
          this.isSpinning = false;
          this.connect();
          this.getParams();
        })
    }
  }

  getMessagesByRoomId() {
    this.liveChatService.getMessagesByRoomId(this.roomId).subscribe(res => {
      this.messages = res;
      // alert('messages :')
      console.log("all message :", res);
      this.onMessageReceived();
    })
  }

  selctedRoom(item) {
    if(item.notSeenMsgCount) item.notSeenMsgCount=0;

    if (this.user.id == item.userB.id) {
      this.contactUser = item.userA;
    }
    else if (this.user.id == item.userA.id) {
      this.contactUser = item.userB;
    }


    //
    if (this.roomId) { this.oldRoom = this.roomId }
    console.log("iteeeem : ", item);
    if (this.user.id == item.userA.id) this.receiverMail = item.userB.email
    else this.receiverMail = item.userA.email
    this.roomId = item.id;
    console.log("iteeeem : ", this.receiverMail);

    this.getMessagesByRoomId();
  }
  getAllRooms() {
    this.isSpinning = true;
    this.liveChatService.getAllRooms(this.searchTxt).subscribe(res => {
      console.log("res :", res);
      this.listRoom = res;
      this.isSpinning = false;
      if (this.listRoom.length > 0 && !this.roomId) {
        console.log("heeeeeeeeeeeeeeeeeeere: !!!!");
        console.log("rooooooms : ", this.listRoom);

        this.roomId = this.listRoom[0].id;
        if (this.user.id == this.listRoom[0].userB.id) {
          this.receiverMail = this.listRoom[0].userA.email
          this.contactUser = this.listRoom[0].userA;
        }
        else {
          this.receiverMail = this.listRoom[0].userB.email;
          this.contactUser = this.listRoom[0].userB;
        }
        this.getMessagesByRoomId();
        this.onNotificationReceived();
      } else if (this.listRoom.length > 0 && this.roomId) {
        let room = this.listRoom.find(element => element.id == this.roomId);
        console.log("roooom",room)
        console.log("roooom",this.listRoom)

        if (this.user.id == room.userB.id) {
          this.receiverMail = room.userA.email
          this.contactUser = room.userA;
        }
        else {
          this.receiverMail = room.userB.email;
          this.contactUser = room.userB;
        }
        this.getMessagesByRoomId();
        this.onNotificationReceived();
      }

    })

  }

  getRoomId() {
    this.liveChatService.getRoomId(this.receiverMail).subscribe((res: any) => {
      console.log("room Id ", res);
      this.roomId = res.id;
      this.getAllRooms();
    })
  }

  sendMessage() {
    console.log("message : ", this.message);
    console.log("message : ", this.message);
    if (this.fileList.length > 0) {
      this.handleUpload();
      return "";
    }

    let message = this.message.toString();
    let messageDTO = {
      roomId: this.roomId,
      receiverMail: this.receiverMail,
      message: this.message,
      filesToUpload: this.fileList
    }
    if (this.message) {
      this.liveChatService.sendMessage(messageDTO).subscribe(res => {
        this.message = "";
        this.getAllRooms();
        console.log("res : ", res);
      })
    }

  }
  is_socket_connected =false;
  connect() {
    this.is_socket_connected = true;
    this.webSocketService._connect(this.user.email);
  }

  disconnect() {
    this.webSocketService._disconnect(this.user.email);
  }

  sendSocketMessage() {
    this.webSocketService._send(this.message);
  }

  onMessageReceived() {
    if (this.subscription) { this.subscription.unsubscribe() }
    this.webSocketService.onMessageReceived(this.roomId, this.oldRoom);
    this.subscription = this.webSocketService.onMessage(this.roomId).subscribe(res => {
      console.log("rees message :", res);

      this.messages.push(res)
      console.log("messages : ", this.messages);

    })
    // this.webSocketService.currentMessage.subscribe(res=> {
    //   alert("message received")
    //   console.log(res);

    //   if(res.body ) {
    //     console.log(JSON.parse(res.body));
    //     this.messages.push(JSON.parse(res.body))
    //     console.log("haaa2 : " , this.messages);

    //   }
    // })

  }

  onNotificationReceived() {
    console.log("useeeeeeeeeer : ", this.user.email);
 if(this.is_socket_connected){
    this.webSocketService.onNotificationReceived(this.user.email)

    this.webSocketService.onNotification(this.user.email).subscribe(res => {
      console.log("new Notification  :", res);
      this.listRoom.forEach(room => {
        if (room.id == res.roomId) {
          room.notSeenMsgCount++;
        }
      });
    })
  }
  }

  public addEmoji(event) {
    this.message = `${this.message}${event.emoji.native}`;
    // this.isEmojiPickerVisible = false;
  }

  changeFrequency(event) {
    this.searchTxt = event;
    this.getAllRooms();
  }

  handleChange(file) {
    console.log("files : ", file);
    console.log(this.fileList);

    console.log("fileList : ", this.fileList);

  }

  sendFiles = (file) => {
    console.log("files : ", file);
    console.log(this.fileList);
    this.fileList.push(file)


    let messageDTO = {
      roomId: this.roomId,
      receiverMail: this.receiverMail,
      message: this.message,
      filesToUpload: this.fileList
    }

    // this.liveChatService.sendFiles(files ,messageDTO ).subscribe(res => {
    //   console.log("good job " , res);
    //   this.fileList = [];
    // })

  };

  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data = this.ValidateFile(file);
      if (!data) {
        this.fileList = []
        // this.listEmplty=true;
        return true;
      } else {
        // this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file) {
    return true;
  }

  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  handleUpload(): void {
    let messageDTO = {
      roomId: this.roomId,
      receiverMail: this.receiverMail,
      message: this.message,
      filesToUpload: this.fileList
    }
    console.log("heeeeeeeeeere", this.fileList);
    this.liveChatService.sendFiles(this.fileList, messageDTO).subscribe(res => {
      console.log("good job ", res);
      this.getAllRooms();
      this.fileList = [];
    })
  }

  downloadFile(id, filename) {
    this.liveChatService.downloadFile(id, filename).subscribe(res => {
      let dataType = res.type;
      let binaryData = [];
      binaryData.push(res);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

  fileisAnImage(filename) {
    let fileExtension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
    if (fileExtension.toLowerCase() == "png" || fileExtension.toLowerCase() == "jpeg" || fileExtension.toLowerCase() == "jpg") {
      return true;
    } else {
      return false;
    }
  }

  checkDate(date: Date) {
    let convertedDate = new Date(date);
    if (convertedDate) {
      if (convertedDate.setHours(0, 0, 0, 0) == this.datNow.setHours(0, 0, 0, 0)) {
        return true;
      }
      else {
        return false;
      }
    } else return false;
  }

  deleteRoom(id) {
    this.liveChatService.deleteRoomId(id).subscribe(res => {
      this.getAllRooms();
      console.log("room deleted !! ", res);
    })
  }

  //Lets declare Record OBJ
  record;//Will use this flag for toggeling recording
  recording = false;//URL of Blob
  url;error;

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

      /**
    * Start recording.
    */initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    }; navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }/**
    * Will be called automatically.
    */successCallback(stream) {
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      sampleRate: 16000,
    };//Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }/**
    * Stop recording.
    */stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }/**
    * processRecording Do what ever you want with blob
    * @param  {any} blob Blog
    */processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    console.log("blob", blob);
    console.log("url", this.url);
  }/**
    * Process Error.
    */errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }

}
