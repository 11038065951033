import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { NzCarouselComponent, NzMessageService, UploadFile } from 'ng-zorro-antd';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-results-all-ctaegories",
  templateUrl: "./results-all-ctaegories.component.html",
  styleUrls: ["./results-all-ctaegories.component.css"]
})
export class ResultsAllCtaegoriesComponent implements OnInit {
  constructor(private buyRequestService: BuyRequestService,private sharedService: SharedService
    ,private msg: NzMessageService, private router: Router) {}
  public id="";
  public categ="";
  public listProduct=[];
  public countListProduct=0;
  baseUrl = environment.baseUrl ;
  isVisible = false;
   effect = 'fade';
   public fileList: UploadFile[] = [];
   public listEmplty = true;

  public selectedPost;
  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;

  ngOnInit() {
    this.fetchData();
    // this.getSharedData();
  }

  fetchData() {
    this.sharedService.sharedSearchyCategories.subscribe((resp: any) => {
      // this.getByID(resp);

     this.id=resp.id
     this.categ=resp.name
     console.log("sharedService resp.id",this.id);
     console.log("sharedService resp.categ",this.categ);
      this.getAllProductCategories(this.id);
    });
    // this.route.queryParams.subscribe(params => {
    //     console.log(params);
    //     console.log(params.id);
    //     this.id=params.id
    //     this.getAllProductCategories(this.id);
    //     this.categ=params.name
    //   }
    // );
  }
  getAllProductCategories(id) {
      this.buyRequestService.getProductsCategories(id).subscribe((resp: any) => {
        let data =resp;
        console.log("listProduct",resp);

        this.listProduct=data.reverse();
        this.countListProduct =this.listProduct.length
        console.log("listProduct",this.listProduct);
      })
  }
  pre(){
    this.myCarousel.pre();
  }
  next(){
    this.myCarousel.next();

  }
  showModal(item): void {
    this.selectedPost=item;
    this.isVisible = true;

  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
   return true;
 }

 goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}

  // getSharedData(){
  //   this.sharedService.sharedSearchBuyLeads.subscribe((resp: any) => {
  //     this.getByID(resp);
  //   });
  // }

  // getByID(id){
  //   this.sharedService.nextSearchBuyLeads(id);
  //   this.buyRequestService.getProductsById(id).subscribe((resp: any) => {
  //     console.log(resp);
  //   },
  //   (err) => {
  //     console.log(err);
  //   })
  // }
}
