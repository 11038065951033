import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ProfileService } from 'src/app/commons/services/profil service/profile.service';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { PostRequestService } from 'src/app/commons/services/service post request/post-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SubscriptionService } from 'src/app/commons/services/subscription/subscription.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-matching-profiler',
  templateUrl: './matching-profiler.component.html',
  styleUrls: ['./matching-profiler.component.css']
})
export class MatchingProfilerComponent implements OnInit {
  radioValue = 'S';
  baseUrl = environment.baseUrl + "/file/aws/download/";
  public listCategories = [];
  public selectedCategory  ;
  public listSubCategories = [];
  public selectedCategory2 = [];
  public selectedSubCategory = null  ;
  public selectedSubCategory2 = null  ;
  array = [1, 2, 3, 4,5,6,7];
  public quick:string=null;
  public selectedClassification = null;
  public DomesticMarket:any;
  public classifications=[];
  public sellers=[];
  public buyers=[];
  public listafricanCountries:any=[];
  public listafricanCountries1:any=[];
  public country  = [];
  public organizationType = null ;
  public businessType  = null;
  public selectedCat   = null;
  public verified   = null;
  public reciver   = null;
  checked = true;
  public messageToSend = "";
  isVisible = false;
  public fileList: UploadFile[] = [];
  public listEmplty = true;
  organizationTypes : any[];
  filterd=false;
  hasClass=false;
  match=false;
  role;
  countriesRigions = [];
  countryNames = [];
  agree = false;
  public loading ;

  currentUser = this.userService.currentUser;
  constructor(private router:Router,private profileService: ProfileService,private requestService: PostRequestService,
    private mailService: MailService,private toaster: ToastrService,private subscriptionService:SubscriptionService,private msg: NzMessageService,private b2bserviceService: B2bProductService,private userService: UserService,private buyRequestService: BuyRequestService) { }

  ngOnInit() {
    this.getUser();
    this.getCategories();
    this.getAllafricanCountries();
    this.getAllOrganizationType();
    this.matching();
  }
  matching(){
    this.matchSeller();
    this.matchBuyer();
  }
  clear(){
    this.classifications =[];
    this.country =[];
    this.selectedCat=null;
    this.organizationType=null;
    this.selectedSubCategory2=null;
    this.selectedCategory=null;
    this.selectedSubCategory=null;
    this.selectedClassification=null;
    this.businessType=null;
    this.quick=null;
    this.matching();
  }
  matchSeller() {
    this.loading = true;
    let data = {
      category: this.selectedCat,
      subCategory: this.selectedSubCategory2,
      classification: this.selectedClassification,
      organisationType: this.organizationType,
      businessType: this.businessType,
      supplierCountry: this.country,
      mainDomestiqueMarket: this.DomesticMarket,
      verifiedStatus: this.verified,
      search: this.quick,
    }
    console.log("==========>",data);

    this.profileService.buyerMatching(data).subscribe((resp: any) => {
      this.sellers = resp;
      this.loading = false;
    }, (err) => {
      console.log(err);

    })
  }
  matchBuyer() {
    this.loading = true;
    let data = {
      category: this.selectedCat,
      subCategory: this.selectedSubCategory2,
      classification: this.selectedClassification,
      organisationType: this.organizationType,
      businessType: this.businessType,
      supplierCountry: this.country,
      mainDomestiqueMarket: this.DomesticMarket,
      verifiedStatus: this.verified,
      search: this.quick,
    }
    console.log("==========>",data);

    this.profileService.sellerMatching(data).subscribe((resp: any) => {
      this.buyers = resp;
      this.loading = false;
    })
  }
  getAllOrganizationType(){
    this.subscriptionService
    .getOrganizationType().subscribe(
     (res: any[]) => {
       this.organizationTypes = res;
     }
   )
  }
  Mtrue(){
    this.checked=true;
  }
  Mfalse(){
    this.checked=false;
  }
  getAllafricanCountries(){
    this.b2bserviceService.AfricanCountryByRegions().subscribe((resp: any) => {
      this.listafricanCountries1=resp

      let i = 0

      this.listafricanCountries1.forEach(element => {
        this.countryNames=[];
        element.countryList.forEach(countryName => {
          let node1 = {
            key:countryName.label,
            title:  countryName.label,
            isLeaf: true
          }
          this.countryNames.push(node1)
      });
        let node = {
          key: element.region,
          title: element.region,
          children: this.countryNames
        }
        this.countriesRigions.push(node)

        i++
      });


    })
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.findById(id).subscribe(
      (res: any) => {
        console.log("find by",res);
        this.role=res.role;

        let array =[]
        array.push(res.subscription.country);
        this.country = array ;
        console.log("country",this.country);

        let array1 = []
        array1.push(res.subscription.organisationType);
        this.organizationTypes = array1;
        console.log("organizationType",this.organizationType);

        let arrayB = []
        arrayB.push(res.subscription.businessType);
        this.businessType = arrayB;
        console.log("businessType", this.businessType);

        if(this.role=="ROLE_BOTH"){
          this.match=true
        }else if(this.role=="ROLE_BUYER"){
          this.checked=true;
        }else if(this.role=="ROLE_SELLER"){
          this.checked=false;
        }
      })
  }
  }
  getCategories() {
    this.requestService.getCategories().subscribe((resp: any) => {
      console.log(resp);
      this.listCategories = resp
    })
  }
  getSubCategories(event) {
    console.log("==================>",this.selectedCategory);
    console.log(event);
    if(event!=null){
    if(event=="All"){
        this.b2bserviceService.AllSubs().subscribe((resp: any) => {
          this.listSubCategories = resp
          this.matching();
        })

    }
    else{
    this.selectedCategory2 = event.id
    this.selectedCat = event.name
    this.listSubCategories = event.subCategories
    this.matching();
  }
  }else{
    this.listSubCategories=[];
    this.matching();
  }
  }

  getCountries(){
    this.buyRequestService.getCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
      console.log(resp);
    })
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  showModal(item): void {
    this.reciver= item
    this.isVisible = true;
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
   return true;
  }

filter(){
  this.filterd= true;
}
getClassification(event) {
  console.log("classification get event : ",event);
  this.selectedSubCategory2 = event.name
  this.classifications = event.classification
  if(this.classifications.length > 0){
    this.hasClass=true;
  }
  else{
    this.hasClass=false;
  }

  this.matching();


}
log(item){
  this.messageToSend=item;
}
sendMail(item) {

 let data = {
  receiver:item.subscription.email,
  message: this.messageToSend,
  subject:  "We are a Match",
  isBuyLeadInquiry: false,
  isWebInquiry: true ,
  isAllCountry: false ,
  isAllCompany: false ,
  location: item.subscription.country,
 }

 console.log("datatat",item);
 console.log("datatat",data);

 this.mailService.sendMessage(data).subscribe(resp => {
   console.log("resap : " , resp);
   this.toaster.success(
     "The inquiry has been sent succefully",
     "Success",
     {
       closeButton: true,
       timeOut: 5000
     })
     this.isVisible = false;
 },(err) => {
 })

}

goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}


}

