import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  isVisible = false;
  isVisible1 = false;
  isVisible2 = false;
  Zoomimage;
  baseUrl = environment.baseUrl+'/';
  public productId;
  productInfo;
  companyInfo;
  prodImg;
  public companyName;
  public fileList: UploadFile[] = [];
  public listEmplty = true;
  public passed: boolean = false;
  public passed1: boolean = false;
  public demoValue = 3;
  public total = 0;
  public Nrates = 0;
  public prodRate = 0;
  public rates5 = 0;
  public Nrates5 = 0;
  public rates4 = 0;
  public Nrates4 = 0;
  public rates3 = 0;
  public Nrates3 = 0;
  public rates2 = 0;
  public Nrates2 = 0;
  public rates1 = 0;
  public Nrates1 = 0;
  commented = false;
  submit = false;
  isOkLoading = false;
  public rate ;
  public comment;
  public formRate!: FormGroup;
  ratings=[];
  public messageToSend = "";
  isAllCountry =false;
  isAllCompany =false;
  role;
  effect = 'scrollx';
  intrestsProds =[];
  interested ;
  urls = ["assets/images/coffe.png","assets/images/coffe.png","assets/images/coffe.png","assets/images/coffe.png"];

  constructor(private b2bProductService : B2bProductService,private userService: UserService,
              private route : ActivatedRoute, private msg: NzMessageService,
              private router:Router,private fb: FormBuilder,
              private mailService: MailService,
              private toaster : ToastrService) { }

  ngOnInit() {
    console.log('product INFOOOO');
    this.route.params.subscribe(data => {
      this.productId = data.id;
      this.getUser();
      this.verifyInterest();
    });

    let id = localStorage.getItem("USER_ID")
      if(id){
      this.getProductInfobyId();
    }else{
      this.getProductInfobyIdNoUser();
    }
      this.createformRate();
      this.getRates();


  }
  Zoom(img){
    this.isVisible2=true;
    this.Zoomimage =img
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.companyName=res.subscription.companyName;
        this.role=res.role;
        this.intrestsProds= res.subscription.interestProductIds
        let prod =res.subscription.interestProductIds.find(x => x === this.productId.toString())
        console.log("intererere",prod)
         if(prod==null||prod==undefined){
           this.interested = false
         }
         else {
           this.interested = true
         }
        console.log("intrestsProds",res)
        console.log("intrestsProds",this.intrestsProds)
      })
  }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }


  getProductInfobyId() {
    this.b2bProductService.getProductInfobyId(this.productId).subscribe((resp:any)=> {
      this.productInfo = resp.productDTO;
      console.log('productInfo',this.productInfo)
      this.prodImg = this.productInfo.images[0];
      this.companyInfo = resp.subscriptionDTO;
      console.log(resp);

    })
  }
  getProductInfobyIdNoUser() {
    this.b2bProductService.getProductInfobyIdNoUser(this.productId).subscribe((resp:any)=> {
      this.productInfo = resp.productDTO;
      this.prodImg = this.productInfo.images[0];
      this.companyInfo = resp.subscriptionDTO;
      console.log(resp);

    })
  }
  showModal(): void {
    this.isVisible = true;
  }
  showModal1(): void {
    this.isVisible1 = true;

  }
  addRate() {
    if(this.formRate.valid){

    let data = {
      comment:this.comment,
      stars:this.rate,
      product:this.productInfo
    }
    console.log('data', data);

    this.b2bProductService.Rate(data).subscribe((resp: any) => {
      this.msg.success('you have rated this product');
      this.getRates()
      },(err) => {
           console.log(err);
        this.msg.error(err.message);
      })
    }
    else{
      this.msg.error('Please fill in all mandatory fields') ;
    }
  }
  getRates(){
    this.b2bProductService.ProductRatings(this.productId).subscribe((resp:any)=> {
      this.ratings=resp.ratingList
      this.prodRate=resp.ratingAverage
      this.Nrates=resp.ratingCount
      this.Nrates1=resp.countStar1
      this.rates1=resp.percentageStar1
      this.Nrates2=resp.countStar2
      this.rates2=resp.percentageStar2
      this.Nrates3=resp.countStar3
      this.rates3=resp.percentageStar3
      this.Nrates4=resp.countStar4
      this.rates4=resp.percentageStar4
      this.Nrates5=resp.countStar5
      this.rates5=resp.percentageStar5
      if(this.role!= 'ROLE_SELLER'||this.role===undefined){

      console.log("hascommented111",this.role);
      // this.b2bProductService.commented(this.productId).subscribe((resp:any)=> {
      //   this.commented=resp;

      // console.log("hascommented111",resp);
      // console.log("hascommented111",this.role);
      // console.log("hascommented222",this.commented);
      // })
    }
      console.log("rates",resp);

    })
  }
  countTotal(product){
    this.productInfo = product;
    this.total=this.demoValue*this.productInfo.priceDollars
    if(this.demoValue<this.productInfo.minimumOrderQuantity){
      this.passed=true;
    }else{
      this.passed=false;
    }
    if(this.demoValue>this.productInfo.quantity){
      this.passed1=true;
    }else{
      this.passed1=false;
    }
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleOk1(): void {
    this.isOkLoading = true;
    setTimeout(() => {
      this.isVisible1 = false;
      this.isOkLoading = false;
    }, 3000);
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.isVisible2 = false;
    this.isVisible1 = false;
  }

  handleCancel1(): void {
    console.log('Button cancel clicked!');
    this.isVisible1 = false;
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
   return true;
 }submitOrder(prod){
  let data ={
    product:prod,
    cost:this.total,
    quantity:this.demoValue
  }
  this.b2bProductService.postOrder(data).subscribe((resp: any) => {
    this.handleOk1();
    console.log("order is successful");
    console.log(resp);
    let dataType = resp.type;
    let binaryData = [];
    binaryData.push(resp);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    let date = new Date();
    let filename=`invoice_${prod.productName}.pdf`
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.submit = true;
  })
  }
  startOrder(item) {
    this.router.navigate(['/order/'], {queryParams:{product:item.id}})
}
createformRate() {
  this.formRate = this.fb.group({
    id: [],
    comment: [null, [Validators.required]],
    rate: [null, [Validators.required]],


  });
}
sendMail() {

 let data = {
  receiver:this.productInfo.user.email,
  message: this.messageToSend,
  subject: this.productInfo.productName,
  productId:this.productInfo.id,
  isBuyLeadInquiry: false,
  isWebInquiry: true ,
  location: this.productInfo.user.country,
  isAllCountry : this.isAllCountry,
  isAllCompany : this.isAllCompany

 //  subject: this.subject,
 }

 this.mailService.sendMessage(data).subscribe(resp => {
   console.log("resap : " , resp);
   this.toaster.success(
    "The inquiry has been sent succefully",
    "Success",
    {
      closeButton: true,
      timeOut: 500,
    }
  );

     this.isVisible = false;
 },(err) => {
     // console.log("erorrrrrrrr !" , err);

   // this.toaster.error(
   //   err.error.meesage,
   //   "Error",
   //   {
   //     closeButton: true,
   //     timeOut: 5000
   //   })
 })


}
addTointer(){
  let type = "product"
  this.b2bProductService.addToInterest(this.productId,type).subscribe((resp: any) => {
    this.toaster.success(
      this.productInfo.productName+" has been added to your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.interested=true;
  })
}
removeFrominter(){
  let type = "product"
  this.b2bProductService.removeInterest(this.productId,type).subscribe((resp: any) => {
    this.toaster.info(
      this.productInfo.productName+" has been removed from your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.interested=false;
  })
}
verifyInterest(){

  console.log("intererere",this.intrestsProds)
  let prod =this.intrestsProds.find(x => x === this.productId.toString())
  console.log("intererere",prod)
   if(prod==null||prod==undefined){
     this.interested = false
   }
   else {
     this.interested = true
   }

  console.log("intererere",this.interested)
}
}
