import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, AfterContentInit, Output, Input } from '@angular/core';
import {CdkStep, CdkStepper, StepContentPositionState} from '@angular/cdk/stepper';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationEvent
} from '@angular/animations';
import {takeUntil, distinctUntilChanged} from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.css'],
  providers: [{provide: CdkStepper, useExisting: CustomStepperComponent}],
  animations: [trigger('stepTransition', [
    state('previous', style({transform: 'translate3d(-100%, 0, 0)', opacity: 0})),
    state('current', style({transform: 'translate3d(0, 0, 0)', opacity: 1})),
    state('next', style({transform: 'translate3d(100%, 0, 0)', opacity: 0})),
    transition(':enter', [style({display:'block'}),animate(0)]),
    
    transition('* => *', [style({display:'none'}),animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')]),
    
  ])],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomStepperComponent extends CdkStepper implements OnInit {

  @Input() mySteps:any[]

  onClickPrevious(): void {
    this.previous();
  }
  checkstep(item:any){
    if(item.stepIndex.includes(this.selectedIndex))
    return true
    else
    return false
  }
  onClickNext(): void {
    this.next();
  }
  
  ngOnInit() {  
    console.log(this.mySteps)
  }
}
