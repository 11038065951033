import { Directive, Input } from '@angular/core';
import { KeyFeature } from 'src/app/commons/models/KeyFeature';
import { PermissionService } from '../services/permission.service';

@Directive({
  selector: '[appHeaderPermissions]'
})
export class HeaderPermissionsDirective {
  @Input() set appHeaderPermissions(permissions: KeyFeature[]) {
    this.permissionService.checkPermission(permissions)
  }

  constructor(
    private permissionService: PermissionService,
  ) { }
}
