import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductRoutingModule } from './product-routing.module';
import { AddProductComponent } from './add-product/add-product.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchProductComponent } from './search-product/search-product.component';
import { ShowcaseComponent } from './showcase-product/showcase.component';
import { LottieModule } from 'ngx-lottie';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FeaturedSearchComponent } from './featured-search/featured-search.component';
export function playerFactory() {
  return import( 'lottie-web');
}


@NgModule({
  declarations: [ SearchProductComponent,ShowcaseComponent, FeaturedSearchComponent],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    CommonModule,
    NgZorroAntdModule,
    ProductRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule
  ]
})
export class ProductsModule { }
