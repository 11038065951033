import { Component, OnInit } from '@angular/core';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {

  constructor(private profileRouteService : ProfileRouteService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("addNewService");
  }

}
