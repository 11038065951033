import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { FinanceOpportunitiesService } from 'src/app/commons/services/financing-opportunities/finance-opportunities.service';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';

@Component({
  selector: 'app-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
export class AddPartnerComponent implements OnInit {

  public formPostRequest = new FormGroup({});

  public freight =true;
  public finance =false;
  public type;
  public partner;
  public code;
  public notes;
  public listFreight=[];
  public listFinance=[];
  public listPartners=[];
  constructor( private route: Router,
    private financeOpportunitiesService: FinanceOpportunitiesService,
    private freightService: FreightService,
    private fb: FormBuilder,private msg: NzMessageService,private b2bserviceService: B2bProductService) { }

  ngOnInit() {
    this.createFormAddPartner();
    this.getAllData();
    this.search();
  }
  createFormAddPartner() {
    this.formPostRequest = this.fb.group({

      type: ['', Validators.required],
      partner: ['', Validators.required],
      code: ['', Validators.required],
      notes: [''],
    });
  }
  getPartner(type){
    if(type=="Freighter"){
      this.freight=true;
      this.finance=false;
    }
    else if(type=="Finance Provider"){
      this.freight=false;
      this.finance=true;

    }
  }
  addPartnerFreight(){
    if(this.formPostRequest.valid){
      if(this.freight){
      let data = {
        partnerType:this.type,
        freightServiceProviders:this.partner,
        preferredStatutCode:this.code,
        additionalInformation:this.notes
      }
      this.b2bserviceService.savePartner(data).subscribe((resp: any) => {

          this.msg.success('partner added successfully');

          this.route.navigate(["/preferred-partners"]);
          },(err) => {
               console.log(err);
            this.msg.error(err.error.message);
          })
    }else{
      let data = {
        partnerType:this.type,
        financingOpportunities:this.partner,
        preferredStatutCode:this.code,
        additionalInformation:this.notes
      }
      this.b2bserviceService.savePartner(data).subscribe((resp: any) => {

          this.msg.success('partner added successfully');

          this.route.navigate(["/preferred-partners"]);
          },(err) => {
               console.log(err);
            this.msg.error(err.message);
          })
    }
  }


  }
  search() {
    let data = {
    }
    console.log(data);

    this.freightService.searchFreight(data).subscribe((resp: any) => {
      this.listFreight = resp.freigthServiceProviderList;
    }, (err) => {
      console.log(err);

    })
  }
  getAllData() {
    this.financeOpportunitiesService.getAll()
      .subscribe(
        (data: any) => {
          this.listFinance = data;
        },
        (error) => console.log(error))
  }

}
