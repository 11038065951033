import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { ListTradValueChainComponent } from './list-trad-value-chain/list-trad-value-chain.component';
import { TradeValueChainComponent } from './trade-value-chain.component';
  

const routes: Routes = [  
  { path:'valueChain',component: TradeValueChainComponent,pathMatch:'full' },
  { path:'valueChain/list',component: ListTradValueChainComponent,pathMatch:'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class valueChaintRoutingModule { }
