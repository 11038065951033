import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaypalService } from '../services/paypal-service/paypal-service.service';

@Component({
  selector: 'app-paypal-sucess',
  templateUrl: './paypal-sucess.component.html',
  styleUrls: ['./paypal-sucess.component.css']
})
export class PaypalSucessComponent implements OnInit {

  orderId;
  constructor(private activatedRoute : ActivatedRoute, private paypalService : PaypalService) { }

  ngOnInit() {
    this.getOrderId();
  }
  getOrderId() {
   this.orderId = this.activatedRoute.snapshot.params['id'];
    console.log("orrrrrrrrder id : " , this.orderId);
    this.paypalService.PaypalPaySuccess(this.orderId).subscribe(res => {
      console.log(res);
    })
    
    
  //  this.activatedRoute.queryParams.subscribe(res => {
  //    console.log("logging res :" , res);
     
  //   this.orderId=res.product;
  //  ;
  //  })
  } 

  
}
