import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class B2bProductService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}
  getCategories(type) {
    return this.http.get(this.baseUrl + "/products/getCategories?type=" + type);
  }
  getServicesCategories() {
    return this.http.get(this.baseUrl + "/services/getServiceCategories");
  }
  findCpcCodeByCategory(id) {
    return this.http.get(this.baseUrl + "/services/getCpcCodeByCategory/" + id);
  }
  getafricanCountries() {
    return this.http.get(this.baseUrl + "/products/africanCountries");
  }

  AfricanCountryByRegions() {
    return this.http.get(this.baseUrl + "/countries/CountriesByRegion");
  }
  getCitiesByCountry(country) {
    return this.http.get(this.baseUrl + "/countries/cities", {
      params: { country: country },
    });
  }
  getUnitOfMeasure() {
    return this.http.get(this.baseUrl + "/products/unitOfMeasure");
  }
  getUnitOfPrice() {
    return this.http.get(this.baseUrl + "/products/unitOfPrice");
  }
  getSupplyAbilityFrequency() {
    return this.http.get(this.baseUrl + "/products/supplyAbilityFrequency");
  }
  getDeliverTimeFrequency() {
    return this.http.get(this.baseUrl + "/products/deliverTimeFrequency");
  }
  getSubCategories(type, name) {
    return this.http.get(
      this.baseUrl +
        "/products/getSubCategories?type=" +
        type +
        "&category=" +
        name
    );
  }

  postProduct(data) {
    return this.http.post(this.baseUrl + "/products", data);
  }
  UpdateProduct(data) {
    return this.http.put(this.baseUrl + "/products", data);
  }
  postService(data) {
    return this.http.post(this.baseUrl + "/services", data);
  }

  deleteProduct(id) {
    return this.http.delete(this.baseUrl + "/products/" + id);
  }

  uploadFileProduct(id: any, body: any) {
    return this.http.post(this.baseUrl + "/products/" + id + "/upload", body, {
      responseType: "text",
    });
  }

  uploadFilesProduct(id: any, body: any) {
    return this.http.post(this.baseUrl + "/products/" + id + "/upload", body, {
      responseType: "text",
    });
  }

  uploadVideoProduct(id: any, body: any) {
    return this.http.post(
      this.baseUrl + "/products/" + id + "/uploadVideo",
      body,
      { responseType: "text" }
    );
  }

  searchProduct(body: any) {
    return this.http.post(this.baseUrl + "/products/productSearch", body);
  }

  getCountries() {
    return this.http.get(this.baseUrl + "/products/itemsByCountry");
  }
  // POST_BUY_REQUEST
  getCategoriesPOSTREQUEST() {
    return this.http.get(this.baseUrl + "/postBuyRequests/getCategories");
  }

  getProductbyId(id) {
    return this.http.get(this.baseUrl + "/products/" + id);
  }

  getProductInfobyId(id) {
    return this.http.get(this.baseUrl + "/products/productInfo/" + id);
  }
  getProductInfobyIdNoUser(id) {
    return this.http.get(this.baseUrl + "/products/product_info/" + id);
  }

  getMoreProductbySubscriptionId(id) {
    return this.http.get(this.baseUrl + "/products/findCompanyProducts/" + id);
  }

  getAllProductCategory() {
    return this.http.get(this.baseUrl + "/products/getAllProductCategory");
  }

  myProductSearch(data) {
    return this.http.post(this.baseUrl + "/products/myProductSearch", data);
  }

  getAllCatAndClassification() {
    return this.http.get(this.baseUrl + "/products/getAllProductCategory");
  }

  getAllByCategories(cat) {
    return this.http.get(
      this.baseUrl + "/products/getAllSubByCategory?category=" + cat
    );
  }

  getClassification(subCat) {
    return this.http.get(
      this.baseUrl + "/products/getAllClassBySub?subCategory=" + subCat
    );
  }
  findHsCodeByClassification(body) {
    return this.http.put(this.baseUrl + "/products/findHsCode", body);
  }
  getProductsBySubCategoriesIn() {
    return this.http.get(this.baseUrl + "/products/getProductsIn");
  }
  getCategoryHome() {
    return this.http.get(this.baseUrl + "/products/get_category_home");
  }
  getCurrencies() {
    return this.http.get(this.baseUrl + "/products/getCurrencies");
  }
  findAllByUserMail() {
    return this.http.get(this.baseUrl + "/prefered_partner/getByUser");
  }
  savePartner(body: any) {
    return this.http.post(this.baseUrl + "/prefered_partner", body);
  }
  deletePartner(id) {
    return this.http.delete(this.baseUrl + "/prefered_partner/" + id);
  }
  getCountsApprovalByUser() {
    return this.http.get(this.baseUrl + "/approval/userApprovalInfo");
  }
  dashboard() {
    return this.http.get(this.baseUrl + "/reports/dashboard");
  }

  updateDiscount(id, discount) {
    let formData = new FormData();
    formData.append("discount", discount);
    return this.http.put(
      this.baseUrl + "/products/updateDiscountAndAvailability/" + id,
      formData
    );
  }

  updateAvailability(id, avl) {
    let formData = new FormData();
    formData.append("avl", avl);
    return this.http.put(
      this.baseUrl + "/products/updateDiscountAndAvailability/" + id,
      formData
    );
  }

  updateFeatured(id, isFeatured) {
    let formData = new FormData();
    formData.append("isFeatured", isFeatured);
    return this.http.put(
      this.baseUrl + "/products/is_featured/" + id,
      formData
    );
  }

  postOrder(data) {
    return this.http.post(this.baseUrl + "/orders/order", data);
  }
  getOrder(id) {
    return this.http.get(this.baseUrl + "/orders/" + id);
  }
  updateOrder(body: any) {
    return this.http.put(this.baseUrl + "/orders", body);
  }
  getInvoice(id) {
    return this.http.get(this.baseUrl + "/orders/getInvoice/" + id, {
      responseType: "blob",
    });
  }
  getSalesReport() {
    return this.http.get(this.baseUrl + "/reports/getSalesReport");
  }
  get_product_sales_percentage() {
    return this.http.get(
      this.baseUrl + "/reports/get_product_sales_percentage"
    );
  }
  get_country_sales_percentage() {
    return this.http.get(
      this.baseUrl + "/reports/get_country_sales_percentage"
    );
  }
  getTotalCount() {
    return this.http.get(this.baseUrl + "/reports/getTotalCount");
  }
  sellerProduct(id) {
    return this.http.get(this.baseUrl + "/products/seller_product/" + id);
  }
  ProductRatings(id) {
    return this.http.get(this.baseUrl + "/ratings/" + id);
  }
  commented(id) {
    return this.http.get(this.baseUrl + "/ratings/is_had_comment/" + id);
  }
  Rate(data) {
    return this.http.post(this.baseUrl + "/ratings", data);
  }
  getProdsByIds(data) {
    return this.http.post(this.baseUrl + "/products/get_products_by_ids", data);
  }

  updateStock(id, quantity) {
    let formData = new FormData();
    formData.append("quantity", quantity);
    return this.http.put(
      this.baseUrl + "/products/updateStock/" + id,
      formData
    );
  }

  AllSubs() {
    return this.http.get(this.baseUrl + "/categories/all_sub_categories");
  }

  getCatById(id) {
    return this.http.get(this.baseUrl + "/categories/" + id);
  }
  getSubCatByname(name) {
    return this.http.get(this.baseUrl + "/categories/?name=" + name);
  }
  getAllCatgs() {
    return this.http.get(this.baseUrl + "/categories");
  }
  addToInterest(id, type) {
    return this.http.put(
      this.baseUrl + "/interest/add-interest/" + id + "?type=" + type,
      null
    );
  }
  removeInterest(id, type) {
    return this.http.delete(
      this.baseUrl + "/interest/remove-interest/" + id + "?type=" + type
    );
  }
  getInterested() {
    return this.http.get(this.baseUrl + "/interest/get-interest");
  }
  addContact(data) {
    return this.http.post(this.baseUrl + "/contact_us", data);
  }
  getPacksByUser(criteria) {
    return this.http.get(this.baseUrl + "/featured_package/by-user", {
      params: criteria,
    });
  }
  getAvDate(criteria) {
    return this.http.get(this.baseUrl + "/featured_package/get-av-date", {
      params: criteria,
    });
  }
  getAllMyFeatureRequest(criteria) {
    return this.http.get(this.baseUrl + "/featured_package/my-requests", {
      params: criteria,
    });
  }
  createFeaturedRequest(criteria) {
    return this.http.post(
      this.baseUrl + "/featured_package/request/",
      criteria
    );
  }
  getFeaturedRequest(id) {
    return this.http.put(
      this.baseUrl + "/featured_package/pay-request/" + id,
      null
    );
  }
  payFeaturedRequest(id) {
    return this.http.get(this.baseUrl + "/featured_package/get/" + id);
  }
  getAds() {
    return this.http.get(this.baseUrl + "/images/get-display-images/ADS");
  }
  getHotEvents() {
    return this.http.get(
      this.baseUrl + "/images/get-display-images/HOT_EVENTS"
    );
  }
  cancelFeaturedRequest(id) {
    return this.http.put(
      this.baseUrl + "/featured_package/cancel-request/" + id,
      null
    );
  }
}
