export class OrderRequest {

    productId: number;
    cost: number = 0;
    quantity: number = 1;
    shippingDetails : any;
    billingDetails : any;
    product: Product;
    user: any;

    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}

export class ShippingDetails {

    id: number;
    firstName: string;
    lastName: string;
    country: string;
    adress: string;
    adress2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    shippingMethode: string;
    email: string;
    companyName: string;

    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}

export class BillingDetails {

    id: number;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    country: string;
    adress: string;
    adress2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;


    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}


export class Product {

    id?: number;
    minimumOrderQuantity: number = 1;
    priceDollars:number;
    discountedPrice:number;
    discount:number;

    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}
