import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FinanceOpportunitiesService } from 'src/app/commons/services/financing-opportunities/finance-opportunities.service';
import { Opportunitie } from 'src/app/commons/models/opportunitie';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
import { ContactService } from 'src/app/commons/services/contacts/contact.service';
import {NzMessageService, NzTreeSelectComponent, UploadFile} from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import * as countriesJson from '../../../../../assets/js/countries.json';
import { debounceTime, distinctUntilChanged, switchMap, first } from 'rxjs/operators';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import {SubscriptionService} from '../../../../commons/services/subscription/subscription.service';
import {MailService} from '../../../../commons/services/mail-service';
import {UserService} from '../../../../commons/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {data} from '../../../../../assets/js/allcountries-eco-geo';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-financing-opportunities',
  templateUrl: './financing-opportunities.component.html',
  styleUrls: ['./financing-opportunities.component.css']
})
export class FinancingOpportunitiesComponent implements OnInit {

  constructor(
    private financeOpportunitiesService: FinanceOpportunitiesService,
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private i18n: NzI18nService,
    private financeService: FinanceService,
    private msg: NzMessageService,
    private contactService: ContactService,
    private toaster: ToastrService,
    private message: NzMessageService,
    private mailService: MailService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
     ) {

    this.financeOpportunitiesService = financeOpportunitiesService;
    this.connectedUserId = this.userService.currentUser && this.userService.currentUser.id;

  }

  @ViewChild('nzTreeComponent', { static: false })
  nzTreeComponent: NzTreeSelectComponent;
  allcountries: any = (countriesJson as any).default;
  myform = new FormGroup({});
  introductionTitle;
  introduction: any = {};
  pageIndex = 0;
  pageSize = 10;
  requestMeetvisible = false;
  RequestMeetForm = new FormGroup({});
  isVisible1 = false;
  idContact: any;
  idFinanceOpportunities: any;
  uploading = false;
  uploadingCapture = false;
  fileToUpload: File = null;
  fileSelected = false;
  fileToUploadCapture: File = null;
  fileSelectedCapture = false;
  amountOperator = 'gt';
  pageSizeOptions;
  cretaria: any = {};
  key: string;
  financeValue;
  loading = false;
  flagUrl: any;
  selectedCountryTo = '';
  newRegion: Array<any> = [];
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  selectedISO = CountryISO.Morocco;
  TooltipLabel = TooltipLabel;
  contact: any = {};
  opportunitie: Opportunitie[];
  separateDialCode = true;
  total: number;
  isVisible = false;
  postFinanceRequest: any = {};
  countries: any[] = [];
  financesTypes: any[] = [];
  financesType = [];
  SupColor = '#0591ff';
  InfColor = '#fec537';
  Egcolor = '#fec537';
  selectedCountry:any = {
    label: 'Morocco',
    value: 'Morocco',
    id: '34',
    type_pays: 'Country',
    isocode: 'MAR',
    region: 'Africa',
    flag: 'assets/countries/MAR.svg'
  };
  financesRequesters: any[] = [];
  financeRequester: any[] = [];
  typeOfInstitutions: any[] = [];
  financeProviders: any[] = [];
  userId: any;
  initFiananceAmount = 0;
  initRangFinanceAmount = 0;
  contactForm = new FormGroup({});
  fileList: UploadFile[] = [];
  fileListCapture: UploadFile[] = [];
  financeOpportunitiesBase: any[] = [];
  regions: any[];
  financeResult: any[];
  newArry2: any;
  role: any[] = [];
  form: FormGroup;
  submitted = false;
  isCheckedButton = false;
  arrCurrencies: any[] = [];
  ActivRangMsg = false;
  natureBuissnes: any[] = [
    'Importer',
    'Exporter',
    'Domestic trade',
    'Service provider',
    'Manufacturer',
    'Producer',
    'Wholesaler',
    'Distributor',
    'Dealer',
    'Retailer'
  ];
  checkDisaledRang = false;
  disabled = false;
  selectedCompanyInformation: any = {};
  CompanyActive = false;
  user;
  companyNames: any = [];
  selectedCompany: any;
  CountryISO = CountryISO;
  totalCapture: number;
  pageIndexCapture = 1;
  pageSizeCapture = 10;
  cretariaCapture: any = {};
  SearchKey = '';
  financeCapture: any = [];
  listOfDisplayData: any = [];
  customStyle: any = {
    background: '#f7f7f7',
    'border-radius': '4px',
    'margin-bottom': '24px',
    border: '0px'
  };
  contributorCompanyName: any;
  company: any;
  idCompany: any;
  TestLisr: any[] = [];
  Revenuevisible = false;
  EmplyeSize = false;
  trurnoverVisible = false;
  modalFactVisibile = false;
  flagUrlForFilter: any;
  lowerAmount: any = null;
  upperAmount: any = null;
  natureBuissnes1: any[] = [
    'Importer',
    'Exporter',
    'Domestic trade',
    'Service provider',
    'Manufacturer',
    'Producer',
    'Wholesaler',
    'Distributor',
    'Dealer',
    'Retailer'
  ];
  facilityList: any[] = [
    'Corporate Loan',
    'Letter of Credit',
    'Receivable Financing',
    'Structured Trade Finance',
    'Asset Finance',
    'Factoring',
    'Project Finance'
  ];
  erorManssa = false;
  countryUser: any;
  connectedUserId;
  typeOfEntities = ['SME', 'Corporate', 'Financial Institution', 'Government, Trade & Other Institutions'];
  financeFacilityTypeList: any[] = [
    'Guarantee',
    'Credit Insurance',
    'Trade finance',
    'Letter of Credit',
    'Project Finance',
    'Asset Finance',
    'Corporate Loan'
  ];
  isMansaChecked = false;
  institutionTypeSelected: any;
  financeCurrencyModel: any;
  fileList3: UploadFile[] = [];
  onMessageStart = false;

  // sending mail
  mailObject: any = {};

  /* the new dev in  Financing Opperunities*/

  countriesRigionsTo = data;

  countriesByFinance: any[] = [];
  institutionType: any[] = [];
  financeCurrenciesList: any[] = [];
  averageProcessingTime: any[] = [];

  arrayCountries: any[] = [];
  myRegionSelected: any[] = [];
  financeOpportunitiesToFilter: any[] = [];
  emptyList: any[] = [];


  selectedCountries: any[] = [];

  nbrSLice = 0;
  listdataSlice: any[] = [];


  allFinancesTypeSelected = false;

  allFinancesRequestersSelected = false;

  baseUrl = environment.baseUrl + '/company';

  initContactForm() {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      file: [null],
      receiver: [''],
      section: [''],
      accessFinance: ['']

    });
  }

  saveIntroduction(value) {
    this.introductionTitle = value;
    this.updateIntroduction(this.introductionTitle);
  }

  ngOnInit() {
    this.getUser();
    this.changeLanguage();
    this.getIntroduction();
    this.getAllData();
    this.myform = this.fb.group({
      country: '',
      financesType: '',
      typeOfInstitution: '',
      financeProvider: ''
    });
    this.getAllCountries();
    this.getAllFinanceTypes();
    this.getAllTypeOfInstitution();
    // this.getAllFinanceProvides();
    this.initContactForm();
    this.initRequestMeetForm();
    this.getAllFinanceRequester();
    this.searchByfinancingopperunities();
    this.role = this.userService.currentUser && this.userService.currentUser.role;
    console.log('ROLE', this.role);
  }


  showMeet() {
    console.log('click meet form');
    this.requestMeetvisible = true;
  }

  cancleMeet() {
    console.log('cancel meet form');
    this.requestMeetvisible = false;
  }

  initRequestMeetForm() {
    this.RequestMeetForm = this.fb.group({
      topic: ['', [Validators.required]],
      message: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],

    });
  }

  sendMail(object, file, item) {
    this.mailService.senMail2(object, file).subscribe(() => {
      item.isVisible1 = false;
      item.requestMeetvisible = false;
      this.toaster.success('mail has been successfully sent');
    }, (error) => {
      this.isVisible1 = false;
      this.requestMeetvisible = false;
      this.toaster.error('mail has not been sent');
    });
  }
  filterByFinancingopperunities() {
    console.log("in filter function")
    this.loading = true
    console.log("nzTreeComponent", this.nzTreeComponent)
    if (this.nzTreeComponent != undefined) {
      this.myRegionSelected = this.nzTreeComponent.getCheckedNodeList()
        .map((item) => {
          if (item.parentNode.key == "0-0") return item.origin.title;
        })
        .filter(
          (value, index, self) => self.indexOf(value) === index && !!value
        );

    }
    console.log("all eco and geo regions", this.myRegionSelected)

    let object = {
      finance_type: this.financesType,
      amount: this.financeValue,
      how: "lt",
      finance_requester: this.financeRequester,
      country: this.selectedCountries,
      region: this.myRegionSelected,
      institution_type: this.institutionTypeSelected,
      finance_currency: this.financeCurrencyModel,
      lower_amount_limit: null,
      upper_amount_limit: null
    }
    this.nbrSLice = 0
    console.log("filter with country ", object)

    this.financeOpportunitiesService
      .filterFinanceOpportunities(object)
      .subscribe((result: any) => {
        this.financeOpportunitiesToFilter = result

        if (this.financeOpportunitiesToFilter) {
          this.loading = false
        }

        console.log("result =======", this.financeOpportunitiesToFilter)

        if (this.financeOpportunitiesToFilter.length != 0) {
          this.financeOpportunitiesBase = this.financeOpportunitiesToFilter
          this.loading = false
        }

        if (this.financeOpportunitiesToFilter.length == 0) {
          this.financeOpportunitiesBase = this.emptyList
        }

        this.sliceData()

      })


  }
  cancelModel1() {
    this.isVisible1 = false;
    this.fileList3 = [];
  }
  cancelModel2() {
    this.requestMeetvisible = false;
    this.fileList3 = [];
  }
  submitFormRequestMeeting(value: any, item): void {
    // tslint:disable-next-line:forin
    for (const key in this.RequestMeetForm.controls) {
      this.RequestMeetForm.controls[key].markAsDirty();
      this.RequestMeetForm.controls[key].updateValueAndValidity();
    }
    console.log(value);
    if (this.RequestMeetForm.valid) {
      this.sendMail(value, this.fileList3, item);
    }
  }

  showContactNowForm(item: any) {
    console.log('item contact now :', item);
    const message = 'Dear Mr./Ms. ' + item.contact_name + ' ,\n' +
      '\n' +
      'I am writing to contact you in order to (purpose of your mail).\n' +
      '\n' +
      'Thank you,\n' +
      this.user.lastName;
    this.RequestMeetForm.patchValue({
      topic: 'no-replay',
      message,
      email: item.contact_email
    });
    item.isVisible1 = true;
  }


  changeLanguage(): void {
    this.i18n.setLocale(en_US);
  }
  getIntroduction() {
    this.financeOpportunitiesService.getTitleIntroduction().subscribe((res: any) => {
        console.log(res.text);
        this.introductionTitle = res.text;
      },
      (error) => console.log(error));
    console.log('fop title :', this.introductionTitle);
  }

  updateIntroduction(value: any) {
    this.introduction.id = 1;
    this.introduction.text = value;
    this.financeOpportunitiesService
      .updateTitle(this.introduction).subscribe(
      (res: any) => {
        this.introductionTitle = res.text;
      },
      (error) => console.log(error));
  }

  showRequestMeetingFrom(item: any) {
    const message = 'Dear [Mr./Ms. Last Name],\n' +
      '\n' +
      'I am writing to schedule a meeting to [write what the purpose of your meeting is]. If the time works for you, I would like to meet at [time] on [date] at [place].\n' +
      '\n' +
      'Please confirm whether that works for you or if another time and place is better. I look forward to our meeting.\n' +
      '\n' +
      'Thank you,\n' +
      '[Your name]';
    this.RequestMeetForm.patchValue({
      topic: 'Request for meeting',
      message,
      email: item.email
    });
    item.requestMeetvisible = true;
  }

  getAllData() {
    this.financeOpportunitiesService.getAllFinancingOpportunities()
      .subscribe(
        (res: any) => {
          this.opportunitie = res.content;
          this.total = res.totalElements;
          console.log('data content :', this.opportunitie);
          console.log('data total :', this.total);
        },
        (error) => console.log(error));
  }

  pageIndexChanged(event) {
    this.cretaria.size = this.pageSize;
    this.cretaria.page = event - 1;
    this.getFilterByPagination(this.cretaria);
    if (this.key.length !== 0) {
      this.search(this.cretaria);
    }
    console.log(event);
  }

  getFilterByPagination(cretaria) {
    this.key = '';
    this.financeOpportunitiesService.getFilterByPagination(this.cretaria).subscribe(
      (res: any) => {
        this.opportunitie = res.content;
        this.total = res.totalElements;
        console.log(this.opportunitie);
      },
      (error) => console.log(error));
  }

  search(value: string) {
    this.initCretaria();
    this.reset();
    this.key = value;
    this.cretaria.key = value;
    this.financeOpportunitiesService.searchBykey(this.cretaria).subscribe(
      (res: any) => {
        this.opportunitie = res.content;
        this.total = res.totalElements;
        console.log(this.opportunitie);
      },
      (error) => console.log(error));
  }

  private initCretaria() {
    for (const attr in this.cretaria) {
      if (!(attr === 'page' || attr === 'size')) {
        delete this.cretaria[attr];
      }
    }
  }

  changePageSize(value: number) {
    this.pageSize = value;
    this.cretaria.size = this.pageSize;
    this.getFilterByPagination(this.cretaria);
    console.log(this.pageSize);
  }

  reset() {
    this.pageIndex = 0;
    this.cretaria.page = this.pageIndex;
    this.cretaria.size = this.pageSize;
    this.opportunitie = [];
  }

  filter(value) {
    this.initCretaria();
    this.reset();
    if (value.country !== '') {
      this.cretaria.country = value.country;
    }
    if (value.financesType !== '') {
      this.cretaria.finance_type = value.financesType;
    }
    if (value.typeOfInstitution !== '') {
      this.cretaria.institution_type = value.typeOfInstitution;
    }
    if (value.financeProvider !== '') {
      this.cretaria.finance_provider = value.financeProvider;
    }
    this.getFilterByPagination(this.cretaria);
    console.log(this.myform.value);
  }


  getAllCountries() {
    this.financeOpportunitiesService.getAllCountries().subscribe((resp: any) => {
      this.countries = resp.sort((a, b) => (a.region > b.region) ? 1 : ((b.region > a.region) ? -1 : 0));

      // console.log("all countries",this.countries)


    });
  }


  getAllTypeOfInstitution() {
    this.financeOpportunitiesService.getInstitutionTypes().subscribe(
      (res: any[]) => {
        this.typeOfInstitutions = res;
      }
    );
  }

  // getAllFinanceProvides() {
  //     this.financeOpportunitiesService.getFinanceFacility().subscribe(
  //         (res: any[]) => {
  //             this.financeProviders = res;
  //         }
  //     )
  // }

  pageSizeChanged(event) {

  }


  checkUser(id: any) {
    this.userId = this.userService.currentUser && this.userService.currentUser.id;
    if (this.userId === id) {
      return true;
    } else {
      return false;
    }
  }

  handleOk1(): void {
    console.log('Button submit clicked!');
    this.isVisible1 = false;
  }

  contactNow(receiver: any, financeOpportunities: any) {
    // tslint:disable-next-line:forin
    for (const i in this.contactForm.controls) {
      this.contactForm.controls[i].markAsDirty();
      this.contactForm.controls[i].updateValueAndValidity();
    }

    this.contact.subject = this.contactForm.value.subject;
    // console.log("subject " + this.contact.subject);
    this.contact.message = this.contactForm.value.message;
    // console.log("message " + this.contact.message);
    this.contact.receiver = receiver;
    // console.log("Reciever ")
    // console.log(this.contact.receiver);
    // console.log("ID " + receiver.id)
    this.contact.section = 'FinancingOpportunities';
    this.contact.financingOpportunities = financeOpportunities;
    // console.log(this.contact.financingOpportunities)
    console.log('receier :', this.contact);
    this.contactService.contactNow(this.contact).subscribe(() => {
      // console.log("Contact")
      // console.log(data);





      // if (this.fileList.length > 0) {
      //     console.log('file selected!!!!!!!!!')
      //     this.idContact = data.id;
      //     this.handleUpload(this.idContact);
      // }


      this.toaster.success(
        'Message Sent Successfully',
        'Success',
        {
          closeButton: true,
          timeOut: 4000
        });


    });
    this.initContactForm();
    this.contactForm.reset();

  }

  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  }

  handleUpload(id: any) {
    const formData = new FormData();
    this.fileList.forEach((file: any) => {
      formData.append('file', file);
    });
    this.uploading = true;
    this.contactService.uploadFile(id, formData)
      .subscribe(
        () => {
          this.uploading = false;
          this.fileList = [];

          console.log('My File');
          console.log(this.contact.file);

          this.msg.success('upload successfully.');


          this.fileToUpload = null;
          this.fileSelected = false;
        },
        () => {
          this.uploading = false;
          this.msg.error('upload failed.');
        }
      );
  }

  // goToLiveChat(data) {

  //     let object = {
  //         name: data.finance_requester,
  //         country: data.country,
  //         website: data.website,
  //         phone: data.contact_phone,
  //         flag: data.country_iso3
  //     }

  //     console.log("---------- object  --------- : ", object);
  //     console.log("current user ; ", this.user);

  //     this.liveChatService.getRoomId(object).subscribe((res: any) => {
  //         let roomId;
  //         console.log("room Id ", res);
  //         console.log("current user ; ", this.user);
  //         if (this.userId == res.userA.id) {
  //             roomId = res.id
  //         }

  //         this.router.navigate(['/myProfile/livechat/'], { queryParams: { roomId: roomId } })
  //     })
  // }

  getAllFinanceTypes() {
    this.financeOpportunitiesService.getFinanceType()
      .subscribe((res: any[]) => {
        this.financesTypes = res;


      });

  }

  getAllFinanceRequester() {
    this.financeOpportunitiesService.getFinanceRequester()
      .subscribe((res: any[]) => {
        this.financesRequesters = res;
        //    console.log("financesRequester", res);

      });
    //   console.log("financesRequester", this.financesRequesters);
  }

  amountChanged(value) {
    console.log(value);
  }

  getCountry(countries: any[]) {
    console.log('getCountry liste countries ', countries);
    let i = 0;
    countries.forEach((item) => {
      const key = '0-0-%';
      const node = {
        title: item,
        value: key.replace('%', '' + i),
        key: item,
        isLeaf: true,
      };
      //  this.countriesRigionsTo[0].children.push(node);

      i++;
    });
  }

  searchByfinancingopperunities() {
    this.loading = true;

    if (this.financeRequester[0] == 'all') {
      this.financeRequester = [];
    }
    if (this.financesType[0] == 'all') {
      this.financesType = [];
    }
    const object = {
      finance_type: this.financesType,
      amount: this.financeValue,
      how: this.amountOperator,
      finance_requester: this.financeRequester
    };

    console.log('search data ', object);
    this.financeOpportunitiesService.searchFinanceOpportunities(object)
      .subscribe((data: any[]) => {
        console.table(data);
        this.financeOpportunitiesBase = data;
        if (this.financeOpportunitiesBase) {
          this.loading = false;
        }
        this.financeOpportunitiesToFilter = data;
        // country list
        //  this.countriesRigionsTo[0].children = []
        this.countriesByFinance = this.financeOpportunitiesToFilter
          .map((item) => item.country)
          .filter((value, index, self) => self.indexOf(value) === index);


        if (this.countriesByFinance.length != 0) {
          this.getCountry(this.countriesByFinance);
        }
        // Region List
        // this.countriesRigionsTo[1].children = []
        this.regions = this.financeOpportunitiesToFilter.map((item) => item.regions);
        this.newRegion = [].concat
          .apply([], this.regions)
          .filter((value, index, self) => self.indexOf(value) === index);

        if (this.newRegion != null) {
          this.getRegion(this.newRegion);
        }
        // InstitutionType
        this.institutionType = this.financeOpportunitiesToFilter
          .map((item) => item.type_institution)
          .filter((value, index, self) => self.indexOf(value) === index);
        this.newArry2 = [].concat
          .apply([], this.institutionType)
          .filter((value, index, self) => self.indexOf(value) === index);

        // currency_of_finance
        this.financeCurrenciesList = this.financeOpportunitiesToFilter
          .map((item) => item.currency_of_finance)
          .filter((value, index, self) => self.indexOf(value) === index);
        this.averageProcessingTime = this.financeOpportunitiesToFilter
          .map((item) => item.average_processing_time)
          .filter((value, index, self) => self.indexOf(value) === index);

        // init first result searchfinanceCurrenciesList
        this.financeResult = data;
        this.sliceData();
      });


  }

  onScroll() {
    console.log('scrolled!!');
    this.sliceData();
  }


  bottomReached(): boolean {
    // return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
    return (window.innerHeight + window.scrollY) >= document.body.scrollHeight;
  }


  sliceData() {
    this.nbrSLice = this.nbrSLice + 4;
    this.listdataSlice = this.financeOpportunitiesBase.slice(0, this.nbrSLice);
    console.log('list Item : ', this.financeOpportunitiesService);
    console.log('listdataSlice : ', this.listdataSlice);
  }
  changePhone() {
    this.form.controls.contactPhone.markAsDirty();
    this.form.controls.contactPhone.updateValueAndValidity();
  }
  getRegion(regions: any[]) {
    console.log('get Region liste region ', regions);
    let i = 0;
    this.newRegion.forEach((item) => {
      const key = '0-1-%';
      const node = {
        title: item,
        value: key.replace('%', '' + i),
        key: item,
        // key: element.code,
        isLeaf: true,
      };
      //   this.countriesRigionsTo[1].children.push(node);
      i++;
    });


  }

  getFlag(isoCode: any): boolean {
    this.allcountries.forEach((element) => {
      if (element.isocode == isoCode) {
        this.flagUrl = element.flag;
      }
    });
    return true;
  }


  loadFinanceResult(itemResult: any) {
    this.financeService.item = itemResult;
    console.log('item details opp :', itemResult);
  }


  handleOk(): void {
    console.log('Button submit clicked!');
    this.isVisible = false;
  }

  countryChange(country, userCountry) {
    country = this.form.value.country ? this.form.value.country : userCountry;
    if (country != null) {
      Object.entries(this.CountryISO).forEach(([key, value]) => {
        if (key.toLowerCase() == ((country.label) ? country.label : userCountry as String).split(' ').join('').toLowerCase()) {
          this.selectedISO = value;
        }
      });
      // this.getAdresse(country.label,'','');
    }
    console.log(this.selectedISO);
  }
  showModal(): void {
    this.countryChange('', this.countryUser);
    this.isVisible = true;
  }
  showEditModal(): void {
    this.isVisible = true;
  }


  selectChangeMode() {
    this.checkDisaledRang = false;
    // console.log("rang",this.isCheckedButton)
    if (this.isCheckedButton == true) {
      this.disabled = true;
      this.checkDisaledRang = true;
    } else {

      this.disabled = false;
    }
    // console.log(this.disabled);
  }


  btnFilter(value) {
    if (value == 'lt') {
      this.SupColor = '#fec537';
      this.InfColor = '#0591ff';
      this.Egcolor = '#fec537';
      this.amountOperator = value;
    } else if (value == 'eq') {
      this.SupColor = '#fec537';
      this.InfColor = '#fec537';
      this.Egcolor = '#0591ff';
      this.amountOperator = value;
    } else if (value == 'gt') {
      this.SupColor = '#0591ff';
      this.InfColor = '#fec537';
      this.Egcolor = '#fec537';
      this.amountOperator = value;
    }

  }

  financesTypeChange(value) {
    this.allFinancesTypeSelected = false;
    //  console.log("value",value)
    value.forEach(element => {
      if (element == 'all') {
        this.financesType = [];
        this.allFinancesTypeSelected = true;
        this.financesType = ['all'];
      }
    });

  }

  financeRequesterChange(value) {
    this.allFinancesRequestersSelected = false;
    value.forEach(element => {
      if (element == 'all') {
        this.financeRequester = [];
        this.allFinancesRequestersSelected = true;
        this.financeRequester = ['all'];
      }
    });


  }



  changeCompanyName(value) {
    if (value != '') {
      console.log('change value', value);
      // this.validateForm.controls.company = value
      this.getCompanyByName(value);
    }
    return value;
  }

  getCompanyByName(value) {
    if (this.role[0] != 'ROLE_PROVIDER') {
      console.log('companyName', this.form.controls.country.value);
      if (value != null) {
        console.log('provider in', );
        this.subscriptionService.getCompanyName(value).subscribe((resp: any) => {
          this.companyNames = resp;
          if (this.companyNames.length > 0) {
            this.selectedCompany = this.companyNames[0];
          }

          //
          console.log('companyNames', this.selectedCompany);
        });
      }
    } else {
      console.log('companyName else :', this.form.controls.country.value);
      if (value != null) {
        console.log('provider in', );
        this.selectedCompany = value;
        // this.subscriptionService.getCompanyName(value.name).subscribe((resp: any) => {
        //     this.companyNames = resp;
        //     if (this.companyNames.length > 0) {
        //         this.selectedCompany = this.companyNames[0];
        //     }else{
        //         this.selectedCompany= this.companyNames[0];
        //         this.companyNames=this.selectedCompany;
        //     }

        //     //
        //     console.log("companyNames from elese :", this.selectedCompany);
        // })
      }
      console.log(' base de donne company mapping', this.selectedCompany);
      this.companyNames.push(this.selectedCompany);
    }
  }

  getCountryByIsoCode(isoCode) {
    return this.allcountries.filter((item: any) => item.isocode == isoCode)[0];
  }
  getNatureBusinessActivity(value) {
    console.log('getNatureBusinessActivity', this.natureBuissnes.filter((item: any) => item.nature_business_activity.toLowerCase().includes(value.toLowerCase()))[0]);
    return this.natureBuissnes.filter((item: any) => item.nature_business_activity.toLowerCase().includes(value.toLowerCase()))[0];
  }

  private initCretariaCapture() {
    for (const attr in this.cretariaCapture) {
      if (!(attr === 'page' || attr === 'size')) {
        delete this.cretariaCapture[attr];
      }
    }
  }
  resetPage() {
    this.pageIndexCapture = 1;
    this.cretariaCapture.page = this.pageIndexCapture;
    this.cretariaCapture.size = this.pageSizeCapture;
  }
  gotLoink() {
    this.router.navigateByUrl('/myProfile/company/post-company');
  }
  getUser() {
    this.userId = this.userService.currentUser && this.userService.currentUser.id;
    this.userService.findById(this.userId).subscribe((user: any) => {
      this.user = user;
    });

    console.log('user company :', this.user);
    console.log('company id :', this.idCompany);
  }

  mansaIdError(value) {
    if (value === '') {
      this.form.get('mansaId')!.setValidators(Validators.required);
      this.erorManssa = true;
    } else {
      this.form.get('mansaId')!.setValidators(Validators.minLength(20));
    }
  }
  handleCancelModalFact(): void {
    this.modalFactVisibile = false;
  }
  getFlagForCountrySelect(labelCountry: any): boolean {
    // console.log("country input :",labelCountry);
    this.allcountries.forEach((element) => {
      if (element.label == labelCountry) {
        this.flagUrlForFilter = element.flag;
      }
    });
    return true;
  }
  mansaRemoveRequired() {
    if (this.isMansaChecked == true) {
      this.form.patchValue({
        mansaId: 'None'
      });
    } else {
      this.form.patchValue({
        mansaId: null
      });
    }
  }
  userMansaIDValidator(value) {
    console.log('value mansa');
    if (value && value !== 'None' ) {
      this.userService.validateMansaId(value).subscribe((res: any) => {
        console.log('res', res);
        if (res.Status === 'Not Found') {
          this.form.get('mansaId').setErrors({MansaIDExists: true});
        }
        if ( res.Status === 'Valid' && this.validateMansaCompany(res['Company Name'])) {
          this.form.get('mansaId').setErrors({MansaCompanyName: true});
        }
      });
    } else {
      this.form.get('mansaId')!.clearValidators();
    }
  }
  validateMansaCompany(companyName: string): boolean {
    console.log('company name mansa ', companyName);
    if (typeof this.form.value.company === 'string') {
      return companyName.toLowerCase() !== this.form.value.company.toLowerCase() ? true : false;
    } else {
      return companyName.toLowerCase() !== this.form.value.company.name.toLowerCase() ? true : false;
    }
  }

  getUrlImg(name) {
    return this.baseUrl + '/downloadImage/' + name + '.jpg';
  }

  onImgError(event) {
    event.target.src = '/assets/img/Mask_group.png';
    event.onerror = null;
  }

  beforeUpload2 = (file: UploadFile): boolean => {
    this.fileList3 = this.fileList3.concat(file);

    return false;
  }


}

