import { Component, OnInit } from '@angular/core';
import { TradeshowsService } from 'src/app/commons/services/tradeshows/tradeshows.service';

@Component({
  selector: 'app-trade-shows',
  templateUrl: './trade-shows.component.html',
  styleUrls: ['./trade-shows.component.css']
})
export class TradeShowsComponent implements OnInit {

  events:any[]=[];
  total_records:number=0;
  constructor(private tradeshowsService:TradeshowsService) { }

  ngOnInit() {
    this.getEventsList();
  }

  getEventsList(){
    this.tradeshowsService.getEvents().subscribe((resp: any) => {
      this.events=resp;
      this.total_records= this.events.length;
      console.log(resp);
    })
  }
}
