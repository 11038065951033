import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TradeValueChainService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getById(id: any) {
    return this.http.get(this.baseUrl + '/services/'+id);
  }
  getByCompany(company, name) {
    return this.http.get(this.baseUrl + '/services/getByCompany?company=' + company + '&company=' + name);
  }
  getByCategories(id) {
    return this.http.get(this.baseUrl + '/services/getByCategories/?category=' + id);
  }
  getServiceCategories() {
    return this.http.get(this.baseUrl + '/services/getServiceCategories');
  }
  serviceSearch(data: any) {
    return this.http.post(this.baseUrl + '/services/serviceSearch', data);
  }

}
