import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShowCaseService { 
  baseUrl = environment.baseUrl+'/showcase';

  constructor(private http: HttpClient) { }

  getCategories() {
    return this.http.get(this.baseUrl + '/myCategories');
  }

  
  getServiceCategories() {
    return this.http.get(this.baseUrl + '/myServicesCategories');
  }

  getImporterbyId(companyId){ 
    return this.http.get(this.baseUrl+'/getByBuyer/'+companyId );
  }
  
  searchImporter(body:any){ 
    return this.http.post(this.baseUrl + '/buyersSearch' ,body );
  }
  
}
