import { AnimationOptions } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products-by-main-category',
  templateUrl: './products-by-main-category.component.html',
  styleUrls: ['./products-by-main-category.component.css']
})
export class ProductsByMainCategoryComponent implements OnInit {

  baseUrl = environment.baseUrl +"/";
  public catName;
  public subCatName;
  public catId;
  isVisible = false;
  public messages: string = null;
  public categoriesList = [];
  public listfilterSub  = [];
  public certificationList = [];
  public countryList = [];
  public productDTOList = [];
  public productDTOShow = [];
  public productCount: number = 0;
  public MinOrder: number = null;
  public minPrice: number = null;
  public maxPrice: number = null;
  public selectProd;
  public isActive = false;
  public index = null;
  public listfilterCategorie = [];
  public listClassfication = [];
  hasClass = true;
  public listClass = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public loading: boolean = false;
  public listfilterClass = [];
  public selectedcurr ="USD - Dollar Americain";
  public options: AnimationOptions;
  public nbrSlice = 0;
  public nbrProduct = 9;
  public styles: Partial<CSSStyleDeclaration> = {
    width: '27%',
    margin: 'auto',
    height: '24%',
  };
  public role ;
  public companyName;

  public fileList: UploadFile[] = [];
  public listEmplty = true;
  subCategory;
  utilSettings = {
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 3550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 2250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

          }
        },
        {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  constructor( private route : ActivatedRoute, private b2bserviceService: B2bProductService,private msg: NzMessageService,
    private router:Router,
    private userService: UserService,
    private toaster: ToastrService) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.subCatName = data.subCatName;
      this.getClassification() ;
      this.listfilterCategorie=[];
      this.listfilterClass = [];
      this.listfilterSub = [this.subCatName];
       this.MinOrder= null;
        this.minPrice= null;
        this.maxPrice= null;
       this.listfiltercountry=[];
       this.listShowcountry=[];
      console.log("messages", this.messages);
      this.search();
      this.getUser();
    });
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log("user",res);
        this.companyName=res.subscription.companyName;
        this.role=res.role;
      })
  }
  }

  checkExist(subname) {
    if (this.listfilterClass.includes(subname)) {
      return true
    } else {
      return false
    }
  }
  getClassification() {
    this.b2bserviceService.getClassification(encodeURIComponent(this.subCatName)).subscribe((resp:any)=> {
      this.subCategory = resp;
      this.catName= resp.nameCategorie;
      this.listClass = resp.classification;
      if(this.listClass.length !==0){
        this.hasClass=true;
      }
      else{
        this.hasClass=false;
      }
      this.sliceCategorie();
    })

  }

  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listClassfication = this.listClass.slice(0, this.nbrSlice);
  }
  search() {
    let data = {
      page: "0",
      pageSize: "10",
      categoriesId: this.listfilterCategorie,
      search: this.messages,
      minOrder: this.MinOrder,
      fromPrice: this.minPrice,
      toPrice: this.maxPrice,
      countries: this.listfiltercountry,
      certifications: [],
      classification: this.listfilterClass,
      subCategories: this.listfilterSub,
      sortBy: "id",
      sort: "DESC"
    }
    this.loading = true;
    this.b2bserviceService.searchProduct(data).subscribe((resp: any) => {
      console.log(resp);
      this.countryList = resp.numberByCountryList;
      this.productDTOList = resp.productDTOList;
      this.productCount = this.productDTOList.length;
      this.slicepProduct();
      this.loading = false;
    },(err)=>{
      console.log(err);
      this.loading = false;
    })
  }
  addCategorie(classif) {
    if (!this.listfilterClass.includes(classif)) {
      this.listfilterClass.push(classif);
      console.log(this.listfilterClass);
    }
    else {
      let index: number = this.listfilterClass.indexOf(classif);
      if (index !== -1) {
        this.listfilterClass.splice(index, 1);
      }
    }
    this.search();
    console.log(this.listfilterClass);
  }
  slicepProduct() {

      this.nbrProduct = this.nbrProduct + 5;
    this.productDTOShow = this.productDTOList.slice(0, this.nbrProduct);


  }

  deleteCountry(idCountry,item){
    let index: number = this.listfiltercountry.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.listfiltercountry.splice(index, 1);
  }
  if (index2 !== -1) {
    this.listShowcountry.splice(index2, 1);
}
  this.search();
  console.log(this.listfiltercountry);
}

addCountry(idCountry,item) {
  if (!this.listfiltercountry.includes(idCountry)) {
    this.listfiltercountry.push(idCountry);
    this.listShowcountry.push(item)
    console.log(this.listfiltercountry);
  }
  this.search();
  console.log(this.listfiltercountry);
}

animationCreated(animationItem: AnimationItem) { }
@HostListener("window:scroll", [])
onScroll(): void {
  if (this.bottomReached()) {
     this.slicepProduct();    }
}

bottomReached(): boolean {
  return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
}

checkExistCountry(id) {
  if (this.listfiltercountry.includes(id)) {
    return true
  } else {
    return false
  }
}

handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isVisible = false;
}
handleChange({ file, fileList }): void {
  const status = file.status;
  if (status !== 'uploading') {
    console.log(file, fileList);
  }
  if (status === 'done') {
    this.msg.success(`${file.name} file uploaded successfully.`);
  } else if (status === 'error') {
    this.msg.error(`${file.name} file upload failed.`);
  }
}
beforeUploads = (file: UploadFile): boolean => {
  this.fileList = this.fileList.concat(file);

  this.fileList.forEach((file: any) => {
    let data= this.ValidateFile(file);
    if(!data) {
      this.fileList =[]
      this.listEmplty=true;
      return true;
    } else {
      this.listEmplty = false;
    }
  });
  return false;
};
ValidateFile(file){
 return true;
}
showModal(item): void {
  console.log("show");
  this.selectProd = item ;
  this.isVisible = true;
}

goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}
startOrder(item) {
  this.router.navigate(['/order/'], {queryParams:{product:item.id}})
}
addTointer(prod){
  let type = "product"
  this.b2bserviceService.addToInterest(prod.id,type).subscribe((resp: any) => {
    prod.isInterested =true;
    this.toaster.success(
      prod.productName+" has been added to your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
  })
}
removeFrominter(prod){
  let type = "product"
  this.b2bserviceService.removeInterest(prod.id,type).subscribe((resp: any) => {
    prod.isInterested =false;
    this.toaster.info(
      prod.productName+" has been removed from your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
  })
}

}
