import { Component, HostListener, OnInit } from '@angular/core';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';

@Component({
  selector: 'app-freight-services',
  templateUrl: './freight-services.component.html',
  styleUrls: ['./freight-services.component.css']
})
export class FreightServicesComponent implements OnInit {

  // public listCAtegories = ['Associations', 'Airlines', 'Cargo Handling Agents', 'Aviation Service Providers', 'Port', 'Airports', 'Airlines GSAs/GSSAs', 'Services/Suppliers to the Industry', 'Express Operators & Couriers', 'Cargo Agents/Freight Forwarders', 'Charter Brokers'];
  public listCAtegories = [];
  public listCAtegories1 = [];
  public listChart = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public listItem = [];
  public listdataSlice = [];

  public selectedCategories = [];
  public selectedChart = null;
  public company = null;
  public nbrSLice = 0;
  public nbrSLice1 = 0;
  public loading = false;
  isVisible = false;
  public reciver   = null;
  public styles: Partial<CSSStyleDeclaration> = {
    width: '27%',
    margin: 'auto',
    height: '24%',
  };
  public listfiltercountry = [];
  public listShowcountry = [];
  public listShowcountryLabel = [];
  public countryList = [];
  constructor(private freightService: FreightService) {
  }

  ngOnInit() {
    this.search();

    // this.sliceCategorie();
  }



  selectedSort(item) {
    if (this.selectedChart == item) {
      this.selectedChart = null
      this.search();

    }
    else {
      this.selectedChart = item;
      this.search();

    }
  }
  sliceData() {
    this.nbrSLice = this.nbrSLice + 8;
    this.listdataSlice = this.listItem.slice(0, this.nbrSLice);
    console.log("list Item : ",this.listItem)
    console.log("listdataSlice : ",this.listdataSlice)
  }
  checkExist(id) {
    if (this.selectedCategories.includes(id)) {
      return true
    } else {
      return false
    }
  }
  addCategorie(item) {
    if (!this.selectedCategories.includes(item)) {
      this.selectedCategories.push(item);
      this.search();
    }
    else {
      let index: number = this.selectedCategories.indexOf(item);
      if (index !== -1) {
        this.selectedCategories.splice(index, 1);
        this.search();
      }
    }

  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      //  console.log('Load Your Data Here');
      this.sliceData();
    }
  }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }
  clear(){
    console.log("before",this.selectedCategories)
    this.selectedCategories =[];
    this.listCAtegories1 =[];
    console.log("after",this.selectedCategories)
    this.listfiltercountry =[];
    this.selectedChart =null;
    this.listShowcountry =[];
    this.company=null;
    this.listShowcountryLabel=null;
    this.search();
  }
  search() {
    this.loading = true;
    this.nbrSLice = 0;
    let data = {
      search: this.selectedCategories,
      sort: this.selectedChart,
      companyName: this.company,
      countries: this.listShowcountryLabel
    }
    console.log(data);

    this.freightService.searchFreight(data).subscribe((resp: any) => {
      this.listItem = resp.freigthServiceProviderList;
      this.countryList = resp.numberByCountries;
      this.listCAtegories = resp.freightTypes;

    console.log("===========>1111",this.listCAtegories)
    if(this.countryList!=null){
      this.countryList = this.countryList.filter(cnt => cnt.flag !== null);
    }
      this.sliceCategorie();
      this.sliceData();
      this.loading = false;
    }, (err) => {
      console.log(err);

    })
  }
  sliceCategorie() {
    this.nbrSLice1 = this.nbrSLice1 + 4;
    this.listCAtegories1 = this.listCAtegories.slice(0, this.nbrSLice1);
    console.log("===========>222",this.nbrSLice1)
    console.log("===========>2222",this.listCAtegories)
    console.log("===========>2222",this.listCAtegories1)
  }
  deleteCountry(idCountry,item){
    let index: number = this.listfiltercountry.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.listfiltercountry.splice(index, 1);
  }
  if (index2 !== -1) {
    this.listShowcountry.splice(index2, 1);
    this.listShowcountryLabel.splice(index2, 1);
}
  this.search();
  console.log(this.listfiltercountry);
}

checkExistCountry(id) {
  if (this.listfiltercountry.includes(id)) {
    return true
  } else {
    return false
  }
}
getAllCountries() {
  this.freightService.getcountries().subscribe((resp: any) => {
    this.countryList = resp
    this.countryList = this.countryList.filter(cnt => cnt !== null);
  })
}
addCountry(idCountry,item) {
  if (!this.listfiltercountry.includes(idCountry)) {
    this.listfiltercountry.push(idCountry);
    this.listShowcountry.push(item)
    this.listShowcountryLabel.push(item.country)
    console.log(this.listfiltercountry);
  }
  this.search();
  console.log(this.listfiltercountry);
}

handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isVisible = false;
}
showModal(item): void {
  this.reciver= item
  this.isVisible = true;
}

}
