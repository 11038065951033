import { Injectable } from "@angular/core";
import { AdminService } from "src/app/commons/services/dashboard-admin/admin.service";
import { InvoiceService } from "../../services/invoice.service/invoice.service";
import { arrayTemplate } from "../templates/arrayTemplate";
import { discountPaymentTemplate } from "../templates/discountTemplate";
import { evoucherPaymentTemplate } from "../templates/evoucherPayment";
import { offlinePaymentTemplate } from "../templates/offlinePayment";
import { onlinePaymentTemplate } from "../templates/onlinePayment";

export enum PdfTemplates {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  EVOUCHER = "EVOUCHER",
  DISCOUNT = "DISCOUNT",
}

@Injectable({
  providedIn: "root",
})
export class PdfService {
  pdfMake: any;

  constructor(
    private invoiceService: InvoiceService,
    private admin: AdminService
  ) {}

  /**
   * Asynchronous Library Loader for PDF
   */
  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import("pdfmake/build/pdfmake");
      const pdfFontsModule = await import("pdfmake/build/vfs_fonts");
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  /**
   * Downloads a PDF based on a chosen template
   *
   * @param template Enum of templates that will be used in the downloaded PDF
   */
  async downloadPdf(template: PdfTemplates, downloadPermission = true) {
    await this.loadPdfMaker();
    const plan = this.invoiceService.plan;
    const businessRegistration = this.invoiceService.businessRegistration;
    const offlinePayment = this.invoiceService.offlinePayment;

    let docDefinition: any;
    let pdfDoc: any;
    let readFilePromise = () => {};

    switch (template) {
      case PdfTemplates.ONLINE:
        docDefinition = onlinePaymentTemplate(
          businessRegistration,
          offlinePayment,
          plan
        );
        pdfDoc = this.pdfMake.createPdf(docDefinition);
        if (downloadPermission === true)
          pdfDoc.download("B2B Subscription Receipt.pdf");

        readFilePromise = () => {
          return new Promise((resolve, reject) => {
            pdfDoc.getBlob((blob: Blob) => {
              const file: File = new File(
                [blob],
                "B2B Subscription Receipt.pdf",
                { type: "application/pdf" }
              );
              resolve(file);
            });
          });
        };

        return { file: await readFilePromise(), reference: "" };

      case PdfTemplates.OFFLINE:
        const { display, reference } = offlinePaymentTemplate(
          businessRegistration,
          offlinePayment,
          plan
        );
        pdfDoc = this.pdfMake.createPdf(display);
        if (downloadPermission == true)
          pdfDoc.download("B2B Subscription Receipt.pdf");

        readFilePromise = () => {
          return new Promise((resolve, reject) => {
            pdfDoc.getBlob((blob: Blob) => {
              const file: File = new File(
                [blob],
                "B2B Subscription Receipt.pdf",
                { type: "application/pdf" }
              );
              resolve(file);
            });
          });
        };

        return { file: await readFilePromise(), reference };

      case PdfTemplates.EVOUCHER:
        docDefinition = evoucherPaymentTemplate(
          businessRegistration,
          offlinePayment,
          plan
        );
        pdfDoc = this.pdfMake.createPdf(docDefinition);
        if (downloadPermission == true)
          pdfDoc.download("B2B Subscription Receipt.pdf");

        readFilePromise = () => {
          return new Promise((resolve, reject) => {
            pdfDoc.getBlob((blob: Blob) => {
              const file: File = new File(
                [blob],
                "B2B Subscription Receipt.pdf",
                { type: "application/pdf" }
              );
              resolve(file);
            });
          });
        };

        return { file: await readFilePromise(), reference: "" };

      case PdfTemplates.DISCOUNT:
        docDefinition = discountPaymentTemplate(
          businessRegistration,
          offlinePayment,
          plan
        );
        pdfDoc = this.pdfMake.createPdf(docDefinition);
        if (downloadPermission == true)
          pdfDoc.download("B2B Subscription Receipt.pdf");

        readFilePromise = () => {
          return new Promise((resolve, reject) => {
            pdfDoc.getBlob((blob: Blob) => {
              const file: File = new File(
                [blob],
                "B2B Subscription Receipt.pdf",
                { type: "application/pdf" }
              );
              resolve(file);
            });
          });
        };

        return { file: await readFilePromise(), reference: "" };
    }
  }

  /**
   * Export an array of objects to PDF
   *
   * @param filename file name to be displayed on download
   * @param title PDF title that will be shown on the top of the table
   * @param array array of objects to be displayed in PDF after removing unwanted keys
   * @param keys `OPTIONAL` array of keys that won't be displayed in the PDF
   */
  async array2Pdf(filename: string, array: any[], keys?: string[]) {
    await this.loadPdfMaker();

    const nestedKeys = Object.keys({ ...array[0] });

    array.map((row) => {
      nestedKeys.map((p) => {
        if (typeof row[p] === "object") {
          delete row[p];
        }
      });
    });

    if (keys) {
      keys.map((p) => {
        array = array.map(({ [p]: _, ...keep }) => keep);
      });
    }
    this.pdfMake
      .createPdf(arrayTemplate(filename, array))
      .download(`${filename}.pdf`);
  }
}
