import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PayementService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    this.http = http;
  }

  getStatus() {
    return this.http.get(this.baseUrl + "/status/check");
  }

  charge(handleRequest: any, token: any) {
    console.log("aaaaaa");
    console.log(token);

    if (token == null || token == "") {
      const headers = new HttpHeaders({ "Content-Type": "application/json" });
      const options = { headers };
      return this.http.post(
        this.baseUrl + "/payment/charge",
        handleRequest,
        options
      );
    } else {
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      const options = { headers };
      return this.http.post(
        this.baseUrl + "/payment/charge",
        handleRequest,
        options
      );
    }
    /*    console.log(headers)
    return this.http.post(this.baseUrl+'/payment/charge',handleRequest);
     */
  }

  //  eVoucherPayement(EvoucherPaymentHandelRequest: any, token: any) {

  //   let headers;
  //   if (token == null) {
  //     console.log('Evoucher token null');
  //     headers = new HttpHeaders({ 'Content-Type': 'application/json'});
  //    } else {
  //     console.log('Evoucher token not null');
  //     headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
  //   }

  //   return this.http.post(this.baseUrl + '/payment/evoucherPayment', EvoucherPaymentHandelRequest);
  //  }

  eVoucherPayement(file: any, EvoucherPaymentHandelRequest: any, token: any) {
    const formData = new FormData();
    let headers;
    if (token == null) {
      headers = new HttpHeaders({ "Content-Type": "multipart/form-data" });
      formData.append("file", file, file.name);
    } else {
      headers = new HttpHeaders({
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
      formData.append("file", file, file.name);
    }

    return this.http.post(
      this.baseUrl +
        "/payment/evoucherPayment?handelRequest=" +
        encodeURIComponent(JSON.stringify(EvoucherPaymentHandelRequest)),
      formData
    );
  }

  /*   eVoucherPayement(EvoucherPaymentHandelRequest:any){

          return this.http.post(this.baseUrl+'/payment/evoucherPayment',EvoucherPaymentHandelRequest)
   }
    */
  /**
   * mpgs get checkout session id
   * retun void
   **/
  // this api
  getCheckoutSessionId(orderId, amount, returnUrl) {
    return this.http.get(
      this.baseUrl +
        "/payment/mpgs/checkoutseesionid?orderId=" +
        orderId +
        "&amount=" +
        amount +
        "&returnUrl=" +
        returnUrl
    );
  }
  masterdcardPay(orderId: string, obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    console.log("service called ", orderId, obj, httpOptions);
    return this.http.post(
      this.baseUrl + "/payment/mpgs/charge?orderId=" + orderId,
      obj
    );
  }
  directPay(obj: any, orderId, transactionId) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      this.baseUrl +
        "/payment/mpgs/directPayment?orderId=" +
        orderId +
        "&transactionId=" +
        transactionId,
      obj
    );
  }
  passCreditCardPayment(sessionId, orderId) {
    let formData = new FormData();
    formData.append("sessionId", sessionId);
    formData.append("orderId", orderId);
    return this.http.put(this.baseUrl + "/payment/credit_card/pay", formData);
  }
  discountpayment(file: any, DiscountPaymentHandelRequest: any, token: any) {
    const formData = new FormData();
    let headers;
    if (token == null) {
      headers = new HttpHeaders({ "Content-Type": "multipart/form-data" });
      formData.append("file", file, file.name);
    } else {
      headers = new HttpHeaders({
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
      formData.append("file", file, file.name);
    }

    return this.http.post(
      this.baseUrl +
        "/payment/promoGiftPayment?handelRequest=" +
        encodeURIComponent(JSON.stringify(DiscountPaymentHandelRequest)),
      formData
    );
  }

  onlinePayment(file: any, OfflinePaymentHandelRequest: any, token: any) {
    let headers;
    const formData = new FormData();
    if (token == null) {
      headers = new HttpHeaders({ "Content-Type": "multipart/form-data" });
      formData.append("file", file, file.name || "");
    } else {
      headers = new HttpHeaders({
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
      formData.append("file", file, file.name);
    }

    //   {
    //     "email": "liwaxora@gmail.com",
    //     "cost": 361.20000000000005,
    //     "pricingPlanId": 1,
    //     "bankId": 2,
    //     "planFrequancy": "MONTH",
    //     "currency": "USD",
    //     "planningKey": "A233EZEZ3"
    // }
    return this.http.post(
      this.baseUrl +
        "/payment/onlinePayment?handelRequest=" +
        encodeURIComponent(JSON.stringify(OfflinePaymentHandelRequest)),
      formData
    );
  }
}
