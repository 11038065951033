import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { KeyFeature } from "src/app/commons/models/KeyFeature";
import { PermissionService } from "../services/permission.service";

@Directive({
  selector: "[appCheckPermission]",
})
export class CheckPermissionDirective {
  @Input() set appCheckPermission(permissions: KeyFeature[]) {
    if (this.permissionService.checkPermission(permissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      console.log("permissions",permissions);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private permissionService: PermissionService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
}
