import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzCarouselComponent } from 'ng-zorro-antd';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.css']
})
export class FeaturedProductsComponent implements OnInit {
  @ViewChild('slickModal1', { static: false }) slickModal1: SlickCarouselComponent;
  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;
  public featuredProds = [];
  public hovered = false;
  baseUrl = environment.baseUrl + '/';
  effect = 'scrollx';
  utilSettings = {
    arrows: false,
    autoplay: true,
    speed:1000,
    autoplaySpeed: 2000,
    // slidesToShow: 4,
    slidesPerRow: 2,
    rows: 2,
    // slidesToScroll: 4,
    draggable:true,
    dots: false,
    infinite: true,
    // responsive: [
    //   {
    //     breakpoint: 3550,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 1,

    //     }
    //   },
    //   {
    //     breakpoint: 2250,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 1,

    //     }
    //   },
    //   {
    //     breakpoint: 1440,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 1,

    //     }
    //   },
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,

    //       }
    //     },
    //     {
    //         breakpoint: 800,
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1
    //         }
    //       },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: 4,
    //         slidesToScroll: 1
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 4,
    //         slidesToScroll: 1
    //       }
    //     }
    //   ]
  };
  constructor(private router: Router,private b2bserviceService: B2bProductService) { }

  ngOnInit() {
    this.search();
  }

  slickInit(e) {
    console.log('slick initialized');
  }
  openProdInNewWindow(prod) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/Product/${prod.id}`])
    );

    window.open(url, '_blank');
  }
  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  pre(){
  this.myCarousel.pre();
  }
 next(){
  this.myCarousel.next();
  }
  next1() {
    this.slickModal1.slickNext();
  }

  prev1() {
    this.slickModal1.slickPrev();
  }
  search() {
    let data = {
      page: "0",
      pageSize: "10",
      certifications: [],
      sortBy: "id",
      sort: "DESC",
      isFeatured: true
    }
    this.b2bserviceService.searchProduct(data).subscribe((resp: any) => {
      console.log(resp);
      this.featuredProds=resp.productDTOList;
      this.featuredProds = this.featuredProds.sort(() => 0.5 - Math.random());
    },(err)=>{
      console.log(err);
    })
  }
  goToLiveChat(item) {
    console.log("---------- item  --------- : " , item);
    this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
  }
}
