import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }
 getAllAccounts () {
    return this.http.get(this.baseUrl + '/banks');
  }
  getAllAccountById (id:any) {
    return this.http.get(this.baseUrl + '/banks'+id);
  }
  addBankAccount (body:any) {
    return this.http.post(this.baseUrl + '/banks',body);
  }
  editBankAccount (body:any,id:any) {
    return this.http.put(this.baseUrl + '/banks/'+id,body);
  }
  deleteBankAccount (id:any) {
    return this.http.delete(this.baseUrl+'/banks/'+id);
  }
  getOfflinePayment(){
    return this.http.get(this.baseUrl+'/banks/activated');
  }
  getOfflinePaymentUsers(){
    return this.http.get(this.baseUrl +'/payment/allOfflinePaymentUsers');
  }
  validateOfflinePaymentUser(paymentId,isValid){
    return this.http.put(this.baseUrl + '/payment/validate?paymentId=' + paymentId + '&isValid=' + isValid,null);
  }



  offlinePayment(file: any, OfflinePaymentHandelRequest: any, token: any) {
    const formData = new FormData();

    formData.append("file", file, file.name);
    formData.append("handelRequest", JSON.stringify(OfflinePaymentHandelRequest));

    return this.http.post(this.baseUrl + '/payment/offlinePayment', formData);
  }
  // offlinePayment(file:any, OfflinePaymentHandelRequest:any,token:any){
  //   let headers
  //   const formData = new FormData();
  //   if(token == null){
  //     console.log("Offline token null")
  //     headers = new HttpHeaders({ 'Content-Type': 'application/json'});
  //     formData.append("file", file, file.name);
  //    }
  //   else{
  //     console.log("Offline token not null")
  //   headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Bearer ${token}` });
  //   formData.append("file", file, file.name);
  //   }
  //   return this.http.post(this.baseUrl+'/payment/offlinePayment',OfflinePaymentHandelRequest);
  // }

  // offlinePayment(file: any, OfflinePaymentHandelRequest: any, token: any) {
  //   let headers;
  //   const formData = new FormData();
  //   if (token == null) {
  //     headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  //     formData.append("file", file, file.name);
  //   }
  //   else {
  //     headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` });
  //     formData.append("file", file, file.name);
  //   }

  // //   {
  // //     "email": "liwaxora@gmail.com",
  // //     "cost": 361.20000000000005,
  // //     "pricingPlanId": 1,
  // //     "bankId": 2,
  // //     "planFrequancy": "MONTH",
  // //     "currency": "USD",
  // //     "planningKey": "A233EZEZ3"
  // // }
  //   return this.http.post(this.baseUrl + '/payment/offlinePayment?handelRequest=' + encodeURIComponent(JSON.stringify(OfflinePaymentHandelRequest)), formData);
  // }

  getOfflinePaymentUsersB2b() {
    return this.http.get(this.baseUrl + '/payment/b2b/allOfflinePaymentUsers');
  }

  validateOfflinePaymentUserB2b(paymentId, isValid) {
    return this.http.put(this.baseUrl + '/payment/b2b/validate?paymentId=' + paymentId + '&isValid=' + isValid, null);
  }


}
