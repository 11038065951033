import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
const jwt = require('jsonwebtoken');
import { HttpClient } from '@angular/common/http';
import {BuyRequestService} from '../../commons/services/service buy-request/buy-request.service';
import {TradeshowsService} from '../../commons/services/tradeshows/tradeshows.service';
import {SharedService} from '../../commons/shared service/shared-service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-trade-show',
  templateUrl: './trade-show.component.html',
  styleUrls: ['./trade-show.component.css']
})
export class TradeShowComponent implements OnInit {
  ZoomAppBaseUrl = environment.ZoomAppBaseUrl;
  apiKey = 'CZldDtqERieOrGx_XztDhQ'; // Your Zoom API Key
  events:any[]=[];
  apiSecret = 'LJmNj8qPj3VXbM6MXliJXgp3vwOCg5ExhcMR'; // Your Zoom API Secret
  total_records:number=0;
  eventName;
  effect = 'scrollx';
  listindustries:any[];
  date = null;
  constructor(private sharedService: SharedService,private datePipe: DatePipe,private b2bserviceService: B2bProductService,private tradeshowsService:TradeshowsService,private buyRequestService: BuyRequestService,public httpClient: HttpClient) { }

  ngOnInit() {
  //  this.getMeetingsList();
    this.subscribetosearch();
    this.getCategories();
    this.getEventsList();
  }
  subscribetosearch(){
    this.sharedService.sharedEvents.subscribe((result: any) => {
      this.events=result;
      this.total_records= this.events.length;
      window.scrollTo(0,document.body.scrollHeight);
      console.log(this.events);
    });
  }
  getCategories(){
    this.b2bserviceService.getAllCatAndClassification().subscribe((resp: any) => {
      this.listindustries=resp;
      console.log(this.listindustries);
    });
  }
  getEventsList(){
    this.tradeshowsService.getEvents().subscribe((resp: any) => {
      this.events=resp;
      this.total_records= this.events.length;
      console.log(resp);
    })
  }
  clear(){
  this.date = null;
  this.getEventsList();
  }
  getEventByIndustry(id){
    this.tradeshowsService.getByIndustry(id.id).subscribe((resp: any) => {
      this.events=resp;
      this.total_records= this.events.length;
      this.eventName=id.name;
      window.scrollTo(0,document.body.scrollHeight);
    })
  }
  getMeetingsList() {

    const payload = {
      iss: this.apiKey,
      exp: ((new Date()).getTime() + 500)
    };

    const zoomAccessToken = jwt.sign(payload, this.apiSecret);

    const request = {
      Token: zoomAccessToken,
    };
    this.httpClient.post(`${this.ZoomAppBaseUrl}zoomfeature/meetings/list`,request).toPromise().then((response: any) => {
      this.total_records=response.total_records;
      console.log(response);
      this.events=response.webinars
      //this.meetingNumber = response.id;
      //this.meetingPassWord = response.password;
      // this.joinUrl = response.join_url;
    }).catch((error) => {
      console.log(error)
    });
  }
  getEventByDate(date:Date){
    if(date){
    this.tradeshowsService.getByDate( this.datePipe.transform(date, 'yyyy-MM-dd')).subscribe((resp: any) => {
      console.log(resp);
      this.sharedService.nextEvents(resp);
    })}
    else{
      this.getEventsList();
    }
  }
}
