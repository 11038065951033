import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { OrderService } from 'src/app/commons/services/order-service/order.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ElementRef, ViewChild } from '@angular/core';
import { ReportService } from 'src/app/commons/services/report-service/report.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';


@Component({
  selector: 'app-stock-status',
  templateUrl: './stock-status.component.html',
  styleUrls: ['./stock-status.component.css']
})
export class StockStatusComponent implements OnInit, AfterViewInit {

  public user: any = [];
  public totalProds = 0;
  public approvedProds = 0;
  public rejectedProds = 0;
  public PendingProds = 0;
  public totalInq = 0;
  public webInq = 0;
  public buyLeadInq = 0;
  listOfDisplayData = [
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
  ];
  orders = [];
  public showPanel = true;
  page = 0;
  status = "";
  productName = "";
  date = "";
  public statusValues = ["TODAY", "THIS WEEK", "THIS MONTH", "THIS YEAR", "ALL"];
  public revenue = "THIS MONTH";
  public analytic = "THIS YEAR";
  public statusValue = "TODAY";
  public recentOrder = "THIS WEEK";
  public tracking = "THIS YEAR";
  stockTrending = [];
  latestUpdatedStock = [];
  stockSummary = [];
  productStatistics = [];

  constructor(private zone: NgZone, private orderService: OrderService, private reportService: ReportService,
    private profileRouteService : ProfileRouteService) { }
  ngAfterViewInit(): void {
    // this.initLineChart();
  }

  ngOnInit() {
    this.profileRouteService.changeRoute("stockStatus");
    this.getOrdersHystorySeller();
    this.getStockTrending();
    this.getStockSummary();
    this.getLatestUpdatedStock();
    this.getProductStatistics();
  }

  getStockTrending() {
    this.reportService.getStockTrending().subscribe((res: any) => {
      this.stockTrending = res;
      console.log("stockTrending : ", this.stockTrending);
    })
  }

  getStockSummary() {
    this.reportService.getStockReport().subscribe((res: any) => {
      this.stockSummary = res;
      console.log("stockSummary : ", this.stockSummary);
      this.initLineChart()
    })
  }

  getLatestUpdatedStock() {
    this.reportService.getLatestUpdatedStock().subscribe((res: any) => {
      this.latestUpdatedStock = res;
      console.log("latestUpdatedStock : ", this.latestUpdatedStock);
    })
  }

  getOrdersHystorySeller() {
    let dataToSend = {
      status: this.status,
      productName: this.productName,
      date: this.date,
      page: this.page,
      pageSize: 5,
      sortBy: "id",
      sort: "DESC"
    }

    this.orderService.getOrdersHystorySeller(dataToSend).subscribe(
      (res: any) => {
        console.log("result : ", res);
        this.orders = res.content
        // this is to help collapse elements
        this.addShownOrders();
      })
  }


  getProductStatistics() {
    this.reportService.getSumStockReport().subscribe((res: any) => {
      this.productStatistics = res;
      console.log("productStatistics : ", this.productStatistics);
      this.initPieChart();
    })
  }

  showColapse(index) {
    this.showPanel = !this.showPanel
    this.orders[index].isShown = !this.orders[index].isShown
  }
  pageIndexChanged(page) {
    console.log("page", page);
    // this.searchMessages()    
  }

  addShownOrders() {
    this.orders.forEach(element => {
      element.isShown = false;
    })
  }

  initLineChart() {
    this.zone.runOutsideAngular(() => {

      let chart = am4core.create("chartRevenue", am4charts.XYChart);
      // get unique values from array so we can make

      // chart.data = this.stockSummary;
      // Create axes
      // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      // categoryAxis.dataFields.category = "productName";
      // categoryAxis.renderer.minGridDistance = 50;
      // categoryAxis.renderer.grid.template.location = 0;
      // categoryAxis.startLocation = 0.5;
      // categoryAxis.endLocation = 0;
      // categoryAxis.renderer.labels.template.fontSize = 13;
      // categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      // categoryAxis.renderer.grid.template.disabled = true;


      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");

      // get Unique product Names
      let produactNames = this.stockSummary.map(item => item.productName)
      .filter((value, index, self) => self.indexOf(value) === index)
      
      const usersByLikes = this.stockSummary.map((value,index,innerArray )=> {
        innerArray.map(val  => { if(value.date == val.date) return value; })
        // if(value.date == value2.date) return value;
    })
      console.log("usersByLikes : " ,usersByLikes);
    
      for (var i = 0; i < produactNames.length; i++) {
        let name = produactNames[i];
        // get only values of specific productName and createSerie from it
        let stockValue = this.stockSummary.filter(word => word.productName == name);
        console.log("stockValue : " , i , " ", stockValue);
        
        createSeries(name,stockValue);
      }

      
      // Create series
      // var series = chart.series.push(new am4charts.LineSeries());
      // series.dataFields.valueY = "quantity";
      // series.dataFields.dateX = "date";
      // series.name = "ss";
      // series.strokeWidth = 1.5;
      // series.stroke = am4core.color("#55C492");
      // series.tensionX = 1;
      // series.fillOpacity = 0.7;
      // series.fill = am4core.color("#F0FBF6");

      // Create series
      function createSeries(name,stockValue) {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "quantity";
        series.dataFields.dateX = "date";
        series.name = name;

        let segment = series.segments.template;
        segment.interactionsEnabled = true;

        let hoverState = segment.states.create("hover");
        hoverState.properties.strokeWidth = 3;

        let dimmed = segment.states.create("dimmed");
        dimmed.properties.stroke = am4core.color("#dadada");


        let data = [];
        
        let value = Math.round(Math.random() * 100) + 100;
               
        for (var i = 1; i < stockValue.length; i++) {
          // console.log("stock value : ", i , stockValue );
          
          value += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 30 + i / 5);
          let dataItem = { date: new Date(stockValue[i].date) };
          dataItem["quantity"] = stockValue[i].quantity;
           data.push(dataItem); 
        }
        
        series.data = data;
        return series;
      }
  

      // // Create series
      // var series = chart.series.push(new am4charts.LineSeries());
      // series.dataFields.valueY = "outcome";
      // series.dataFields.categoryX = "date";
      // series.strokeWidth = 1.5;
      // series.tensionX = 0.77;
      // series.fill = am4core.color("#FFF0F4");
      // series.fillOpacity = 0.7;
      // series.stroke = am4core.color("#F07B95");

      // var range = valueAxis.createSeriesRange(series);
      // range.value = 0;
      // range.endValue = -1000;
      // range.contents.stroke = am4core.color("#FF0000");
      // range.contents.fill = range.contents.stroke;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.scrollable = true;
      
      // here you u will add the global count of product 1 serie
      
      // chart.legend.itemContainers.template.events.on("hit", function(ev) {
      //   // let name =ev.target.dataItem.name;
      //   // console.log("clicked on ",  ev.target.dataItem.name);
      //   console.log("chart series : " , chart.series);
      //   let name ="testing"
      //   if(name != "sum")
      //     createSumSerie(name);
      //   // let seriesColumn = ev.target.dataItem.dataContext.columns.template;
        
      // }, this);
      let that = this;
      function createSumSerie(name) {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "quantity";
        series.dataFields.dateX = "date";
        series.name = "sum";

        let segment = series.segments.template;
        segment.interactionsEnabled = true;

        let hoverState = segment.states.create("hover");
        hoverState.properties.strokeWidth = 3;

        let dimmed = segment.states.create("dimmed");
        dimmed.properties.stroke = am4core.color("#dadada");


        let data = [];
        
        let value = Math.round(Math.random() * 100) + 100;
               
        for (var i = 1; i < that.stockSummary.length; i++) {
          // console.log("stock value : ", i , stockValue );
          
          value += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 30 + i / 5);
          let dataItem = { date: new Date(that.stockSummary[i].date) };
          dataItem["quantity"] = that.stockSummary[i].quantity;
           data.push(dataItem); 
        }
        series.data = data;
        console.log(chart.series);
        
        return series;
      }
      // Add scrollbar
      // var scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();

    }
    )
  }

  initPieChart() {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("chartStatus", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 85;
    chart.legend.scrollable = true;

    chart.data = this.productStatistics;
    // chart.data = [
    //   {
    //     productName: "beans",
    //     quantity: 17,
    //     color: "#55C492"
    //   },F
    //   {
    //     productName: "samsung",
    //     quantity: 55,
    //     color: "#E59AAE"
    //   },
    //   {
    //     productName: "iphone",
    //     quantity: 11,
    //     color: "#B57DFF"
    //   },
    //   {
    //     productName: "others",
    //     quantity: 77,
    //   }
    // ];

    chart.innerRadius = am4core.percent(40);

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "quantity";
    series.dataFields.category = "productName";
    series.slices.template.stroke = am4core.color("#fff");
    series.innerRadius = 10;
    series.slices.template.fillOpacity = 0.5;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;

    // series.slices.template.fill = am4core.color("{series.color}")
    series.colors.list = [
      am4core.color("#33CC8D"),
      am4core.color("#2F69FF"),
      am4core.color("#E2EEFE"),
    ];

  }

}
