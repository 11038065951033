import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { NzCarouselComponent, UploadFile } from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-result-by-id',
  templateUrl: './result-by-id.component.html',
  styleUrls: ['./result-by-id.component.css']
})
export class ResultByIdComponent implements OnInit {
  public allProduct=[];
  public itemProduct2:any;
  loading=false;
  public fileList: UploadFile[] = [];
  public listEmplty = true;
  baseUrl = environment.baseUrl +"/";
  isVisible = false;
  public selectedProduct;
  public messageToSend = "";
  intrestsProds =[];
  interested ;
  requestId;
  email;

  public styles: Partial<CSSStyleDeclaration> = {
    width: '27%',
    margin: 'auto',
    height: '24%',
  };
  effect = 'fade';
  // @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;

  isVisible2 = false;
  Zoomimage;
  constructor(private b2bProductService : B2bProductService,private buyRequestService: BuyRequestService,
              private sharedService: SharedService,
              private mailService : MailService,
              private toaster : ToastrService,
              private router : Router,private userService: UserService) {

}
animationCreated(animationItem: AnimationItem) { }
  ngOnInit() {
    this.getSharedData();
    this.getUser();
    this.verifyInterest();

  }
  getSharedData(){
    this.sharedService.sharedSearchProductByID.subscribe((resp: any) => {
      this.getByID(resp);
      this.requestId=resp;
    });
  }

  getByID(id){
    this.buyRequestService.getProductsById(id).subscribe((resp: any) => {
      console.log(resp);
       this.itemProduct2=resp;
       this.getAllProductCategories(this.itemProduct2.categoryId);

    },
    (err) => {
      console.log(err);
    })
  }

  getAllProductCategories(id) {
    this.loading=true;
    this.buyRequestService.getProductsCategories(id).subscribe((resp: any) => {
      let data =resp;
      this.allProduct=data.reverse();
      this.allProduct = this.allProduct.filter( s => !( this.itemProduct2.id==s.id ) )
      this.loading=false;
    })
}
// pre(){
//   this.myCarousel.pre();
// }
// next(){
//   this.myCarousel.next();

// }

  showModal(item): void {
    console.log("here is the item : " ,item);

    this.selectedProduct = item;
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisible2 = false;
  }

  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.email = res.email
        this.intrestsProds= res.subscription.interestPostBuyRequestIds
        let prod =res.subscription.interestPostBuyRequestIds.find(x => x === this.requestId.toString())
        console.log("intererere",prod)
         if(prod==null||prod==undefined){
           this.interested = false
         }
         else {
           this.interested = true
         }
        console.log("intrestsProds",res)
        console.log("intrestsProds",this.intrestsProds)
      })
  }
  }
  sendMail() {
    console.log("item : ", this.selectedProduct);

   let data = {
    receiver:this.selectedProduct.user.email,
    message: this.messageToSend,
    subject: this.selectedProduct.productName,
    // productId:this.selectedProduct.id,
    isBuyLeadInquiry: false,
    isWebInquiry: true ,
    location: this.selectedProduct.user.country,
    isAllCountry : false,
    isAllCompany : false

   //  subject: this.subject,
   }

   this.mailService.sendMessage(data).subscribe(resp => {
     console.log("resap : " , resp);
     this.toaster.success(
       "The inquiry has been sent succefully",
       "Success",
       {
         closeButton: true,
         timeOut: 5000
       })
       this.isVisible = false;
   },(err) => {
       // console.log("erorrrrrrrr !" , err);

     // this.toaster.error(
     //   err.error.meesage,
     //   "Error",
     //   {
     //     closeButton: true,
     //     timeOut: 5000
     //   })
   })

  }
  Zoom(img){
    this.isVisible2=true;
    this.Zoomimage =img
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
   return true;
 }

 handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}

goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}
addTointer(){
  let type = "postBuyRequest"
  this.b2bProductService.addToInterest(this.itemProduct2.id,type).subscribe((resp: any) => {
    this.toaster.success(
      this.itemProduct2.productName+" has been added to your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.interested=true;
  })
}
removeFrominter(){
  let type = "postBuyRequest"
  this.b2bProductService.removeInterest(this.itemProduct2.id,type).subscribe((resp: any) => {
    this.toaster.info(
      this.itemProduct2.productName+" has been removed from your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.interested=false;
  })
}
verifyInterest(){

  console.log("intererere",this.intrestsProds)
  let prod =this.intrestsProds.find(x => x === this.itemProduct2.id.toString())
  console.log("intererere",prod)
   if(prod==null||prod==undefined){
     this.interested = false
   }
   else {
     this.interested = true
   }

  console.log("intererere",this.interested)
}

}
