import { Component, HostListener, OnInit } from '@angular/core';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  public listChart = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public selectedChart = null;
  public loading = false;
  public nbrSLice = 0;
  public listItem = [];
  public listdataSlice = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public listShowcountryLabel = [];
  public countryList = [];
  public company = null;
  isVisible = false;
  public reciver   = null;
  constructor(private freightService: FreightService) { }

  ngOnInit() {
    this.search();
    // this.getAllCountries();
  }

addCountry(idCountry,item) {
  if (!this.listfiltercountry.includes(idCountry)) {
    this.listfiltercountry.push(idCountry);
    this.listShowcountry.push(item)
    this.listShowcountryLabel.push(item.country)
    console.log(this.listfiltercountry);
  }
  this.search();
  console.log(this.listfiltercountry);
}
checkExistCountry(id) {
  if (this.listfiltercountry.includes(id)) {
    return true
  } else {
    return false
  }
}
deleteCountry(idCountry,item){
  let index: number = this.listfiltercountry.indexOf(idCountry);
  let index2: number = this.listShowcountry.indexOf(item);
  if (index !== -1) {
    this.listfiltercountry.splice(index, 1);
}
if (index2 !== -1) {
  this.listShowcountry.splice(index2, 1);
  this.listShowcountryLabel.splice(index2, 1);
}
this.search();
console.log(this.listfiltercountry);
}

  selectedSort(item) {
    if (this.selectedChart == item) {
      this.selectedChart = null
      this.search();

    }
    else {
      this.selectedChart = item;
      this.search();

    }
  }
  getAllCountries() {
    this.freightService.countriesInsurance().subscribe((resp: any) => {
      this.countryList = resp
      this.countryList = this.countryList.filter(cnt => cnt !== null);
    })
  }

  clear(){
    this.listfiltercountry =[];
    this.selectedChart =null;
    this.listShowcountry =[];
    this.company=null;
    this.listShowcountryLabel=null;
    this.search();
  }
  search() {
    this.loading = true;
    this.nbrSLice = 0;
    let data = {
      sort: this.selectedChart,
      countries: this.listShowcountryLabel,
      companyName: this.company,
    }
    console.log(data);

    this.freightService.getAllInsurance(data).subscribe((resp: any) => {
      this.listItem = resp.insuranceList;
      this.countryList = resp.numberByCountries;
      if(this.countryList!=null){
      this.countryList = this.countryList.filter(cnt => cnt.flag !== null);
    }
      this.sliceData();
      this.loading = false;
    }, (err) => {
      console.log(err);

    })
  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      this.sliceData();
    }
  }
  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }
  sliceData() {
    this.nbrSLice = this.nbrSLice + 8;
    this.listdataSlice = this.listItem.slice(0, this.nbrSLice);
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  showModal(item): void {
    this.reciver= item
    this.isVisible = true;
  }
}
