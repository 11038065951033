import { Component, OnInit } from '@angular/core';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-b2b',
  templateUrl: './home-b2b.component.html',
  styleUrls: ['./home-b2b.component.css']
})
export class HomeB2bComponent implements OnInit {
  effect = 'scrollx';
  // urls = ["assets/images/slide.png","assets/images/hotevent.png","assets/images/slide.png","assets/images/hotevent.png"];
  urls = [{image: 'assets/images/pic1.jpeg', text: 'Source for suppliers, manufacturers and importers'},
  {image: 'assets/images/pic2.jpeg', text: 'Are you a freighter or involved in foreign trade supply chain in Africa.....Be where your customers are and promote and market your services.'},
  {image: 'assets/images/pic3.jpeg', text: 'Be part of the Africa largest international B2B trade community.....'},
  {image: 'assets/images/pic2.jpeg', text: 'Be part of the Africa largest international B2B trade community.....'},
  {image: 'assets/img/2.jpeg', text: ''},
  {image: 'assets/img/1.jpeg', text: ''}
 ];

  urls2 = ['assets/images/hotevent.png', 'assets/images/hotevent.png', 'assets/images/hotevent.png', 'assets/images/hotevent.png'];
 cat1 = 'Agriculture & Food';
 cat2 = 'Apparel & Textiles';
 cat3 = 'Auto & Transportation';
 cat4 = 'Bags, Shoes & Accessories';
 cat5 = 'Electronics';
 cat6 = 'Electrical Equipment';
 cat7 = 'Gifts, Sports & Toys';
 cat8 = 'Health & Beauty';
 public categoriesList = [];
 public listCategories = [];
 public url = environment.baseUrl + "/file/aws/download/";
 public cats: any = [];
 public firtCats = [];
 public subCats = [];
 public classif  = [];
 public ads  = [];
 public hotEvents  = [];
 hasClass = false;
  constructor(private b2bserviceService: B2bProductService, ) { }

  ngOnInit() {
    this.getCategories() ;
    this.getAllCatAndClassification();
    this.getAds();
    this.getHotEvents();
  }

  getAds() {
    this.b2bserviceService.getAds().subscribe((resp: any) => {
      this.ads = resp;
    });
}
  getHotEvents() {
    this.b2bserviceService.getHotEvents().subscribe((resp: any) => {
      this.hotEvents = resp;
    });
}
  getCategories() {
    this.b2bserviceService.getCategories('product').subscribe((resp: any) => {
      console.log('productproductproduct');
      console.log(resp);
      this.categoriesList = resp;
      this.sliceCategorie();

    });

  }
  getAllCatAndClassification() {
    this.b2bserviceService.getAllCatAndClassification().subscribe((resp: any) => {
      this.cats = resp;

      this.cats.forEach(cat => {
      this.subCats = cat.subCategories;
      this.subCats = this.subCats.filter(cla => cla.classification.length !== 0);
      cat.subCategories = this.subCats;
     });
      console.log(resp);

    });
  }

  sliceCategorie() {
    this.listCategories = this.categoriesList.slice(0, 8);
  }
}
