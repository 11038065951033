import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { SubscriptionService } from 'src/app/commons/services/subscription/subscription.service';
import { UserService } from 'src/app/commons/services/user.service';

@Component({
  selector: 'app-privacy-settings',
  templateUrl: './privacy-settings.component.html',
  styleUrls: ['./privacy-settings.component.css']
})
export class PrivacySettingsComponent implements OnInit {

  webInquiry: boolean = true;
  buyLeadInquiry: boolean = true;
  public user: any;

  constructor(private profileRouteService: ProfileRouteService, private subscriptionService: SubscriptionService,
    private userService: UserService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("privacy");
    this.getUser();
  }

  getUser() {
    let id = localStorage.getItem("USER_ID")

    if (id) {
      this.userService.getAuthUser(id).subscribe(
        (res: any) => {
          console.log("userService", res);
          this.user = res;
          if(this.user.subscription.allowWebInquiry != null){
            this.webInquiry = this.user.subscription.allowWebInquiry
          }
          if(this.user.subscription.allowBuyLeadInquiry != null) {
            this.buyLeadInquiry = res.subscription.allowBuyLeadInquiry
          }

          console.log('this.webInquiry' , this.webInquiry);
          console.log('this.buyLeadInquiry' , this.buyLeadInquiry);
          
          
        })
    }
  }

  allowInquiry() {
    console.log("webInquiry : ", this.webInquiry);
    console.log("buyLeadInquiry : ", this.buyLeadInquiry);
    this.subscriptionService.allowInquiry(this.user.id, this.webInquiry, this.buyLeadInquiry).subscribe(res => {
      console.log("result : ", res);
    })
  }

}
