import { APP_INITIALIZER, FactoryProvider } from "@angular/core";
import { EMPTY } from "rxjs";
import { KeyFeature } from "../models/KeyFeature";
import { AppInitService } from "./app-init.service";
import { UserService } from "./user.service";

export function loadPermissionsFactory(
  appInit: AppInitService,
  userService: UserService
) {
  const tokenExist: boolean = !!localStorage.getItem("TIP_APP_TOEKN");

  if (tokenExist && appInit) {
    console.log("token exist");
    let arrRoles =["ROLE_BUYER","ROLE_SELLER","ROLE_BOTH"]
    return () =>
      appInit.getCurrentUser().then(() => {
        if (
          arrRoles.includes(
            userService.currentUser.role
          )
        ) {
          return appInit
            .Init(userService.currentUser.id)
            .then((permissions: KeyFeature[]) => {
              console.log("permission initializer",permissions);
              userService.userPermissions = permissions;
            });
        }
        // else if (userService.currentUser.role.includes("ROLE_ADMIN")) {
        //   return appInit
        //     .initSubAdmin(userService.currentUser.id)
        //     .then((permissions: KeyFeature[]) => {
        //       userService.userPermissions = permissions;
        //     });
        // }
      });
  } else {
    return () =>
      new Promise((resolve, reject) => {
        resolve(EMPTY);
      });
  }
}

export const loadPermissionProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: loadPermissionsFactory,
  deps: [AppInitService, UserService],
  multi: true,
};
