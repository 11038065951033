const styles: any = {
    header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center'
    },
    subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5]
    },
    tableExample: {
        margin: [0, 5, 0, 15]
    },
    tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
    }
}

const defaultStyle: any = {
}

const images: any = {
}

export const arrayTemplate = (title: string, array: any[]) => {

    const keys = Object.keys(array[0]);
    const headers: string[] = [];

    keys.map((key) => {
        headers.push(key.replace(/_/g, " "));
    });

    let displayedArray: unknown[][] = array.map(element => Object.values(element));
    displayedArray = [headers, ...displayedArray];


    const content: any = [
        { text: `${title}`, style: 'header' },
        {
            style: 'tableExample',
            table: {
                body: displayedArray,
            },
            fontSize: 12,
            alignment: 'center',
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0) ? '#eeeeee' : null;
                },
            }
        },
    ];
    return { content, images, styles, defaultStyle };
}