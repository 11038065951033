import {Component, OnInit, ViewChild} from '@angular/core';
import {NzMessageService, NzTreeSelectComponent, UploadFile} from 'ng-zorro-antd';
import {products} from '../../../../assets/js/products';
import {AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as countriesJson from '../../../../assets/js/countries.json';
import {ActivatedRoute} from '@angular/router';
import {SubscriptionService} from '../../../commons/services/subscription/subscription.service';
import {FreightService} from '../../../commons/services/service freight/freight.service';
import {UserService} from '../../../commons/services/user.service';
import {debounceTime, distinctUntilChanged, first, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-post-freight-opportunities',
  templateUrl: './post-freight-opportunities.component.html',
  styleUrls: ['./post-freight-opportunities.component.css']
})
export class PostFreightOpportunitiesComponent implements OnInit {

  @ViewChild('nzTreeComponentProducts', { static: false }) nzTreeComponentProducts!: NzTreeSelectComponent;
  nodes = [];
  value = '1001';
  allproduct = products;
  selectedValues?: string;
  shippingMode: any ='Sea';
  company: any;
  originCountry: any;
  quantity: any;
  quantityUnit: any;
  originCity: any;
  stateOrigin: any;
  postalOrigin: any;
  departure: any;
  countryDestination: any;
  cityDestination: any;
  stateDestination: any;
  checked: any;
  postalDestination: any;
  Arrival: any;
  postDescription: any;
  weight: any;
  weightUnit: any;
  Commodity: any;
  date: any;
  dateShipping: any;
  INCOTERMS: any;
  fullName: any;
  lastName: any;
  emailUser: any;
  Insurance: any;
  InsuranceRequired: any;
  Hazardous: any;
  Stackable: any;
  L: any;
  W: any;
  H: any;
  dimensionsUnit: any;
  notes: any;
  listUnitOfMeasure: any = [
    "Box/Boxes",
    "Container/Containers",
    "Bottle/Bottles",
    "Kit/Kits",
    "Bag/Bags",
    "Carton/Cartons",
    "Case/Cases",
    "Pack/Packs",
    "Pair/Pairs",
    "Pallet/Pallets",
    "Parcel/Parcels",
    "Perch/Perches",
    "Piece/Pieces",
    "Paint/Paints",
    "Plant/Plants",
    "Rod/Rods",
    "Roll/Rolls",
    "Set/Sets",
    "Sheet/Sheets",
    "Strand/Strands",
    "Hectare/Hectares",
    "Square Centimeter/Square Centimeters",
    "Square Foot/Square Foots",
    "Square Inch/Square Inches",
    "Square Meter/Square Meters",
    "Square Mile/Square Miles",
    "Square Yard/Square Yards",
    "Acre/Acres",
    "Ampere/Amperes",
    "Kiloampere/Kiloamperes",
    "Kiloohm/Kiloohms",
    "Kilovolt/Kilovolts",
    "Kilowatt/Kilowatts",
    "Milliampere/Milliamperes",
    "Milliohm/Milliohm",
    "Millivolt/Millivolts",
    "Milliwatt/Milliwatts",
    "Ohm/Ohms",
    "Unit/Units",
    "Volt/Volts",
    "Watt/Watts",
    "Wp",
    "Hertz",
    "Kilohertz",
    "Megahertz",
    "Milihertz",
    "Centimeter/Centimeters",
    "Chain/Chains",
    "Foot/Feet",
    "Forty-Foot Container",
    "Furlong/Furlongs",
    "Inch/Inches",
    "Meter/Meters",
    "Millimeter/Millimeters",
    "Pole/Poles",
    "Twenty-Foot Container",
    "Yard/Yards",
    "Carat/Carats",
    "Ounce/Ounces",
    "Short Ton/Short Tons",
    "Gross",
    "Dozen/Dozens",
    "Tray/Trays",
    "Grain/Grains",
    "Quarter/Quarters",
    "Degrees Celsius",
    "Degrees Fahrenheit",
    "Kilometer/Kilometers",
    "Mile/Miles",
    "Nautical Mile/Nautical Miles",
    "Cubic Centimeter/ Cubic Centimeters",
    "Cubic Foot/Cubic Feet",
    "Cubic Meter/Cubic Meters",
    "Cubic Yard/Cubic Yards",
    "Dram/Drams",
    "Gill/Gills",
    "Quart/Quarts",
    "Barrel/Barrels",
    "Fluid Ounce/Fluid Ounces",
    "Gallon/Gallons",
    "Liter/Liters",
    "Milliliter/Milliliters",
    "Bushel/Bushels",
    "Gram/Grams",
    "Kilograms/Kilograms",
    "Long Ton/Long Tons",
    "Metric Ton/Metric Tons",
    "Milligram/Milligrams",
    "Pound/Pounds",
    "Stone/Stones",
    "Ton/Tons",
    "Tonne/Tonnes"
  ];
  listUnitOfDim: any = [
    "inch (in)",
    "foot (ft)",
    "yard (yd)",
    "Kilometer (km)",
    "Centimeter (cm)",
    "Millimeter (mm)",
    "mile (mi)"
  ];
  listUnitOfweight: any = [
    "grain (gr)",
    "drachm (dr)",
    "ounce (oz)",
    "pound (lb)",
    "stone (st)",
    "quarter (qr)",
    "hundredweight (cwt)",
    "Kilogram (kg)",
    "ton (t)"
  ];
  loading = false;
  formPostRequest = new FormGroup({});
  allcountries: any = (countriesJson as any).default;
  listafricanCountries1: any = new Array(2);
  incoterms: any = ["CIF (Cost, Insurance and Freight)"
    , "CIP (Carriage and Insurance Paid to)drop down menu"
    , "CFR (Cost and Freight)"
    , "CPT (Carriage paid to)"
    , "DAT (Delivered at Terminal)"
    , "DAP (Delivered at Place)"
    , "DDP (Delivery Duty Paid)"
    , "EXW (Ex Works)drop down menu"
    , "FAS (Free Alongside Ship)"
    , "FCA (Free Carrier)"
    , "FOB (Free on Board)"
  ];

  fileList: UploadFile[] = [];
  listEmplty = true;
  userId: any;
  user: any;
  companyNames: any;
  nodes2 = [
    {
      title: 'parent 1',
      key: '100',
      children: [
        {
          title: 'parent 1-0',
          key: '1001',
          children: [
            { title: 'leaf 1-0-0', key: '10010', isLeaf: true },
            { title: 'leaf 1-0-1', key: '10011', isLeaf: true }
          ]
        },
        {
          title: 'parent 1-1',
          key: '1002',
          children: [{ title: 'leaf 1-1-0', key: '10020', isLeaf: true }]
        }
      ]
    }
  ];
  flagUrl: any;
  insuranceInput: boolean = false;
  role: any;
  intermodelVisible: boolean = false;
  locationLabel: any = new Array(2)
  IntermodelFrom: any = 'city';
  IntermodelTo: any = 'airport';
  ShippingDetailsArray: any[] = []
  location: any = new Array(2);
  locationDestination: any = new Array(2);
  criteria: any = {};
  shippingIntermodel: any[] = [
    {
      label: 'Air',
      value: 'airport'
    },
    {
      label: 'Sea',
      value: 'port'
    },
    {
      label: 'Land',
      value: 'city'
    },
  ]
  africanCountryNoPort: string[] = ['BWA', 'MOZ', 'ZMB', 'UGA', 'SWZ', 'SSD', 'RWA', 'NER', 'MLI', 'MWI', 'LSO', 'ETH', 'TCD', 'CAF', 'BDI', 'BFA']
  originCountryStart: any;
  destinationCountryFinal: any;
  expectedArrivalFinnal: any;
  expectedShippingStart: any;
  departureTypeStart: any;
  arrivalTypeFinal: any;
  destinationPositionFinal:any;
  originPositionStart:any;
  originCountryFinal:any;
  destinationCountryStart:any;
  testSelected = {}
  freightTypes:any;
  freightTypesList = ["Seaport", "Freight forwarder", "Air Cargo", "Railway", "Couriers", "Logistics", "Warehousing", "Trucking", "Forwarding", "Inspection", "Customs brokers","Customs"];
  freightIgnoreCaseList =["Trucking","Air Cargo", "Railway","Seaport"];
  freightServicesCheck =false;
  constructor(private route: ActivatedRoute,private subscriptionService: SubscriptionService, private freightService: FreightService, private msg: NzMessageService, private fb: FormBuilder, private userService: UserService) { }

  ngOnInit() {
    this.locationLabel[0] = 'port'
    this.locationLabel[1] = 'airport'
    this.createFormPostFreight();
    this.getAfricanCountries();
    this.getUser();
    if (this.route.snapshot.paramMap.get('freightProvider') != null) {
      this.formPostRequest.patchValue({
        userCompany :this.route.snapshot.paramMap.get('freightProvider'),
      })
    }
  }
  createFormPostFreight() {
    this.formPostRequest = this.fb.group({
      id: [],
      shippingMode: [null],
      freightLegs: this.fb.array([this.createFormGroup()]),
      commodity: [''],
      quantity: [0, Validators.required],
      quantityUnit: ['', Validators.required],
      weight: [0, Validators.required],
      weightUnit: ['', Validators.required],
      nameUser: ['', Validators.required],
      EmailUser: ['', Validators.required],
      L: [0],
      H: [0],
      W: [0],
      dimensionsUnit: [''],
      Stackable: [''],
      Hazardous: [''],
      Insurance: ['', Validators.required],
      INCOTERMS: [''],
      notes: [''],
      checkboxAgree: [false, Validators.requiredTrue],
      userCompany: [null, [Validators.required]],
      InsuranceRequired: [null],
      IntermodelTo: [null],
      IntermodelFrom: [null],
      freightActivityType:[null,Validators.required]
    });
  }
  getAfricanCountries() {
    console.log('this.allcountries', this.allcountries)
    this.listafricanCountries1[0] = this.allcountries.filter(x => x.in_africa != false);
    this.listafricanCountries1[1] = this.allcountries.filter(x => x.in_africa != false);
    console.log('this.listafricanCountries1 :>> ', this.listafricanCountries1);
    this.onSearch(2, "");
  }
  patchValueToIntermodel() {
    this.formPostRequest.patchValue({
      countryFromIntermodel: this.formPostRequest.value.countryFrom,
      cityFromIntermodel: this.formPostRequest.value.cityFrom,
      stateFromIntermodel: this.formPostRequest.value.stateFrom,
      postalFromIntermodel: this.formPostRequest.value.postalFrom,
      countryToIntermodel: this.formPostRequest.value.countryTo,
      cityToIntermodel: this.formPostRequest.value.cityTo,
      stateToIntermodel: this.formPostRequest.value.stateTo,
      postalToIntermodel: this.formPostRequest.value.postalTo,
      departureIntermodel: this.formPostRequest.value.departure,
      arrivalIntermodel: this.formPostRequest.value.arrival,
      dateArriveIntermodel: this.formPostRequest.value.dateArrive,
      dateShippingIntermodel: this.formPostRequest.value.dateShipping,
    })
  }
  onChangeShippingMode(event) {
    if (event == 'Air') {
      this.removeFormGroup();
      this.locationLabel[0] = 'Airport'
      this.listafricanCountries1[0] = this.allcountries.filter(item => item.in_africa != false);
    }
    if (event == 'Sea') {
      this.removeFormGroup();
      this.locationLabel[0] = 'Port'
      this.listafricanCountries1[0] = this.allcountries.filter(item => item.in_africa != false && this.africanCountryNoPort.includes(item.isocode) != true);
    }
    if (event == 'Land') {
      this.removeFormGroup();
      this.locationLabel[0] = 'City'
      this.listafricanCountries1[0] = this.allcountries.filter(item => item.in_africa != false);
    }
    if (event == 'Intermodal') {
      this.addFormGroup();
      this.intermodelVisible = true
    } else {
      this.intermodelVisible = false
    }
  }
  private createFormGroup(): FormGroup {
    return new FormGroup({
      countryFrom: new FormControl(null, Validators.required),
      cityFrom: new FormControl(''),
      stateFrom: new FormControl('', Validators.required),
      postalFrom: new FormControl('', Validators.required),
      countryTo: new FormControl(null, Validators.required),
      cityTo: new FormControl(''),
      stateTo: new FormControl('', Validators.required),
      postalTo: new FormControl('', Validators.required),
      departure: new FormControl('', Validators.required),
      arrival: new FormControl('', Validators.required),
      dateArrive: new FormControl('', Validators.required),
      dateShipping: new FormControl('', Validators.required),
    });
  }
  addFormGroup(): void {
    const features = this.formPostRequest.get("freightLegs") as FormArray;
    features.push(this.createFormGroup());
  }
  removeFormGroup(): void {
    const features = this.formPostRequest.get("freightLegs") as FormArray;
    features.length > 1 ? features.removeAt(1) : null;
  }
  getListLocationTo(countryName: string) {
  }
  getListLocationFrom(countryName: string) {
    console.log('countryName :>> ', countryName);
    console.log('this.shippingMode :>> ', this.shippingMode);
  }
  resetFormulaire(){
    this.intermodelVisible =false;
    if(this.formPostRequest.get('freightLegs')['controls'].length > 1){
      this.removeFormGroup()
    }
  }
  submitModal() {
    const formModules: any[] = this.formPostRequest.value.freightLegs;
    this.ShippingDetailsArray= [];
    formModules.map((module) => {
      console.log('module :>> ', module);
      this.ShippingDetailsArray.push({
        originCountry: module.countryFrom.label,
        originName: module.cityFrom,
        originType: this.shippingMode,
        originState: module.stateFrom,
        originPostal: module.postalFrom,
        originflag: module.countryFrom.flag,
        departureType: module.departure,
        destinationCountry: module.countryTo.label,
        destinationName: module.cityTo,
        destinationType: this.shippingMode,
        destinationState: module.stateTo,
        destinationPostal: module.postalTo,
        destinationflag: module.countryTo.flag,
        arrivalType: module.arrival,
        expectedArrivalDate: module.dateArrive,
        expectedShippingDate: module.dateShipping,
      });
    });
    console.log('this.modules :>> ', this.ShippingDetailsArray);
    //this.moduleForm.reset();
    //this.modal.destroy(this.modules);
  }
  getUser() {
    this.userId = this.userService.currentUser && this.userService.currentUser.id;
    // this.role = this.userService.currentUser && this.userService.currentUser.role;
    console.log("user id first  :", this.userId);
    this.userService.findById(this.userId).subscribe((res: any) => {
      this.fullName = res.firstName + " " + res.lastName;
      this.emailUser = res.email;
    })
    console.log("user company :", this.user);

  }
  CompanyNameValidator(): AsyncValidatorFn {
    return control => control.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(value => this.changeCompanyName(value)),
        first());
  }
  beforeUpload = (file: UploadFile): boolean => {
    console.log("beforeUpload function is called !!!")
    this.fileList = this.fileList.concat(file);
    console.log("file list taill", this.fileList.length);
    return false;
  };

  ValidateFile(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.msg.error('Only JPG or png files are allowed');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.msg.error("the image size should not exceed 10MB! ");
      return false;
    }

    return true;
  }
  onChange($event: string): void {
    console.log($event);
  }
  changeIntermodal() {
    if (this.shippingMode == 'Intermodal') {
      this.originCountryStart = this.ShippingDetailsArray[0].originCountry;
      this.originCountryFinal=this.ShippingDetailsArray[0].destinationCountry;
      this.destinationCountryStart=this.ShippingDetailsArray[1].originCountry;
      this.destinationCountryFinal = this.ShippingDetailsArray[1].destinationCountry;
      this.originPositionStart =this.ShippingDetailsArray[0].originName;
      this.destinationPositionFinal=this.ShippingDetailsArray[1].destinationName;
      this.expectedShippingStart = this.ShippingDetailsArray[0].expectedShippingDate;
      this.expectedArrivalFinnal = this.ShippingDetailsArray[1].expectedArrivalDate;
      this.departureTypeStart = this.ShippingDetailsArray[0].departureType;
      this.arrivalTypeFinal = this.ShippingDetailsArray[1].arrivalType;
    } else {
      this.originCountryStart = this.ShippingDetailsArray[0].originCountry;
      this.originCountryFinal=this.ShippingDetailsArray[0].originCountry;
      this.destinationCountryStart=this.ShippingDetailsArray[0].destinationCountry;
      this.destinationCountryFinal = this.ShippingDetailsArray[0].destinationCountry;
      this.originPositionStart =this.ShippingDetailsArray[0].originName;
      this.destinationPositionFinal=this.ShippingDetailsArray[0].destinationName;
      this.expectedShippingStart = this.ShippingDetailsArray[0].expectedShippingDate;
      this.expectedArrivalFinnal = this.ShippingDetailsArray[0].expectedArrivalDate;
      this.departureTypeStart = this.ShippingDetailsArray[0].departureType;
      this.arrivalTypeFinal = this.ShippingDetailsArray[0].arrivalType;
    }
  }
  addPost() {
    const arr: any[] = [];
    if(this.freightServicesCheck){
      this.nzTreeComponentProducts.getCheckedNodeList().map((checkedTree) => {
        arr.push({
          key: checkedTree.origin.key,
          title: checkedTree.origin.title
        });
      });
    }else{
      arr.push(this.formPostRequest.value.commodity);
    }
    console.log("this.formPostRequest : ", this.formPostRequest);
    if (this.formPostRequest.valid) {
      this.submitModal();
      this.changeIntermodal();
      this.loading = true
      let savedCompany: any;
      if (typeof this.formPostRequest.value.userCompany === 'string') {
        savedCompany = {
          id: this.formPostRequest.value.userCompany
        };
      } else {
        savedCompany = {
          id: this.formPostRequest.value.userCompany.id
        };
      }

      let data = {
        shippingMode: this.freightServicesCheck ? this.shippingMode :null,
        shipmentRoutes: this.ShippingDetailsArray,
        commodity: this.freightServicesCheck ? arr.map((item) => item.title) : arr,
        hs_commodity: this.freightServicesCheck ? arr.map((item) => item.key) : [],
        quantity: this.quantity,
        quantityUnit: this.quantityUnit,
        totalWeight: this.weight,
        totalWeightUnit: this.weightUnit,
        dimensions_h: this.freightServicesCheck ? this.H : null,
        dimensions_l: this.freightServicesCheck ? this.L :null,
        dimensions_w: this.freightServicesCheck ? this.W :null,
        dimensions_unity: this.freightServicesCheck ? this.dimensionsUnit :null,
        hazardous: this.freightServicesCheck ? this.Hazardous :null,
        insurance: this.Insurance,
        insuranceRequired: this.InsuranceRequired,
        incoterms: this.INCOTERMS,
        stackable: this.freightServicesCheck ? this.Stackable :null,
        additionalInformation: this.notes,
        userCompany: savedCompany['id'],
        intermodelTo: this.IntermodelTo ? this.IntermodelTo : this.shippingMode,
        intermodelFrom: this.IntermodelFrom ? this.IntermodelFrom : this.shippingMode,
        originCountryStart: this.originCountryStart,
        destinationCountryFinal: this.destinationCountryFinal,
        expectedArrivalFinnal: this.expectedArrivalFinnal,
        expectedShippingStart: this.expectedShippingStart,
        departureTypeStart: this.departureTypeStart,
        arrivalTypeFinal: this.arrivalTypeFinal,
        originPositionStart:this.originPositionStart,
        destinationPositionFinal:this.destinationPositionFinal,
        destinationCountryStart:this.destinationCountryStart,
        originCountryFinal:this.originCountryFinal,
        freightActivityType:this.formPostRequest.value.freightActivityType,
      }
      console.log('data', data);
      let formData = new FormData();
      if (this.fileList)
        this.fileList.forEach((file: any) => {
          formData.append('files', file);
        });
      this.freightService.createPostFreight(data).subscribe((resp: any) => {
        let freightId = resp.id;
        this.freightService.uploadFilesFreightRequest(freightId, formData).subscribe((resp: any) => {
            console.log(resp);
            this.loading = false;
            //this.msg.success('post added successfully');
          },
          () => {
            this.loading = false;
            this.msg.error('upload failed.');
          })
        this.loading = false;
        this.msg.success('post added successfully');
        this.resetFormulaire();
        console.log("addRequest", resp);
        this.formPostRequest.reset();
        this.getUser();
      }, (err) => {
        this.loading = false;
        console.log(err);
        this.msg.error(err.message);
      })
    }
    else {
      this.loading = false;
      this.msg.error('Please fill in all mandatory fields');
    }
  }
  getCompanyByName(value) {
    this.subscriptionService.getCompanyName(value).subscribe((resp: any) => {
      this.companyNames = resp;
    })
  }
  changeCompanyName(value) {
    if (value != "") {
      this.getCompanyByName(value)
    }
    return value;
  }
  onChangeIntermodelFrom(value) {
    if (value == 'airport') {
      this.locationLabel[0] = 'Airport'
      this.listafricanCountries1[0] = this.allcountries.filter(item => item.in_africa != false);
    }
    if (value == 'port') {
      this.locationLabel[0] = 'Port'
      this.listafricanCountries1[0] = this.allcountries.filter(item => item.in_africa != false && this.africanCountryNoPort.includes(item.isocode) != true);
    }
    if (value == 'city') {
      this.locationLabel[0] = 'City'
      this.listafricanCountries1[0] = this.allcountries.filter(item => item.in_africa != false);
    }
  }
  onChangeIntermodelTo(value) {
    if (value == 'airport') {
      this.locationLabel[1] = 'Airport'
      this.listafricanCountries1[1] = this.allcountries.filter(item => item.in_africa != false);

    }
    if (value == 'port') {
      this.locationLabel[1] = 'Port'
      this.listafricanCountries1[1] = this.allcountries.filter(item => item.in_africa != false && this.africanCountryNoPort.includes(item.isocode) != true);
    }
    if (value == 'city') {
      this.locationLabel[1] = 'City'
      this.listafricanCountries1[1] = this.allcountries.filter(item => item.in_africa != false);
    }
  }
  getCountryByIso(isocode: string) {
    // console.log("country input :",labelCountry);
    this.listafricanCountries1[1].forEach((element) => {
      if (element.isocode == isocode) {
        console.log("element iso ",element);
        this.testSelected=element;
      }
    });
    return this.testSelected;
  }
  onchangeIntermodalCountry(isocode: string) {
    /*let formArray :FormArray = this.formPostRequest.get('freightLegs');
    formArray.controls[1].get('countryFrom').setValue(this.getCountryByIso(isocode))
    */
    this.formPostRequest.controls.freightLegs['controls'][1].patchValue(
      {
        countryFrom :this.getCountryByIso(isocode),
      }
    )

    console.log(isocode);
    console.log(this.formPostRequest)
    let formArrayTest = this.formPostRequest.get('freightLegs') as FormArray;

    console.log("formArrayTest", formArrayTest);
    //  console.log('form array',this.formPostRequest.controls.freightLegs['controls'][1].controls['countryFrom'].setValue(this.getCountryByIso(isocode)));
    this.formPostRequest.controls.freightLegs['controls'][1].controls['countryFrom'].setValue(this.testSelected)
    console.log(this.formPostRequest);
    this.onSearch(1,this.getCountryByIso(isocode));
  }
  interModalRequest(number: number, value: any) {
    if (number == 0) {
      console.log('this.IntermodelFrom :>> ', this.IntermodelFrom);
      this.criteria.type = this.IntermodelFrom
      let list = [];
      list.push(value.isocode);
      if (this.IntermodelFrom != 'city') {
        this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
          this.location[0] = res
        });
      } else {
        this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
          this.location[0] = res
        });
      }
    } else if (number == 1) {
      console.log('this.IntermodelTo :>> ', this.IntermodelTo);
      this.criteria.type = this.IntermodelTo
      let list = [];
      list.push(value.isocode);
      if (this.IntermodelTo != 'city') {
        this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
          this.location[1] = res
        });
      } else {
        this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
          this.location[1] = res
        });
      }
    }
  }
  airportRequest(value: any) {
    console.log('this.shippingMode air :>> ', this.shippingMode);
    this.criteria.type = 'airport'
    let list = [];
    list.push(value.isocode);
    this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
      this.location[0] = res
    });
  }
  seaportRequest(value: any) {
    console.log('this.shippingMode air :>> ', this.shippingMode);
    this.criteria.type = 'port'
    let list = [];
    list.push(value.isocode);
    this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
      this.location[0] = res
    });
  }
  citiesRequest(value: any) {
    console.log('this.shippingMode air :>> ', this.shippingMode);
    this.criteria.type = 'city'
    let list = [];
    list.push(value.isocode);
    this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
      this.location[0] = res
    });
  }
  onSearch(number: number, value: any): void {
    console.log('value :>> ', value);
    if(value != undefined) {
      if (this.shippingMode == 'Intermodal') {
        this.interModalRequest(number, value)
      }
      if (this.shippingMode == 'Sea') {
        this.seaportRequest(value)
      }
      if (this.shippingMode == 'Air') {
        this.airportRequest(value)
      }
      if (this.shippingMode == 'Land') {
        this.citiesRequest(value);
      }
    }
  }

  //Destination
  interModalRequestDestination(number: number, value: any) {
    if (number == 0) {
      console.log('this.IntermodelFrom :>> ', this.IntermodelFrom);
      this.criteria.type = this.IntermodelFrom
      let list = [];
      list.push(value.isocode);
      this.onchangeIntermodalCountry(value.isocode);
      if (this.IntermodelFrom != 'city') {
        this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
          this.locationDestination[0] = res
        });
      } else {
        this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
          this.locationDestination[0] = res
        });
      }
    } else if (number == 1) {
      console.log('this.IntermodelTo :>> ', this.IntermodelTo);
      this.criteria.type = this.IntermodelTo
      let list = [];
      list.push(value.isocode);
      if (this.IntermodelTo != 'city') {
        this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
          this.locationDestination[1] = res
        });
      } else {
        this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
          this.locationDestination[1] = res
        });
      }
    }
  }
  airportRequestDestination(value: any) {
    console.log('this.shippingMode air :>> ', this.shippingMode);
    this.criteria.type = 'airport'
    let list = [];
    list.push(value.isocode);
    this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
      this.locationDestination[0] = res
    });
  }
  seaportRequestDestination(value: any) {
    console.log('this.shippingMode air :>> ', this.shippingMode);
    this.criteria.type = 'port'
    let list = [];
    list.push(value.isocode);
    this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
      this.locationDestination[0] = res
    });
  }
  citiesRequestDestination(value: any) {
    console.log('this.shippingMode air :>> ', this.shippingMode);
    this.criteria.type = 'city'
    let list = [];
    list.push(value.isocode);
    this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
      this.locationDestination[0] = res
    });
  }
  onSearchDestination(number: number, value: any): void {
    console.log('value :>> destin', value);
    if (this.shippingMode == 'Intermodal') {
      this.interModalRequestDestination(number, value)
    }
    if (this.shippingMode == 'Sea') {
      this.seaportRequestDestination(value)
    }
    if (this.shippingMode == 'Air') {
      this.airportRequestDestination(value)
    }
    if (this.shippingMode == 'Land') {
      this.citiesRequestDestination(value);
    }
    if (value && value.length > 1) {
      // this.listafricanCountries1 = this.allcountries.filter(x => x.in_africa != false);
      this.listafricanCountries1[number] = this.allcountries.filter(item => item.in_africa != false && item.label.toLowerCase().indexOf(value.toLowerCase()) > -1);
      console.log('object :>> ', this.listafricanCountries1);
    } else {
      this.listafricanCountries1[number] = this.allcountries.filter(x => x.in_africa != false);
    }
  }
  onChangeProduct(value: any) {
    const arr: any[] = [];
    console.log('value :>> ', value);
    console.log('value form :>> ', this.formPostRequest.value.commodity);
    this.nzTreeComponentProducts.getCheckedNodeList().map((checkedTree) => {
      console.log("checkedTree", checkedTree)
      arr.push({
        key: checkedTree.origin.key,
        title: checkedTree.origin.title
      });

    });
    console.log("hsCode : ", arr.map((item) => item.key));
  }
  onChangeInsurance(value) {
    if (value == 'No') {
      this.insuranceInput = true;
    } else {
      this.insuranceInput = false;
    }

  }
  getFlag(isoCode: any) {
    console.log('checkisoCode :>> ', isoCode.isocode);
    this.allcountries.forEach((element) => {
      if (element.isocode == isoCode.isocode) {
        this.flagUrl = element.flag;
      }
    });
    return true;
  }
  overideValidatorFieldList :any[]= ['INCOTERMS','Hazardous','Stackable','dimensionsUnit','W','H','L','shippingMode']

  onChangeFreightTypes(value :any) {
    this.freightServicesCheck = this.freightIgnoreCaseList.some(x => x === value);
    if(!this.freightServicesCheck){
      this.overideValidatorFieldList.forEach(x => this.clearValidatorFields(x));
    }else {
      this.overideValidatorFieldList.forEach(x => this.setValidatorFields(x));
    }
    console.log('check form validation ', this.formPostRequest);
    return true;
  }
  clearValidatorFields(field :string) {
    this.formPostRequest.get(field).clearValidators();
  }
  setValidatorFields(field :string) {
    this.formPostRequest.get(field).setValidators(Validators.required);
  }

}
