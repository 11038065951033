import { Component, NgZone, OnInit } from '@angular/core';
import { ReportService } from 'src/app/commons/services/report-service/report.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


@Component({
  selector: 'app-sales-activity-buyer',
  templateUrl: './sales-activity-buyer.component.html',
  styleUrls: ['./sales-activity-buyer.component.css']
})
export class SalesActivityBuyerComponent implements OnInit {

  constructor(private productService: B2bProductService,private zone: NgZone,private userService: UserService,
              private reportService: ReportService) { }

  buyerTotalCount : any;
  salesReport = [];
  productPurchased :any ;
  buyerAudiance = [];

  ngOnInit() {
    this.getBuyerTotalCount();
    this.getBuyerSalesReport();
    // this.initPieChart();
    this.getBuyerProductPurchased();
    this.getBuyerAudience();
  }

  getBuyerTotalCount() {
    this.reportService.getBuyerTotalCount().subscribe((resp: any) => {
      console.log("getBuyerTotalCount : " ,resp);
      this.buyerTotalCount = resp;
      // this.reportData = resp
    })
  }

  getBuyerSalesReport() {
    this.reportService.getBuyerSalesReport().subscribe((resp: any) => {
      console.log("getBuyerSalesReport : " ,resp);
      this.salesReport = resp;
      // this.reportData = resp
      this.initLineChart();
    })
  }

  getBuyerProductPurchased() {
    this.reportService.getBuyerProductPurchased().subscribe((resp: any) => {
      console.log("getBuyerProductPurchased : " ,resp);
      this.productPurchased = resp;
      // this.reportData = resp
      this.initPieChart();
    })
  }

  getBuyerAudience() {
    this.reportService.getBuyerAudience().subscribe((resp: any) => {
      console.log("getBuyerProductPurchased : " ,resp);
      this.buyerAudiance = resp;
      this.initAudianceLineChart();
    })
  }

  initLineChart() {
    this.zone.runOutsideAngular(() => {



      let chart = am4core.create("BuyerchartSales", am4charts.XYChart);
      chart.data=this.salesReport;
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0;
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      categoryAxis.renderer.grid.template.disabled = true;


      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");


      // Create series
      // var series = chart.series.push(new am4charts.LineSeries());
      // series.dataFields.valueY = "views";
      // series.dataFields.categoryX = "date";
      // series.strokeWidth = 1.5;
      // series.tensionX = 0.76;
      // series.fill = am4core.color("#E27BF1");
      // series.stroke = am4core.color("#E27BF1");
      // series.fillOpacity  = 0.8;

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "ordersCount";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.75;
      series.fill = am4core.color("#2785FB");
      series.fillOpacity = 0.6;
      series.stroke = am4core.color("#2785FB");
      series.tooltipText = "sales Count : {salesCount} \n orders Count : {ordersCount}";
      series.tooltip.fill = am4core.color("#CEB1BE");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "salesCount";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.stroke = am4core.color("#5F5DE1");
      series.tensionX = 0.75;
      series.fillOpacity = 0.4;
      series.fill = am4core.color("#5F5DE1");



      var range = valueAxis.createSeriesRange(series);
      range.value = 0;
      range.endValue = -1000;
      range.contents.stroke = am4core.color("#FF0000");
      range.contents.fill = range.contents.stroke;

      // Add scrollbar
      // var scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();
    }
    )
  }

  initAudianceLineChart() {
    this.zone.runOutsideAngular(() => {
      let chart = am4core.create("chartAudianceBuyer", am4charts.XYChart);
      chart.data=this.buyerAudiance;
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0;
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      categoryAxis.renderer.grid.template.disabled = true;


      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "productQuantity";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.75;
      // series.fill = am4core.color("#2785FB");
      // series.fillOpacity = 0.6;
      series.stroke = am4core.color("#2785FB");
      series.tooltipText = "product names : {productName} \n quantity : {valueY} ";
      series.tooltip.fill = am4core.color("#CEB1BE");


      // // Create series
      // var series = chart.series.push(new am4charts.LineSeries());
      // series.dataFields.valueY = "productQuantity";
      // series.dataFields.categoryX = "date";
      // series.strokeWidth = 1.5;
      // series.stroke = am4core.color("#5F5DE1");
      // series.tensionX = 0.75;
      // series.fillOpacity = 0.4;
      // series.fill = am4core.color("#5F5DE1");

      // var range = valueAxis.createSeriesRange(series);
      // range.value = 0;
      // range.endValue = -1000;
      // range.contents.stroke = am4core.color("#FF0000");
      // range.contents.fill = range.contents.stroke;

      chart.cursor = new am4charts.XYCursor();
    }
    )
  }


  initPieChart() {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("chartStatusBuyer", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.legend = new am4charts.Legend();
    chart.data = this.productPurchased.data;
    let prodCount = this.productPurchased.productCount
    // chart.data = [
    //   {
    //     product: "Beans",
    //     sales: 300,
    //     // color:"#2785FB"
    //   },
    //   {
    //     product: "Shoes",
    //     sales: 30,
    //     // color:"#F15931"
    //   },
    //   {
    //     product: "Eggs",
    //     sales: 45,
    //     // color: "#F8B7C9"
    //   },
    //   {
    //     product: "Watches",
    //     sales: 7,
    //     // color: "#C2DEC0"
    //   },
    //   {
    //     product: "Airpods",
    //     sales: 200,
    //     // color: "#E27BF1"
    //   }
    // ];

    chart.innerRadius = am4core.percent(40);
    let label = chart.seriesContainer.createChild(am4core.Label);
    label.text = prodCount+" Products";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 15;
    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "sales";
    series.dataFields.category = "product";
    series.slices.template.stroke = am4core.color("#fff");
    series.innerRadius = 10;
    series.slices.template.fillOpacity = 1;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;



    // series.slices.template.fill = am4core.color("{series.color}")
  //   series.colors.list = [
  //     am4core.color("#55C492"),
  //     am4core.color("#E59AAE"),
  //     am4core.color("#B57DFF"),
  //     am4core.color("#2785FB"),
  // ];

  }

}
