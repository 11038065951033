import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  baseUrl = environment.baseUrl + "/chat";


  constructor(private http: HttpClient) { }

  getAllRooms(search) {
    let formData = new FormData();
    formData.append("search", search);
    return this.http.put(this.baseUrl + '/get-rooms',formData);
  }

  getMessagesByRoomId(roomId) {
    return this.http.get(this.baseUrl + '/get-messages/' + roomId);
  }

  sendMessage(body: any) {
    return this.http.post(this.baseUrl + '/send-msg', body);
  }

  sendFiles(files ,messageDTO ) {
    let formData = new FormData();
    // formData.append("files",files)
    for (var i = 0; i < files.length; i++) {
      console.log(files[i]);
      formData.append('files', files[i]);

  }
    // fileList.forEach((file: any) => {
    //   formData.append('files', file);
    // });
    formData.append('receiverMail',messageDTO.receiverMail)
    return this.http.post(this.baseUrl +"/"+ messageDTO.roomId +'/uploadFiles' , formData);
  }

  getRoomId(receiver) {
    let formData = new FormData();
    formData.append("receiver", receiver);
    return this.http.put(this.baseUrl + '/get-room-id', formData);
  }

  deleteRoomId(id) {
    return this.http.delete(this.baseUrl + '/delete-room/'+id);
  }

  downloadFile(id,fileName){
    let formData = new FormData();
    formData.append("name",fileName)
    return this.http.post(this.baseUrl+'/get-file/'+id ,formData,{ responseType: 'blob' });
   }

}
