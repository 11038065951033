import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SourceService } from 'src/app/commons/services/source-service/source.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/commons/shared service/shared-service';

@Component({
  selector: 'app-search-suppliers-bycategories',
  templateUrl: './search-suppliers-bycategories.component.html',
  styleUrls: ['./search-suppliers-bycategories.component.css']
})
export class SearchSuppliersByCategoryComponent implements OnInit {
  url = environment.baseUrl+'/';
  isVisible = false;
  public messages: string = null;
  public categoriesList = [];
  public certificationList = [];
  public countryList = [];
  public productDTOList = [];
  public productDTOShow = [];
  public productCount: number = 0;
  public MinOrder: number = null;
  public minPrice: number = null;
  public maxPrice: number = null;

  public isActive = false;
  public index = null;
  public listfilterCategorie = [];
  public listCategories = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public loading: boolean = false;
  public suppliers = [];
  public countries;
  public category;
  public supplierId;
  public nbrSlice = 0;



  constructor(private sourceService: SourceService,
              private b2bserviceService: B2bProductService,
              private route: ActivatedRoute,
              private sharedService : SharedService
          ) {

           }

  ngOnInit() {
    // this.getCategories();
    // this.route.params.subscribe(data => {
    //   this.supplierId = data.id;
    //   this.search();
    //   this.getCompanyContries();
    // });
    this.sharedService.sharedSupplierByCategorie.subscribe( (resp : any)=> {
        console.log(resp);
        this.listfilterCategorie = resp.categoriesId;
        this.listfiltercountry = resp.countries
        this.search();
        this.getCategories();
        setTimeout(() => {
          this.checkCountries();
        }, 1000);
    })

  }

  search() {
    console.log("list categorie ! " , this.listfilterCategorie);

    let data = {
      categoriesId: this.listfilterCategorie,
      countries : this.listfiltercountry,
      minOrder:  this.MinOrder,
      fromPrice: this.minPrice,
      toPrice:   this.maxPrice,
      companyId: this.supplierId,
      sortBy: "id",
      sort: "DESC"
    }
    this.loading = true;
    this.sourceService.getAllByCategorySearch(data).subscribe((resp: any) => {
      console.log(resp);
      this.loading = false;
      this.suppliers = resp.sourceSellers
      this.countryList = resp.numberByCountries
      // this.categoriesList = resp.categoriesDTOList
      // this.sliceCategorie();
    })
  }

  getCategories() {
    this.b2bserviceService.getCategories("product").subscribe((resp: any) => {
      console.log("productproductproduct");
      console.log(resp);
      this.categoriesList = resp
      this.listfilterCategorie.forEach(filtredCat => {
        resp.forEach(cat => {
          if(filtredCat == cat.id) { cat.checked = true;}
        })
      })
      resp.map((item) => item.label = item.name);

      this.listCategories= resp

      this.sliceCategorie();
    })
  }

  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
  }

  addCategorie() {
    // console.log(event);
    console.log("idCategory : " ,this.listCategories);
    this.listfilterCategorie = [];
    this.loading = true
    this.listCategories.forEach(cat => {
      if(cat.checked == true) {
        this.listfilterCategorie.push(cat.id);
      }
    })
    // if (!this.listfilterCategorie.includes(idCatg)) {
    //   this.listfilterCategorie.push(idCatg);
    //   console.log(this.listfilterCategorie);
    // }
    // else {
    //   let index: number = this.listfilterCategorie.indexOf(idCatg);
    //   if (index !== -1) {
    //     this.listfilterCategorie.splice(index, 1);
    //   }
    // }
    this.search();
    console.log(this.listfilterCategorie);
  }

  checkExist(id) {
    if (this.listfilterCategorie.includes(id)) {
      return true
    } else {
      return false
    }
  }

  getCompanyContries() {
    this.sourceService.getCountriesById(this.supplierId).subscribe((resp: any) => {
      console.log(resp);
      this.loading = false;
      this.countries = resp
      // this.sliceCategorie();
    })
  }

  // Country
  checkCountries() {
    console.log("heeeeeeeeeeeere" , this.listfiltercountry);

    this.listfiltercountry.forEach(country => {
      this.countryList.forEach(countryl=>{ if(country == countryl.country) {this.listShowcountry.push(countryl)}})
    })
  }

    // Country
  addCountry(idCountry,item) {
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item)
      console.log(this.listfiltercountry);
    }
    this.search();
    console.log(this.listfiltercountry);
  }

  deleteCountry(idCountry,item){
      let index: number = this.listfiltercountry.indexOf(idCountry);
      let index2: number = this.listShowcountry.indexOf(item);
      if (index !== -1) {
        this.listfiltercountry.splice(index, 1);
    }
    if (index2 !== -1) {
      this.listShowcountry.splice(index2, 1);
  }
    this.search();
    console.log(this.listfiltercountry);
  }
  checkExistCountry(id) {
    if (this.listfiltercountry.includes(id)) {
      return true
    } else {
      return false
    }
  }

  contactNow() {};
}
