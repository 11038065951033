import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {NzNotificationService} from "ng-zorro-antd";
import {Router} from '@angular/router';
import {TradeshowsService} from '../../commons/services/tradeshows/tradeshows.service';
import {BuyRequestService} from '../../commons/services/service buy-request/buy-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { DisabledTimeFn } from 'ng-zorro-antd/date-picker/standard-types';
import { differenceInCalendarDays, setHours } from 'date-fns';
const jwt = require('jsonwebtoken');

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})

export class AddEventComponent implements OnInit {
  @ViewChild('template',{static: false}) template: TemplateRef<{}>;
  ZoomAppBaseUrl = 'http://demo.peaqock.com/webinars/';
  // ZoomAppBaseUrl = environment.ZoomAppBaseUrl;
  apiKey = 'CZldDtqERieOrGx_XztDhQ'; // Your Zoom API Key
  apiSecret = 'LJmNj8qPj3VXbM6MXliJXgp3vwOCg5ExhcMR'; // Your Zoom API Secret
  meetingNumber = '0'; // always generate new when requested or user will input
  leaveUrl = 'http://localhost:4200'; // Your redirect Url, when meeting over
  userName = 'Zoom Feature';
  userEmail = '';
  meetingPassWord = ''; // set default meeting password here
  joinUrl = "";
  joinMeetingForm: FormGroup;
  createMeetingForm!: FormGroup;
  duration:number;
  category;
  listCategories:any[];
  listafricanCountries=[];
  meetingrequest:any;
  public loading: boolean =false;
  meetingCreated: boolean = false;
  selectedISO = CountryISO.Morocco;
  TooltipLabel = TooltipLabel;
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
  today = new Date();
  constructor(private productService: B2bProductService, private buyRequestService:BuyRequestService ,private tradeshowsService:TradeshowsService,private router: Router,private formBuilder: FormBuilder,public httpClient: HttpClient,private notification: NzNotificationService) { }
  meetingCreateForm() {
    this.createMeetingForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      phoneNumberPrefix: ['+212'],
      phoneNumber: [null],
      category: [null, [Validators.required]],
      eventcountry:[null],
      eventcity:[null],
      startdatetime:[null,[Validators.required]],
     // enddate:[null,[Validators.required]],
     // starttime:[null,[Validators.required]],
     // endtime:[null,[Validators.required]],
      enddatetime:[null,[Validators.required]],
      eventdescription:[null,[Validators.required]],
      exhibitor:[null],
      contactname:[null],
      companyname:[null],
      website: [null],
      address:[null],
      city:[null],
      country:[null],
      eventType:[null,[Validators.required]],
      agree: [false,[Validators.requiredTrue]],
      eventname: [null,[Validators.required]],

    });
    this.getAllafricanCountries();
  }
  logTime(){
    console.log('TIIIME',this.today.toTimeString().split(" ")[0]);
  }
  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) < 0;
  };

  disabledDateTime: DisabledTimeFn = () => {
    return {
      nzDisabledHours: () => [],
      nzDisabledMinutes: () => [],
      nzDisabledSeconds: () => []
    };
  };
  submitForm(): void {
    for (const i in this.createMeetingForm.controls) {
      this.createMeetingForm.controls[i].markAsDirty();
      this.createMeetingForm.controls[i].updateValueAndValidity();
    }
    console.log(this.createMeetingForm.value);
    if(this.createMeetingForm.valid){

      let meetingrequest={
        "topic": this.createMeetingForm.value.eventname,
        "type": 5,
        "start_time": this.createMeetingForm.value.startdatetime.toISOString(),
        "agenda": this.createMeetingForm.value.eventdescription,
        "timezone":'Africa/Casablanca',
        "duration":120,
        "settings": {
          "host_video": "true",
          "panelists_video": "true",
          "practice_session": "true",
          "hd_video": "true",
          "approval_type": 1,
          "registration_type": 2,
          "audio": "both",
          "auto_recording": "none",
          "enforce_login": "false",
          "close_registration": "false",
          "show_share_button": "true",
          "allow_multiple_devices": "true",
          "registrants_email_notification": "true"
        }
      };
      console.log(meetingrequest);
      this.createNewMeeting(meetingrequest);
    }
  }
  getCategories() {
    this.productService.getAllProductCategory().subscribe((resp: any) => {
      console.log(resp);
      this.listCategories = resp
    })
  }
  getAllafricanCountries(){
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }
  saveEvent(){

    this.loading = true
    let event={
      eventId:this.meetingNumber,
      email:  this.createMeetingForm.value.email,
      mobileNumber:this.createMeetingForm.value.phoneNumber.nationalNumber,
      eventName:this.createMeetingForm.value.eventname,
      eventType:this.createMeetingForm.value.eventType,
      categoryId:this.createMeetingForm.value.category,
      eventCountry:this.createMeetingForm.value.eventcountry,
      eventCity:this.createMeetingForm.value.eventcity,
      startDate:this.createMeetingForm.value.startdatetime.toISOString().split("T")[0],
      endDate:this.createMeetingForm.value.enddatetime.toISOString().split("T")[0],
      startAt:this.createMeetingForm.value.startdatetime.toTimeString().split(" ")[0],
      endAt:this.createMeetingForm.value.enddatetime.toTimeString().split(" ")[0],
      webSite:this.createMeetingForm.value.website,
      eventDescription:this.createMeetingForm.value.eventdescription,
      productProfile:this.createMeetingForm.value.exhibitor,
      contactPersonName:this.createMeetingForm.value.contactname,
      companyName:this.createMeetingForm.value.companyname,
      adress:this.createMeetingForm.value.address,
      city:this.createMeetingForm.value.city,
      country:this.createMeetingForm.value.country
    };
    if(this.category=="All"){
      event.categoryId=null
    }
    console.log(event);
    this.tradeshowsService.addEvent(event).subscribe((resp: any) => {
       console.log(resp);
    this.loading = false
      this.createBasicNotification(this.template);
      this.router.navigateByUrl('/Trade');
    })
  }

  calculateDuration(date){
      var hours = date.getHours();
      var minutes = date.getMinutes();
      this.duration = hours*60+minutes;
  }
  createNewMeeting(data: any) {
    console.log(data);
    const payload = {
      iss: this.apiKey,
      exp: ((new Date()).getTime() + 500)
    };

    const zoomAccessToken = jwt.sign(payload, this.apiSecret);

    const request = {
      Token: zoomAccessToken,
      MeetingRequest:data
    };
    this.httpClient.post(`${this.ZoomAppBaseUrl}zoomfeature/meetings`, request).toPromise().then((response: any) => {
      console.log(response);

      this.meetingNumber = response.id;
      this.joinUrl = response.join_url;

      this.meetingCreated = true;
      this.saveEvent();



    }).catch((error) => {
      console.log(error)
    });
  }
  ngOnInit() {
   // this.joinMeetingCreateForm();
    this.getCategories();
    this.meetingCreateForm();
  }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.template(template);
  }
}
