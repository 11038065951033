import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { OrderService } from 'src/app/commons/services/order-service/order.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ElementRef, ViewChild } from '@angular/core';
import { ReportService } from 'src/app/commons/services/report-service/report.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';


@Component({
  selector: 'app-order-status',
  templateUrl: './orders-status.component.html',
  styleUrls: ['./orders-status.component.css']
})
export class OrderStatusComponent implements OnInit {

  public user: any = [];
  public totalProds = 0;
  public approvedProds = 0;
  public rejectedProds = 0;
  public PendingProds = 0;
  public totalInq = 0;
  public webInq = 0;
  public buyLeadInq = 0;
  listOfDisplayData = [
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
    { order: "display", isShown: false }, { order: "display3", isShown: false },
  ];
  orders = [];
  recentOrders=[];
  public showPanel = true;
  page = 0;
  status = "";
  productName = "";
  date = "";
  public statusValues = ["TODAY","THIS WEEK","THIS MONTH" , "THIS YEAR" , "ALL"];
  public revenue = "THIS MONTH";
  public analytic = "THIS YEAR";
  public statusValue = "TODAY";
  public recentOrder = "THIS WEEK";
  public tracking = "THIS YEAR";
  totalCount;
  orderStatus = [];
  orderTracking = [];
  role;
  isSeller: Boolean = true;
  incomeRevenue = [];
  buyerAnalytics = [];
  buyerOrderStatus = [];

  constructor(private zone: NgZone, private orderService: OrderService,
              private reportService:ReportService,private userService: UserService,
              private profileRouteService : ProfileRouteService) { }



  ngOnInit() {

    this.profileRouteService.changeRoute("businessActivity");
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.role = res.role;
        if ( this.role == 'ROLE_BUYER' ) {
           this.isSeller =false;
        }

        // if(this.role == 'ROLE_BOTH' || this.role == 'ROLE_SELLER' ) {
        //     this.getOrdersHystorySeller();
        //     this.getSalesReportStat();
        //     this.getRevenueIncome();
        //     this.getOrderStatus();
        //     this.getOrderTracking();
        //     this.getRevenueOutCome();
        //     this.getBuyerAnalytics();
        //     this.getBuyerOrderStatus();
        //     this.getBuyerRecentOrder();

        // } else if ( this.role == 'ROLE_BUYER' ) {
        //   this.isSeller =false;
        //   this.getRevenueOutCome();
        //   this.getBuyerAnalytics();
        //   this.getBuyerOrderStatus();
        //   this.getBuyerRecentOrder();
        // }
      })
  }
  }

  getOrderTracking() {
    this.reportService.getOrderTracking("").subscribe((res : any) => {
      console.log("this.orderTracking " , this.orderTracking);
      this.orderTracking= res
    })
  }

  getBuyerAnalytics() {
    this.reportService.getBuyerAnalytics("").subscribe((res : any) => {
      this.buyerAnalytics = res
      console.log("this.buyerAnalytics " , this.buyerAnalytics);
    })
  }

  getSalesReportStat() {
    this.reportService.getSalesReportStat("").subscribe((res : any) => {
      this.totalCount= res
      console.log("total count : " , this.totalCount);

    })
  }

  getBuyerOrderStatus() {
    this.reportService.getBuyerOrderStatus("").subscribe((res : any) => {
      this.buyerOrderStatus= res
      console.log("total buyerOrderStatus : " , this.buyerOrderStatus);

    })
  }

  getRevenueIncome() {
    this.reportService.getRevenueIncome("").subscribe((res : any) => {
      this.incomeRevenue = res;
      this.initLineChart();
    })
  }

  getRevenueOutCome() {
    this.reportService.getRevenueOutcome("").subscribe((res : any) => {
      this.incomeRevenue = res;
      console.log("incomeRevenue : " , this.incomeRevenue);

      this.initLineChart();
    })
  }


  getOrderStatus() {
    this.reportService.getOrderStatus("").subscribe((res : any) => {
      this.orderStatus= res
      this.initPieChart();
    })
  }

  getOrdersHystorySeller() {

    this.reportService.getRecentOrder("").subscribe(
      (res: any) => {
        console.log("result orderrss::!  : ", res);
        this.orders = res
        // this is to help collapse elements
        // this.addShownOrders();
      })
  }
  getBuyerRecentOrder() {
    this.reportService.getBuyerRecentOrder("").subscribe( (res: any) => {
        console.log("result orderrss::!  : ", res);
        this.recentOrders = res
        // this is to help collapse elements
        // this.addShownOrders();
      })
  }

  showColapse(index) {
    this.showPanel = !this.showPanel
    this.orders[index].isShown = !this.orders[index].isShown
  }
  pageIndexChanged(page) {
    console.log("page", page);
    // this.searchMessages()
  }

  addShownOrders() {
    this.orders.forEach(element => {
      element.isShown = false;
    })
  }

  initLineChart() {
    this.zone.runOutsideAngular(() => {

      let chart = am4core.create("chartRevenue", am4charts.XYChart);
      chart.data = [{
        "date": "1950",
        "income": 55,
        "outcome": 11,
      }, {
        "date": "1951",
        "income": 130,
        "outcome": 120,
      }, {
        "date": "1952",
        "income": 230,
        "outcome": 110,
      }, {
        "date": "1953",
        "income": 210,
        "outcome": 107,
      }, {
        "date": "1954",
        "income": 101,
        "outcome": 150
      }, {
        "date": "1955",
        "income": 320,
        "outcome": 110
      }, {
        "date": "1956",
        "income": 100,
        "outcome": 30
      }]
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0;
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      categoryAxis.renderer.grid.template.disabled = true;


      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "income";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.stroke = am4core.color("#55C492");
      series.tensionX = 0.77;
      series.fillOpacity = 0.7;
      series.fill = am4core.color("#F0FBF6");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "outcome";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.77;
      series.fill = am4core.color("#FFF0F4");
      series.fillOpacity = 0.7;
      series.stroke = am4core.color("#F07B95");

      var range = valueAxis.createSeriesRange(series);
      range.value = 0;
      range.endValue = -1000;
      range.contents.stroke = am4core.color("#FF0000");
      range.contents.fill = range.contents.stroke;

      // Add scrollbar
      // var scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();

    }
    )
  }

  initOutcomeChart() {
    this.zone.runOutsideAngular(() => {

      let chart = am4core.create("chartRevenue", am4charts.XYChart);
      chart.data = [{
        "date": "1950",
        "outcome": 11,
      }, {
        "date": "1951",
        "outcome": 120,
      }, {
        "date": "1952",
        "outcome": 110,
      }, {
        "date": "1953",
        "outcome": 107,
      }, {
        "date": "1954",
        "outcome": 150
      }, {
        "date": "1955",
        "outcome": 110
      }, {
        "date": "1956",
        "outcome": 30
      }]
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0;
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      categoryAxis.renderer.grid.template.disabled = true;


      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");

      // Create series
      // var series = chart.series.push(new am4charts.LineSeries());
      // series.dataFields.valueY = "income";
      // series.dataFields.categoryX = "date";
      // series.strokeWidth = 1.5;
      // series.stroke = am4core.color("#55C492");
      // series.tensionX = 0.77;
      // series.fillOpacity = 0.7;
      // series.fill = am4core.color("#F0FBF6");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "outcome";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.77;
      series.fill = am4core.color("#FFF0F4");
      series.fillOpacity = 0.7;
      series.stroke = am4core.color("#F07B95");

      var range = valueAxis.createSeriesRange(series);
      range.value = 0;
      range.endValue = -1000;
      range.contents.stroke = am4core.color("#FF0000");
      range.contents.fill = range.contents.stroke;
      chart.cursor = new am4charts.XYCursor();

    }
    )
  }

  initPieChart() {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("chartStatus", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    console.log("order status ; " , this.buyerOrderStatus);


    const propertyNames = Object.keys(this.buyerOrderStatus);
    const propertyValues = Object.values(this.buyerOrderStatus);
    var result = propertyNames.map((value,index) =>{
        return {status: value, value:propertyValues[index]};
    });

    // var result = Object.keys(this.orderStatus).map((key) =>
    //  [Number(key), this.orderStatus[key]]

    //  );
    console.log("result : " , result);

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 85;
    chart.legend.scrollable = true;

    chart.data = result;
    chart.innerRadius = am4core.percent(40);

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "status";
    series.slices.template.stroke = am4core.color("#fff");
    series.innerRadius = 10;
    series.slices.template.fillOpacity = 0.5;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;

    // series.slices.template.fill = am4core.color("{series.color}")
    series.colors.list = [
      am4core.color("#55C492"),
      am4core.color("#E59AAE"),
      am4core.color("#B57DFF"),
  ];

  }

  changeToBuyer() {
    this.isSeller = !this.isSeller;
    setTimeout(() => {
      this.initLineChart();
      this.initPieChart();
    }, 1000);

  }

}
