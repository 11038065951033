import { Component, OnInit } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { PostRequestService } from 'src/app/commons/services/service post request/post-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SubscriptionService } from 'src/app/commons/services/subscription/subscription.service';
import { UserService } from 'src/app/commons/services/user.service';

@Component({
  selector: 'app-matching-service',
  templateUrl: './matching-service.component.html',
  styleUrls: ['./matching-service.component.css']
})
export class MatchingServiceComponent implements OnInit {

  constructor() { }


  ngOnInit() {
  }


}
