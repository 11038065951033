import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BuyRequestComponent } from "./modules/buy-request/buy-request.component";
import { HomeB2bComponent } from "./modules/home-b2b/home-b2b.component";
import { PostRequestComponent } from "./modules/post-request/post-request.component";
import { SubscriptionComponent } from "./modules/subscription/subscription.component";
import { ProtectionComponent } from "./modules/search-importers/search-importers.component";
import { SearchSuppliersComponent } from "./modules/search-suppliers/search-suppliers.component";
import { ProfileComponent } from "./modules/profile/profile.component";
import { SourceSuppliersManufacturesComponent } from "./modules/source-suppliers-manufactures/source-suppliers-manufactures.component";
import { SourceImportersBuyersComponent } from "./modules/source-importers-buyers/source-importers-buyers.component";
import { ResultsAllCtaegoriesComponent } from "./modules/buy-request/results-all-ctaegories/results-all-ctaegories.component";
import { TradeShowComponent } from "./modules/trade-show/trade-show.component";
import { AddEventComponent } from "./modules/add-event/add-event.component";
import { TradeListComponent } from "./modules/trade-list/trade-list.component";
import { ShowComponent } from "./modules/show/show.component";
import { ProductDetailsComponent } from "./modules/product-details/product-details.component";
// import { AccesToFinanceParentComponent } from './modules/access-to-finance/acces-to-finance-parent.component';
import { AuthGuard } from "./commons/guards/auth";
import { ManufacturerComponent } from "./modules/manufacturer/manufacturer.component";
import { AccesToFinanceParentComponent } from "./modules/access-to-finance/acces-to-finance-parent.component";
import { ResultByIdComponent } from "./modules/buy-request/result-by-id/result-by-id.component";
import { SearchSuppliersByCategoryComponent } from "./modules/suppliers-bycategories/search-suppliers-bycategories.component";
import { FreightServicesComponent } from "./modules/freight-services/freight-services.component";
import { ProductsByCategoryComponent } from "./modules/products-by-category/products-by-category.component";
import { CategoryListComponent } from "./modules/category-list/category-list.component";
import { ProductsByMainCategoryComponent } from "./modules/products-by-main-category/products-by-main-category.component";
import { ProductsByClassificationComponent } from "./modules/products-by-classification/products-by-classification.component";
import { AllMainCategoriesComponent } from "./modules/all-main-categories/all-main-categories.component";
import { MatchingServiceComponent } from "./modules/home-b2b/matching-service/matching-service.component";
import { MatchingProfilerComponent } from "./modules/matching-profiler/matching-profiler.component";
import { SearchCompanyComponent } from "./modules/search-company/search-company.component";
import { InsuranceComponent } from "./modules/insurance/insurance.component";
import { PostFreightComponent } from "./modules/freight-request/post-freight/post-freight.component";
import { FreightRequestComponent } from "./modules/freight-request/freight-request.component";
import { FreightLeadByIdComponent } from "./modules/freight-request/freight-lead-by-id/freight-lead-by-id.component";
import { PreferredPartnersComponent } from "./modules/preferred-partners/preferred-partners.component";
import { AddPartnerComponent } from "./modules/preferred-partners/add-partner/add-partner.component";
import { FreightCompanyByIdComponent } from "./modules/freight-services/freight-company-by-id/freight-company-by-id.component";
import { InsuranceCompanyByIdComponent } from "./modules/insurance/insurance-company-by-id/insurance-company-by-id.component";
import { OrderComponent } from "./modules/order/order.component";
import { SearchServiceComponent } from "./modules/services/search-service/search-service.component";
import { PaypalSucessComponent } from "./modules/order/paypal-sucess/paypal-sucess.component";
import { ComparisonComponent } from "./modules/products/comparison/comparison.component";
import { ServicebyIdComponent } from "./modules/services/serviceby-id/serviceby-id.component";
import { FeaturedSearchComponent } from "./modules/products/featured-search/featured-search.component";
import { TermAndConditionsComponent } from "./modules/term-and-conditions/term-and-conditions.component";
import { ContactComponent } from "./modules/contact/contact.component";
// import { NotFoundComponent } from "./modules/not-found/not-found.component";
// import { ProductsModule } from "./modules/products/product.module";
import { FeaturedRequestPaymentComponent } from "./modules/featured-request-payment/featured-request-payment.component";
import { DashboardAdminComponent } from "./modules/dashboard-admin/dashboard-admin.component";
import { B2blayoutComponent } from "./modules/dashboard-admin/pages/b2blayout/b2blayout.component";
import { PermissionGuard } from "./modules/permission/guards/permission.guard";
import { KeyFeature } from "./commons/models/KeyFeature";
import {FreightOpportunitiesComponent} from './modules/freight-services/freight-opportunities/freight-opportunities.component';
import {
  FreightOpportunitiesDetailsComponent
} from './modules/freight-services/freight-opportunities/freight-opportunities-details/freight-opportunities-details.component';
import {
  PostFreightOpportunitiesComponent
} from './modules/freight-services/post-freight-opportunities/post-freight-opportunities.component';
import {FreightRequestDetailsComponent} from './modules/freight-request/freight-request-details/freight-request-details.component';

// import { ProductsModule } from "./modules/products/product.module";

const routes: Routes = [
  { path: "", component: HomeB2bComponent, pathMatch: "full" },
  {
    path: "terms-and-conditions",
    component: TermAndConditionsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "PostRequest",
    component: PostRequestComponent,
    canActivate: [AuthGuard , PermissionGuard],
    pathMatch: "full",
    // loadChildren: "./modules/trade-show/trade-show.module#TradeShowModule",
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH"],
      permissions: [
        KeyFeature.POST_BUY_REQUEST
      ],
    },
  },
  {
    path: "buy-request",
    component: BuyRequestComponent,
    pathMatch: "full",
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_SELLER", "ROLE_BOTH"],
      permissions: [KeyFeature.SEARCH_BUYING_OFFERS],
    },
  },
  {
    path: "buy-request/product",
    component: ResultByIdComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "ResultsCategory",
    component: ResultsAllCtaegoriesComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "profil",
    component: ProfileComponent,
    loadChildren: "./modules/profile/profile.module#ProfileModule",
    canActivate: [AuthGuard],
  },
  {
    // path: 'test', component: SubscriptionComponent,
    path: "subscription",
    component: SubscriptionComponent,
    loadChildren:
      "./modules/subscription/subscription.module#SubscriptionModule",

    data: {
      breadcrumb: "Subscription",
      activeRouteIndex: "0",
    },
  },
  {
    path: "accesstofinance",
    component: AccesToFinanceParentComponent,
    canActivate: [AuthGuard , PermissionGuard],
    loadChildren:
      "./modules/access-to-finance/access-to-finance.module#AccessToFinanceModule",
    data: {
      breadcrumb: "Access To Finance",
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH"],
      permissions: [KeyFeature.ACCESS_TO_FINANCE],
    },
    //   activeRouteIndex: "2",
  },

  {
    path: "order",
    component: OrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "search-importers-buyers",
    // path: "search-importers-buyers/:id",
    component: ProtectionComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_SELLER", "ROLE_BOTH"],
      permissions: [KeyFeature.SOURCE_FOR_IMPORTERS_BUYERS],
    },
  },
  {
    path: "search-suppliers-manufacturers/:id",
    component: SearchSuppliersComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "search-suppliers-byCategory",
    component: SearchSuppliersByCategoryComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "SourceSuppliers",
    component: SourceSuppliersManufacturesComponent,
    pathMatch: "full",
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH"],
      permissions: [KeyFeature.SOURCE_FOR_SUPPLIERS_MANUFACTURES],
    },
  },
  {
    path: "SourceImporters",
    component: SourceImportersBuyersComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Trade/addEvent",
    component: AddEventComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [KeyFeature.SUBMIT_YOUR_EVENT],
    },
  },
  {
    path: "Trade",
    component: TradeShowComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [KeyFeature.SEARCH_FOR_TRADE_EXHIBITIONS],
    },
  },
  {
    path: "TradeList",
    component: TradeListComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Show",
    component: ShowComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Product/:id",
    component: ProductDetailsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Manufacturer/:id",
    component: ManufacturerComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "freight",
    component: FreightRequestComponent,
    pathMatch: "full",
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [
        KeyFeature.SOURCE_FOR_FREIGHT_SERVICES_PROVIDERS,
        KeyFeature.FIND_FREIGHT_QUOTE,
      ],
    },
  },
  {
    path: "FreightLeads/freightOpportunities-details",
    component: FreightOpportunitiesDetailsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [
        KeyFeature.SOURCE_FOR_FREIGHT_SERVICES_PROVIDERS,
        KeyFeature.FIND_FREIGHT_QUOTE,
      ],
    },
  },
  {
    path: "freight/freightProvider-details",
    component: FreightRequestDetailsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [
        KeyFeature.SOURCE_FOR_FREIGHT_SERVICES_PROVIDERS,
        KeyFeature.FIND_FREIGHT_QUOTE,
      ],
    },
  },
  {
    path: "category/:catID/:catName",
    component: ProductsByCategoryComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "matchingService/profiler",
    component: MatchingProfilerComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [KeyFeature.MATCHING_PROFILER],
    },
  },
  {
    path: "categoryList/:catName",
    component: CategoryListComponent,
    pathMatch: "full",
    // canActivate: [AuthGuard],
  },
  {
    path: "Subcategory/:subCatName",
    component: ProductsByMainCategoryComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Classification/:classification",
    component: ProductsByClassificationComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Allcategories",
    component: AllMainCategoriesComponent,
    pathMatch: "full",
    // canActivate: [AuthGuard],
  },
  {
    path: "search-company",
    component: SearchCompanyComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "insurance",
    component: InsuranceComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "FreightRequest",
    component: PostFreightOpportunitiesComponent,
    pathMatch: "full",
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_BUYER", "ROLE_BOTH", "ROLE_SELLER"],
      permissions: [
        KeyFeature.REQUEST_FREIGHT_QUOTE,
        // KeyFeature.REQUEST_FREIGHT_QUOTES,
      ],
    },
  },
  {
    path: "FreightLeads",
    component: FreightOpportunitiesComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_SELLER", "ROLE_BOTH"],
      permissions: [KeyFeature.FIND_FREIGHT_REQUESTS],
    },
  },
  {
    path: "Freight-Request/:id",
    component: FreightLeadByIdComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Freight-Company/:id",
    component: FreightCompanyByIdComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "Insurance-Company/:id",
    component: InsuranceCompanyByIdComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "preferred-partners",
    component: PreferredPartnersComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_SELLER", "ROLE_BOTH"],
      permissions: [
        KeyFeature.INTRODUCE_PREFERRED_TRADE_AND_FINANCING_PARTNERS,
      ],
    },
  },
  {
    path: "Add-preferred-partners",
    component: AddPartnerComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "search-service",
    component: SearchServiceComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "pay/success/:id",
    component: PaypalSucessComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "pay/cancel",
    component: PaypalSucessComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "compare",
    component: ComparisonComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "service/:id",
    component: ServicebyIdComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "payFeatured/:id",
    component: FeaturedRequestPaymentComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "featuredProds",
    component: FeaturedSearchComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "contactUs",
    component: ContactComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },

  {
    path: "dashboard",
    component: DashboardAdminComponent,
    loadChildren:
      "./modules/dashboard-admin/dashboard-admin.module#DashboardAdminModule",
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Dashboard",
      // roles: ['ROLE_ADMIN', 'ROLE_SUB_ADMIN']
    },
  },
  {
    path: "B2BManagment",
    component: B2blayoutComponent,
    canActivate: [AuthGuard],
    loadChildren:
      "./modules/dashboard-admin/dashboard-admin.module#DashboardAdminModule",
    data: {
      breadcrumb: "Dashboard",
      roles: ["ROLE_ADMIN", "ROLE_SUB_ADMIN"],
    },
  },

  // roles: ['ROLE_ADMIN', 'ROLE_SUB_ADMIN'],
  // roles: ['ROLE_ADMIN', 'ROLE_SELLER','ROLE_BOTH'],
  // roles: ['ROLE_ADMIN', 'ROLE_BUYER' ,'ROLE_BOTH'],
  // roles: ['ROLE_ADMIN', 'ROLE_BOTH'],
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
