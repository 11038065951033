import {AfterViewInit,Component, ElementRef, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {environment} from "../../../environments/environment";
import { ZoomMtg } from '@zoomus/websdk';
import {UserService} from "../../commons/services/user.service";
import {ActivatedRoute} from "@angular/router";
import {TradeshowsService} from '../../commons/services/tradeshows/tradeshows.service';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Console } from 'console';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const jwt = require('jsonwebtoken');
@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit ,AfterViewInit{

  @ViewChild('chartElement', {static: false}) chartElement: ElementRef<HTMLElement>;
  isVisible = false;
  ZoomAppBaseUrl = 'http://demo.peaqock.com/webinars/';
  // ZoomAppBaseUrl = environment.ZoomAppBaseUrl;
  apiKey = 'CZldDtqERieOrGx_XztDhQ'; // Your Zoom API Key
  apiSecret = 'LJmNj8qPj3VXbM6MXliJXgp3vwOCg5ExhcMR'; // Your Zoom API Secret
  meetingNumber = '0'; // always generate new when requested or user will input
  role = 0;
  leaveUrl = 'http://demo.peaqock.com/Trade'; // Your redirect Url, when meeting over
  userName = 'Emeka';
  userEmail = 'eonyia@afreximbank.com';
  email='';
  meetingPassWord = ''; // set default meeting password here
  webinar:any;
  event:any;
  designation = null;
  designations =["Director","Proprietor","Partner","CEO","Owner","General Manager","Managing Director","Marketing Manager","Sales Manager","Other"]
  user =null;
  numberParticipants =null;
  firstName =null;
  emailToRegister =null;
  lasttName =null;
  participants = [];
  pieData = [];
  participantsFromDb = [];
  checked = true;
  registered = false;
  inDB = false;
  loading = true;
  loading1 = false;
  eventId;


  constructor(private tradeshowsService:TradeshowsService,private activatedRoute: ActivatedRoute
    ,private toaster: ToastrService,private userService: UserService,public httpClient: HttpClient, @Inject(DOCUMENT) document, private formBuilder: FormBuilder) {

    this.getUser();
    this.activatedRoute.queryParams.subscribe(params => {
      this.eventId = params['id'];
      this.getEventById(this.eventId);
  });

}

ngAfterViewInit() {

  setTimeout(() => {
    this.initPie();

  }, 3500);
}
  getwebinar(){

      const payload = {
        iss: this.apiKey,
        exp: ((new Date()).getTime() + 500)
      };

      const zoomAccessToken = jwt.sign(payload, this.apiSecret);

      const request = {
        Token: zoomAccessToken,
        webinarId:this.meetingNumber,
      };
      this.httpClient.post(`${this.ZoomAppBaseUrl}zoomfeature/meeting/`,request).toPromise().then((response: any) => {
        console.log(response);
        this.webinar=response
      }).catch((error) => {
        console.log(error)
      });
    }


  getEventById(id){
    this.tradeshowsService.getEvent(id).subscribe((resp: any) => {
      this.event=resp;
      console.log("evvvvvvvvvvvent",this.event)

      this.getParticipants();
      this.getEventParticipantPie(id);

    })
  }
  getEventParticipantPie(id){
    this.tradeshowsService.getParticipantsPie(id).subscribe((resp: any) => {
      this.pieData=resp;
      console.log("evvvvvvvvvvvent",this.event)
    })
  }
  getParticipants(){
    const payload = {
      iss: this.apiKey,
      exp: ((new Date()).getTime() + 50000)
    };
    const zoomAccessToken = jwt.sign(payload, this.apiSecret);
    const request = {
      Token: zoomAccessToken,
      meetingNumber:this.event.eventId,
    };

    this.httpClient.post(`${this.ZoomAppBaseUrl}zoomfeature/registrants/list`,request)
    .subscribe((response: any) => {
      console.log("zoom API",response.registrants);
      this.numberParticipants=response.registrants.length
      this.participants=response.registrants
      console.log("zoom API",this.participants);
      this.checkParticipant();

    });
  }



  getSignature() {
    this.httpClient.post(this.ZoomAppBaseUrl, {
      meetingNumber: this.event.eventId,
      role: this.role
    }).toPromise().then((data: any) => {
      if(data.signature) {
        console.log(data.signature)
        this.startMeeting(data.signature);
        // this.registerInDb();
      } else {
        console.log(data)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  startMeeting(signature) {

    document.getElementById('headerB2B').style.display = 'none';
    document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      debug: true,
      leaveUrl: this.leaveUrl, //redirect url after meeting end
      isSupportAV: true,
      isSupportChat: true,
      sharingMode: 'both',
      screenShare: true,
      videoHeader: true,
      success: (success) => {
        console.log(success);
        console.log("opened register");

        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.event.eventId,
          userName: this.userName,
          apiKey: this.apiKey,
          userEmail: this.email,

          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })

  }
  registerInDb(){
   this.loading1 = true;
    const payload = {
      iss: this.apiKey,
      exp: ((new Date()).getTime() + 50000)
    };
    const zoomAccessToken = jwt.sign(payload, this.apiSecret);
    let registerData={
          "Token":zoomAccessToken,
          "meetingNumber":this.event.eventId,
          "registrant":{
            "email": this.emailToRegister,
            "first_name": this.firstName,
            "last_name": this.lasttName,
          },
    }
    this.httpClient.post(`${this.ZoomAppBaseUrl}zoomfeature/registrants/add`, registerData).toPromise().then((response: any) => {
      console.log(response);


      let data ={
        participant : this.user,
        tradeShowId : this.eventId,
        designation : this.designation,

      }
      this.tradeshowsService.addParticipant(data).subscribe((resp: any) => {
        console.log(resp);
        console.log("Registred in db",resp);
        this.getParticipantsfromDb();
        this.getParticipants();
        this.getEventParticipantPie(this.event.id);
        setTimeout(() => {
          this.initPie();

        }, 3500);
      },(err)=>{
        console.log(err);
      })

    }).catch((error) => {
      console.log(error)
    });
    setTimeout(() => {
      this.toaster.success(
        "You have registered successfully to this event",
        "Success",
        {
          closeButton: true,
          timeOut: 5000
        })
        this.loading1 = false;
        this.isVisible = false;

    }, 5000);



  }
  checkParticipant(){
    // this.getParticipants();
    let index = this.participants.findIndex(x => x.email === this.email);
    if(index == -1){
      this.registered = false
     }else{
       this.registered = true
     }
     console.log("isRegistred",this.participants)
     console.log("isRegistred",this.registered)
     console.log("isRegistred",index == -1)

     this.loading=false;
    //  this.initPie();
  }
  getUser(){

    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        this.userName=res.firstName + ' ' + res.lastName;
        this.userEmail=res.email;
        this.email=res.email
        this.emailToRegister=res.email
        this.user=res
      })

      // console.log("ussserr",this.event.user.email);
    //   console.log("evvvveent",this.userEmail);
    //  if( this.event.user.email==this.user.email){
    //    this.registered =true;
    //  }
  }
  }
  getParticipantsfromDb(){

    this.tradeshowsService.getParticipants(this.eventId).subscribe(
      (res: any) => {
        this.participantsFromDb=res;
        let index = this.participantsFromDb.findIndex(x => x.email === this.email);
        if(index == -1){
          this.inDB = false
         }else{
           this.inDB = true
         }
      console.log("inDB",this.inDB)
      })


  }
  ngOnInit() {
    const payload = {
      iss: this.apiKey,
      exp: ((new Date()).getTime() + 500)
    };
    const zoomAccessToken = jwt.sign(payload, this.apiSecret);
    console.log("zooom token",zoomAccessToken)



  console.log("EVVVVNNT",this.event);

    setTimeout(() => {

      console.log("pie");

      console.log("ussserr",this.user);

      // this.initPie();
      this.getParticipantsfromDb();

    }, 1000);
  }
  showModalMiddle(): void {
    this.isVisible = true;

  }
  registerTomeeting(){
    this.getSignature();
  }
  joinMeeting(){
    if(this.user.email==this.event.user.email){
    this.role = 1;
    this.email = 'm.benkhaled@peaqock.com';
  }
  console.log("role",this.role)
  console.log("email",this.email)
    this.getSignature();
  }

  handleOk(): void {
    // this.role = 1;
    // this.userEmail = 'm.benkhaled@peaqock.com';
    this.registerInDb();
  }

  handleCancel(): void {
    this.isVisible = false;
    // this.getSignature();
  }

initPie(){
  // am4core.ready(function() {
 /* Chart code */
// Themes begin

console.log("piiiie");
am4core.useTheme(am4themes_animated);
// Themes end

let chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart3D);
chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

// chart.legend = new am4charts.Legend();

chart.data = this.pieData;
// ];

let series = chart.series.push(new am4charts.PieSeries3D());
series.dataFields.value = "count";
series.dataFields.category = "designation";
series.ticks.template.disabled = true;
// series.alignLabels = false;
// series.labels.template.text = "{country}";
// series.labels.template.radius = am4core.percent(-40);
// series.labels.template.fill = am4core.color("white");
series.labels.template.fontSize = 12;
series.labels.template.disabled = true;

series.slices.template.stroke = am4core.color("#fff");
series.slices.template.strokeOpacity = 2;
series.ticks.template.disabled = true;
}
// );
}
// }
