import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {

  private message = new BehaviorSubject(null);

  sharedMessage = this.message.asObservable();

  private events = new BehaviorSubject([]);
  sharedEvents = this.events.asObservable();


  private user = new BehaviorSubject(null);
  sharedUser = this.user.asObservable();

  private click = new BehaviorSubject(null);
  sharedClick = this.click.asObservable();

  public data={
    product:"",
    country:"",
    period:""
  };
  private eventClick = new BehaviorSubject(null);
  sharedClickEvent = this.eventClick.asObservable();


  private searchBuyLeads = new BehaviorSubject(null);
  sharedSearchBuyLeads = this.searchBuyLeads.asObservable();

  private searchProductByID = new BehaviorSubject(null);
  sharedSearchProductByID = this.searchProductByID.asObservable();

  private searchByCategories = new BehaviorSubject(null);
  sharedSearchyCategories= this.searchByCategories.asObservable();


  private quote = new BehaviorSubject(null);
  Sharedquote= this.quote.asObservable();

  private searchSupplierByCategorie = new BehaviorSubject({categoriesId:[], countries:[]});
  sharedSupplierByCategorie = this.searchSupplierByCategorie.asObservable();

  constructor() { }

  nextMessage(message: string) {
    this.message.next(message)
  }
  nextClick(click: any) {
    this.click.next(click)
  }
  nextEvents(events: any[]) {
    this.events.next(events);
  }

  nextUser(user) {
    this.user.next(user)
  }
  nextSearchBuyLeads(buyLeads) {
    this.searchBuyLeads.next(buyLeads)
  }
  nextSearchProductByID(id) {
    this.searchProductByID.next(id)
  }
  nextSearchsearchByCategories(id) {
    this.searchByCategories.next(id)
  }

  nextsearchSupplierByCategorie(searchSupplierByCategorie) {
    this.searchSupplierByCategorie.next(searchSupplierByCategorie)
  }
  nextPostBuy(quote:any){
    this.quote.next(quote)
  }
}
