import { INT_TYPE } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TradeValueChainService } from 'src/app/commons/services/service tradeValueChain/trade-value-chain.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-trad-value-chain',
  templateUrl: './list-trad-value-chain.component.html',
  styleUrls: ['./list-trad-value-chain.component.css']
})
export class ListTradValueChainComponent implements OnInit {

  baseUrl = environment.baseUrl + '/';


  public listfiltercountry = [];
  effect = 'scrollx';
  public image = [];
  public companyList = [];
  public categoriesList = [];
  public selectedList = [];
  public selectcategoriesList = [];
  public categoryListId = [];
  public listOfId=[];

  //country
  public countryList = [];
  public countryListId = [];

  public description: string = null;
  public subCategory: string = null;
  public companyName: string = null;
  public name: string = null;
  public nameof: string = null;
  public icon: string = null;
  public message: string = null;
  public category: string = null;
  public minPrice: number = null;
  public maxPrice: number = null;
  public categoryId: number = null;

  public count: number = null;
  public nbrSlice = 0;
  public supplierId;
  public supplier;
  listNameCatg: any[];
  public listShowcountry = [];

  constructor(private tradeValueChainService: TradeValueChainService, private router: ActivatedRoute) { }
  addCountry(idCountry,item) {
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item)
      console.log(this.listfiltercountry);
    }
    this.searchCompany();
    console.log(this.listfiltercountry);
  }
  deleteCountry(idCountry,item){
      let index: number = this.listfiltercountry.indexOf(idCountry);
      let index2: number = this.listShowcountry.indexOf(item);
      if (index !== -1) {
        this.listfiltercountry.splice(index, 1);
    }
    if (index2 !== -1) {
      this.listShowcountry.splice(index2, 1);
  }
    this.searchCompany();
    console.log(this.listfiltercountry);
  }
  checkExistCountry(id) {
    if (this.listfiltercountry.includes(id)) {
      return true
    } else {
      return false
    }
  }
  getByCotegorie(id) {
    this.tradeValueChainService.getByCategories(id).subscribe((response: any[]) => {
      this.companyList = response;
      console.log(this.companyList);
      this.count = this.companyList.length;
    })
  }
  getServiceCategorie() {

    this.tradeValueChainService.getServiceCategories().subscribe((response: any[]) => {
      this.categoriesList = response;
      console.log(this.categoriesList)
      this.sliceCategorie();
    })
  }

  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.selectedList = this.categoriesList.slice(0, this.nbrSlice);
  }

  checkCategory(id) {
    console.log("idCategory : ", id);
    if (!this.categoryListId.includes(id)) {
      this.categoryListId.push(id);
    }
    else {
      let index: number = this.categoryListId.indexOf(id);
      if (index !== -1) {
        this.categoryListId.splice(index, 1);
      }
    }
    this.searchCompany();
    console.log(this.categoryListId)
  }
  addName(names){
    console.log("idCategory : ", names);
    if (!this.listNameCatg.includes(names)) {
      this.listNameCatg.push(names);
    }
    else {
      let index: number = this.listNameCatg.indexOf(names);
      if (index !== -1) {
        this.listNameCatg.splice(index, 1);
      }
    }
    this.searchCompany();
    console.log(this.listNameCatg)

  }

  checkExist(id) {
    if (this.categoryListId.includes(id)) {
      return  true;
    } else {
      return  false;
    }
  }
  searchCompany() {
    let data = {
      fromPrice: this.minPrice,
      toPrice: this.maxPrice,
      categoriesId: this.categoryListId,
      countries: this.listfiltercountry
    }
    this.tradeValueChainService.serviceSearch(data).subscribe((resp: any) => {
      console.log(resp);
      this.companyList = resp.services;
      this.countryList = resp.numberByCountryList;
      console.log(this.companyList);
      console.log(this.countryList);
      this.count=this.companyList.length;
    })
  }
  getParams() {
    this.router.queryParams.subscribe(res => {
      this.name = res.name;
      this.categoryId = parseInt(res.id);
      this.message = res.msg;
      this.countryListId=[];//country
      this.categoryListId = [];
      this.categoryListId.push(parseInt(res.id));
      this.listNameCatg=[];
      this.listNameCatg.push(res.name)
      console.log("list countryyy", this.countryListId);
      console.log("list namessss", this.listNameCatg);
      this.searchCompany();

    })
  }



  ngOnInit() {
    this.getServiceCategorie();
    this.getParams();

  }
}
