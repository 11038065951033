import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map, switchMap } from "rxjs/operators";
import { BehaviorSubject, timer } from "rxjs";
import { KeyFeature } from "../models/KeyFeature";
@Injectable({ providedIn: "root" })
export class UserService {
  constructor(private http: HttpClient) {}

  private userPermissions$: BehaviorSubject<KeyFeature[]> = new BehaviorSubject<
    KeyFeature[]
  >(null);

  private currentUser$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  baseUrl = environment.baseUrl;
  editenable: boolean = true;

  login(body: any) {
    return this.http.post(this.baseUrl + "/user/authenticate", body);
  }
  validateMansaId(africanKey: string) {
    return timer(1000)
      .pipe(
        switchMap(() => {
          // returns a boolean whether the mansa is valid or not
          return this.http.get<any>(this.baseUrl + '/company/checkmansaid/' + africanKey);
        })
      );
  }
  findById(id: any) {
    return this.http.get(this.baseUrl + "/user/" + id);
  }
  getAuthUser(id) {
    return this.http.get(this.baseUrl + "/user/me");
  }

  // getAuthUser(id) {
  //   return this.http.get(this.baseUrl + "/user/" + id);
  // }
  // // we change this api to /user/me

  // getInfoUser(){
  //     let id = localStorage.getItem("USER_ID");
  //     console.log(id);
  //     let data
  //     this.userService.findById(id).subscribe(
  //      (res: any) => {
  //        data= res;
  //        console.log(data);
  //        return data
  //      })
  //    }
  forgotPassword(body: any) {
    let formData = new FormData();
    formData.append("email", body.email);
    return this.http.put(this.baseUrl + "/user/forgotPassword", formData);
  }

  //     validateEmail(email: string) {
  //     return timer(1000)
  //         .pipe(
  //             switchMap(() => {
  //                 // returns a boolean whether the email is valid or not
  //                 return this.http.get<any>(this.baseUrl + '/user/checkEmailNotExist' + email.toLowerCase());
  //             })
  //         );
  // }

  refreshToken() {
    return this.http.post<any>(this.baseUrl + "/user/refreshtoken", {
      refreshToken: localStorage.getItem(environment.REFRESH_TOKEN_NAME),
    });
  }

  validateEmail(email) {
    return this.http.post<any>(this.baseUrl + "/user/validateEmail", {
      email: email.toLowerCase(),
    });
  }

  public get currentUser(): any {
    return this.currentUser$.value;
  }

  public set currentUser(user: any) {
    this.currentUser$.next(user);
  }

  getSubscribersByPayment() {
    return this.http.get<any>(this.baseUrl + "/user/subscribers");
  }
  getSubscribershistory() {
    return this.http.get<any>(
      this.baseUrl + "/dashboard/user/subscription-history"
    );
  }

  public get userPermissions(): KeyFeature[] {
    return this.userPermissions$.value;
  }

  public set userPermissions(features: KeyFeature[]) {
    this.userPermissions$.next(features);
  }

  getUserPermissions(id:any) {
    return this.http.get<any>(`${this.baseUrl}/user/getUserHistory/${this.currentUser.id}`).pipe(
      map((permissions) => {
        const permissionArray: KeyFeature[] = [];
        permissions["keyFeaturs"].map((modules) => {
          modules["featurs"].map((feature:any) => {
            permissionArray.push(feature.name as KeyFeature);
          });
        });
        console.log('getUserPermissions',permissionArray)
        return permissionArray;
      })
    );
  }
}
