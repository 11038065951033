import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ShowCaseService } from 'src/app/commons/services/showcase/showCase.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent implements OnInit {
  constructor(private showCaseService : ShowCaseService,private router: Router) { }
  loading = false;
  loading1 = false;
  index=0;
  prodToshow :any;
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;

  slideConfig = {"slidesToShow": 4, "slidesToScroll": 4};
  utilSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    autoplay:true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 3550,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        }
      },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,

          }
        },
        {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  url = environment.baseUrl + '/';
  showCaseProducts =[];
  showCaseServices = [];
  classes =
  [
    {background:"#d6cbbf",colorLabel:"#968473",backButton:"#968473"},
    {background:"#ceeaf9",colorLabel:"#4281a2",backButton:"#64a9ce"},
    {background:"#f3a3b6",colorLabel:"#ae556a",backButton:"#d48295"},
    {background:"#8ab3e1",colorLabel:"#4e81b8",backButton:"#598fcc"},
    {background:"#b1d4da",colorLabel:"#5b6169",backButton:"#6b9e9f"},
    {background:"#abcfd0",colorLabel:"#29757c",backButton:"#6b9e9f"},
    {background:"#5c5ffb",colorLabel:"#3336a2",backButton:"#8081e1"},
    {background:"#257097",colorLabel:"#193e53",backButton:"#dfb21e"},
    {background:"#fde181",colorLabel:"#b99316",backButton:"#b99316"},
    {background:"#69ca9e",colorLabel:"#359068",backButton:"#53af85"},
    {background:"#ffb456",colorLabel:"#d77f10",backButton:"#e58915"},
  ]

  ngOnInit() {
    // this.getProd();
    this.load();
  }
  load(){
    this.loadServices();
    this.loadProducts();
  }
  nextProd(){
    this.index=this.index+1;
    this.index = this.index % this.showCaseProducts.length;
  }
  PrevProd(){
    this.index=this.index-1;
  }
  getProd(){
    this.prodToshow = this.showCaseProducts[this.index];
    console.log("prod to show" ,this.prodToshow)
    console.log("prod to show" ,this.showCaseProducts[this.index])
  }
  loadProducts() {
    this.showCaseProducts = [];
    this.loading=true;
    this.showCaseService.getCategories().subscribe((resp :any) => {
      resp.forEach( category => {
        let item;
        item = {...category, style : this.getrandomItem() }
        this.showCaseProducts.push(item)
      })

    this.loading=false;

    });
  }


  loadServices() {
    this.showCaseServices = [];
    this.loading1=true;
    this.showCaseService.getServiceCategories().subscribe((resp :any) => {
      resp.forEach( category => {
        let item;
        item = {...category, style : this.getrandomItem() }
        this.showCaseServices.push(item)
      })
      this.loading1=false;
    });
  }


  getrandomItem(){
    const item = Math.floor(Math.random() * this.classes.length);
    return this.classes[item];
  }
  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }
  openProdInNewWindow(prod) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/Product/${prod.id}`])
    );

    window.open(url, '_blank');
  }
// loadData(){
//   this.sharedService.sharedMessage.subscribe(message => this.message = message)
// }
}
