import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  baseUrl = environment.baseUrl+'/emails';

  constructor(private http: HttpClient) { }

  url = 'https://translation.googleapis.com/language/translate/v2?key=';
  key = 'b3659c52f3b7444aa7229bc63f4a2c67c54cc2ee';
  POST ="https://translation.googleapis.com/v3/projects/b2b-project-298010:translateText";
  test= 'https://www.googleapis.com/language/translate/v2?key=${firebaseConfig.firebase.apiKey}&source=en&target=${lang}&q=${text}'
  apiKey = "AIzaSyCeE_cWVFNP7z9WolR05dz0lo8MwYjCL58";
  
  translate(obj) {return this.http.post(this.POST, obj);}

  translatetoSpanish(obj) {
   return  this.http.post(this.url +this.key, obj);
  }

 
}

export interface GoogleObj {q: string[];target: string;}