import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from '../order/order.component';
import { OrderRoutingModule } from './order-routing.module';
import { NgZorroAntdModule, NzCheckboxModule, NzDropDownModule, NzFormModule, NzGridModule, NzPopoverModule, NzRadioModule, NzStepsModule } from 'ng-zorro-antd';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ShippingDetailsComponent } from './shipping-details/shipping-details.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { CustomOrderStepperComponent } from './custom-order-stepper/custom-stepper.component';
import { OrderPaymentComponent } from './order-payment/order-payment.component';
import { StartOrderComponent } from './start-order/start-order.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { PaypalSucessComponent } from './paypal-sucess/paypal-sucess.component';



@NgModule({
  declarations: [OrderComponent, StartOrderComponent, BillingDetailsComponent , ShippingDetailsComponent, OrderDetailsComponent,
    OrderPaymentComponent,
    PaypalSucessComponent ],
   imports: [
    CommonModule,
    OrderRoutingModule,
    NzFormModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    FormsModule,
    RecaptchaFormsModule,
    NzGridModule,
    NgZorroAntdModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzRadioModule,
    NzPopoverModule,
    NzStepsModule ,
  ]
})
export class OrderModule { }
