import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckPermissionDirective } from "./directives/check-permission.directive";
import { PermissionGuard } from "./guards/permission.guard";
import { PermissionService } from "./services/permission.service";
import { HeaderPermissionsDirective } from './directives/header-permissions.directive';

@NgModule({
  declarations: [CheckPermissionDirective, HeaderPermissionsDirective],
  imports: [CommonModule],
  exports: [CheckPermissionDirective, HeaderPermissionsDirective],
  providers: [PermissionGuard, PermissionService],
})

export class PermissionModule { }
