import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {


  baseUrl = environment.baseUrl;
  
  
  constructor(private http:HttpClient) {
               this.http = http;
   }


   getAllMessage(){
    return this.http.get(this.baseUrl+'/contacts')
  }

  search(criteria){
    return this.http.get(this.baseUrl+'/contacts/search',{params : criteria})
  }

  getFilterByPagination(criteria){
    return this.http.get(this.baseUrl +'/contacts/filter',{params : criteria})
 }
 deleteContact(id:any){
   return this.http.delete(this.baseUrl+'/contacts/'+id)
 }
 getAllMessageBetween(criteria){
   return this.http.get(this.baseUrl+'/contacts/getAllMessageBetween',{params :criteria});
 }
 download(id:any){
  let headers = new HttpHeaders();
  headers = headers.set('Accept', 'application/pdf');
   return this.http.get(this.baseUrl+'/contacts/'+id+'/download',{ headers, responseType: 'blob' });
 }

 getAllMessageNotSeen(){
  return this.http.get(this.baseUrl+'/contacts/getAllMessageNotSeen') 
 }


 getAllMessageBetweenSearch(criteria){
  return this.http.get(this.baseUrl+'/contacts/getAllMessageBetweenSearch',{params :criteria});
}

contactNow(contact:any){
  return this.http.post(this.baseUrl+'/contacts',contact);
}
uploadFile(id:any,body:any){
    return this.http.post(this.baseUrl+'/contacts/'+id+"/upload",body,{ responseType: 'text' });
 }

 changePassword(body:any){
  return this.http.put(this.baseUrl+'/user/changepassword/',body,{ responseType: 'text' });
}

forgotPassword(body:any){
  return this.http.put(this.baseUrl+'/user/forgotPassword/',body,{ responseType: 'text' });
}

dectivationReason(id,body:any){
  return this.http.delete(this.baseUrl+'/subscriptions/'+id,body);
}

}