import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MailService } from "src/app/commons/services/mail service/mail.service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";
import { UserService } from "src/app/commons/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manufacturer",
  templateUrl: "./manufacturer.component.html",
  styleUrls: ["./manufacturer.component.css"],
})
export class ManufacturerComponent implements OnInit {
  effect = "scrollx";
  urls = [
    "assets/icons/Company.png",
    "assets/icons/bg.png",
    "assets/icons/TradeShows2.png",
    "assets/icons/TradeShows3.png",
  ];
  urls1 = [
    "assets/images/coffe.png",
    "assets/images/coffe.png",
    "assets/images/coffe.png",
    "assets/images/coffe.png",
  ];

  public baseUrl = environment.baseUrl + "/file/aws/download/";
  manufacturertId;
  companyInfo;
  productInfo = [];
  public messageToSend = "";
  isVisible = false;
  public reciver = null;
  agree = false;
  intrestsProds = [];
  interested;

  imgUrl = environment.baseUrl + "/file/aws/download/";

  constructor(
    private b2bProductService: B2bProductService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private mailService: MailService,
    private toaster: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUser();
    this.route.params.subscribe((data) => {
      this.manufacturertId = data.id;
      this.getManufacturerInfobyId();
      this.getProductInfobyId();
    });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log(res);
        this.intrestsProds = res.subscription.interestServiceIds;
        let prod = res.subscription.interestSubscriptionIds.find(
          (x) => x === this.manufacturertId.toString()
        );
        console.log("intererere", prod);
        if (prod == null || prod == undefined) {
          this.interested = false;
        } else {
          this.interested = true;
        }
      });
    }
  }

  getManufacturerInfobyId() {
    this.subscriptionService
      .getById(this.manufacturertId)
      .subscribe((resp: any) => {
        this.companyInfo = resp;
        console.log("companyInfo", this.companyInfo);
      });
  }

  getProductInfobyId() {
    this.b2bProductService
      .getMoreProductbySubscriptionId(this.manufacturertId)
      .subscribe((resp: any) => {
        this.productInfo = resp;
        console.log(resp);
      });
  }
  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }
  showModal(item): void {
    this.reciver = item;
    this.isVisible = true;
  }

  log(item) {
    this.messageToSend = item;
  }
  sendMail(item) {
    let data = {
      receiver: item.email,
      message: this.messageToSend,
      subject: "We are a Match",
      isBuyLeadInquiry: false,
      isWebInquiry: true,
      isAllCountry: false,
      isAllCompany: false,
      location: item.country,
    };

    console.log("datatat", item);
    console.log("datatat", data);

    this.mailService.sendMessage(data).subscribe(
      (resp) => {
        console.log("resap : ", resp);
        this.toaster.success(
          "The inquiry has been sent succefully",
          "Success",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.isVisible = false;
      },
      (err) => {}
    );
  }
  addTointer() {
    let type = "subscription";
    this.b2bProductService
      .addToInterest(this.manufacturertId, type)
      .subscribe((resp: any) => {
        this.toaster.success(
          this.companyInfo.companyName + " has been added to your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.interested = true;
      });
  }
  removeFrominter() {
    let type = "subscription";
    this.b2bProductService
      .removeInterest(this.manufacturertId, type)
      .subscribe((resp: any) => {
        this.toaster.info(
          this.companyInfo.companyName +
            " has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.interested = false;
      });
  }
  verifyInterest() {
    console.log("intererere", this.intrestsProds);
    let prod = this.intrestsProds.find(
      (x) => x === this.manufacturertId.toString()
    );
    console.log("intererere", prod);
    if (prod == null || prod == undefined) {
      this.interested = false;
    } else {
      this.interested = true;
    }

    console.log("intererere", this.interested);
  }
}
