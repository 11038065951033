export const services = [
  {
    "code": "SA",
    "service": "Manufacturing services on physical inputs owned by others"
  },
  {
    "code": "SAY",
    "service": "Goods for processing in reporting economy"
  },
  {
    "code": "SAZ",
    "service": "Goods for processing abroad"
  },
  {
    "code": "SB",
    "service": "Maintenance and repair services n.i.e."
  },
  {
    "code": "SC",
    "service": "Transport"
  },
  {
    "code": "SC1",
    "service": "Sea transport"
  },
  {
    "code": "SC11",
    "service": "Sea transport, passenger"
  },
  {
    "code": "SC11Z",
    "service": "Sea transport, passenger, payable by border, seasonal, and other short-term workers"
  },
  {
    "code": "SC12",
    "service": "Sea transport, freight"
  },
  {
    "code": "SC13",
    "service": "Sea transport, other"
  },
  {
    "code": "SC2",
    "service": "Air transport"
  },
  {
    "code": "SC21",
    "service": "Air transport, passenger"
  },
  {
    "code": "SC21Z",
    "service": "Air transport, passenger, payable by border, seasonal, and other short-term workers"
  },
  {
    "code": "SC22",
    "service": "Air transport, freight"
  },
  {
    "code": "SC23",
    "service": "Air transport, other"
  },
  {
    "code": "SC3",
    "service": "Other modes of transport"
  },
  {
    "code": "SC31",
    "service": "Other modes of transport, passenger"
  },
  {
    "code": "SC31Z",
    "service": "Other modes of transport, passenger, payable by border, seasonal, and other short-term workers"
  },
  {
    "code": "SC32",
    "service": "Other modes of transport, freight"
  },
  {
    "code": "SC33",
    "service": "Other modes of transport, other"
  },
  {
    "code": "SC4",
    "service": "Postal and courier services"
  },
  {
    "code": "SCA",
    "service": "Transport, passenger"
  },
  {
    "code": "SCAZ",
    "service": "Transport, passenger, payable by border, seasonal, and other short-term workers"
  },
  {
    "code": "SCB",
    "service": "Transport, freight"
  },
  {
    "code": "SCC",
    "service": "Transport, other"
  },
  {
    "code": "SCC1",
    "service": "Other transport, other"
  },
  {
    "code": "SD",
    "service": "Travel"
  },
  {
    "code": "SD1",
    "service": "Goods"
  },
  {
    "code": "SD2",
    "service": "Local transport services"
  },
  {
    "code": "SD3",
    "service": "Accomodation services"
  },
  {
    "code": "SD4",
    "service": "Food-serving services"
  },
  {
    "code": "SD5",
    "service": "Other services"
  },
  {
    "code": "SD5Y",
    "service": "Education services"
  },
  {
    "code": "SD5Z",
    "service": "Health services"
  },
  {
    "code": "SDA",
    "service": "Business"
  },
  {
    "code": "SDA1",
    "service": "Acquisition of goods and services by border, seasonal, and other short-term workers"
  },
  {
    "code": "SDA2",
    "service": "Business travel, other"
  },
  {
    "code": "SDB",
    "service": "Personal"
  },
  {
    "code": "SDB1",
    "service": "Health-related"
  },
  {
    "code": "SDB2",
    "service": "Education-related"
  },
  {
    "code": "SDB3",
    "service": "Personal travel, other"
  },
  {
    "code": "SDZ",
    "service": "Tourism-related services in travel and passenger transport"
  },
  {
    "code": "SE",
    "service": "Construction"
  },
  {
    "code": "SE1",
    "service": "Construction abroad"
  },
  {
    "code": "SE2",
    "service": "Construction in the reporting economy"
  },
  {
    "code": "SF",
    "service": "Insurance and pension services"
  },
  {
    "code": "SF1",
    "service": "Direct insurance"
  },
  {
    "code": "SF11",
    "service": "Life insurance"
  },
  {
    "code": "SF11Y",
    "service": "Gross life insurance premiums receivable"
  },
  {
    "code": "SF11Z",
    "service": "Gross life insurance claims payable"
  },
  {
    "code": "SF2",
    "service": "Reinsurance"
  },
  {
    "code": "SF3",
    "service": "Auxiliary insurance services"
  },
  {
    "code": "SF4",
    "service": "Pension and standardized guarantee services"
  },
  {
    "code": "SF41",
    "service": "Pension services"
  },
  {
    "code": "SF42",
    "service": "Standardized guarantee services"
  },
  {
    "code": "SG",
    "service": "Financial services"
  },
  {
    "code": "SG1",
    "service": "Explicitly charged and other financial services"
  },
  {
    "code": "SG2",
    "service": "Financial intermediation services indirectly measured (FISIM)"
  },
  {
    "code": "SH",
    "service": "Charges for the use of intellectual property n.i.e"
  },
  {
    "code": "SH1",
    "service": "Franchises and trademarks licensing fees"
  },
  {
    "code": "SH2",
    "service": "Licenses for the use of outcomes of research and development"
  },
  {
    "code": "SH3",
    "service": "Licenses to reproduce and/or distribute computer software"
  },
  {
    "code": "SH4",
    "service": "Licenses to reproduce and/or distribute audiovisual and related products"
  },
  {
    "code": "SH41",
    "service": "Licenses to reproduce and or distribute audiovisual products"
  },
  {
    "code": "SI",
    "service": "Telecommunications, computer, and information services"
  },
  {
    "code": "SI1",
    "service": "Telecommunications services"
  },
  {
    "code": "SI2",
    "service": "Computer services"
  },
  {
    "code": "SI3",
    "service": "Information services"
  },
  {
    "code": "SJ",
    "service": "Other business services"
  },
  {
    "code": "SJ1",
    "service": "Research and development services"
  },
  {
    "code": "SJ2",
    "service": "Professional and management consulting services"
  },
  {
    "code": "SJ21",
    "service": "Legal, accounting, auditing, bookkeeping, and tax consulting services"
  },
  {
    "code": "SJ211",
    "service": "Legal services"
  },
  {
    "code": "SJ212",
    "service": "Accounting, auditing, bookkeeping, and tax consulting services"
  },
  {
    "code": "SJ213",
    "service": "Business and management consulting and public relations services"
  },
  {
    "code": "SJ22",
    "service": "Advertising, market research, and public opinion polling services"
  },
  {
    "code": "SJ3",
    "service": "Technical, trade-related and other business services"
  },
  {
    "code": "SJ31",
    "service": "Architectural, engineering, scientific and other technical services"
  },
  {
    "code": "SJ312",
    "service": "Engineering services"
  },
  {
    "code": "SJ313",
    "service": "Scientific and other technical services"
  },
  {
    "code": "SJ32",
    "service": "Waste treatment and de-pollution, agricultural and mining services"
  },
  {
    "code": "SJ322",
    "service": "Services incidental to agriculture, forestry and fishing"
  },
  {
    "code": "SJ33",
    "service": "Operating leasing services"
  },
  {
    "code": "SJ34",
    "service": "Trade-related services"
  },
  {
    "code": "SJ35",
    "service": "Other business services n.i.e."
  },
  {
    "code": "SJ35Z",
    "service": "Employment services i.e. search, placement and supply services of personnel"
  },
  {
    "code": "SK",
    "service": "Personal, cultural, and recreational services"
  },
  {
    "code": "SK1",
    "service": "Audiovisual and related services"
  },
  {
    "code": "SK2",
    "service": "Other personal, cultural, and recreational services"
  },
  {
    "code": "SL",
    "service": "Government goods and services n.i.e."
  },
  {
    "code": "SOX",
    "service": "Commercial services"
  },
  {
    "code": "SOX1",
    "service": "Other commercial services"
  },
  {
    "code": "SPX1",
    "service": "Other services"
  },
  {
    "code": "SN",
    "service": "Services not allocated"
  },
  {
    "code": "SJ321",
    "service": "Waste treatment and de-pollution"
  },
  {
    "code": "SF12",
    "service": "Freight insurance"
  },
  {
    "code": "SF13",
    "service": "Other direct insurance"
  },
  {
    "code": "SH42",
    "service": "Licenses to reproduce and/or distribute other products"
  },
  {
    "code": "SI21",
    "service": "Computer software"
  },
  {
    "code": "SI22",
    "service": "Other computer services"
  },
  {
    "code": "SJ111",
    "service": "Provision of customised and non customised R&D services"
  },
  {
    "code": "SJ112",
    "service": "Sale of proprietary rights arising from R&D"
  },
  {
    "code": "SJ323",
    "service": "Services incidental to mining, and oil and gas extraction"
  },
  {
    "code": "SC3A",
    "service": "Space transport"
  },
  {
    "code": "SC3B",
    "service": "Rail transport"
  },
  {
    "code": "SC3B1",
    "service": "Rail transport, passenger"
  },
  {
    "code": "SC3B2",
    "service": "Rail transport, freight"
  },
  {
    "code": "SC3B3",
    "service": "Rail transport, other"
  },
  {
    "code": "SC3C",
    "service": "Road transport"
  },
  {
    "code": "SC3C1",
    "service": "Road transport, passenger"
  },
  {
    "code": "SC3C2",
    "service": "Road transport, freight"
  },
  {
    "code": "SC3C3",
    "service": "Road transport, other"
  },
  {
    "code": "SC3E",
    "service": "Pipeline transport"
  },
  {
    "code": "SC3G",
    "service": "Other supporting and auxiliary transport services"
  },
  {
    "code": "S3Z",
    "service": "Licenses to use computer software products"
  },
  {
    "code": "SC3D",
    "service": "Inland waterway transport"
  },
  {
    "code": "SC3D1",
    "service": "Inland waterway transport, passenger"
  },
  {
    "code": "SC3D2",
    "service": "Inland waterway transport, freight"
  },
  {
    "code": "SC3D3",
    "service": "Inland waterway transport, other"
  },
  {
    "code": "SC3F",
    "service": "Electricity transmission"
  },
  {
    "code": "SC41X",
    "service": "Postal services"
  },
  {
    "code": "SC42X",
    "service": "Couriers services"
  },
  {
    "code": "SF12Y",
    "service": "Gross freight insurance premiums receivable"
  },
  {
    "code": "SF12Z",
    "service": "Gross freight insurance claims payable"
  },
  {
    "code": "SF13Y",
    "service": "Gross other direct insurance premiums receivable"
  },
  {
    "code": "SF13Z",
    "service": "Gross other direct insurance claims payable"
  },
  {
    "code": "SI21Z",
    "service": "Software originals"
  },
  {
    "code": "SI31",
    "service": "News agency services"
  },
  {
    "code": "SI32",
    "service": "Other information services"
  },
  {
    "code": "SJ11",
    "service": "Work undertaken on a systematic basis to increase the stock of knowledge"
  },
  {
    "code": "SJ1121",
    "service": "Patents"
  },
  {
    "code": "SJ1122",
    "service": "Copyrights arising from R&D"
  },
  {
    "code": "SJ1123",
    "service": "Industrial processes and designs"
  },
  {
    "code": "SJ1124",
    "service": "Sale of proprietary rights arising from R&D, other"
  },
  {
    "code": "SJ12",
    "service": "Research and development services, other"
  },
  {
    "code": "SJ22Z",
    "service": "Convention, trade-fair and exhibition organisation services"
  },
  {
    "code": "SJ311",
    "service": "Architectural services"
  },
  {
    "code": "SJ32X",
    "service": "Services incidental to agriculture and mining"
  },
  {
    "code": "SK11",
    "service": "Audiovisual services"
  },
  {
    "code": "SK11Z",
    "service": "Audiovisual originals"
  },
  {
    "code": "SK12",
    "service": "Artistic related services"
  },
  {
    "code": "SK21",
    "service": "Health services"
  },
  {
    "code": "SK22",
    "service": "Education services"
  },
  {
    "code": "SK23",
    "service": "Heritage and recreational services"
  },
  {
    "code": "SK24",
    "service": "Other personal services"
  },
  {
    "code": "SL1",
    "service": "Embassies and consulates"
  },
  {
    "code": "SL2",
    "service": "Military units and agencies"
  },
  {
    "code": "SL3",
    "service": "Other government goods and services n.i.e."
  },
  {
    "code": "S5",
    "service": "Total services transactions between related enterprises"
  },
  {
    "code": "S",
    "service": "Total EBOPS service"
  },
  {
    "code": "G",
    "service": "Goods"
  },
  {
    "code": "G1",
    "service": "General merchandise on a balance of payments basis"
  },
  {
    "code": "G2",
    "service": "Net exports of goods under merchanting"
  }
]
