import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SourceService {
  baseUrl = environment.baseUrl+'/source';
  baseUrl1 = environment.baseUrl;

  constructor(private http: HttpClient) { }
  getCategories (type) {
    return this.http.get(this.baseUrl + '/getCategories?type=' + type );
  }
  getafricanCountries () {
    return this.http.get(this.baseUrl + '/africanCountries');
  }

  getSubCategories (type,name) {
     return this.http.get(this.baseUrl + '/getSubCategories?type=' + type +'&category='+name);
  }
  postProduct (data) {
    return this.http.post(this.baseUrl + '',data);
 }
uploadFileProduct(id:any,body:any){
  return this.http.post(this.baseUrl+'/'+id+"/upload",body,{ responseType: 'text' });
}

uploadFilesProduct(id:any,body:any){
  return this.http.post(this.baseUrl+'/'+id+"/upload",body,{ responseType: 'text' });
}

searchSupplierbyId(id,body:any){
  return this.http.post(this.baseUrl+'/search' ,body );
}

searchSupplier(body:any){
  return this.http.post(this.baseUrl + '/sellersSearch' ,body );
}

getCountries () {
  return this.http.get(this.baseUrl + '/products/itemsByCountry');
}

  getCategoriesByParam(param) {
    let formData = new FormData();
    formData.append("search" , param);
    return this.http.post(this.baseUrl + '/getCategories', formData);
  }

  getImporterbyId(companyId){
    return this.http.get(this.baseUrl+'/getByBuyer/'+companyId );
  }

  getBySeller(companyId){
    return this.http.get(this.baseUrl+'/getByBuyer?companyId='+companyId );
  }

  searchImporter(body:any){
    return this.http.post(this.baseUrl + '/buyersSearch' ,body );
  }
  searchCompany(body:any){
    return this.http.post(this.baseUrl + '/buyerSearch' ,body );
  }

  searchCompany1(body:any){
    return this.http.post(this.baseUrl + '/sellerCompanySearch' ,body );
  }
  getCategoriesAll () {
    return this.http.get(this.baseUrl1 + '/postBuyRequests/getCategories' );
}
  importers(){
    return this.http.get(this.baseUrl + '/sellers'  );
  }

  getCountriesById (subscriptionId) {
    return this.http.get(this.baseUrl + '/getCountries/' + subscriptionId);
 }



 getCountriesOfSupplier (data) {
  return this.http.post(this.baseUrl + '/getCategories' , data);
}

getAllByCategorySearch (data) {
  return this.http.post(this.baseUrl + '/getAllByCategorySearch' , data);
}


}
