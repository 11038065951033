import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzMessageService, UploadFile } from "ng-zorro-antd";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";

@Component({
  selector: "app-post-service",
  templateUrl: "./post-service.component.html",
  styleUrls: ["./post-service.component.css"],
})
export class PostServiceComponent implements OnInit {
  public current = 0;
  public index = 1;
  public formAddService = new FormGroup({});
  public formAddService2 = new FormGroup({});
  public selectedCategory = null;
  public listCategories: any = [];
  public serviceName = null;
  public cpcCode = null;
  public ServiceDescription = "";
  public tags = [];
  public inputVisible = false;
  public inputValue = null;
  fileList: UploadFile[] = [];
  videoList: UploadFile[] = [];
  public listEmplty = true;
  public videoIsEmpty = true;
  public fileName = "";
  public formData: FormData = new FormData();
  public fileProduct: any;
  previewImage = "";
  previewVisible = false;
  uploadVideo = true;
  availability = true;
  public video = "";
  public MinimumQuantity: number = null;
  public Price: number = null;
  public selectedUnitofPrice = null;
  public listUnitOfPrice: any = [];
  public typePayment: any = [
    { label: "Wire Transfer", value: "Wire Transfer", checked: true },
    { label: "Online web payment", value: "Online web payment" },
    { label: "Paypal", value: "Paypal" },
    { label: "Western Union", value: "Western Union" },
    { label: "Western Union", value: "Western Union" },
    { label: "Letter of Credit", value: "Letter of Credit" },
    { label: "Open Account", value: "Open Account" },
  ];
  public listCheked: any = [];
  public SupplyAbility: any;
  public SupplyAbilityUnit: any;
  public listSupplyAbilityFrequency: any = [];
  public DeliveryTime: any;
  public DeliveryTimeUnit: any;
  public listDeliverTimeFrequency: any = [];
  public MainExport: any = [];
  public DomesticMarket: any;
  public listafricanCountries: any = [];
  public CertificationsDetails: any;
  public loading = false;
  constructor(
    private msg: NzMessageService,
    private fb: FormBuilder,
    private b2bserviceService: B2bProductService
  ) {}

  ngOnInit() {
    this.createFormService();
    this.getCategories();
    this.getAllUnitOfPrice();
    this.getAllSupplyAbilityFrequency();
    this.getAllDeliverTimeFrequency();
    this.getAllafricanCountries();
  }
  postService() {
    this.loading = true;
    let data = {
      type: "service",
      category: this.selectedCategory.id,
      name: this.serviceName,
      cpcCode: this.cpcCode,
      video: this.video,
      description: this.ServiceDescription,
      keywords: this.tags,
      minimumOrderQuantity: this.MinimumQuantity,
      isAvailable: this.availability,
      price: this.Price,
      unitOfPrice: this.selectedUnitofPrice,
      paymentTerms: this.listCheked,
      supplyAbility: this.SupplyAbility,
      supplyAbilityFrequency: this.SupplyAbilityUnit,
      deliveryTime: this.DeliveryTime,
      deliveryTimeFrequency: this.DeliveryTimeUnit,
      mainExportMarkets: this.MainExport,
      mainDomesticMarket: this.DomesticMarket,
      certifications: this.CertificationsDetails,
    };
    console.log("saveed", data);
    this.b2bserviceService.postService(data).subscribe(
      (resp: any) => {
        console.log("resssssp", resp);
        let formData = new FormData();
        // tslint:disable-next-line:no-any
        if (this.fileList)
          this.fileList.forEach((file: any) => {
            formData.append("files", file);
          });
        formData.append("type", "service");
        this.b2bserviceService.uploadFilesProduct(resp.id, formData).subscribe(
          (resp: any) => {
            console.log(resp);
            if (this.uploadVideo) {
            } else {
              this.loading = false;
              this.current += 1;
              this.changeContent();
              this.msg.success("Service added successfully");
            }
          },
          () => {
            this.loading = false;
            this.msg.error("upload failed.");
          }
        );
        this.loading = false;
        this.current += 1;
        this.changeContent();
        this.msg.success("Service added successfully");
      },
      (err) => {
        this.loading = false;
        console.log(err);
        this.msg.error("failed to add Service ");
      }
    );
  }
  getAllafricanCountries() {
    this.b2bserviceService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries = resp;
    });
  }
  getAllDeliverTimeFrequency() {
    this.b2bserviceService.getDeliverTimeFrequency().subscribe((resp: any) => {
      console.log(resp);
      this.listDeliverTimeFrequency = resp;
    });
  }
  getAllSupplyAbilityFrequency() {
    this.b2bserviceService
      .getSupplyAbilityFrequency()
      .subscribe((resp: any) => {
        console.log(resp);
        this.listSupplyAbilityFrequency = resp;
      });
  }
  CheckPayment(value): void {
    this.listCheked = [];
    this.typePayment.forEach((element) => {
      if (element.checked) {
        this.listCheked.push(element.value);
      }
    });
  }
  getAllUnitOfPrice() {
    this.b2bserviceService.getUnitOfPrice().subscribe((resp: any) => {
      console.log(resp);
      this.listUnitOfPrice = resp;
    });
  }
  showInput(): void {
    this.inputVisible = true;
  }
  handleInputConfirm(): void {
    if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
      this.tags = [...this.tags, this.inputValue];
    }
    this.inputValue = "";
    this.inputVisible = false;
  }
  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }
  handleClose(removedTag: {}): void {
    this.tags = this.tags.filter((tag) => tag !== removedTag);
  }
  getCategories() {
    this.b2bserviceService.getServicesCategories().subscribe((resp: any) => {
      console.log(resp);
      this.listCategories = resp;
    });
  }
  getCpcCode(id) {
    this.b2bserviceService
      .findCpcCodeByCategory(id.id)
      .subscribe((resp: any) => {
        console.log(resp);
        this.cpcCode = resp.cpcCode;
      });
  }
  createFormService() {
    this.formAddService = this.fb.group({
      id: [""],
      type: [""],
      category: ["", Validators.required],
      serviceName: ["", Validators.required],
      cpc: [""],
      tag: [""],
      serviceDescription: ["", Validators.required],
      checkUploadVideo: [""],
      video: [""],
    });

    this.formAddService2 = this.fb.group({
      minimumOrderQuantity: ["", Validators.required],
      availability: ["", Validators.required],
      price: ["", Validators.required],
      unitOfPrice: ["", Validators.required],
      supplyAbility: ["", Validators.required],
      supplyAbilityFrequency: ["", Validators.required],
      deliveryTime: ["", Validators.required],
      deliveryTimeFrequency: ["", Validators.required],
      mainExportMarkets: [""],
      mainDomesticMarket: ["", Validators.required],
      checkbox: [""],
      certifications: [""],
    });
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data = this.ValidateFile(file);
      if (!data) {
        this.fileList = [];
        this.listEmplty = true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };

  handleUploadVideo(): void {
    let formData = new FormData();
    // tslint:disable-next-line:no-any
    this.videoList.forEach((file: any) => {
      formData.append("file", file);
      // let uploadedFiles = this.fileList.map(item => formData.append('file', item, item.name));
      this.b2bserviceService
        .uploadVideoProduct(5, formData)
        .subscribe((resp: any) => {
          console.log(resp);
        });
    });
  }

  beforeUpload = (file: UploadFile[]): boolean => {
    this.fileList = file;
    this.fileList.forEach((file: any) => {
      this.formData.append("file", file);
      this.fileProduct = file;
    });
    this.listEmplty = false;
    this.fileName = this.fileProduct.name;
    let data = this.ValidateFile(this.fileProduct);
    if (!data) {
      this.listEmplty = true;
    } else {
      this.listEmplty = false;
    }
    //     this.b2bserviceService.uploadFileProduct(1,this.formData).subscribe((resp: any) => {
    //   console.log(resp);
    // })
    return false;
  };

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };

  ValidateFile(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      this.msg.error("Only JPG or png files are allowed");
      this.fileName = "Only JPG or png files are allowed";
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.msg.error("the image size should not exceed 10MB! ");
      this.fileName = "the image size should not exceed 10MB! ";
      return false;
    }
    return true;
  }

  beforeUploadVideo = (file: UploadFile): boolean => {
    this.videoList = this.videoList.concat(file);
    this.fileName = file.name;
    let data = this.ValidateVideo(file);
    if (!data) {
      this.videoList = [];
      this.videoIsEmpty = false;
    } else {
      this.videoIsEmpty = true;
    }
    return false;
  };

  ValidateVideo(file) {
    const isJpgOrPng = file.type === "video/mp4" || file.type === "video/3gpp";
    if (!isJpgOrPng) {
      this.msg.error("Only MP4 files are allowed");
      this.fileName = "Only MP4 files are allowed";
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.msg.error("the video size should not exceed 250MB! ");
      this.fileName = "the video size should not exceed 25MB! ";
      return false;
    }

    return true;
  }

  changeUploadVideo(value) {
    console.log(value);

    this.uploadVideo = value;
  }
  next1(): void {
    console.log("form valid 1", this.formAddService);
    console.log(this.listEmplty);
    if (!this.listEmplty) {
      if (!this.formAddService.valid) {
        this.msg.error("Please fill in all mandatory fields");
      } else {
        this.current += 1;
        this.changeContent();
      }
    } else {
      this.msg.error("please add a file");
    }
  }
  next2(): void {
    console.log("form valid 2", this.formAddService2);
    console.log("Exports", this.MainExport);

    if (!this.formAddService2.valid) {
      this.msg.error("Please fill in all mandatory fields");
    } else {
      this.current += 1;
      this.changeContent();
    }
  }
  next3(): void {
    this.current += 1;
    this.changeContent();
  }

  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 1;
        break;
      }
      case 1: {
        this.index = 2;
        break;
      }
      case 2: {
        this.index = 3;
        break;
      }
      case 3: {
        this.index = 4;
        break;
      }
      case 4: {
        this.index = 5;
        break;
      }
      default: {
        this.index = 1;
      }
    }
  }
}
