import { NzTreeNodeOptions } from "ng-zorro-antd/core/tree/public-api";

export interface SubAdmin {
  id: any;
  user: any;
  userManagementPermission: any;
  permissionDTOS: Permission[];
}
export interface node {
  node: NzTreeNodeOptions[];
  mode?: "edit" | "view" | "none";
  title: string;
}
export interface leaf {
  key: string;
  name: string;
}
export interface PermissionEntity {
  id: number;
  namePermission: string;
  permissionDTOS: Permission[];
}
export class Permission {
  name: string;
  mode: Mode;
  actions: string[] | null;

  constructor(permission: Permission) {
    this.name = permission.name;
    this.mode = permission.mode;
    this.actions = permission.mode === Mode.EDIT ? permission.actions : null;
  }
}

export enum Mode {
  VIEW = "VIEW",
  EDIT = "EDIT",
  NONE = "NONE",
}

export enum MenuName {

  // DASHBOARD = "DASHBOARD",

  PACKAGE_MANAGEMENT = "PACKAGE MANAGEMENT",
  BANK_ACCOUNT_MANAGEMENT = "BANK ACCOUNT MANAGEMENT",

  DISCOUNT_MANAGEMENT = "DISCOUNT MANAGEMENT",
  PROMO_GIFT = "PROMO GIFT",

  EVOUCHER_MANAGEMENT = "EVOUCHER MANAGEMENT",

  USER_MANAGEMENT = "USER MANAGEMENT",
  ADMIN_USERS = "ADMIN USERS",
  CONTRIBUTORS = "CONTRIBUTORS",
  PERMISSION = "PERMISSION",
  AGENTS = "AGENTS",

  SUBSCRIPTION_MANAGEMENT = "SUBSCRIPTION MANAGEMENT",
  SUBSCRIBERS = "SUBSCRIBERS",
  SUBSCRIPTION_HISTORY = "SUBSCRIPTION HISTORY",

  MANAGE_MESSAGES = "MANAGE MESSAGES",
  CONTACT_US_MESSAGES = "CONTACT US MESSAGES",
  CAMPAIGN_MANAGEMENT = "CAMPAIGN MANAGEMENT",

  ADVISORY_MANAGEMENT = "ADVISORY MANAGEMENT",

  LICENCE_MANAGEMENT = "LICENCE MANAGEMENT",

  DATA_MANAGEMENT = "DATA MANAGEMENT",
  ACCESS_TO_FINANCE = "ACCESS TO FINANCE",
  FINANCING_OPPORTUNITIES = "FINANCING OPPORTUNITIES",
  INVESTMENT_OPPORTUNITIES = "INVESTMENT OPPORTUNITIES",
  INVESTMENT_PROVIDERS = "INVESTMENT PROVIDERS",
  MARKETING_OPPORTUNITIES = "MARKETING OPPORTUNITIES",
  TRADE_SHOWS = "TRADE SHOWS",
  COMPANY_INFORMATION = "COMPANY INFORMATION",
  FREIGHT_PROVIDERS = "FREIGHT PROVIDERS",
  FREIGHT_OPPORTUNITIES = "FREIGHT OPPORTUNITIES",

  AUDIT_MANAGEMENT = "AUDIT MANAGEMENT",

  CONTENT_MANAGEMENT = "CONTENT MANAGEMENT",

  REPORT_MANAGEMENT = "REPORT MANAGEMENT",

  SECURITY_MANAGEMENT = "SECURITY MANAGEMENT",
}

/** begin actions */
export enum DashboardAction { }

export enum PackageManagementActions {
  CHANGE_NAME = "CHANGE NAME",
  MANAGE_PRICE = "MANAGE PRICE",
  MANAGE_DATA = "MANAGE DATA",
  MANAGE_KEY = "MANAGE KEY",
  MANAGE_ACCESS = "MANAGE ACCESS",
  MANAGE_LICENCE = "MANAGE LICENCE",
  ACTIVATE_PLAN = "ACTIVATE PLAN",
  MANAGE_FREQUENCY = "MANAGE FREQUENCY",
}

export enum BankAccountManagementActions {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  SWITCH_STATUS = "SWITCH STATUS",
}

export enum PromoGiftActions {
  GENERATE = "GENERATE",
  SHARE = "SHARE",
  ADD_USER = "ADD USER",
  SWITCH_STATUS = "SWITCH STATUS",
  DELETE = "DELETE",
  EXPORT = "EXPORT"
}

export enum EvoucherActions {
  GENERATE = "GENERATE",
  SHARE = "SHARE",
  ADD_USER = "ADD USER",
  SWITCH_STATUS = "SWITCH STATUS",
  DELETE = "DELETE",
  EXPORT = "EXPORT"
}

export enum AdvisoryManagementActions {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  SELECT_FILE = "SELECT FILE",
  SELECT_IMAGE = "SELECT IMAGE",
}

export enum AdminUsersActions {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  SWITCH_STATUS = "SWITCH STATUS",
}

export enum ContributorsActions {
  ADD = "ADD",
  VALIDATE = "VALIDATE",
  SWITCH_STATUS = "SWITCH STATUS",
  DELETE = "DELETE",
  ASSIGN_TASK = "ASSIGN TASK",
}

export enum PermissionActions {
  ADD = "ADD",
  EDIT = "EDIT",
}

export enum AgentsActions {
  ADD = "ADD",
  VALIDATE = "VALIDATE",
  SWITCH_STATUS = "SWITCH STATUS",
  DELETE = "DELETE",
  ASSIGN_TASK = "ASSIGN TASK",
}

export enum SubscribersActions {
  SWITCH_STATUS = "SWITCH STATUS",
  DELETE = "DELETE",
  VIEW_BILLING_HISTORY = "VIEW BILLING HISTORY",
}

export enum SubscriptionHistoryActions {
  DELETE = "DELETE",
}

export enum ContactUsMessagesActions {
  CONTACT_NOW = "CONTACT NOW",
  DELETE_MESSAGE = "DELETE MESSAGE",
}

export enum CampaignManagementActions {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  SELECT_FILE = "SELECT FILE",
  SELECT_IMAGE = "SELECT IMAGE",
  SHARE = "SHARE",
}

export enum LicenceManagementActions { }

export enum DataActions {
  ACCESS_TO_FINANCE = "ACCESS TO FINANCE",
  VALIDATE_ACCESS_TO_FINANCE = "VALIDATE ACCESS TO FINANCE",

  FINANCING_OPPORTUNITIES = "FINANCING OPPORTUNITIES",
  VALIDATE_FINANCING_OPPORTUNITIES = "VALIDATE FINANCING OPPORTUNITIES",

  INVESTMENT_OPPORTUNITIES = "INVESTMENT OPPORTUNITIES",
  VALIDATE_INVESTMENT_OPPORTUNITIES = "VALIDATE INVESTMENT OPPORTUNITIES",

  INVESTMENT_PROVIDERS = "INVESTMENT PROVIDERS",
  VALIDATE_INVESTMENT_PROVIDERS = "VALIDATE INVESTMENT PROVIDERS",

  MARKETING_OPPORTUNITIES = "MARKETING OPPORTUNITIES",
  VALIDATE_MARKETING_OPPORTUNITIES = "VALIDATE MARKETING OPPORTUNITIES",

  TRADE_SHOWS = "TRADE SHOWS",
  VALIDATE_TRADE_SHOWS = "VALIDATE TRADE SHOWS",

  COMPANY_INFORMATION = "COMPANY INFORMATION",
  VALIDATE_COMPANY_INFORMATION = "VALIDATE COMPANY INFORMATION",

  FREIGHT_PROVIDERS = "FREIGHT PROVIDERS",
  VALIDATE_FREIGHT_PROVIDERS = "VALIDATE FREIGHT PROVIDERS",

  FREIGHT_OPPORTUNITIES = "FREIGHT OPPORTUNITIES",
  VALIDATE_FREIGHT_OPPORTUNITIES = "VALIDATE FREIGHT OPPORTUNITIES",

  TENDERS_OPPORTUNITIES = "TENDERS OPPORTUNITIES",
  VALIDATE_TENDERS_OPPORTUNITIES = "VALIDATE TENDERS OPPORTUNITIES",
}

export enum AuditManagementActions {
  EXPORT_AUDIT = "EXPORT AUDIT",
}

export enum ContentManagementActions {
  VIEW_LANDING_PAGE = "VIEW LANDING PAGE",

  VIEW_TOP_SLIDER = "VIEW TOP SLIDER",
  ADD_ITEM_TOP_SLIDER = "ADD ITEM TOP SLIDER",
  EDIT_ITEM_TOP_SLIDER = "EDIT ITEM TOP SLIDER",
  DELETE_ITEM_TOP_SLIDER = "DELETE ITEM TOP SLIDER",

  VIEW_BOTTOM_SLIDER = "VIEW BOTTOM SLIDER",
  ADD_ITEM_BOTTOM_SLIDER = "ADD ITEM BOTTOM SLIDER",
  EDIT_ITEM_BOTTOM_SLIDER = "EDIT ITEM BOTTOM SLIDER",
  DELETE_ITEM_BOTTOM_SLIDER = "DELETE ITEM BOTTOM SLIDER",

  VIEW_PRODUCT_VIDEO = "VIEW PRODUCT VIDEO",
  ADD_PRODUCT_VIDEO = "ADD PRODUCT VIDEO",
  EDIT_PRODUCT_VIDEO = "EDIT PRODUCT VIDEO",
  DELETE_PRODUCT_VIDEO = "DELETE PRODUCT VIDEO",

  VIEW_NETWORK_PARTNER = "VIEW NETWORK PARTNER",
  ADD_PARTNERS = "ADD PARTNERS",
  EDIT_PARTNERS = "EDIT PARTNERS",
  DELETE_PARTNERS = "DELETE PARTNERS",



  VIEW_FEATURED_SERVICES = "VIEW FEATURED SERVICES",

  VIEW_ADS = "VIEW ADS",
  ADD_ADS = "ADD ADS",
  EDIT_ADS = "EDIT ADS",
  DELETE_ADS = "DELETE ADS",

  VIEW_CARDS = "VIEW CARDS",
  ADD_CARDS = "ADD CARDS",
  EDIT_CARDS = "EDIT CARDS",
  DELETE_CARDS = "DELETE CARDS",
}

export enum ReportManagementActions {
  EXPORT_REPORT = "EXPORT REPORT",
}

export enum SecurityActions {
  EDIT_PASSWORD_CRITERIA = "EDIT PASSWORD CRITERIA",
  EDIT_ONE_TIME_PASSWORD_CRITERIA = "EDIT ONE TIME PASSWORD CRITERIA",
}
/** end actions */

/** begin node tree */
// export const DashboardNode: NzTreeNodeOptions[] = [
//   {
//     title: "Dashboard",
//     feature: MenuName.DASHBOARD,
//     key: "100",
//     isLeaf: true,
//     children: [],
//   },
// ];

export const PackageManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Package Management",
    feature: MenuName.PACKAGE_MANAGEMENT,
    key: "200",
    children: [
      {
        title: "Package Management",
        feature: MenuName.PACKAGE_MANAGEMENT,
        key: "200-1",
        children: [
          {
            title: "Change Plan Name",
            feature: PackageManagementActions.CHANGE_NAME,
            key: "200-1-1",
            isLeaf: true,
          },
          {
            title: "Manage Data",
            feature: PackageManagementActions.MANAGE_DATA,
            key: "200-1-2",
            isLeaf: true,
          },
          {
            title: "Manage Key Features",
            feature: PackageManagementActions.MANAGE_KEY,
            key: "200-1-3",
            isLeaf: true,
          },
          {
            title: "Manage Plan Access",
            feature: PackageManagementActions.MANAGE_ACCESS,
            key: "200-1-4",
            isLeaf: true,
          },
          {
            title: "Manage Licence",
            feature: PackageManagementActions.MANAGE_LICENCE,
            key: "200-1-5",
            isLeaf: true,
          },
          {
            title: "Activate/Deactivate Plan",
            feature: PackageManagementActions.ACTIVATE_PLAN,
            key: "200-1-6",
            isLeaf: true,
          },
          {
            title: "Manage Plan Frequency",
            feature: PackageManagementActions.MANAGE_FREQUENCY,
            key: "200-1-7",
            isLeaf: true,
          },
        ],
      },
      {
        title: "Bank Account Management",
        feature: MenuName.BANK_ACCOUNT_MANAGEMENT,
        key: "200-2",
        children: [
          {
            title: "Add New Bank",
            feature: BankAccountManagementActions.ADD,
            key: "200-2-1",
            isLeaf: true,
          },
          {
            title: "Delete Bank",
            feature: BankAccountManagementActions.DELETE,
            key: "200-2-2",
            isLeaf: true,
          },
          {
            title: "Edit Bank",
            feature: BankAccountManagementActions.EDIT,
            key: "200-2-3",
            isLeaf: true,
          },
          {
            title: "Switch Bank Status",
            feature: BankAccountManagementActions.SWITCH_STATUS,
            key: "200-2-4",
            isLeaf: true,
          },
        ],
      },
    ],
  },
];

export const DiscountManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Discount Management",
    feature: MenuName.DISCOUNT_MANAGEMENT,
    key: "300",
    children: [
      {
        title: "Promo/Gift",
        feature: MenuName.PROMO_GIFT,
        key: "300-1",
        children: [
          {
            title: "Generate New Code",
            feature: PromoGiftActions.GENERATE,
            key: "300-1-1",
            isLeaf: true,
          },
          {
            title: "Delete Code",
            feature: PromoGiftActions.DELETE,
            key: "300-1-2",
            isLeaf: true,
          },
          {
            title: "Add New Subscriber",
            feature: PromoGiftActions.ADD_USER,
            key: "300-1-3",
            isLeaf: true,
          },
          {
            title: "Share Code With Subscribers",
            feature: PromoGiftActions.SHARE,
            key: "300-1-4",
            isLeaf: true,
          },
          {
            title: "Switch Code Status",
            feature: PromoGiftActions.SWITCH_STATUS,
            key: "300-1-5",
            isLeaf: true,
          },
          {
            title: "Export Promo/Gift Codes",
            feature: PromoGiftActions.EXPORT,
            key: "300-1-6",
            isLeaf: true,
          }
        ],
      },
    ],
  },
];

export const EvoucherMangementNode: NzTreeNodeOptions[] = [
  {
    title: "Evoucher Management",
    feature: MenuName.EVOUCHER_MANAGEMENT,
    key: "400",
    children: [
      {
        title: "Generate New Evoucher",
        feature: EvoucherActions.GENERATE,
        key: "400-1",
        isLeaf: true,
      },
      {
        title: "Delete Evoucher",
        feature: EvoucherActions.DELETE,
        key: "400-2",
        isLeaf: true,
      },
      {
        title: "Add New Subscriber",
        feature: EvoucherActions.ADD_USER,
        key: "400-3",
        isLeaf: true,
      },
      {
        title: "Share Evoucher With Subscribers",
        feature: EvoucherActions.SHARE,
        key: "400-4",
        isLeaf: true,
      },
      {
        title: "Switch Evoucher Status",
        feature: EvoucherActions.SWITCH_STATUS,
        key: "400-5",
        isLeaf: true,
      },
      {
        title: "Export Evoucher Codes",
        feature: EvoucherActions.EXPORT,
        key: "400-6",
        isLeaf: true,
      }
    ]
  }
];


export const UserManagementNode: NzTreeNodeOptions[] = [
  {
    title: "User Management",
    feature: MenuName.USER_MANAGEMENT,
    key: "500",
    children: [
      {
        title: "Admin Users",
        feature: MenuName.ADMIN_USERS,
        key: "500-1",
        children: [
          {
            title: "Add New User",
            feature: AdminUsersActions.ADD,
            key: "500-1-1",
            isLeaf: true,
          },
          {
            title: "Delete User",
            feature: AdminUsersActions.DELETE,
            key: "500-1-2",
            isLeaf: true,
          },
          {
            title: "Edit User",
            feature: AdminUsersActions.EDIT,
            key: "500-1-3",
            isLeaf: true,
          },
          {
            title: "Switch User Status",
            feature: AdminUsersActions.SWITCH_STATUS,
            key: "500-1-4",
            isLeaf: true,
          },
        ],
      },
      {
        title: "Contributors",
        feature: MenuName.CONTRIBUTORS,
        key: "500-2",
        children: [
          {
            title: "Add New Contributor",
            feature: ContributorsActions.ADD,
            key: "500-2-1",
            isLeaf: true,
          },
          {
            title: "Validate Contributor",
            feature: ContributorsActions.VALIDATE,
            key: "500-2-2",
            isLeaf: true,
          },
          {
            title: "Switch Contributor Status",
            feature: ContributorsActions.SWITCH_STATUS,
            key: "500-2-3",
            isLeaf: true,
          },
          {
            title: "Delete Contributor",
            feature: ContributorsActions.DELETE,
            key: "500-2-4",
            isLeaf: true,
          },
          {
            title: "Assign Tasks to Contributor",
            feature: ContributorsActions.ASSIGN_TASK,
            key: "500-2-5",
            isLeaf: true,
          }
        ],
      },
      {
        title: "Permission",
        feature: MenuName.PERMISSION,
        key: "500-3",
        children: [
          {
            title: "Add New Permission",
            feature: PermissionActions.ADD,
            key: "500-3-1",
            isLeaf: true,
          },
          {
            title: "Edit Permission",
            feature: PermissionActions.EDIT,
            key: "500-3-2",
            isLeaf: true,
          },
        ]
      },
      {
        title: "Agents",
        feature: MenuName.AGENTS,
        key: "500-4",
        children: [
          {
            title: "Add New Agent",
            feature: AgentsActions.ADD,
            key: "500-4-1",
            isLeaf: true,
          },
          {
            title: "Validate Agent",
            feature: AgentsActions.VALIDATE,
            key: "500-4-2",
            isLeaf: true,
          },
          {
            title: "Switch Agent Status",
            feature: AgentsActions.SWITCH_STATUS,
            key: "500-4-3",
            isLeaf: true,
          },
          {
            title: "Delete Agent",
            feature: AgentsActions.DELETE,
            key: "500-4-4",
            isLeaf: true,
          },
          {
            title: "Assign Tasks to Agent",
            feature: AgentsActions.ASSIGN_TASK,
            key: "500-4-5",
            isLeaf: true,
          }
        ],
      },
    ],
  },
];

export const SubscriptionManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Subscription Management",
    feature: MenuName.SUBSCRIPTION_MANAGEMENT,
    key: "600",
    children: [
      {
        title: "Subscribers",
        feature: MenuName.SUBSCRIBERS,
        key: "600-1",
        children: [
          {
            title: "Switch Subscriber Status",
            feature: SubscribersActions.SWITCH_STATUS,
            key: "600-1-1",
            isLeaf: true,
          },
          {
            title: "Delete Subscriber",
            feature: SubscribersActions.DELETE,
            key: "600-1-2",
            isLeaf: true,
          },
          {
            title: "View Subscriber Billing History",
            feature: SubscribersActions.VIEW_BILLING_HISTORY,
            key: "600-1-3",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Subscription History",
        feature: MenuName.SUBSCRIPTION_HISTORY,
        key: "600-2",
        children: [
          {
            title: "Delete Subscription History",
            feature: SubscriptionHistoryActions.DELETE,
            key: "600-2-1",
            isLeaf: true,
          }
        ]
      }
    ]
  }
];

export const ManageMessagesNode: NzTreeNodeOptions[] = [
  {
    title: "Manage Messages",
    feature: MenuName.MANAGE_MESSAGES,
    key: "700",
    children: [
      {
        title: "Contact Us Messages",
        feature: MenuName.CONTACT_US_MESSAGES,
        key: "700-1",
        children: [
          {
            title: "Contact Now",
            feature: ContactUsMessagesActions.CONTACT_NOW,
            key: "700-1-1",
            isLeaf: true,
          },
          {
            title: "Delete Message",
            feature: ContactUsMessagesActions.DELETE_MESSAGE,
            key: "700-1-2",
            isLeaf: true,
          },
        ],
      },
      {
        title: "Campaign Management",
        feature: MenuName.CAMPAIGN_MANAGEMENT,
        key: "700-2",
        children: [
          {
            title: "Add New Post",
            feature: CampaignManagementActions.ADD,
            key: "700-2-1",
            isLeaf: true,
          },
          {
            title: "Delete Post",
            feature: CampaignManagementActions.DELETE,
            key: "700-2-2",
            isLeaf: true,
          },
          {
            title: "Edit Post",
            feature: CampaignManagementActions.EDIT,
            key: "700-2-3",
            isLeaf: true,
          },
          {
            title: "Select File",
            feature: CampaignManagementActions.SELECT_FILE,
            key: "700-2-4",
            isLeaf: true,
          },
          {
            title: "Select Image",
            feature: CampaignManagementActions.SELECT_IMAGE,
            key: "700-2-5",
            isLeaf: true,
          },
          {
            title: "Share Post",
            feature: CampaignManagementActions.SHARE,
            key: "700-2-6",
            isLeaf: true,
          },
        ],
      },
    ],
  },
];

export const AdvisoryManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Advisory Management",
    feature: MenuName.ADVISORY_MANAGEMENT,
    key: "800",
    children: [
      {
        title: "Advisory Management",
        feature: MenuName.ADVISORY_MANAGEMENT,
        key: "800-1",
        children: [
          {
            title: "Add New Article",
            feature: AdvisoryManagementActions.ADD,
            key: "800-1-1",
            isLeaf: true,
          },
          {
            title: "Delete Article",
            feature: AdvisoryManagementActions.DELETE,
            key: "800-1-2",
            isLeaf: true,
          },
          {
            title: "Edit Article",
            feature: AdvisoryManagementActions.EDIT,
            key: "800-1-3",
            isLeaf: true,
          },
          {
            title: "Select File",
            feature: AdvisoryManagementActions.SELECT_FILE,
            key: "800-1-4",
            isLeaf: true,
          },
          {
            title: "Select Image",
            feature: AdvisoryManagementActions.SELECT_IMAGE,
            key: "800-1-5",
            isLeaf: true,
          },
        ],
      },
    ],
  },
];

export const LicenceManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Licence Management",
    feature: MenuName.LICENCE_MANAGEMENT,
    key: "900",
    children: [],
    isLeaf: true
  },
];

export const DataManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Data Management",
    feature: MenuName.DATA_MANAGEMENT,
    key: "1000",
    children: [
      {
        title: "Access to Finance",
        feature: DataActions.ACCESS_TO_FINANCE,
        key: "1000-1",
        children: [
          {
            title: "Validate Access to Fianance",
            feature: DataActions.VALIDATE_ACCESS_TO_FINANCE,
            key: "1000-1-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Financing Opportunities",
        feature: DataActions.FINANCING_OPPORTUNITIES,
        key: "1000-2",
        children: [
          {
            title: "Validate Financing Opportunities",
            feature: DataActions.VALIDATE_FINANCING_OPPORTUNITIES,
            key: "1000-2-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Investment Opportunities",
        feature: DataActions.INVESTMENT_OPPORTUNITIES,
        key: "1000-3",
        children: [
          {
            title: "Validate Investment Opportunities",
            feature: DataActions.VALIDATE_INVESTMENT_OPPORTUNITIES,
            key: "1000-3-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Investment Providers",
        feature: DataActions.INVESTMENT_PROVIDERS,
        key: "1000-4",
        children: [
          {
            title: "Validate Investment Providers",
            feature: DataActions.VALIDATE_INVESTMENT_PROVIDERS,
            key: "1000-4-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Marketing Opportunities",
        feature: DataActions.MARKETING_OPPORTUNITIES,
        key: "1000-5",
        children: [
          {
            title: "Validate Marketing Opportunities",
            feature: DataActions.VALIDATE_MARKETING_OPPORTUNITIES,
            key: "1000-5-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Trade Shows",
        feature: DataActions.TRADE_SHOWS,
        key: "1000-6",
        children: [
          {
            title: "Validate Trade Shows",
            feature: DataActions.VALIDATE_TRADE_SHOWS,
            key: "1000-6-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Company Information",
        feature: DataActions.COMPANY_INFORMATION,
        key: "1000-7",
        children: [
          {
            title: "Validate Company Information",
            feature: DataActions.VALIDATE_COMPANY_INFORMATION,
            key: "1000-7-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Freight Providers",
        feature: DataActions.FREIGHT_PROVIDERS,
        key: "1000-8",
        children: [
          {
            title: "Validate Freight Providers",
            feature: DataActions.VALIDATE_FREIGHT_PROVIDERS,
            key: "1000-8-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Freight Opportunities",
        feature: DataActions.FREIGHT_OPPORTUNITIES,
        key: "1000-9",
        children: [
          {
            title: "Validate Freight Opportunities",
            feature: DataActions.VALIDATE_FREIGHT_OPPORTUNITIES,
            key: "1000-9-1",
            isLeaf: true,
          }
        ]
      },
      {
        title: "Tenders Opportunities",
        feature: DataActions.TENDERS_OPPORTUNITIES,
        key: "1000-10",
        children: [
          {
            title: "Validate Tenders Opportunities",
            feature: DataActions.VALIDATE_TENDERS_OPPORTUNITIES,
            key: "1000-10-1",
            isLeaf: true,
          }
        ]
      },
    ],
  },
];

export const AuditManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Audit Management",
    feature: MenuName.AUDIT_MANAGEMENT,
    key: "1100",
    children: [
      {
        title: "Export Audit",
        feature: AuditManagementActions.EXPORT_AUDIT,
        key: "1100-1",
        isLeaf: true,
      }
    ],
  },
];

export const ContentManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Content Management",
    feature: MenuName.CONTENT_MANAGEMENT,
    key: "1200",
    children: [
      {
        title: "View Landing Page",
        feature: ContentManagementActions.VIEW_LANDING_PAGE,
        key: "1200-1",
        children: [
          {
            title: "View Top Slider",
            feature: ContentManagementActions.VIEW_TOP_SLIDER,
            key: "1200-1-1",
            children: [
              {
                title: "Add Item Top Slider",
                feature: ContentManagementActions.ADD_ITEM_TOP_SLIDER,
                key: "1200-1-1-1",
                isLeaf: true,
              },
              {
                title: "Edit Item Top Slider",
                feature: ContentManagementActions.EDIT_ITEM_TOP_SLIDER,
                key: "1200-1-1-2",
                isLeaf: true,
              },
              {
                title: "Delete Item Top Slider",
                feature: ContentManagementActions.DELETE_ITEM_TOP_SLIDER,
                key: "1200-1-1-3",
                isLeaf: true,
              }
            ]
          },
          {
            title: "View Product Video",
            feature: ContentManagementActions.VIEW_PRODUCT_VIDEO,
            key: "1200-1-2",
            children: [
              {
                title: "Add Product Video",
                feature: ContentManagementActions.ADD_PRODUCT_VIDEO,
                key: "1200-1-2-1",
                isLeaf: true,
              },
              {
                title: "Edit Product Video",
                feature: ContentManagementActions.EDIT_PRODUCT_VIDEO,
                key: "1200-1-2-2",
                isLeaf: true,
              },
              {
                title: "Delete Product Video",
                feature: ContentManagementActions.DELETE_PRODUCT_VIDEO,
                key: "1200-1-2-3",
                isLeaf: true,
              }
            ]
          },
        ]
      },
      {
        title: "View Featured Services",
        feature: ContentManagementActions.VIEW_FEATURED_SERVICES,
        key: "1200-2",
        children: [
          {
            title: "View Ads",
            feature: ContentManagementActions.VIEW_ADS,
            key: "1200-2-1",
            children: [
              {
                title: "Add Ads",
                feature: ContentManagementActions.ADD_ADS,
                key: "1200-2-1-1",
                isLeaf: true,
              },
              {
                title: "Edit Ads",
                feature: ContentManagementActions.EDIT_ADS,
                key: "1200-2-1-2",
                isLeaf: true,
              },
              {
                title: "Delete Ads",
                feature: ContentManagementActions.DELETE_ADS,
                key: "1200-2-1-3",
                isLeaf: true,
              }
            ]
          },
          {
            title: "View Cards",
            feature: ContentManagementActions.VIEW_CARDS,
            key: "1200-2-2",
            children: [
              {
                title: "Add Cards",
                feature: ContentManagementActions.ADD_CARDS,
                key: "1200-2-2-1",
                isLeaf: true,
              },
              {
                title: "Edit Cards",
                feature: ContentManagementActions.EDIT_CARDS,
                key: "1200-2-2-2",
                isLeaf: true,
              },
              {
                title: "Delete Cards",
                feature: ContentManagementActions.DELETE_CARDS,
                key: "1200-2-2-3",
                isLeaf: true,
              }
            ]
          },
          {
            title: "View Bottom Slider",
            feature: ContentManagementActions.VIEW_BOTTOM_SLIDER,
            key: "1200-2-3",
            children: [
              {
                title: "Add Item Bottom Slider",
                feature: ContentManagementActions.ADD_ITEM_BOTTOM_SLIDER,
                key: "1200-2-3-1",
                isLeaf: true,
              },
              {
                title: "Edit Item Bottom Slider",
                feature: ContentManagementActions.EDIT_ITEM_BOTTOM_SLIDER,
                key: "1200-2-3-2",
                isLeaf: true,
              },
              {
                title: "Delete Item Bottom Slider",
                feature: ContentManagementActions.DELETE_ITEM_BOTTOM_SLIDER,
                key: "1200-2-3-3",
                isLeaf: true,
              }
            ]
          },
          {
            title: "View Network Partner",
            feature: ContentManagementActions.VIEW_NETWORK_PARTNER,
            key: "1200-2-4",
            children: [
              {
                title: "Add Partners",
                feature: ContentManagementActions.ADD_PARTNERS,
                key: "1200-2-4-1",
                isLeaf: true,
              },
              {
                title: "Edit Partners",
                feature: ContentManagementActions.EDIT_PARTNERS,
                key: "1200-2-4-2",
                isLeaf: true,
              },
              {
                title: "Delete Partners",
                feature: ContentManagementActions.DELETE_PARTNERS,
                key: "1200-2-4-3",
                isLeaf: true,
              }
            ]
          }
        ]
      }
    ],
  },
];

export const ReportManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Report Management",
    feature: MenuName.REPORT_MANAGEMENT,
    key: "1300",
    children: [
      {
        title: "Export Report",
        feature: ReportManagementActions.EXPORT_REPORT,
        key: "1300-1",
        isLeaf: true,
      }
    ],
  },
];

export const SecurityManagementNode: NzTreeNodeOptions[] = [
  {
    title: "Security Management",
    feature: MenuName.SECURITY_MANAGEMENT,
    key: "1400",
    children: [
      {
        title: "Edit Password Criteria",
        feature: SecurityActions.EDIT_PASSWORD_CRITERIA,
        key: "1400-1",
        isLeaf: true,
      },
      {
        title: "Edit One Time Password Criteria",
        feature: SecurityActions.EDIT_ONE_TIME_PASSWORD_CRITERIA,
        key: "1400-2",
        isLeaf: true,
      },
    ]
  }
];

/** end node tree */

export const ListNode: node[] = [
  // {
  //   node: DashboardNode,
  //   title: "Dashboard",
  // },
  {
    node: PackageManagementNode,
    title: "Package Management",
  },
  {
    node: DiscountManagementNode,
    title: "Discount Management",
  },
  {
    node: EvoucherMangementNode,
    title: "Evoucher Management",
  },
  {
    node: UserManagementNode,
    title: "User Management",
  },
  {
    node: SubscriptionManagementNode,
    title: "Subscription Management",
  },
  {
    node: ManageMessagesNode,
    title: "Manage Messages",
  },
  {
    node: AdvisoryManagementNode,
    title: "Advisory Management",
  },
  {
    node: LicenceManagementNode,
    title: "Licence Management",
  },
  {
    node: DataManagementNode,
    title: "Data Management",
  },
  {
    node: AuditManagementNode,
    title: "Audit Management",
  },
  {
    node: ContentManagementNode,
    title: "Content Management",
  },
  {
    node: ReportManagementNode,
    title: "Report Management",
  },
  {
    node: SecurityManagementNode,
    title: "Security Management",
  },
];

export const Permissions: Permission[] = [
  // {
  //   name: "Dashboard",
  //   mode: Mode.VIEW,
  //   actions: []
  // },
  {
    name: "Package Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Discount Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Evoucher Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "User Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Subscription Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Manage Messages",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Advisory Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Licence Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Data Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Audit Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Content Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Report Management",
    mode: Mode.VIEW,
    actions: [],
  },
  {
    name: "Security Management",
    mode: Mode.VIEW,
    actions: [],
  },
];

export const permissionKeys = [
  {
    name: "Package Management",
    keys: ["200-1-1", "200-1-2", "200-1-3", "200-1-4", "200-1-5", "200-1-6", "200-1-7"],
    parent: "Package Management",
  },
  {
    name: "Bank Account Management",
    keys: ["200-2-1", "200-2-2", "200-2-3", "200-2-4"],
    parent: "Package Management",
  },

  {
    name: "Promo/Gift",
    keys: ["300-1-1", "300-1-2", "300-1-3", "300-1-4", "300-1-5", "300-1-6"],
    parent: "Discount Management",
  },

  {
    name: "Admin Users",
    keys: ["500-1-1", "500-1-2", "500-1-3", "500-1-4"],
    parent: "User Management",
  },
  {
    name: "Contributors",
    keys: ["500-2-1", "500-2-2", "500-2-3", "500-2-4", "500-2-5"],
    parent: "User Management",
  },
  {
    name: "Permission",
    keys: ["500-3-1", "500-3-2"],
    parent: "User Management",
  },
  {
    name: "Agents",
    keys: ["500-4-1", "500-4-2", "500-4-3", "500-4-4", "500-4-5"],
    parent: "User Management",
  },

  {
    name: "Subscribers",
    keys: ["600-1-1", "600-1-2", "600-1-3"],
    parent: "Subscription Management",
  },
  {
    name: "Subscription History",
    keys: ["600-2-1"],
    parent: "Subscription Management",
  },

  {
    name: "Contact Us Messages History",
    keys: ["700-1-1", "700-1-2"],
    parent: "Manage Messages",
  },
  {
    name: "Campaign Mangement",
    keys: ["700-2-1", "700-2-2", "700-2-3", "700-2-4", "700-2-5", "700-2-6"],
    parent: "Manage Messages",
  },

  {
    name: "Access To Finance",
    keys: ["1000-1-1"],
    parent: "Data Management",
  },
  {
    name: "Financing Opportunities",
    keys: ["1000-2-1"],
    parent: "Data Management",
  },
  {
    name: "Investment Opportunities",
    keys: ["1000-3-1"],
    parent: "Data Management",
  },
  {
    name: "Investment Providers",
    keys: ["1000-4-1"],
    parent: "Data Management",
  },
  {
    name: "Marketing Opportunities",
    keys: ["1000-5-1"],
    parent: "Data Management",
  },
  {
    name: "Trade Shows",
    keys: ["1000-6-1"],
    parent: "Data Management",
  },
  {
    name: "Company Information",
    keys: ["1000-7-1"],
    parent: "Data Management",
  },
  {
    name: "Freight Providers",
    keys: ["1000-8-1"],
    parent: "Data Management",
  },
  {
    name: "Freight Opportunities",
    keys: ["1000-9-1"],
    parent: "Data Management",
  },
  {
    name: "Tenders Opportunities",
    keys: ["1000-10-1"],
    parent: "Data Management",
  },
];

export const permissionKeysObject = {
  packageManagement: ["200-1-1", "200-1-2", "200-1-3", "200-1-4", "200-1-5", "200-1-6", "200-1-7"],
  bankAccountManagement: ["200-2-1", "200-2-2", "200-2-3", "200-2-4"],
  promoGift: ["300-1-1", "300-1-2", "300-1-3", "300-1-4", "300-1-5", "300-1-6"],
  adminUsers: ["500-1-1", "500-1-2", "500-1-3", "500-1-4"],
  contributors: ["500-2-1", "500-2-2", "500-2-3", "500-2-4", "500-2-5"],
  permission: ["500-3-1", "500-3-2"],
  agents: ["500-4-1", "500-4-2", "500-4-3", "500-4-4", "500-4-5"],
  subscribers: ["600-1-1", "600-1-2", "600-1-3"],
  subscriptionHistory: ["600-2-1"],
  contactUs: ["700-1-1", "700-1-2"],
  campaignManagement: ["700-2-1", "700-2-2", "700-2-3", "700-2-4", "700-2-5", "700-2-6"],
  accessToFinance: ["1000-1-1"],
  financingOpportunities: ["1000-2-1"],
  investmentOpportunities: ["1000-3-1"],
  investmentProviders: ["1000-4-1"],
  marketingOpportunities: ["1000-5-1"],
  tradeShows: ["1000-6-1"],
  companyInformation: ["1000-7-1"],
  freightProviders: ["1000-8-1"],
  freightOpportunities: ["1000-9-1"],
  tendersOpportuntities:["1000-10-1"]
};

export const parentPermissions = [
  // {
  //   parent: "Dashboard",
  //   keys: ['100']
  // },
  {
    parent: "Package Management",
    keys: [
      "200-1-1", "200-1-2", "200-1-3", "200-1-4", "200-1-5", "200-1-7",
      "200-2-1", "200-2-2", "200-2-3", "200-2-4",
    ],
  },
  {
    parent: "Discount Management",
    keys: [
      "300-1-1", "300-1-2", "300-1-3", "300-1-4", "300-1-5", "300-1-6",
    ],
  },
  {
    parent: "Evoucher Management",
    keys: [
      "400-1", "400-2", "400-3", "400-4", "400-5", "400-6"
    ]
  },
  {
    parent: "User Management",
    keys: [
      "500-1-1", "500-1-2", "500-1-3", "500-1-4",
      "500-2-1", "500-2-2", "500-2-3", "500-2-4", "500-2-5",
      "500-3-1", "500-3-2",
      "500-4-1", "500-4-2", "500-4-3", "500-4-4", "500-4-5"
    ],
  },
  {
    parent: "Subscription Management",
    keys: [
      "600-1-1", "600-1-2", "600-1-3",
      "600-2-1"
    ]
  },
  {
    parent: "Manage Messages",
    keys: [
      "700-1-1", "700-1-2",
      "700-2-1", "700-2-2", "700-2-3", "700-2-4", "700-2-5", "700-2-6"
    ],
  },
  {
    parent: "Advisory Management",
    keys: [
      "800-1-1", "800-1-2", "800-1-3", "800-1-4", "800-1-5"
    ],
  },
  {
    parent: "Licence Management",
    keys: ["900"],
  },
  {
    parent: "Data Management",
    keys: [
      "1000-1-1",
      "1000-2-1",
      "1000-3-1",
      "1000-4-1",
      "1000-5-1",
      "1000-6-1",
      "1000-7-1",
      "1000-8-1",
      "1000-9-1",
      "1000-10-1"
    ],
  },
  {
    parent: "Audit Management",
    keys: [
      "1100-1"
    ],
  },
  {
    parent: "Content Management",
    keys: [
      "1200-1-1-1", "1200-1-1-2", "1200-1-1-3",
      "1200-1-2-1", "1200-1-2-2", "1200-1-2-3",

      "1200-2-1-1", "1200-2-1-2", "1200-2-1-3",
      "1200-2-2-1", "1200-2-2-2", "1200-2-2-3",
      "1200-2-3-1", "1200-2-3-2", "1200-2-3-3",
      "1200-2-4-1", "1200-2-4-2", "1200-2-4-3",
    ],
  },
  {
    parent: "Report Management",
    keys: [
      "1300-1"
    ],
  },
  {
    parent: "Security Management",
    keys: [
      "1400-1", "1400-2"
    ],
  },
];
