import { UserService } from './../../../../commons/services/user.service';
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FinanceService } from "src/app/commons/services/access-to-finance/finance.service";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";
import { ContactService } from "src/app/commons/services/contacts/contact.service";
import { en_US, NzI18nService, zh_CN } from "ng-zorro-antd/i18n";
import { environment } from "src/environments/environment";
import * as countriesJson from "../../../../../assets/js/countries.json";
import * as newArraySectors from '../../../../../assets/js/new_sectors.json';
import {CountryISO, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input';
import {
  isTemplateRef,
  NzMessageService,
  NzTreeSelectComponent,
  UploadFile,
  UploadFilter,
} from "ng-zorro-antd";
import { ToastrService } from "ngx-toastr";
import { element } from "protractor";
import { Observable, Observer } from "rxjs";
import { Finance } from "src/app/commons/models/Finance";
import {data} from '../../../../../assets/js/allcountries-eco-geo';
import { MailService } from "src/app/commons/services/mail-service";
// import {Finance} from '../../../../commons/models/Finance';
export interface Sectors {
  key: string;
  title: string;
}
@Component({
  selector: "app-access-to-finance",
  templateUrl: "./access-to-finance.component.html",
  styleUrls: ["./access-to-finance.component.css"],
})
export class AccessToFinanceComponent implements OnInit {
//@ViewChild('nzTreeComponent3', { static: false }) nzTreeComponent!: NzTreeSelectComponent;
allsectors: any = (newArraySectors as any).default;
@ViewChild('nzTreeComponent', {static: false})
@ViewChild('nzTreeComponentSectors', {static: false}) nzTreeComponentSectors!: NzTreeSelectComponent;

nzTreeComponent!: NzTreeSelectComponent;
preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
introductionTitle: string = 'Explore the financial services hub and browse finance providers and their financing solutions';
introduction: any = {};
accesToFinancePost: any = {};
total: number;
submitted = false;
pageIndex: number = 0;
pageSize: number = 10;
requestMeetvisible: boolean = false;
form = new FormGroup({});
accessToFinanceForm = new FormGroup({});
editAccessToFinanceForm = new FormGroup({});
TooltipLabel = TooltipLabel;
contactForm = new FormGroup({});
RequestMeetForm = new FormGroup({});
pageSizeOptions;
selected = false;
newArry: Array<any> = [];
newArry2: Array<any> = [];
newArryFinanceTypes: Array<any> = [];
newRegion: Array<any> = [];
newArrayCurrencies: Array<any> = [];
amountLowerList: Array<any> = [];
amountUpperList: Array<any> = [];
lowerAmount: any = null;
upperAmount: any = null;
lowerAmountDollar: any = null;
upperAmountDollar: any = null;
newSectors: any[] = [];
selectedISO = CountryISO.Morocco;
loading: boolean = false;
loading2: boolean = false;
selectedSectorValue: any;
separateDialCode = true;
CountryISO = CountryISO;
/* country  : any[]=[]; */
countriesSelected1: any[] = [];
InstitutionTypeSelected: any = [];
average_processing_time_selectd: any[];
financeTypeModel: any[] = [];
eligibleCountries: any[] = [];
financeBase: any[] = [];
currencyOfFinance: any[] = [];
sectorSubsector: any[] = [];
natureBuissnes = ['Importer', 'Exporter', 'Importer and Exporter', 'Domestic trade', 'Service provider', 'Manufacturer', 'Producer', 'Wholesaler', 'Distributor', 'Dealer', 'Retailer'];
business: any[] = [];
cretaria: any = {};
document: any;
flagUrl: any;
widthConfig = [
    '50px',
    '50px',
    '50px',
    '50px',
    '50px',
    '50px',
    '50px',
    '50px',
    '50px',
    '50px',
];
contact: any = {};
idContact: any;
userId: any;
email: any;
key: string;
finance: Finance[];
idUser: any;
countries: any[];
countriesByFinance: any[] = [];
regions: any[] = [];
InstitutionType: any[] = [];
average_processing_time: any[];
sectorList: any[];
financeCurrenciesList: any[];
financeTypeFilterList: any[];
eligibaleCountriesFilterList = [];
financesTypes: any[];
typeOfInstitutions: any[];
financeProviders: any[] = [];
isVisible: boolean = false;
isVisible1: boolean = false;
allcountries: any = (countriesJson as any).default;
countriesForFlag: any = (countriesJson as any).default;
isAdmin: Boolean;
isConfirmLoading = false;

selectedCountry: any = {
    currency_name: 'Moroccan Dirham',
    flag: 'assets/countries/MAR.svg',
    id: '34',
    in_africa: true,
    isocode: 'MAR',
    label: 'Morocco',
    region: 'Africa',
    type_pays: 'Country',
    value: 'Morocco'
};
customStyle: any = {
    background: '#f7f7f7',
    'border-radius': '4px',
    'margin-bottom': '24px',
    border: '0px'
};
isOkLoading = false;
fileToUpload: File = null;
fileSelected = false;
uploading = false;
fileList: UploadFile[] = [];
maxTagCount = 0;
result: any[] = [];
financesType = [];
financeProvider: any[] = [];
financeToFilter: any[] = [];
list: any[];
financeResult: any[];
institutionTypeSelected: any[] = [];
flags: any[];
selectedCountryModel: any;
selectedCountryTo = '';

countriesRigionsTo = data;
SearchCountryField = SearchCountryField;
selectCountry = false;
selectRegion = false;
InstitutionType2: any[];
fin: any;
amountlist: any[];
amountlist2: any[];
nbrSLice: number = 0;
listdataSlice: any[] = [];
all: any[] = [];
role: any[] = [];
user;
isCheckedButton: boolean = false;
checkDisaledRang: boolean = false;
disabled: boolean = false;
initInvestmentAmount: number = 0;
initRangInvestmentAmount: number = 0;
companyNames: any = [];
selectedCompany: any = {};
selectedCompanyInformation: any = {};
CompanyActive: boolean = false;
totalCapture: number;
pageIndexCapture: number = 1;
pageSizeCapture: number = 10;
cretariaCapture: any = {};
SearchKey: string = '';
financeCapture: any = [];
listOfDisplayData: any = [];
contributorCompanyName: any;
TestLisr: any[] = [
    {
        id: 3,
        revenue: 3,
        size: 3,
        turnover: 4,
        countryName: 'Afghanistan',
        isoCode: 'AFG',
        year: 2020,
        companyId: 'CO-1642982790797'
    },
    {
        id: 4,
        revenue: 7,
        size: 6,
        turnover: 6,
        countryName: 'United States',
        isoCode: 'USA',
        year: 2021,
        companyId: 'CO-1642982790797'
    }
];
Revenuevisible: boolean = false;
EmplyeSize: boolean = false;
trurnoverVisible: boolean = false;
modalFactVisibile: boolean = false;
financesTypesCapture: any[] = [
    'Credit Insurance',
    'Guarantee',
    'Corporate Loan',
    'Letter of Credit',
    'Receivable Financing',
    'Structured Trade Finance',
    'Asset Finance',
    'Factoring',
    'Project Finance'
];
arrCurrencies: any;
private connectedUserId;
typeOfEntities = ["SME", "Corporate", "Financial Institution", "Government", "Trade Supporting Institution"];

// add attachment to mail
fileList2: UploadFile[] = [];
fileList3: UploadFile[] = [];

filters: UploadFilter[] = [
    {
        name: 'type',
        fn: (fileList: UploadFile[]) => {
            const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'video/mp4'].indexOf(w.type));
            if (filterFiles.length !== fileList.length) {
                this.msg.error(`Include file format is incorrect, only png format is supported`);
                return filterFiles;
            }
            return fileList;
        }
    },
    {
        name: 'async',
        fn: (fileList: UploadFile[]) => {
            return new Observable((observer: Observer<UploadFile[]>) => {
                // doing
                observer.next(fileList);
                observer.complete();
            });
        }
    }
];
uploading2=false;
  fullName: string;
  emailUser: any;
  constructor(
    private financeService: FinanceService,
    private subscriptionService: SubscriptionService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private i18n: NzI18nService,
    private msg: NzMessageService,
    private contactService: ContactService,
    private mailService: MailService,
    private userService:UserService
  ) {
    this.financeService = financeService;
    this.subscriptionService = subscriptionService;
  }
  isAdminLogged() {
    if (localStorage.getItem(environment.ROLE) == "ROLE_ADMIN") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }
  ngOnInit(): void {
    this.isAdminLogged();
    this.selectedCountryModel = {
        label: 'Morocco',
        value: 'Morocco',
        id: '34',
        type_pays: 'Country',
        isocode: 'MAR',
        region: 'Africa',
        flag: 'assets/countries/MAR.svg'
    };

    console.log('countries model :', this.allcountries);
    this.changeLanguage();
    this.isAdminLogged();
    this.getSectors();
    this.initContactForm();
    this.initRequestMeetForm();
    this.getAllCountries();
    this.getAllData();
    this.getAllFinanceTypes();
    this.getAllFinanceProviders();
    this.searchByFinance();
    this.initForms();
    this.getCountries();
  }
  getSectors() {
    this.newSectors = this.allsectors;
    console.log('new list of sectors', this.newSectors);
}

sectorChange(secotrs: any) {
    //console.log(secotrs)
    this.selectedSectorValue = this.nzTreeComponentSectors.getCheckedNodeList()[0].key;
}

checkUser(id: any) {
    this.userId =   localStorage.getItem("USER_ID");
    if (this.userId == id) {
        return true;
    } else {
        return false;
    }
}

public redirect(item: any): any {
    return `subaccesstofinance/finance-provider-details?itemResult=${btoa(
        JSON.stringify(item)
    )}`;
}


changeLanguage(): void {
    this.i18n.setLocale(en_US);
}

initForms() {
    this.accessToFinanceForm = this.fb.group({
        companyID: [null],
        country: [this.selectedCountryModel],
        investmentAmountRequesting: [this.initInvestmentAmount],
        investmentAmountRequestingRangLower: [this.initRangInvestmentAmount],
        investmentAmountRequestingRangUpper: [this.initRangInvestmentAmount],
        currenciesFinance: [null, [Validators.required]],
        descriptionService: [null, [Validators.required]],
        eligibleCountries: [null, [Validators.required]],
        eligibleCountriesIso: [null],
        email: [
            null,
            [
                Validators.required,
                Validators.email,
            ],
        ],
        financeProvider: [null, [Validators.required]],
        financeType: [null, [Validators.required]],
        institutionType: [null, [Validators.required]],
        phone: [null, [Validators.required]],
        qualificationCriteria: [null, [Validators.required]],
        securityRequired: [null, [Validators.required]],
        intraAfricaPresence: [null],
        natureBusinessActivity: [null],
        adress: [null],
        additionalInfo: [null],
        descriptionSector: [null, [Validators.required]],
        website: [null],
        sector: [null],
        isoCode: [null],
        emailCompany: [null],
        sectorName: [null],
        adressCompany: [null],
        websiteCompany: [null],
        companyType: [null],
        phoneCompany: [null],
        intraAfricaPresenceCompany: [null],
        date: [null],
        creationDate:[null],
        id:[null]
    });
    this.editAccessToFinanceForm = this.fb.group({
        amountLowerLimit: ['', [Validators.required]],
        country: ['', [Validators.required]],
        currenciesFinance: ['', [Validators.required]],
        descriptionService: ['', [Validators.required]],
        eligibleCountries: ['', [Validators.required]],
        email: [
            '',
            [
                Validators.required,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
            ],
        ],
        financeProvider: ['', [Validators.required]],
        financeType: ['', [Validators.required]],
        institutionType: ['', [Validators.required]],
        phone: ['', [Validators.required, Validators.pattern('[0-9 ]{11}')]],
        qualificationCriteria: ['', [Validators.required]],
        securityRequired: ['', [Validators.required]],
        webSite: [''],
    });

    if (this.role[0] != 'ROLE_PROVIDER') {
        this.accessToFinanceForm.get('financeProvider').valueChanges.subscribe(selectedValue => {
            console.log('financeProvider changed');
            console.log(selectedValue);
            this.changeCompanyName(selectedValue);

        });
    }
    this.accessToFinanceForm.get('country').valueChanges.subscribe(selectedValue => {
        console.log('financeProvider changed');
        console.log(selectedValue);
        this.countryChangeModel(selectedValue);

    });
}

initContactForm() {
    this.contactForm = this.fb.group({
        subject: ['', [Validators.required]],
        message: ['', [Validators.required]],
        file: [null],
        receiver: ['', [Validators.email, Validators.required]],
        section: [''],
        accessFinance: [''],
    });
}

initRequestMeetForm() {
    this.RequestMeetForm = this.fb.group({
        topic: [''],
        message: ['', [Validators.required]],
        email: ['', [Validators.email, Validators.required]],

    });
}


contactNow(receiver: any, access: any) {
    console.log('receiver input :', receiver);
    console.log('access input :', access);
    for (const i in this.contactForm.controls) {
        this.contactForm.controls[i].markAsDirty();
        this.contactForm.controls[i].updateValueAndValidity();
    }
    this.contact.subject = this.contactForm.value.subject;
    console.log('subject ' + this.contact.subject);
    this.contact.message = this.contactForm.value.message;
    console.log('message ' + this.contact.message);
    this.contact.receiver = receiver;
    console.log('Reciever ');
    console.log(this.contact.receiver);
    console.log('ID ' + receiver.id);
    this.contact.section = 'AccesstoFinance';
    this.contact.accessFinance = access;
    console.log(this.contact.accessFinance);
    this.contactService.contactNow(this.contact).subscribe((data: any) => {
        console.log('Contact');
        console.log(data);

        if (this.fileList.length > 0) {
            console.log('file selected!!!!!!!!!');
            this.idContact = data.id;
            // this.handleUpload(this.idContact);
        }

        this.toaster.success('Message Sent Successfully', 'Success', {
            closeButton: true,
            timeOut: 4000,
        });
    });
    this.initContactForm();
    this.contactForm.reset();
}

company: any;
idCompany: any;


upload(id: any) {
    console.log('in upload!!!!');

    const formData: FormData = new FormData();

    formData.append('file', this.fileToUpload, this.fileToUpload.name);

    this.contactService.uploadFile(id, formData).subscribe((res) => {
        console.log(res);

        this.contact.file = res;
        console.log('My File');
        console.log(this.contact.file);
        this.toaster.show(
            'success',
            'File  ' + this.fileToUpload.name + ' is loaded'
        );

        this.fileToUpload = null;
        this.fileSelected = false;
    });
    this.initContactForm();
}


beforeUpload = (file: UploadFile): boolean => { 
    console.log('file :>> ', file); 
    this.fileList3 = this.fileList3.concat(file);
    console.log('this.fileList3 :>> ', this.fileList3); 

    return false;
};

// handleUpload(id: any) {
//     const formData = new FormData();


//     this.fileList.forEach((file: any) => {
//         formData.append('file', file);
//     });

//     this.uploading = true;
//     this.contactService.uploadFile(id, formData).subscribe(
//         () => {
//             this.uploading = false;
//             this.fileList = [];

//             console.log(this.contact.file);

//             this.msg.success('upload successfully.');

//             this.fileToUpload = null;
//             this.fileSelected = false;
//         },
//         () => {
//             this.uploading = false;
//             this.msg.error('upload failed.');
//         }
//     );
// }

initEditForm(data: any) {
    data.isVisibleEditForm = true;
    console.log('see ');
    console.log(data);
    this.editAccessToFinanceForm.patchValue({
        amountLowerLimit: data.amountLowerLimit,
        country: data.country,
        currenciesFinance: data.currenciesFinance,
        descriptionService: data.descriptionService,
        eligibleCountries: data.eligibleCountries,
        email: data.email,
        financeProvider: data.financeProvider,
        financeType: data.financeType,
        institutionType: data.institutionType,
        phone: data.phone,
        qualificationCriteria: data.qualificationCriteria,
        securityRequired: data.securityRequired,
        webSite: data.webSite,
    });
    this.form.updateValueAndValidity();
}

updateAcces2Finance(id: any) {
    this.financeService
        .updateAccess(this.editAccessToFinanceForm.value, id)
        .subscribe((data: any) => {
            this.getAllData();
        });
}

deleteAcces2Finance(id: any) {
    this.financeService.deleteAccess(id).subscribe((data: any) => {
        this.getAllData();
    });
}

getAllData() {
    // this.financeService.getAllFinance().subscribe((data: any) => {
    //     this.finance = data.content;
    //     this.total = data.totalElements;

    // });
}

showMeet() {
    console.log('click meet form');
    this.requestMeetvisible = true;
}

cancleMeet() {
    console.log('cancel meet form');
    this.requestMeetvisible = false;
}

pageSizeChanged(event) {
    console.log(event);
}


saveIntroduction(value: any) {

    this.updateIntroduction(value);
}

filter(value) {
    this.initCretaria();
    this.reset();
    if (value.country != '') {
        this.cretaria.country = value.country;
    }
    if (value.financesType != '') {
        this.cretaria.finance_type = value.financesType;
    }
    if (value.typeOfInstitution != '') {
        this.cretaria.institution_type = value.typeOfInstitution;
    }
    if (value.financeProvider != '') {
        this.cretaria.finance_provider = value.financeProvider;
    }
    this.getFilterByPagination(this.cretaria);

}

getFilterByPagination(cretaria) {
    this.key = '';
    this.financeService
        .getFilterByPagination(this.cretaria)
        .subscribe((data: any) => {
            this.finance = data.content;
            this.total = data.totalElements;

        });
}




updateIntroduction(value: any) {
    this.introduction.id = 1;
    this.introduction.text = value;
    this.financeService
        .updateTitle(this.introduction)
        .subscribe((data: any) => {
            this.introductionTitle = data.text;
        });
}

private initCretaria() {
    for (let attr in this.cretaria) {
        if (!(attr === 'page' || attr === 'size')) {
            delete this.cretaria[attr];
        }
    }
}

changePageSize(value: number) {
    this.pageSize = value;
    this.cretaria.size = this.pageSize;
    this.getFilterByPagination(this.cretaria);

}

reset() {
    this.pageIndex = 0;
    this.cretaria.page = this.pageIndex;
    this.cretaria.size = this.pageSize;

    this.finance = [];
}

getAllCountries() {
        this.countries = this.allcountries;
        console.log('all countries ', this.countries);
}

/*   getAllFinanceTypes() {
  this.financeService.getFinanceTypes()
    .subscribe((res: any[]) => {
      this.financesTypes = res;
    })
} */

/*   getAllTypeOfInstitution() {
  this.financeService.getInstitutionTypes().subscribe(
    (res: any[]) => {
      this.typeOfInstitutions = res;
    }
  )
} */

/*   getAllFinanceProvides() {
  this.financeService.getFinanceProviders().subscribe(
    (res: any[]) => {
      this.financeProviders = res;
    }
  )
} */
showModal(): void {
    this.isVisible = true;
}

showModal1(): void {
    this.isVisible1 = true;
}

handleCancel(): void {
    this.isVisible = false;
    if(this.accessToFinanceForm.value.id != null){
        this.initForms();
        this.selectedCompanyInformation=null
        this.CompanyActive=false
    }
}

handleCancel1(isVisible): void {

    this.isVisible = false;
}

handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
        this.isVisible1 = false;
        this.isConfirmLoading = false;
    });
}

handleChange({file, fileList}): void {
    const status = file.status;
    if (status !== 'uploading') {

    }
    if (status === 'done') {
        this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
        this.msg.error(`${file.name} file upload failed.`);
    }

}

showEligble(value) {
    console.log('eleib val :', value);
    console.log('elibl iso', this.getEligibleCountriesIso(value, 'isocode'));
}

getEligibleCountriesIso(value: any[], attr: String) {
    if (attr == 'isocode') {
        return value.map((item: any) => item.isocode);
    }
    if (attr == 'label') {
        return value.map((item: any) => item.label);
    }
}

selectChangeMode() {

    this.checkDisaledRang = false;
    console.log('rang', this.isCheckedButton);
    if (this.isCheckedButton == true) {
        this.disabled = true;
        this.checkDisaledRang = true;
    } else {

        this.disabled = false;
    }
    console.log(this.disabled);
}


selectedCountries: any = [];

countryChange(value: string): void {

    this.selectedCountries = value;
    console.log('selectedCountries', this.selectedCountries);

}

countryChangeModel(country) {
    console.log('countires :', country);
    Object.entries(this.CountryISO).forEach(([key, value]) => {
        if (key.toLowerCase() == (country.label as String).split(' ').join('').toLowerCase()) {
            this.selectedISO = value;
        } else if (country.isocode == 'COD') {
            this.selectedISO = CountryISO.CongoDRCJamhuriYaKidemokrasiaYaKongo;
        }
    });
    //console.log(this.selectedISO);
}

handleOk1(): void {

    this.isVisible1 = false;
    this.fileList3=[];
}

onFileSelected(event) {
    // console.log(files.item(0));
    /*  if(event!=null){
      this.selected = true
    } */

    if (event.target.files[0] != null) {
        this.fileSelected = true;
    }

    this.fileToUpload = <File> event.target.files[0];

}

getAllFinanceTypes() {
    this.financeService.getFinanceByType().subscribe((res: any[]) => {
        this.financesTypes = res;
        this.maxTagCount = this.financesTypes.length + 1;
    });
    console.log('finance type :' + this.financesTypes);
}

getAllFinanceProviders() {
    this.financeService.getFinanceProvider().subscribe((res: any[]) => {
        this.financeProviders = res;
    });
}


totalAllData: any;

searchByFinance() {

    this.loading = true;
    let var1 = [];
    let var2 = [];
    if (this.financesType[0] == 'all') {
        this.financesType = [];
    }
    if (this.financeProvider[0] == 'all') {
        this.financeProvider = [];
    }
    let object = {
        finance_type: this.financesType,
        finance_provider: this.financeProvider

        /*
        finance_type: ["Debt"],
        finance_provider: ["Banco Millennuim Atlantico", "Bank of Africa"],
         */
    };

    console.log('first search ', object);

    this.financeService.searchByFinanceProvider(object)
        .subscribe((data: any) => {

            this.financeBase = data;
            console.log('data ', data);
            console.log('data.totalElements ', data.totalElements);
            this.totalAllData = data.totalElements;
            console.log('totalAllData ', this.totalAllData);
            if (this.financeBase) {
                console.log('finance base ', this.financeBase);
                this.loading = false;
            }


            this.financeBase.forEach((element, index, array) => {
                element.filterFinance_types = element.finance_types;

            });
            this.financeToFilter = data;


            //this.countriesRigionsTo[0].children = []

            this.countriesByFinance = this.financeToFilter
                .map((item) => item.country)
                .filter((value, index, self) => self.indexOf(value) === index);
            console.log('countries ', this.countriesByFinance);
            if (this.countriesByFinance != null) {
                // this.getCountry(this.countriesByFinance);
            }

            this.InstitutionType = this.financeToFilter
                .map((item) => item.institution_type)
                .filter((value, index, self) => self.indexOf(value) === index);
            // console.log("InstitutionType : ", this.InstitutionType);

            this.newArry2 = [].concat
                .apply([], this.InstitutionType)
                .filter((value, index, self) => self.indexOf(value) === index);

            this.InstitutionType2 = this.financeToFilter
                .map((item) => item.institution_type)
                .filter(
                    (value, index, self) => self.indexOf(value) === index && !!value
                );
            //console.log("InstitutionType : ", this.InstitutionType);

            this.average_processing_time = this.financeToFilter
                .map((item) => item.average_processing_time)
                .filter((value, index, self) => self.indexOf(value) === index);

            this.financeTypeFilterList = this.financeToFilter.map((item) =>
                item.finance_types.map((itemIndex) => itemIndex.finance_type)
            );
            this.newArryFinanceTypes = [].concat
                .apply([], this.financeTypeFilterList)
                .filter((value, index, self) => self.indexOf(value) === index);

            this.financeCurrenciesList = this.financeToFilter
                .map((item) =>
                    item.finance_types.map((itemIndex) => itemIndex.finance_currencies)
                )
                .filter((value, index, self) => self.indexOf(value) === index);
            this.newArrayCurrencies = [].concat
                .apply([], this.financeCurrenciesList)
                .filter((value, index, self) => self.indexOf(value) === index);

            this.amountlist = this.financeToFilter.map((item) =>
                item.finance_types.map((itemIndex) => itemIndex.lower_limit_amount)
            );
            this.amountLowerList = [].concat
                .apply([], this.amountlist)
                .filter((value, index, self) => self.indexOf(value) === index);


            this.amountlist2 = this.financeToFilter.map((item) =>
                item.finance_types.map((itemIndex) => itemIndex.upper_limit_amount)
            );
            this.amountUpperList = [].concat
                .apply([], this.amountlist2)
                .filter((value, index, self) => self.indexOf(value) === index);


            //console.log("financeType : ", this.newArryFinanceTypes);
            this.eligibaleCountriesFilterList = this.financeToFilter.map(
                (item) => item.eligible_countries
            );
            this.newArry = [].concat
                .apply([], this.eligibaleCountriesFilterList)
                .filter((value, index, self) => self.indexOf(value) === index);

            this.regions = this.financeToFilter.map((item) => item.regions);

            this.newRegion = [].concat
                .apply([], this.regions)
                .filter((value, index, self) => self.indexOf(value) === index);
            if (this.newRegion != null) {
                this.getRegion(this.newRegion);
            }
            //  this.newRegion = ["Common Market for Eastern and Southern Africa", "East Africa"]

            this.sectorList = this.financeToFilter
                .map((item) => item.sector_description)
                .filter((value, index, self) => self.indexOf(value) === index);

            //console.log("eligible_countries : ", this.newArry);

            this.financeResult = data;
            // this.nbrSLice = 0
            this.sliceData();

        });
}

emptyList: any[] = [];

filterByQuery() {
    this.loading = true;

    const arrSector: Sectors[] = [];
    this.nzTreeComponentSectors.getCheckedNodeList().map((sector) => {
        arrSector.push({
            key: sector.origin.key,
            title: sector.origin.title
        });
    });
    console.log('sector key', arrSector.map((item) => item.key));
    if (this.nzTreeComponent != undefined) {
        this.myRegionSelected = this.nzTreeComponent
            .getCheckedNodeList()
            .map((item) => {
                if (item.parentNode.key == '0-1') {
                    return item.origin.title;
                }
            })
            .filter(
                (value, index, self) => self.indexOf(value) === index && !!value
            );
    }
    // need to fix 
    // this.myRegionSelected = this.nzTreeComponent
    //     .getCheckedNodeList()
    //     .map((item) => {
    //         if (item.parentNode.key == "0-1") return item.origin.title;
    //     })
    //     .filter(
    //         (value, index, self) => self.indexOf(value) === index && !!value
    //     );

    let query = {
        finance_type: this.financesType,
        finance_provider: this.financeProvider,
        country: this.selectedCountries,
        region: this.myRegionSelected,
        institution_type: this.institutionTypeSelected,
        eligible_countries: this.eligibleCountries,
        lower_amount_limit: this.lowerAmount == '' ? this.lowerAmount = null : this.lowerAmount,
        upper_amount_limit: this.upperAmount == '' ? this.upperAmount = null : this.upperAmount,
        currency_finance: this.currencyOfFinance,
        sector: arrSector.map((item) => item.key),
        lower_limit_dollar: this.lowerAmountDollar == '' ? this.lowerAmountDollar = null : this.lowerAmountDollar,
        upper_limit_dollar: this.upperAmountDollar == '' ? this.upperAmountDollar = null : this.upperAmountDollar,
        average_processing_time: null
    };


    this.nbrSLice = 0;
    console.log('my Object==========>  ', query);
    this.financeService.getA2fByFilter(query)
        .subscribe((result: any) => {
            this.financeToFilter = result;
            if (this.financeToFilter) {
                this.loading = false;
            }
            console.log('my result =========', this.financeToFilter);
            if (this.financeToFilter.length != 0) {
                this.financeBase = this.financeToFilter;
                this.loading = false;
            }
            if (this.financeToFilter.length == 0) {
                this.financeBase = this.emptyList;
            }


            this.sliceData();
            console.log('Loading =============', this.loading);
        });


}

arrayCountries: any[] = [];
myRegionSelected: any[] = [];
myinstitutionTypeSelected: any[] = [];
myFinanceType: any[] = [];
myCurrency: any[] = [];
myLowerAmount: any[] = [];
myUpperAmount: any[] = [];


/*  getCountry(countries: any[]) {
   //console.log("liste countries ", countries);
   let i = 0;
   countries.forEach((item) => {
     let key = "0-0-%";
     let node = {
       title: item,
       value: key.replace("%", "" + i),
       key: item,
       isLeaf: true,
     };
     this.countriesRigionsTo[0].children.push(node);

     i++;
   });
   this.selectCountry = true;
 } */


getRegion(regions: any[]) {
    //console.log("liste region ", regions);
    let i = 0;
    this.newRegion.forEach((item) => {
        let key = '0-1-%';
        let node = {
            title: item,
            value: key.replace('%', '' + i),
            key: item,
            //key: element.code,
            isLeaf: true,
        };
        //this.countriesRigionsTo[1].children.push(node);
        i++;
    });

    this.selectRegion = true;
}

countryChangeTo(value) {

    if (this.nzTreeComponent.getCheckedNodeList().length > 0) {
        if (
            this.nzTreeComponent.getCheckedNodeList()[0].parentNode.origin.key ==
            '0-0'
        ) {
            this.countriesRigionsTo[1].disabled = true;
            this.countriesRigionsTo[0].disabled = false;
        } else if (
            this.nzTreeComponent.getCheckedNodeList()[0].parentNode.origin.key ==
            '0-1'
        ) {
            this.countriesRigionsTo[0].disabled = true;
            this.countriesRigionsTo[1].disabled = false;
        }
    } else {
        this.countriesRigionsTo[0].disabled = false;
        this.countriesRigionsTo[1].disabled = false;
    }
}

filterByCountry(countries: any[]) {
    //this.result = []
    if (this.result.length == 0) {
        this.financeBase = this.financeToFilter;
    } else {
        this.financeBase = this.result;
        this.result = [];
    }

    countries.forEach((item) => {
        this.financeBase.forEach((el) => {
            if (el.country === item) {
                this.result.push(el);
            }
        });
    });


    if (this.result.length > 0) {
        this.financeBase = this.result;
    } else {
        this.financeBase = this.financeResult;
    }


}

filterInstitutionType(types: any[]) {
    //console.log("institution type ", types);
    //this.result = []
    if (this.result.length == 0) {
        this.financeBase = this.financeToFilter;
    } else {
        this.financeBase = this.result;
        this.result = [];
    }

    types.forEach((item) => {
        this.financeBase.forEach((el) => {
            if (el.institution_type === item) {
                this.result.push(el);
            }
        });
    });
    if (this.result.length > 0) {
        this.financeBase = this.result;
    } else {
        this.financeBase = this.financeResult;
    }
}

// flagUrl : any ;
flagUrlForFilter: any;

removeDuplicationByAttribut(
    array: any[],
    attribut: any,
    selectedCountry: any
) {
    const uniqueArray = array.filter((element, index) => {
        const item = element[attribut];
        return (
            index ===
            array.findIndex((obj) => {
                return (
                    (obj[attribut] === item &&
                        obj[attribut] != null &&
                        obj[attribut] != selectedCountry.currency_code) ||
                    (obj[attribut] === item &&
                        obj[attribut] != null &&
                        obj[attribut] == selectedCountry.currency_code &&
                        obj['label'] == selectedCountry.label)
                );
            })
        );
    });
    return uniqueArray;
}

loadFinanceResult(itemResult: any) {
    this.financeService.item = itemResult;
}

getFlag(isoCode: any): boolean {
    this.allcountries.forEach((element) => {
        if (element.isocode == isoCode) {
            this.flagUrl = element.flag;
        }
    });
    return true;
}

getFlagForCountrySelect(labelCountry: any): boolean {
    // console.log("country input :",labelCountry);
    this.allcountries.forEach((element) => {
        if (element.label == labelCountry) {
            this.flagUrlForFilter = element.flag;
        }
    });
    return true;
}

//scroll functions
onScroll() {
    console.log('scrolled!!');
    //if (this.bottomReached()) {
    this.sliceData();
    //       }
}

// @HostListener("window:scroll", [])
// onScroll(): void {
//     if (this.bottomReached()) {
//         this.sliceData()
//     }
// }

bottomReached(): boolean {
    //return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
    return (window.innerHeight + window.scrollY) >= document.body.scrollHeight;
}

sliceData() {
    this.nbrSLice = this.nbrSLice + 4;
    this.listdataSlice = this.financeBase.slice(0, this.nbrSLice);
    console.log('list Item : ', this.financeBase);
    console.log('listdataSlice : ', this.listdataSlice);
}

// getNatureBuisness() {
//     // this.financeService.getNatureBuisness().subscribe((data: any[]) => {
//     //     this.natureBuissnes = data;
//     //     console.log('nature buisness', data);
//     // })
//     this.subscriptionService.getInstitutionNature().subscribe( 
//         (res: any[]) => {
//             this.natureBuissnes = res;
//             console.log('nature buisness', res);
//         }
//     )
// }


/*   getAllNatureBusiness(){
    this.financeService.getNatureBusiness()
    .subscribe((data : any[])=>{
          console.log("All nature business================================ ",data)
          this.business = data
    })
  } */


/*
    public adapter: ChatAdapter = new CostumChatAdapter(this.liveChatService, this.webSocketService, this.userService);
*/



AllFinancesTypeSelected = false;

onChangeFinancesType(value) {
    console.log('value ', value);
    this.AllFinancesTypeSelected = false;
    value.forEach(element => {
        if (element == 'all') {
            this.financesType = [];
            this.AllFinancesTypeSelected = true;
            this.financesType = ['all'];
        }
    });

}

AllFinancesProvidersSelected = false;

onChangeFinanceProvider(value) {
    console.log('all finance provider ', value);
    this.AllFinancesProvidersSelected = false;

    value.forEach(element => {
        if (element == 'all') {
            this.financeProvider = [];
            this.AllFinancesProvidersSelected = true;
            this.financeProvider = ['all'];

        }
    });
}

isLoadingCountries = false;

getCountries() {
    this.isLoadingCountries = true;
    let sortBy = [{
        prop: 'region',
        direction: 1
    }, {
        prop: 'label',
        direction: 1
    }];
    //  this.isLoadingCountries = true;
            this.countries = this.allcountries.sort(function(a, b) {
                let i = 0, result = 0;
                while (i < sortBy.length && result === 0) {
                    result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
                    i++;
                }
                return result;
            });
            this.isLoadingCountries = false;
            console.log('all countries', this.countries);

        
}
cancelModel1(){
    this.isVisible1 = false;
    this.fileList3=[];
}
cancelModel2(){
    this.requestMeetvisible=false;
    this.fileList3=[];
}
//sending mail
mailObject: any = {};

sendMail(object,file,item) {
    console.log("object",object);
    console.log("file",file);
    
    this.mailService.senMail2(object,file).subscribe((data: any) => {
        item.isVisible1 = false;
        item.requestMeetvisible = false;
        this.toaster.success('mail has been successfully sent');
    },(error) => {
        this.isVisible1 = false;
        this.requestMeetvisible = false;
        this.toaster.error('mail has not been sent');
});
}

submitFormRequestMeeting(value: any,item): void {
    for (const key in this.RequestMeetForm.controls) {
        this.RequestMeetForm.controls[key].markAsDirty();
        this.RequestMeetForm.controls[key].updateValueAndValidity();
    }
   
    if (this.RequestMeetForm.valid) {  
        this.sendMail(value,this.fileList3,item);
    }
}


showRequestMeetingFrom(item: any) {
    let message = 'Dear [Mr./Ms. Last Name],\n' +
        '\n' +
        'I am writing to schedule a meeting to [write what the purpose of your meeting is]. If the time works for you, I would like to meet at [time] on [date] at [place].\n' +
        '\n' +
        'Please confirm whether that works for you or if another time and place is better. I look forward to our meeting.\n' +
        '\n' +
        'Thank you,\n' +
        '[Your name]';
    this.RequestMeetForm.patchValue({
        topic: 'Request for meeting',
        message: message,
        email: item.email
    });
    item.requestMeetvisible = true;
}

getUser() {
  let id = localStorage.getItem("USER_ID")
  if(id)
  {
     this.userService.getAuthUser(id).subscribe(
    (res: any) => {
      console.log(res);
      this.fullName = res.firstName +" "+res.lastName;
      this.emailUser = res.email;
    })
}
}
showContactNowForm(item: any) {
    console.log('item contact now :', item);
    let message = 'Dear Mr./Ms. ' + item.contact_name + ' ,\n' +
        '\n' +
        'I am writing to contact you in order to (purpose of your mail).\n' +
        '\n' +
        'Thank you,\n' +
        this.fullName;
    this.RequestMeetForm.patchValue({
        topic: 'no-replay',
        message: message,
        email: item.contact_email
    });
    item.isVisible1 = true;
}

changeCompanyName(value) {
    if (value != '') {
        console.log('change value', value);
        //this.validateForm.controls.company = value
        this.getCompanyByName(value);
    }
    return value;
}

getCompanyByName(value) {
    if (this.role[0] != 'ROLE_PROVIDER') {

        if (value != null) {
            console.log('provider in',);
            this.subscriptionService.getCompanyName(value).subscribe((resp: any) => {
                this.companyNames = resp;
                if (this.companyNames.length > 0) {
                    this.selectedCompany = this.companyNames[0];
                }

                console.log('companyNames', this.selectedCompany);
            });
        }
    } else {
        if (value != null) {
            console.log('provider in', value);
            this.selectedCompany = value;
            // this.subscriptionService.getCompanyName(value.name).subscribe((resp: any) => {
            //     this.companyNames = resp;
            //     console.log('this.resp :>> ', resp);
            //     console.log('this.companyNames contributor :>> ', this.companyNames);
            //     if (this.companyNames.length > 0) {
            //         this.selectedCompany = this.companyNames[0];
            //     }else{
            //         this.selectedCompany= this.companyNames[0];
            //         this.companyNames=this.selectedCompany;
            //     }

            //     //
            //     console.log("companyNames from elese :", this.selectedCompany);
            // })
        }
        console.log(' base de donne company mapping', this.selectedCompany);
        this.companyNames.push(this.selectedCompany);
        console.log('list of companys :', this.companyNames);
    }
}

getCountryByIsoCode(isoCode) {
    return this.allcountries.filter((item: any) => item.isocode == isoCode)[0];
}

getNatureBusinessActivity(value) {
    console.log('getNatureBusinessActivity', this.natureBuissnes.filter((item: any) => item.nature_business_activity.toLowerCase().includes(value.toLowerCase()))[0]);
    return this.natureBuissnes.filter((item: any) => item.nature_business_activity.toLowerCase().includes(value.toLowerCase()))[0];
}


private initCretariaCapture() {
    for (let attr in this.cretariaCapture) {
        if (!(attr === 'page' || attr === 'size')) {
            delete this.cretariaCapture[attr];
        }
    }
}

resetPage() {
    this.pageIndexCapture = 1;
    this.cretariaCapture.page = this.pageIndexCapture;
    this.cretariaCapture.size = this.pageSizeCapture;

    this.finance = [];
}

// getCompanyType(value){
//     console.log("test",this.allcountries.filter((item:any)=>item.isocode=isoCode)[0])
//     return this.allcountries.filter((item:any)=>item.isocode=isoCode)[0]
// }


showModalFact(type: string): void {
    this.modalFactVisibile = true;
    if (type == 'turnover') {
        this.trurnoverVisible = true;
        this.EmplyeSize = false;
        this.Revenuevisible = false;
    }
    if (type == 'revenue') {
        this.trurnoverVisible = false;
        this.EmplyeSize = false;
        this.Revenuevisible = true;
    }
    if (type == 'size') {
        this.trurnoverVisible = false;
        this.EmplyeSize = true;
        this.Revenuevisible = false;
    }
}

handleCancelModalFact(): void {
    this.modalFactVisibile = false;
}

baseUrl = environment.baseUrl + "/company";

getUrlImg(name) {
return this.baseUrl + "/downloadImage/" + name+".jpg";
}

onImgError(event){
event.target.src = '/assets/img/Mask_group.png'
event.onerror = null; 
}

beforeUpload2 = (file: File) => {
console.log("file.type", file.type);
return new Observable((observer: Observer<boolean>) => {
    // check type
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isMP4 = file.type === 'video/mp4';
    if (!isJPG && !isPNG && !isMP4) {
        this.msg.error('You can only upload JPG,PNG or MP4 file!');
        observer.complete();
        return;
    }
    // check size
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
        this.msg.error('Image must smaller than 2MB!');
        observer.next(isJPG && isLt2M && isMP4);
        observer.complete();
        return;
    }
    // check height
    // this.checkImageDimension(file).then(dimensionRes => {
    //   if (!dimensionRes) {
    //     this.msg.error('Image only 300x300 above');
    //     observer.complete();
    //     return;
    //   }

    //   observer.next(isJPG && isLt2M );
    //   observer.complete();
    // });
});
};

uploadFile(id) {
this.uploading = true; // start uploading flag
const formData = new FormData();
this.fileList2.forEach((file: any) => {
    formData.append('file', file);
});
this.uploading2 = true;

this.financeService.uploadAttachement(id, formData)
    .subscribe(
        () => {
            this.uploading = false;
            this.uploading2 = true;
            this.fileList2 = [];
            this.msg.success('upload successfully.');
            this.fileToUpload = null; 
            this.handleOk();
        },
        () => {
            this.uploading = false;
            this.uploading2 = true;
            this.msg.error('upload failed.');
        }
    );
}

getCountryByIso(value) {
    let country = "";
    this.allcountries.forEach(element => {
        if (element.isocode == value)
            country = element
    });
    return country;
}
getArrayOfCountries(countryIso :any[]){
 let countries :any[] =[]
 countryIso.forEach((element :any)=>{
     countries.push(this.getCountryByIso(element))
 });
 return countries;
}

}

