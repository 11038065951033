import { Component, OnInit } from '@angular/core';
import { FreightService } from '../../../../commons/services/service freight/freight.service';
import {environment} from '../../../../../environments/environment';
@Component({
  selector: 'app-freight-opportunities-details',
  templateUrl: './freight-opportunities-details.component.html',
  styleUrls: ['./freight-opportunities-details.component.css']
})
export class FreightOpportunitiesDetailsComponent implements OnInit {
  baseUrl = environment.baseUrl + "/company";
  freight: any;
  freightTypeSelected: any;
  constructor(private freightService: FreightService) { }

  ngOnInit() {
    this.freight = this.freightService.freight
    this.freightTypeSelected = this.freightService.freight.shippingInfos
  }
  downloadAttachementFiles(freightKey: string) {
    console.log('financingKey :>> ', freightKey);
    this.freightService.downLoadFile(freightKey).subscribe((data: any) => {
      console.log("completeCallback 4", data);
      let binaryData = [];
      binaryData.push(data);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }));
      downloadLink.setAttribute('download', freightKey + '.zip');
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
  onImgError(event){
    event.target.src = '/assets/img/Mask_group.png'
    event.onerror = null;
  }
  getUrlImg(name) {
    return this.baseUrl + "/downloadImage/" + name+".jpg";
  }
}
