import { Component, NgZone, OnInit } from '@angular/core';
import { OrderService } from 'src/app/commons/services/order-service/order.service';
import { ReportService } from 'src/app/commons/services/report-service/report.service';
import { UserService } from 'src/app/commons/services/user.service';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-orders-status-seller',
  templateUrl: './orders-status-seller.component.html',
  styleUrls: ['./orders-status-seller.component.css']
})
export class OrdersStatusSellerComponent implements OnInit {

  totalCount;
  orders = [];
  incomeRevenue = [];
  orderStatus = [];
  orderTracking = [];
  public statusValues = ["TODAY","THIS WEEK","THIS MONTH" , "THIS YEAR" , "ALL"];
  public revenue = "THIS MONTH";
  public analytic = "THIS YEAR";
  public statusValue = "TODAY";
  public recentOrder = "THIS WEEK";
  public tracking = "THIS YEAR";

  constructor(private zone: NgZone, private orderService: OrderService,
    private reportService:ReportService,private userService: UserService) { }


  ngOnInit() {
    this.getOrdersHystorySeller();
    this.getSalesReportStat();
    this.getRevenueIncome();
    this.getOrderStatus();
    this.getOrderTracking();
    // this.getRevenueOutCome();
  }

  statusDetection(value) {
    this.analytic = value
    this.getOrdersHystorySeller();
    this.getSalesReportStat();
    this.getRevenueIncome();
    this.getOrderStatus();
    this.getOrderTracking();
  }

  getSalesReportStat() {
    this.reportService.getSalesReportStat(this.analytic).subscribe((res : any) => {
      this.totalCount= res
      console.log("total count : " , this.totalCount);

    })    
  }
  getOrdersHystorySeller() {
    this.reportService.getRecentOrder(this.analytic).subscribe(
      (res: any) => {
        console.log("result orderrss::!  : ", res);
        this.orders = res
      })
  }

  getRevenueIncome() {
    this.reportService.getRevenueIncome(this.analytic).subscribe((res : any) => {
      this.incomeRevenue = res;
      this.initLineChart();
    })
  }

  getOrderStatus() {
    this.reportService.getOrderStatus(this.analytic).subscribe((res : any) => {
      this.orderStatus= res
      console.log("order Status : " , res);
      this.initPieChart();
    })    
  }

  getOrderTracking() {
    this.reportService.getOrderTracking(this.analytic).subscribe((res : any) => {
      console.log("this.orderTracking " , this.orderTracking);
      this.orderTracking= res
    })   
  }

  initLineChart() {
    this.zone.runOutsideAngular(() => {
      console.log("income : " , this.incomeRevenue);
      
      let chart = am4core.create("chartRevenue", am4charts.XYChart);
      chart.data = this.incomeRevenue;
      
      // [{
      //   "date": "1950",
      //   "income": 55,
      //   "outcome": 11,
      // }, {
      //   "date": "1951",
      //   "income": 130,
      //   "outcome": 120,
      // }, {
      //   "date": "1952",
      //   "income": 230,
      //   "outcome": 110,
      // }, {
      //   "date": "1953",
      //   "income": 210,
      //   "outcome": 107,
      // }, {
      //   "date": "1954",
      //   "income": 101,
      //   "outcome": 150
      // }, {
      //   "date": "1955",
      //   "income": 320,
      //   "outcome": 110
      // }, {
      //   "date": "1956",
      //   "income": 100,
      //   "outcome": 30
      // }]
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0;
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      categoryAxis.renderer.grid.template.disabled = true;


      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "amountIncome";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.stroke = am4core.color("#55C492");
      series.tensionX = 0.77;
      series.fillOpacity = 0.7;
      series.fill = am4core.color("#F0FBF6");

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "outcome";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.77;
      series.fill = am4core.color("#FFF0F4");
      series.fillOpacity = 0.7;
      series.stroke = am4core.color("#F07B95");

      var range = valueAxis.createSeriesRange(series);
      range.value = 0;
      range.endValue = -1000;
      range.contents.stroke = am4core.color("#FF0000");
      range.contents.fill = range.contents.stroke;

      // Add scrollbar
      // var scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();

    }
    )
  }
  initPieChart() {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("chartStatus", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
  
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 85;
    chart.legend.scrollable = true;

    const propertyNames = Object.keys(this.orderStatus);
    const propertyValues = Object.values(this.orderStatus);
    var result = propertyNames.map((value,index) =>{
        return {status: value, value:propertyValues[index]};
    });

    chart.data = result;
    chart.innerRadius = am4core.percent(40);

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "status";
    series.slices.template.stroke = am4core.color("#fff");
    series.innerRadius = 10;
    series.slices.template.fillOpacity = 0.5;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;

    // series.slices.template.fill = am4core.color("{series.color}")
    series.colors.list = [
      am4core.color("#55C492"),
      am4core.color("#E59AAE"),
      am4core.color("#B57DFF"),
  ];
  
  }

}
