import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddNewProductServiceComponent } from './pages/add-new-product-service/add-new-product-service.component';
import { AddServiceComponent } from './pages/add-service/add-service.component';
import { BUSINESSPROFILEComponent } from './pages/businessprofile/businessprofile.component';
import { ChagePassComponent } from './pages/chage-pass/chage-pass.component';
// import { DashboardComponent } from './pages/dasboard/dashboard.component';
import { DashboardComponent } from './pages/dasboard/dashboard.component';
import { DeactivateAccountComponent } from './pages/deactivate-account/deactivate-account.component';
import { FeaturingRequestsComponent } from './pages/featuring-requests/featuring-requests.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { InterestsComponent } from './pages/interests/interests.component';
import { LiveChatComponent } from './pages/live-chat/live-chat.component';
import { ManageYourProductComponent } from './pages/manage-your-product/manage-your-product.component';
import { MyBuyLeadsComponent } from './pages/my-buy-leads/my-buy-leads.component';
import { MyRFQSComponent } from './pages/my-rfqs/my-rfqs.component';
import { OrderInvoiceComponent } from './pages/orders-invoices/orders-invoices.component';
import { OrderStatusComponent } from './pages/orders-status/orders-status.component';
import { PERSONALPROFILEComponent } from './pages/personalprofile/personalprofile.component';
import { PrivacySettingsComponent } from './pages/privacy-settings/privacy-settings.component';
import { ProductsServiceComponent } from './pages/products-service/products-service.component';
import { ResponsesReceivedComponent } from './pages/responses-received/responses-received.component';
import { SalesActivityComponent } from './pages/sales-activity/sales-activity.component';
import { SentInquiryComponent } from './pages/sent-inquiry/sent-inquiry.component';
import { SentComponent } from './pages/sent/sent.component';
import { SpamComponent } from './pages/spam/spam.component';
import { StockStatusComponent } from './pages/stock-status/stock-status.component';
import { TrashInquiryComponent } from './pages/trash-inquiry/trash-inquiry.component';
import { TrashComponent } from './pages/trash/trash.component';
import { UpdateProductServiceComponent } from './pages/update-product-service/update-product-service.component';



const routes: Routes = [
  {
    path: "business",
    component: BUSINESSPROFILEComponent,
    pathMatch: "full"
  }
  ,
  {
    path: "personal",
    component: PERSONALPROFILEComponent,
    pathMatch: "full"
  } ,
  {
    path: "productServices",
    component: ProductsServiceComponent,
    pathMatch: "full"
  } ,
  {
    path: "Recieved",
    component: ResponsesReceivedComponent,
    pathMatch: "full"
  } ,
  {
    path: "sent",
    component: SentComponent,
    pathMatch: "full"
  } ,
  {
    path: "trash",
    component: TrashComponent,
    pathMatch: "full"
  } ,
  {
    path: "spam",
    component: SpamComponent,
    pathMatch: "full"
  } ,
  {
    path: "updateProduct/:id",
    component: UpdateProductServiceComponent,
    pathMatch: "full"
  } ,
  {
    path: "addNew",
    component: AddNewProductServiceComponent,
    pathMatch: "full"
  } ,
  {
    path: "addNewService",
    component: AddServiceComponent,
    pathMatch: "full"
  } ,

  {
    path: "Manage",
    component: ManageYourProductComponent,
    pathMatch: "full"
  } ,
  {
    path: "privacy",
    component: PrivacySettingsComponent,
    pathMatch: "full"
  } ,
  {
    path: "deactivate",
    component: DeactivateAccountComponent,
    pathMatch: "full"
  }
  ,
  {
    path: "ChangePassword",
    component: ChagePassComponent,
    pathMatch: "full"
  },
  {
    path: "SalesActivity",
    component: SalesActivityComponent,
    pathMatch: "full"
  },
  {
    path: "trash/inquiry/:id",
    component: TrashInquiryComponent,
    pathMatch: "full"
  },
  {
    path: "sent/inquiry/:id",
    component: SentInquiryComponent,
    pathMatch: "full"
  },
  {
    path: "Recieved/inquiry/:id",
    component: InquiryComponent,
    pathMatch: "full"
  },
  {
    path: "ordersinvoices",
    component: OrderInvoiceComponent,
    pathMatch: "full"
  } ,
  {
    path: "businessActivity",
    component: OrderStatusComponent,
    pathMatch: "full"
  } ,
  {
    path: "stockStatus",
    component: StockStatusComponent,
    pathMatch: "full"
  } ,
  {
    path: "liveChat",
    component: LiveChatComponent,
    pathMatch: "full"
  } ,
  {
    path: "interests",
    component: InterestsComponent,
    pathMatch: "full"
  },
  {
    path: "featuringRequests",
    component: FeaturingRequestsComponent,
    pathMatch: "full"
  },
  {
    path: "myRFQs",
    component: MyRFQSComponent,
    pathMatch: "full"
  },
  {
    path: "myBuyLeads",
    component: MyBuyLeadsComponent,
    pathMatch: "full"
  },
  // {
  //   path: "Dashboard",
  //   component: DashboardComponent,
  //   pathMatch: "full"
  // }
  ];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
   ],
  exports: [RouterModule],
})
export class ProfileRoutingModule { }
