import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PermissionService } from "../services/permission.service";
import { UserService } from "../../../commons/services/user.service";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const roles: string[] =
      this.userService.currentUser && this.userService.currentUser.role;
    console.log("roles :>> ", roles);
    if (roles && roles.includes("ROLE_BUYER" || "ROLE_SELLER" || "ROLE_BOTH")) {
      console.log(
        "perms",
        this.permissionService.checkPermission(next.data.permissions)
      );

      if (this.permissionService.checkPermission(next.data.permissions)) {
        return true;
      } else {
        return this.router.navigateByUrl("/");
      }
    }
    return true;
    // return roles.includes("ROLE_USER") ?

    //     ? true
    //     : this.router.navigateByUrl("/upgrade")
    //   : true;
  }
}
