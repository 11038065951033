import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-services',
  templateUrl: './trade-services.component.html',
  styleUrls: ['./trade-services.component.css']
})
export class TradeServicesComponent implements OnInit {
  hovered1 = false;
  hovered2 = false;
  hovered3 = false;
  constructor() { }

  ngOnInit() {
  }

}
