import { Component, OnInit } from '@angular/core'; 

@Component({
  selector: 'app-buy-request',
  templateUrl: './buy-request.component.html',
  styleUrls: ['./buy-request.component.css']
})
export class BuyRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  } 

}
