import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { ProfileService } from 'src/app/commons/services/profil service/profile.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';

@Component({
  selector: 'app-products-service',
  templateUrl: './products-service.component.html',
  styleUrls: ['./products-service.component.css']
})
export class ProductsServiceComponent implements OnInit {
  show1=false;
  show2=false;
  show3=false;
  show4=false;
  show5=false;
  show6=false;
  show7=false;
  show8=false;
  show9=false;
  show10=false;
  show11=false;
  hide1=false;
  hide2=false;
  hide3=false;
  hide4=false;
  hide5=false;
  hide6=false;
  hide7=false;
  hide8=false;
  hide9=false;
  hide10=false;
  hide11=false;
  public listCategories:any=[];
  public radioValueType="product";
  public selectedCategory= [];
  public selectedCategory1= [];
  public selectedCategory2= [];
  public selectedCategory3= [];
  public selectedCategory4= [];
  public selectedCategory5= [];
  public selectedCategory6= [];
  public selectedCategory7= [];
  public selectedCategory8= [];
  public selectedCategory9= [];
  public selectedCategory10= [];
  public selectedCategory11= [];
  public selectedType;
  public listOfProducts = ['coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk','coffe', 'milk'];
  public index = 0;
  public types =["Manufacturer","Importer","Exporter","Distributor","Trader","Wholesaler","Retailer","Dealer","Producer","Supplier","Service Provider"];
  public cat=false;
// salah
public id;
public selectedValues;
public selectedValuesManufacturer;
public selectedValuesImporter;
public selectedValuesExporter;
public selectedValuesDistributor;
public selectedValuesTrader;
public selectedValuesWholesaler;
public selectedValuesRetailer;
public selectedValuesDealer;
public selectedValuesProducer;
public selectedValuesFabricator;
public selectedValuesProvider;
public nodes=[];
public allProduct=[];
  constructor(private msg: NzMessageService,private profileService: ProfileService,private b2bserviceService: B2bProductService,private userService: UserService,
    private profileRouteService : ProfileRouteService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("productServices");
    this.getCategories(this.radioValueType);
    this.getAllProductCategory();
    this.getUser();
  }
  getCategories(radioValueType){
    this.b2bserviceService.getCategories(radioValueType).subscribe((resp: any) => {
      console.log(resp);
      this.listCategories=resp
    })
}
selected(){
  this.cat=true;
}
getUser() {

  let id = localStorage.getItem("USER_ID")
  if(id)
  {
  this.userService.getAuthUser(id).subscribe(
    (res: any) => {
      this.selectedType= res.subscription.businessType[0];
      this.selectedValues= res.subscription.interestedProducts;
      this.selectedCategory= res.subscription.interestedProducts;
      this.id= res.subscription.id;
      console.log("selectedValuesss",this.selectedValues);
    this.getAllProductService();
    //  this.provider(this.selectedType);
    })
}
}

provider(selected:any){
  console.log("======================>",selected);
  if(selected == "Manufacturer"){
  this.hide1=true;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesManufacturer
}else if(selected == "Importer") {
  this.hide1=false;
  this.hide2=true;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesImporter
}else if(selected == "Exporter") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=true;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesExporter
}else if(selected == "Distributor") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=true;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesDistributor
}else if(selected == "Trader") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=true;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesTrader
}else if(selected == "Wholesaler") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=true;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesWholesaler
}else if(selected == "Retailer") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=true;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesRetailer
}else if(selected == "Dealer") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=true;
  this.hide9=false;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesDealer
}else if(selected == "Producer") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=true;
  this.hide10=false;
  this.hide11=false;
  this.selectedValues = this.selectedValuesProducer
}else if(selected == "Supplier") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=true;
  this.hide11=false;
  this.selectedValues = this.selectedValuesFabricator
}else if(selected == "Service Provider") {
  this.hide1=false;
  this.hide2=false;
  this.hide3=false;
  this.hide4=false;
  this.hide5=false;
  this.hide6=false;
  this.hide7=false;
  this.hide8=false;
  this.hide9=false;
  this.hide10=false;
  this.hide11=true;
  this.selectedValues = this.selectedValuesProvider;
}
// let data={
//   businessType:this.selectedType,
//   mainProductCategories:this.selectedValuesManufacturer
// }
// this.addMainService(data);
// console.log(data);

}

addItem(input: HTMLInputElement): void {
  const value = input.value;
  if (this.listOfProducts.indexOf(value) === -1) {
    if(input.value != ""){
    this.listOfProducts = [...this.listOfProducts, input.value];
  }
}
}

// salah

showAll(){
  console.log("allProduct",this.allProduct)
  console.log("+++++++++++++++++++++++")
  console.log("selectedValuesManufacturer",this.selectedValuesManufacturer)
  console.log("selectedValuesImporter",this.selectedValuesImporter)
  console.log("selectedValuesExporter",this.selectedValuesExporter)
  console.log("selectedValuesDistributor",this.selectedValuesDistributor)
  console.log("selectedValuesTrader",this.selectedValuesTrader)
  console.log("selectedValuesWholesaler",this.selectedValuesWholesaler)
  console.log("selectedValuesRetailer",this.selectedValuesRetailer)
  console.log("selectedValuesDealer",this.selectedValuesDealer)
  console.log("selectedValuesProducer",this.selectedValuesProducer)
  console.log("selectedValuesProvider",this.selectedValuesProvider)
}

getAllProductCategory() {
  this.nodes = [];
  this.b2bserviceService.getAllProductCategory().subscribe((resp: any) => {
    resp.forEach(category => {
      let newArray = []
      category.subCategories.forEach(subCategory => {
        let oldArray = []
        subCategory.classification.forEach(calassification => {
          let classif
          classif = {
            key: calassification,
            title: calassification,
            isLeaf:true
          }
          oldArray.push(classif)
        });

        let subCat
        subCat = {
        key: subCategory.name,
        title: subCategory.name,
        children: oldArray
        // isLeaf:true
      }
      newArray.push(subCat);
      });

      let categories
      categories = {
        key: category.name,
        title: category.name,
        children: newArray
      }
      this.nodes.push(categories)
     });
  })
}

onChangeNodes(event ) {
  console.log(event );
  console.log("selectedValues",this.selectedValues);
  let data={
    businessType:this.selectedType,
    mainProductCategories:this.selectedValues
  }
  this.addMainService(data);
}
onChangeNodesImporter(event ) {
  let data={
    businessType:"Importer",
    mainProductCategories:this.selectedValuesImporter
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesManufacturer(event ) {
  let data={
    businessType:"Manufacturer",
    mainProductCategories:this.selectedValuesManufacturer
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesExporter(event ) {
  let data={
    businessType:"Exporter",
    mainProductCategories:this.selectedValuesExporter
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesDistributor(event ) {
  let data={
    businessType:"Distributor",
    mainProductCategories:this.selectedValuesDistributor
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesTrader(event ) {
  let data={
    businessType:"Trader",
    mainProductCategories:this.selectedValuesTrader
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesWholesaler(event ) {
  let data={
    businessType:"Wholesaler",
    mainProductCategories:this.selectedValuesWholesaler
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesRetailer(event ) {
  let data={
    businessType:"Retailer",
    mainProductCategories:this.selectedValuesRetailer
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesDealer(event ) {
  let data={
    businessType:"Dealer",
    mainProductCategories:this.selectedValuesDealer
  }
  this.addOtherService(data);
  console.log(data);
}

onChangeNodesProducer(event ) {
  let data={
    businessType:"Producer",
    mainProductCategories:this.selectedValuesProducer
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesFabricator(event ) {
  let data={
    businessType:"Supplier",
    mainProductCategories:this.selectedValuesFabricator
  }
  this.addOtherService(data);
  console.log(data);
}
onChangeNodesProvider(event ) {
  let data={
    businessType:"Provider",
    mainProductCategories:this.selectedValuesProvider
  }
  console.log(data);
  this.addOtherService(data);
}

addOtherService(data){
  this.profileService.addOtherProductService(this.id,data).subscribe((resp: any) => {
        this.msg.success('user updated successfully');
  })
}
addMainService(data){
  this.profileService.addMainProductService(this.id,data).subscribe((resp: any) => {
        this.msg.success('user updated successfully');
  })
}

getAllProductService(){
  this.profileService.getAllProduct(this.id).subscribe((resp: any) => {
        this.allProduct=resp;
        console.log("allProduct",this.allProduct);
        this.setData(this.allProduct);

  },(err)=>{
    console.error(err);

  })
}

setData(data){

  data.forEach(element => {
    if(element.businessType=="Manufacturer"){
      this.selectedValuesManufacturer=element.mainProductCategories
      if(this.selectedValuesManufacturer.length>0) this.show1=true;
    }
    else  if(element.businessType=="Importer"){
      this.selectedValuesImporter=element.mainProductCategories
      if(this.selectedValuesImporter.length>0) this.show2=true;

    }
    else  if(element.businessType=="Exporter"){
      this.selectedValuesExporter=element.mainProductCategories
      if(this.selectedValuesExporter.length>0) this.show3=true;

    }
    else  if(element.businessType=="Distributor"){
      this.selectedValuesDistributor=element.mainProductCategories
      if(this.selectedValuesDistributor.length>0) this.show4=true;

    }
    else  if(element.businessType=="Trader"){
      this.selectedValuesTrader=element.mainProductCategories
      if(this.selectedValuesTrader.length>0) this.show5=true;

    }
    else  if(element.businessType=="Wholesaler"){
      this.selectedValuesWholesaler=element.mainProductCategories
      if(this.selectedValuesWholesaler.length>0) this.show6=true;

    }
    else  if(element.businessType=="Retailer"){
      this.selectedValuesRetailer=element.mainProductCategories
      if(this.selectedValuesRetailer.length>0) this.show7=true;

    }
    else  if(element.businessType=="Dealer"){
      this.selectedValuesDealer=element.mainProductCategories
      if(this.selectedValuesDealer.length>0) this.show8=true;

    }
    else  if(element.businessType=="Producer"){
      this.selectedValuesProducer=element.mainProductCategories
      if(this.selectedValuesProducer.length>0) this.show9=true;

    }
    else  if(element.businessType=="Supplier"){
      this.selectedValuesFabricator=element.mainProductCategories
      if(this.selectedValuesFabricator.length>0) this.show10=true;

    }
    else  if(element.businessType=="Provider"){
      this.selectedValuesProvider=element.mainProductCategories
      if(this.selectedValuesProvider.length>0) this.show11=true;

    }
  });
  this.provider(this.selectedType)
}

deleteOtherProduct(name){
  let data={
    businessType:name
  }
  this.profileService.deleteOtherProductService(this.id,data).subscribe((resp: any) => {
    // this.getAllProductService();
    this.getUser();
    this.msg.success('user updated successfully');
},(err)=>{
console.error(err);

})
}

onChangecheckManufacturer(event){
  if(event==false){
    this.deleteOtherProduct("Manufacturer");
  }
}
 onChangecheckImporter(event){
  if(event==false){
    this.deleteOtherProduct("Importer");
  }
}

onChangecheckExporter(event){
  if(event==false){
    this.deleteOtherProduct("Exporter");
  }
}
onChangecheckDistributor(event){
  if(event==false){
    this.deleteOtherProduct("Distributor");
  }
}
onChangecheckTrader(event){
  if(event==false){
    this.deleteOtherProduct("Trader");
  }
}
onChangecheckWholesaler(event){
  if(event==false){
    this.deleteOtherProduct("Wholesaler");
  }
}
onChangecheckRetailer(event){
  if(event==false){
    this.deleteOtherProduct("Retailer");
  }
}
onChangecheckDealer(event){
  if(event==false){
    this.deleteOtherProduct("Dealer");
  }
}
onChangecheckProducer(event){
  if(event==false){
    this.deleteOtherProduct("Producer");
  }
}
onChangecheckFabricator(event){
  if(event==false){
    this.deleteOtherProduct("Supplier");
  }
}
onChangecheckProvider(event){
  if(event==false){
    this.deleteOtherProduct("Provider");
  }
}
}
