import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { OrderService } from 'src/app/commons/services/order-service/order.service';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';
import { BillingDetails, OrderRequest, ShippingDetails } from './models/OrderRequest';
import { OrderRequestService } from './services/order-service/order-request-service.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
	SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
	separateDialCode = true;
  current = 0;
  user;
  order;
  orderId =null;
  productId;
  product;
  relatedProds;
  public orderRequest : OrderRequest ;
  public savings = 0;
  public savedOrder ;
  public savingsPrice = 0;
  public Nprods = 0;
  baseUrl = environment.baseUrl + '/';
  userInfo;
  billingInfo;
  public formBilling!: FormGroup;
  public listafricanCountries = [];
  billingDetails :BillingDetails = new BillingDetails
  shippingDetails :ShippingDetails = new ShippingDetails
  email;
  Fname;
  Lname;
  company;
  public listEmplty = true;
  agree =false;
  country;
  adresse;
  city;
  state;
  zip;
  now:  Date;
  submit=false;
  valid = false;
  update = false;
  success='success'
  info='info'
  error='error'
  subtotal=0;
  public formShipping!: FormGroup;
  public payment;
  isVisible = false;
  public messageToSend = "";
  saving=false;
  public fileList: UploadFile[] = [];
  constructor(private fb: FormBuilder,private activatedRoute : ActivatedRoute,private b2bProductService : B2bProductService,
    private userService: UserService,private orderRequestService : OrderRequestService,private message: NzMessageService,
    private router: Router,private buyRequestService: BuyRequestService,
    private mailService: MailService,
    private toaster: ToastrService) { }

  ngOnInit() {
    console.log("heeeere : n");

    this.getParams();

    this.getOrderRequest();
    this.getUser();
    this.userInfo = this.orderRequest.user;
    this.billingInfo = this.orderRequest.billingDetails;
    this.createFormBilling();
    this.getAllafricanCountries();
     this.createformShipping();
     this.onChanges();
     this.onChanges1();
     this.now= new Date();
     console.log("formBill",this.formBilling.validator)
     console.log("formShip",this.formShipping.validator)

    // billing Details
  }
  showModal(): void {
    this.isVisible = true;

  }
  pre(): void {
    this.current -= 1;
  }

  next(): void {
    if(this.current==0){
      this.current = 1;
  }
    if(this.current==3){
      this.current = 4;
  }
    if(this.current==1){
      if(!this.formBilling.valid){
        this.message.create(this.error, `please implement all of your billing details `);
    }else{
      this.current = 2;
    }
    }
    if(this.current==2){
      if(!this.formShipping.valid){
        this.message.create(this.error, `please implement all of your shipping details `);
    }else{
      this.current = 3;
    }
    }

    if(this.current==3&&this.submit==false){
      this.saving=true
      if (this.update==false){
      this.orderRequest.billingDetails.phone=this.orderRequest.billingDetails.phone.e164Number
      this.orderRequest.shippingDetails.phone=this.orderRequest.shippingDetails.phone.e164Number
      let data ={
        productId:this.productId,
        cost:this.orderRequest.cost,
        quantity:this.orderRequest.quantity,
        shippingDetails:this.orderRequest.shippingDetails,
        billingDetails:this.orderRequest.billingDetails,
        paymentTerm:this.payment,
      }
      this.b2bProductService.postOrder(data).subscribe((resp: any) => {
        this.orderId=resp


        this.b2bProductService.getOrder(this.orderId).subscribe(res=>{
          this.savedOrder =res
          this.saving=false
        })
        console.log("order is successful",this.orderId);
          this.message.create(this.success, `you order has been registered successfully `);
        this.submit = true;
      })}
      else if(this.update==true){
        if(this.formBilling.dirty||this.formShipping.dirty){

      this.order.billingDetails.phone=this.order.billingDetails.phone.e164Number
      this.order.shippingDetails.phone=this.order.shippingDetails.phone.e164Number
          let data1 ={
            id:this.orderId,
            product:this.order.product ,
            amount:this.order.amount,
            quantity:this.order.quantity,
            shippingDetails:this.order.shippingDetails,
            billingDetails:this.order.billingDetails,
            buyer:this.user
          }
          this.b2bProductService.updateOrder(data1).subscribe((resp: any) => {


            // this.b2bProductService.getOrder(this.orderId).subscribe(res=>{
            //   this.savedOrder =res
            // })
            console.log("order is successful",this.orderId);
              this.message.create(this.success, `you order has been updated successfully `);
            this.submit = true;

      this.saving=false
          })

        }else{

          this.message.info(`you can now proceed to payment`);
        }

      }
    }
  }
  downloadInvoice(){
    this.b2bProductService.getInvoice(this.orderId).subscribe(res=>{
      let dataType = res.type;
      let binaryData = [];
      binaryData.push(res);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));

      downloadLink.setAttribute('download', "Invoice.pdf");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });

  }

  createformShipping() {
    this.formShipping = this.fb.group({
      id: [],
      email: [null, [Validators.required, Validators.email]],
      Fname: [null, [Validators.required]],
      Lname: [null, [Validators.required]],
      company: [null, [Validators.required]],
      country: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      // secondAdress: [null, [Validators.required]],
      State: [null, [Validators.required]],
      City: [null, [Validators.required]],
      ZIP: [null, [Validators.required]],

    });
  }

  getParams() {
    this.activatedRoute.queryParams.subscribe(res => {
     this.productId=res.product;
     this.orderId= res.orderId;
     console.log("orderIDDDZZZ",this.orderId)
      if(this.orderId!=null || this.orderId !=undefined)
      {
        this.update=true
        this.current=4;
        console.log("orderIDDD",this.orderId)
        this.b2bProductService.getOrder(this.orderId).subscribe(res=>{
          this.order =res
          this.subtotal =this.order.amount -this.order.shippingPrice
        })

      }
    })


  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.user=res;
        this.orderRequest.user = res;
        this.setBilling();
        this.setShipping();
        this.getProduct();
      })
  }
  }
  setBilling(){
     this.billingDetails.adress = this.user.subscription.companyAddress
     this.billingDetails.city = this.user.subscription.companyCity
     this.billingDetails.companyName = this.user.subscription.companyName
     this.billingDetails.firstName = this.user.subscription.firstName
     this.billingDetails.lastName = this.user.subscription.lastName
     this.billingDetails.country = this.user.subscription.country
     this.billingDetails.email = this.user.subscription.email
     this.billingDetails.phone = this.user.subscription.phone
     this.billingDetails.state = this.user.subscription.state
     this.billingDetails.phone = this.user.subscription.companyPhone
     this.billingDetails.zip = this.user.subscription.zip
     this.orderRequest.billingDetails = this.billingDetails;
  }
  setShipping(){
    this.shippingDetails.adress = this.user.subscription.companyAddress
    this.shippingDetails.city = this.user.subscription.companyCity
    this.shippingDetails.companyName = this.user.subscription.companyName
    this.shippingDetails.firstName = this.user.subscription.firstName
    this.shippingDetails.lastName = this.user.subscription.lastName
    this.shippingDetails.country = this.user.subscription.country
    this.shippingDetails.email = this.user.subscription.email
    this.shippingDetails.phone = this.billingDetails.phone
    this.shippingDetails.state = this.user.subscription.state
    this.shippingDetails.zip = this.user.subscription.zip
    this.orderRequest.shippingDetails = this.shippingDetails;
  }
  setValue() {
    console.log("formBilling",this.orderRequest)
   this.formBilling.get('email').setValue(this.billingInfo.email);
   this.formBilling.get('Fname').setValue(this.billingInfo.firstName);
   this.formBilling.get('Lname').setValue(this.billingInfo.lastName);
   this.formBilling.get('company').setValue(this.billingInfo.companyName);
   this.formBilling.get('country').setValue(this.billingInfo.country);
   this.formBilling.get('adress').setValue(this.billingInfo.adress);
   this.formBilling.get('City').setValue(this.billingInfo.city);
 }
 setValue1() {
   this.formShipping.get('email').setValue(this.userInfo.subscription.email);
   this.formShipping.get('Fname').setValue(this.userInfo.subscription.firstName);
   this.formShipping.get('Lname').setValue(this.userInfo.subscription.lastName);
   this.formShipping.get('company').setValue(this.userInfo.subscription.companyName);
   this.formShipping.get('country').setValue(this.userInfo.subscription.country);
   this.formShipping.get('adress').setValue(this.userInfo.subscription.companyAddress);
   this.formShipping.get('City').setValue(this.userInfo.subscription.companyCity);
 }
  getProduct() {
    this.b2bProductService.getProductInfobyId(this.productId).subscribe(
      (res: any) => {
        this.product = res.productDTO;
        this.orderRequest.product = res.productDTO;
        this.payment =  res.productDTO.paymentTerms[0]
        console.log("resss :", res);
        this.orderRequest.quantity = this.orderRequest.product.minimumOrderQuantity;
        console.log("order Request : !! " , this.orderRequest);
        this.countTotal()
        this.b2bProductService.sellerProduct(res.productDTO.user.id).subscribe(
          (res: any) => {
            this.relatedProds=res
            this.Nprods=this.relatedProds.length
          })
      })
  }

  getOrderRequest() {
    this.orderRequestService.currentOrderRequest.subscribe(orderRequest => {
      this.orderRequest = orderRequest
    })
  }

  recievedRequest(orderRequest) {
    this.orderRequest = orderRequest;
  }
   openProdInNewWindow(prod) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/Product/${prod.id}`])
    );

    window.open(url, '_blank');
  }
  getAllafricanCountries(){
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }
  createFormBilling() {
    this.formBilling = this.fb.group({
      id: [],
      email: [null, [Validators.required, Validators.email]],
      Fname: [null, [Validators.required]],
      Lname: [null, [Validators.required]],
      company: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      country: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      State: [null, [Validators.required]],
      City: [null, [Validators.required]],
      ZIP: [null, [Validators.required]],

    });
  }
  onChanges(): void {
    this.formBilling.valueChanges.subscribe(val => {
      console.log("phone",this.orderRequest)
    this.orderRequestService.changeOrderRequest(this.orderRequest);
    });
  }
  onChanges1(): void {
    this.formShipping.valueChanges.subscribe(val => {

    this.orderRequestService.changeOrderRequest(this.orderRequest);
    });
  }
  isValidated() {
    if(this.formBilling.valid) {
      return true
    }
    return false
  }
  countTotal(){
    if(this.orderRequest.product.discount==0)
    {
    this.orderRequest.cost =this.orderRequest.quantity*this.orderRequest.product.priceDollars
  }
    else if(this.orderRequest.product.discount>0)
    {
    this.orderRequest.cost =this.orderRequest.quantity*this.orderRequest.product.discountedPrice
  }
    this.savingsPrice= this.orderRequest.product.priceDollars-this.orderRequest.product.discountedPrice
    this.savings=this.orderRequest.quantity*this.savingsPrice

    // this.emitOrderRequest();

    // this.orderRequestService.changeOrderRequest(this.orderRequest);

  //   if(this.demoValue<this.selectedProduct.minimumOrderQuantity){
  //     this.passed=true;
  //   }else{
  //     this.passed=false;
  //   }
  //   if(this.demoValue>this.selectedProduct.quantity){
  //     this.passed1=true;
  //   }else{
  //     this.passed1=false;
  //   }
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
   return true;
 }
 sendMail() {

 let data = {
  receiver:this.product.user.email,
  message: this.messageToSend,
  subject: this.product.productName,
  productId:this.product.id,
  isBuyLeadInquiry: false,
  isWebInquiry: true ,
  isAllCountry: false ,
  isAllCompany: false ,
  location: this.product.user.country,

 //  subject: this.subject,
 }
 console.log();

 this.mailService.sendMessage(data).subscribe(resp => {
   console.log("resap : " , resp);
   this.toaster.success(
     "The inquiry has been sent succefully",
     "Success",
     {
       closeButton: true,
       timeOut: 5000
     })
     this.isVisible = false;
 },(err) => {
     // console.log("erorrrrrrrr !" , err);

   // this.toaster.error(
   //   err.error.meesage,
   //   "Error",
   //   {
   //     closeButton: true,
   //     timeOut: 5000
   //   })
 })

}


}
