import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/commons/services/contacts/contact.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';

@Component({
  selector: 'app-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.css']
})
export class DeactivateAccountComponent implements OnInit {

  reason;
  listReason = [
  { label: 'Company closing', value: 'Company closing', checked: false },
  { label: 'Trademark Infringement', value: 'Trademark Infringement', checked: false },
  { label: 'Getting regular calls from Afreximbank', value: 'Getting regular calls from Afreximbank', checked: false },
  { label: 'Service is not satisfactory', value: 'Service is not satisfactory', checked: false },
  { label: 'Others', value: 'Others', checked: false }
]

  listToSend=[];


  constructor(private contactService : ContactService,private profileRouteService:ProfileRouteService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("deactivate");
  }

  desactivateAccount() {
  
    this.contactService.dectivationReason(1,this.listToSend[0]).subscribe(resp=> {
      console.log(resp);
    })

  }

  addReason() {
   this.listToSend = []
   this.listReason.forEach(x => {
      if(x.checked) { this.listToSend.push(x.value)}
   })
    
  }
  
}
