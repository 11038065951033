import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ProfileService } from 'src/app/commons/services/profil service/profile.service';
import { PostRequestService } from 'src/app/commons/services/service post request/post-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SourceService } from 'src/app/commons/services/source-service/source.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-company',
  templateUrl: './search-company.component.html',
  styleUrls: ['./search-company.component.css']
})
export class SearchCompanyComponent implements OnInit {
  public listCategories=[];
  public listSellers=[];
  public listBuyers=[];
  public categoriesList = [];
  public nbrSlice = 0;
  public listfilterCategorie = [];
  public listCountriesSell = [];
  public listCountriesBuy = [];
  public listCountries = [];
  public selectedCategory=null ;
  buy = true;
  sell = false;
  product="";
  company="";
  sort ="";
  public categories = [];
  public countries = [];
  public listShowcountry = [];
  public selectedSubCategory = null;
  public reciver   = null;
  public isVisible   = false
  public messageToSend = "";
  public companyName;
  public UsercompanyName = null;
  agree = false;
  public listfilterSubCategorie = [];
  public loading;
  public listSubCategories = [];
  public motCle:string=null;
  public selectedCategory2 = [];
  public listCompany = [];
  public listCompany1 = [];
  public baseUrl =environment.baseUrl+'/';
  constructor(private sourceService: SourceService,private profileService: ProfileService,private postService: PostRequestService,private sharedService: SharedService
    ,private mailService: MailService,private toaster: ToastrService, private router:Router,private userService: UserService,private b2bserviceService: B2bProductService) { }

  ngOnInit() {
    this.getUser();
    this.getCategories();
    this.sharedService.sharedMessage.subscribe((resp: any) => {
      this.company = resp;
      this.search();
    });
    this.search();
  }
  goToLiveChat(item) {
    console.log("---------- item  --------- : " , item);
    this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.UsercompanyName=res.subscription.companyName;

    console.log("---------- item  --------- : " , this.UsercompanyName);
      })
  }
  }
  log(item){
    this.messageToSend=item;
  }
  showModal(item): void {
    this.reciver= item
    console.log("reciveeer",item)
    this.isVisible = true;
  }
  sendMail(item) {

    let data = {
     receiver:item.email,
     message: this.messageToSend,
     subject: item.companyName,
     isBuyLeadInquiry: false,
     isWebInquiry: true ,
     isAllCountry: false ,
     isAllCompany: false ,
     location: item.country,
    }

    console.log("datatat",item);
    console.log("datatat",data);

    this.mailService.sendMessage(data).subscribe(resp => {
      console.log("resap : " , resp);
      this.toaster.success(
        "The inquiry has been sent succefully",
        "Success",
        {
          closeButton: true,
          timeOut: 5000
        })
        this.isVisible = false;
    },(err) => {
    })

   }

   handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  buyers(){
    this.sell=false;
    this.buy=true;
  }
  sellers(){
    this.sell=true;
    this.buy=false;
  }
  getCategories() {
    this.postService.getCategories().subscribe((resp: any) => {
      this.categoriesList = resp
      console.log("categoriesList");
      console.log("categoriesList",this.categoriesList);

       this.sliceCategorie();
    })

  }
  checkExist(id) {
    if (this.listfilterCategorie.includes(id)) {
      return true
    } else {
      return false
    }
  }
  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
  }

  addCategorie(idCatg) {
    if (!this.listfilterCategorie.includes(idCatg)) {
      this.listfilterCategorie.push(idCatg);
      console.log(this.listfilterCategorie);
    }
    else {
      let index: number = this.listfilterCategorie.indexOf(idCatg);
      if (index !== -1) {
        this.listfilterCategorie.splice(index, 1);
      }
    }
    //  this.search();
    console.log(this.listfilterCategorie);
  }
  getSubCategories(event) {
    console.log("=============>",this.selectedCategory);
    console.log(event);
    if(event!=null){
      if(event=="All"){
        this.loading=true;
          this.b2bserviceService.AllSubs().subscribe((resp: any) => {
            this.listSubCategories = resp
        this.loading=false;
          })

      }
      else{
    this.selectedCategory2 = event.id
    this.listSubCategories = event.subCategories
    this.addCategorie(event.name);
  }
}
  else{
    this.listSubCategories=[];
    this.listfilterCategorie=[];
    this.search();
  }
  }
  addCountry(idCountry,item) {
    if (!this.countries.includes(idCountry)) {
      this.countries.push(idCountry);
      this.listShowcountry.push(item)
      console.log(this.countries);
    }
    this.search();
    console.log(this.countries);
  }
  checkExistCountry(id) {
    if (this.countries.includes(id)) {
      return true
    } else {
      return false
    }
  }

  addSubCategorie(idCatg) {
    this.listfilterSubCategorie=[];
    this.listfilterSubCategorie.push(idCatg.name);
     this.search();
    console.log(this.listfilterSubCategorie);
  }
  deleteCountry(idCountry,item){
    let index: number = this.countries.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.countries.splice(index, 1);
  }
  if (index2 !== -1) {
    this.listShowcountry.splice(index2, 1);
}
  this.search();
  console.log(this.countries);
}
clear(){
  this.motCle ="";
  this.listfilterCategorie =[];
  this.selectedCategory =null;
  this.selectedSubCategory =null;
  this.listfilterSubCategorie =[];
  this.countries =[];
  this.listSubCategories =[];
  this.search();
}
search(){
  this.loading=true;
  let data ={
    product:this.motCle,
    categories:this.listfilterCategorie,
    subCategories:this.listfilterSubCategorie,
    countries:this.countries,
    company:this.company
  }
  console.log(data);

  this.sourceService.searchCompany1(data).subscribe((resp: any) => {

  this.loading=false;
    console.log("categoriesList",resp);
    this.listCountriesBuy=resp.numberByCountries;
    this.listCompany=resp.productCompany;

  this.listCountries=this.listCountriesBuy.concat(this.listCountriesSell);
  })
  this.sourceService.searchCompany(data).subscribe((resp: any) => {
  this.loading=false;
    this.listCountriesSell=resp.numberByCountries;
    this.listCompany1=resp.subscriptions;
    this.listCountries=this.listCountriesBuy.concat(this.listCountriesSell);
    // this.listCountries = this.listCountries.filter((element, i) =>  this.listCountries.indexOf(element)===i )
    this.listCountries = this.listCountries.filter((test, index, array) =>
     index === array.findIndex((findTest) =>
        findTest.country === test.country
     )
  );
    console.log('listCountries',this.listCountries)
  })

}
getText(data){
  let txt="";
 data.forEach(element => {
   txt=txt+","+element
 });
 return txt
}
//   search(){
//     let data = {
//       product: this.product,
//       company: this.company,
//       categories: this.categories,
//       countries: this.countries,
//       sort: ""
//     }
//     this.profileService.getByCompanyName(data).subscribe((resp: any) => {
//       this.listBuyers=resp.subscriptions;
//       this.listCountriesBuy=resp.numberByCountries;

//   },(err)=>{
//     console.log(err);
//   })
//   this.profileService.findSellerCompanyByName(data).subscribe((resp: any) => {
//     this.listSellers=resp.subscriptions;
//     this.listCountriesSell=resp.numberByCountries;

// },(err)=>{
//   console.log(err);
// })

// }
}
