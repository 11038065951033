import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
interface ItemData {
  sender: string; Requirement: string; Location: string; date: Date;
}
@Component({
  selector: 'app-manage-your-product',
  templateUrl: './manage-your-product.component.html',
  styleUrls: ['./manage-your-product.component.css']
})
export class ManageYourProductComponent implements OnInit {
  demoValue = 100;
  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');
  isCollapsed = false;
  public listCategories:any=[];
  public radioValueType="product";
  buisness=true;
  personal=false;
  my=false;
  recieve=false;
  trash=false;
  sent=false;
  add=false;
  manage=false;
  privacy=false;
  account=false;
  change=false;
  checked = false;
  show1=false;
  show2=false;
  show3=false;
  show4=false;
  show5=false;
  show6=false;
  show7=false;
  show8=false;
  show9=false;
  show10=false;
  show11=false;
  show12=false;
  isVisible = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfAllData: ItemData[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  listOfName = [{ text: 'Web Inquiry', value: '' }, { text: 'Buy Lead Inquiry', value: '' }, { text: 'Chat', value: '' }];
  listOfData  = [];
  listOfDisplayData: Array<{ sender: string; Requirement: string; Location: string; date: Date; [key: string]: string | number | Date }> =
  [
  ];

  public selectedValues;
  stockValue = 0;



  nodes = [
    {
      title: '0-0',
      key: '0-0',
      expanded: true,
      children: [
        {
          title: '0-0-0',
          key: '0-0-0',
          children: [
            { title: '0-0-0-0', key: '0-0-0-0', isLeaf: true },
            { title: '0-0-0-1', key: '0-0-0-1', isLeaf: true },
            { title: '0-0-0-2', key: '0-0-0-2', isLeaf: true }
          ]
        },
        {
          title: '0-0-1',
          key: '0-0-1',
          children: [
            { title: '0-0-1-0', key: '0-0-1-0', isLeaf: true },
            { title: '0-0-1-1', key: '0-0-1-1', isLeaf: true },
            { title: '0-0-1-2', key: '0-0-1-2', isLeaf: true }
          ]
        },
        {
          title: '0-0-2',
          key: '0-0-2',
          isLeaf: true
        }
      ]
    },
    {
      title: '0-1',
      key: '0-1',
      children: [
        { title: '0-1-0-0', key: '0-1-0-0', isLeaf: true },
        { title: '0-1-0-1', key: '0-1-0-1', isLeaf: true },
        { title: '0-1-0-2', key: '0-1-0-2', isLeaf: true }
      ]
    },
    {
      title: '0-2',
      key: '0-2',
      isLeaf: true
    }
  ];

  public page = 1;
  public pageSize = 5;
  public total = 0 ;

  selectedSortType;
  activeProduct: Boolean = false;
  pendingProduct: Boolean = false;
  deletedProduct: Boolean = false;
  search = '';
  currentProduct: any;

  constructor(private b2bserviceService: B2bProductService, private profileRouteService : ProfileRouteService,private router: Router) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("Manage");
    this.getCategories(this.radioValueType);
    this.getAllProductCategory();
    this.myProductSearch();
  }

  getAllProductCategory() {
    this.nodes = [];
    this.b2bserviceService.getAllProductCategory().subscribe((resp: any) => {
      console.log("getteing all : " , resp);
      // this.nodes = resp

       resp.forEach(category => {
        let newArray = []
        category.subCategories.forEach(subCategory => {
          let oldArray = []
          subCategory.classification.forEach(calassification => {
            let classif
            classif = {
              key: calassification,
              title: calassification,
              isLeaf:true
            }
            oldArray.push(classif)
          });

          let subCat
          subCat = {
          key: subCategory.name,
          title: subCategory.name,
          children: oldArray
          // isLeaf:true
        }
        newArray.push(subCat);
        });

        let categories
        categories = {
          key: category.name,
          title: category.name,
          children: newArray
        }
        this.nodes.push(categories)
       });


      console.log("nodes all : " , this.nodes);

    })
  }

  myProductSearch() {
    let  data = {
    page : this.page-1,
    pageSize:this.pageSize,
    categoriesId : [],
    search : this.search,
    minOrder : null,
    fromPrice : null,
    toPrice : null,
    countries : [],
    certifications : [],
    classification : this.selectedValues,
    subCategories : [],
    sortBy : "id",
    sort : "asc",
    activeProduct : this.activeProduct,
    pendingProduct : this.pendingProduct,
    deletedProduct : this.deletedProduct
    }
    this.b2bserviceService.myProductSearch(data).subscribe((resp: any) => {
      console.log("getteing all : " , resp);
      this.listOfData = resp.content
      this.total = resp.totalElements

      console.log(this.listOfData);
      this.handleDiscount();
    })
  }

  handleDiscount() {
    this.listOfData.forEach(product => {
      if(product.discount == null){ product.discount=0 }
      if(product.availability == null){ product.availability="out of stock" }

      let numVal1 = Number(product.price);
      let numVal2 = Number(product.discount) / 100;
      let totalValue = numVal1 - (numVal1 * numVal2)
      product.price = totalValue.toFixed(2);

    })
  }

  changeDiscount(id) {
    this.listOfData.forEach(product => {
      if(product.id == id){
        console.log("here" , id);
        let numVal1 = Number(product.price);
        let numVal2 = Number(product.discount) / 100;
        let totalValue = numVal1 - (numVal1 * numVal2)
        // product.price = totalValue.toFixed(2);
        this.b2bserviceService.updateDiscount(id, product.discount).subscribe((prod:any)=> {
          console.log("updated " , product);
          product.discountedPrice=prod.discountedPrice;
        })
      }
    })
  }

  changeStockValue(product) {
    this.listOfData.forEach(prd => {
      if(prd.id == product.id){
        prd.quantity = product.quantity
        prd.availability = product.availability
      }
    })
  }

  changeStockQuantity(quantity) {
    this.stockValue = quantity
  }

  changeAvl(id, value) {
    console.log("value : " , value);

        this.b2bserviceService.updateFeatured(id, value).subscribe(product=> {
          console.log("updated " , product);
        })

  }

  changeFeatured(id, value) {
    this.b2bserviceService.updateFeatured(id, value).subscribe(product=> {
      console.log("updated " , product);
    })
  }

  pageIndexChanged(page) {
    console.log("page" , page);
    this.myProductSearch()

  }

  currentPageDataChange($event: ItemData[]): void {
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.sender]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.sender]) && !this.isAllDisplayDataChecked;
  }

  goToRequests(){
    this.router.navigateByUrl('/profil/featuringRequests');
  }
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.sender] = value));
    this.refreshStatus();
  }

  show(){
    this.show1=true;
  }
  showModal(item): void {
    this.isVisible = true;
    this.currentProduct = item;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.b2bserviceService.updateStock(this.currentProduct.id,this.stockValue).subscribe(res => {
      console.log("res : " , res);
      this.changeStockValue(res);
    this.isVisible = false;
    })
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  getTypeService(event){
    console.log("event",event);
    console.log("type",this.radioValueType);
    this.getCategories(event);
  }
  getCategories(radioValueType){
    this.b2bserviceService.getCategories(radioValueType).subscribe((resp: any) => {
      console.log(resp);
      this.listCategories=resp
    })
  }

// Recursivity


  recursive(children) {
    if(children.sublevels){
      let sublvs = []
      children.sublevels.forEach(element => {
        let childs= []
        if (element.sublevels) {
        childs=  this.recursive(element)
        }
        let elm
        elm = {
          key: element.code,
          title: element.product_name,
          children: childs
        }
        if(childs.length == 0){
          elm = {
            key: element.code,
            title: element.product_name,
            isLeaf: true
          }
        }

        sublvs.push(elm)
      });
      return sublvs
    }
    else{
      return []
    }
  }

  sortChange(event) {
    console.log("event : " , event);
      if (event == 1) {
        this.activeProduct=false;
        this.pendingProduct=false;
        this.deletedProduct=false;
      } else if (event == 2) {
        console.log("here 2  activeProduct :");
        this.activeProduct=true;
        this.pendingProduct=false;
        this.deletedProduct=false;
      } else if (event == 3)  {
        console.log("here 3  pendignProduct :");
        this.activeProduct=false;
        this.pendingProduct=true;
        this.deletedProduct=false;
     } else if (event == 4) {
        this.activeProduct=false;
        this.pendingProduct=false;
        this.deletedProduct=true;
     }
   this.myProductSearch()
   }

  onChangeNodes(event ) {
    console.log(this.selectedValues);
    this.myProductSearch();
  }

  deleteProduct(id) {
    this.b2bserviceService.deleteProduct(id).subscribe(res=> {
      console.log("product deleted ");
      this.page = 1;
      this.myProductSearch();
    })
  }
}
