import { Component, OnInit } from '@angular/core';
import {TradeshowsService} from '../../commons/services/tradeshows/tradeshows.service';
import {SharedService} from '../../commons/shared service/shared-service';

@Component({
  selector: 'app-header-trade',
  templateUrl: './header-trade.component.html',
  styleUrls: ['./header-trade.component.css']
})
export class HeaderTradeComponent implements OnInit {
  public selectedSearch='name';
  inputValue: string | null = null;
  constructor(private tradeshowsService:TradeshowsService,private sharedService: SharedService) { }

  ngOnInit() {
  }

  getEventByName(name:string){
    this.tradeshowsService.getByName(name).subscribe((resp: any) => {
      console.log(resp);
      this.sharedService.nextEvents(resp);
    })
  }
  getEventByCityorCountry(cityorcountry:string){
    this.tradeshowsService.getByCityorCountry(cityorcountry).subscribe((resp: any) => {
      console.log(resp);
      this.sharedService.nextEvents(resp);
    })
  }

  search(){
    if(this.selectedSearch=='name')
      this.getEventByName(this.inputValue);
    else if (this.selectedSearch=='City or Country')
      this.getEventByCityorCountry(this.inputValue);
  }
}
