import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-rfqs',
  templateUrl: './my-rfqs.component.html',
  styleUrls: ['./my-rfqs.component.css']
})
export class MyRFQSComponent implements OnInit {

  constructor(private route: Router,private profileRouteService : ProfileRouteService,private buyRequestService: BuyRequestService, private router:Router,private sharedService: SharedService,) { }
  selectedRFQ;
  effect = 'scrollx';
  status = null;
  public loading = false;
  showDet = false;
  listData=[];
  baseUrl = environment.baseUrl+'/';
  ngOnInit() {
    this.profileRouteService.changeRoute("myRFQs");
    this.searchPostRequest();
  }
  toQuote(id){
    let data ={
      postId :id,
    }
    this.sharedService.nextPostBuy(data);
    this.route.navigateByUrl('/PostRequest');

}
  getByID(id){
    this.sharedService.nextSearchProductByID(id);
    this.router.navigate(['/buy-request/product']);

   }
   searchPostRequest(){
    this.loading =true;
     let data={
      status :this.status
     }
    this.buyRequestService.findMyPostBuyRequest(data).subscribe((resp: any) => {
      this.listData=resp
      this.loading =false;
    });

}
getDetails(rfq){
  this.selectedRFQ=rfq;
  this.showDet = true;
}
unsetDetails(){
  this.selectedRFQ = null;
  this.showDet = false;
}
}
