import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable({providedIn: 'root'})
export class MailService {
    constructor(private http: HttpClient) {
    }

    baseUrl = environment.baseUrl;

    senMail(body: any) {
        return this.http.post(this.baseUrl + '/access_finance/send-mail', body);
    }

    senMail2(body: any,files:any[]) {
        const formData = new FormData();
        files.forEach((file: any) => {
            formData.append('files[]', file);
        });
        return this.http.post(this.baseUrl + '/access_finance/send-mail2?body=' + encodeURIComponent(JSON.stringify(body)), formData);
    }

}
