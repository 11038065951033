import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostRequestService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }
  uploadFilesPostByRequest(id: any, body: any) {
    return this.http.post(this.baseUrl + '/postBuyRequests/' + id + "/uploadImages", body, { responseType: 'text' });
  }
  uploadVideoPostByRequest(id: any, body: any) {
    return this.http.post(this.baseUrl + '/postBuyRequests/' + id + "/uploadVideo", body, { responseType: 'text' });
  }
  getCategories() {
    return this.http.get(this.baseUrl + '/postBuyRequests/getCategories');
  }

  addRequest(data) {
    return this.http.post(this.baseUrl + '/postBuyRequests', data);
  }
  updateRequest(data) {
    return this.http.put(this.baseUrl + '/postBuyRequests', data);
  }
  getById(id) {
    return this.http.get(this.baseUrl + '/postBuyRequests/'+id);
  }
}
