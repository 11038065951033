import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WebSocketService {
  baseUrl = environment.baseUrl + "/chat";
  SockUrl = environment.baseUrl ;

  private messageSource = new BehaviorSubject<any>({});
  currentMessage = this.messageSource.asObservable();
  webSocketEndPoint: string =  this.SockUrl+'/stomp';
    topic: string = "/user/50/queue/message";
    stompClient: any;
    email;
    constructor(private http: HttpClient) {
    }
    _connect(email) {
        this.email = email;
        console.log("Initialize WebSocket Connection");
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        let that = this;
        this.stompClient.connect({}, function (frame) {
        that.connectViaAPI(email).subscribe(res => {
        console.log("connect to api");
          console.log("connected to websocket : ");
            //_this.stompClient.reconnect_delay = 2000;
        })
    }, this.errorCallBack);

    };

    _disconnect(email) {
      this.disconnectViaAPI(email).subscribe(res => {
        console.log("disconnecting : res");
        if (this.stompClient !== null) {
          this.stompClient.disconnect();
      }
      console.log("Disconnected");
      })
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error) {
        console.log("errorCallBack -> " + error)
        setTimeout(() => {
            this._connect(this.email);
        }, 5000);
    }

 /**
  * Send message to sever via web socket
  * @param {*} message
  */
    _send(message) {
        console.log("calling logout api via web socket");
        this.stompClient.send("/app/hello", {}, JSON.stringify(message));

    }

    onMessageReceived(roomId,oldRoomId) {
      if(this.stompClient) {
        let that = this;
        if(oldRoomId) this.stompClient.unsubscribe("/user/"+oldRoomId+"/queue/message");

        this.stompClient.subscribe("/user/"+roomId+"/queue/message", function (message) {
           that.messageSource.next(message);
        });
      }
    }

    onNotificationReceived(receiverMail) {
      if(this.stompClient) {
        let that = this;
        console.log("receiverMail ", receiverMail);
        if(receiverMail) this.stompClient.unsubscribe("/user/"+receiverMail+"/queue/notification");

        this.stompClient.subscribe("/user/"+receiverMail+"/queue/notification", function (message) {
           that.messageSource.next(message);
        });
      }
    }



    onMessage(roomId: string): Observable<any> {
        return new Observable<any>(observer => {
          const subscription = this.stompClient.subscribe("/user/"+roomId+"/queue/message", message => {
            observer.next(JSON.parse(message.body));
          });
          return () => this.stompClient.unsubscribe(subscription.id);
        });
    }

    onNotification(receiverMail: string): Observable<any> {
      return new Observable<any>(observer => {
        const subscription = this.stompClient.subscribe("/user/"+receiverMail+"/queue/notification", message => {
          observer.next(JSON.parse(message.body));
        });
        return () => this.stompClient.unsubscribe(subscription.id);
      });
  }

  onCountReceived(receiverMail) {
    if(this.stompClient) {
      let that = this;
      console.log("receiverMail ", receiverMail);
      if(receiverMail) this.stompClient.unsubscribe("/user/"+receiverMail+"/queue/count");

      this.stompClient.subscribe("/user/"+receiverMail+"/queue/count", function (message) {
         that.messageSource.next(message);
      });
    }
  }

  onCount(receiverMail: string): Observable<any> {
    if(this.stompClient) {
    return new Observable<any>(observer => {
      const subscription = this.stompClient.subscribe("/user/"+receiverMail+"/queue/count", message => {
        observer.next(JSON.parse(message.body));
      });
      return () => this.stompClient.unsubscribe(subscription.id);
    });
  }
  }

  onRecieveConnectedUsers(): Observable<any> {
    // alert("subscribe to athentificated users ");
    if(this.stompClient) {
    return new Observable<any>(observer => {
      const subscription = this.stompClient.subscribe("/user/allusers"+"/queue/connection", message => {
        observer.next(JSON.parse(message.body));
      });
      return () => this.stompClient.unsubscribe(subscription.id);
    });
    }
  }

  // Connecting apis
  connectViaAPI(email) {
    return this.http.get(this.baseUrl + '/connect/' +email);
  }

  // Connecting apis
  disconnectViaAPI(email) {
    return this.http.get(this.baseUrl + '/disconnect/' +email);
  }

  // get connected users
  getConnectedUsers() {
    return this.http.get(this.baseUrl + '/all-connected');
  }

 }
