import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MailService } from "src/app/commons/services/mail service/mail.service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { SourceService } from "src/app/commons/services/source-service/source.service";
import { UserService } from "src/app/commons/services/user.service";
import { WebSocketService } from "src/app/commons/services/websocket-service/web-socket.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-protection",
  templateUrl: "./search-importers.component.html",
  styleUrls: ["./search-importers.component.css"],
})
export class ProtectionComponent implements OnInit {
  public selectedCategory = null;
  public importerId = 1;
  public importer;
  public loading;
  public countryList = [];
  public slicedMainProds = [];
  public listCategories = [];
  public categoriesList = [];
  public nbrSlice = 0;
  public companyCount = 0;
  public listfilterCategorie = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public listCompany = [];
  public selectedSearch = "products";
  public motCle: string = null;
  public listSubCategories = [];
  public selectedCategory2 = [];
  public selectedSubCategory = null;
  public listfilterSubCategorie = [];
  public formSearch = new FormGroup({});
  public reciver = null;
  public isVisible = false;
  public messageToSend = "";
  public email;
  agree = false;
  loadin = false;
  public connectedUsers;
  public baseUrl = environment.baseUrl + "/";
  imgUrl = environment.baseUrl + "/file/aws/download/";
  constructor(
    private mailService: MailService,
    private sourceService: SourceService,
    private b2bserviceService: B2bProductService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private userService: UserService,
    private router: Router,
    private webSocketService: WebSocketService
  ) {}

  ngOnInit() {
    // this.getAllCountries();
    this.getCategories();
    this.search();
    // this.getImporter();

    // this.route.params.subscribe(data => {
    //   this.importerId = data.id;
    //   this.getImporter();
    // });
    this.getUser();
    this.createForm();
  }
  addTointer(importer) {
    let type = "subscription";
    this.b2bserviceService
      .addToInterest(importer.id, type)
      .subscribe((resp: any) => {
        importer.isInterested = true;
        this.toaster.success(
          importer.companyName + " has been added to your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
      });
  }
  removeFrominter(importer) {
    let type = "subscription";
    this.b2bserviceService
      .removeInterest(importer.id, type)
      .subscribe((resp: any) => {
        importer.isInterested = false;
        this.toaster.info(
          importer.companyName + " has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
      });
  }

  createForm() {
    this.formSearch = this.fb.group({
      type: [""],
      name: [""],
    });
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log(res);
        this.email = res.email;
        console.log("eeemmmail", this.email);
      });
    }
  }
  sliceMain(mainProd) {
    this.slicedMainProds = [];
    this.slicedMainProds = mainProd.slice(0, 50);
  }
  search() {
    if (this.selectedSearch === "products") {
      this.searchProduct();
    } else {
      this.searchCompany();
    }
  }
  addSubCategorie(idCatg) {
    this.listfilterSubCategorie = [];
    this.listfilterSubCategorie.push(idCatg.name);
    this.search();
    console.log(this.listfilterSubCategorie);
  }
  clear() {
    this.motCle = "";
    this.listfilterCategorie = [];
    this.selectedCategory = null;
    this.selectedSubCategory = null;
    this.listfilterSubCategorie = [];
    this.listfiltercountry = [];
    this.listSubCategories = [];
    this.selectedSearch = "products";
    this.searchProduct();
  }
  searchProduct() {
    this.loading = true;
    let data = {
      product: this.motCle,
      categories: this.listfilterCategorie,
      subCategories: this.listfilterSubCategorie,
      countries: this.listfiltercountry,
    };
    console.log(data);

    this.sourceService.searchCompany(data).subscribe((resp: any) => {
      console.log("categoriesList", resp);
      this.loading = false;
      this.countryList = resp.numberByCountries;
      this.listCompany = resp.subscriptions;
      console.log("COMPANIES", this.listCompany);
      this.companyCount = this.listCompany.length;
    });
  }

  getSubCategories(event) {
    console.log("=============>", this.selectedCategory);
    console.log(event);
    if (event != null) {
      if (event == "All") {
        this.loading = true;
        this.b2bserviceService.AllSubs().subscribe((resp: any) => {
          this.listSubCategories = resp;
          this.loading = false;
        });
      } else {
        this.selectedCategory2 = event.id;
        this.listSubCategories = event.subCategories;
        this.addCategorie(event.name);
      }
    } else {
      this.listSubCategories = [];
      this.listfilterCategorie = [];
      this.search();
    }
  }
  searchCompany() {
    this.loading = true;
    let data = {
      company: this.motCle,
      categories: this.listfilterCategorie,
      subCategories: this.listfilterSubCategorie,
      countries: this.listfiltercountry,
    };
    console.log(data);

    this.sourceService.searchCompany(data).subscribe((resp: any) => {
      this.loading = false;
      console.log("categoriesList", resp);
      this.countryList = resp.numberByCountries;
      this.listCompany = resp.subscriptions;
      this.companyCount = this.listCompany.length;
      this.onRecieveConnectedUsers();
    });
  }
  getCategories() {
    this.sourceService.getCategoriesAll().subscribe((resp: any) => {
      this.categoriesList = resp;
      console.log("categoriesList");
      console.log("categoriesList", this.categoriesList);

      this.sliceCategorie();
    });
  }
  checkExist(id) {
    if (this.listfilterCategorie.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
  }
  getAllCountries() {
    this.b2bserviceService.getCountries().subscribe((resp: any) => {
      this.countryList = resp;
      console.log(this.countryList);
    });
  }
  getImporter() {
    this.loading = true;
    this.sourceService
      .getImporterbyId(this.importerId)
      .subscribe((resp: any) => {
        console.log("response : ", resp);

        this.loading = false;
        this.importer = resp;
        // this.sliceCategorie();
      });
  }
  addCategorie(idCatg) {
    if (!this.listfilterCategorie.includes(idCatg)) {
      this.listfilterCategorie.push(idCatg);
      console.log(this.listfilterCategorie);
    } else {
      let index: number = this.listfilterCategorie.indexOf(idCatg);
      if (index !== -1) {
        this.listfilterCategorie.splice(index, 1);
      }
    }
    this.search();
    console.log(this.listfilterCategorie);
  }

  // Country
  addCountry(idCountry, item) {
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item);
      console.log(this.listfiltercountry);
    }
    this.search();
    console.log(this.listfiltercountry);
  }
  deleteCountry(idCountry, item) {
    let index: number = this.listfiltercountry.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.listfiltercountry.splice(index, 1);
    }
    if (index2 !== -1) {
      this.listShowcountry.splice(index2, 1);
    }
    this.search();
    console.log(this.listfiltercountry);
  }
  checkExistCountry(id) {
    if (this.listfiltercountry.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  getText(data) {
    let txt = "";
    data.forEach((element) => {
      txt = txt + "," + element;
    });
    return txt;
  }
  log(item) {
    this.messageToSend = item;
  }
  sendMail(item) {
    let data = {
      receiver: item.email,
      message: this.messageToSend,
      subject: item.companyName,
      isBuyLeadInquiry: false,
      isWebInquiry: true,
      isAllCountry: false,
      isAllCompany: false,
      location: item.country,
    };

    console.log("datatat", item);
    console.log("datatat", data);

    this.mailService.sendMessage(data).subscribe(
      (resp) => {
        console.log("resap : ", resp);
        this.toaster.success(
          "The inquiry has been sent succefully",
          "Success",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.isVisible = false;
      },
      (err) => {}
    );
  }

  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }
  showModal(item): void {
    this.reciver = item;
    this.isVisible = true;
  }

  goToLiveChat(item) {
    console.log("---------- item  --------- : ", item);
    this.router.navigate(["/profil/liveChat/"], {
      queryParams: { email: item },
    });
  }

  onRecieveConnectedUsers() {
    console.log("get received ");
    this.webSocketService.getConnectedUsers().subscribe((res: any) => {
      console.log("result : ", res);
      this.connectedUsers = res;
      console.log("connected users : ", res);
      // this.connectedUsers = JSON.parse(res);
      this.connectedUsers = Object.assign({}, res);
      this.userExistInConnected(res);
      this.webSocketService.onRecieveConnectedUsers().subscribe((res) => {
        // alert("new user connected or disconnected")
        console.log("reeees : ", res);
        this.userExistInConnected(res);
      });
    });
  }

  userExistInConnected(users) {
    // alert("list to display ")
    console.log(this.listCompany);

    this.listCompany.forEach((product, index) => {
      users.forEach((user) => {
        if (product.user.email == user.email) {
          let connected = { isConnected: true };
          this.listCompany[index] = { ...product, ...connected };
        }
      });
    });
  }
}
