// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {IWebEnvironment} from "src/app/commons/models/Environment";

export const environment : IWebEnvironment = {
  // ZoomAppBaseUrl:'http://demo.peaqock.com/webinars/',
  ZoomAppBaseUrl: "webinars/",
  baseUrl: "https://b2b.peaqock.com/api",
  // baseUrl: '/api',
  //baseUrl: 'http://localhost:8090/api',
  // http://localhost:8090/api/subscriptions
  TOKEN_NAME: "TIP_APP_TOEKN",
  REFRESH_TOKEN_NAME: "TIP_APP_REFRESH_TOEKN",
  ROLE: "ROLE",
  production: false,
  SITE_KEY_RECAPTCHA: "6LeQD4AjAAAAAC3HkJIGb-puH1hUL0ccUd1Azb4U",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
