import { Component, OnInit } from "@angular/core";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-all-main-categories",
  templateUrl: "./all-main-categories.component.html",
  styleUrls: ["./all-main-categories.component.css"],
})
export class AllMainCategoriesComponent implements OnInit {
  public categoriesList = [];
  public listCategories = [];
  public cats: any = [];
  public firt9cats: any = [];
  public subCats: any = [];
  public url = environment.baseUrl + "/";
  constructor(private b2bserviceService: B2bProductService) {}

  ngOnInit() {
    this.getAllCatAndClassification();
  }

  getAllCatAndClassification() {
    this.b2bserviceService
      .getAllCatAndClassification()
      .subscribe((resp: any) => {
        console.log("cats", resp);
        this.cats = resp;
        this.firt9cats = this.cats.slice(0, 9);
        this.subCats = this.firt9cats.subCategories;
        console.log(resp);
      });
  }
}
