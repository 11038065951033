import { Component, NgZone, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from '@amcharts/amcharts4/charts';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
@Component({
  selector: 'app-sales-activity',
  templateUrl: './sales-activity.component.html',
  styleUrls: ['./sales-activity.component.css']
})
export class SalesActivityComponent implements OnInit {
  lineData;
  sales;
  views;
  orders;
  inquiries;
  groupData = [
    {
      "name": "Morocco",
      "data": [
        {
          "title": "Morocco",
          "id": "MA",
          "customData": 50
        }
      ]
    },
    {
      "name": "Nigeria",
      "data": [
        {
          "title": "Nigeria",
          "id": "NG",
          "customData": 27
        }
      ]
    },
    {
      "name": "Egypt",
      "data": [
        {
          "title": "Egypt",
          "id": "EG",
          "customData": 13
        }
      ]
    }, {
      "name": "Mauritania",
      "data": [
        {
          "title": "Mauritania",
          "id": "MR",
          "customData": 10
        }

      ]
    },
     {
      "name": "other",
      "data": [
        {
          "title": "Senegal",
          "id": "SN",
          "customData": 1
        }, {
          "title": "Somalia",
          "id": "SO",
          "customData": 1
        }, {
          "title": "South Africa",
          "id": "ZA",
          "customData": 1
        }
        , {
          "title": "Tanzania",
          "id": "TZ",
          "customData": 1
        }

      ]
    }
  ];
  role;
  isSeller: Boolean = true;
  buyerTotalCount ;
  constructor(private productService: B2bProductService,private zone: NgZone,private userService: UserService,
              private profileRouteService : ProfileRouteService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("SalesActivity");
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.role = res.role;
        console.log("this.role : " ,this.role);
        if(this.role == 'ROLE_BOTH' || this.role == 'ROLE_SELLER' ) {
          this.isSeller =true;

          // this.initRace(this.groupData);
          // this.initPieChart();
          // this.initLineChart();
          // this.reportCount();

        } else if ( this.role == 'ROLE_BUYER' ) {
          this.isSeller =false;

        }
      })
  }
  }

  changeToBuyer() {
    this.isSeller = !this.isSeller;
    // setTimeout(() => {
    //   this.initRace(this.groupData);
    //   this.initPieChart();
    //   this.initLineChart();
    // }, 50);
  }

  reportCount(){
    this.productService.getTotalCount().subscribe((resp: any) => {
      console.log(resp);
      this.views = resp.views
      this.orders = resp.orders
      this.sales = resp.sales
      this.inquiries = resp.inquiries
    })

  }
  initLineChart() {
    this.zone.runOutsideAngular(() => {
        this.productService.getSalesReport().subscribe((resp: any) => {
          console.log(resp);
          this.lineData = resp


      let chart = am4core.create("chartRevenue", am4charts.XYChart);
      chart.data=this.lineData;
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0;
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      categoryAxis.renderer.grid.template.disabled = true;


      // Create value axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;
      valueAxis.renderer.labels.template.fontSize = 13;
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");
      valueAxis.renderer.labels.template.fill = am4core.color("#D8D8DD");


      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "views";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.76;
      series.fill = am4core.color("#E27BF1");
      series.stroke = am4core.color("#E27BF1");
      series.fillOpacity  = 0.8;

      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "orders";
      series.dataFields.categoryX = "date";
      series.strokeWidth = 1.5;
      series.tensionX = 0.75;
      series.fill = am4core.color("#2785FB");
      series.fillOpacity = 0.6;
      series.stroke = am4core.color("#2785FB");
 // Create series
 var series = chart.series.push(new am4charts.LineSeries());
 series.dataFields.valueY = "sales";
 series.dataFields.categoryX = "date";
 series.strokeWidth = 1.5;
 series.stroke = am4core.color("#5F5DE1");
 series.tensionX = 0.75;
 series.fillOpacity = 0.4;
 series.fill = am4core.color("#5F5DE1");

      var range = valueAxis.createSeriesRange(series);
      range.value = 0;
      range.endValue = -1000;
      range.contents.stroke = am4core.color("#FF0000");
      range.contents.fill = range.contents.stroke;

      // Add scrollbar
      // var scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();
    })
    }
    )
  }

  initPieChart() {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("SellerchartStatus", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.legend = new am4charts.Legend();

    chart.data = [
      {
        product: "Beans",
        sales: 300,
        // color:"#2785FB"
      },
      {
        product: "Shoes",
        sales: 30,
        // color:"#F15931"
      },
      {
        product: "Eggs",
        sales: 45,
        // color: "#F8B7C9"
      },
      {
        product: "Watches",
        sales: 7,
        // color: "#C2DEC0"
      },
      {
        product: "Airpods",
        sales: 200,
        // color: "#E27BF1"
      }
    ];

    chart.innerRadius = am4core.percent(40);
    let label = chart.seriesContainer.createChild(am4core.Label);
    label.text = "5 Products";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 30;
    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "sales";
    series.dataFields.category = "product";
    series.slices.template.stroke = am4core.color("#fff");
    series.innerRadius = 10;
    series.slices.template.fillOpacity = 1;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;

    // series.slices.template.fill = am4core.color("{series.color}")
  //   series.colors.list = [
  //     am4core.color("#55C492"),
  //     am4core.color("#E59AAE"),
  //     am4core.color("#B57DFF"),
  //     am4core.color("#2785FB"),
  // ];

  }

  initRace(data:any){
    am4core.useTheme(am4themes_animated);
// Themes end

// Create map instance
let chart = am4core.create("chartdiv", am4maps.MapChart);

// Set map definition
chart.geodata = am4geodata_worldHigh;

// Set projection
chart.projection = new am4maps.projections.Mercator();

// Export
chart.exporting.menu = new am4core.ExportMenu();

// Zoom control
chart.zoomControl = new am4maps.ZoomControl();

let homeButton = new am4core.Button();
homeButton.events.on("hit", function() {
  chart.goHome();
});

homeButton.icon = new am4core.Sprite();
homeButton.padding(7, 5, 7, 5);
homeButton.width = 30;
homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
homeButton.marginBottom = 10;
homeButton.parent = chart.zoomControl;
homeButton.insertBefore(chart.zoomControl.plusButton);

// Center on the groups by default
chart.homeZoomLevel = 4.2;
chart.homeGeoPoint = { longitude: 15, latitude: 0 };



// This array will be populated with country IDs to exclude from the world series
let excludedCountries = ["AQ"];

// Create a series for each group, and populate the above array
    this.groupData.forEach(function(group) {
  let series = chart.series.push(new am4maps.MapPolygonSeries());
  if(group.name === "other"){
    series.name = "other";
  }
  else
  {
    series.name = group.data[0].title;
  }
  series.useGeodata = true;
  let includedCountries = [];
  group.data.forEach(function(country) {

  if(country.customData>=50)
  {
  series.fill = am4core.color("rgba(0, 98, 225, 1)");
  let mapPolygonTemplate = series.mapPolygons.template;
  mapPolygonTemplate.fill = am4core.color("rgba(0, 98, 225, 1)");
}
else if(country.customData<=49&&country.customData>=35){
  series.fill = am4core.color("rgba(0, 98, 225, 0.85)");
  let mapPolygonTemplate = series.mapPolygons.template;
  mapPolygonTemplate.fill = am4core.color("rgba(0, 98, 225, 0.85)");

}
else if(country.customData<=34&&country.customData>=25){
  series.fill = am4core.color("rgba(0, 98, 225, 0.7)");
  let mapPolygonTemplate = series.mapPolygons.template;
  mapPolygonTemplate.fill = am4core.color("rgba(0, 98, 225, 0.7)");

}
else if(country.customData<=24&&country.customData>=10){
  series.fill = am4core.color("rgba(0, 98, 225, 0.5)");
  let mapPolygonTemplate = series.mapPolygons.template;
  mapPolygonTemplate.fill = am4core.color("rgba(0, 98, 225, 0.5)");

}
else if(country.customData<10){
  series.fill = am4core.color("rgba(0, 98, 225, 0.3)");
  let mapPolygonTemplate = series.mapPolygons.template;
  mapPolygonTemplate.fill = am4core.color("rgba(0, 98, 225, 0.3)");

}
    includedCountries.push(country.id);
    excludedCountries.push(country.id);
  });
  series.include = includedCountries;

  // By creating a hover state and setting setStateOnChildren to true, when we
  // hover over the series itself, it will trigger the hover SpriteState of all
  // its countries (provided those countries have a hover SpriteState, too!).
  series.setStateOnChildren = true;
  series.calculateVisualCenter = true;

  // Country shape properties & behaviors
  let mapPolygonTemplate = series.mapPolygons.template;
  // Instead of our custom title, we could also use {name} which comes from geodata
  // mapPolygonTemplate.fill = am4core.color(group.color);
  mapPolygonTemplate.fillOpacity = 0.8;
  mapPolygonTemplate.nonScalingStroke = true;
  mapPolygonTemplate.tooltipPosition = "fixed"

  mapPolygonTemplate.events.on("over", function(event) {
    series.mapPolygons.each(function(mapPolygon) {
      mapPolygon.isHover = true;
    })
    event.target.isHover = false;
    event.target.isHover = true;
  })

  mapPolygonTemplate.events.on("out", function(event) {
    series.mapPolygons.each(function(mapPolygon) {
      mapPolygon.isHover = false;
    })
  })

  // States
  let hoverState = mapPolygonTemplate.states.create("hover");
  // hoverState.properties.fill = am4core.color("#CC0000");

  // Tooltip
  mapPolygonTemplate.tooltipText = "{title}  {customData}%"; // enables tooltip
  // series.tooltip.getFillFromObject = false; // prevents default colorization, which would make all tooltips red on hover
  // series.tooltip.background.fill = am4core.color(group.color);

  // MapPolygonSeries will mutate the data assigned to it,
  // we make and provide a copy of the original data array to leave it untouched.
  // (This method of copying works only for simple objects, e.g. it will not work
  //  as predictably for deep copying custom Classes.)
  series.data = JSON.parse(JSON.stringify(group.data));
});

// The rest of the world.
let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
let worldSeriesName = "world";
worldSeries.name = worldSeriesName;
worldSeries.useGeodata = true;
worldSeries.exclude = excludedCountries;
worldSeries.fillOpacity = 0.8;
worldSeries.hiddenInLegend = true;
worldSeries.mapPolygons.template.nonScalingStroke = true;

// This auto-generates a legend according to each series' name and fill
chart.legend = new am4maps.Legend();

// Legend styles
chart.legend.paddingLeft = 27;
chart.legend.paddingRight = 27;
chart.legend.marginBottom = 15;
chart.legend.width = am4core.percent(90);
chart.legend.valign = "bottom";
chart.legend.contentAlign = "left";

// Legend items
chart.legend.itemContainers.template.interactionsEnabled = false;

  }

}
