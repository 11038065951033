import { AfterViewInit, Component, Input, OnInit, Output ,EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';
import { OrderRequest } from '../models/OrderRequest';
import { OrderRequestService } from '../services/order-service/order-request-service.service';

@Component({
  selector: 'app-start-order',
  templateUrl: './start-order.component.html',
  styleUrls: ['./start-order.component.css']
})
export class StartOrderComponent implements OnInit,AfterViewInit {
  @Input() orderRequest:OrderRequest;
  // orderRequest:OrderRequest = new OrderRequest();
  quantity=1;
  minQuantity=1;
  public productId;
  productInfo;
  companyInfo;
  baseUrl = environment.baseUrl + '/';
  public selectedProduct;
  public total = 0;
  public savings = 0;
  public savingsPrice = 0;
  @Output() orderRequestToSend = new EventEmitter<OrderRequest>();

  constructor(private b2bProductService : B2bProductService,private userService: UserService,
    private route : ActivatedRoute,private orderRequestService : OrderRequestService) { }
  ngAfterViewInit(): void {
    this.getProductInfobyId()
  }
  


  ngOnInit() {
    console.log("orderRequest " , this.orderRequest);
      // this.productId = this.orderRequest.product;
      // this.getOrderRequest();
      

  }

  
  
  getProductInfobyId() {
    // this.productInfo = resp.productDTO;
    // this.companyInfo = resp.subscriptionDTO;
    // this.minQuantity = this.orderRequest.product.minimumOrderQuantity;
    // this.orderRequest.quantity = this.orderRequest.product.minimumOrderQuantity;
    this.countTotal();
    // this.b2bProductService.getProductInfobyId(this.productId).subscribe((resp:any)=> {
    //   this.productInfo = resp.productDTO;
    //   this.companyInfo = resp.subscriptionDTO;
    //   this.minQuantity = this.productInfo.minimumOrderQuantity;
    //   this.orderRequest.quantity = this.productInfo.minimumOrderQuantity;
    //   this.countTotal();
    //   console.log(resp);

    // })
  }
  countTotal(){
    // this.orderRequest.quantity = this.orderRequest.product.minimumOrderQuantity;
    this.orderRequest.cost =this.orderRequest.quantity*this.orderRequest.product.priceDollars
    this.savingsPrice= this.orderRequest.product.priceDollars-this.orderRequest.product.discountedPrice
    this.savings=this.orderRequest.quantity*this.savingsPrice

    this.emitOrderRequest();

    // this.orderRequestService.changeOrderRequest(this.orderRequest);
    
  //   if(this.demoValue<this.selectedProduct.minimumOrderQuantity){
  //     this.passed=true;
  //   }else{
  //     this.passed=false;
  //   }
  //   if(this.demoValue>this.selectedProduct.quantity){
  //     this.passed1=true;
  //   }else{
  //     this.passed1=false;
  //   }
  }

  // getOrderRequest() {
  //   this.orderRequestService.currentOrderRequest.subscribe(orderRequest => {
  //     this.orderRequest = orderRequest
  //     this.countTotal();
  //   })
  // }

  emitOrderRequest() {
    this.orderRequestToSend.emit(this.orderRequest);
  }
}
