import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderRequestService {

  baseUrl = environment.baseUrl+"/orders";

  private orderRequest = new BehaviorSubject<any>({});
  currentOrderRequest = this.orderRequest.asObservable();

  constructor(private http: HttpClient) { }

    changeOrderRequest(orderRequest) {
      this.orderRequest.next(orderRequest);
    }

    buyItem(order:any){
      return this.http.post(this.baseUrl+'/pay',order);
    }
    getOrder(id){
      return this.http.get(this.baseUrl+'/'+id);
    }

}
