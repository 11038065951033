import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { OrderRequest } from '../models/OrderRequest';
import { OrderRequestService } from '../services/order-service/order-request-service.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.css']
})
export class BillingDetailsComponent implements OnInit {
  @Input() orderRequest:OrderRequest;

  userInfo;
  billingInfo;
  public formBilling!: FormGroup;
  public listafricanCountries = [];
  constructor(private fb: FormBuilder,private buyRequestService: BuyRequestService,private orderRequestService : OrderRequestService) { }

  ngOnInit() {
    console.log("user Auth 11111:",this.orderRequest)
    this.userInfo=this.orderRequest.user;
    this.billingInfo=this.orderRequest.billingDetails;
    console.log("user Auth 22222:",this.userInfo)
    this.createFormBilling();
    this.getAllafricanCountries();
    this.setValue();

  }
   getAllafricanCountries(){
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }
  createFormBilling() {
    this.formBilling = this.fb.group({
      id: [],
      email: [null, [Validators.required, Validators.email]],
      Fname: [null, [Validators.required]],
      Lname: [null, [Validators.required]],
      company: [null, [Validators.required]],
      country: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      State: [null, [Validators.required]],
      City: [null, [Validators.required]],
      ZIP: [null, [Validators.required]],

    });
  }
  setValue() {
    this.formBilling.get('email').setValue(this.billingInfo.email);
    this.formBilling.get('Fname').setValue(this.billingInfo.firstName);
    this.formBilling.get('Lname').setValue(this.billingInfo.lastName);
    this.formBilling.get('company').setValue(this.billingInfo.companyName);
    this.formBilling.get('country').setValue(this.billingInfo.country);
    this.formBilling.get('adress').setValue(this.billingInfo.companyAddress);
    this.formBilling.get('City').setValue(this.billingInfo.companyCity);
  }
  onChanges(): void {
    this.formBilling.valueChanges.subscribe(val => {

    this.orderRequestService.changeOrderRequest(this.orderRequest);
    });
  }
  isValidated() {
    if(this.formBilling.valid) {
      return true
    }
    return false
  }
}
