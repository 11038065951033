import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PricingPlan } from "../../models/PricingPlan";
import { DiscountType } from "../../models/DiscountType";
import { Discount } from "../../models/Discount";
import { HandleCoupon } from "../../models/HandleCoupon";
import { ShareRequest } from "../../models/ShareRequest";
import { environment } from "../../../../environments/environment";
import { PermissionEntity } from "src/app/modules/sub-admin/sub-admin.model";
@Injectable({
  providedIn: "root",
})
export class AdminService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    this.http = http;
  }

  getById(id: number) {
    return this.http.get(this.baseUrl + "/pricingPlans/" + id);
  }

  create(pricingPlan: PricingPlan) {
    return this.http.post(this.baseUrl + "/pricingPlans/", pricingPlan);
  }

  update(pricingPlan: PricingPlan) {
    return this.http.put(this.baseUrl + "/pricingPlans", pricingPlan);
  }

  updatePlan(pricingPlan: PricingPlan) {
    return this.http.put(this.baseUrl + "/pricingPlans/update", pricingPlan);
  }
  updateWithDeactivate(pricingPlan: PricingPlan) {
    return this.http.put(this.baseUrl + "/pricingPlans/", pricingPlan);
  }
  getActivate() {
    return this.http.get(this.baseUrl + "/pricingPlans");
  }

  /********** discount services */
  //  :::::::::::::::::::::::::::::::::::::::::::::::
  getCountContactType(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/contact_cnt?timePeriod=" + value
    );
  }

  getLatestUser(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/recent_users?timePeriod=" + value
    );
  }

  getMostFollowedMarket(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/most_followed_market?timePeriod=" + value
    );
  }

  getMostFollowedProduct(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/most_followed_product?timePeriod=" + value
    );
  }

  getUserByDays(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/login_counts?timePeriod=" + value
    );
  }

  getNumberCustomerByOrgType(criteria) {
    return this.http.get(this.baseUrl + "/dashboard/customer_report", {
      params: criteria,
    });
  }

  getCoutByPayment(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/count_by_payment?timePeriod=" + value
    );
  }

  getAgentContributer(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/agent_contributer_counts?timePeriod=" + value
    );
  }

  getDataCapture(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/data_counts?timePeriod=" + value
    );
  }

  getDiscountCount(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/discounts_counts?timePeriod=" + value
    );
  }

  getTopTendersCountries(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/tenders/top-countries?timePeriod=" + value
    );
  }

  getTopTendersFavoritProject(value) {
    return this.http.get(
      this.baseUrl +
        "/dashboard/tenders/top-favorit-project?timePeriod=" +
        value
    );
  }

  getTopTendersSectors(value) {
    return this.http.get(
      this.baseUrl + "/dashboard/tenders/top-sectors?timePeriod=" + value
    );
  }

  // :::::::::::::::::::::::::::::::::::::::::
  generateCoupon(discountType: String) {
    return this.http.get(this.baseUrl + "/discount/" + discountType);
  }

  enableOrdisableCoupon(couponCode: string) {
    return this.http.get(this.baseUrl + "/discount/status/" + couponCode);
  }

  getAllDiscountsForCurrentAccount() {
    return this.http.get(this.baseUrl + "/discount/accounts");
  }

  getAllDiscount() {
    return this.http.get(this.baseUrl + "/discount");
  }

  getAllEvouchers() {
    return this.http.get(this.baseUrl + "/discount/evoucher");
  }

  createDiscount(discount: Discount) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: headers };
    return this.http.post(this.baseUrl + "/discount", discount, options);
  }
  createEvoucher(discount: Discount) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: headers };
    return this.http.post(
      this.baseUrl + "/discount/newEvoucher",
      discount,
      options
    );
  }

  editDiscount(couponCode: string, discountType: string) {
    return this.http.get(
      this.baseUrl + "/discount/status/evoucher/" + couponCode
    );
  }
  // deleteDiscount(coupon:string){
  //   return this.http.delete(this.baseUrl+'/discount/'+coupon)
  // }
  deleteDiscount(coupon: string, discountType: string) {
    return this.http.delete(this.baseUrl + "/discount/" + coupon);
  }

  validateCoupon(handleCoupon: any, token: any) {
    let headers;
    if (token == null) {
      headers = new HttpHeaders({ "Content-Type": "application/json" });
    } else {
      headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
    }
    const options = { headers: headers };
    return this.http.post(
      this.baseUrl + "/discount/validate",
      handleCoupon,
      options
    );
  }

  validateEvoucher(evoucher: any, token: any) {
    let headers;
    if (token == null) {
      headers = new HttpHeaders({ "Content-Type": "application/json" });
    } else {
      headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
    }
    const options = { headers: headers };
    return this.http.post(
      this.baseUrl + "/discount/validate/evoucher",
      evoucher,
      options
    );
  }

  shareWithUsers(coupn, emails) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: headers };
    return this.http.put<any>(
      this.baseUrl + "/discount/share",
      { coupon: coupn, emails: emails },
      options
    );
  }

  getUsers() {
    return this.http.get(this.baseUrl + "/user/users");
  }

  getUsersDiscountManagment() {
    return this.http.get(this.baseUrl + "/user/users-users");
  }

  getUsersEvoucher() {
    return this.http.get(this.baseUrl + "/user/users-users");
  }

  searchBykeyPostFreightRequest(body, criteria) {
    return this.http.post(
      this.baseUrl + "/postFreightRequests/searchKeyStatues",
      body,
      {
        params: criteria,
      }
    );
  }
  getCaptureFinanceOpportunitesByUser(criteria) {
    return this.http.get(this.baseUrl + "/postFreightRequests/searchByUser", {
      params: criteria,
    });
  }
  //validation data capture post freight

  //validation data capture post freight
  validatePostFreight(body) {
    return this.http.post(this.baseUrl + "/postFreightRequests/validate", body);
  }
  validateFreighthub(body) {
    return this.http.post(this.baseUrl + "/freight_hub/validate", body);
  }
  //validation data capture post freight
  searchBykeyFreighthub(body,criteria) {
    return this.http.post(this.baseUrl + "/freight_hub/search", body,{
      params: criteria,
    });
  }
  getPasswordSetting() {
    return this.http.get(this.baseUrl + "/usp_management");
  }
  setPasswordSetting(body) {
    return this.http.post(this.baseUrl + "/usp_management", body);
  }

  getPasswordCretaria(id: any) {
    return this.http.get(this.baseUrl + "/criteria", id);
  }

  setOtpSetting(body) {
    return this.http.post(this.baseUrl + "/usp_management/otp_critaria", body);
  }
  getOtpCretaria() {
    return this.http.get(this.baseUrl + "/usp_management/otp_critaria");
  }

  //  new add from tip

  shareCodeUsers(coupn, emails) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: headers };
    return this.http.post<any>(
      this.baseUrl + "/discount/share",
      { coupon: coupn, emails: emails },
      options
    );
  }

  addContact(body) {
    return this.http.post(this.baseUrl + "/cupon_settings/contacts", body);
  }

  getCuponHistoriq(type) {
    return this.http.get(
      this.baseUrl + "/cupon_settings/historic?type=" + type
    );
  }

  cuponShare(body, type) {
    return this.http.post(
      this.baseUrl + "/cupon_settings/share?type=" + type,
      body,
      { responseType: "text" }
    );
  }

  deleteEvoucher(coupon: string, discountType: string) {
    return this.http.delete(this.baseUrl + "/discount/EVOUCHER/" + coupon);
  }

  // /////////////////////////////////////////////////////

  getAllPermissionEntity() {
    return this.http.get(this.baseUrl + "/managementPermission");
  }
  updateUser(id, body) {
    return this.http.put(this.baseUrl + "/user/" + id, body);
  }

  deletePermissionEntity(id) {
    return this.http.delete(this.baseUrl + "/managementPermission/" + id);
  }

  enableUser(id) {
    return this.http.put(this.baseUrl + "/user/" + id + "/enable", null);
  }

  disableUser(id) {
    return this.http.put(this.baseUrl + "/user/" + id + "/disable", null);
  }

  addPermissionEntity(body: PermissionEntity) {
    return this.http.post(this.baseUrl + "/managementPermission", body);
  }

  updatePermissionEntity(body: PermissionEntity) {
    return this.http.put(this.baseUrl + "/managementPermission", body);
  }

  deleteUser(id) {
    return this.http.delete(this.baseUrl + "/user/" + id);
  }

  getUsersAdmins() {
    return this.http.get(this.baseUrl + "/user/users-admin");
  }

  addSubAdminUser(body) {
    return this.http.post(this.baseUrl + "/sub-admin", body);
  }
}
