import { Component, OnInit } from '@angular/core';
import { LiveChatService } from 'src/app/commons/services/live-chat-service/live-chat.service';
import { UserService } from 'src/app/commons/services/user.service';

@Component({
  selector: 'app-chat-home',
  templateUrl: './chat-home.component.html',
  styleUrls: ['./chat-home.component.css']
})
export class ChatHomeComponent implements OnInit {

  searchTxt: string = "";
  listRoom: any = [];
  user :any;

  constructor(private liveChatService : LiveChatService, private userService : UserService) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    let id = localStorage.getItem("USER_ID")
    if (id) {
      this.userService.getAuthUser(id).subscribe(
        (res: any) => {
          // alert("user")
          console.log("userService", res);
          this.user = res;
          this.getAllRooms();
        })
    }
  }

  getAllRooms() {
    this.liveChatService.getAllRooms(this.searchTxt).subscribe(res => {
      console.log("res :", res);
      this.listRoom = res;
    })

  }


}
