import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {

  baseUrl = environment.baseUrl+"/paypal";
  paymentUrl = environment.baseUrl+"/braintree";


  constructor(private http: HttpClient) { }
   

    buyItemWithPaypal(orderId:any){
      return this.http.post(this.baseUrl+'/pay/'+orderId,orderId,{responseType:"text"});
    }

    PaypalPaySuccess(orderId:any){
      return this.http.post(this.baseUrl+'/pay/success/'+orderId, orderId);
    }
    
    getBraintreetoken(){
      return this.http.get(this.paymentUrl+'/token',{responseType:"text"});
    }

    buyItemWithBraiinTree(orderId, noOnce){
      return this.http.post(this.paymentUrl+'/'+orderId,noOnce);
    }
  
  
}
