import { Component, OnInit, Input, Output, ViewChild, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



@Component({
  selector: 'app-af-introduction',
  templateUrl: './af-introduction.component.html',
  styleUrls: ['./af-introduction.component.css']
})
export class AfIntroductionComponent implements OnInit {


  editMode = false;
  isDisabled = false;


  public Editor = ClassicEditor;


  @Input() title;

  @Output() valueChange : EventEmitter<string> = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }


valueChanged(){
    console.log(this.title)
    this.valueChange.emit(this.title);
    this.editMode = false;

  }




}
