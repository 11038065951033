export const data=[
  {
    "disabled": false,
    "title": "Economic Region",
    "key": "0-0",
    "children": [
      {
        "key": "AMU",
        "title": "Arab Maghreb Union"
      },
      {
        "key": "CEN-SAD",
        "title": "Community of Sahel-Saharan States"
      },
      {
        "key": "COMESA",
        "title": "Common Market for Eastern and Southern Africa"
      },
      {
        "key": "EAC",
        "title": "East African Community"
      },
      {
        "key": "ECCAS",
        "title": "Economic Community of Central African States"
      },
      {
        "key": "IGAD",
        "title": "Intergovernmental Authority on Development"
      },
      {
        "key": "SADC",
        "title": "Southern African Development Community"
      },
      {
        "key": "ECOWAS",
        "title": "Economic Community of West African States"
      }
    ]

  },
  {
    "disabled": false,
    "title": "Geographic Region",
    "key": "0-1",
    "children": [
      {
        "key": 1008,
        "title": "West Africa"
      },
      {
        "key": 1009,
        "title": "Southern Africa"
      },
      {
        "key": 1010,
        "title": "East Africa"
      },
      {
        "key": 1011,
        "title": "North Africa"
      },
      {
        "key": 1012,
        "title": "Central Africa"
      }
    ]

  }
]
