import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a2f-contributer',
  templateUrl: './a2f-contributer.component.html',
  styleUrls: ['./a2f-contributer.component.css']
})
export class A2fContributerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
