import { Component, OnInit, Output } from '@angular/core';
import { AdminService } from 'src/app/commons/services/dashboard-admin/admin.service';
import { PricingPlan } from 'src/app/commons/models/PricingPlan';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.css']
})
export class PriceTableComponent implements OnInit {

  public pricingPlans: PricingPlan[] = []
  size: number;
  frequancy:any="MONTH"

  @Output() costChange = new EventEmitter<{ cost: any, id: any,planFrequency:any }>();
  
  planSelected1:boolean=false
  planSelected2:boolean=false
  // planSelected3:boolean=false
  // planSelected4:boolean=false
  constructor(private adminService: AdminService) { }
  access: any = {
    FULL: 'FULL',
    LIMITED: "LIMITED",
    REGISTER_TO_VIEW_ONLY: 'Register to view only'
  }
  ngOnInit() {
    this.getAllPlans()
  }

  getAllPlans() {
    this.adminService.getActivate()
      .subscribe(
        (data: PricingPlan[]) => {
          this.pricingPlans = data;
          console.log(this.pricingPlans);
        },
        (error) => console.log(error))
  }

  click(cost: any, id: any,planFrequency) {
    console.log(planFrequency)
    this.costChange.emit({ cost, id ,planFrequency});
   
    if(id == 1){
       this.planSelected1 = true;
       this.planSelected2=false
      //  this.planSelected3=false
      //  this.planSelected4=false
    }else if(id == 2){
      this.planSelected1 = false;
      this.planSelected2=true
      // this.planSelected3=false
      // this.planSelected4=false
    }
    // else if(id == 3){
    //   this.planSelected1 = false;
    //   this.planSelected2=false
    //   this.planSelected3=true
    //   this.planSelected4=false
    // }else if(id == 4){
    //   this.planSelected1 = false;
    //   this.planSelected2=false
    //   this.planSelected3=false
    //   this.planSelected4=true
    // }

   
  }

  disableSelect(annualIsActive, monthIsActive): Boolean {
    if (this.frequancy == "MONTH" && monthIsActive == true) {

      return false
    } else if (this.frequancy == "YEAR" && annualIsActive == true){
      return false
    }
    else{
      return true
    }
  }

  onFrequencyChange(event){
    this.planSelected1 = false;
    this.planSelected2 = false;
    this.costChange.emit({ cost: 0, id: null, planFrequency: event });
  }

}
