import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  baseUrl = environment.baseUrl;


  constructor(private http: HttpClient) {
     this.http = http;
  }

  create(subscription: any) {
  return this.http.post(this.baseUrl + '/subscriptions', subscription);
  }

  getById(id: number) {
    return this.http.get(this.baseUrl + '/subscriptions/' + id);
  }

  update(id: number, subscription: any) {
    return this.http.put(this.baseUrl + '/subscriptions/' + id, subscription);
  }
  delete(id: number) {
    return this.http.delete(this.baseUrl + '/subscriptions/' + id);
  }

  getIndividualType() {
     return this.http.get(this.baseUrl + '/subscriptions/individualType');
  }
  getInstitutionNature() {
  return this.http.get(this.baseUrl + '/subscriptions/businessType');
  }
  getInterrestedProduct() {
    return this.http.get(this.baseUrl + '/subscriptions/interrestedCategories');
  }
  getOrganizationType() {
    return this.http.get(this.baseUrl + '/subscriptions/organisationType');
  }
  getPreferredMarkets() {
    return this.http.get(this.baseUrl + '/subscriptions/preferredMarket');
  }

  getByEmail(email: any) {
    return this.http.get(this.baseUrl + '/subscriptions/getByEmail');
  }

  checkTipUser(body: any) {
    return this.http.post(this.baseUrl + '/subscriptions/checkTipUser', body);
  }
  getInfoNotif() {
    return this.http.get(this.baseUrl + '/chat/count-msg');
  }
  allowInquiry (id, webInquiry, buyLeadInquiry) {
    let formData = new FormData();
    formData.append("webInquiry", webInquiry);
    formData.append("buyLeadInquiry", buyLeadInquiry);
    return this.http.put(this.baseUrl + '/subscriptions/allow-inquiry/'+id ,formData);
  }


  companyName
  getCompanyName(object: any) {
    return this.http.get(this.baseUrl + '/subscription/companyName?key=' + object);
  }

  // Products
  // getProducts() {
  //   return this.http.get(this.baseUrl + "/subscription/myProductService");
  // }
  // //Services
  // getServices() {
  //   return this.http.get(this.baseUrl + "/trade_insights/services");
  // }


  //  Recaptcha service
  getCaptchaResponse(object: string): Observable<any> {
    return this.http.get(this.baseUrl + '/captchaResponse/?recaptcha=' + object);
  }
}
