import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
// export class FreightService {
//   baseUrl = environment.baseUrl+"/freight_service_provider";
//   baseUrl1 = environment.baseUrl+"/postFreightRequests";
//   baseUrl2 = environment.baseUrl+"/insurance";

// constructor(private http: HttpClient) { }
//   searchFreight(body:any){
//     return this.http.post(this.baseUrl,body );
//   }
//     getcountries(){
//       return this.http.get(this.baseUrl);
//     }
//     getAllInsurance(body:any){
//       return this.http.post(this.baseUrl+'/insurance',body);
//     }
//     countriesInsurance(){
//       return this.http.get(this.baseUrl+'/insuranceCountries');
//     }
//      getPostFreightById(id) {
//     return this.http.get(this.baseUrl1 + '/'+id);
//     }
//      getAllPostFreight() {
//     return this.http.get(this.baseUrl1);
//     }

//     searchPostFreight(body:any){
//       return this.http.post(this.baseUrl1+'/search',body);
//     }
//     createPostFreight(body:any){
//       return this.http.post(this.baseUrl1,body);
//     }
//     uploadFilesFreightRequest(id: any, body: any) {
//       return this.http.post(this.baseUrl1 +"/"+ id + "/upload", body, { responseType: 'text' });
//     }
//     getOriginCountries() {
//       return this.http.get(this.baseUrl1+'/originCountries');
//       }

//     getDestinationCountry() {
//       return this.http.get(this.baseUrl1+'/destinationCountries');
//       }
//       getDimensionUnit() {
//       return this.http.get(this.baseUrl1+'/getDimensionUnit');
//       }
//       getWeightUnit() {
//       return this.http.get(this.baseUrl1+'/getWeightUnit');
//       }
//       getFreightCompanyById(id) {
//      return this.http.get(this.baseUrl + '/'+id);
//      }
//   getInsuranceCompanyById(id) {
//  return this.http.get(this.baseUrl2 + '/'+id);
//  }

//      getLocationFreight(type, country) {
//       return this.http.post(this.baseUrl1 + '/ports', country, { params: type });
//     }

//     getLocationFreightCities(type, country) {
//       return this.http.post(this.baseUrl1 + '/cities', country, { params: type });
//     }
// }
export class FreightService {
  baseUrl = environment.baseUrl + "/freight_service_provider";
  baseUrl1 = environment.baseUrl + "/postFreightRequests";
  baseUrl2 = environment.baseUrl + "/insurance";
  coutiresBaseUrl=environment.baseUrl +"/countries";
  freight: any;
  constructor(private http: HttpClient) {}
  searchFreight(body: any) {
    return this.http.post(this.baseUrl, body);
  }
  getcountries() {
    return this.http.get(this.baseUrl);
  }
  getAllInsurance(body: any) {
    return this.http.post(this.baseUrl + "/insurance", body);
  }
  postFreightHub(obj: any) {
    return this.http.post(environment.baseUrl + "/freight_hub", obj);
  }
  countriesInsurance() {
    return this.http.get(this.baseUrl + "/insuranceCountries");
  }
  getAllTypes() {
    return this.http.get(this.baseUrl + "/get_types");
  }
  searchFreightProvider(object) {
    return this.http.post(
      environment.baseUrl + "/freight_hub/searchByCreateria",
      object
    );
  }
  createPostFreight(body: any) {
    return this.http.post(this.baseUrl1, body);
  }
  uploadFilesFreightRequest(id: any, body: any) {
    return this.http.post(this.baseUrl1 + "/" + id + "/upload", body, {
      responseType: "text",
    });
  }
  getFilterByPagination(body) {
    return this.http.post(this.baseUrl1 + "/search", body);
  }
  downLoadFile(id: any) {
    return this.http.get(this.baseUrl1 + "/" + id + "/downloadFile", {
      responseType: "arraybuffer",
    });
  }
  getLocationFreight(type, country) {
    return this.http.post(this.baseUrl1 + "/ports", country, { params: type });
  }
  getLocationFreightCities(type, country) {
    return this.http.post(this.baseUrl1 + "/cities", country, { params: type });
  }

  getLocationFreightPort(type, country) {
    return this.http.post(this.baseUrl1 + "/ports?type=" + type, country);
  }

  getLocationFreightCities2(type, country) {
    return this.http.post(this.baseUrl1 + "/cities?type=" + "", country);
  }
  getAllFreightHub() {
    return this.http.get(environment.baseUrl + "/freight_hub");
  }
  searchBykeyFreightHub(criteria) {
    return this.http.get(environment.baseUrl + '/freight_hub/searchByUser', { params: criteria })
  }
  getCountriesCitiesFreight() {
    return this.http.get(this.coutiresBaseUrl + '/cities');
  }
  getCountriesSeaportsFreight() {
    return this.http.get(this.coutiresBaseUrl + '/seaports');
  }
  getCountriesAirportsFreight() {
    return this.http.get(this.coutiresBaseUrl + '/airports');
  }
  // getLocationFreightPort(type, country) {
  //   return this.http.post(this.baseUrl1 + '/ports', country, { params: type });
  // }
  // getLocationFreightCities(type, country) {
  //   return this.http.post(this.baseUrl1 + '/cities', country, { params: type });
  // }
  getOriginCountries() {
    return this.http.get(this.baseUrl1 + "/originCountries");
  }

  searchPostFreight(body: any) {
    return this.http.post(this.baseUrl1 + "/search", body);
  }

  getDestinationCountry() {
    return this.http.get(this.baseUrl1 + "/destinationCountries");
  }

  getInsuranceCompanyById(id) {
    return this.http.get(this.baseUrl2 + "/" + id);
  }
  getPostFreightById(id) {
    return this.http.get(this.baseUrl1 + "/" + id);
  }

  getAllPostFreight() {
    return this.http.get(this.baseUrl1);
  }
  getDimensionUnit() {
    return this.http.get(this.baseUrl1 + "/getDimensionUnit");
  }

  getWeightUnit() {
    return this.http.get(this.baseUrl1 + "/getWeightUnit");
  }

  getFreightCompanyById(id) {
    return this.http.get(this.baseUrl + "/" + id);
  }
}
