import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  data = [
    {
      title: "INDIVIDUAL",
      routerLink: "individual",
      description: "Select this option if you are an individual.",
      image_url: "assets/images/icons/individual.png",
      backgroundColor: "item1"
    },
    {
      title: "BUSINESS",
      routerLink: "business",
      description: "Select this option if you are an organization including SMEs.",
      image_url: "assets/images/icons/business.png",
      backgroundColor: "item2"
    },
   
    
  ]
  constructor() { }

  ngOnInit() {
  }

}
