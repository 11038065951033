import { WebSocketService } from 'src/app/commons/services/websocket-service/web-socket.service';
import { LiveChatService } from 'src/app/commons/services/live-chat-service/live-chat.service';
import { SubscriptionService } from '../../../commons/services/subscription/subscription.service';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as countriesJson from '../../../../assets/js/countries.json'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/commons/services/contacts/contact.service';
import { UserService } from 'src/app/commons/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MailService } from "../../../commons/services/mail-service"
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import {UploadFile} from 'ng-zorro-antd';
interface Countryiso {
  country: string,
  number: number,
  isocode: string,
  flag: string
}
@Component({
  selector: 'app-freight-opportunities',
  templateUrl: './freight-opportunities.component.html',
  styleUrls: ['./freight-opportunities.component.css']
})
export class FreightOpportunitiesComponent implements OnInit {
  nodes = [];
  selectedValues?: string;
  shippingMode: null;
  company: any;
  introductionTitle: string = 'Freight Opportunities/Leads'
  originCountry: any;
  quantity: any;
  quantityUnit: any;
  originCity: any;
  stateOrigin: any;
  postalOrigin: any;
  departure: null;
  countryDestination: any;
  cityDestination: any;
  stateDestination: any;
  checked: any;
  postalDestination: any;
  Arrival: null;
  postDescription: any;
  weight: any;
  weightUnit: any;
  Commodity: any;
  date: any;
  INCOTERMS: any;
  fullName: any;
  lastName: any;
  emailUser: any;
  Insurance: any;
  Hazardous: any;
  Stackable: any;
  dimensions: any;
  dimensionsUnit: any;
  notes: any;
  listUnitOfMeasure: any = [
    "Box/Boxes",
    "Container/Containers",
    "Bottle/Bottles",
    "Kit/Kits",
    "Bag/Bags",
    "Carton/Cartons",
    "Case/Cases",
    "Pack/Packs",
    "Pair/Pairs",
    "Pallet/Pallets",
    "Parcel/Parcels",
    "Perch/Perches",
    "Piece/Pieces",
    "Paint/Paints",
    "Plant/Plants",
    "Rod/Rods",
    "Roll/Rolls",
    "Set/Sets",
    "Sheet/Sheets",
    "Strand/Strands",
    "Hectare/Hectares",
    "Square Centimeter/Square Centimeters",
    "Square Foot/Square Foots",
    "Square Inch/Square Inches",
    "Square Meter/Square Meters",
    "Square Mile/Square Miles",
    "Square Yard/Square Yards",
    "Acre/Acres",
    "Ampere/Amperes",
    "Kiloampere/Kiloamperes",
    "Kiloohm/Kiloohms",
    "Kilovolt/Kilovolts",
    "Kilowatt/Kilowatts",
    "Milliampere/Milliamperes",
    "Milliohm/Milliohm",
    "Millivolt/Millivolts",
    "Milliwatt/Milliwatts",
    "Ohm/Ohms",
    "Unit/Units",
    "Volt/Volts",
    "Watt/Watts",
    "Wp",
    "Hertz",
    "Kilohertz",
    "Megahertz",
    "Milihertz",
    "Centimeter/Centimeters",
    "Chain/Chains",
    "Foot/Feet",
    "Forty-Foot Container",
    "Furlong/Furlongs",
    "Inch/Inches",
    "Meter/Meters",
    "Millimeter/Millimeters",
    "Pole/Poles",
    "Twenty-Foot Container",
    "Yard/Yards",
    "Carat/Carats",
    "Ounce/Ounces",
    "Short Ton/Short Tons",
    "Gross",
    "Dozen/Dozens",
    "Tray/Trays",
    "Grain/Grains",
    "Quarter/Quarters",
    "Degrees Celsius",
    "Degrees Fahrenheit",
    "Kilometer/Kilometers",
    "Mile/Miles",
    "Nautical Mile/Nautical Miles",
    "Cubic Centimeter/ Cubic Centimeters",
    "Cubic Foot/Cubic Feet",
    "Cubic Meter/Cubic Meters",
    "Cubic Yard/Cubic Yards",
    "Dram/Drams",
    "Gill/Gills",
    "Quart/Quarts",
    "Barrel/Barrels",
    "Fluid Ounce/Fluid Ounces",
    "Gallon/Gallons",
    "Liter/Liters",
    "Milliliter/Milliliters",
    "Bushel/Bushels",
    "Gram/Grams",
    "Kilograms/Kilograms",
    "Long Ton/Long Tons",
    "Metric Ton/Metric Tons",
    "Milligram/Milligrams",
    "Pound/Pounds",
    "Stone/Stones",
    "Ton/Tons",
    "Tonne/Tonnes"
  ];
  listUnitOfDim: any = [
    "inch (in)",
    "foot (ft)",
    "yard (yd)",
    "Kilometer (km)",
    "Centimeter (cm)",
    "Millimeter (mm)",
    "mile (mi)"
  ];
  listUnitOfweight: any = [
    "grain (gr)",
    "drachm (dr)",
    "ounce (oz)",
    "pound (lb)",
    "stone (st)",
    "quarter (qr)",
    "hundredweight (cwt)",
    "Kilogram (kg)",
    "ton (t)"
  ];
  loading = false;
  allcountries: any = (countriesJson as any).default;
  listafricanCountries1: any = this.allcountries.filter(x => x.in_africa != false);
  incoterms: any = ["CIF (Cost, Insurance and Freight)"
    , "CIP (Carriage and Insurance Paid to)drop down menu"
    , "CFR (Cost and Freight)"
    , "CPT (Carriage paid to)"
    , "DAT (Delivered at Terminal)"
    , "DAP (Delivered at Place)"
    , "DDP (Delivery Duty Paid)"
    , "EXW (Ex Works)drop down menu"
    , "FAS (Free Alongside Ship)"
    , "FCA (Free Carrier)"
    , "FOB (Free on Board)"
  ];
  RequestMeetForm = new FormGroup({});
  contactForm = new FormGroup({});
  companyNames: any;
  selectedCountriesFrom: any;
  selectedCountriesTo: any;
  selectedCountriesFilter: any;
  contact: any = {};
  userId: any;
  user: any;
  public nbrSLice = 0;
  public nbrSLiceData: number = 0;
  listdataSlice: any[] = [];
  public nbrSLiceIndirect = 0;
  public nbrSLiceDataIndirect: number = 0;
  listdataSliceIndirect: any[] = [];
  listItem: any[] = [];
  cretaria: any = {};
  total: number;
  pageIndex: number = 0;
  pageSize: number = 10;
  key: string;
  public listPeriod = ["12 months", "6 months", "3 months", "Last month", "Last week"];
  month: any;
  public reciver;
  isVisible: boolean;
  insured: null;
  listdataSlice2: any[];
  insuredRequired: null;
  shippingDate: null;
  listItem2: any;
  periodFrom: null;
  periodTo: null;
  listIndicators =['originCountry','destinationCountry','shippingMode','departureType','arrivalType','insurance','insuranceRequired','expectedShippingDate','periodFrom','periodTo','originPositionStart','destinationPositionFinal','freightActivityType']
  checkAllNull =false;
  data = {
    originCountry: null,
    destinationCountry: null,
    shippingMode: null,
    departureType: null,
    arrivalType: null,
    insurance: null,
    insuranceRequired: null,
    expectedShippingDate: null,
    periodFrom: null,
    periodTo: null,
    originPositionStart:null,
    destinationPositionFinal:null,
    freightActivityType:[]
  }
  flagUrl: any;
  criteria: any={};
  criteriaport: any={};
  criteriacities: any={};
  locationfrom :any[]=[]
  locationTo :any[]=[]
  destinationPosition:any=null
  originPosition:any =null
  listItemIndirect: any;
  listAllItem: any[] =[];
  fileList3: UploadFile[] = [];
  private requestMeetvisible: boolean =false;
  isVisible1:boolean=false;
  public ExportflowSelected : any[] = [];
  locationLabel: any = "Airport";
  public listCAtegories =["Seaport", "Freight forwarder", "Air Cargo", "Railway", "Couriers", "Logistics", "Warehousing", "Trucking", "Forwarding", "Inspection", "Customs brokers","Customs"];
  constructor(
    private datePipe: DatePipe,
    private mailService: MailService, private liveChatService: LiveChatService, private webSocketService: WebSocketService, private toaster: ToastrService, private userService: UserService, private contactService: ContactService, private fb: FormBuilder, private subscriptionService: SubscriptionService, private freightService: FreightService) { }

  ngOnInit() {
    this.initContactForm();
    this.initRequestMeetForm();
    this.search();
    this.getUser();
  }
  onScroll() {
    console.log("scrolled!!");
    this.sliceData();
  }
  onScrollIndirect() {
    console.log("scrolled indirect!!");
    this.sliceDataIndirect()
  }
  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //   if (this.bottomReached()) {
  //     this.sliceData()
  //   }
  // }
  bottomReached(): boolean {
    //return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
    return (window.innerHeight + window.scrollY) >= document.body.scrollHeight
  }
  sliceData() {
    this.nbrSLiceData = this.nbrSLiceData + 4;
    this.listdataSlice = this.listItem.slice(0, this.nbrSLiceData);
    //this.listdataSlice2 = this.listItem.slice(0, this.nbrSLiceData);

  }
  sliceDataIndirect() {
    this.nbrSLiceDataIndirect = this.nbrSLiceDataIndirect + 4;
    this.listdataSliceIndirect = this.listItemIndirect.slice(0, this.nbrSLiceDataIndirect);
  }
  getCompanyByName(value) {
    this.subscriptionService.getCompanyName(value).subscribe((resp: any) => {
      this.companyNames = resp;
    })
  }
  changeCompanyName(value) {
    if (value != "") {
      this.getCompanyByName(value)
    }

  }
  getUser() {
    this.userId = this.userService.currentUser && this.userService.currentUser.id;
    console.log("user id first  :", this.userId);
    this.userService.findById(this.userId).subscribe((res: any) => {
      this.user = res;
    })
    console.log("user company :", this.user);

  }
  onSearch(labelDestination: string, value: any): void {
    console.log('value :>> ', value);
    if (this.shippingMode == 'Intermodal') {
      this.intermodalRequest(labelDestination, value)
    }
    if (this.shippingMode == 'Sea') {
      this.filterPositionChange(labelDestination,'port',value)
    }
    if (this.shippingMode == 'Air') {
      this.filterPositionChange(labelDestination,'airport',value)
    }
    if (this.shippingMode == 'Land') {
      this.filterPositionChange(labelDestination,'city',value);
    }
  }
  intermodalRequest(lableDestination :string,value :any){
    this.criteria.type = 'airport'
    this.criteriaport.type='port'
    this.criteriacities.type='cities'
    let list = [];
    list.push(value.isocode);
    if(lableDestination == 'From'){
      this.locationfrom = []
      this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
        this.locationfrom = this.locationfrom.concat(res)
      });
      this.freightService.getLocationFreight(this.criteriaport,list).subscribe((res: any) => {
        this.locationfrom = this.locationfrom.concat(res)
      });
      this.freightService.getLocationFreightCities(this.criteriacities, list).subscribe((res: any) => {
        this.locationfrom = this.locationfrom.concat(res)
      });
    }else if(lableDestination == 'To') {
      this.locationTo = []
      this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
        this.locationTo = this.locationTo.concat(res)
      });
      this.freightService.getLocationFreight(this.criteriaport, list).subscribe((res: any) => {
        this.locationTo = this.locationTo.concat(res)
      });
      this.freightService.getLocationFreightCities(this.criteriacities, list).subscribe((res: any) => {
        this.locationTo = this.locationTo.concat(res)
      });
    }
  }
  filterPositionChange(labelDestination: string, shippingMode:string,value: any) {
    //console.log('this.IntermodelFrom :>> ', this.IntermodelFrom);
    if(value != null) {
      this.criteria.type = shippingMode
      let list = [];
      list.push(value.isocode);
      if (shippingMode != 'city') {
        if (labelDestination == 'From') {
          this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
            this.locationfrom = res
          });
        } else if (labelDestination == 'To') {
          this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
            this.locationTo = res
          });
        }
      } else {
        if (labelDestination == 'From') {
          this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
            this.locationfrom = res
          });
        } else if (labelDestination == 'To') {
          this.freightService.getLocationFreightCities(this.criteria, list).subscribe((res: any) => {
            this.locationTo = res
          });
        }
      }
    }
  }
  contactNow(receiver: any, financeOpportunities: any) {
    for (const i in this.contactForm.controls) {
      this.contactForm.controls[i].markAsDirty();
      this.contactForm.controls[i].updateValueAndValidity();
    }

    this.contact.subject = this.contactForm.value.subject;
    // console.log("subject " + this.contact.subject);
    this.contact.message = this.contactForm.value.message;
    // console.log("message " + this.contact.message);
    this.contact.receiver = receiver;
    // console.log("Reciever ")
    //console.log(this.contact.receiver);
    //console.log("ID " + receiver.id)
    this.contact.section = "FinancingOpportunities"
    this.contact.financingOpportunities = financeOpportunities;
    //console.log(this.contact.financingOpportunities)
    console.log("receier :", this.contact);
    this.contactService.contactNow(this.contact).subscribe((data: any) => {
      // console.log("Contact")
      // console.log(data);





      // if (this.fileList.length > 0) {
      //     console.log('file selected!!!!!!!!!')
      //     this.idContact = data.id;
      //     this.handleUpload(this.idContact);
      // }


      this.toaster.success(
        'Message Sent Successfully',
        "Success",
        {
          closeButton: true,
          timeOut: 4000
        })


    });
    this.initContactForm();
    this.contactForm.reset();

  }
  showRequestMeetingFrom(item: any) {
    let message = 'Dear [Mr./Ms. Last Name],\n' +
      '\n' +
      'I am writing to schedule a meeting to [write what the purpose of your meeting is]. If the time works for you, I would like to meet at [time] on [date] at [place].\n' +
      '\n' +
      'Please confirm whether that works for you or if another time and place is better. I look forward to our meeting.\n' +
      '\n' +
      'Thank you,\n' +
      '[Your name]'
    this.RequestMeetForm.patchValue({
      topic: "Request for meeting",
      message: message,
      email: item.userCompany.email
    })
    item.requestMeetvisible = true
  }
  showContactNowForm(item: any) {
    console.log("item contact now :", item)
    let message = 'Dear Mr./Ms. ' + item.user.lastName + ' ,\n' +
      '\n' +
      'I am writing to contact you in order to (purpose of your mail).\n' +
      '\n' +
      'Thank you,\n' +
      this.user.lastName
    this.RequestMeetForm.patchValue({
      topic: "no-replay",
      message: message,
      email: item.contact_email
    })
    item.isVisible1 = true
  }
  initRequestMeetForm() {
    this.RequestMeetForm = this.fb.group({
      topic: ["", [Validators.required]],
      message: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],

    });
  }
  initContactForm() {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      file: [null],
      receiver: [''],
      section: [''],
      accessFinance: ['']

    })
  }
  reset() {
    this.pageIndex = 0;
    this.cretaria.page = this.pageIndex;
    this.cretaria.size = this.pageSize;

    this.listItem = [];
  }
  private initCretaria() {
    for (let attr in this.cretaria) {
      if (!(attr === "page" || attr === "size")) {
        delete this.cretaria[attr];
      }
    }
  }
  changePageSize(value: number) {
    this.pageSize = value;
    this.cretaria.size = this.pageSize;
    this.getFilterByPagination(this.cretaria);

  }
  getFilterByPagination(cretaria) {
    this.key = "";
    this.freightService
      .getFilterByPagination(this.cretaria)
      .subscribe((data: any) => {
        this.listItem = data.content;
        this.total = data.totalElements;

      });
  }

  checkNullSearchField(data :any){
    let contr :number = 0;
    this.listIndicators.forEach((element:string) => {
      if(data[element] !== null && data[element] !== this.ExportflowSelected){
        contr ++;
        console.log('true',element);
      }
      this.checkAllNull = contr > 0;
      console.log('false',data[element]);
    })
  }
  changePositionLabel(){
    if(this.shippingMode === "Sea"){
      this.locationLabel ="Port";
    }
    if(this.shippingMode === "Air"){
      this.locationLabel ="Airport";
    }
    if(this.shippingMode === "Land"){
      this.locationLabel ="City";
    }
  }
  search() {
    console.log('this.originPositionStart',this.originPosition)
    console.log('this.originPositionStart',this.data['originCountry'])
    // let date = this.datePipe.transform(new Date(this.periodFrom), "yyyy-MM-dd");
    this.nbrSLiceData = 0;
    this.data.originCountry = this.selectedCountriesFrom && this.selectedCountriesFrom.label ? this.selectedCountriesFrom.label : null;
    this.data.destinationCountry =this.selectedCountriesTo && this.selectedCountriesTo.label ? this.selectedCountriesTo.label : null;
    this.data.shippingMode = this.shippingMode ? this.shippingMode : null;
    this.data.departureType = this.departure ? this.departure : null;
    this.data.arrivalType = this.Arrival ? this.Arrival : null;
    this.data.insurance = this.insured ? this.insured : null;
    this.data.insuranceRequired = this.insuredRequired ? this.insuredRequired : null;
    this.data.expectedShippingDate = this.shippingDate ? this.datePipe.transform(new Date(this.shippingDate), "yyyy-MM-dd") : null;
    this.data.periodFrom = this.periodFrom ? this.datePipe.transform(new Date(this.periodFrom), "yyyy-MM-dd") : null;
    this.data.periodTo = this.periodTo ? this.datePipe.transform(new Date(this.periodTo), "yyyy-MM-dd") : null;
    this.data.originPositionStart=this.originPosition != '' ? this.originPosition : null;
    this.data.destinationPositionFinal=this.destinationPosition != '' ? this.destinationPosition : null;
    this.data.freightActivityType=this.ExportflowSelected;
    this.checkNullSearchField(this.data);
    this.freightService.getFilterByPagination(this.data).subscribe((resp: any) => {
      this.listItem = resp.direct;
      this.listItemIndirect =resp.indirect;
      this.listItem2 = resp.direct;
      this.listAllItem = !this.checkAllNull ? resp.direct : []
      this.checkAllNull = this.listItemIndirect == null || this.listItemIndirect == undefined
      console.log('this.listItem :>> ', this.listAllItem);
      this.loading = false;
      if(this.data.originCountry !=null) {
        this.onSearch('From', this.selectedCountriesFrom);
      }else {
        this.onSearch('From', null);
      }
      if(this.data.destinationCountry != null){
        this.onSearch('To',this.selectedCountriesTo)
      }else{
        this.onSearch('To',null)
      }
      this.sliceData();
      this.sliceDataIndirect();
    })
    // if (data.originCountry == null && data.destinationCountry == null && data.shippingMode == null
    //   && data.periodFrom == null && data.periodTo == null && data.departureType == null && data.arrivalType == null
    //   && data.expectedShippingDate == null && data.insurance == null && data.insuranceRequired == null) {
    //   this.loading = false;
    // } else {
    //   this.loading = true;
    // }
  }
  loadFinanceResult(value) {
    this.freightService.freight = value;
  }
  showModal(item): void {
    this.reciver = item
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }
  onMessageStart = false
  goToLiveChat(data) {

    let object = {
      name: data.finance_provider,
      country: data.country,
      website: data.website,
      phone: data.contact_phone,
      flag: data.country_iso3
    }

    console.log("---------- object  --------- : ", object);
    console.log("current user ; ", this.user);

    this.liveChatService.getRoomId(object).subscribe((res: any) => {
      let roomId;
      console.log("room Id ", res);
      console.log("current user ; ", this.user);
      if (this.userId == res.userA.id) {
        roomId = res.id
      }

      // this.router.navigate(['/myProfile/livechat/'], { queryParams: { roomId: roomId } })
    })
  }
  sendMail(object,file,item) {
    this.mailService.senMail2(object,file).subscribe((data: any) => {
      item.isVisible1 = false;
      item.requestMeetvisible = false;
      this.isVisible = false;
      this.toaster.success('mail has been successfully sent');
    },(error) => {
      this.isVisible1 = false;
      this.requestMeetvisible = false;
      this.isVisible = false;
      this.toaster.error('mail has not been sent');
    });
  }
  submitFormRequestMeeting(value: any,item): void {
    for (const key in this.RequestMeetForm.controls) {
      this.RequestMeetForm.controls[key].markAsDirty();
      this.RequestMeetForm.controls[key].updateValueAndValidity();
    }
    console.log(value)
    if (this.RequestMeetForm.valid)
      this.sendMail(value,this.fileList3,item);
  }
  beforeUpload2 = (file: UploadFile): boolean => {
    this.fileList3 = this.fileList3.concat(file);
    return false;
  };
  cancelModel1(){
    this.isVisible1 = false;
    this.fileList3=[];
  }
  cancelModel2(){
    this.requestMeetvisible=false;
    this.fileList3=[];
  }
  getFlag(isoCode: any): boolean {
    if (isoCode != null) {
      this.allcountries.forEach((element) => {
        if (element.label.toLowerCase() == isoCode.toLowerCase()) {
          this.flagUrl = element.flag;
        }
      });
    } else {
      this.flagUrl = "";
      return false;
    }
    return true;
  }

  baseUrl = environment.baseUrl + "/company";

  getUrlImg(name) {
    return this.baseUrl + "/downloadImage/" + name+".jpg";
  }

  onImgError(event){
    event.target.src = '/assets/img/Mask_group.png'
    event.onerror = null;
  }


}
