import { Component, OnInit, Input, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { CustomStepperComponent } from "../../components/custom-stepper/custom-stepper.component";
import { SubscriptionService } from "../../../../commons/services/subscription/subscription.service";
import { first } from "rxjs/operators";
// import { AdminService } from '../../../../commons/services/dashboard-admin/admin.service';

@Component({
  selector: "app-b2b",
  templateUrl: "./b2b.component.html",
  styleUrls: ["./b2b.component.css"],
})
export class B2bComponent implements OnInit {
  form: FormGroup;
  contactPreference: FormGroup;
  contactPreference2: FormGroup;
  isCollapse = true;
  controlArray: Array<{ index: number; show: boolean }> = [];
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  SITE_KEY = "6LfJHKsZAAAAAIUhvIeSG-G2ogBwaNzaIDq50k9l";
  // SITE_KEY = "6Ld35ScjAAAAACSr7-FItAHkiaKMth3jzacj8R52";
  size: number;
  large: any;
  subscription: any = {};

  token: any;

  email: any;
  id: any;

  handleCoupon: any = {};
  handleRequest: any = {};

  costumSteps: any[] = [
    { stepLabel: "Identification", stepIndex: [0] },
    { stepLabel: "Contact and Perferences", stepIndex: [1, 2] },
    { stepLabel: "Pricing", stepIndex: [3] },
    { stepLabel: "Payment", stepIndex: [4] },
  ];
  submitted = false;

  @Input()
  isEditable = true;
  @Input()
  isLinear = true;

  _fristName: string = "";

  idSubscription: any;

  promoCode: any;
  eVoucher: any;
  giftCode: any;

  constructor(
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService // private adminService:AdminService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      confirmEmail: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.required,
          this.confirmationEmail,
        ],
      ],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required, this.confirmationPassword]],
      phone: ["0656327794"],
      country: [null, [Validators.required]],
      businessRegistrationNumber: [null, [Validators.required]],
      organizationType: [null, [Validators.required]],
    });

    this.contactPreference = this.fb.group({
      interestingMarkets: [],
      interestingProductsAndServices: [],
      contactAfreximbankActivitiesEvents: [],
      contactProvideInfoRelatedToAreasInterest: [],
      contactProvideInfoRelatedToServicesAndOffers: [],
      contactByEmail: [],
      contactByWhatsapp: [],
      contactByTextSms: [],
    });

    this.contactPreference2 = this.fb.group({
      b2bMemberType: [],
      businessNature: [],
      countriesOperated: [["Morocco"]],
      employeeSize: [],
      devise: [],
      annualTurnover: [],
      businessSectors: [],
      mansaId: [],
      businessRegistrationCertificate: [],
    });
  }

  onSubmit() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    this.subscription.firstName = this.form.value.firstName;
    this.subscription.lastName = this.form.value.lastName;
    this.subscription.email = this.form.value.email;
    this.subscription.password = this.form.value.password;
    this.subscription.phone = "0665321723";
    this.subscription.country = this.form.value.country;
    this.subscription.businessRegistrationNumber =
      this.form.value.businessRegistrationNumber;
    this.subscription.organizationType = this.form.value.organizationType;
    this.subscription.subscriptionType = "B2B";
    console.log(this.subscription);
  }

  onSubmitContactPreference() {
    console.log(this.idSubscription);
    console.log(this.contactPreference.value);
    this.subscription.interestingMarkets =
      this.contactPreference.value.interestingMarkets;
    this.subscription.interestingProductsAndServices =
      this.contactPreference.value.interestingProductsAndServices;
    this.subscription.contactAfreximbankActivitiesEvents =
      this.contactPreference.value.contactAfreximbankActivitiesEvents;
    this.subscription.contactProvideInfoRelatedToAreasInterest =
      this.contactPreference.value.contactProvideInfoRelatedToAreasInterest;
    this.subscription.contactProvideInfoRelatedToServicesAndOffers =
      this.contactPreference.value.contactProvideInfoRelatedToServicesAndOffers;
    this.subscription.contactByEmail =
      this.contactPreference.value.contactByEmail;
    this.subscription.contactByWhatsapp =
      this.contactPreference.value.contactByWhatsapp;
    this.subscription.contactByTextSms =
      this.contactPreference.value.contactByTextSms;
    console.log(this.subscription);
  }

  onSubmitContactPreference2() {
    console.log(this.contactPreference2.value);
    this.subscription.b2bMemberType =
      this.contactPreference2.value.b2bMemberType;
    this.subscription.businessNature =
      this.contactPreference2.value.businessNature;
    this.subscription.countriesOperated =
      this.contactPreference2.value.countriesOperated;
    this.subscription.employeeSize = this.contactPreference2.value.employeeSize;
    this.subscription.devise = this.contactPreference2.value.devise;
    this.subscription.annualTurnover =
      this.contactPreference2.value.annualTurnover;
    this.subscription.businessSectors =
      this.contactPreference2.value.businessSectors;
    this.subscription.mansaId = this.contactPreference2.value.mansaId;
    this.subscription.businessRegistrationCertificate =
      this.contactPreference2.value.businessRegistrationCertificate;

    this.subscriptionService
      .create(this.subscription)
      .subscribe((data: any) => {
        this.token = data.token;
      });
  }
  getPrice(value) {
    this.handleCoupon.cost = value.cost;
    this.handleRequest.pricingPlanId = value.id;
  }
  validate() {
    this.handleCoupon.email = this.form.value.email;
    console.log(this.handleCoupon.email);
    this.handleCoupon.cost = 300;
    // this.adminService.validateCoupon(this.handleCoupon,this.token).subscribe((data)=>{
    //   console.log(data);
    // });
  }

  toggleCollapse(): void {
    this.isCollapse = !this.isCollapse;
    this.controlArray.forEach((c, index) => {
      c.show = this.isCollapse ? index < 6 : true;
    });
  }

  resetForm(): void {
    this.form.reset();
  }

  changePreferredCountries() {
    this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
      CountryISO.Morocco,
    ];
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
  }

  get f() {
    return this.form.controls;
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.form.controls.confirmPassword.updateValueAndValidity()
    );
  }

  confirmationPassword = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.form.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  updateConfirmEmail(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.form.controls.confirmEmail.updateValueAndValidity()
    );
  }
  confirmationEmail = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.form.controls.email.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
}
