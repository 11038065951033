import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AuthProvider } from 'src/app/commons/services/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  constructor(
    
  ) {
    
   }

  ngOnInit() {
  }

}
