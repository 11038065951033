import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UploadFile } from "ng-zorro-antd";
import { ToastrService } from "ngx-toastr";
import { MailService } from "src/app/commons/services/mail service/mail.service";
import { ProfileService } from "src/app/commons/services/profil service/profile.service";
import { ProfileRouteService } from "src/app/commons/services/profiles-route/profile-route.service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { UserService } from "src/app/commons/services/user.service";
import { SharedService } from "src/app/commons/shared service/shared-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-interests",
  templateUrl: "./interests.component.html",
  styleUrls: ["./interests.component.css"],
})
export class InterestsComponent implements OnInit {
  public prods;
  public services;
  public comps;
  public buyLeads;
  public FreightLeads;
  baseUrl = environment.baseUrl + "/";
  public isVisible: boolean = false;
  public isVisible1: boolean = false;
  public isVisible2: boolean = false;
  public selectedProduct;
  public selectedService;
  public role;
  public companyName;
  effect = "scrollx";
  public listEmplty = true;
  public messageToSend = "";
  isAllCountry = true;
  isAllCompany = true;
  agree = false;
  public reciver = null;
  public fileList: UploadFile[] = [];
  constructor(
    private b2bProductService: B2bProductService,
    private router: Router,
    private userService: UserService,
    private mailService: MailService,
    private toaster: ToastrService,
    private profileService: ProfileRouteService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.profileService.changeRoute("interests");
    this.getUser();
    this.getInterests();
  }
  getInterests() {
    this.b2bProductService.getInterested().subscribe((resp: any) => {
      this.prods = resp.productList;
      console.log("proooods", this.prods);
      this.services = resp.serviceList;
      console.log("services", this.services);
      this.comps = resp.subscriptionList;
      console.log("Companies", this.comps);
      this.buyLeads = resp.postBuyRequestList;
      this.FreightLeads = resp.postFreightRequestList;
      console.log(resp);
    });
  }
  getByID(id) {
    this.sharedService.nextSearchProductByID(id);
    this.router.navigate(["/buy-request/product"]);
  }
  showModal2(item): void {
    this.reciver = item;
    this.isVisible2 = true;
  }

  showModal1(item): void {
    this.selectedService = item;
    this.isVisible1 = true;
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data = this.ValidateFile(file);
      if (!data) {
        this.fileList = [];
        this.listEmplty = true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file) {
    return true;
  }

  goToLiveChat(item) {
    console.log("---------- item  --------- : ", item);
    this.router.navigate(["/profil/liveChat/"], {
      queryParams: { email: item },
    });
  }
  showModal(item): void {
    this.selectedProduct = item;
    this.isVisible = true;
  }
  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = false;
    this.isVisible1 = false;
    this.isVisible2 = false;
  }
  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
    this.isVisible1 = false;
    this.isVisible2 = false;
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log("user", res);
        this.companyName = res.subscription.companyName;
        this.role = res.role;
      });
    }
  }
  removeFrominterProd(item) {
    let type = "product";
    this.b2bProductService
      .removeInterest(item.id, type)
      .subscribe((resp: any) => {
        this.toaster.info(
          item.productName + " has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.getInterests();
      });
  }
  removeFrominterserv(item) {
    let type = "service";
    this.b2bProductService
      .removeInterest(item.id, type)
      .subscribe((resp: any) => {
        this.toaster.info(
          item.name + " has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.getInterests();
      });
  }
  removeFromintercomp(item) {
    let type = "subscription";
    this.b2bProductService
      .removeInterest(item.id, type)
      .subscribe((resp: any) => {
        this.toaster.info(
          item.companyName + " has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.getInterests();
      });
  }
  removeFrominterBuyLeads(item) {
    let type = "postBuyRequest";
    this.b2bProductService
      .removeInterest(item.id, type)
      .subscribe((resp: any) => {
        this.toaster.info(
          "This buy lead has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.getInterests();
      });
  }
  startOrder(item) {
    this.router.navigate(["/order/"], { queryParams: { product: item.id } });
  }
  sendMail() {
    console.log("item : ", this.selectedProduct);

    let data = {
      receiver: this.selectedProduct.user.email,
      message: this.messageToSend,
      subject: this.selectedProduct.productName,
      productId: this.selectedProduct.id,
      isBuyLeadInquiry: false,
      isWebInquiry: true,
      isAllCountry: this.isAllCountry,
      isAllCompany: this.isAllCompany,
      location: this.selectedProduct.user.country,

      //  subject: this.subject,
    };
    console.log();

    this.mailService.sendMessage(data).subscribe((resp) => {
      console.log("resap : ", resp);
      this.messageToSend = "";
      this.toaster.success("The inquiry has been sent succefully", "Success", {
        closeButton: true,
        timeOut: 5000,
      });
      this.isVisible = false;
    });
  }
  sendMail1() {
    let data = {
      receiver: this.selectedService.user.email,
      message: this.messageToSend,
      subject: this.selectedService.productName,
      productId: this.selectedService.id,
      isBuyLeadInquiry: false,
      isWebInquiry: true,
      isAllCountry: this.isAllCountry,
      isAllCompany: this.isAllCompany,
      location: this.selectedService.user.country,

      //  subject: this.subject,
    };
    console.log();

    this.mailService.sendMessage(data).subscribe((resp) => {
      this.messageToSend = "";
      console.log("resap : ", resp);
      this.toaster.success("The inquiry has been sent succefully", "Success", {
        closeButton: true,
        timeOut: 5000,
      });
      this.isVisible1 = false;
    });
  }
  log(item) {
    this.messageToSend = item;
  }
  sendMail2(item) {
    let data = {
      receiver: item.email,
      message: this.messageToSend,
      subject: item.companyName,
      isBuyLeadInquiry: false,
      isWebInquiry: true,
      isAllCountry: false,
      isAllCompany: false,
      location: item.country,
    };

    console.log("datatat", item);
    console.log("datatat", data);

    this.mailService.sendMessage(data).subscribe(
      (resp) => {
        this.messageToSend = "";
        console.log("resap : ", resp);
        this.toaster.success(
          "The inquiry has been sent succefully",
          "Success",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
        this.isVisible2 = false;
      },
      (err) => {}
    );
  }
}
