import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SourceService } from 'src/app/commons/services/source-service/source.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-source-suppliers-manufactures',
  templateUrl: './source-suppliers-manufactures.component.html',
  styleUrls: ['./source-suppliers-manufactures.component.css']
})
export class SourceSuppliersManufacturesComponent implements OnInit {

  array = [1, 2, 3, 4,5,6,7];
  public importerId=1;
  public importer;
  public loading;
  public listSubCategories = [];
  public selectedCategory2 = [];
  public selectedSubCategory = null;
  public countryList=[];
  public listCategories=[];
  public selectedCategory = null;
  public categoriesList = [];
  public nbrSlice = 0;
  public companyCount = 0;
  public listfilterCategorie = [];
  public listfilterSubCategorie = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public listCompany = [];
  public listProducts = [];
  public selectedSearch='products';
  public motCle:string=null;
  public reciver   = null;
  public isVisible   = false
  public messageToSend = "";
  public companyName;
  agree = false;
  loadin=false;
  public formSearch = new FormGroup({});

  public baseUrl =environment.baseUrl+'/file/aws/download/';
  imgUrl = environment.baseUrl+'/';
  constructor(private sourceService: SourceService, private router:Router,
              private b2bserviceService: B2bProductService,private mailService: MailService,
              private route: ActivatedRoute,private fb: FormBuilder,private toaster: ToastrService,private userService: UserService
  ) { }

  ngOnInit() {
    this.getUser();
    this.getCategories();
    this.search() ;
   ;
    this.createForm();
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.companyName=res.subscription.companyName;
      })
  }
  }
  createForm() {
    this.formSearch = this.fb.group({
      type: [''],
      name: ['']
    });
  }
  search(){
    if (this.selectedSearch==='products') {
      this.searchProduct();
    }
    else{
      this.searchCompany();

    }
  }

  getSubCategories(event) {
    console.log("=============>",this.selectedCategory);
    console.log(event);
    if(event!=null){
      if(event=="All"){
        this.loading=true;
          this.b2bserviceService.AllSubs().subscribe((resp: any) => {
            this.listSubCategories = resp
        this.loading=false;
          })

      }
      else{
    this.selectedCategory2 = event.id
    this.listSubCategories = event.subCategories
    this.addCategorie(event.name);
  }
}
  else{
    this.listSubCategories=[];
    this.listfilterCategorie=[];
    this.search();
  }
  }
  showModal(item): void {
    this.reciver= item
    this.isVisible = true;
  }
  searchProduct(){
    this.loading=true;
    let data ={
      product:this.motCle,
      categories:this.listfilterCategorie,
      subCategories:this.listfilterSubCategorie,
      countries:this.listfiltercountry
    }
    console.log(data);

    this.sourceService.searchCompany1(data).subscribe((resp: any) => {

    this.loading=false;
      console.log("categoriesList",resp);
      this.countryList=resp.numberByCountries;
      this.listCompany=resp.productCompany;
      this.listProducts=resp.productCompany.productDTOList;
      this.companyCount=this.listCompany.length
      console.log("=======>====",this.listCompany)
    })
  }
  searchCompany(){
    this.loading=true;
    let data ={
      company:this.motCle,
      categories:this.listfilterCategorie,
      subCategories:this.listfilterSubCategorie,
      countries:this.listfiltercountry
    }
    console.log(data);

    this.sourceService.searchCompany1(data).subscribe((resp: any) => {
      console.log("categoriesList",resp);
      this.loading=false;
      this.countryList=resp.numberByCountries;
      this.listCompany=resp.productCompany;
      this.companyCount=this.listCompany.length
    })
  }
  clear(){
    this.motCle ="";
    this.listfilterCategorie =[];
    this.selectedCategory =null;
    this.selectedSubCategory =null;
    this.listfilterSubCategorie =[];
    this.listfiltercountry =[];
    this.listSubCategories =[];
    this.selectedSearch='products';
    this.searchProduct();
  }
  getCategories() {
    this.sourceService.getCategoriesAll().subscribe((resp: any) => {
      this.categoriesList = resp
      console.log("categoriesList");
      console.log("categoriesList",this.categoriesList);

       this.sliceCategorie();
    })

  }
  checkExist(id) {
    if (this.listfilterCategorie.includes(id)) {
      return true
    } else {
      return false
    }
  }
  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
  }
  getAllCountries() {
    this.b2bserviceService.getCountries().subscribe((resp: any) => {
      this.countryList = resp
      console.log(this.countryList);

    })
  }
  getImporter() {
    this.loading = true;
    this.sourceService.getBySeller(this.importerId).subscribe((resp: any) => {

      console.log("response : ", resp);

      this.loading = false;
      this.importer = resp
      // this.sliceCategorie();
    })
  }
  addCategorie(idCatg) {
    this.listfilterCategorie=[];
    this.listfilterCategorie.push(idCatg);
     this.search();
    console.log(this.listfilterCategorie);
  }
  addSubCategorie(idCatg) {
    this.listfilterSubCategorie=[];
    this.listfilterSubCategorie.push(idCatg.name);
     this.search();
    console.log(this.listfilterSubCategorie);
  }

    // Country
    addCountry(idCountry,item) {
      if (!this.listfiltercountry.includes(idCountry)) {
        this.listfiltercountry.push(idCountry);
        this.listShowcountry.push(item)
        console.log(this.listfiltercountry);
      }
      this.search();
      console.log(this.listfiltercountry);
    }
    deleteCountry(idCountry,item){
        let index: number = this.listfiltercountry.indexOf(idCountry);
        let index2: number = this.listShowcountry.indexOf(item);
        if (index !== -1) {
          this.listfiltercountry.splice(index, 1);
      }
      if (index2 !== -1) {
        this.listShowcountry.splice(index2, 1);
    }
      this.search();
      console.log(this.listfiltercountry);
    }
    checkExistCountry(id) {
      if (this.listfiltercountry.includes(id)) {
        return true
      } else {
        return false
      }
    }

log(item){
  this.messageToSend=item;
}
addTointer(importer){
  let type = "subscription"
  this.b2bserviceService.addToInterest(importer.id,type).subscribe((resp: any) => {
    importer.isInterested =true;
    this.toaster.success(
      importer.companyName+" has been added to your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
  })
}
removeFrominter(importer){
  let type = "subscription"
  this.b2bserviceService.removeInterest(importer.id,type).subscribe((resp: any) => {
    importer.isInterested =false;
    this.toaster.info(
      importer.companyName+" has been removed from your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
  })
}

sendMail(item) {

  let data = {
   receiver:item.subscriptionDTO.email,
   message: this.messageToSend,
   subject: item.subscriptionDTO.companyName,
   isBuyLeadInquiry: false,
   isWebInquiry: true ,
   isAllCountry: false ,
   isAllCompany: false ,
   location: item.subscriptionDTO.country,
  }

  console.log("datatat",item);
  console.log("datatat",data);

  this.mailService.sendMessage(data).subscribe(resp => {
    console.log("resap : " , resp);
    this.toaster.success(
      "The inquiry has been sent succefully",
      "Success",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.isVisible = false;
  },(err) => {
  })

 }

 handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isVisible = false;
}
goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}
}
