import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = environment.baseUrl+"/orders";


  constructor(private http: HttpClient) { }
    getOrdersHystorySeller(data) {
      return this.http.post(this.baseUrl + '/sellerOrders',data);
    }

    getOrdersHistoryBuyer(data) {
      return this.http.post(this.baseUrl + '/buyerOrders',data);
    }

    searchFreight(body:any){
      return this.http.post(this.baseUrl,body );
    }
    getcountries(){
      return this.http.get(this.baseUrl);
    }
    getAllInsurance(body:any){
      return this.http.post(this.baseUrl+'/insurance',body);
    }
    countriesInsurance(){
      return this.http.get(this.baseUrl+'/insuranceCountries');
    }
      getFreightCompanyById(id) {
     return this.http.get(this.baseUrl + '/'+id);
     }

     getOrderById(id) {
      return this.http.get(this.baseUrl + '/'+id);
      }

      CancelOrder(id) {
        return this.http.get(this.baseUrl + '/cancel_order/'+id);
        }

      changeShippingPrice(orderId,shippingPrice) {
        let formData = new FormData();
        formData.append("shippingPrice",shippingPrice);
        return this.http.put(this.baseUrl + '/change_shipping_price/'+orderId,formData);
      
    }

    changeShippingDetails(orderId,shppingDetails) {
      return this.http.put(this.baseUrl + '/shipping_info/'+orderId,shppingDetails);
    }

    changeStatus(orderid , status) {
      // status_update/{orderId}?status=Delivred
      let formData = new FormData();
      formData.append("status", status);
      return this.http.put(this.baseUrl + '/status_update/'+orderid, formData);
      }

    getOrdersCounts() {
      return this.http.get(this.baseUrl + '/getOrdersCounts');
    }

}
