import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable()
export class AuthProvider {
  private loggedIn: boolean = false;

  constructor(
    private readonly router: Router,
    private userService: UserService
  ) {
    this.checkAuthentication();
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  signIn(
    userId: string,
    token: string,
    role: string,
    refreshToken: string
  ): void {
    console.log("sign in called");
    localStorage.setItem(environment.TOKEN_NAME, token);
    localStorage.setItem(environment.REFRESH_TOKEN_NAME, refreshToken);
    localStorage.setItem(environment.ROLE, role);
    localStorage.setItem("USER_ID", userId);
    this.loggedIn = true;
  }

  // getInfoUser(){
  //  let id = localStorage.getItem("USER_ID");
  //  console.log(id);
  //  let data
  //  this.userService.getAuthUser().subscribe(
  //   (res: any) => {
  //     data= res;
  //     console.log(data);
  //     return data
  //   })
  // }

  signOut(): void {
    localStorage.removeItem(environment.TOKEN_NAME);
    localStorage.removeItem(environment.REFRESH_TOKEN_NAME);
    localStorage.removeItem(environment.ROLE);
    localStorage.removeItem("USER_ID");
    this.loggedIn = false;
    // this.router.navigate(['']);
  }

  checkAuthentication(): void {
    const token: string = localStorage.getItem(environment.TOKEN_NAME);
    const refreshToken: string = localStorage.getItem(environment.REFRESH_TOKEN_NAME);
    const role: string = localStorage.getItem(environment.ROLE);
    const userId: string = localStorage.getItem("USER_ID");
    token ? this.signIn(userId, token, role, refreshToken) : this.signOut();
  }
  redirectOut(): void {
    localStorage.removeItem(environment.TOKEN_NAME);
    localStorage.clear();
    this.loggedIn = false;
    this.userService.currentUser = null;
    this.userService.userPermissions = null;
    this.router.navigate(["/"]);
  }
}
