import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
@Component({
  selector: 'app-financing-opportunities-details',
  templateUrl: './financing-opportunities-details.component.html',
  styleUrls: ['./financing-opportunities-details.component.css']
})
export class FinancingOpportunitiesDetailsComponent implements OnInit {
  itemResult:any;
  constructor(private financeService :FinanceService) { }

  ngOnInit() {
    this.itemResult =this.financeService.item;
    console.log("detail info :",this.itemResult);
  }

}
