import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileRouteService {
  private route = new BehaviorSubject("/");

  sharedRoute = this.route.asObservable();
  constructor() { }

  changeRoute(route: string) {
    this.route.next(route)
  }
}
