import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-freight-company-by-id',
  templateUrl: './freight-company-by-id.component.html',
  styleUrls: ['./freight-company-by-id.component.css']
})
export class FreightCompanyByIdComponent implements OnInit {

  public companyInfo:any;
  public id;
  isVisible=false
  effect = 'scrollx';
  public baseUrl =environment.baseUrl+"/";
  constructor(private freightService: FreightService,
    private route : ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.id = data.id;
      this.getByID(this.id);
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
}
handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isVisible = false;
}
showModal(): void {
  this.isVisible = true;
}
  getByID(id){
    this.freightService.getFreightCompanyById(id).subscribe((resp: any) => {
      console.log(resp);
       this.companyInfo=resp;
    },
    (err) => {
      console.log(err);
    })
  }

}
