import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SubscriptionService } from 'src/app/commons/services/subscription/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trash-inquiry',
  templateUrl: './trash-inquiry.component.html',
  styleUrls: ['./trash-inquiry.component.css']
})
export class TrashInquiryComponent implements OnInit {
  baseUrl = environment.baseUrl+"/";
  prodImg;
  public fileList: UploadFile[] = [];
  public listEmplty = true;
  public messageId= 1;
  public productId= 1;
  public recieverId= 1;
  public senderId= 1;
  public product;
  public message;
  public subscription;
  sender: any;
  reciever: any;
  replyMessage: '';
  subCategory: string = '';

  constructor(private msg: NzMessageService,
              private B2bProductService: B2bProductService,
              private mailService: MailService,
              private route : ActivatedRoute,
              private subscriptionService : SubscriptionService,
              private router : Router) { }

  ngOnInit() {
    // this.getProductById();
    this.route.params.subscribe(data => {
      this.messageId = data.id
      this.getMessageById();
    });
    console.log(this.isSent());
    
  }

  getProductById() {
    this.B2bProductService.getProductInfobyId(this.productId).subscribe((resp : any) =>{
      console.log("response : " , resp);
      this.product = resp.productDTO;
      this.subscription = resp.subscriptionDTO
      // this.product.categories.forEach(category => {
        
      // });

      let subCategory = [];
      subCategory = this.product.categories.subCategories.filter(subCategory => subCategory.id == this.product.subCategory);
      this.subCategory = subCategory[0].name;
    })
  }

  getRecieverById() {
    console.log(this.recieverId);
    
    this.subscriptionService.getById(this.recieverId).subscribe((Response : any)=> {
        console.log("reciver : " , Response);
        this.reciever = Response;
    }) 
  }

  getSenderById() {
    this.subscriptionService.getById(this.senderId).subscribe((Response : any)=> {
      console.log("sender : " , Response);
      this.sender = Response;
  }) 
  }

  getMessageById() {
    this.mailService.getMessageById(this.messageId).subscribe((resp :any) =>{
      console.log("response : " , resp);
      // this.product = resp;
      this.senderId = resp.senderSubscriptionId;
      this.recieverId = resp.receiverSubscriptionId;
      this.getSenderById();
      this.getRecieverById();


      this.message = resp;
      this.productId = this.message.productId;
      this.getProductById();

    })
  }

  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
 
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };

  ValidateFile(file){
   return true;
 }

 reply() {
  let data = {
    receiver:this.sender.email,
    message: this.replyMessage,
    subject: this.product.productName,
    productId:this.product.id,
    isBuyLeadInquiry: false,
    isWebInquiry: true ,
    location: this.product.user.country,
    emailRoomId : this.message.emailRoomId
   }
  this.mailService.sendMessage(data).subscribe((res:any) => {
      console.log(res);
  })   
 }

 isSent() {
  return this.router.url.includes("/sent");
}

}
