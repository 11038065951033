import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UploadFile } from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { TradeValueChainService } from 'src/app/commons/services/service tradeValueChain/trade-value-chain.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-serviceby-id',
  templateUrl: './serviceby-id.component.html',
  styleUrls: ['./serviceby-id.component.css']
})
export class ServicebyIdComponent implements OnInit {

  public serviceId;
  public serviceInfo;
  public companyInfo;
  prodImg;
  isVisible2 = false;
  isVisible1 = false;
  Zoomimage;
  public companyName;
  baseUrl = environment.baseUrl+'/';
  effect = 'scrollx';
  isVisible = false;
  public messageToSend = "";
  public fileList: UploadFile[] = [];
  public listEmplty = true;
  isAllCountry =true;
  isAllCompany =true;
  intrestsProds =[];
  interested ;
  constructor(  private route : ActivatedRoute,private servService:TradeValueChainService,private userService: UserService,
    private mailService: MailService,private b2bProductService : B2bProductService,
    private toaster : ToastrService) { }

  ngOnInit() {
    this.getUser();
    this.route.params.subscribe(data => {
      this.serviceId = data.id;
      console.log('serviceId::::::',this.serviceId)
      this.getServiceInfobyId();
    });
  }
  sendMail() {

    let data = {
     receiver:this.serviceInfo.user.email,
     message: this.messageToSend,
     subject: this.serviceInfo.name,
     productId:this.serviceInfo.id,
     isBuyLeadInquiry: false,
     isWebInquiry: true ,
     location: this.serviceInfo.user.country,
     isAllCountry : this.isAllCountry,
     isAllCompany : this.isAllCompany

    //  subject: this.subject,
    }

    this.mailService.sendMessage(data).subscribe(resp => {
      console.log("resap : " , resp);

        this.isVisible = false;
        this.isVisible1 = false;
    },(err) => {

    })

   }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
    return true;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.isVisible2 = false;
    this.isVisible1 = false;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }
  showModal(): void {
    this.isVisible = true;
  }
  showModal1(): void {
    this.isVisible1 = true;
  }

  getServiceInfobyId() {
    this.servService.getById(this.serviceId).subscribe((resp:any)=> {
      this.serviceInfo = resp;
      this.prodImg = this.serviceInfo.images[0];
      this.companyInfo = this.serviceInfo.user.subscription;
      console.log(resp);

    })
  }
  getUser() {

    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log(res);
        this.companyName=res.subscription.companyName;
         this.intrestsProds= res.subscription.interestServiceIds
        let prod =res.subscription.interestServiceIds.find(x => x === this.serviceId.toString())
        console.log("intererere",prod)
         if(prod==null||prod==undefined){
           this.interested = false
         }
         else {
           this.interested = true
         }
      })
  }
}
Zoom(img){
  this.isVisible2=true;
  this.Zoomimage =img
}
addTointer(){
  let type = "service"
  this.b2bProductService.addToInterest(this.serviceId,type).subscribe((resp: any) => {
    this.toaster.success(
      this.serviceInfo.name+" has been added to your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.interested=true;
  })
}
removeFrominter(){
  let type = "service"
  this.b2bProductService.removeInterest(this.serviceId,type).subscribe((resp: any) => {
    this.toaster.info(
      this.serviceInfo.name+" has been removed from your interests",
      "",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.interested=false;
  })
}
verifyInterest(){

  console.log("intererere",this.intrestsProds)
  let prod =this.intrestsProds.find(x => x === this.serviceId.toString())
  console.log("intererere",prod)
   if(prod==null||prod==undefined){
     this.interested = false
   }
   else {
     this.interested = true
   }

  console.log("intererere",this.interested)
}
}
