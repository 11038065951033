import { Injectable } from "@angular/core";
import { KeyFeature } from "src/app/commons/models/KeyFeature";
import { UserService } from "src/app/commons/services/user.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  roles: string;

  constructor(private userService: UserService) {}

  /**
   * A function that checks user permissions and returns a boolean only if the application user has role `USER` else it will allow access by default,
   * this access might be controlled somewhere else for other users using the structural directive *ngIf
   * @param permissions array of key features used as permission to give access to application user with role `USER`
   * @returns a boolean wether the user is allowed to access application features
   */
  // checkPermission(permissions: KeyFeature[]): boolean {
  //   console.log("this.userService.currentUser", this.userService.currentUser);
  //   this.roles =
  //     this.userService.currentUser && this.userService.currentUser.role;
  //   console.log("roleeees", this.roles);
  //   let _switch: boolean = false;

  //   if (!this.roles.includes("ROLE_ADMIN")) {
  //     if (permissions) {
  //       this.userService.userPermissions.map((permission) => {
  //         if (permissions.includes(permission)) _switch = true;
  //       });
  //     }
  //     return _switch;
  //   }
  //   return true;
  // }
  checkPermission(permissions: KeyFeature[]): boolean {
    // return true;
    this.roles = '';
    this.roles =
      this.userService.currentUser && this.userService.currentUser.role;
    let _switch: boolean = false;
    let arrRoles =["ROLE_SELLER","ROLE_BUYER","ROLE_BOTH"]

    // console.log("this.roles1",this.roles)
    if (this.roles && arrRoles.includes(this.roles)) {
      // console.log("this.roles",this.roles)
      console.log("permission99",permissions)
      console.log("permission991",this.userService.userPermissions);
      if (permissions) {
        this.userService.userPermissions.map((permission) => {
          // console.log("permission1",permission)
          // console.log("permission1",permissions)
          if (permissions.includes(permission)) _switch = true;
          // console.log("permission1",permission)
        });
      }

      return _switch;
    }
    return true;
  }

  /**
   *
   * @param permissions array of key features used as permission to give access to advisory for application users with role `USER`
   * @returns an array of key features used to give check access for sub-features for the `ADVISORY` module,
   * OR `true` if the application user does have another role than `USER`
   */
  // checkPermissionsAdvisory(permissions: KeyFeature[]): KeyFeature[] | true {
  //   this.roles =
  //     this.userService.currentUser && this.userService.currentUser.role;

  //   if (this.roles.indexOf("ROLE_USER") > -1) {
  //     let returnedPermissions = [...permissions];
  //     permissions.map((permission) => {
  //       if (!this.userService.userPermissions.includes(permission)) {
  //         returnedPermissions = returnedPermissions.filter(
  //           (returnedPermission) => returnedPermission !== permission
  //         );
  //       }
  //     });

  //     return returnedPermissions;
  //   }

  //   return true;
  // }
}
