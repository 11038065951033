import { Component, OnInit } from "@angular/core";
import { MailService } from "src/app/commons/services/mail service/mail.service";
import { ProfileRouteService } from "src/app/commons/services/profiles-route/profile-route.service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { UserService } from "src/app/commons/services/user.service";
import { SharedService } from "src/app/commons/shared service/shared-service";
import { environment } from "src/environments/environment";

interface ItemData {
  sender: string;
  Requirement: string;
  Location: string;
  date: Date;
}

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  isSeller: Boolean = true;
  isCollapsed = false;
  public listCategories: any = [];
  public radioValueType = "product";
  checked = false;
  show1 = false;
  show2 = false;
  show3 = false;
  show4 = false;
  show5 = false;
  show6 = false;
  show7 = false;
  show8 = false;
  show9 = false;
  show10 = false;
  show11 = false;
  show12 = false;
  isVisible = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfAllData: ItemData[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  listOfName = [
    { text: "Web Inquiry", value: "" },
    { text: "Buy Lead Inquiry", value: "" },
    { text: "Chat", value: "" },
  ];
  listOfData: Array<{
    sender: string;
    Requirement: string;
    Location: string;
    date: Date;
    [key: string]: string | number | Date;
  }> = [
    {
      sender: "John Brown",
      Requirement: "inquiry through www.google.com",
      date: new Date("February 4, 2016 10:13:00"),
      Location: "casa",
    },
    {
      sender: "John Brown",
      Requirement: "inquiry through www.google.com",
      date: new Date("February 4, 2016 10:13:00"),
      Location: "casa",
    },
    {
      sender: "John Brown",
      Requirement: "inquiry through www.google.com",
      date: new Date("February 4, 2016 10:13:00"),
      Location: "casa",
    },
    {
      sender: "John Brown",
      Requirement: "inquiry through www.google.com",
      date: new Date("February 4, 2016 10:13:00"),
      Location: "casa",
    },
  ];
  countMessages;
  public company: any;
  public user: any = null;
  public baseUrl = environment.baseUrl + "/";
  currentRoute = "personal";

  currentUser: any;

  imgUrl = environment.baseUrl + "/file/aws/download/";
  constructor(
    private b2bserviceService: B2bProductService,
    private userService: UserService,
    private sharedService: SharedService,
    private mailService: MailService,
    private profileRouteService: ProfileRouteService
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.currentUser;
    console.log("currentUSER", this.currentUser);
    this.getProfileRoute();
    this.getCategories(this.radioValueType);
    this.getUser();
    this.getEventClick();
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");

    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        this.company = res.subscription;
        this.company.personalPhoto = res.subscription.personalPhoto;
        // this.company.companyPhoto = res.subscription.personalPhoto;
        // console.log("companytestphoto", this.company);
        // console.log("testphoto", this.company.personalPhoto);
        this.user = res;

        console.log("response1", res);
        if (this.user.role == "ROLE_BOTH" || this.user.role == "ROLE_SELLER") {
          this.isSeller = true;
          console.log("response2", res);
        } else if (this.user.role == "ROLE_BUYER") {
          this.isSeller = false;
          console.log("response3", res);
        }

        this.getCountEmails();
      });
    }
  }
  getEventClick() {
    this.sharedService.sharedClick.subscribe((resp: any) => {
      this.getUser();
    });
  }

  getCountEmails() {
    this.mailService.getCountMessages().subscribe((count) => {
      console.log("getCountEmails : ", count);
      this.countMessages = count;
    });
  }

  show() {
    this.show1 = true;
  }
  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }
  getProfileRoute() {
    this.profileRouteService.sharedRoute.subscribe((currentRoute) => {
      console.log("current Route : ", currentRoute);
      this.currentRoute = currentRoute;
    });
  }
  getTypeService(event) {
    console.log("event", event);
    console.log("type", this.radioValueType);
    this.getCategories(event);
  }
  getCategories(radioValueType) {
    this.b2bserviceService
      .getCategories(radioValueType)
      .subscribe((resp: any) => {
        console.log(resp);
        this.listCategories = resp;
      });
  }

  routeMatches(routes) {
    return routes.includes(this.currentRoute);
  }
}
