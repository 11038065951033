import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SourceService } from 'src/app/commons/services/source-service/source.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-suppliers',
  templateUrl: './search-suppliers.component.html',
  styleUrls: ['./search-suppliers.component.css']
})
export class SearchSuppliersComponent implements OnInit {
  url = environment.baseUrl+'/';
  isVisible = false;
  public messages: string = null;
  public categoriesList = [];
  public certificationList = [];
  public countryList = [];
  public productDTOList = [];
  public productDTOShow = [];
  public productCount: number = 0;
  public MinOrder: number = null;
  public minPrice: number = null;
  public maxPrice: number = null;

  public isActive = false;
  public index = null;
  public listfilterCategorie = [];
  public listCategories = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public loading: boolean = false;
  public supplier;
  public countries;

  public supplierId;
  public nbrSlice = 0;



  constructor(private sourceService: SourceService,
              private b2bserviceService: B2bProductService,
              private route: ActivatedRoute,
          ) {

           }

  ngOnInit() {
    // this.getCategories();
    this.route.params.subscribe(data => {
      this.supplierId = data.id;
      this.search();
      this.getCompanyContries();
    });
  }


  search() {
    console.log("list categorie ! " , this.listfilterCategorie);

    let data = {
      category:  this.listfilterCategorie,
      minOrder:  this.MinOrder,
      fromPrice: this.minPrice,
      toPrice:   this.maxPrice,
      companyId: this.supplierId,
      sortBy: "id",
      sort: "DESC"
    }
    this.loading = true;
    this.sourceService.searchSupplierbyId(this.supplierId,data).subscribe((resp: any) => {
      console.log(resp);
      this.loading = false;
      this.supplier = resp
      // this.categoriesList = resp.categoriesDTOList
      // this.sliceCategorie();
      // this.sliceCategorie();
    })
  }

  getCategories() {
    this.b2bserviceService.getCategories("product").subscribe((resp: any) => {
      console.log("productproductproduct");
      console.log(resp);
      this.categoriesList = resp
      this.sliceCategorie();
    })
  }

  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
  }

  addCategorie(idCatg) {
    // console.log(event);
    console.log("idCategory : " ,idCatg);
    this.loading = true

    if (!this.listfilterCategorie.includes(idCatg)) {
      this.listfilterCategorie.push(idCatg);
      console.log(this.listfilterCategorie);
    }
    else {
      let index: number = this.listfilterCategorie.indexOf(idCatg);
      if (index !== -1) {
        this.listfilterCategorie.splice(index, 1);
      }
    }
    this.search();
    console.log(this.listfilterCategorie);
  }

  checkExist(id) {
    if (this.listfilterCategorie.includes(id)) {
      return true
    } else {
      return false
    }
  }

  getCompanyContries() {
    this.sourceService.getCountriesById(this.supplierId).subscribe((resp: any) => {
      console.log(resp);
      this.loading = false;
      this.countries = resp
      // this.sliceCategorie();
    })
  }

  contactNow() {};
}
