import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
const jwt = require('jsonwebtoken');
import { HttpClient } from '@angular/common/http';
import {TradeshowsService} from '../../commons/services/tradeshows/tradeshows.service';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-trade-list',
  templateUrl: './trade-list.component.html',
  styleUrls: ['./trade-list.component.css']
})
export class TradeListComponent implements OnInit {
  ZoomAppBaseUrl = environment.ZoomAppBaseUrl;
  apiKey = 'CZldDtqERieOrGx_XztDhQ'; // Your Zoom API Key
  webinars:any[];
  apiSecret = 'LJmNj8qPj3VXbM6MXliJXgp3vwOCg5ExhcMR'; // Your Zoom API Secret
  meetingNumber = '0'; // always generate new when requested or user will input
  role = 1;
  events:any[]=[];
  type:string='';
  total_records:number=0;
  userName = 'Zoom Feature';
  userEmail = '';
  meetingPassWord = ''; // set default meeting password here
  joinUrl = "";
  constructor(private activatedRoute: ActivatedRoute ,public httpClient: HttpClient,private tradeshowsService:TradeshowsService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.type = params['type'];
      if(this.type=='virtualevents')
        this.getEventsByType('Virtual Event');
      else if (this.type=='webinars')
        this.getEventsByType('Webinar');
    });
  }

  ngOnInit() {
   // this.getMeetingsList();
  }
  getEventsByType(type:string){
    this.tradeshowsService.getEventsByType(type).subscribe((resp: any) => {
      this.events=resp;
      this.total_records= this.events.length;
      console.log(resp);
    })
  }
  getMeetingsList() {

    const payload = {
      iss: this.apiKey,
      exp: ((new Date()).getTime() + 500)
    };

    const zoomAccessToken = jwt.sign(payload, this.apiSecret);

    const request = {
      Token: zoomAccessToken,
    };
    this.httpClient.post(`${this.ZoomAppBaseUrl}zoomfeature/meetings/list`,request).toPromise().then((response: any) => {
      console.log(response.webinars);
      this.webinars=response.webinars
      //this.meetingNumber = response.id;
      //this.meetingPassWord = response.password;
      // this.joinUrl = response.join_url;
    }).catch((error) => {
      console.log(error)
    });
  }
}
