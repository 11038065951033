import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';

@Component({
  selector: 'app-preferred-partners',
  templateUrl: './preferred-partners.component.html',
  styleUrls: ['./preferred-partners.component.css']
})
export class PreferredPartnersComponent implements OnInit {

  part1 = true;
  part2 = false;
  isVisible = false;
  confirmModal?: NzModalRef;
  public listFreight = [];
  public listFinance = [];
  public listpartner = [];
  constructor(private modal: NzModalService,private b2bserviceService: B2bProductService) { }

  ngOnInit() {
    this.getAllpartners();
  }
  deletePartner(id:any)
  {
    this.b2bserviceService.deletePartner(id).subscribe(
      (data: any) => {
        this.getAllpartners()
      })
  }
  getAllpartners(){
    this.b2bserviceService.findAllByUserMail().subscribe((resp: any) => {
      this.listFinance=resp.preferedPartnerFinancingOpportunities;
      this.listFreight=resp.preferedPartnerfreigthServiceProvider;
      console.log("=========>resp",resp)

    }, (err) => {
      console.log(err);

    })
  }
  finance(){
    this.part1=false;
    this.part2=true;
  }
  freight(){
    this.part1=true;
    this.part2=false;
  }
  showConfirm(idPartner): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Are you sure you want to remove this partner ?',
      // nzContent: 'When clicked the OK button, this dialog will be closed after 1 second',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.deletePartner(idPartner);
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'))
    });
  }
}
