import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { TradeValueChainService } from 'src/app/commons/services/service tradeValueChain/trade-value-chain.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trade-value-chain',
  templateUrl: './trade-value-chain.component.html',
  styleUrls: ['./trade-value-chain.component.css']
})
export class TradeValueChainComponent implements OnInit {
  @Input() public name: string;
  @Input() public id:number;
  @Input() public searchText: string;
  baseUrl = environment.baseUrl + '/';

  public categoriesList = [];
  //public name: string = null;
  public icon: string = null;
  public styleOf = null;
  public classes =[
    {background:"#e5d3be",backButton:"#b8a187"},
    {background:"#c6b1fa",backButton:"#7e66b8"},
    {background:"#f3a3b6",backButton:"#d48295"},
    {background:"#f6b7a8",backButton:"#d9907f"},
    {background:"#82e6c9",backButton:"#56bb9e"},
    {background:"#a8dde6",backButton:"#73b3be"},
    {background:"#5f61d0",backButton:"#383abc"},
    {background:"#2dd3d9",backButton:"#1eafb4"},
    {background:"#f7bc5f",backButton:"#e29f34"},

  ];

  constructor(private tradeValueChainService: TradeValueChainService, private router:Router ) { }
 
  getServiceCategorie(){
    this.categoriesList=[]
    this.tradeValueChainService.getServiceCategories().subscribe((response : any[]) =>{
      response.forEach(element=>{
        let itemStyle;
        itemStyle= {element, style: this.getItem()}
        this.categoriesList.push(itemStyle);
      })
      console.log(this.categoriesList);

     // this.categoriesList = response;
      //  response.forEach((element, index) => {
      //   let itemStyle;
      //   itemStyle= {element, style: this.classes[index]}
      //   this.categoriesList.push(itemStyle);
      //   console.log(this.categoriesList);
      // })
     
    })
  }
  getItem() {
    const item = Math.floor(Math.random() * this.classes.length);
    return this.classes[item];
  }
  seeAll(category){
    
    this.router.navigate(['/valueChain/list'],{queryParams:{id:category.element.id, name: category.element.name}})
   
    console.log(category.element.id);
  }
  searchMsg(){
    this.router.navigate(['/valueChain/list'], {queryParams:{msg:this.searchText}})
  }
  ngOnInit() {
    this.getServiceCategorie();
  }
}
