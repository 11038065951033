// import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
// import * as ExcelJS from "./../package-lock.json";
// import 'zone.js/dist/zone';
// if (environment.production) {
//   enableProdMode();
// }

// platformBrowserDynamic( [{ provide: 'exceljs', useValue: ExcelJS }]).bootstrapModule(AppModule)
//   .catch(err => console.error(err));
