import { Component, OnInit, ViewChild, Input } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";
// import { AdminService } from '../../../../commons/services/dashboard-admin/admin.service';
import * as countriesJson from "../../../../../assets/js/countries.json";

@Component({
  selector: "app-individual",
  templateUrl: "./individual.component.html",
  styleUrls: ["./individual.component.css"],
})
export class IndividualComponent implements OnInit {
  validateForm: FormGroup;
  contactPreference: FormGroup;
  controlArray: Array<{ index: number; show: boolean }> = [];
  isCollapse = true;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  size: number;
  large: any;
  countries: any = (countriesJson as any).default;
  submitted: boolean = false;
  state$: Observable<object>;

  planSelected = false;
  selectedCountry: any = {
    label: "Morocco",
    value: "Morocco",
    id: "34",
    type_pays: "Country",
    isocode: "MAR",
    region: "Africa",
    flag: "https://restcountries.eu/data/mar.svg",
  };
  MaxTagCount: number = 3;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  SITE_KEY = "6LfJHKsZAAAAAIUhvIeSG-G2ogBwaNzaIDq50k9l";
  // SITE_KEY = "6Ld35ScjAAAAACSr7-FItAHkiaKMth3jzacj8R52";
  costumSteps: any[] = [
    { stepLabel: "Identification", stepIndex: [0] },
    { stepLabel: "Contact and Perferences", stepIndex: [1] },
    { stepLabel: "Pricing", stepIndex: [2] },
    { stepLabel: "Payment", stepIndex: [3] },
  ];
  subscription: any = {};
  token: any;
  email: any;
  id: any;

  handleCoupon: any = {};
  handleRequest: any = {};
  pricingFrequency: any;
  typeOfIndividuals: any[];
  preferredMarkets: any[];
  interrestedProducts: any[];

  @Input()
  isEditable = true;
  @Input()
  isLinear = true;

  toggleCollapse(): void {
    this.isCollapse = !this.isCollapse;
    this.controlArray.forEach((c, index) => {
      c.show = this.isCollapse ? index < 6 : true;
    });
  }

  resetForm(): void {
    this.validateForm.reset();
  }

  constructor(
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    // private adminService:AdminService
    public activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      confirmEmail: [
        null,
        [Validators.required, Validators.email, this.confirmationEmail],
      ],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required, this.confirmationPassword]],
      phone: [null, [Validators.required]],
      country: [this.selectedCountry, [Validators.required]],
      individualType: [null, [Validators.required]],
    });

    this.contactPreference = this.fb.group({
      interestingMarkets: [null, [Validators.required]],
      interestingProductsAndServices: [null, [Validators.required]],
      contactAfreximbankActivitiesEvents: [],
      contactProvideInfoRelatedToAreasInterest: [],
      contactProvideInfoRelatedToServicesAndOffers: [],
      contactByEmail: [],
      contactByWhatsapp: [],
      contactByTextSms: [],
    });

    this.state$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      map(() => this.router.getCurrentNavigation().extras.state)
    );

    // this.state$ = this.activatedRoute.paramMap
    // .pipe(map(() => window.history.state))

    console.log("user : ", this.state$);

    // this.getAllTypeOfIndividual();
    this.getAllPreferredMarkets();
    this.getAllInterrestedProduct();
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
  }

  onSubmit() {
    this.submitted = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    this.subscription.firstName = this.validateForm.value.firstName;
    this.subscription.lastName = this.validateForm.value.lastName;
    this.subscription.email = this.validateForm.value.email;
    this.subscription.password = this.validateForm.value.password;
    this.subscription.phone = this.validateForm.value.phone.internationalNumber;
    this.subscription.country = this.validateForm.value.country.label;
    this.subscription.individualType = this.validateForm.value.individualType;
    this.subscription.subscriptionType = "INDIVIDUAL";

    console.log(this.subscription);
    this.handleCoupon.email = this.subscription.email;
  }

  onSubmitContactPreference() {
    for (const i in this.contactPreference.controls) {
      this.contactPreference.controls[i].markAsDirty();
      this.contactPreference.controls[i].updateValueAndValidity();
    }
    this.subscription.interestingMarkets =
      this.contactPreference.value.interestingMarkets;
    this.subscription.interestingProductsAndServices =
      this.contactPreference.value.interestingProductsAndServices;
    this.subscription.contactAfreximbankActivitiesEvents =
      this.contactPreference.value.contactAfreximbankActivitiesEvents;
    this.subscription.contactProvideInfoRelatedToAreasInterest =
      this.contactPreference.value.contactProvideInfoRelatedToAreasInterest;
    this.subscription.contactProvideInfoRelatedToServicesAndOffers =
      this.contactPreference.value.contactProvideInfoRelatedToServicesAndOffers;
    this.subscription.contactByEmail =
      this.contactPreference.value.contactByEmail;
    this.subscription.contactByWhatsapp =
      this.contactPreference.value.contactByWhatsapp;
    this.subscription.contactByTextSms =
      this.contactPreference.value.contactByTextSms;

    if (this.contactPreference.valid) {
      this.subscriptionService
        .create(this.subscription)
        .subscribe((data: any) => {
          this.token = data.token;
        });
    }
  }

  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.validateForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  getPrice(value) {
    this.planSelected = true;

    this.handleCoupon.cost = value.cost;
    console.log(this.handleCoupon.cost);
    this.handleRequest.pricingPlanId = value.id;
    console.log(this.handleRequest.pricingPlanId);
    this.pricingFrequency = value.planFrequency;
    console.log(this.pricingFrequency);
  }

  validate() {
    this.handleCoupon.email = this.validateForm.value.email;
    if (this.handleCoupon.cost != null) {
      // this.adminService.validateCoupon(this.handleCoupon,this.token).subscribe((data)=>{
      //   console.log(data);
      // });
    }
  }

  confirmationPassword = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  updateConfirmEmail(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.validateForm.controls.confirmEmail.updateValueAndValidity()
    );
  }
  confirmationEmail = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.email.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  getAllTypeOfIndividual() {
    this.subscriptionService.getIndividualType().subscribe((res: any[]) => {
      this.typeOfIndividuals = res;
    });
  }

  countryChange(value: string): void {
    this.selectedCountry = value;
    console.log(this.selectedCountry);
  }

  getAllPreferredMarkets() {
    this.subscriptionService.getPreferredMarkets().subscribe((res: any[]) => {
      this.preferredMarkets = res;
    });
  }
  getAllInterrestedProduct() {
    this.subscriptionService.getInterrestedProduct().subscribe((res: any[]) => {
      this.interrestedProducts = res;
    });
  }
}
