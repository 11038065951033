import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout'
import { ContactService } from 'src/app/commons/services/contacts/contact.service';

@Component({
  selector: 'app-acces-to-finance-parent',
  templateUrl: './acces-to-finance-parent.component.html',
  styleUrls: ['./acces-to-finance-parent.component.css']
})
export class AccesToFinanceParentComponent implements OnInit {
  results1$: any;
  itemStyle:string
  itemStyle1:string
  itemStyle2:string

  count=0;
  
  constructor(private _observer: BreakpointObserver,private contactService:ContactService) { }

  ngOnInit() {
    this.getAllMessageNotSeen();
        this.results1$ = this._observer.observe('(max-width: 900px)')
       
  }

   getAllMessageNotSeen(){
    this.contactService.getAllMessageNotSeen().subscribe(
      (data:any)=>{

           this.count = data;
           console.log("COUNT")
           console.log(this.count)
      }
    )
  }



}
