import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from 'src/app/commons/services/access-to-finance/scroll-top.service';
import {ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
@Component({
  selector: 'app-finance-provider-details',
  templateUrl: './finance-provider-details.component.html',
  styleUrls: ['./finance-provider-details.component.css']
})
export class FinanceProviderDetailsComponent implements OnInit {



  itemResult :any;
  financeTypeList :any[];
  newArryFinanceTypes :any[];
  finaceTypeSelected :any[]=[];
  submited :boolean =true;
  constructor(private scrollTopService : ScrollTopService,
    private route: ActivatedRoute,private financeService:FinanceService) { }


    ngOnInit() {

      this.scrollTopService.setScrollTop();
      this.itemResult =this.financeService.item;
      console.log("result provider ",this.itemResult)

      this.financeTypeList= this.itemResult.finance_types.map(item  =>
        item.finance_type).filter((value, index, self) => self.indexOf(value) === index)
      this.newArryFinanceTypes =[].concat.apply([],this.financeTypeList).filter((value, index, self) => self.indexOf(value) === index)

      // this.finaceTypeSelected =this.itemResult.map(item =>


      // )

          console.log("financeType new array : ",this.newArryFinanceTypes)
          console.log("financeType array : ",this.financeTypeList)

        console.log("item Phone number: ",this.itemResult.contact_phone);
    //   this.route.params.subscribe( params =>
    //     this.itemResult = params['itemResult']
    // )
    console.log();
    this.itemResult.finance_types.forEach(element => {
      this.finaceTypeSelected.push(element);
  });
    }
    loadFinanceTypeData(financeType :any){
      this.finaceTypeSelected=[];
      this.itemResult.finance_types.forEach(element => {
        if(element.finance_type == financeType){
          this.finaceTypeSelected.push(element) ;
        }
      });
      console.log("financeType selected :",this.finaceTypeSelected);

    }

  scroll(el: HTMLElement) {
    el.scrollIntoView({block: "start", inline: "nearest"});
  }


}
