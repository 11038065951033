import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-buy-leads',
  templateUrl: './my-buy-leads.component.html',
  styleUrls: ['./my-buy-leads.component.css']
})
export class MyBuyLeadsComponent implements OnInit {

  constructor(private profileRouteService : ProfileRouteService,private buyRequestService: BuyRequestService
    , private router:Router,private sharedService: SharedService,private userService: UserService
    ,private mailService : MailService,
    private toaster : ToastrService, private fb: FormBuilder) { }
  listData=[];
  baseUrl = environment.baseUrl+'/';
  public reciver  = null;
  public selectedProduct  = null;
  public listPeriod = [];
  public UsercompanyName ;
  effect = 'fade';
  public messageToSend = "";
  public isVisible   = false
  public motCle = null;
  public country = null;
  public month = null;
  public loading = false;
  public formSearch = new FormGroup({});
  public listafricanCountries = [];
  ngOnInit() {
    this.getUser();
    this.profileRouteService.changeRoute("myBuyLeads");
    this.searchPostRequest();
    this.createForm();
    this.getAllafricanCountries();
    this.getAllPeriod();
  }
  createForm() {
    this.formSearch = this.fb.group({
      motCle: [''],
      country: [''] ,
      month: ['']
    });
  }
  getAllPeriod(){
    this.buyRequestService.getperiod().subscribe((resp: any) => {
      this.listPeriod=resp
      console.log(this.listPeriod);

    })
  }
  getAllafricanCountries(){
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }
  searchPostRequest(){
    this.loading =true;
    let data={
      product:this.motCle,
      country:this.country,
      period:this.month
    }
    this.buyRequestService.postSearch(data).subscribe((resp: any) => {
      this.listData=resp
      this.loading =false;
    });

}

getByID(id){
  this.sharedService.nextSearchProductByID(id);
  this.router.navigate(['/buy-request/product']);

 }
 getUser() {
  let id = localStorage.getItem("USER_ID")
  if(id)
  {
  this.userService.getAuthUser(id).subscribe(
    (res: any) => {
      console.log(res);
      this.UsercompanyName=res.subscription.companyName;
    })
}
}
showModal(item): void {
  this.reciver= item.user.subscription
  this.selectedProduct= item
  console.log("reciveeer",item)
  this.isVisible = true;
}

goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}
sendMail() {
  console.log("item : ", this.selectedProduct);

 let data = {
  receiver:this.selectedProduct.user.email,
  message: this.messageToSend,
  subject: this.selectedProduct.productName,
  isBuyLeadInquiry: false,
  isWebInquiry: true ,
  location: this.selectedProduct.user.country,
  isAllCountry : false,
  isAllCompany : false
 }

 this.mailService.sendMessage(data).subscribe(resp => {
   console.log("resap : " , resp);
   this.toaster.success(
     "The inquiry has been sent succefully",
     "Success",
     {
       closeButton: true,
       timeOut: 5000
     })
     this.isVisible = false;
 })

}
handleCancel(): void {
  this.isVisible = false;
}

handleOk(): void {
  this.isVisible = false;
}


}
