import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SusbcriptionRoutingModule } from "./subscription-routing.module";
import { MainComponent } from "./pages/main/main.component";
import { IndividualComponent } from "./pages/individual/individual.component";
import { CustomStepperComponent } from "./components/custom-stepper/custom-stepper.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NgZorroAntdModule, NzFormModule } from "ng-zorro-antd";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { BusinessComponent } from "./pages/business/business.component";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";

import { B2bComponent } from "./pages/b2b/b2b.component";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzPopoverModule } from "ng-zorro-antd/popover";
// import { SharedModuleModule } from '../shared-module/shared-module.module';
import { PriceTableComponent } from "./components/price-table/price-table.component";
import { StripComponent } from "./components/stripe-form/strip/strip.component";
import {} from "./components/header/header-subscription.component";
import { SharedModuleModule } from "../shared-module/shared-module.module";
import { NgxTreeSelectModule } from "ngx-tree-select";
import { environment } from '../../../environments/environment';

@NgModule({
  declarations: [
    MainComponent,
    CustomStepperComponent,
    IndividualComponent,
    BusinessComponent,
    B2bComponent,
    PriceTableComponent,
    StripComponent,
  ],

  imports: [
    CommonModule,
    SusbcriptionRoutingModule,
    CdkStepperModule,
    NzFormModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    FormsModule,
    RecaptchaModule, //this is the recaptcha main module
    RecaptchaFormsModule,
    NzGridModule,
    NgZorroAntdModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzRadioModule,
    NzPopoverModule,
    SharedModuleModule,
    NgxTreeSelectModule.forRoot({
      allowFilter: true,
      expandMode: "",
      filterCaseSensitive: false,
      filterPlaceholder: "Type your filter here...",
      maxVisibleItemCount: 5,
      idField: "key",
      textField: "title",
      childrenField: "children",
      allowParentSelection: true,
    }),
  ],

  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.SITE_KEY_RECAPTCHA,
      } as RecaptchaSettings,
    },
  ]
})
export class SubscriptionModule {}
