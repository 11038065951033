import { Component, OnInit } from "@angular/core";
import { SourceService } from "src/app/commons/services/source-service/source.service";

@Component({
  selector: "app-source-importers-buyers",
  templateUrl: "./source-importers-buyers.component.html",
  styleUrls: ["./source-importers-buyers.component.css"],
})
export class SourceImportersBuyersComponent implements OnInit {
  companyName = "";
  productName = "";
  importers = [];

  constructor(private sourceService: SourceService) {}

  ngOnInit() {
    this.search();
  }

  search() {
    let data = {
      companyName: this.companyName,
      productName: this.productName,
    };
    let formData: FormData = new FormData();
    formData.append("companyName", this.companyName);
    formData.append("productName", this.productName);
    // this.loading = true;
    this.sourceService.searchImporter(data).subscribe((resp: any) => {
      console.log("importers1", resp);
      this.importers = resp;
      console.log("importers", this.importers);
    });
  }
}
