import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { OrderRequest } from '../models/OrderRequest';

@Component({
  selector: 'app-shipping-details',
  templateUrl: './shipping-details.component.html',
  styleUrls: ['./shipping-details.component.css']
})
export class ShippingDetailsComponent implements OnInit {

  @Input() orderRequest;
  
  userInfo;
  public formShipping!: FormGroup;
  public listafricanCountries = [];

  constructor(private fb: FormBuilder,private buyRequestService: BuyRequestService) { }

  ngOnInit() {
    console.log("user Auth 11111:",this.orderRequest)
    this.userInfo = this.orderRequest.user;
    console.log("user Auth 22222:",this.userInfo)
    this.createformShipping();
    this.getAllafricanCountries();
    this.setValue();

  }
   getAllafricanCountries(){
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }
  createformShipping() {
    this.formShipping = this.fb.group({
      id: [],
      email: [null, [Validators.required, Validators.email]],
      Fname: [null, [Validators.required]],
      Lname: [null, [Validators.required]],
      company: [null, [Validators.required]],
      country: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      secondAdress: [null, [Validators.required]],
      State: [null, [Validators.required]],
      City: [null, [Validators.required]],
      ZIP: [null, [Validators.required]],

    });
  }
  setValue() {
    this.formShipping.get('email').setValue(this.userInfo.subscription.email);
    this.formShipping.get('Fname').setValue(this.userInfo.subscription.firstName);
    this.formShipping.get('Lname').setValue(this.userInfo.subscription.lastName);
    this.formShipping.get('company').setValue(this.userInfo.subscription.companyName);
    this.formShipping.get('country').setValue(this.userInfo.subscription.country);
    this.formShipping.get('adress').setValue(this.userInfo.subscription.companyAddress);
    this.formShipping.get('City').setValue(this.userInfo.subscription.companyCity);
  }

}
