import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzMessageService, UploadFile } from "ng-zorro-antd";
import { ProfileService } from "src/app/commons/services/profil service/profile.service";
import { UserService } from "src/app/commons/services/user.service";
import { Router } from "@angular/router";
import { SharedService } from "src/app/commons/shared service/shared-service";
import {
  CountryISO,
  SearchCountryField,
  TooltipLabel,
} from "ngx-intl-tel-input";
import { ProfileRouteService } from "src/app/commons/services/profiles-route/profile-route.service";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: "app-personalprofile",
  templateUrl: "./personalprofile.component.html",
  styleUrls: ["./personalprofile.component.css"],
})
export class PERSONALPROFILEComponent implements OnInit {
  public formPersonal = new FormGroup({});
  public user: any = [];
  public loading: boolean = false;
  SearchCountryField = SearchCountryField;
  public fileListPhoto: UploadFile[] = [];
  TooltipLabel = TooltipLabel;
  separateDialCode = true;
  CountryISO = CountryISO;
  selectedISO = CountryISO.Morocco;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  imgUrl = environment.baseUrl + "/file/aws/download/";

  // tslint:disable-next-line:max-line-length
  constructor(
    private router: Router,
    private msg: NzMessageService,
    private profileService: ProfileService,
    private userService: UserService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private profileRouteService: ProfileRouteService
  ) {}

  ngOnInit() {
    this.profileRouteService.changeRoute("Personal Profile");

    this.createFormPersonal();
    this.getUser();
  }
  getUser() {
    const id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        Object.entries(this.CountryISO).forEach(([key, value]) => {
          if (key == res.subscription.country) {
            this.selectedISO = value;
          }
        });
        // this.selectedISO = res.subscription.country
        console.log("userService", res);
        this.user = res.subscription;
        this.setValuePersonal(this.user);
      });
    }
  }
  // updateConfirmEmail(): void {
  //   /** wait for refresh value */
  //   Promise.resolve().then(() => this.formPersonal.controls.confirmEmail.updateValueAndValidity());
  // }

  createFormPersonal() {
    this.formPersonal = this.fb.group({
      abreviation: [""],
      // email: ['', Validators.email],
      firstName: [""],
      lastName: [""],
      phone: [""],
      phone2: [""],
      whatsapp: [""],
      jobTitle: [""],
    });
  }

  setValuePersonal(user) {
    this.formPersonal.get("abreviation").setValue(user.abreviation);
    // this.formPersonal.get('email').setValue(user.email);
    this.formPersonal.get("firstName").setValue(user.firstName);
    this.formPersonal.get("lastName").setValue(user.lastName);
    this.formPersonal.get("phone").setValue(user.phone);
    this.formPersonal.get("phone2").setValue(user.phone2);
    this.formPersonal.get("whatsapp").setValue(user.whatsapp);
    this.formPersonal.get("jobTitle").setValue(user.jobTitle);
  }

  UpdateProfil() {
    this.loading = true;
    this.user.abreviation = this.formPersonal.get("abreviation").value;
    // this.user.email = this.formPersonal.get('email').value;
    this.user.firstName = this.formPersonal.get("firstName").value;
    this.user.lastName = this.formPersonal.get("lastName").value;
    this.user.phone = this.formPersonal.value.phone.nationalNumber;
    if (this.formPersonal.value.phone2) {
      this.user.phone2 = this.formPersonal.value.phone2.nationalNumber;
    } else {
      this.user.phone2 = null;
    }
    if (this.formPersonal.value.whatsapp) {
      this.user.whatsapp = this.formPersonal.value.whatsapp.nationalNumber;
    } else {
      this.user.whatsapp = null;
    }
    this.user.jobTitle = this.formPersonal.get("jobTitle").value;
    this.profileService.setBusinessProfile(this.user).subscribe(
      () => {
        this.loading = false;
        if (this.fileListPhoto.length > 0) this.uploadCompanyPhoto();
        else this.getUser();
        this.sharedService.nextClick("this.motCle");
        this.msg.success("user updated successfully");
      },
      (err) => {
        this.loading = false;
        console.log("error", err);
        this.msg.error("error  updated user");
      }
    );
  }

  uploadCompanyPhoto() {
    this.loading = true;
    let formData = new FormData();

    formData.append("personnal", this.fileListPhoto.pop() as any);
    this.fileListPhoto = [];

    this.profileService.uploadFile(this.user.id, formData).subscribe(
      () => {
        this.loading = false;
        this.msg.success("Upload completed successfully");
      },
      () => {
        this.loading = false;
        this.msg.error("error upload");
      },
      () => this.getUser()
    );
  }
  beforeUploadsPhoto = (file: UploadFile): boolean => {
    this.fileListPhoto = this.fileListPhoto.concat(file);
    return false;
  };

  ValidateFile(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      this.msg.error("Only JPG or png files are allowed");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.msg.error("the image size should not exceed 10MB! ");
      return false;
    }

    return true;
  }
}
