import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd";
import { MailService } from "src/app/commons/services/mail service/mail.service";
import { OrderService } from "src/app/commons/services/order-service/order.service";
import { ProfileRouteService } from "src/app/commons/services/profiles-route/profile-route.service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { UserService } from "src/app/commons/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-order-invoice",
  templateUrl: "./orders-invoices.component.html",
  styleUrls: ["./orders-invoices.component.css"],
})
export class OrderInvoiceComponent implements OnInit {
  public user: any = [];
  public totalProds = 0;
  public approvedProds = 0;
  public rejectedProds = 0;
  public PendingProds = 0;
  public totalInq = 0;
  public webInq = 0;
  public buyLeadInq = 0;
  listOfDisplayData = [
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
    { order: "display", isShown: false },
    { order: "display3", isShown: false },
  ];
  sellerOrders = [];
  buyerOrders = [];
  public statusValue = "Pending";
  public statusValues = ["Pending", "Confirm", "Decline"];
  public buyerStatus = ["Paid"];

  public showPanel = true;
  page = 1;
  status = "";
  productName = "";
  date = "";
  public pageSize = 5;
  public total = 0;
  role;
  isSeller: Boolean = true;
  baseUrl = environment.baseUrl + "/";
  isVisible = false;
  currentOrder: any;
  shippingPrice = 0;
  shippingCompany = "";
  shippingEstimationFrom = 0;
  shippingEstimationTo = 0;
  currentStatus = "";
  ordersCount;
  public statuses: any = [
    "Pending",
    "Declined",
    "Confirmed",
    "Paid",
    "Ready to pick",
    "Ready to pack",
    "Ready to ship",
    "Delivered",
  ];
  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private router: Router,
    private msg: NzMessageService,
    private b2bProductService: B2bProductService,
    private profileRouteService: ProfileRouteService
  ) {}

  ngOnInit() {
    this.profileRouteService.changeRoute("ordersinvoices");
    this.getUser();
    this.getOrderCount();
  }

  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log(res);
        this.role = res.role;
        if (this.role == "ROLE_BUYER") {
          this.isSeller = false;
          this.getOrdersHistoryBuyer();
        } else {
          this.getOrdersHistorySeller();
        }
      });
    }
  }

  getOrderCount() {
    this.orderService.getOrdersCounts().subscribe((res) => {
      console.log("getOrdersCounts : ", res);
      this.ordersCount = res;
    });
  }

  getOrdersHistorySeller() {
    let dataToSend = {
      productName: this.productName,
      date: this.date,
      page: this.page - 1,
      pageSize: this.pageSize,
      sortBy: "id",
      sort: "DESC",
      status: this.status,
    };

    this.orderService
      .getOrdersHystorySeller(dataToSend)
      .subscribe((res: any) => {
        console.log("result : ", res);
        this.sellerOrders = res.content;
        // this is to help collapse elements
        this.total = res.totalElements;
        this.addShownSellerOrders();
      });
  }

  getOrdersHistoryBuyer() {
    let dataToSend = {
      productName: this.productName,
      date: this.date,
      page: this.page - 1,
      pageSize: this.pageSize,
      sortBy: "id",
      sort: "DESC",
      status: this.status,
    };

    this.orderService
      .getOrdersHistoryBuyer(dataToSend)
      .subscribe((res: any) => {
        console.log("result : ", res);
        this.buyerOrders = res.content;
        // this is to help collapse elements
        this.total = res.totalElements;
        this.addShownBuyerOrders();
      });
  }

  showColapseSeller(index) {
    this.showPanel = !this.showPanel;
    this.sellerOrders[index].isShown = !this.sellerOrders[index].isShown;
  }

  showColapseBuyer(index) {
    this.buyerOrders[index].isShown = !this.buyerOrders[index].isShown;
  }

  pageIndexChanged(page) {
    // this.searchMessages()
    this.getOrdersHistorySeller();
  }

  pageIndexBuyerChanged(page) {
    this.getOrdersHistoryBuyer();
  }

  addShownSellerOrders() {
    this.sellerOrders.forEach((element) => {
      element.isShown = false;
    });
  }

  addShownBuyerOrders() {
    this.buyerOrders.forEach((element) => {
      element.isShown = false;
    });
  }

  statusDetection(order, value) {
    console.log("Value : ", value);
    this.orderService.changeStatus(order.id, value).subscribe((res: any) => {
      console.log("testing : ", res);
      this.sellerOrders.forEach((prd) => {
        if (prd.id == res.id) {
          prd.dynamicStatus = res.dynamicStatus;
          prd.status = res.status;
        }
      });
    });
  }

  changeSellerVal() {
    // this.isSeller = !this.isSeller;
    this.page = 1;
    if (this.isSeller) this.getOrdersHistorySeller();
    if (!this.isSeller) this.getOrdersHistoryBuyer();
  }

  showModal(item): void {
    this.isVisible = true;
    this.currentOrder = item;
    console.log("this Current Order : ", this.currentOrder);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  changeShippingPrice(shippingPrice) {
    this.shippingPrice = shippingPrice;
  }

  handleOk(): void {
    console.log("Button ok clicked!");
    if (
      this.currentOrder.shippingPrice == null ||
      this.currentOrder.shippingCompany == null ||
      this.currentOrder.shippingEstimationFrom == null ||
      this.currentOrder.shippingEstimationTo == null
    ) {
      this.msg.warning("Please give all shipping details");
    } else {
      let shippingDetails = {
        shippingPrice: this.currentOrder.shippingPrice,
        shippingCompany: this.currentOrder.shippingCompany,
        shippingEstimationFrom: this.currentOrder.shippingEstimationFrom,
        shippingEstimationTo: this.currentOrder.shippingEstimationTo,
      };

      this.orderService
        .changeShippingDetails(this.currentOrder.id, shippingDetails)
        .subscribe((res) => {
          console.log("res : ", res);
          this.changeShippingDetails(res);
          this.isVisible = false;
        });
    }
  }

  changeShippingDetails(order) {
    this.sellerOrders.forEach((prd) => {
      if (prd.id == order.id) {
        prd.shippingPrice = order.shippingPrice;
        prd.shippingCompany = order.shippingCompany;
        prd.shippingEstimationFrom = order.shippingEstimationFrom;
        prd.shippingEstimationTo = order.shippingEstimationTo;
        prd.isHaveShippingPrice = order.isHaveShippingPrice;
      }
    });
  }

  cancelOrder(id) {
    this.orderService.CancelOrder(id).subscribe((res) => {
      console.log("order Cancelled : ", id);
      this.sellerOrders.forEach((prd) => {
        if (prd.id == id) {
          prd = res;
        }
      });
    });
  }

  proceedToPayment(order) {
    this.router.navigate(["/order/"], {
      queryParams: { product: order.product.id, orderId: order.id },
    });
    // this.router.navigate(['/order/'], {queryParams:{product:order.product.id} ,  state: { orderId: order.id } });
  }

  downloadInvoice(orderId) {
    this.b2bProductService.getInvoice(orderId).subscribe((res) => {
      let dataType = res.type;
      let binaryData = [];
      binaryData.push(res);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );

      downloadLink.setAttribute("download", "Invoice.pdf");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  searchOrders() {
    this.page = 1;
    if (this.isSeller) {
      this.getOrdersHistorySeller();
    } else {
      this.getOrdersHistoryBuyer();
    }
  }

  checkStatus(status) {
    //   console.log("testing ", status);
    let state = true ? status != "Confirmed" && status != "Pending" : false;
    return state;

    //   if((status != 'Confirmed') && (status != 'Pending')) {return true; }
    //   else return false
  }
}
