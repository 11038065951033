import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TradeshowsService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getEventsByType(type){
    return this.http.get(this.baseUrl + '/tradeShows/getByType',{ params: {type: type}});
  }

  getEvents() {
    return this.http.get(this.baseUrl + '/tradeShows');
  }
  getEvent(id) {
    console.log(id);
    return this.http.get(this.baseUrl + '/tradeShows/'+id);
  }
  getByName(name) {
    return this.http.get(this.baseUrl + '/tradeShows/search/',{ params: {name: name}});
  }
  getByCityorCountry(cityorcountry) {
    return this.http.get(this.baseUrl + '/tradeShows/search/',{ params: {cityOrCountry: cityorcountry}});
  }
  getByDate(startDate) {
    return this.http.get(this.baseUrl + '/tradeShows/search/',{ params: {date: startDate}});
  }
  getByIndustry(categoryId) {
  return this.http.get(this.baseUrl + '/tradeShows/search/', { params: {categoryId: categoryId}});
  }

  addEvent(data) {
    return this.http.post(this.baseUrl + '/tradeShows', data);
  }
  getParticipantsPie(id) {
    return this.http.get(this.baseUrl + '/tradeShows/chart/'+id);
  }
  getParticipants(id) {
    return this.http.get(this.baseUrl + '/tradeShows/getParticipants/'+id);
  }
  addParticipant(partic) {
    return this.http.put(this.baseUrl + '/tradeShows/addParticipant/', partic);
  }

}
