import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorProvider } from "src/app/commons/interceptors/http-interceptor";
import { ToastrModule } from "ngx-toastr";
import { SharedModuleModule } from "../shared-module/shared-module.module";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { ChagePassComponent } from './pages/chage-pass/chage-pass.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { BUSINESSPROFILEComponent } from './pages/businessprofile/businessprofile.component';
import { PERSONALPROFILEComponent } from './pages/personalprofile/personalprofile.component';
import { ProductsServiceComponent } from './pages/products-service/products-service.component';
import { ResponsesReceivedComponent } from './pages/responses-received/responses-received.component';
import { SentComponent } from './pages/sent/sent.component';
import { TrashComponent } from './pages/trash/trash.component';
import { AddNewProductServiceComponent } from './pages/add-new-product-service/add-new-product-service.component';
import { AddProductComponent } from '../products/add-product/add-product.component';
import { ManageYourProductComponent } from './pages/manage-your-product/manage-your-product.component';
import { PrivacySettingsComponent } from './pages/privacy-settings/privacy-settings.component';
import { DeactivateAccountComponent } from './pages/deactivate-account/deactivate-account.component';
import { AppModule } from 'src/app/app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InquiryComponent } from "./pages/inquiry/inquiry.component";
import { UpdateProductServiceComponent } from "./pages/update-product-service/update-product-service.component";
import { SentInquiryComponent } from "./pages/sent-inquiry/sent-inquiry.component";
import { TrashInquiryComponent } from "./pages/trash-inquiry/trash-inquiry.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { DashboardComponent } from './pages/dasboard/dashboard.component';
import { OrderInvoiceComponent } from "./pages/orders-invoices/orders-invoices.component";
import { NzCollapseModule } from "ng-zorro-antd";
import { OrderStatusComponent } from "./pages/orders-status/orders-status.component";
import { SalesActivityComponent } from './pages/sales-activity/sales-activity.component';
import { StockStatusComponent } from "./pages/stock-status/stock-status.component";
import { AddServiceComponent } from './pages/add-service/add-service.component';
import { SalesActivityBuyerComponent } from './pages/sales-activity/sales-activity-buyer/sales-activity-buyer.component';
import { SalesActivitySellerComponent } from './pages/sales-activity/sales-activity-seller/sales-activity-seller.component';
import { OrdersStatusSellerComponent } from './pages/orders-status/orders-status-seller/orders-status-seller.component';
import { OrdersStatusBuyerComponent } from './pages/orders-status/orders-status-buyer/orders-status-buyer.component';
import { LiveChatComponent } from './pages/live-chat/live-chat.component';
import { EmojiFrequentlyService, EmojiSearch, PickerModule } from "@ctrl/ngx-emoji-mart";
import { ProfilesHeaderComponent } from './pages/profiles-header/profiles-header.component';
import { InterestsComponent } from './pages/interests/interests.component';
import { SpamComponent } from "./pages/spam/spam.component";
import { EmojiService } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { FeaturingRequestsComponent } from "./pages/featuring-requests/featuring-requests.component";
import { MyBuyLeadsComponent } from "./pages/my-buy-leads/my-buy-leads.component";
import { MyRFQSComponent } from "./pages/my-rfqs/my-rfqs.component";


registerLocaleData(en);
@NgModule({
  imports: [
    ProfileRoutingModule,
    CommonModule,
     SharedModuleModule,
    ToastrModule.forRoot(),
    NgxIntlTelInputModule,
    FormsModule,
    ReactiveFormsModule,
    NzCollapseModule,
    PickerModule
  ],
  declarations: [
   ChagePassComponent,
   BUSINESSPROFILEComponent,
   PERSONALPROFILEComponent,
   ProductsServiceComponent,
   ResponsesReceivedComponent,
   SentComponent,
   TrashComponent,
   SpamComponent,
   AddNewProductServiceComponent,
   UpdateProductServiceComponent,
   ManageYourProductComponent,
   PrivacySettingsComponent,
   DeactivateAccountComponent,
   InquiryComponent,
   SentInquiryComponent,
   TrashInquiryComponent,
   DashboardComponent,
   OrderInvoiceComponent,
   OrderStatusComponent,
   StockStatusComponent,
   SalesActivityComponent,
   AddServiceComponent,
   SalesActivityBuyerComponent,
   SalesActivitySellerComponent,
   OrdersStatusSellerComponent,
   OrdersStatusBuyerComponent,
   LiveChatComponent,
   ProfilesHeaderComponent,
   InterestsComponent,
   FeaturingRequestsComponent,
   MyRFQSComponent,
   MyBuyLeadsComponent
  ],
  exports: [ProfilesHeaderComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorProvider,
      multi: true,
    },
    EmojiFrequentlyService,EmojiSearch,EmojiService
  ],
})
export class ProfileModule {
  }
