import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Finance } from '../../models/finance';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  baseUrl = environment.baseUrl;
  item :any ;
  sharedFreightDetails: any;
  constructor(private http:HttpClient) {
               this.http = http;
   }
   getAllCountries() {
    return this.http.get("./assets/js/countries.json");
  }
   getAllFinance(){
     return this.http.get<any[]>(this.baseUrl+'/access_finance');
   }
   getAllFinanceByUser(id:any){
     return this.http.get<any[]>(this.baseUrl+'/access_finance/history/'+id);
   }
   postFinance(body:any){
     return this.http.post(this.baseUrl+'/access_finance',body);
   }

   getFilterByPagination(criteria){

      return this.http.get(this.baseUrl +'/access_finance/filter',{params : criteria})
   }

   getCountries(){
     return this.http.get(this.baseUrl+'/access_finance/filterInput/countries')
   }

   getFinanceTypes(){
     return this.http.get(this.baseUrl+'/access_finance/filterInput/financeTypes')
   }

   getFinanceProviders(){
     return this.http.get(this.baseUrl+'/access_finance/filterInput/financeProviders')
   }

   getInstitutionTypes(){
     return this.http.get(this.baseUrl+'/access_finance/filterInput/institutionTypes')
   }

   searchBykey(criteria){
     return this.http.get(this.baseUrl+'/access_finance/search',{params : criteria})
   }

   getTitle(id:number){
     return this.http.get(this.baseUrl+'/title/'+id)
   }

   updateTitle(body:any){
     return this.http.put(this.baseUrl+'/title',body)
   }
   updateAccess(body:any,id){
     return this.http.put(this.baseUrl +'/access_finance/'+id,body)
   }
   deleteAccess(id){
     return this.http.delete(this.baseUrl +'/access_finance/'+id)
   }


    contactNowWithUpload(contact:any,file:any){
      return this.http.post(this.baseUrl+'/contacts/saveWithUpload',contact,file);
    }


    getTitleIntroductionSub(){
      return this.http.get(this.baseUrl+'/title/module/A2F_SUB')
    }
    getFinanceByType(){
      return this.http.get(this.baseUrl+'/params/financeType');
    }

    getFinanceProvider(){
      return this.http.get(this.baseUrl+'/params/getFinanceProvider');
    }
    searchByFinanceProvider(obj){
      return this.http.post(this.baseUrl+'/params/acessToFinance',obj);
    }
    getA2fByFilter(object){
      return this.http.post(this.baseUrl+'/params/acessToFinanceByFilter',object)
    }

   getTitleIntroduction(){
    return this.http.get(this.baseUrl+'/title/module/A2F')
  }

  getTitleIntroductionPostRequest(){
    return this.http.get(this.baseUrl+'/title/module/A2F_PFR')
  }
  uploadAttachement(id,body){
    const formData: FormData = new FormData();
    formData.append("file",body);
    return this.http.post(this.baseUrl+'/financing_opportunities/'+id+'/uploadFile',body);
  }
}
