import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/commons/services/user.service";
import { ProfileService } from "src/app/commons/services/profil service/profile.service";
import { NzMessageService, UploadFile } from "ng-zorro-antd";
import { BuyRequestService } from "src/app/commons/services/service buy-request/buy-request.service";
import { ProfileRouteService } from "src/app/commons/services/profiles-route/profile-route.service";
import { environment } from '../../../../../environments/environment';
import {
  CountryISO,
  SearchCountryField,
  TooltipLabel,
} from "ngx-intl-tel-input";

@Component({
  selector: "app-businessprofile",
  templateUrl: "./businessprofile.component.html",
  styleUrls: ["./businessprofile.component.css"],
})
export class BUSINESSPROFILEComponent implements OnInit {
  public formBusiness!: FormGroup;
  public formAdditional!: FormGroup;
  public company: any;
  public ownerShipType: any = [];
  public IncludeBusinessCitiesValue: any = [];
  public ExcludeBusinessCitiesValue: any = [];
  public fileListLogo: UploadFile[] = [];
  public fileListPhoto: UploadFile[] = [];
  public modeOfPayment: any = [];
  CountryISO = CountryISO;
  public loading: boolean = false;
  public loadingAdditional: boolean = false;
  SearchCountryField = SearchCountryField;
  selectedISO = CountryISO.Morocco;
  TooltipLabel = TooltipLabel;
  separateDialCode = true;
  public country = null;
  public listafricanCountries = [];
  date = null;

  imgUrl = environment.baseUrl + "/file/aws/download/";

  constructor(
    private msg: NzMessageService,
    private buyRequestService: BuyRequestService,
    private profileService: ProfileService,
    private userService: UserService,
    private fb: FormBuilder,
    private profileRouteService: ProfileRouteService
  ) {}

  ngOnInit() {
    this.profileRouteService.changeRoute("Business Profile");
    this.createFormPost();
    this.createFormAdditional();
    this.getUser();
    this.getOwnerShipType();
    this.getAllafricanCountries();
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        Object.entries(this.CountryISO).forEach(([key, value]) => {
          if (key == res.subscription.country) {
            this.selectedISO = value;
          }
        });
        console.log("userService", res);
        this.setValue(res);
        this.setValueAdditional(res);
        this.company = res.subscription;
      });
    }
  }
  setphoneCountry(country) {
    Object.entries(this.CountryISO).forEach(([key, value]) => {
      if (key == country) {
        this.selectedISO = value;
      }
    });
    //console.log(this.selectedISO);
  }
  getOwnerShipType() {
    this.profileService.getOwnerShipType().subscribe((res: any) => {
      this.ownerShipType = res;
    });
  }
  getAllafricanCountries() {
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries = resp;
    });
  }
  createFormPost() {
    this.formBusiness = this.fb.group({
      id: [],
      companyName: [null, [Validators.required]],
      adresse: [""],
      city: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      alternateWebsite: [""],
      YearOfEstablishment: ["", Validators.required],
      NoOfEmployees: ["", Validators.required],
      annualTurnover: [""],
      lastAnnualTurnover: [""],
      annualTurnoverPrice: [""],
      country: ["", Validators.required],
      province: [""],
      companyReg: [""],
      companyAddress: [""],
      ownershipType: [""],
    });
  }

  createFormAdditional() {
    this.formAdditional = this.fb.group({
      companyDescription: [""],
      ModeofPayment: [""],
      WorkingDaysFrom: [""],
      WorkingDaysToo: [""],
      rib: ["", Validators.minLength(24)],
      WorkingTimeFrom: [""],
      WorkingTimeToo: [""],
      // ExcludeBusinessCities: ['', Validators.required],
      // IncludeBusinessCities: ['', Validators.required]
    });
  }

  setValue(user) {
    this.formBusiness
      .get("phoneNumber")
      .setValue(user.subscription.companyPhone);
    this.formBusiness
      .get("companyName")
      .setValue(user.subscription.companyName);
    this.formBusiness
      .get("companyAddress")
      .setValue(user.subscription.companyAddress);
    this.formBusiness.get("country").setValue(user.subscription.country);
    this.formBusiness.get("province").setValue(user.subscription.province);
    this.formBusiness.get("city").setValue(user.subscription.companyCity);
    this.formBusiness.get("annualTurnoverPrice").setValue("DOLLAR");
    this.formBusiness
      .get("alternateWebsite")
      .setValue(user.subscription.website);
    this.formBusiness
      .get("YearOfEstablishment")
      .setValue(new Date(user.subscription.yearOfEstablishment));
    this.date = new Date(user.subscription.yearOfEstablishment);
    console.log("date", this.date);
    console.log("date2222", user.subscription.yearOfEstablishment);
    console.log("dateZZZZ", user);
    this.formBusiness
      .get("NoOfEmployees")
      .setValue(user.subscription.employeeSize);
    this.formBusiness
      .get("annualTurnover")
      .setValue(user.subscription.annualTurnover);
    this.formBusiness
      .get("lastAnnualTurnover")
      .setValue(user.subscription.lastAnnualTurnover);
    this.formBusiness
      .get("companyReg")
      .setValue(user.subscription.businessRegistrationNumber);
    this.formBusiness
      .get("ownershipType")
      .setValue(user.subscription.ownershipType);
  }

  addBusiness() {
    for (const i in this.formBusiness.controls) {
      this.formBusiness.controls[i].markAsDirty();
      this.formBusiness.controls[i].updateValueAndValidity();
    }

    if (this.formBusiness.valid) {
      this.loading = true;
      this.company.companyPhone =
        this.formBusiness.value.phoneNumber.nationalNumber;
      this.company.companyName = this.formBusiness.get("companyName").value;
      this.company.companyCity = this.formBusiness.get("city").value;
      this.company.country = this.formBusiness.get("country").value;
      this.company.province = this.formBusiness.get("province").value;
      this.company.companyAddress =
        this.formBusiness.get("companyAddress").value;
      this.company.website = this.formBusiness.get("alternateWebsite").value;
      this.company.yearOfEstablishment = new Date(this.date).getFullYear();
      this.company.employeeSize = this.formBusiness.get("NoOfEmployees").value;
      this.company.annualTurnover =
        this.formBusiness.get("annualTurnover").value;
      this.company.lastAnnualTurnover =
        this.formBusiness.get("lastAnnualTurnover").value;
      this.company.ownershipType = this.formBusiness.get("ownershipType").value;
      this.company.businessRegistrationNumber =
        this.formBusiness.get("companyReg").value;
      console.log("company buisnessssss", this.company);
      console.log("thisformBusniess", this.formBusiness.value);
      this.profileService.setBusinessProfile(this.company).subscribe(
        (res: any) => {
          this.loading = false;
          this.getUser();
          this.msg.success("user updated successfully");
        },
        (err) => {
          this.loading = false;
          console.log("error", err);
        }
      );
    }
  }

  setValueAdditional(user) {
    this.formAdditional
      .get("companyDescription")
      .setValue(user.subscription.description);
    this.formAdditional
      .get("ModeofPayment")
      .setValue(user.subscription.modeOfPayment);
    this.formAdditional
      .get("WorkingDaysFrom")
      .setValue(user.subscription.fromDay);
    this.formAdditional.get("WorkingDaysToo").setValue(user.subscription.toDay);
    this.formAdditional
      .get("WorkingTimeFrom")
      .setValue(user.subscription.fromTime);
    this.formAdditional
      .get("WorkingTimeToo")
      .setValue(user.subscription.toTime);
    this.formAdditional.get("rib").setValue(user.subscription.rib);
    // this.formAdditional.get('ExcludeBusinessCities').setValue(user.subscription.excludeBusinessCity);
    // this.formAdditional.get('IncludeBusinessCities').setValue(user.subscription.includeBusinessCity);
  }

  addAdditional() {
    for (const i in this.formAdditional.controls) {
      this.formAdditional.controls[i].markAsDirty();
      this.formAdditional.controls[i].updateValueAndValidity();
    }

    if (this.formAdditional.valid) {
      this.loadingAdditional = true;
      this.company.description =
        this.formAdditional.get("companyDescription").value;
      this.company.modeOfPayment = this.modeOfPayment;
      this.company.fromDay = this.formAdditional.get("WorkingDaysFrom").value;
      this.company.toDay = this.formAdditional.get("WorkingDaysToo").value;
      this.company.fromTime = this.formAdditional.get("WorkingTimeFrom").value;
      this.company.toTime = this.formAdditional.get("WorkingTimeToo").value;
      this.company.rib = this.formAdditional.get("rib").value;
      this.company.excludeBusinessCity = this.ExcludeBusinessCitiesValue;
      this.company.includeBusinessCity = this.IncludeBusinessCitiesValue;
      this.profileService.setBusinessProfile(this.company).subscribe(
        () => {
          if (this.fileListPhoto.length > 0)
            this.uploadCompanyPhoto();
          if (this.fileListLogo.length > 0)
            this.uploadCompanyLogo();
          if (this.fileListPhoto.length === 0 && this.fileListLogo.length === 0)
            this.getUser();

          this.msg.success("user updated successfully");
          this.loadingAdditional = false;
        },
        (err) => {
          this.loadingAdditional = false;
          console.error("error", err);
        }
      );
    }
  }

  uploadCompanyLogo() {
    this.loadingAdditional = true;
    let formData = new FormData();

    formData.append("logo", this.fileListLogo.pop() as any);
    this.fileListLogo = [];

    this.profileService.uploadFile(this.company.id, formData).subscribe(
      () => {
        this.loadingAdditional = false;
        this.msg.success("Upload completed successfully");
      },
      () => {
        this.loading = false;
        this.msg.error("error upload");
      },
      () => this.getUser()
    );
  }
  uploadCompanyPhoto() {
    this.loadingAdditional = true;
    let formData = new FormData();

    formData.append("photo", this.fileListPhoto.pop() as any);
    this.fileListPhoto = [];

    this.profileService.uploadFile(this.company.id, formData).subscribe(
      () => {
        this.loadingAdditional = false;
        this.msg.success("Upload completed successfully");
      },
      () => {
        this.loading = false;
        this.msg.error("error upload");
      },
      () => this.getUser()
    );
  }

  beforeUploadsLogo = (file: UploadFile): boolean => {
    this.fileListLogo = this.fileListLogo.concat(file);
    return false;
  };
  beforeUploadsPhoto = (file: UploadFile): boolean => {
    this.fileListPhoto = this.fileListPhoto.concat(file);
    return false;
  };

  ValidateFile(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      this.msg.error("Only JPG or png files are allowed");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.msg.error("the image size should not exceed 10MB! ");
      return false;
    }

    return true;
  }
}
