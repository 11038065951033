import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly userService: UserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: boolean = this.userService.currentUser;

    console.log("netre path", currentUser , route.data.roles )

    if (currentUser) {
      const role = currentUser["role"];

      if (route.data.roles && route.data.roles.indexOf(role) === -1) {
        return role === "ROLE_ADMIN"
          ? this.router.navigate(["/B2BManagment"])
          : this.router.navigate(["/"]);
      }

      return true;
    }

    return this.router.navigate(['/']);
  }
}
