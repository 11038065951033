import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  NgZorroAntdModule,
  NZ_I18N,
  en_US,
  NzCollapseModule,
  NzLayoutModule,
} from "ng-zorro-antd";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { HomeB2bComponent } from "./modules/home-b2b/home-b2b.component";
import { PostRequestComponent } from "./modules/post-request/post-request.component";
import { TradeShowsComponent } from "./modules/home-b2b/trade-shows/trade-shows.component";
import { TradeServicesComponent } from "./modules/home-b2b/trade-services/trade-services.component";
import { MatchingServiceComponent } from "./modules/home-b2b/matching-service/matching-service.component";
import { CategoriesHomeComponent } from "./modules/home-b2b/categories-home/categories-home.component";
import { SlideB2bComponent } from "./components/slide-b2b/slide-b2b.component";
import { HeaderB2bComponent } from "./components/header-b2b/header-b2b.component";
import {
  playerFactory,
  ProductsModule,
} from "./modules/products/product.module";
import { TradeValueChainModule } from "./modules/trade-value-chain/trade-value-chain.module";
import { BuyRequestComponent } from "./modules/buy-request/buy-request.component";
import { SearchBuyLeadsComponent } from "./modules/buy-request/search-buy-leads/search-buy-leads.component";
import { LatestBuyLeadsComponent } from "./modules/buy-request/latest-buy-leads/latest-buy-leads.component";
import { AllCategoriesComponent } from "./modules/buy-request/all-categories/all-categories.component";
import { ResultsAllCtaegoriesComponent } from "./modules/buy-request/results-all-ctaegories/results-all-ctaegories.component";
import { ProfileComponent } from "./modules/profile/profile.component";
import { AddProductComponent } from "./modules/products/add-product/add-product.component";
import { SharedService } from "./commons/shared service/shared-service";
import { SubscriptionComponent } from "./modules/subscription/subscription.component";
import { AuthProvider } from "./commons/services/auth";
import { AuthGuard } from "./commons/guards/auth";
import { UserService } from "./commons/services/user.service";
import { InterceptorProvider } from "./commons/interceptors/http-interceptor";
import { SubscriptionModule } from "./modules/subscription/subscription.module";
import { ToastrModule } from "ngx-toastr";
import { SourceImportersBuyersComponent } from "./modules/source-importers-buyers/source-importers-buyers.component";
import { ProtectionComponent } from "./modules/search-importers/search-importers.component";
import { SourceSuppliersManufacturesComponent } from "./modules/source-suppliers-manufactures/source-suppliers-manufactures.component";
import { SearchSuppliersComponent } from "./modules/search-suppliers/search-suppliers.component";
import { TradeShowComponent } from "./modules/trade-show/trade-show.component";
import { HeaderTradeComponent } from "./modules/header-trade/header-trade.component";
import { AddEventComponent } from "./modules/add-event/add-event.component";
import { TradeListComponent } from "./modules/trade-list/trade-list.component";
import { ShowComponent } from "./modules/show/show.component";
import { ProductDetailsComponent } from "./modules/product-details/product-details.component";
import { LottieModule } from "ngx-lottie";
import { ManufacturerComponent } from "./modules/manufacturer/manufacturer.component";
import { ProfileRoutingModule } from "./modules/profile/profile-routing.module";
import { ProfileModule } from "./modules/profile/profile.module";
import { SharedModuleModule } from "./modules/shared-module/shared-module.module";
import { AccesToFinanceParentComponent } from "./modules/access-to-finance/acces-to-finance-parent.component";
import { AccessToFinanceModule } from "./modules/access-to-finance/access-to-finance.module";
import { ResultByIdComponent } from "./modules/buy-request/result-by-id/result-by-id.component";
import { SearchSuppliersByCategoryComponent } from "./modules/suppliers-bycategories/search-suppliers-bycategories.component";
import { FreightServicesComponent } from "./modules/freight-services/freight-services.component";
import { InfoComponent } from "./modules/info/info.component";
import { ProductsByCategoryComponent } from "./modules/products-by-category/products-by-category.component";

import { MatchingProfilerComponent } from "./modules/matching-profiler/matching-profiler.component";
import { CategoryListComponent } from "./modules/category-list/category-list.component";
import { AllMainCategoriesComponent } from "./modules/all-main-categories/all-main-categories.component";
import { ProductsByClassificationComponent } from "./modules/products-by-classification/products-by-classification.component";
import { ProductsByMainCategoryComponent } from "./modules/products-by-main-category/products-by-main-category.component";
import { SearchCompanyComponent } from "./modules/search-company/search-company.component";
import { InsuranceComponent } from "./modules/insurance/insurance.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { FreightRequestComponent } from "./modules/freight-request/freight-request.component";
import { PostFreightComponent } from "./modules/freight-request/post-freight/post-freight.component";
import { FreightLeadByIdComponent } from "./modules/freight-request/freight-lead-by-id/freight-lead-by-id.component";
import { PreferredPartnersComponent } from "./modules/preferred-partners/preferred-partners.component";
import { AddPartnerComponent } from "./modules/preferred-partners/add-partner/add-partner.component";
import { FreightCompanyByIdComponent } from "./modules/freight-services/freight-company-by-id/freight-company-by-id.component";
import { InsuranceCompanyByIdComponent } from "./modules/insurance/insurance-company-by-id/insurance-company-by-id.component";
import { OrderComponent } from "./modules/order/order.component";
import { ShippingDetailsComponent } from "./modules/order/shipping-details/shipping-details.component";
import { OrderDetailsComponent } from "./modules/order/order-details/order-details.component";
import { OrderPaymentComponent } from "./modules/order/order-payment/order-payment.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { ServicebyIdComponent } from "./modules/services/serviceby-id/serviceby-id.component";
import { SearchServiceComponent } from "./modules/services/search-service/search-service.component";
import { StartOrderComponent } from "./modules/order/start-order/start-order.component";
import { OrderModule } from "./modules/order/order.module";
import { ComparisonComponent } from "./modules/products/comparison/comparison.component";
import { PostServiceComponent } from "./modules/services/post-service/post-service.component";
import { FeaturedProductsComponent } from "./modules/home-b2b/featured-products/featured-products.component";
import { ChatHomeComponent } from "./modules/chat-home/chat-home.component";
import { TermAndConditionsComponent } from "./modules/term-and-conditions/term-and-conditions.component";
import { QuotationComponent } from "./modules/home-b2b/quotation/quotation.component";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { ContactComponent } from "./modules/contact/contact.component";
import { NotFoundComponent } from "./modules/not-found/not-found.component";
import { FeaturedRequestPaymentComponent } from "./modules/featured-request-payment/featured-request-payment.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { DashboardAdminComponent } from "./modules/dashboard-admin/dashboard-admin.component";
import { B2blayoutComponent } from "./modules/dashboard-admin/pages/b2blayout/b2blayout.component";

import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzDividerModule, NzPopoverModule, NzListModule } from "ng-zorro-antd";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LayoutModule } from "@angular/cdk/layout";
import { PermissionModule } from "./modules/permission/permission.module";
import { loadPermissionProvider } from "./commons/services/app.initializer";
import { FreightOpportunitiesComponent } from './modules/freight-services/freight-opportunities/freight-opportunities.component';
import { FreightOpportunitiesDetailsComponent } from './modules/freight-services/freight-opportunities/freight-opportunities-details/freight-opportunities-details.component';
import { PostFreightOpportunitiesComponent } from './modules/freight-services/post-freight-opportunities/post-freight-opportunities.component';
import { FreightRequestDetailsComponent } from './modules/freight-request/freight-request-details/freight-request-details.component';
// import { DiscountManagmentComponent } from './modules/dashboard-admin/pages/discount-managment/discount-managment.component';

registerLocaleData(en);
// export function playerFactory() {
//   return import( 'lottie-web');
// }
@NgModule({
  declarations: [
    AppComponent,
    AccesToFinanceParentComponent,
    HeaderB2bComponent,
    SlideB2bComponent,
    HomeB2bComponent,
    CategoriesHomeComponent,
    MatchingServiceComponent,
    TradeServicesComponent,
    TradeShowsComponent,
    PostRequestComponent,
    BuyRequestComponent,
    SubscriptionComponent,
    SourceImportersBuyersComponent,
    ProfileComponent,
    ProtectionComponent,
    SourceSuppliersManufacturesComponent,
    SearchSuppliersComponent,
    SearchSuppliersByCategoryComponent,
    SearchBuyLeadsComponent,
    LatestBuyLeadsComponent,
    AllCategoriesComponent,
    ResultsAllCtaegoriesComponent,
    TradeShowComponent,
    HeaderTradeComponent,
    AddEventComponent,
    TradeListComponent,
    ShowComponent,
    ProductDetailsComponent,
    ManufacturerComponent,
    ResultByIdComponent,
    FreightServicesComponent,
    InfoComponent,
    ProductsByCategoryComponent,
    MatchingProfilerComponent,
    CategoryListComponent,
    ProductsByMainCategoryComponent,
    ProductsByClassificationComponent,
    AllMainCategoriesComponent,
    SearchCompanyComponent,
    InsuranceComponent,
    FreightRequestComponent,
    PostFreightComponent,
    FreightLeadByIdComponent,
    PreferredPartnersComponent,
    AddPartnerComponent,
    FreightCompanyByIdComponent,
    InsuranceCompanyByIdComponent,
    SearchServiceComponent,
    ServicebyIdComponent,
    ComparisonComponent,
    ChatHomeComponent,
    FeaturedProductsComponent,
    TermAndConditionsComponent,
    QuotationComponent,
    ContactComponent,
    NotFoundComponent,
    FeaturedRequestPaymentComponent,
    DashboardAdminComponent,
    B2blayoutComponent,
    FreightOpportunitiesComponent,
    FreightOpportunitiesDetailsComponent,
    PostFreightOpportunitiesComponent,
    FreightRequestDetailsComponent,
    // DiscountManagmentComponent
  ],

  imports: [
    NzTabsModule,
    NgxIntlTelInputModule,
    SlickCarouselModule,
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    NzCollapseModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    FormsModule,
    ProfileModule,
    ReactiveFormsModule,
    ProductsModule,
    TradeValueChainModule,
    SubscriptionModule,
    AccessToFinanceModule,
    OrderModule,
    PickerModule,
    EmojiModule,
    LayoutModule,
    LottieModule.forRoot({ player: playerFactory }),
    ToastrModule.forRoot(),
    NzCarouselModule,
    NzBadgeModule,
    NzDropDownModule,
    NzBreadCrumbModule,
    NzIconModule,
    NzGridModule,
    NzDividerModule,
    NzPopoverModule,
    NzListModule,
    InfiniteScrollModule,
    // NzLayoutModule
    // NgxImageZoomModule,
    PermissionModule,
  ],

  providers: [
    SharedService,
    { provide: NZ_I18N, useValue: en_US },
    AuthProvider,
    AuthGuard,
    UserService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true },
    CurrencyPipe,
    loadPermissionProvider,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
