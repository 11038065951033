import { AnimationOptions } from "@angular/animations";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnimationItem } from "lottie-web";
import { NzMessageService, UploadFile } from "ng-zorro-antd";
import { ToastrService } from "ngx-toastr";
import { MailService } from "src/app/commons/services/mail service/mail.service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { UserService } from "src/app/commons/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-products-by-category",
  templateUrl: "./products-by-category.component.html",
  styleUrls: ["./products-by-category.component.css"],
})
export class ProductsByCategoryComponent implements OnInit {
  public baseUrl = environment.baseUrl + "/";
  imgUrl = environment.baseUrl + "/file/aws/download/";
  public catName;
  public selectedcurr = "USD - Dollar Americain";
  public catId;
  isVisible = false;
  public messages: string = null;
  public categoriesList = [];
  public certificationList = [];
  public countryList = [];
  public productDTOList = [];
  public productDTOShow = [];
  public productCount: number = 0;
  public MinOrder: number = null;
  public minPrice: number = null;
  public maxPrice: number = null;
  public listClassfication = [];
  public reached = false;
  public isActive = false;
  public index = null;
  public listfilterCategorie = [];
  public listCategories = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public loading: boolean = false;

  effect = "scrollx";
  public listClass = [];
  public listDisplayClass = [];
  public catImg;
  public options: AnimationOptions;
  public nbrSlice = 0;
  public nbrProduct = 9;
  public styles: Partial<CSSStyleDeclaration> = {
    width: "27%",
    margin: "auto",
    height: "24%",
  };

  public messageToSend = "";
  isAllCountry = true;
  isAllCompany = true;
  agree = false;
  public selectProd;
  public fileList: UploadFile[] = [];
  public listEmplty = true;
  public role;
  public companyName;
  utilSettings = {
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 3550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  constructor(
    private route: ActivatedRoute,
    private b2bserviceService: B2bProductService,
    private msg: NzMessageService,
    private mailService: MailService,
    private toaster: ToastrService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getUser();
    this.route.params.subscribe((data) => {
      this.catId = data.catID;
      this.catName = data.catName;
      this.listfilterCategorie.push(this.catId);
      this.search();
    });

    this.getClassification();
  }
  startOrder(item) {
    this.router.navigate(["/order/"], { queryParams: { product: item.id } });
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log("user", res);
        this.companyName = res.subscription.companyName;
        this.role = res.role;
      });
    }
  }
  clear() {
    // this.messages =null;
    this.listfilterCategorie = [];
    // this.selectedCategory =null;
    // this.selectedSubCategory =null;
    // this.classed =false
    this.listfiltercountry = [];
    this.listClassfication = [];
    this.listShowcountry = [];
    this.MinOrder = null;
    this.minPrice = null;
    this.maxPrice = null;
    this.search();
  }
  search() {
    let data = {
      page: "0",
      pageSize: "10",
      categoriesId: this.listfilterCategorie,
      search: this.messages,
      minOrder: this.MinOrder,
      fromPrice: this.minPrice,
      toPrice: this.maxPrice,
      countries: this.listfiltercountry,
      subCategories: this.listClassfication,
      certifications: [],
      sortBy: "id",
      sort: "DESC",
    };
    this.loading = true;
    this.b2bserviceService.searchProduct(data).subscribe(
      (resp: any) => {
        console.log(resp);
        this.countryList = resp.numberByCountryList;
        this.productDTOList = resp.productDTOList;
        this.productCount = this.productDTOList.length;
        this.slicepProduct();
        this.loading = false;
      },
      (err) => {
        console.log(err);
        this.loading = false;
      }
    );
  }
  slicepProduct() {
    this.nbrProduct = this.nbrProduct + 5;
    this.productDTOShow = this.productDTOList.slice(0, this.nbrProduct);
  }
  getClassification() {
    console.log("catIDDDD", this.catId);
    this.b2bserviceService.getCatById(this.catId).subscribe((resp: any) => {
      // this.subCategory = resp;&&
      // this.catName= resp.nameCategorie;
      this.listClass = resp.subCategories;
      this.catImg = resp.icon;
      console.log("suuuubs", this.listClass);
      this.sliceCategorie();
    });
  }
  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listDisplayClass = this.listClass.slice(0, this.nbrSlice);
    if (this.listDisplayClass.length == this.listClass.length) {
      this.reached = true;
    }
  }
  addCategorie(classif) {
    if (!this.listClassfication.includes(classif.name)) {
      this.listClassfication.push(classif.name);
      console.log(this.listClassfication);
    } else {
      let index: number = this.listClassfication.indexOf(classif.name);
      if (index !== -1) {
        this.listClassfication.splice(index, 1);
      }
    }
    this.search();
    console.log(this.listClassfication);
  }
  deleteCountry(idCountry, item) {
    let index: number = this.listfiltercountry.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.listfiltercountry.splice(index, 1);
    }
    if (index2 !== -1) {
      this.listShowcountry.splice(index2, 1);
    }
    this.search();
    console.log(this.listfiltercountry);
  }
  goToLiveChat(item) {
    console.log("---------- item  --------- : ", item);
    this.router.navigate(["/profil/liveChat/"], {
      queryParams: { email: item },
    });
  }
  checkExist(subname) {
    if (this.listClassfication.includes(subname)) {
      return true;
    } else {
      return false;
    }
  }
  addCountry(idCountry, item) {
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item);
      console.log(this.listfiltercountry);
    }
    this.search();
    console.log(this.listfiltercountry);
  }

  animationCreated(animationItem: AnimationItem) {}
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      this.slicepProduct();
    }
  }

  bottomReached(): boolean {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight;
  }

  checkExistCountry(id) {
    if (this.listfiltercountry.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  showModal(item): void {
    console.log("show");
    this.selectProd = item;
    this.isVisible = true;
  }
  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== "uploading") {
      console.log(file, fileList);
    }
    if (status === "done") {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === "error") {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data = this.ValidateFile(file);
      if (!data) {
        this.fileList = [];
        this.listEmplty = true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file) {
    return true;
  }
  sendMail() {
    let data = {
      receiver: this.selectProd.user.email,
      message: this.messageToSend,
      subject: this.selectProd.productName,
      productId: this.selectProd.id,
      isBuyLeadInquiry: false,
      isWebInquiry: true,
      isAllCountry: this.isAllCountry,
      isAllCompany: this.isAllCompany,
      location: this.selectProd.user.country,

      // subject: this.subject,
    };
    console.log();

    this.mailService.sendMessage(data).subscribe(
      (resp) => {
        console.log("resap1 : ", resp);
        this.toaster.success(
          "The inquiry has been sent succefully",
          "Success",
          {
            closeButton: true,
            timeOut: 500,
          }
        );
        this.isVisible = false;
      },
      (err) => {
        // console.log("erorrrrrrrr !" , err);
        // this.toaster.error(
        //   err.error.meesage,
        //   "Error",
        //   {
        //     closeButton: true,
        //     timeOut: 5000
        //   })
      }
    );
  }
  addTointer(prod) {
    let type = "product";
    this.b2bserviceService
      .addToInterest(prod.id, type)
      .subscribe((resp: any) => {
        prod.isInterested = true;
        this.toaster.success(
          prod.productName + " has been added to your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
      });
  }

  removeFrominter(prod) {
    let type = "product";
    this.b2bserviceService
      .removeInterest(prod.id, type)
      .subscribe((resp: any) => {
        prod.isInterested = false;
        this.toaster.info(
          prod.productName + " has been removed from your interests",
          "",
          {
            closeButton: true,
            timeOut: 5000,
          }
        );
      });
  }
  openInNewWindow(prod) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/Product/${prod.id}`])
    );

    window.open(url, "_blank");
  }
}
