import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';

@Component({
  selector: 'app-search-buy-leads',
  templateUrl: './search-buy-leads.component.html',
  styleUrls: ['./search-buy-leads.component.css']
})
export class SearchBuyLeadsComponent implements OnInit {
  public formSearch = new FormGroup({});
  public listafricanCountries = [];
  public listPeriod = [];
  public motCle = null;
  public country = null;
  public month = null;

  constructor(private sharedService: SharedService, private fb: FormBuilder,private buyRequestService: BuyRequestService) { }

  ngOnInit() {
    this.createForm();
    this.getCountries();
    this.getAllPeriod();
    this.search();
  }
  createForm() {
    this.formSearch = this.fb.group({  
      motCle: [''],
      country: [''] , 
      month: ['']  
    });
  }

  search(){
    let data={
      product:this.motCle,
      country:this.country,
      period:this.month
    }
    this.sharedService.nextSearchBuyLeads(data);
  }

  getAllafricanCountries(){
    this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }
  getCountries(){
    this.buyRequestService.getCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
      console.log(resp); 
    })
  }
  getAllPeriod(){
    this.buyRequestService.getperiod().subscribe((resp: any) => {
      this.listPeriod=resp
      console.log(this.listPeriod);
      
    })
  }
}
