import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/commons/guards/auth";
import { KeyFeature } from "src/app/commons/models/KeyFeature";
import { PermissionGuard } from "../permission/guards/permission.guard";
import { AddProductComponent } from "./add-product/add-product.component";
import { SearchProductComponent } from "./search-product/search-product.component";
import { ShowcaseComponent } from "./showcase-product/showcase.component";

const routes: Routes = [
  { path: "add-product", component: AddProductComponent, pathMatch: "full" },
  {
    path: "search-product",
    component: SearchProductComponent,
    pathMatch: "full",
  },
  {
    path: "showcase-product",
    component: ShowcaseComponent,
    pathMatch: "full",
    canActivate: [AuthGuard , PermissionGuard],
    data: {
      roles: ["ROLE_ADMIN", "ROLE_SELLER", "ROLE_BOTH"],
      permissions: [KeyFeature.SHOWCASE_YOUR_PRODUCT_SERVICE],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ProductRoutingModule {}
