import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public formContact = new FormGroup({});
  constructor(private formBuilder: FormBuilder,
    private productService:B2bProductService ,
    private msg: NzMessageService,private router: Router) { }

  ngOnInit() {
    this.createFormContact();
  }
  createFormContact() {

    this.formContact = this.formBuilder.group({
      email: ['', [Validators.required,Validators.email]],
      name: ['', [Validators.required]],
      company: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }
  send(){
    if(this.formContact.valid){
    let data={
      name:this.formContact.value.name,
      subject:this.formContact.value.subject,
      companyName:this.formContact.value.company,
      message:this.formContact.value.message,
      email:this.formContact.value.email,
       };

    this.productService.addContact(data).subscribe((resp: any) => {
      console.log(resp)
      this.router.navigateByUrl('/');
  }
  )
}else{
  this.msg.error('Please fill in all mandatory fields') ;
}
}
}
