import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { Imodule } from "src/app/modules/dashboard-admin/components/key-feature-modal/key-feature-modal.component";
import { SubAdminService } from "src/app/modules/sub-admin/services/sub-admin.service";
// import { SubAdmin } from "src/app/modules/sub-admin/sub-admin.model";
import { environment } from "../../../environments/environment";
import { KeyFeature } from "../models/KeyFeature";
import { User } from "../models/User";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  private baseUrl = environment.baseUrl;


  constructor(
    private http: HttpClient,
    // private subAdminService: SubAdminService,
    private userService: UserService
  ) {}
  currentUser = this.userService.currentUser;

  Init(id:any) {
    return id
      ? this.http
          .get<any>(`${this.baseUrl}/user/getUserHistory/${id}`)
          .pipe(
            map((res) => {
              let permissions: KeyFeature[] = [];
              res &&
                res["keyFeaturs"] &&
                res["keyFeaturs"].map((module: Imodule) => {
                  module.featurs.forEach((permission) => {
                    permissions.push(permission.name as KeyFeature);
                  });
                });

              return Array.from(new Set([].concat.apply([], permissions)));
            })
          )
          .toPromise()
      : of().toPromise();
  }

  // initSubAdmin(id?: string) {
  //   return id
  //     ? this.http
  //         .get<any>(`${this.baseUrl}/sub-admin/${id}`)
  //         .pipe(
  //           map((subAdmin: SubAdmin) => {
  //             this.subAdminService.permissions = subAdmin.permissionDTOS;
  //           })
  //         )
  //         .toPromise()
  //     : of().toPromise();
  // }

  getCurrentUser() {
    return this.http
      .get<any>(`${this.baseUrl}/user/current-user`)
      .pipe(
        map((currentUser: any) => {
          console.log("currentUser", currentUser);
          this.userService.currentUser = currentUser;
        })
      )
      .toPromise();
  }
}
