import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { BuyRequestService } from "src/app/commons/services/service buy-request/buy-request.service";
import { FreightService } from "src/app/commons/services/service freight/freight.service";
import * as countriesJson from '../../../assets/js/countries.json';
import { regioData } from '../../../assets/js/geo_eco_region';
import {UserService} from '../../commons/services/user.service';
import {NzMessageService, NzTreeSelectComponent} from 'ng-zorro-antd';
import {ContactService} from '../../commons/services/contacts/contact.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {MailService} from '../../commons/services/mail-service';
import {SharedService} from '../../commons/shared service/shared-service';
import {FinanceService} from '../../commons/services/access-to-finance/finance.service';
import {environment} from '../../../environments/environment';

interface Countryiso {
  country: string,
  number: number,
  isocode: string,
  flag: string
}
@Component({
  selector: "app-freight-request",
  templateUrl: "./freight-request.component.html",
  styleUrls: ["./freight-request.component.css"],
})
/* export class FreightRequestComponent implements OnInit {
  public motCle;
  public mode;
  public origin;
  public destination;
  public month;
  public listPeriod = [];
  public originCountries = [];
  public destinationCountries = [];
  public listData = [];
  public loading = true;

  public formSearch = new FormGroup({});
  constructor(
    private fb: FormBuilder,
    private buyRequestService: BuyRequestService,
    private freightService: FreightService
  ) {}

  ngOnInit() {
    this.getAllPeriod();
    this.getOriginCountries();
    this.getDestinationCountry();
    this.createForm();
    this.search();
  }
  getAllPeriod() {
    this.buyRequestService.getperiod().subscribe((resp: any) => {
      this.listPeriod = resp;
      console.log(this.listPeriod);
    });
  }
  getOriginCountries() {
    this.freightService.getOriginCountries().subscribe((resp: any) => {
      this.originCountries = resp;
    });
  }
  getDestinationCountry() {
    this.freightService.getDestinationCountry().subscribe((resp: any) => {
      this.destinationCountries = resp;
    });
  }
  createForm() {
    this.formSearch = this.fb.group({
      origin: [""],
      destination: [""],
      mode: [""],
      month: [""],
    });
  }
  search() {
    let data = {
      originCountry: this.origin,
      destinationCountry: this.destination,
      shippingMode: this.mode,
      period: this.month,
    };
    this.freightService.searchPostFreight(data).subscribe((resp: any) => {
      this.listData = resp;
      console.log("listdata", this.listData);
    });

    if (
      data.originCountry == null &&
      data.destinationCountry == null &&
      data.shippingMode == null &&
      data.period == null
    ) {
      this.loading = false;
    } else {
      this.loading = true;
    }
  }
}
 */
export class FreightRequestComponent implements OnInit {
  @ViewChild("nzTreeComponent", { static: false })
  nzTreeComponent: NzTreeSelectComponent;
  // public listCAtegories = ['Associations', 'Airlines', 'Cargo Handling Agents', 'Aviation Service Providers', 'Port', 'Airports', 'Airlines GSAs/GSSAs', 'Services/Suppliers to the Industry', 'Express Operators & Couriers', 'Cargo Agents/Freight Forwarders', 'Charter Brokers'];
  listCAtegories = ["Seaport", "Freight forwarder", "Air Cargo", "Railway", "Couriers", "Logistics", "Warehousing", "Trucking", "Forwarding", "Inspection", "Customs brokers","Customs"];
  public listCAtegoriesFilter: any[] = [];
  public listCAtegories1: any[] = [];
  public listChart = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z'];
  public listItem = [];
  listItem2: any;
  private listItemIndirect: any[] =[];
  public listdataSlice: any[] = [];
  public listdataSliceAll: any[] = [];
  public listdataSliceIndirect: any[] = [];

  introductionTitle: any = "Freight/ Logistics Service Provider"
  public selectedCategories = [];
  public selectedChart = null;
  public nbrSLice = 0;
  public nbrSLiceData: number = 0;
  public nbrSLiceDataAll: number = 0;
  public nbrSLiceDataIndirect: number = 0;

  public loading = false;
  public styles: Partial<CSSStyleDeclaration> = {
    width: '27%',
    margin: 'auto',
    height: '24%',
  };
  public binding: any
  public listfiltercountry = [];
  public listShowcountry = [];
  public listShowcountryLabel = [];
  public ExportflowSelectedFillter = [];
  public countryList = [];

  public reciver = null;
  isVisible = false;
  companies: any;
  filterData: any;
  public company = null;
  countries: any = (countriesJson as any).default;
  allcountries: any = (countriesJson as any).default;
  amountOperator = "gt"
  SupColor = "#0591ff"
  InfColor = "#fec537"
  Egcolor = "#fec537"
  selectedCountries: Countryiso[] = []
  countriesRigionsTo = regioData
  selectedCountryTo = "";
  flagUrlForFilter: any;
  lowerAmount: any = null;
  upperAmount: any = null;
  flagUrl: any;
  contactForm = new FormGroup({});
  isVisible1: boolean = false;
  idContact: any;
  requestMeetvisible: boolean = false;
  RequestMeetForm = new FormGroup({});
  contact: any = {};
  userId: any;
  user: any;
  financeValue: number;
  myRegionEcoSelected: any[] = [];
  myRegionGeoSelected: any[] = [];
  sendingCountry: any[] = [];
  regionType: string = "";
  emptyList: any[] = [];
  shippingMode: null;
  selectedCountriesFrom: any[] = [];
  selectedCountriesTo: any[] = [];
  companyName: null;
  africanList: any = this.allcountries.filter(item => item.in_africa != false)
  companyCountry: any[] = []
  allAfricanCountries = this.allcountries.filter(x => x.in_africa == true);
  criteria: any={};
  criteriaport: any={};
  criteriacities: any={};
  locationfrom :any[]=[]
  locationTo :any[]=[]
  destinationPosition:any=null
  originPosition:any =null
  customStyle: any = {
    background: '#F0F5FF',
    'border-radius': '10px ',
    border: '0px',
    top:'6px'
  }
  CompanyActive:boolean=false;
  ArrayOfShippement:any[]=['Land','Sea']
  nodesFrom = [
    {
      title: 'From :',
      key: '00',
      expanded: false,
      isocode:'',
      children: [

        {
          title: 'Morroco',
          key: '00',
          expanded: false,
          isocode:'MAR',
          children: [
            {
              title: 'Casablanca',
              key: '000',
              isLeaf: true
            },
            {
              title: 'Assilah',
              key: '001',
              isLeaf: true
            },
            {
              title: 'Tanger',
              key: '002',
              isLeaf: true
            }
          ]
        },
        {
          title: 'Egypt',
          key: '01',
          expanded: false,
          isocode:'EGY',
          children: [
            {
              title: 'Cairo',
              key: '010',
              isLeaf: true
            },
            {
              title: 'Smouha',
              key: '011',
              isLeaf: true
            },
            {
              title: 'Alexandaria',
              key: '012',
              isLeaf: true
            }
          ]
        },
        {
          title: 'Tunisia',
          key: '02',
          expanded: false,
          isocode: 'TUN',
          children: [
            {
              title: 'Sousse',
              key: '020',
              isLeaf: true,
            },
            {
              title: 'Sfax',
              key: '021',
              isLeaf: true,
            }
          ]
        }
      ]
    }
  ];
  nodesTo = [
    {
      title: 'To :',
      key: '00',
      expanded: false,
      isocode:'',
      children: [

        {
          title: 'Morroco',
          key: '00',
          expanded: false,
          isocode:'MAR',
          children: [
            {
              title: 'Casablanca',
              key: '000',
              isLeaf: true
            },
            {
              title: 'Assilah',
              key: '001',
              isLeaf: true
            },
            {
              title: 'Tanger',
              key: '002',
              isLeaf: true
            }
          ]
        },
        {
          title: 'Egypt',
          key: '01',
          expanded: false,
          isocode:'EGY',
          children: [
            {
              title: 'Cairo',
              key: '010',
              isLeaf: true
            },
            {
              title: 'Smouha',
              key: '011',
              isLeaf: true
            },
            {
              title: 'Alexandaria',
              key: '012',
              isLeaf: true
            }
          ]
        },
        {
          title: 'Tunisia',
          key: '02',
          expanded: false,
          isocode: 'TUN',
          children: [
            {
              title: 'Sousse',
              key: '020',
              isLeaf: true,
            },
            {
              title: 'Sfax',
              key: '021',
              isLeaf: true,
            }
          ]
        }
      ]
    }
  ];
  countriesTest :any[]=[
    {
      "label": "United States",
      "value": "United States",
      "id": "18",
      "type_pays": "Country",
      "isocode": "USA",
      "region": "Americas",
      "flag": "assets/countries/UMI.svg",
      "currency_code": "USD",
      "currency_name": "United States Dollar",
      "in_africa": false,
      "positions":[
        'California','oklahoma','florida',"NY",'Los Angles'
      ]
    },
    {
      "label": "France",
      "value": "France",
      "id": "20",
      "type_pays": "Country",
      "isocode": "FRA",
      "region": "Europe",
      "flag": "assets/countries/FRA.svg",
      "currency_code": "EUR",
      "currency_name": "European Euro",
      "in_africa": false,
      "positions":[
        'Paris','Marseille','Monaco',"Nice",'Lille'
      ]
    },
    {
      "label": "Afghanistan",
      "value": "Afghanistan",
      "id": "96",
      "type_pays": "Country",
      "isocode": "AFG",
      "region": "assets/countries/Asia",
      "flag": "assets/countries/AFG.svg",
      "currency_code": "AFN",
      "currency_name": "Afghanistani Afghani",
      "in_africa": false,
      "positions":[
        'kaboul'
      ]
    }
  ]
  indextest :number = 0;
  shippingInfoVisibile: boolean;
  shippingInfoVisibileDirect: boolean;
  listOfData:any[]=[]
  locationLabel: any = "Airport";


  postFreight:FormGroup;
  shippingModeList =
    [
      {
        label: 'Air Freight',
        value: 'air'
      },
      {
        label: 'Sea Freight',
        value: 'sea'
      },
      {
        label: 'Land Freight',
        value: 'land'
      }
    ];
  listAllItem: any[] =[];
  public ExportflowSelected : any[] = [];
  listIndicators =['companyName','country','region_geo','region_eco','shippingMode','destinationCountries','originCountries','originPositions','destinationPosition','activityType']
  checkAllNull =false;
  checkIndirectNull=false;
  constructor(private userService: UserService, private msg: NzMessageService,
              private contactService: ContactService,
              private toaster: ToastrService,
              private router: Router,
              private mailService: MailService,
              private fb: FormBuilder, private freightService: FreightService, private sharedService: SharedService, private financeService: FinanceService) {
  }

  ngOnInit() {
    console.log("filter data", this.filterData);
    // this.getTypeActivity();
    this.search();
    this.initContactForm();
    this.getUser();
    this.initRequestMeetForm();
    // this.getAllCountries();
    // this.sliceCategorie();
    // this.findCountriesObject(this.filterData.country);
  }

  //sending mail
  mailObject: any = {}

  sendMail(object,item) {

    this.mailService.senMail(object).subscribe((data: any) => {
      item.isVisible1=false
      item.requestMeetvisible=false
      this.toaster.success("mail has been successfully sent")
    });
  }

  submitFormRequestMeeting(value: any,item): void {
    for (const key in this.RequestMeetForm.controls) {
      this.RequestMeetForm.controls[key].markAsDirty();
      this.RequestMeetForm.controls[key].updateValueAndValidity();
    }
    console.log(value)
    if (this.RequestMeetForm.valid)
      this.sendMail(value,item)
  }

  countryLabel: any;

  findCountriesObject(val) {

    this.countries.forEach(element => {
      if (element.isocode.toLowerCase() == val.toLowerCase()) {
        console.log("there is a country", element.value);
        this.countryLabel = element.value;
        // this.addCountry(element.value,element);
      }
    });
    console.log("list of countries : ", this.countryList);
    this.countryList.forEach(element => {
      if (element.country.toLowerCase() == this.countryLabel.toLowerCase()) {
        this.addSelectedCountry(element.country, element);
      }
    })
  }

  sliceCategorie() {
    this.nbrSLice = this.nbrSLice + 4;
    this.listCAtegories1 = this.listCAtegories.slice(0, this.nbrSLice);
  }

  selectedSort(item) {
    if (this.selectedChart == item) {
      this.selectedChart = null
      this.search();
    } else {
      this.selectedChart = item;
      this.search();
    }
  }

  sliceData() {
    this.nbrSLiceData = this.nbrSLiceData + 4;
    this.listdataSlice = this.listItem.slice(0, this.nbrSLiceData);
  }

  sliceDataIndirect() {
    this.nbrSLiceDataIndirect = this.nbrSLiceDataIndirect + 4;
    this.listdataSliceIndirect = this.listItemIndirect.slice(0, this.nbrSLiceDataIndirect);
  }
  sliceDataAll() {
    this.nbrSLiceDataAll = this.nbrSLiceDataAll + 4;
    this.listdataSliceAll = this.listAllItem.slice(0, this.nbrSLiceDataAll);
  }
  checkExist(id) {
    if (this.selectedCategories.includes(id)) {
      return true
    } else {
      return false
    }
  }

  addCategorie(item) {
    if (!this.selectedCategories.includes(item)) {
      this.selectedCategories.push(item);
      this.search();
    } else {
      let index: number = this.selectedCategories.indexOf(item);
      if (index !== -1) {
        this.selectedCategories.splice(index, 1);
        this.search();
      }
    }

  }

  onScroll() {
    console.log("scrolled!!");
    this.sliceData()
  }
  onScrollAll() {
    console.log("scrolled!!");
    this.sliceDataAll()
  }
  onScrollIndirect() {
    console.log("scrolled indirect!!");
    this.sliceDataIndirect()
  }
  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //   if (this.bottomReached()) {
  //     //  console.log('Load Your Data Here');
  //     this.sliceData();
  //   }
  // }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.scrollHeight;
  }

  addCountry(idCountry, item) {
    console.log('id country :', idCountry);
    console.log('item country :', item);
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item)
      this.listShowcountryLabel.push(item.isocode)
      console.log('filter country :', this.listfiltercountry);
    }
    this.search();
    console.log(this.listfiltercountry);
  }

  clear() {
    console.log("before", this.selectedCategories)
    this.selectedCategories = [];
    this.listCAtegories1 = [];
    console.log("after", this.selectedCategories)
    this.selectedChart = null;
    this.listfiltercountry = [];
    this.listShowcountry = [];
    this.listShowcountryLabel = [];
    this.company = null;
    this.search();
  }

  addSelectedCountry(idCountry, item) {
    console.log('id country :', idCountry);
    console.log('item country :', item);
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item)
      this.listShowcountryLabel.push(item.isocode)
      console.log('filter country :', this.listfiltercountry);
    }
  }
  onSearch(labelDestination: string, value: any[]): void {
    console.log('value :>> ', value);
    if (this.shippingMode == 'intermodal') {
      this.intermodalRequest(labelDestination, value)
    }
    if (this.shippingMode == 'sea') {
      console.log('location Label',this.locationLabel[0])
      this.filterPositionChange(labelDestination,'port',value)
    }
    if (this.shippingMode == 'air') {
      this.filterPositionChange(labelDestination,'airport',value)
    }
    if (this.shippingMode == 'land') {

      this.filterPositionChange(labelDestination,'city',value);
    }
  }
  intermodalRequest(lableDestination :string,value :any[]){
    this.criteria.type = 'airport'
    this.criteriaport.type='port'
    this.criteriacities.type='cities'
    let list = [];
    list =value.map(item => item.isocode);
    if(lableDestination == 'From'){
      this.locationfrom = []
      this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
        this.locationfrom = this.locationfrom.concat(res)
      });
      this.freightService.getLocationFreight(this.criteriaport, list).subscribe((res: any) => {
        this.locationfrom = this.locationfrom.concat(res)
      });
      this.freightService.getLocationFreightCities(this.criteriacities, list).subscribe((res: any) => {
        this.locationfrom = this.locationfrom.concat(res)
      });
    }else if(lableDestination == 'To') {
      this.locationTo = []
      this.freightService.getLocationFreight(this.criteria, list).subscribe((res: any) => {
        this.locationTo = this.locationTo.concat(res)
      });
      this.freightService.getLocationFreight(this.criteriaport, list).subscribe((res: any) => {
        this.locationTo = this.locationTo.concat(res)
      });
      this.freightService.getLocationFreightCities(this.criteriacities, list).subscribe((res: any) => {
        this.locationTo = this.locationTo.concat(res)
      });
    }
  }
  filterPositionChange(labelDestination: string, shippingMode:string,value: any[]) {
    //console.log('this.IntermodelFrom :>> ', this.IntermodelFrom);
    this.criteria.type = shippingMode
    let list = [];
    list =value.map(item=> item.isocode)
    //list.push(value.isocode);
    if (shippingMode != 'city' ) {
      if(labelDestination == 'From'){
        this.freightService.getLocationFreight(this.criteria, value).subscribe((res: any) => {
          this.locationfrom = res
        });
      }else if(labelDestination == 'To'){
        this.freightService.getLocationFreight(this.criteria, value).subscribe((res: any) => {
          this.locationTo = res
        });
      }
    } else {
      if(labelDestination == 'From') {
        this.freightService.getLocationFreightCities(this.criteria, value).subscribe((res: any) => {
          this.locationfrom = res
        });
      }else if (labelDestination == 'To'){
        this.freightService.getLocationFreightCities(this.criteria, value).subscribe((res: any) => {
          this.locationTo = res
        });
      }
    }
  }
  search() {
    //this.loading = true;
    this.nbrSLice = 0;
    this.nbrSLiceData = 4;
    this.nbrSLiceDataAll = 4;
    this.nbrSLiceDataIndirect = 4;
    console.log("nzTreeComponent", this.nzTreeComponent)
    if (this.nzTreeComponent != undefined) {
      console.log('this.nzTreeComponent.getCheckedNodeList()[0] :>> ', this.nzTreeComponent.getCheckedNodeList()[0]);
      this.myRegionEcoSelected = this.nzTreeComponent.getCheckedNodeList()
        .map((item) => {
          if (item.parentNode.key == "0-0") {
            return item.origin.key;
          }
        })
        .filter(
          (value, index, self) => self.indexOf(value) === index && !!value
        );
      this.myRegionGeoSelected = this.nzTreeComponent.getCheckedNodeList()
        .map((item) => {
          if (item.parentNode.key == "0-1") {
            return item.origin.key;
          }
        })
        .filter(
          (value, index, self) => self.indexOf(value) === index && !!value
        );
    }
    console.log("all eco and geo regions", this.myRegionEcoSelected)
    let data = {
      companyName: this.companyName,
      country: this.companyCountry,
      region_geo: this.myRegionGeoSelected.length > 0 ? this.myRegionGeoSelected : [],
      region_eco: this.myRegionEcoSelected,
      shippingMode: this.shippingMode,
      destinationCountries: this.selectedCountriesTo,
      originCountries: this.selectedCountriesFrom,
      originPositions:this.originPosition != '' ? this.originPosition : null,
      destinationPosition:this.destinationPosition != '' ? this.destinationPosition : null,
      activityType:this.ExportflowSelected,
    }
    //this.checkNullSearchField(data);
    console.log('objet', data);
    this.changePositionLabel();
    this.freightService.searchFreightProvider(data).subscribe((resp: any) => {
      console.log("freight services data :", resp);
      this.listItem = resp.direct;
      this.listItemIndirect =resp.indirect;
      this.listItem2 = resp.direct;
      this.listAllItem = resp.direct;
      this.checkIndirectNull = this.listItemIndirect == null || this.listItemIndirect == undefined
      //this.checkAllNull = !this.listItemIndirect;
      console.log('this.listItem :>> ', this.listAllItem);
      //activity type
      this.listCAtegoriesFilter = this.listItem
        .map((item) => item.freightActivityType)
        .filter((value, index, self) => self.indexOf(value) === index);
      console.log('this.listCAtegoriesFilter :>> ', this.listCAtegoriesFilter);

      if(this.selectedCountriesFrom.length !== 0) {
        this.onSearch('From', this.selectedCountriesFrom);
      }
      if(this.selectedCountriesTo.length !== 0){
        this.onSearch('To',this.selectedCountriesTo)
      }
      this.sliceCategorie();
      this.sliceData();
      if(!this.checkIndirectNull) {this.sliceDataIndirect();}
      this.sliceDataAll();
      this.loading = false;
    }, (err) => {
      console.log(err);

    })
  }
  changePositionLabel(){
    if(this.shippingMode === "sea"){
      this.locationLabel ="Port";
    }
    if(this.shippingMode === "air"){
      this.locationLabel ="Airport";
    }
    if(this.shippingMode === "land"){
      this.locationLabel ="City";
    }
  }
  filterByQuery() {
    this.loading = true;

    // need to fix
    // this.myRegionSelected = this.nzTreeComponent
    //     .getCheckedNodeList()
    //     .map((item) => {
    //         if (item.parentNode.key == "0-1") return item.origin.title;
    //     })
    //     .filter(
    //         (value, index, self) => self.indexOf(value) === index && !!value
    //     );

    let query = {
      freight_type: this.ExportflowSelectedFillter,
      freight_provider: this.companies,
      country: this.listShowcountryLabel.length == 0 ? this.selectedCountries : this.listShowcountryLabel,
      region_geo: this.myRegionGeoSelected,
      region_eco: this.myRegionEcoSelected,
      amount: this.financeValue,
      how: this.amountOperator,
    };


    this.nbrSLice = 0
    console.log("my Object==========>  ", query)
    this.freightService.searchFreightProvider(query)
      .subscribe((result: any) => {
        this.listItem = result.results
        if (this.listItem) {
          this.loading = false
        }
        // console.log("my result =========", this.listItem)
        // if (this.listItem.length != 0) {
        //     this.financeBase = this.listItem
        //     this.loading = false
        // }
        // if (this.listItem.length == 0) {
        //     this.financeBase = this.emptyList
        // }


        this.sliceData()
        this.sliceDataIndirect();
        console.log("Loading =============", this.loading)
      });


  }


  // getTypeActivity(){
  //   this.freightService.getAllTypes().subscribe((type :any)=>{
  //     console.log("freight services Type Activity :",type);
  //     this.listCAtegories=type;
  //   })
  // }

  getAllCountries() {
    this.freightService.getcountries().subscribe((resp: any) => {
      this.countryList = resp
      this.countryList = this.countryList.filter(cnt => cnt !== null);
    })
  }

  addTagFn(name) {
    return { name: name, tag: true };
  }

  changeFindCompanies(val) {
    console.log("tag companies ==> ", val)
  }

  showModal(item): void {
    this.reciver = item
    this.isVisible = true;
  }

  getFlag(isoCode: any): boolean {
    this.allcountries.forEach((element) => {
      if (element.isocode == isoCode) {
        this.flagUrl = element.flag;
      }
    });
    return true;
  }
  getFlagFreight(isoCode: any) {
    this.allcountries.forEach((element) => {
      if (element.isocode == isoCode) {
        return  element.flag;
      }
    });
  }
  loadFinanceResult(itemResult: any) {
    this.financeService.sharedFreightDetails = itemResult;
    console.log('item details opp :', itemResult);
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }


  deleteCountry(idCountry, item) {
    let index: number = this.listfiltercountry.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.listfiltercountry.splice(index, 1);
    }
    if (index2 !== -1) {
      this.listShowcountry.splice(index2, 1);
      this.listShowcountryLabel.splice(index2, 1);
    }
    this.search();
    console.log(this.listfiltercountry);
  }

  checkExistCountry(id) {
    if (this.listfiltercountry.includes(id)) {
      return true
    } else {
      return false
    }
  }

  btnFilter(value) {
    if (value == "lt") {
      this.SupColor = "#fec537"
      this.InfColor = "#0591ff"
      this.Egcolor = "#fec537"
      this.amountOperator = value;
    } else if (value == "eq") {
      this.SupColor = "#fec537"
      this.InfColor = "#fec537"
      this.Egcolor = "#0591ff"
      this.amountOperator = value;
    } else if (value == "gt") {
      this.SupColor = "#0591ff"
      this.InfColor = "#fec537"
      this.Egcolor = "#fec537"
      this.amountOperator = value;
    }

  }

  getFlagForCountrySelect(labelCountry: any): boolean {
    // console.log("country input :",labelCountry);
    this.allcountries.forEach((element) => {
      if (element.label == labelCountry) {
        this.flagUrlForFilter = element.flag;
      }
    });
    return true;
  }

  initContactForm() {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      file: [null],
      receiver: [''],
      section: [''],
      accessFinance: ['']

    })
  }

  initRequestMeetForm() {
    this.RequestMeetForm = this.fb.group({
      topic: ["", [Validators.required]],
      message: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],

    });
  }

  contactNow(receiver: any, financeOpportunities: any) {
    for (const i in this.contactForm.controls) {
      this.contactForm.controls[i].markAsDirty();
      this.contactForm.controls[i].updateValueAndValidity();
    }

    this.contact.subject = this.contactForm.value.subject;
    // console.log("subject " + this.contact.subject);
    this.contact.message = this.contactForm.value.message;
    // console.log("message " + this.contact.message);
    this.contact.receiver = receiver;
    // console.log("Reciever ")
    //console.log(this.contact.receiver);
    //console.log("ID " + receiver.id)
    this.contact.section = "FinancingOpportunities"
    this.contact.financingOpportunities = financeOpportunities;
    //console.log(this.contact.financingOpportunities)
    console.log("receier :", this.contact);
    this.contactService.contactNow(this.contact).subscribe((data: any) => {
      // console.log("Contact")
      // console.log(data);


      // if (this.fileList.length > 0) {
      //     console.log('file selected!!!!!!!!!')
      //     this.idContact = data.id;
      //     this.handleUpload(this.idContact);
      // }


      this.toaster.success(
        'Message Sent Successfully',
        "Success",
        {
          closeButton: true,
          timeOut: 4000
        })


    });
    this.initContactForm();
    this.contactForm.reset();

  }

  showRequestMeetingFrom(item: any) {
    let message = 'Dear Mr./Ms.' + item.companyName + ',\n' +
      '\n' +
      'I am writing to schedule a meeting to [write what the purpose of your meeting is]. If the time works for you, I would like to meet at [time] on [date] at [place].\n' +
      '\n' +
      'Please confirm whether that works for you or if another time and place is better. I look forward to our meeting.\n' +
      '\n' +
      'Thank you,\n' +
      this.user.lastName
    this.RequestMeetForm.patchValue({
      topic: "Request for meeting",
      message: message,
      email: item.contactEmail
    })
    item.requestMeetvisible = true
  }

  showContactNowForm(item: any) {
    //console.log("item contact now :", item)
    let message = 'Dear Mr./Ms. ' + item.companyName + ' ,\n' +
      '\n' +
      'I am writing to contact you in order to (purpose of your mail).\n' +
      '\n' +
      'Thank you,\n' +
      this.user.lastName
    this.RequestMeetForm.patchValue({
      topic: "no-replay",
      message: message,
      email: item.contactEmail
    })
    item.isVisible1 = true
  }

  getUser() {
    this.userId = this.userService.currentUser && this.userService.currentUser.id;
    console.log("user id first  :", this.userId);
    this.userService.findById(this.userId).subscribe((res: any) => {
      this.user = res;
    })
    console.log("user company :", this.user);

  }

  postFreightRequest(FreightProvider :any) {
    this.router.navigate(['tradeTools/freightOpportunitiesRequest'])
  }
  getCountryByIso(value) :string {
    let country = "";
    this.allcountries.forEach(element => {
      if (element.isocode == value)
        country = element.label
    });
    return country;
  }

  baseUrl = environment.baseUrl + "/company";

  getUrlImg(name) {
    return this.baseUrl + "/downloadImage/" + name+".jpg";
  }

  onImgError(event){
    event.target.src = '/assets/img/Mask_group.png'
    event.onerror = null;
  }
  showPanel(value:any){
    console.log(value);
    this.CompanyActive=!this.CompanyActive;
    console.log(this.CompanyActive);
  }
  clickList(value :any){
    console.log(value);
    this.indextest = value;
  }
  log(value){
    console.log(value);
  }
  handleCancelShippingInfo(){
    this.listOfData=[];
    this.shippingInfoVisibile=false;
  }
  handleCancelShippingInfoDirect(){
    this.listOfData=[];
    this.shippingInfoVisibileDirect=false;
  }
  showShippingInfo(data:any){
    this.listOfData=[];
    this.listOfData=data;
    console.log("data shipping",data);
    this.initFreightForm();
    this.shippingInfoVisibile=true;
  }
  showShippingInfoDirect(data:any){
    this.listOfData=[];
    this.listOfData=data;
    console.log("data shipping",data);
    this.initFreightForm()
    this.shippingInfoVisibileDirect=true;
  }
  initFreightForm(){
    this.postFreight = this.fb.group({
      shippingInfo: this.fb.array([this.populateFormGroup()]),
    })
  }
  private populateFormGroup(): any[] {
    console.log("in populate ");
    const currentModule = this.listOfData;
    const returnedFormArray: any[] = [];
    console.log('currentModule :>> ', currentModule);
    currentModule.map((shippingInfo) => {
      const date = new Date('' + shippingInfo["year"]);
      returnedFormArray.push(new FormGroup({
        id: new FormControl(shippingInfo["id"]),
        destinationCountries: new FormControl(new Date(shippingInfo["destination_countries"])),
        size: new FormControl(shippingInfo["destination_positions"]),
        originCountries: new FormControl(shippingInfo["origin_countries"]),
        originPositions: new FormControl(shippingInfo["origin_positions"]),
        shipmentKey: new FormControl(shippingInfo["shipment_key"]),
        shippingModeFrom: new FormControl(shippingInfo["shipping_mode_from"]),
        shippingModeTo: new FormControl(shippingInfo["shipping_mode_to"]),
        shippingOrder: new FormControl(shippingInfo["shipping_order"]),
        freightHubId: new FormControl(shippingInfo["freight_hub_id"]),
      }))
    });
    console.log('returnedFormArray :>> ', returnedFormArray);
    return returnedFormArray;
  }
  checkNullSearchField(data :any){
    let contr :number = 0;
    this.listIndicators.forEach((element:string) => {
      if(data[element] !== null && data[element] !== undefined){

        contr ++;
        console.log('true',element);
      }
      this.checkAllNull = contr > 0;
      console.log('false',data[element]);
    })
  }
}
