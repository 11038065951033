import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuyRequestService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }


  getafricanCountries () {
    return this.http.get(this.baseUrl + '/products/africanCountries');
  }
  getperiod() {
    return this.http.get(this.baseUrl + '/postBuyRequests/period');
  }
  getCountries() {
    return this.http.get(this.baseUrl + '/postBuyRequests/countries');
  }
  getCategories() {
    return this.http.get(this.baseUrl + '/postBuyRequests/getCategories');
  }
  getProductsCategories(id) {
    return this.http.get(this.baseUrl + '/postBuyRequests/getByCategory?category='+id);
  }
  getProductsById(id) {
    return this.http.get(this.baseUrl + '/postBuyRequests/'+id);
  }
  postSearch(data) {
    return this.http.post(this.baseUrl + '/postBuyRequests/search',data);
  }
  findMyPostBuyRequest(data) {
    return this.http.post(this.baseUrl + '/postBuyRequests/get-my-buyRequest',data);
  }
  allLeads() {
    return this.http.get(this.baseUrl + '/postBuyRequests');
  }
}
