import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
interface ItemData {
  sender: string; Requirement: string; Location: string; date: Date;
}
@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.css']
})
export class SentComponent implements OnInit {
  isCollapsed = false;
  public listCategories:any=[];
  public radioValueType="product";

  isVisible = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfAllData: ItemData[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  listOfName = [{ text: 'Web Inquiry', value: '' }, { text: 'Buy Lead Inquiry', value: '' }, { text: 'Chat', value: '' }];
  listOfData: Array<{sender: string; Requirement: string; Location: string; date: Date; [key: string]: string | number | Date }> = [];
  listOfDisplayData: Array<{ sender: string; Requirement: string; Location: string; date: Date; [key: string]: string | number | Date }> = [
    ...this.listOfData
  ];

  public search = "";
  public page = 1;
  public pageSize = 10;
  public total = 0 ;
  public isWeb = false;
  public isBuyLead = false;

  constructor(private b2bserviceService: B2bProductService,
                      private mailService: MailService,private profileRouteService : ProfileRouteService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("sent")
    this.getCategories(this.radioValueType);
    this.searchMessages();
  }
  currentPageDataChange($event: ItemData[]): void {
    this.refreshStatus();
  }



  searchMessages() {
    let data = {
      page: this.page-1,
      pageSize: this.pageSize,
      search: this.search,
      isBuyLead: this.isBuyLead,
      isWeb: this.isWeb,
      sortBy: "id",
      sort: "DESC"
    }

    this.mailService.searchsentMessages(data).subscribe((resp:any) => {
      console.log("response : " , resp);
      this.listOfDisplayData = resp.content
      
      this.total = resp.totalElements
      console.log(this.total);

    })
  }

  pageIndexChanged(page) {
    console.log("page" , page);
    this.searchMessages()
    
  }

  checkOne(values) {
    this.isWeb = values.includes("isWeb");
    this.isBuyLead = values.includes("isBuyLead");

    this.searchMessages();
  
    
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.sender]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.sender]) && !this.isAllDisplayDataChecked;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.sender] = value));
    this.refreshStatus();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  getTypeService(event){
    console.log("event",event);
    console.log("type",this.radioValueType);
    this.getCategories(event);
  }
  getCategories(radioValueType){
    this.b2bserviceService.getCategories(radioValueType).subscribe((resp: any) => {
      console.log(resp);
      this.listCategories=resp
    })
}

deleteMessage(id) {
  console.log("deleted");
  this.mailService.sendToTrash(id).subscribe(resp => {
    console.log("deleted" , resp);
    this.searchMessages();
  })
}
cancel() {
  console.log('Cancel Delete');
  
}

}
