import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  baseUrl = environment.baseUrl+"/reports";


  constructor(private http: HttpClient) { }

    getStockTrending(){
      return this.http.get(this.baseUrl+'/getStockTrending');
    }

    getLatestUpdatedStock(){
      return this.http.get(this.baseUrl+'/getLatestUpdatedStock');
    }

    getSumStockReport(){
      return this.http.get(this.baseUrl+'/getSumStockReport');
    }

    getStockReport(productId?) {
     return this.http.get(this.baseUrl + '/getStockReport/' , productId);
     }

     getTotalCount() {
      return this.http.get(this.baseUrl+"/getTotalCount");
    }

    getBuyerTotalCount() {
      return this.http.get(this.baseUrl+"/get_buyer_total_count");
    }

    getOrderStatus(analytic){
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+'/get_product_stat',formdata);
    }

    getSalesReportStat(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_sales_report_stat",formdata);
    }

    getBuyerSalesReport() {
      return this.http.get(this.baseUrl+"/get_buyer_sales_report");
    }

    getRevenueIncome(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_income_report",formdata);
    }

    getRevenueOutcome(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_outcome_report",formdata);
    }

    getRecentOrder(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_recent_order",formdata);
    }

    getBuyerRecentOrder(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_buyer_recent_orders",formdata);
    }

    getBuyerOrderStatus(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_buyer_orders_status",formdata);
    }

    getOrderTracking(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_country_amount",formdata);
    }

    getBuyerAnalytics(analytic) {
      let formdata = new FormData();
      formdata.append("date",analytic)
      return this.http.post(this.baseUrl+"/get_buyer_analytique_overview",formdata);
    }

    getBuyerProductPurchased() {
      return this.http.get(this.baseUrl+"/get_buyer_product_purchased");
    }

    getBuyerAudience() {
      return this.http.get(this.baseUrl+"/get_buyer_audience");
    }
    // public getIPAddress()
    // {
    //   return this.http.get("https://jsonip.com");
    // }
    getUserIp(ip) {
      return this.http.get(this.baseUrl+"/get-ip/"+ip );
    }

}
