import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PaypalService } from '../services/paypal-service/paypal-service.service';
import * as braintree from 'braintree-web';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayementService } from 'src/app/commons/services/stripe-payement/payement.service';
import { ActivatedRoute } from '@angular/router';
declare var PaymentSession;

declare var paypal;

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.css']
})
export class OrderPaymentComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  product = {
    price: 777.77,
    description: 'used couch, decent condition',
    img: 'assets/couch.jpg'
  };

  paidFor = false;

  @Input() orderId;
  showPaypalButton: boolean = false;
  authorizationKey = "AUTORISATION_KEY"
  nonce;
  public formShipping!: FormGroup;

  constructor(private paypalService : PaypalService , private fb: FormBuilder, private paymentService : PayementService
    ,private activatedRoute : ActivatedRoute) { }

  ngOnInit() {
    this.getParams();
    console.log("orderId : " , this.orderId);
    // this.getBrainTreeToken();
    this.createOnlinePaymentForm();
    paypal
    .Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: this.product.description,
              amount: {
                currency_code: 'USD',
                value: this.product.price
              }
            }
          ]
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paidFor = true;
        console.log(order);
      },
      onError: err => {
        console.log(err);
      }
    })
    .render(this.paypalElement.nativeElement);
  }
  getParams() {
    this.activatedRoute.queryParams.subscribe(res => {
     this.orderId= res.orderId;
     console.log("orderIDDDZZZ",this.orderId)
    })


  }
  createOnlinePaymentForm() {
      this.formShipping = this.fb.group({
        nameOnCard: [null, [Validators.required]],
        creditCard: [null, [Validators.required]],
        experationMonth: [null, [Validators.required]],
        experationYear: [null, [Validators.required]],
        cvc: [null, [Validators.required]],
      });
    }

  getPayPal() {
    return window && (window as any).paypal;
  }

  // xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/subscriptions/' + subcriptionId, true);
  //   xhttp.setRequestHeader('Authorization', this.basicAuth);

  //   xhttp.send();

  buyItemWithPaypal() {
    let order = {
      amount:100,
      product:{user:{subscription : {paypalEmail : "sb-ame0h5245001@business.example.com"}}}
    //   Payment payment = service.createPayment(order.getAmount(), "USD", "PAYPAL",
		// 			"sale" , "desc", SITE_URL + CANCEL_URL,
		// 			SITE_URL + SUCCESS_URL,order.getProduct().getUser().getSubscription().getPaypalEmail());
		// 	for(Links link:payment.getLinks()) {
     }
    this.paypalService.buyItemWithPaypal(this.orderId).subscribe((links :any) => {
      console.log("links : " , links);
      let redirectUrl = JSON.parse(links);
      console.log(redirectUrl.approvalUrl);

      window.location.href = redirectUrl.approvalUrl;
      // event.preventDefault();

      // window.location.href = "https://www.google.com/";
      // event.preventDefault();

      // window.location.replace("www.google.com");
      // window.location.href = links.approvalUrl;
      // window.open(links.approvalUrl);
      // (window as any).open(links.approvalUrl, "_blank");
      // http://localhost:4200/pay/success/34?paymentId=PAYID-MBE75XQ5B752056LF395002C&token=EC-6YF30337Y5157011E&PayerID=UANKHCZH57922
   });

  }

  buyItemWithCreditCard() {
    let that = this;
    braintree.client.create({
      authorization: this.authorizationKey
    }, function (err, client) {
      client.request({
        endpoint: 'payment_methods/credit_cards',
        method: 'post',
        data: {
          creditCard: {
            number: '4111111111111111',
            expirationDate: '10/20',
            cvv: '123',
            billingAddress: {
              postalCode: '12345'
            }
          }
        }
      }, function (err, response) {
        // Send response.creditCards[0].nonce to your server
        console.log("response Succeeed" , response);
        let nonce = response.creditCards[0].nonce;
        setTimeout(() => { that.paymentWithCreditCard(nonce); }, 500);

      });
    });

  }


  paymentWithCreditCard(nonce) {
    console.log("here !!!!!!!");


    this.paypalService.buyItemWithBraiinTree(34,nonce).subscribe((resp :any) => {
      console.log("pay with credit cARD");
      console.log("pay with credit cARD : " , resp );

   });
  }


  showPaypal() {
    this.showPaypalButton = !this.showPaypalButton;
    if(!this.showPaypalButton ) {
      setTimeout(() => {
        this.createPaymentSession();
      }, 1000);
    }
  }

  getBrainTreeToken() {
    this.paypalService.getBraintreetoken().subscribe((token: string) => {
      console.log("Tokeeeeeeeeeeeeeeeen ");
        console.log(token);
        this.authorizationKey = token
    })
  }
  // BraintreeIntegration

  clientDidCreate(err, client) {
    braintree.hostedFields.create({
      client: client,
      styles: {
        'input': {
          'font-size': '16pt',
          'color': '#3A3A3A'
        },

        '.number': {
          'font-family': 'monospace'
        },

        '.valid': {
          'color': 'green'
        }
      },
      fields: {
        number: {
          selector: '#cardNumber'
        },
        cvv: {
          selector: '#cvv'
        },
        expirationDate: {
          selector: '#expiration-date'
        }
      }
    }, console.log("Hosted fields created"));
  }

  sessionId ="";
  createPaymentSession() {
    console.log("create payment Session");
    let that = this;
    PaymentSession.configure({
      session: this.sessionId,
      fields: {
          // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
          card: {
              number: "#creditCard",
              securityCode: "#cvc",
              expiryMonth: "#experationMonth",
              expiryYear: "#experationYear",
              nameOnCard: "#nameOnCard"
          }
      },
      //SPECIFY YOUR MITIGATION OPTION HERE
      frameEmbeddingMitigation: ["javascript"],
      callbacks: {
          initialized: function(response) {
              // HANDLE INITIALIZATION RESPONSE
              console.log(response);
          },
          formSessionUpdate: function(response) {
              // HANDLE RESPONSE FOR UPDATE SESSION
              console.log("heeeeeeere :", response );

              if (response.status) {
                  if ("ok" == response.status) {
                      that.sessionId = response.session.id;
                      console.log("Session updated with data: " + response.session.id);
                      console.log("luuuul : " , that.sessionId);
                      that.passPaymentToBackend();
                      //check if the security code was provided by the user
                      if (response.sourceOfFunds.provided.card.securityCode) {
                          console.log("Security code was provided.");
                      }

                      //check if the user entered a Mastercard credit card
                      if (response.sourceOfFunds.provided.card.scheme == 'MASTERCARD') {
                          console.log("The user entered a Mastercard credit card.")
                      }
                  } else if ("fields_in_error" == response.status)  {

                      console.log("Session update failed with field errors.");
                      if (response.errors.cardNumber) {
                          console.log("Card number invalid or missing.");
                      }
                      if (response.errors.expiryYear) {
                          console.log("Expiry year invalid or missing.");
                      }
                      if (response.errors.expiryMonth) {
                          console.log("Expiry month invalid or missing.");
                      }
                      if (response.errors.securityCode) {
                          console.log("Security code invalid.");
                      }
                  } else if ("request_timeout" == response.status)  {
                      console.log("Session update failed with request timeout: " + response.errors.message);
                  } else if ("system_error" == response.status)  {
                      console.log("Session update failed with system error: " + response.errors.message);
                  }
              } else {
                  console.log("Session update failed: " + response);
              }
          },
        visaCheckout: function(response) {
            // HANDLE VISA CHECKOUT RESPONSE
            console.log("testing " , response);

        },
        amexExpressCheckout: function(response) {
           // HANDLE AMEX EXPRESS CHECKOUT RESPONSE
        }
      },
      interaction: {
          displayControl: {
              formatCard: "EMBOSSED",
              invalidFieldCharacters: "REJECT"
          }
      },
      order: {
          amount: 10.00,
          currency: "USD" ,
          id: that.orderId
      }
   });
  }

  pay() {
    // UPDATE THE SESSION WITH THE INPUT FROM HOSTED FIELDS
    PaymentSession.updateSessionFromForm('card');
}

  passPaymentToBackend() {
    this.paymentService.passCreditCardPayment(this.sessionId, this.orderId).subscribe(res => {
      console.log("returned value " , res);
      
    })
  }

}
