import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { valueChaintRoutingModule } from './valueChain-routing.module';
import { TradeValueChainComponent } from './trade-value-chain.component';
import { ListTradValueChainComponent } from './list-trad-value-chain/list-trad-value-chain.component';
 


@NgModule({
  declarations: [TradeValueChainComponent, ListTradValueChainComponent],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    valueChaintRoutingModule,
    FormsModule,
    ReactiveFormsModule 
  ]
})
export class TradeValueChainModule { }
