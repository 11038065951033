import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';

@Component({
  selector: 'app-profiles-header',
  templateUrl: './profiles-header.component.html',
  styleUrls: ['./profiles-header.component.css']
})
export class ProfilesHeaderComponent implements OnInit {
  selectedRoutes;
  constructor(private router: Router, private profileRouteService : ProfileRouteService) { }

  ngOnInit() {
    console.log("current route : " , );
    console.log(this.router.url);
    this.getProfileRoute();
  }

  getProfileRoute() {
    this.profileRouteService.sharedRoute.subscribe(currentRoute=> {
        console.log("current Route : " , currentRoute);
        this.routesFormula.forEach(routes => {
          if(routes.name == currentRoute)
              this.selectedRoutes = routes.valueRoutes;
        })
    })
  }

  // 1 - dashboard - liveChat - businessActivity - SalesActivity - Buisness Profile - Personal Profile - productServices

  routesFormula = [
    {name: 'dashboard' , valueRoutes: [{label : "Dashboard" , route : "dashboard" }] },
    {name: 'livechat' , valueRoutes: [{label : "Live Chat" , route : "livechat" }] },
    {name: 'myBuyLeads' , valueRoutes: [{label : "Buy Leads" , route : "myBuyLeads" }] },
    {name: 'myRFQs' , valueRoutes: [{label : "My RFQs" , route : "myRFQs" }] },
    {name: 'businessActivity' , valueRoutes: [{label : "Business Activity Reports" , route : "businessActivity" }] },
    {name: 'Business Profile' , valueRoutes: [
                                              {label : "Profile" , route : "business" },
                                              {label : "Business Profile" , route : "business" },
                                            ] },
    {name: 'Personal Profile' , valueRoutes: [
                                              {label : "Profile" , route : "business" },
                                              {label : "Personal Profile" , route : "personal" },
                                            ] },
    {name: 'productServices' , valueRoutes: [
                                              {label : "Profile" , route : "business" },
                                              {label : "My Products & Services" , route : "productServices" },
                                            ] },
  {name: 'Recieved' , valueRoutes: [
                                              {label : "Inquiries & Contacts" , route : "Recieved" },
                                              {label : "Inbox" , route : "Recieved" },
                                            ] },
  {name: 'sent' , valueRoutes: [
                                              {label : "Inquiries & Contacts" , route : "Recieved" },
                                              {label : "Sent" , route : "sent" },
                                            ] },
  {name: 'trash' , valueRoutes: [
                                              {label : "Inquiries & Contacts" , route : "Recieved" },
                                              {label : "Trash" , route : "trash" },
                                            ] },
  {name: 'spam' , valueRoutes: [
                                              {label : "Inquiries & Contacts" , route : "Recieved" },
                                              {label : "Spam" , route : "spam" },
                                            ] },
  {name: 'addNew' , valueRoutes: [
                                              {label : "Products/Services" , route : "addNew" },
                                              {label : "Add Service" , route : "addNew" },
                                            ] },
  {name: 'addNewService' , valueRoutes: [
                                              {label : "Products/Services" , route : "addNew" },
                                              {label : "Add Service" , route : "addNewService" },
                                            ] },
 {name:'interests' , valueRoutes: [
                                              {label : "Profile" , route : "addNew" },
                                              {label : "Interests" , route : "interests" },
                                            ] },
  {name: 'Manage' , valueRoutes: [
                                              {label : "Products/Services" , route : "addNew" },
                                              {label : "Manage Your Product" , route : "Manage" },
                                            ] },
  {name: 'featuringRequests' , valueRoutes: [
                                              {label : "Products/Services" , route : "addNew" },
                                              {label : "Featuring Requests" , route : "featuringRequests" },
                                            ] },
  {name: 'stockStatus' , valueRoutes: [
                                              {label : "Products/Services" , route : "addNew" },
                                              {label : "Stock Report" , route : "stockStatus" },
                                            ] },
  {name: 'ordersinvoices' , valueRoutes: [
                                              {label : "Orders Management" , route : "ordersinvoices" },
                                              {label : "Orders Invoices" , route : "ordersinvoices" },
                                            ] },
  {name: 'privacy' , valueRoutes: [
                                              {label : "Settings" , route : "privacy" },
                                              {label : "Inquiries" , route : "privacy" },
                                            ] },
  {name: 'deactivate' , valueRoutes: [
                                              {label : "Settings" , route : "privacy" },
                                              {label : "Deactivate Account" , route : "deactivate" },
                                            ] },
  {name: 'ChangePassword' , valueRoutes: [
                                              {label : "Settings" , route : "privacy" },
                                              {label : "Change Password" , route : "ChangePassword" },
                                            ] }
  ]
}
