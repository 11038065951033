import { Component, OnInit } from '@angular/core';
import { AuthProvider } from '../../commons/services/auth';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {

  isCollapsed: any;
  constructor(

    private readonly auth: AuthProvider,
  ) {

  }

  ngOnInit() {
  }

  signOut(): void {
    this.auth.signOut();
  }

}
export class DashboardAdminModule { }
