import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.css']
})
export class UpdateProductComponent implements OnInit {
  public current = 0;
  public index =1;
  radioValue = "NO";
  public radioValueType="product";
  public selectedCategory=null;
  public selectedCategory2=null;
  public selectedProductName=null;
  public selectedSubCategory=null;
  public selectedClassification=null;
  public selectedProductCode=null;
  public ProductDescription="";
  public video="";
  public tags = [];
  public inputVisible = false;
  public inputValue = null;
  public MinimumQuantity:number=null;
  public selectedUnitofMeasure=null;
  public Price:number=null;
  public MaximumPrice:number=null;
  public MinimumPrice:number=null;
  public quantity:number=null;
  public unitWeight=null;
  public selectedUnitofPrice=null;
  public prodImgs =[];
  public productToUpdate;
  public typePayment:any = [
    { label: 'Online web payment', value: 'Online web payment', checked: true },
    { label: 'Paypal', value: 'Paypal' },
    { label: 'Cheque', value: 'Cheque' },
    { label: 'Wire Transfer', value: 'Wire Transfer' },
    { label: 'Western Union', value: 'Western Union' },
    { label: 'Letter of Credit', value: 'Letter of Credit' },
    { label: 'Open Account', value: 'Open Account' }
  ];
  public listCheked:any=[];
  public SupplyAbility:any;
  public SupplyAbilityUnit:any;
  public DeliveryTime:any;
  public DeliveryTimeUnit:any;
  public PackagingDetails:any;
  public CertificationsDetails:any;
  public MainExport:any;
  public DomesticMarket:any;
  public listCategories:any=[];
  public listSubCategories:any=[];
  public classifications:any=[];
  public listafricanCountries:any=[];
  public fileProduct:any;
  public isDisabled:boolean=false;
  public formAddProduct = new FormGroup({});
  public formAddProduct2 = new FormGroup({});
  public selecteduUnitOfMeasureSupplyAbility=null;
  public listUnitOfMeasure:any=[];
  public listUnitOfPrice:any=[];
  public listSupplyAbilityFrequency:any=[];
  public listDeliverTimeFrequency:any=[];
  public listEmplty=true;
  public videoIsEmpty = true;
  public fileName="";
  fileList: UploadFile[] = [];
  videoList: UploadFile[] = [];
  public formData: FormData = new FormData();
  public loading=false;
  previewImage = '';
  previewVisible = false;
  uploadVideo = true;
  prodID;
  baseUrl = environment.baseUrl+'/';
  public SamplePolicy:any;
  public SamplePolicies:any=[
    "Free samples are available","Within a certain price range samples are available","Free samples are available with shipping and taxes paid by the buyer"
    ,"Sample costs shipping and taxes has to be paid by the buyer","If order is confirmed we will reimburse the sample cost",
    "Contact us for information regarding our sample policy"
  ];
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  constructor(private msg: NzMessageService, private fb: FormBuilder,private b2bserviceService: B2bProductService, private route : ActivatedRoute) { }

  ngOnInit() {

    this.createFormChiffre();
     this.getCategories(this.radioValueType);
     this.getAllafricanCountries();
     this.getAllUnitOfMeasure();
     this.getAllSupplyAbilityFrequency();
     this.getAllDeliverTimeFrequency();
     this.getAllUnitOfPrice();
     this.route.params.subscribe(data => {
      this.prodID = data.id;
      this.getProductbyId();
    });
  }

  getProductbyId() {
    this.b2bserviceService.getProductbyId(this.prodID).subscribe((resp:any) => {
        console.log("response get product : " , resp);
        this.productToUpdate=resp
        console.log("response get product : " , this.productToUpdate);
        this.prodImgs=resp.images;
        this.selectedProductCode = resp.productName
       this.ProductDescription = resp.productDescription
       resp.keywords.forEach(keyword => {this.tags.push(keyword)});

            this.selectedCategory = resp.category;
            this.selectedProductName = resp.subCategory
            this.selectedClassification = resp.classification

        // console.log("response get product : " , this.selectedCategory);
        // console.log("response get product : " , this.selectedProductName);
        // console.log("response get product : " , this.selectedClassification);
      //  this.listCategories.forEach(category => {
      //     if(resp.category == category.id) {
      //       this.selectedCategory = category;
      //       category.subCategories.forEach(subCategory => {
      //         if(resp.subCategory == subCategory.name ) {
      //           this.selectedProductName = subCategory
      //           subCategory.classification.forEach(classification => {
      //             if(resp.classification == classification) {
      //               this.selectedClassification = classification
      //             }
      //           });
      //         }
      //       });
      //     }
      //  });
      this.MinimumQuantity = resp.minimumOrderQuantity
      this.selectedUnitofMeasure = resp.unitOfMeasure
      this.Price = resp.price
      this.selectedUnitofPrice = resp.unitOfPrice
      // this.typePayment = resp.paymentTerms
      this.SupplyAbility = resp.supplyAbility
      this.selecteduUnitOfMeasureSupplyAbility = resp.unitOfMeasure
      this.SupplyAbilityUnit = resp.supplyAbilityFrequency
      this.DeliveryTime= resp.deliveryTime
      this.DeliveryTimeUnit = resp.deliveryTimeFrequency
      this.PackagingDetails = resp.packagingDetails
      this.MainExport = resp.mainExportMarkets
      this.DomesticMarket = resp.mainDomesticMarket
      this.CertificationsDetails = resp.certifications
      this.unitWeight = resp.unitWeightKg
      this.quantity = resp.quantity
      this.radioValue = resp.sampleAvailable
      this.SamplePolicy = resp.samplePolicy
    })
  }
  cancel(){
    console.log("canceled")
  }
  deleteImg(image){
    let index: number = this.productToUpdate.images.indexOf(image);
    this.productToUpdate.images.splice(index, 1);
  }
  createFormChiffre() {
    this.formAddProduct = this.fb.group({
      id : [''],
      type: [''],
      category: [''],
      subCategory: [''],
      classification: [''],
      productName: ['', Validators.required],
      tag: [''],
      productDescription: ['', Validators.required],
      checkUploadVideo: [''],
      video : ['']
    });

    this.formAddProduct2 = this.fb.group({
      minimumOrderQuantity: ['', Validators.required],
      quantity: ['', Validators.required],
      unitWeight: ['', Validators.required],
      unitOfMeasure: ['', Validators.required],
      price: ['', Validators.required],
      unitOfPrice: ['', Validators.required],
      supplyAbility: ['', Validators.required] ,
      unitOfMeasureSupplyAbility: ['', Validators.required] ,
      supplyAbilityFrequency: ['', Validators.required],
      packagingDetails: [''],
      checkbox: [''],
      sample: [''],
      mainExportMarkets: [''],
      mainDomesticMarket: ['', Validators.required],
      certifications: ['']

    });
  }
  handleClose(removedTag: {}): void {
    this.tags = this.tags.filter(tag => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
  }

  changeUploadVideo(value){
    console.log(value);

    this.uploadVideo = value;
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
      this.tags = [...this.tags, this.inputValue];
    }
    this.inputValue = '';
    this.inputVisible = false;
  }
  getAllafricanCountries(){
    this.b2bserviceService.getafricanCountries().subscribe((resp: any) => {
      this.listafricanCountries=resp
    })
  }

  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };

  handleUploadVideo(): void {
    let formData = new FormData();
    // tslint:disable-next-line:no-any
    this.videoList.forEach((file: any) => {
      formData.append('file', file);
    // let uploadedFiles = this.fileList.map(item => formData.append('file', item, item.name));
    this.b2bserviceService.uploadVideoProduct(5, formData).subscribe((resp: any) => {
      console.log(resp);
    })
    });

  }

  beforeUpload = (file: UploadFile[]): boolean => {
      this.fileList = file
    this.fileList.forEach((file: any) => {
     this.formData.append('file', file);
     this.fileProduct=file;
   });
   this.listEmplty=false;
   this.fileName=this.fileProduct.name
  let data= this.ValidateFile(this.fileProduct);
  if (!data) {
    this.listEmplty=true;
   }
   else{
    this.listEmplty=false;
   }
  //     this.b2bserviceService.uploadFileProduct(1,this.formData).subscribe((resp: any) => {
  //   console.log(resp);
  // })
    return false;
  };

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  }

  ValidateFile(file){
     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.msg.error('Only JPG or png files are allowed');
      this.fileName="Only JPG or png files are allowed";
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.msg.error("the image size should not exceed 10MB! ");
      this.fileName="the image size should not exceed 10MB! ";
      return false;
    }

    // if(this.fileList.length >=10) {
    //   this.msg.error("Please upload 1 to 10 pictures! ");
    //   this.fileName="Please upload 1 to 10 pictures! ";
    //   return false;
    //  }

    return true;
  }

  beforeUploadVideo = (file: UploadFile): boolean => {
    this.videoList = this.videoList.concat(file);
    this.fileName= file.name
  let data= this.ValidateVideo(file);
  if (!data) {
    this.videoList = [];
    this.videoIsEmpty=false;
   }
   else{
    this.videoIsEmpty=true;
   }
    return false;
  }

  ValidateVideo(file){
    const isJpgOrPng =  file.type === 'video/mp4' || file.type === 'video/3gpp';
   if (!isJpgOrPng) {
     this.msg.error('Only MP4 files are allowed');
     this.fileName="Only MP4 files are allowed";
     return false;
   }
   const isLt2M = file.size / 1024 / 1024 < 10;
   if (!isLt2M) {
     this.msg.error("the video size should not exceed 250MB! ");
     this.fileName="the video size should not exceed 25MB! ";
     return false;
   }

   return true
 }

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    console.log("form valid 1",this.formAddProduct);
    console.log("form valid 2",this.formAddProduct2);

    if (!this.formAddProduct.valid) {
      this.msg.error('Please fill in all mandatory fields')
    }
    else{
      this.current += 1;
      this.changeContent();
    }
    if (!this.formAddProduct2.valid) {
      this.msg.error('Please fill in all mandatory fields')
    }
    else{
      this.current += 1;
      this.changeContent();
    }

  }
  next1(): void {
    console.log("form valid 1",this.formAddProduct);
    console.log(this.listEmplty);
    // if (!this.listEmplty) {
      if (!this.formAddProduct.valid||this.prodImgs.length==0&&this.fileList.length==0) {
        this.msg.error('Please fill in all mandatory fields')
      }
      else{
        this.current += 1;
        this.changeContent();
      }
    // }
    // else{
    //   this.msg.error('please add a file')

    // }

  }
  next2(): void {
    console.log("form valid 2",this.formAddProduct2);

    if (!this.formAddProduct2.valid) {
      this.msg.error('Please fill in all mandatory fields')
    }
    else{
      this.current += 1;
      this.changeContent();
    }

  }


  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 1;
        break;
      }
      case 1: {
        this.index = 2;
        break;
      }
      case 2: {
        this.index =3;
        break;
      }
      case 3: {
        this.index = 4;
        break;
      }
      default: {
        this.index = 1;
      }
    }
  }

  CheckPayment(value): void {
    this.listCheked=[];
    this.typePayment.forEach(element => {
      if (element.checked) {
    this.listCheked.push(element.value);
       }
    });

  }

  getCategories(radioValueType){
    this.b2bserviceService.getCategories(radioValueType).subscribe((resp: any) => {
      console.log(resp);
      this.listCategories=resp
    })
}

getAllUnitOfMeasure(){
  this.b2bserviceService.getUnitOfMeasure().subscribe((resp: any) => {
    console.log(resp);
    let data =resp
    this.listUnitOfMeasure=data.sort();
  })
}
getAllUnitOfPrice(){
  this.b2bserviceService.getUnitOfPrice().subscribe((resp: any) => {
    console.log(resp);
    this.listUnitOfPrice=resp
  })
}
getAllDeliverTimeFrequency(){
  this.b2bserviceService.getDeliverTimeFrequency().subscribe((resp: any) => {
    console.log(resp);
    this.listDeliverTimeFrequency=resp
  })
}
getAllSupplyAbilityFrequency(){
  this.b2bserviceService.getSupplyAbilityFrequency().subscribe((resp: any) => {
    console.log(resp);
    this.listSupplyAbilityFrequency=resp
  })
}
getSubCategories(event){
  console.log("sub get event",event);
  console.log(this.selectedCategory);
  this.selectedCategory2=event.id
  this.listSubCategories = event.subCategories

}

getClassification(event) {
  console.log("classification get event : ",event);
  this.selectedSubCategory = event.id
  this.classifications = event.classification

}
getTypeService(event){
  console.log("event",event);
  console.log("type",this.radioValueType);
  this.getCategories(event);
}



addItem(input: HTMLInputElement): void {
  const value = input.value;
  console.log(value);

  if (this.listCategories.indexOf(value) === -1) {
    this.listCategories = [...this.listCategories, input.value];
  }
  console.log(this.listCategories);

}
addItemSubCategories(input: HTMLInputElement): void {
  const value = input.value;
  if (this.listSubCategories.indexOf(value) === -1) {
    this.listSubCategories = [...this.listSubCategories, input.value];
  }
}

edit(){
  this.current = 0;
  this.changeContent();
}

postProduct(){
  this.loading=true;
  let data={
    id:this.prodID,
    type:this.radioValueType,
    category:this.selectedCategory,
    subCategory:this.selectedProductName,
    quantity:this.quantity,
    unitWeightKg:this.unitWeight,
    classification : this.selectedClassification,
    productName:this.selectedProductCode,
    video:this.video,
    productDescription:this.ProductDescription,
    keywords:this.tags,
    minimumOrderQuantity:this.MinimumQuantity,
    unitOfMeasure:this.selectedUnitofMeasure,
    price:this.Price,
    unitOfPrice:this.selectedUnitofPrice,
    paymentTerms:this.listCheked,
    supplyAbility:this.SupplyAbility ,
    supplyAbilityFrequency:this.SupplyAbilityUnit,
    deliveryTime:this.DeliveryTime,
    deliveryTimeFrequency:this.DeliveryTimeUnit,
    packagingDetails:this.PackagingDetails,
    mainExportMarkets:this.MainExport,
    mainDomesticMarket:this.DomesticMarket,
    images:this.prodImgs,
    certifications:this.CertificationsDetails,
    sampleAvailable:this.radioValue,
    samplePolicy:this.SamplePolicies,
  }
  this.b2bserviceService.UpdateProduct(this.productToUpdate).subscribe((resp: any) => {
    console.log(resp);
    let formData = new FormData();
    // tslint:disable-next-line:no-any
    if(this.fileList)
    this.fileList.forEach((file: any) => {
      formData.append('files', file);
    });
    this.productToUpdate.images.forEach((image: any) => {
      formData.append('oldImages', image);
    });
    formData.append('type' , this.radioValueType)
    this.b2bserviceService.uploadFilesProduct(resp.id,formData).subscribe((resp: any) => {
      console.log(resp);
      if(this.uploadVideo) {

      } else {
        this.loading=false;
        this.current += 1;
        this.changeContent();
        this.msg.success('Product Updated successfully');
      }
    },
    () => {
      this.loading = false;
      this.msg.error('upload failed.');
    })
    this.loading=false;
    this.current += 1;
    this.changeContent();
    this.msg.success('Product Updated successfully');
  },
  (err) => {
    this.loading = false;
    console.log(err);
    this.msg.error('failed added product ');
  })


}
}
