import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA,NgModule, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ZoomMtg } from '@zoomus/websdk';
import { GoogleObj, TranslateService } from './commons/services/googletranslate/googletranslate.service';
import * as AOS from 'aos';
import { WebSocketService } from './commons/services/websocket-service/web-socket.service';
import { ReportService } from './commons/services/report-service/report.service';
import { HttpClient } from '@angular/common/http';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'b2b';
  public isTrad=false;
  public isSubscription=false;
  public isSubs=false;
  public ipAddress;
  private translateBtn: any;

 ngOnInit()  {
  // this.translateBtn = document.getElementById('translatebtn');
  AOS.init();
 }
 checkRout(){
  this.isTrad=this.isActive();
  this.isSubscription = this.isSubscriptionActive();
 }
 isActive(): boolean {
   if(this.route.url.includes('/Trade') ||this.route.url.includes('/Show') || this.route.url.includes('/subscription/business') ){
    return true;
   }
   else{
    return false;
   }

}

checkRoutSubs(){
  this.isSubs=this.isActiveSubs();
 }

 isActiveSubs(): boolean {
   if(this.route.url.includes('/subscription')){
    return true;
   }
   else{
    return false;
   }

}

constructor(private route : Router,
            private google: TranslateService,
            private webSocketService: WebSocketService,
            private reportService : ReportService
            ,public httpClient: HttpClient) {
  route.events.subscribe((val) => {

    this.checkRout();
    this.checkRoutSubs();
    // this.scroll();
    // this.getUserIp();
});
 }
 scroll() {
  let el = document.getElementById("scroll");

  el.scrollIntoView();
}
// getUserIp() {
//   this.reportService.getIPAddress().subscribe((res :any )=> {
//     console.log("got ip",res);

//     this.ipAddress = res.ip;

//     this.saveIp();
//   }
//   )

// }
saveIp(){
  this.reportService.getUserIp(this.ipAddress).subscribe(res => {
    console.log("saved guest",res);
  })
}


 isSubscriptionActive(): boolean {
  if(this.route.url.includes('/subscription/business') ){
   return true;
  }
  else{
   return false;
  }

}

send() {const googleObj: GoogleObj = {q: ['hello'],target: 'es'};

// this.translateBtn.disabled = true;
let data = {
  "sourceLanguageCode": "en",
  "targetLanguageCode": "ru",
  "contents": ["Dr. Watson, come here!"],
  "mimeType": "text/plain"
}

this.google.translatetoSpanish(googleObj).subscribe((res: any) => {

  // this.translateBtn.disabled = false;

  console.log(res.data.translations[0].translatedText)}
  ,err => {console.log(err);

  });
}

@HostListener('window:beforeunload', ['$event'])
unloadHandler(event: Event) {
    // Your logic on beforeunload
    // alert("are u sure you are leaving ")
}

listenToClose() {
  window.addEventListener('beforeunload', (event) => {
    event.returnValue = `You have unsaved changes, leave anyway?`;
  });
}

}
