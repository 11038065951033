import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  QueryList,
} from "@angular/core";
// import {   AbstractControl,
//   AsyncValidatorFn,
//   FormBuilder,
//   FormControl,
//   FormGroup,
//   ValidationErrors,
//   ValidatorFn,
//   Validators} from '@angular/forms';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";
// import { AdminService } from 'src/app/commons/services/dashboard-admin/admin.service';
import * as countriesJson from "../../../../../assets/js/countries.json";
// import * as africanCountryjs from '../../../../../assets/js/africanCountry.json';

import * as product from "../../../../../assets/js/products.json";
import * as service from "../../../../../assets/js/services.json";
import { CurrencyPipe } from "@angular/common";
import { SharedService } from "src/app/commons/shared service/shared-service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { AdminService } from "src/app/commons/services/dashboard-admin/admin.service";
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  switchMap,
} from "rxjs/operators";
import { UserService } from "src/app/commons/services/user.service";
import { InvoiceService } from "src/app/modules/services/invoice.service/invoice.service";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { object } from "@amcharts/amcharts4/core";

export interface Registration {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  businessRegistrationNumber?: string;
  organisationType?: string;
  subscriptionType: string;
  companyName?: string;
}

export interface PromotionRecap {
  nature: "Promotion" | "Gift" | "Evoucher";
  code: string;
  value: string;
  discount: string;
}
export interface PricingPlan {
  id: number;
  cost: number;
  frequency: "MONTH" | "YEAR";
  promotions: PromotionRecap[];
}

export interface Phone {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
}
@Component({
  selector: "app-business",
  templateUrl: "./business.component.html",
  styleUrls: ["./business.component.css"],
})
export class BusinessComponent implements OnInit {
  submitSubject: Subject<any> = new Subject();
  @ViewChild("recaptcha", { static: false }) recaptcha!: QueryList<ElementRef>;

  form: FormGroup;
  contactPreference: FormGroup;
  contactPreference2: FormGroup;
  isCollapse = true;
  controlArray: Array<{ index: number; show: boolean }> = [];
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  size: number;
  large: any;
  selectedISO = CountryISO.Morocco;
  submitted: boolean = false;
  public checked = true;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  costumSteps: any[] = [
    { stepLabel: "Identification", stepIndex: [0] },
    { stepLabel: "Contact and Preferences", stepIndex: [1, 2] },
    { stepLabel: "Pricing", stepIndex: [3] },
    { stepLabel: "Payment", stepIndex: [4] },
  ];

  disabled = false;

  formatterDollar = (value: number) =>
    `$ ${value}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

  passwordValidators = [Validators.required];
  critaria: any = {};
  label: {
    firstName;
    lastName;
    email;
    confirmEmail;
    companyName;
    phone;
  } = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    confirmEmail: "Confirm Email",
    companyName: "Company Name",
    phone: "Phone",
  };
  planSelected = false;

  subscription: any = {};
  products: any;
  services: any;
  token: any;
  acrossCountriesDisabled = true;
  email: any;
  id: any;
  countries: any = (countriesJson as any).default;
  // africancountries : any =(africanCountryjs as any).default;
  listProduct: any = (product as any).default;
  listService: any = (service as any).default;
  selectedCountry: any = {
    label: "Morocco",
    value: "Morocco",
    id: "34",
    type_pays: "Country",
    isocode: "MAR",
    region: "Africa",
    flag: "assets/countries/MAR.svg",
  };

  newArry: Array<any> = [];
  currentarray: any[];
  // operateCountry: any = {
  //   label: "Morocco",
  //   value: "Morocco",
  //   id: "34",
  //   type_pays: "Country",
  //   isocode: "MAR",
  //   region: "Africa",
  //   flag: "https://restcountries.eu/data/mar.svg"
  // }
  MaxTagCount: number = 3;
  handleCoupon: any = {};
  handleRequest: any = {};
  pricingFrequency: any;
  typeOfEntities = [
    "SME",
    "Corporate",
    "Financial Institution",
    "Government, Trade & Other Institutions",
  ];

  preferredMarkets: any[];
  interrestedProducts: any[];
  interestingProductsAndServices : any[];
  businesstypes: any[];
  institutionNature: any[];

  servs = [];
  prods = [];
  interestedToFollow = [
    {
      title: "Products",
      key: "Products",
      disabled: false,
      children: [],
    },
    {
      disabled: false,
      title: "Services",
      key: "Services",
      children: [],
    },
  ];

  @Input()
  isEditable = true;
  @Input()
  isLinear = true;

  @Input() barLabel = "weak ";
  value: string[] = ["0-0-0"];
  nodes = [
    {
      title: " Agriculture; forestry and fishing ",
      value: "0-0",
      key: "0-0",
    },
    {
      title: "Mining and quarrying",
      value: "0-1",
      key: "0-1",
    },
    {
      title: "Manufacturing",
      value: "0-2",
      key: "0-2",
      children: [
        {
          title: "Aerospace/Defence/Advanced",
          value: "0-2-0",
          key: "0-2-0",
          isLeaf: true,
        },
        {
          title: "Automotive/Rail/Marine",
          value: "0-2-1",
          key: "0-2-1",
          isLeaf: true,
        },
        {
          title: "Life Sciences/Chemicals/Pharmaceuticals",
          value: "0-2-2",
          key: "0-2-2",
          isLeaf: true,
        },
        {
          title: "Food, Beverages Processing",
          value: "0-2-3",
          key: "0-2-3",
          isLeaf: true,
        },
        {
          title: "Other",
          value: "0-2-4",
          key: "0-2-4",
          isLeaf: true,
        },
      ],
    },
    {
      title: "Electricity; gas, steam and air conditioning supply",
      value: "0-3",
      key: "0-3",
    },
    {
      title: "Water supply, sewerage, waste management",
      value: "0-4",
      key: "0-4",
    },
    {
      title: "Construction",
      value: "0-5",
      key: "0-5",
    },
    {
      title: "Wholesale, Retail (including automotive and motor bike repairs)",
      value: "0-6",
      key: "0-6",
    },
    {
      title: "Wholesale, Retail (including automotive and motor bike repairs)",
      value: "0-7",
      key: "0-7",
    },
    {
      title: "Food, beverages",
      value: "0-8",
      key: "0-8",
    },
    {
      title: "Information and Communications Technology (incl digital)",
      value: "0-9",
      key: "0-9",
    },
    {
      title: "Finance and insurance services",
      value: "0-10",
      key: "0-10",
    },
    {
      title: "Real estate",
      value: "0-11",
      key: "0-11",
    },
    {
      title: "Professional, scientific and technical	services & consultancy",
      value: "0-11",
      key: "0-11",
    },
    {
      title: "Administration and support	",
      value: "0-12",
      key: "0-12",
    },
    {
      title: "Public administration and defence",
      value: "0-13",
      key: "0-13",
    },
    {
      title: "Education",
      value: "0-14",
      key: "0-14",
    },
    {
      title: "Health and social care	",
      value: "0-15",
      key: "0-15",
    },
    {
      title: "Arts and creativity",
      value: "0-16",
      key: "0-16",
    },
    {
      title: "Travel, tourism, catering hotels and leisure",
      value: "0-17",
      key: "0-17",
    },
  ];

  Prods = [];
  initCheckEvent: boolean = true;
  initCheckInterset: boolean = true;
  initCheckServicesAndOffers: boolean = true;
  initCheckEmail: boolean = true;
  initCheckWhatsapp: boolean = false;
  initCheckTextSms: boolean = false;
  mode = "default";
  list = [
    "Importer",
    "Exporter",
    "Importer and Exporter",
    "Domestic trade",
    "Service provider",
    "Manufacturer",
    "Producer",
    "Wholesaler",
    "Distributor",
    "Dealer",
    "Retailer",
  ];
  businessnatures: any[];
  natureBuissnes = [
    "Importer",
    "Exporter",
    "Importer and Exporter",
    "Domestic trade",
    "Service provider",
    "Manufacturer",
    "Producer",
    "Wholesaler",
    "Distributor",
    "Dealer",
    "Retailer",
  ];
  newCost: number = 0;
  evoucherMode: boolean = false;
  isValidatingPromotion: boolean = false;
  isValidatingGift: boolean = false;
  isValidatingEvoucher: boolean = false;

  selectedPlanValues: Partial<PricingPlan> = null;
  promotionError: string = null;
  giftError: string = null;
  evoucherCode: any = "";
  promotionRecaps: PromotionRecap[] = [];

  evoucherError: string = null;
  listCountriesOperated: any[] = [];
  ListAfricanCountries: any[] = [];

  selectedCurrency = "";
  countriesCurrency: any[];
  ngMode1 = "default";

  freeMode: "evoucher" | "discount" | null = null;
  constructor(
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private adminService: AdminService,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private toaster: ToastrService,

    private sharedService: SharedService,
    private b2bserviceService: B2bProductService
  ) {}
  organisationChange() {
    this.form.get("organisationType").valueChanges.subscribe((data: any) => {
      if (data == "Government, Trade & Other Institutions") {
        this.label = {
          firstName: "Contact Person First Name",
          lastName: "Contact Person Last Name",
          email: "Contact Email",
          confirmEmail: "Confirm Contact Email",
          companyName: "Government Body Name",
          phone: "Contact Phone Number",
        };
      } else {
        this.label = {
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email",
          confirmEmail: "Confirm Email",
          companyName: "Company Name",
          phone: "Phone",
        };
      }
    });
  }

  private setPasswordValidators() {
    this.adminService.getPasswordCretaria(this.id).subscribe((data) => {
      this.critaria = data;
      this.passwordValidators = [Validators.required];
      if (this.critaria.minlength)
        this.passwordValidators = [
          Validators.minLength(8),
          ...this.passwordValidators,
        ];
      if (this.critaria.hasNumber)
        this.passwordValidators = [
          this.patternValidator(/\d/, {
            hasNumber: true,
          }),
          ...this.passwordValidators,
        ];
      if (this.critaria.hasCapitalCase)
        this.passwordValidators = [
          this.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          ...this.passwordValidators,
        ];
      if (this.critaria.hasSmallCase)
        this.passwordValidators = [
          this.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          ...this.passwordValidators,
        ];
      if (this.critaria.hasSpecialCharacters)
        this.passwordValidators = [
          this.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
            hasSpecialCharacters: true,
          }),
          ...this.passwordValidators,
        ];

      console.log(this.passwordValidators);
      this.form.get("password").setValidators(this.passwordValidators);
      this.form.get("password").updateValueAndValidity();
    });
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [
        null,
        [Validators.required, Validators.email],
        this.userEmailValidator(),
      ],
      confirmEmail: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.required,
          this.confirmationEmail,
        ],
      ],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required, this.confirmationPassword]],
      phone: [null, [Validators.required]],
      country: [null, [Validators.required]],
      businessRegistrationNumber: [null, [Validators.required]],
      organisationType: [null, [Validators.required]],
      companyName: ["", [Validators.required]],
      linkdin: [],
      twitter: [],
      whatsapp: [],
      recaptcha: [null, [Validators.required]],
    });
    this.organisationChange();
    this.setPasswordValidators();

    this.contactPreference = this.fb.group({
      interestingMarkets: [null, [Validators.required]],
      interestingProductsAndServices: [null, [Validators.required]],
      contactAfreximbankActivitiesEvents: [true],
      contactProvideInfoRelatedToAreasInterest: [true],
      contactProvideInfoRelatedToServicesAndOffers: [true],
      contactByEmail: [true],
      contactByWhatsapp: [false],
      contactByTextSms: [false],
    });

    this.contactPreference2 = this.fb.group({
      businessNature: [null, [Validators.required]],
      countriesOperated: [null, [Validators.required]],
      checkcountriesOperated: [true],
      employeeSize: [null, [Validators.required]],
      annualTurnover: [0, [Validators.required]],
      businessSectors: [null, [Validators.required]],
      b2bMemberType: ["SELLER", [Validators.required]],
    });
    this.getAllPreferredMarkets();
    this.initHandelRequest();
    this.getProducts();
    this.getServices();

    this.sharedService.sharedUser.subscribe((user) => {
      console.log("user : ", user);

      if (user) {
        this.form.get("firstName").setValue(user.firstName);
        this.form.get("lastName").setValue(user.lastName);
        this.form.get("email").setValue(user.email);
        this.form.get("confirmEmail").setValue(user.email);
        this.form.get("password").setValue("");
        this.form.get("confirmPassword").setValue("");
        // this.form.get('phone').setValue(user.phone);
        if (user.country) {
          this.form.get("country").setValue(user.country);
          this.countries.forEach((element) => {
            if (element.value == user.country) {
              this.selectedCountry = element;
            }
          });
          console.log("selectedCountry", this.selectedCountry);
        }
        this.form
          .get("businessRegistrationNumber")
          .setValue(user.businessRegistrationNumber);
        this.form.get("organisationType").setValue(user.organisationType);

        this.contactPreference
          .get("interestingMarkets")
          .setValue(user.interestingMarkets);
        // this.contactPreference.get('interestingProductsAndServices').setValue(user.interestingProductsAndServices);
        // this.contactPreference.get('interestingProducts').setValue(user.interestedProducts);
        // this.contactPreference.get('interestingServices').setValue(user.interestedServices);
        this.contactPreference
          .get("interestingProductsAndServices")
          .setValue(user.interestingProductsAndServices);
        this.contactPreference
          .get("contactAfreximbankActivitiesEvents")
          .setValue(user.contactAfreximbankActivitiesEvents);
        this.contactPreference
          .get("contactProvideInfoRelatedToAreasInterest")
          .setValue(user.contactProvideInfoRelatedToAreasInterest);
        this.contactPreference
          .get("contactProvideInfoRelatedToServicesAndOffers")
          .setValue(user.contactProvideInfoRelatedToServicesAndOffers);
        this.contactPreference
          .get("contactByEmail")
          .setValue(user.contactByEmail);
        this.contactPreference
          .get("contactByWhatsapp")
          .setValue(user.contactByWhatsapp);
        this.contactPreference
          .get("contactByTextSms")
          .setValue(user.contactByTextSms);

        this.contactPreference2.get("businessType").setValue(user.businessType);
        this.contactPreference2
          .get("countriesOperated")
          .setValue(user.countriesOperated);
        this.contactPreference2.get("employeeSize").setValue(user.employeeSize);
        if (user.annualTurnover) {
          this.contactPreference2
            .get("annualTurnover")
            .setValue(user.annualTurnover);
        }
        this.contactPreference2
          .get("businessSectors")
          .setValue(user.businessSectors);
        this.form.get("companyName").setValue(user.companyName);
        this.contactPreference2
          .get("b2bMemberType")
          .setValue(user.b2bMemberType);
      } else {
      }
    });

    this.getAllOrganizationType();
    this.getAllPreferredMarkets();
    this.getAllInterrestedProduct();
    this.getAllInstitutionNature();
    this.initHandelRequest();
  }

  handlePreviewToFillForm() {
    let recaptchaControl = this.form.get("recaptcha");
    recaptchaControl.setValidators([Validators.required]);
    recaptchaControl.markAsDirty();
    recaptchaControl.updateValueAndValidity();
  }

  onSubmit() {
    if (this.form.valid) {
      this.recaptcha.reset(null);
      this.form.get('recaptcha').clearValidators();
    }
    // this.currencyByCountries(null);
    this.submitted = true;
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    this.subscription.firstName = this.form.value.firstName;
    this.subscription.lastName = this.form.value.lastName;
    this.subscription.email = this.form.value.email;
    this.subscription.password = this.form.value.password;
    this.subscription.phone = (this.form.value.phone as Phone).number; //this.form.value.phone.internationalNumber;
    this.subscription.country = this.form.value.country.label;
    this.subscription.businessRegistrationNumber =
      this.form.value.businessRegistrationNumber;
    this.subscription.organisationType = this.form.value.organisationType;
    if (this.form.value.whatsapp != null) {
      this.subscription.whatsapp = (this.form.value.whatsapp as Phone).number;
    } else {
      this.subscription.whatsapp = this.form.value.whatsapp;
    }
    this.subscription.companyName = this.form.value.companyName;
    this.subscription.subscriptionType = "BUSINESS";
    console.log(this.subscription);
    this.handleCoupon.email = this.subscription.email;
  }
  // currencyByCountries(val : string){
  //   this.countries.forEach(element => {

  //     if(this.selectedCountry.label == element.label ){
  //       this.selectedCurrency=element;
  //       console.log(this.selectedCurrency);
  //       // this.currenyByCountries=this.countries.currency_code;

  //     }

  //   });
  //   console.log(this.selectedCurrency);
  //   this.countriesCurrency= this.removeDuplicationByAttribut(this.countries,'currency_code',this.selectedCurrency);
  // }

  currencyByCountries(val: string) {
    if (val != null) {
      this.countries.forEach((element) => {
        if (val == element.label) {
          this.selectedCurrency = element;
          // this.currenyByCountries=this.countries.currency_code;
        }
      });
      console.log("this.selectedCurrency not null :>> ", this.selectedCurrency);
    } else {
      this.countries.forEach((element) => {
        if (this.selectedCountry.label == element.label) {
          this.selectedCurrency = element;
          // this.currenyByCountries=this.countries.currency_code;
        }
      });
      console.log("this.selectedCurrency not null :>> ", this.selectedCurrency);
    }
    this.countriesCurrency = this.removeDuplicationByAttribut(
      this.countries,
      "currency_code",
      this.selectedCurrency
    );
  }

  removeDuplicationByAttribut(
    array: any[],
    attribut: any,
    selectedCountry: any
  ) {
    const uniqueArray = array.filter((element, index) => {
      const item = element[attribut];
      return (
        index ===
        array.findIndex((obj) => {
          return (
            (obj[attribut] === item &&
              obj[attribut] != null &&
              obj[attribut] != selectedCountry.currency_code) ||
            (obj[attribut] === item &&
              obj[attribut] != null &&
              obj[attribut] == selectedCountry.currency_code &&
              obj["label"] == selectedCountry.label)
          );
        })
      );
    });
    return uniqueArray;
  }

  log() {
    console.log("prodsssss", this.Prods);
  }
  onSubmitContactPreference() {
    for (const i in this.contactPreference.controls) {
      this.contactPreference.controls[i].markAsDirty();
      this.contactPreference.controls[i].updateValueAndValidity();
    }

    this.subscription.interestingMarkets =
      this.contactPreference.value.interestingMarkets;
    this.subscription.interestingProductsAndServices =
      this.contactPreference.value.interestingProductsAndServices;
    this.subscription.contactAfreximbankActivitiesEvents =
      this.contactPreference.value.contactAfreximbankActivitiesEvents;
    this.subscription.contactProvideInfoRelatedToAreasInterest =
      this.contactPreference.value.contactProvideInfoRelatedToAreasInterest;
    this.subscription.contactProvideInfoRelatedToServicesAndOffers =
      this.contactPreference.value.contactProvideInfoRelatedToServicesAndOffers;
    this.subscription.contactByEmail =
      this.contactPreference.value.contactByEmail;
    this.subscription.contactByWhatsapp =
      this.contactPreference.value.contactByWhatsapp;
    this.subscription.contactByTextSms =
      this.contactPreference.value.contactByTextSms;
    console.log(this.subscription);
  }

  onSubmitContactPreference2() {
    for (const i in this.contactPreference2.controls) {
      this.contactPreference2.controls[i].markAsDirty();
      this.contactPreference2.controls[i].updateValueAndValidity();
    }

    if (this.contactPreference2.value.checkcountriesOperated == true) {
      this.subscription.countriesOperated = [
        this.contactPreference2.value.countriesOperated,
      ];
    } else {
      this.subscription.countriesOperated =
        this.contactPreference2.value.countriesOperated;
    }
    this.subscription.employeeSize = this.contactPreference2.value.employeeSize;
    this.subscription.b2bMemberType =
      this.contactPreference2.value.b2bMemberType;
    this.subscription.businessType =
      this.contactPreference2.value.businessNature;
    this.subscription.annualTurnover =
      this.contactPreference2.value.annualTurnover;
    this.subscription.businessSectors =
      this.contactPreference2.value.businessSectors.map((item) => {
        return item.key;
      });

    this.invoiceService.businessRegistration = this
      .subscription as Registration;
    console.log("save subscriber", this.subscription);

    // if (this.contactPreference2.valid) {
    //   this.subscriptionService
    //     .create(this.subscription)
    //     .subscribe((data: any) => {
    //       this.token = data.token;
    //     });
    // }
  }

  //   selectChangeMode(value) {

  //     if (value == false) {
  //       this.ngMode1 = 'multiple';

  //     } else {
  //      this.ngMode1 = 'default';

  //     }
  // }
  //  getPrice(value){
  //     this.planSelected = true;
  //     console.log(value);
  //     this.handleCoupon.cost = value.cost;
  //     this.handleRequest.pricingPlanId = value.id;
  //     this.pricingFrequency = value.planFrequency;
  //     //this.handleCoupon.email=this.form.value.email;
  //   }

  getPrice(value) {
    this.invoiceService.selectedPlan = null;
    this.promotionRecaps = [];

    const selectedPlan: Partial<PricingPlan> = {
      id: value.id,
      cost: value.cost,
      frequency: value.planFrequency,
    };

    this.selectedPlanValues = {
      id: value.id,
      frequency: value.planFrequency,
    };

    this.invoiceService.selectedPlan = {
      ...selectedPlan,
      ...this.invoiceService.selectedPlan,
    };
    this.invoiceService.initialPrice = value.cost;
    this.planSelected = value.id !== null ? true : false;
    this.handleCoupon.cost = value.cost;
    this.handleRequest.pricingPlanId = value.id;
    this.pricingFrequency = value.planFrequency;

    this.newCost = value.cost;
  }

  getCoupon(value: any) {
    this.handleCoupon.coupon = value;
  }

  initHandelRequest() {
    this.handleCoupon.email = "";
    this.handleCoupon.coupon = "";
    this.handleCoupon.cost = "";
  }
  getValueCheckEvent(value) {
    this.initCheckEvent = value;
  }
  validate() {
    console.log("email");
    console.log(this.handleCoupon.email);
    console.log("Coupon");
    console.log(this.handleCoupon.coupon);
    console.log("HandleCoupon");
    console.log(this.handleCoupon);
    // this.adminService.validateCoupon(this.handleCoupon,this.token)
    // .subscribe((data)=>{
    //   console.log(data);
    // });
  }
  toggleCollapse(): void {
    this.isCollapse = !this.isCollapse;
    this.controlArray.forEach((c, index) => {
      c.show = this.isCollapse ? index < 6 : true;
    });
  }

  resetForm(): void {
    this.form.reset();
  }

  changePreferredCountries() {
    this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
      CountryISO.Morocco,
    ];
  }

  // resolved(captchaResponse: string) {
  //   console.log(`Resolved response token: ${captchaResponse}`);

  // }youness

  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
    this.checkRecaptcha(captchaResponse);
  }
  captchaIsValid: boolean = false;
  checkRecaptcha(e) {
    this.subscriptionService.getCaptchaResponse(e).subscribe(
      (data: any) => {
        this.captchaIsValid = true;
      },
      (error) => {
        this.captchaIsValid = false;
      }
    );
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.form.controls.confirmPassword.updateValueAndValidity()
    );
  }

  confirmationPassword = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.form.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  userEmailValidator(): AsyncValidatorFn {
    return (control) =>
      control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => this.userService.validateEmail(value)),
        map((used: boolean) => (used ? { emailExists: true } : null)),
        first()
      );
  }

  updateConfirmEmail(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.form.controls.confirmEmail.updateValueAndValidity()
    );
  }

  confirmationEmail = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.form.controls.email.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  getAllOrganizationType() {}

  getAllCountries() {
    this.subscriptionService.getPreferredMarkets().subscribe((res: any[]) => {
      this.countries = res;
    });
  }

  //  getProducts() {
  //   this.b2bserviceService.getAllProductCategory().subscribe((data: any) => {
  //      // //console.log("Product",data)
  //      // this.products = data.slice(0,5)
  //      this.products = data;
  //      this.products.forEach(category => {
  //       let newArray = []
  //       category.subCategories.forEach(subCategory => {
  //         let oldArray = []
  //         subCategory.classification.forEach(calassification => {
  //           let classif
  //           classif = {
  //             key: calassification,
  //             title: calassification,
  //             isLeaf:true
  //           }
  //           oldArray.push(classif)
  //         });

  //         let subCat
  //         subCat = {
  //         key: subCategory.name,
  //         title: subCategory.name,
  //         children: oldArray
  //         // isLeaf:true
  //       }
  //       newArray.push(subCat);
  //       });

  //       let categories
  //       categories = {
  //         key: category.name,
  //         title: category.name,
  //         children: newArray
  //       }
  //       this.prods.push(categories)
  //      });

  //   });
  // }
  //   getServices(){
  //     this.b2bserviceService.getServicesCategories().subscribe((resp: any) => {
  //       console.log(resp);
  //       this.services=resp
  //       let index = 0;
  //          this.services.forEach(element => {
  //            const key = '0-1-%';
  //            const node = {
  //             title: element.name,
  //             value: element,
  //             key:  key.replace('%', '' + index),
  //             isLeaf: true
  //           };
  //            this.servs.push(node);
  //            index++;
  //          });
  //     })
  // }

  recursive(children) {
    if (children.sublevels) {
      const sublvs = [];
      children.sublevels.forEach((element) => {
        let childs = [];
        if (element.sublevels) {
          childs = this.recursive(element);
        }
        let elm;
        elm = {
          key: element.code,
          title: element.product_name,
          children: childs,
        };
        if (childs.length == 0) {
          elm = {
            key: element.code,
            title: element.product_name,
            isLeaf: true,
          };
        }

        sublvs.push(elm);
      });
      return sublvs;
    } else {
      return [];
    }
  }

  getAllPreferredMarkets() {
    this.subscriptionService.getPreferredMarkets().subscribe((res: any[]) => {
      this.preferredMarkets = res;
      console.log(
        "this.preferredMarkets Country yes :>> ",
        this.preferredMarkets
      );
    });
  }
  getAllInterrestedProduct() {
    this.subscriptionService.getInterrestedProduct().subscribe((res: any[]) => {
      this.interestingProductsAndServices = res;
    });
  }

  getAllInstitutionNature() {
    if (
      this.form.value.organisationType ==
      "Government, Trade & Other Institutions"
    ) {
      this.mode = "default";
      this.subscriptionService
        .getInstitutionNature()
        .subscribe((res: any[]) => {
          this.businessnatures = res;
        });
    } else {
      this.mode = "multiple";
      this.businessnatures = this.list;
    }
  }
  getIndividualType() {
    this.subscriptionService.getIndividualType().subscribe((res: any[]) => {
      this.institutionNature = res;
    });
  }

  // operateCountryChange(value: string): void {
  //   this.operateCountry = value;
  // }

  onChange($event: string[]): void {
    console.log($event);
  }

  onChangeMemberType(event) {
    this.contactPreference2.get("b2bMemberType").setValue(event);
  }

  changeDisabled(value) {
    this.disabled = value;
    if (this.disabled == true) {
      this.subscription.country = null;
    }
  }
  formatCurrency(value) {
    console.log(value);
  }
  countryChange(country) {
    Object.entries(this.CountryISO).forEach(([key, value]) => {
      if (
        key.toLocaleLowerCase() ==
        (country.label as String).split(" ").join("").toLocaleLowerCase()
      ) {
        this.selectedISO = value;
      }
    });
    //console.log(this.selectedISO);
    this.contactPreference2.patchValue({
      countriesOperated: country.label,
    });
    this.currencyByCountries(country.label);
  }

  getValueCheckInterset(value) {
    this.initCheckInterset = value;
  }
  getValueCheckServicesAndOffers(value) {
    this.initCheckServicesAndOffers = value;
  }
  getValueCheckEmail(value) {
    this.initCheckEmail = value;
  }
  getValueCheckWhatsapp(value) {
    this.initCheckWhatsapp = value;
  }
  getValueCheckTextSms(value) {
    this.initCheckTextSms = value;
  }

  // getListCountry(country){
  //   this.listCountriesOperated = []
  //     Object.entries(this.countries).forEach(([key, value]) => {
  //       if (key == country.label) {
  //         this.listCountriesOperated.push(country)
  //       }
  //    });
  //    return this.listCountriesOperated
  //   }
  getListCountry(country) {
    this.listCountriesOperated = [];
    Object.entries(this.countries).forEach(([key]) => {
      if (key == country.label) {
        this.listCountriesOperated.push(country);
      }
    });
    return this.listCountriesOperated;
  }

  // getListAfricanCountry(countriesA){
  //   this.ListAfricanCountries = []
  //   Object.entries(this.africancountries).forEach(([key]) =>{
  //     if(key == countriesA.label){
  //       this.ListAfricanCountries.push(countriesA)
  //     }
  //   });
  //   return this.ListAfricanCountries
  // }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  //  ///////////////////////////////////Company Name //////////////////////////////////////////////////
  // // companyName
  companyNames: any[] = [];

  changeCompanyName(value) {
    if (value != "") {
      this.form.value.companyName = value;
      this.getCompanyByName();
    }
  }

  getCompanyByName() {
    // let data = {
    //     company: this.form.value.companyName,
    //     limit: 10
    // }
    // this.subscriptionService.getCompanyName(this.form.value.companyName).subscribe((resp: any) => {
    //     this.companyNames = resp;
    // })
  }

  //  ///////////////////////////////////Company Name //////////////////////////////////////////////////
  //Products
  getProducts() {
    this.products = this.listProduct;
    let index = 0;
    const key = "0-0-%";
    this.products.forEach((element) => {
      let item;
      item = {
        key: element.code + " - " + element.product_name,
        alue: key.replace("%", "" + index),
        title: element.product_name,
        children: this.recursive(element),
      };
      this.interestedToFollow[0].children.push(item);
      this.newArry.push(item);

      index++;
    });
    this.currentarray = this.newArry;
  }

  //Services
  getServices() {
    this.services = this.listService;
    let index = 0;
    this.services.forEach((element) => {
      const key = "0-1-%";
      const node = {
        title: element.service,
        value: key.replace("%", "" + index),
        key: element.service,
        children: this.recursiveService(element),
      };
      this.interestedToFollow[1].children.push(node);
      index++;
    });
  }

  recursiveService(children) {
    if (children.sublevels) {
      const sublvs = [];
      children.sublevels.forEach((element) => {
        let childs = [];
        if (element.sublevels) {
          childs = this.recursiveService(element);
        }
        let elm;
        elm = {
          key: element.service,
          title: element.service,
          children: childs,
        };
        if (childs.length == 0) {
          elm = {
            key: element.service,
            title: element.service,
            isLeaf: true,
          };
        }

        sublvs.push(elm);
      });
      return sublvs;
    } else {
      return [];
    }
  }

  // select Change Mode
  selectChangeMode(value) {
    if (value == false) {
      this.ngMode1 = "multiple";
      this.acrossCountriesDisabled = false;
      console.log(
        "this.contactPreference.value.countriesOperated :>> ",
        this.contactPreference.value.countriesOperated.map((x) => x)
      );
      this.contactPreference.patchValue({
        countriesOperated: this.contactPreference.value.countriesOperated.map(
          (x) => x
        ),
      });
    } else {
      this.ngMode1 = "default";
      this.acrossCountriesDisabled = true;
      this.contactPreference.patchValue({
        countriesOperated: this.form.value.country.label.map((x) => x),
      });
    }
  }

  removePromotion(code: string) {
    this.promotionRecaps = [
      ...this.promotionRecaps.filter((recap) => recap.code !== code),
    ];
    this.recalculateCost();

    if (this.promotionRecaps.length === 0) {
      this.evoucherMode = false;
      this.freeMode = null;
      this.invoiceService.selectedPlan.cost = this.invoiceService.initialPrice;
    }
  }

  clearPromotions() {
    this.promotionRecaps = [];
    this.evoucherMode = false;
    this.newCost = this.invoiceService.initialPrice;
    this.freeMode = null;
    this.invoiceService.selectedPlan.cost = this.invoiceService.initialPrice;
  }

  validatePromoCode(value: string, event?: ClipboardEvent) {
    const newValue = event ? event.clipboardData.getData("text") : value;

    if (newValue.startsWith("PRO")) {
      const codeExist: boolean = !!this.promotionRecaps.find(
        (recap) => recap.code === newValue
      );

      if (this.newCost > 0) {
        this.freeMode = null;
      }

      if (
        this.handleCoupon.cost != null &&
        !this.evoucherMode &&
        !codeExist &&
        this.planSelected &&
        this.newCost > 0
      ) {
        this.isValidatingPromotion = false;
        this.isValidatingGift = false;

        this.handleCoupon.coupon = event
          ? event.clipboardData.getData("text")
          : value;
        this.handleCoupon.email = this.subscription.email;
        // this.handleCoupon.cost = this.invoiceService.selectedPlan.cost;

        if (this.handleCoupon.coupon.startsWith("PRO"))
          this.isValidatingPromotion = true;

        this.promotionError = null;
        this.giftError = null;

        this.adminService
          .validateCoupon(this.handleCoupon, this.token)
          .subscribe(
            (data: any) => {
              setTimeout(() => {
                // this.newCost = data.cost;

                if (this.newCost <= 0) {
                  this.freeMode = "discount";
                }

                if (this.newCost < 0) this.newCost = 0;

                this.promotionRecaps = [
                  {
                    nature: "Promotion",
                    code: this.handleCoupon.coupon,
                    value: this.newCost.toString(),
                    discount:
                      data.valueType === "DOLLAR"
                        ? `\$${data.discountValue}`
                        : `${data.discountValue}%`,
                  },
                  ...this.promotionRecaps,
                ];

                console.log("new promotion", this.promotionRecaps);
                this.recalculateCost();

                this.invoiceService.selectedPlan.cost = this.newCost;
                this.isValidatingPromotion = false;
              }, 1000);
            },
            (error) => {
              setTimeout(() => {
                if (value.startsWith("PRO"))
                  this.promotionError = error.error.message;
                  this.isValidatingPromotion = false;
              }, 1000);
            }
          );
      }
    }

    //  validateGiftCode(value: string, event?: ClipboardEvent) {
    //     const newValue = event ? event.clipboardData.getData('text') : value;
    //     if (newValue.startsWith('GIF')) {
    //         const codeExist: boolean = !!this.promotionRecaps.find((recap) => recap.code === newValue);

    //         if (this.newCost > 0) {
    //             this.freeMode = null;
    //         }

    //         if (this.handleCoupon.cost != null && !this.evoucherMode && !codeExist && this.planSelected && this.newCost > 0) {
    //             this.isValidatingGift = false;
    //             this.isValidatingPromotion = false;

    //             this.handleCoupon.coupon = event ? event.clipboardData.getData('text') : value;
    //             this.handleCoupon.email = this.subscription.email;
    //             this.handleCoupon.cost = this.invoiceService.selectedPlan.cost;

    //             if (this.handleCoupon.coupon.startsWith('GIF')) this.isValidatingGift = true;

    //             this.promotionError = null;
    //             this.giftError = null;

    //             this.adminService.validateCoupon(this.handleCoupon, this.token).subscribe((data: any) => {
    //                 setTimeout(() => {
    //                     this.newCost = data.cost;

    //                     if (this.newCost <= 0) {
    //                         this.freeMode = 'discount';
    //                     }

    //                     if (this.newCost < 0) this.newCost = 0;

    //                     this.promotionRecaps = [{
    //                         nature: 'Promotion',
    //                         code: this.handleCoupon.coupon,
    //                         value: this.newCost.toString(),
    //                         discount: data.valueType === "DOLLAR" ? `\$${data.discountValue}` : `${data.discountValue}%`,
    //                     }, ...this.promotionRecaps];

    //                     this.invoiceService.selectedPlan.cost = data.cost;
    //                     this.isValidatingGift = false;
    //                 }, 1000);

    //             },
    //                 (error) => {
    //                     setTimeout(() => {
    //                         if (value.startsWith('GIF')) this.promotionError = error.error.message;
    //                         this.isValidatingGift = false;
    //                     }, 1000);
    //                 });
    //         }
    //     }
    // }
  }

  promoCode: string = "";

  eVoucherPayement(event?: ClipboardEvent) {
    this.freeMode = null;
    const obj = {
      email: this.subscription.email,
      evoucherCode: event
        ? event.clipboardData.getData("text")
        : this.evoucherCode,
      planFrequancy: this.pricingFrequency,
      pricingPlanId: this.handleRequest.pricingPlanId,
    };
    const codeExist: boolean = !!this.promotionRecaps.find(
      (recap) => recap.code === obj.evoucherCode
    );

    if (!codeExist && this.planSelected) {
      this.isValidatingEvoucher = true;
      this.evoucherError = null;

      this.adminService.validateEvoucher(obj, this.token).subscribe(
        (data: any) => {
          setTimeout(() => {
            this.clearPromotions();
            this.evoucherMode = true;
            this.freeMode = "evoucher";
            this.promotionRecaps = [
              {
                nature: "Evoucher",
                code: obj.evoucherCode,
                value: "0",
                discount: "100%",
              },
              ...this.promotionRecaps,
            ];

            this.newCost = 0;

            const message = data.message;
            this.toaster.success(message, "Success", {
              closeButton: true,
              timeOut: 10000,
            });
            this.isValidatingEvoucher = false;
          }, 1000);
        },
        (error) => {
          setTimeout(() => {
            this.evoucherError = error.error.message;
            this.isValidatingEvoucher = false;
          }, 1000);
        }
      );
    }
  }

  setPromotionsState(): void {
    this.invoiceService.selectedPlan = {
      promotions: this.promotionRecaps,
      id: this.selectedPlanValues.id,
      cost: this.newCost,
      frequency: this.selectedPlanValues.frequency,
    };

    this.submitSubject.next(true);
  }

  recalculateCost() {
    console.log("recalculate cost", this.promotionRecaps);
    this.newCost = this.invoiceService.initialPrice;
    let cost = this.invoiceService.initialPrice;
    let percentValue = 0;
    let dollarValue = 0;

    // 20%
    // 10$
    this.promotionRecaps.map((recap) => {
      console.log("recap", recap);
      if (recap.discount.indexOf("%") !== -1) {
        const value = parseInt(
          recap.discount.replace("-", "").replace("%", "")
        );
        percentValue = (cost * value) / 100;

        console.log("percentValue", percentValue);
        this.newCost -= percentValue;

        console.log("new cost", this.newCost);
        // 80
      }
    });

    this.promotionRecaps.map((recap) => {
      if (recap.discount.indexOf("$") !== -1) {
        const value = parseInt(
          recap.discount.replace("-", "").replace("$", "")
        );
        dollarValue = value;
        // 90
        console.log("cost", cost);
        this.newCost -= dollarValue;
        console.log("new cost", this.newCost);
      }
    });

    if (this.newCost < 0) this.newCost = 0;

    if (this.newCost !== 0) this.freeMode = null;
  }

  validateGiftCode(value: string, event?: ClipboardEvent) {
    const newValue = event ? event.clipboardData.getData("text") : value;
    if (newValue.startsWith("GIF")) {
      const codeExist: boolean = !!this.promotionRecaps.find(
        (recap) => recap.code === newValue
      );

      if (this.newCost > 0) {
        this.freeMode = null;
      }

      if (
        this.handleCoupon.cost != null &&
        !this.evoucherMode &&
        !codeExist &&
        this.planSelected &&
        this.newCost > 0
      ) {
        this.isValidatingGift = false;
        this.isValidatingPromotion = false;

        this.handleCoupon.coupon = event
          ? event.clipboardData.getData("text")
          : value;
        this.handleCoupon.email = this.subscription.email;
        this.handleCoupon.cost = this.invoiceService.selectedPlan.cost;

        if (this.handleCoupon.coupon.startsWith("GIF"))
          this.isValidatingGift = true;

        this.promotionError = null;
        this.giftError = null;

        this.adminService
          .validateCoupon(this.handleCoupon, this.token)
          .subscribe(
            (data: any) => {
              setTimeout(() => {
                this.newCost = data.cost;

                if (this.newCost <= 0) {
                  this.freeMode = "discount";
                }

                if (this.newCost < 0) this.newCost = 0;

                this.promotionRecaps = [
                  {
                    nature: "Promotion",
                    code: this.handleCoupon.coupon,
                    value: this.newCost.toString(),
                    discount:
                      data.valueType === "DOLLAR"
                        ? `\$${data.discountValue}`
                        : `${data.discountValue}%`,
                  },
                  ...this.promotionRecaps,
                ];

                this.invoiceService.selectedPlan.cost = data.cost;
                this.isValidatingGift = false;
              }, 1000);
            },
            (error) => {
              setTimeout(() => {
                if (value.startsWith("GIF"))
                  this.giftError = error.error.message;
                  this.isValidatingGift = false;
              }, 1000);
            }
          );
      }
    }
  }
}
