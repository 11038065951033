import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AfCardComponent } from "./components/af-card/af-card.component";
import { AfIntroductionComponent } from "./components/af-introduction/af-introduction.component";
import {
  NzGridModule,
  NgZorroAntdModule,
  NzButtonModule,
  NzTableModule,
  NzBreadCrumbModule,
  NzPaginationModule,
} from "ng-zorro-antd";

import { NzInputModule } from "ng-zorro-antd/input";
import { LoginComponent } from "./components/login/login.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { AddProductComponent } from '../products/add-product/add-product.component';
import { UpdateProductComponent } from "../products/update-product/update-product.component";
import { PostServiceComponent } from "../services/post-service/post-service.component";
import { PasswordStrengthBarComponent } from "../subscription/components/password-strength-bar/password-strength-bar.component";
import {PermissionModule} from '../permission/permission.module';

@NgModule({
  declarations: [AfCardComponent, AfIntroductionComponent,AddProductComponent,UpdateProductComponent,PostServiceComponent,
    PasswordStrengthBarComponent],
  exports: [
    AfCardComponent,
    CommonModule,
    FormsModule,
    NzGridModule,
    NgZorroAntdModule,
    NzButtonModule,
    NzTableModule,
    NzBreadCrumbModule,
    NzPaginationModule,
    NzInputModule,
    NzFormModule,
    AfIntroductionComponent,
    CKEditorModule,
    AddProductComponent,
    UpdateProductComponent,
    PostServiceComponent,
    PasswordStrengthBarComponent,
    PermissionModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzGridModule,
    NgZorroAntdModule,
    NzButtonModule,
    NzTableModule,
    NzBreadCrumbModule,
    NzPaginationModule,
    NzInputModule,
    NzFormModule,
    ReactiveFormsModule,
    CKEditorModule,
    PermissionModule
    // PasswordStrengthBarComponent,
  ],
})
export class SharedModuleModule {}
