import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
import { ScrollTopService } from 'src/app/commons/services/access-to-finance/scroll-top.service';
import * as countriesJson from '../../../../assets/js/countries.json';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-freight-request-details',
  templateUrl: './freight-request-details.component.html',
  styleUrls: ['./freight-request-details.component.css']
})
export class FreightRequestDetailsComponent implements OnInit {

  itemResult: any;
  financeTypeList: any[];
  newArryFinanceTypes: any[];
  finaceTypeSelected: any[] = [];
  submited: boolean = true;
  allcountries: any = (countriesJson as any).default;
  flagUrl: any;
  shippingInfos: any[] = []
  listOriginCountry = new Array(2)
  listDestinationCountry = new Array(2)
  originCountry: any[] = [];
  destinationCountry: any[] = [];
  private list: any = []
  private list2: any = []
  private listdestination: any = [];
  private listdestination2: any = [];
  baseUrl = environment.baseUrl + "/company";
  companyDetails: any;
  listDataDetail: any;
  isVisibleDetails: boolean = false;
  constructor(private scrollTopService: ScrollTopService,
              private route: ActivatedRoute, private financeService: FinanceService) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.itemResult = this.financeService.sharedFreightDetails;
    this.shippingInfos = this.financeService.sharedFreightDetails.shippingInfos;
    /**this.financeService.getCompanyById(this.itemResult.userCompanyId).subscribe((data: any) => {
      this.companyDetails = data;
      console.log('data :>> ', data);
    });
     **/
    this.shippingInfos.forEach((element: any, index) => {
      this.originCountry[index] = element.originCountries;
      this.destinationCountry[index] = element.destinationCountries;
    })
    // this.originCountry = this.financeService.sharedFreightDetails.shippingInfos.originCountries
    this.originCountry.forEach((element: any[], index) => {
      element.forEach(item => {
        this.getCountryNameByIsoCode(index, item);
      });
    })
    this.destinationCountry.forEach((element: any[], index) => {
      element.forEach(item => {
        this.getCountryNameByIsoCodeDestination(index, item);
      });
    })
    if (this.list.length != 0) {
      this.listOriginCountry[0] = this.list
    }
    if (this.list2.length != 0) {
      this.listOriginCountry[1] = this.list2
    }
    if (this.listdestination.length != 0) {
      this.listDestinationCountry[0] = this.listdestination
    }
    if (this.listdestination2.length != 0) {
      this.listDestinationCountry[1] = this.listdestination2
    }
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ block: "start", inline: "nearest" });
  }
  getFlag(isoCode: any): boolean {
    this.allcountries.forEach((element) => {
      if (element.isocode == isoCode) {
        this.flagUrl = element.flag;
      }
    });
    return true;
  }

  getCountryNameByIsoCode(number: number, isocode: any) {

    this.allcountries.forEach((element) => {
      if (element.isocode == isocode) {
        if (number == 0) {
          this.list.push(element.label)
        }
        if (number == 1) {
          this.list2.push(element.label)
        }
      }
    });
    return true
  }
  getCountryNameByIsoCodeDestination(number: number, isocode: any) {

    this.allcountries.forEach((element) => {
      if (element.isocode == isocode) {
        if (number == 0) {
          this.listdestination.push(element.label)
        }
        if (number == 1) {
          this.listdestination2.push(element.label)
        }
      }
    });
    return true
  }
  onImgError(event){
    event.target.src = '/assets/img/Mask_group.png'
    event.onerror = null;
  }
  getUrlImg(name) {
    return this.baseUrl + "/downloadImage/" + name+".jpg";
  }
  detailInformation(data) {
    this.listDataDetail = data;
    this.isVisibleDetails = true
  }
  handleCancelDetails() {
    this.isVisibleDetails = false
  }
  getCountryByIso(value) :string {
    let country = "";
    this.allcountries.forEach(element => {
      if (element.isocode == value)
        country = element.label
    });
    return country;
  }
}
