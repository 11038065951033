import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public user: any = [];
  public totalOrdrs = 0;
  public approvedOrdr = 0;
  public rejectedOrdr = 0;
  public PendingOrdr = 0;
  public totalInq = 0;
  public inqNotseen = 0;
  public newOrdrs = 0;
  public webInq = 0;
  public buyLeadInq = 0;
  public dueValue = "total";
  public inquiryValue = "total";
  baseUrl = environment.baseUrl + '/';
  constructor( private userService: UserService, private mailService : MailService,
               private b2bService : B2bProductService, private profileRouteService : ProfileRouteService) { }

  ngOnInit() {
    this.getUser();
    this.getCountsApprovalByUser();
    this.getCountsInquiries();
    this.profileRouteService.changeRoute("dashboard");
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log("userService", res);
        this.user = res;
      })
  }
  }
  getCountsApprovalByUser() {
    this.b2bService.dashboard().subscribe(
      (res: any) => {
        this.totalOrdrs = res.countOrders;
        this.approvedOrdr = res.approvedOrders;
        this.rejectedOrdr = res.rejectedOrders;
        this.PendingOrdr = res.pendingOrders;
        this.totalInq = res.countInquiries;
        this.inqNotseen = res.countInquiryNotSeen;
        this.webInq = res.countWebInquiries;
        this.newOrdrs = res.todaysPendingOrders;
        this.buyLeadInq = res.countBuyLeadInquiries;
      })
  }
  getCountsInquiries() {
    this.mailService.getCountsInquiries().subscribe(
      (res: any) => {
        this.totalInq = res.countInquiries;
        this.webInq = res.countWebInquiries;
        this.buyLeadInq = res.countBuyLeadInquiries;
      })
  }

  changeDueValue(value) {
    this.dueValue = value;
  }

  changeInquiryValue(value) {
    this.inquiryValue = value;
  }
}
