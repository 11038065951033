import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NzCarouselComponent, NzMessageService } from 'ng-zorro-antd';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { ToastrService } from 'ngx-toastr';
import { count } from 'rxjs/operators';
import { UserService } from 'src/app/commons/services/user.service';
import { Router } from '@angular/router';
import { WebSocketService } from 'src/app/commons/services/websocket-service/web-socket.service';
import { ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css']
})

export class SearchProductComponent implements OnInit {

  // @ViewChild('carousel', { static: false }) myCarousel: NzCarouselComponent;
  hovered=false;
  baseUrl = environment.baseUrl + '/';
  isVisible = false;
  isOkLoading = false;
  submit = false;
  isVisible1 = false;
  prodsCompare = 0;
  effect = 'scrollx';
  public messages: string = null;
  public categoriesList = [];
  public currencies = [];
  classed=false;
  public certificationList = [];
  public countryList = [];
  public productDTOList = [];
  public classifications = [];
  public classifications2 = [];
  public categ ;
  public selectedcurr ="USD - Dollar Americain";
  public subCateg;
  public productDTOShow = [];
  public productCount: number = 0;
  public MinOrder: number = null;
  public minPrice: number = null;
  public maxPrice: number = null;
  public demoValue = 3;;
  public total = 0;

  public isActive = false;
  public index = null;
  public listfilterCategorie = [];
  public listCategories = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public loading: boolean = false;
  public passed: boolean = false;
  public passed1: boolean = false;

  public selectedProduct;
  public connectedUsers = [];

  public nbrSlice = 0;
  public nbrProduct = 9;
  public styles: Partial<CSSStyleDeclaration> = {
    width: '27%',
    margin: 'auto',
    height: '24%',
  };

  public fileList: UploadFile[] = [];
  public listEmplty = true;
  public messageToSend = "";
  public subject = "";
  isAllCountry =true;
  isAllCompany =true;
  agree =false;
  public role ;
  public companyName;
  public compareIds =[];
  public prodsTocompare =[];
  interested ;
  utilSettings = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 3550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 2250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

          }
        },
        {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  constructor(private sharedService: SharedService,
              private b2bserviceService: B2bProductService ,
              private msg: NzMessageService,
              private mailService: MailService,
              private toaster: ToastrService,
              private userService: UserService,
              private router:Router,
              private webSocketService:WebSocketService) {


  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      //  console.log('Load Your Data Here');
       this.slicepProduct();    }
  }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }
  ngOnInit() {
    this.getUser();
    this.getCategories();
      // this.getAllCountries();
    this.sharedService.sharedMessage.subscribe((resp: any) => {
      this.messages = resp;
      this.listfilterCategorie=[];
       this.MinOrder= null;
        this.minPrice= null;
        this.maxPrice= null;
       this.listfiltercountry=[];
       this.listShowcountry=[];
      console.log("messages", this.messages);
      this.search();
      this.getCurrencies();
    });
  }
  addTocompare(prod){
    if(this.compareIds.includes(prod.id)){
      this.removefromCompare(prod);
    }else{
    this.compareIds.push(prod.id);
    this.prodsTocompare.push(prod);
  }
    this.prodsCompare=this.prodsTocompare.length;
  }
  removefromCompare(prod){

    let index: number = this.compareIds.indexOf(prod.id);
    let index2: number = this.prodsTocompare.indexOf(prod);

    this.compareIds.splice(index, 1);
    this.prodsTocompare.splice(index2, 1);
    this.prodsCompare=this.prodsTocompare.length;
  }
  clearAllCompare(){
    this.compareIds=[]
    this.prodsTocompare=[]
    this.prodsCompare=this.prodsTocompare.length;
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log("user",res);
        this.companyName=res.subscription.companyName;
        this.role=res.role;
      })
  }
  }
  sliceCategorie() {
    this.nbrSlice = this.nbrSlice + 5;
    this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
  }
  slicepProduct() {

      this.nbrProduct = this.nbrProduct + 5;
    this.productDTOShow = this.productDTOList.slice(0, this.nbrProduct);

    // this.onRecieveConnectedUsers();

  }
  sliceCategorieMoin() {
    if (this.nbrSlice > 5) {
      this.nbrSlice = this.nbrSlice - 5;
      this.listCategories = this.categoriesList.slice(0, this.nbrSlice);
    }

  }
  addClassif(classifi) {
    // if (!this.listfilterCategorie.includes(idCatg)) {
    //   this.listfilterCategorie.push(idCatg);
    //   console.log(this.listfilterCategorie);
    // }
    // else {
    //   let index: number = this.listfilterCategorie.indexOf(idCatg);
    //   if (index !== -1) {
    //     this.listfilterCategorie.splice(index, 1);
    //   }
    // }
    this.classifications = [];
    this.classifications.push(classifi);
    this.search();
    this.classed=true;
    console.log(this.listfilterCategorie);
  }
  checkExist(id) {
    if (this.listfilterCategorie.includes(id)) {
      return true
    } else {
      return false
    }
  }
  // Country
  addCountry(idCountry,item) {
    if (!this.listfiltercountry.includes(idCountry)) {
      this.listfiltercountry.push(idCountry);
      this.listShowcountry.push(item)
      console.log(this.listfiltercountry);
    }
    this.search();
    console.log(this.listfiltercountry);
  }
  deleteCountry(idCountry,item){
      let index: number = this.listfiltercountry.indexOf(idCountry);
      let index2: number = this.listShowcountry.indexOf(item);
      if (index !== -1) {
        this.listfiltercountry.splice(index, 1);
    }
    if (index2 !== -1) {
      this.listShowcountry.splice(index2, 1);
  }
    this.search();
    console.log(this.listfiltercountry);
  }
  checkExistCountry(id) {
    if (this.listfiltercountry.includes(id)) {
      return true
    } else {
      return false
    }
  }
  clear(){
  // this.messages =null;
  this.listfilterCategorie =[];
  // this.selectedCategory =null;
  // this.selectedSubCategory =null;
  this.classed =false
  this.listfiltercountry =[];
  this.classifications =[];
  this.listShowcountry =[];
  this.MinOrder=null;
  this.minPrice=null;
  this.maxPrice=null;
  this.search();
}
  search() {
    let data = {
      page: "0",
      pageSize: "10",
      categoriesId: this.listfilterCategorie,
      search: this.messages,
      minOrder: this.MinOrder,
      fromPrice: this.minPrice,
      toPrice: this.maxPrice,
      countries: this.listfiltercountry,
      classification:this.classifications,
      certifications: [],
      sortBy: "id",
      sort: "DESC"
    }
    this.loading = true;
    this.b2bserviceService.searchProduct(data).subscribe((resp: any) => {
      console.log(resp);
      resp.productDTOList.forEach(product => {
        let  mycarousel : NzCarouselComponent;
        product.carousel = mycarousel ;
      });
      this.countryList = resp.numberByCountryList;
      this.productDTOList = resp.productDTOList;
      this.handleDiscount();
      this.classifications = resp.classifications;
      // this.classifications2 = resp.classifications;
      this.categ=resp.category;
      this.subCateg=resp.subCategory;
      this.productCount = this.productDTOList.length;
      this.slicepProduct();
      this.loading = false;

    },(err)=>{
      console.log(err);
      this.loading = false;
    })
  }
  getCategories() {
    this.b2bserviceService.getCategories("product").subscribe((resp: any) => {
      console.log("productproductproduct");
      console.log(resp);
      this.categoriesList = resp
      this.sliceCategorie();

    })

  }
  getCurrencies() {
    this.b2bserviceService.getCurrencies().subscribe((resp: any) => {
      this.currencies = resp
    })

  }
  getAllCountries() {
    this.b2bserviceService.getCountries().subscribe((resp: any) => {
      this.countryList = resp
    })
  }
  showModal(item): void {
    this.selectedProduct = item;
    this.isVisible = true;

  }
  addTointer(prod){
    let type = "product"
    this.b2bserviceService.addToInterest(prod.id,type).subscribe((resp: any) => {
      prod.isInterested =true;
      this.toaster.success(
        prod.productName+" has been added to your interests",
        "",
        {
          closeButton: true,
          timeOut: 5000
        })
    })
  }
  removeFrominter(prod){
    let type = "product"
    this.b2bserviceService.removeInterest(prod.id,type).subscribe((resp: any) => {
      prod.isInterested =false;
      this.toaster.info(
        prod.productName+" has been removed from your interests",
        "",
        {
          closeButton: true,
          timeOut: 5000
        })
    })
  }

  showModal1(item): void {
    this.selectedProduct = item;
    this.demoValue = this.selectedProduct.minimumOrderQuantity;
    this.submit = false;
    this.isVisible1 = true;
    this.countTotal(item);

  }
  countTotal(product){
    this.selectedProduct = product;
    this.total=this.demoValue*this.selectedProduct.priceDollars
    if(this.demoValue<this.selectedProduct.minimumOrderQuantity){
      this.passed=true;
    }else{
      this.passed=false;
    }
    if(this.demoValue>this.selectedProduct.quantity){
      this.passed1=true;
    }else{
      this.passed1=false;
    }
  }
  changeCurr(){
    console.log("currrr",this.selectedcurr)
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }
  handleOk1(): void {
    this.isOkLoading = true;
    setTimeout(() => {
      this.isVisible1 = false;
      this.isOkLoading = false;
    }, 3000);
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  handleCancel1(): void {
    console.log('Button cancel clicked!');
    this.isVisible1 = false;
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  ValidateFile(file){
   return true;
 }

 sendMail() {
   console.log("item : ", this.selectedProduct);

  let data = {
   receiver:this.selectedProduct.user.email,
   message: this.messageToSend,
   subject: this.selectedProduct.productName,
   productId:this.selectedProduct.id,
   isBuyLeadInquiry: false,
   isWebInquiry: true ,
   isAllCountry: this.isAllCountry ,
   isAllCompany: this.isAllCompany ,
   location: this.selectedProduct.user.country,

  //  subject: this.subject,
  }
  console.log();

  this.mailService.sendMessage(data).subscribe(resp => {
    console.log("resap : " , resp);
    this.toaster.success(
      "The inquiry has been sent succefully",
      "Success",
      {
        closeButton: true,
        timeOut: 5000
      })
      this.isVisible = false;
  },(err) => {
      // console.log("erorrrrrrrr !" , err);

    // this.toaster.error(
    //   err.error.meesage,
    //   "Error",
    //   {
    //     closeButton: true,
    //     timeOut: 5000
    //   })
  })

 }

 handleDiscount() {
  this.productDTOList.forEach(product => {
    if(product.discount == null){ product.discount=0 }

    let numVal1 = Number(product.priceDollars);
    let numVal2 = Number(product.discount) / 100;
    let totalValue = numVal1 - (numVal1 * numVal2)
    product.priceAfterDiscount = totalValue.toFixed(2);

  })
}
submitOrder(prod){
let data ={
  product:prod,
  cost:this.total,
  quantity:this.demoValue
}
this.b2bserviceService.postOrder(data).subscribe((resp: any) => {
  this.handleOk1();
  console.log("order is successful");
  console.log(resp);
  let dataType = resp.type;
  let binaryData = [];
  binaryData.push(resp);
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
  let date = new Date();
  let filename=`invoice_${prod.productName}.pdf`
  downloadLink.setAttribute('download', filename);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  this.submit = true;
})
}

startOrder(item) {
    this.router.navigate(['/order/'], {queryParams:{product:item.id}})
}
startCompare(item) {
  const url = this.router.serializeUrl(
    this.router.createUrlTree([`/compare`],{ queryParams: {ids:this.compareIds}})
  );

  window.open(url, '_blank');

}

goToLiveChat(item) {
  console.log("---------- item  --------- : " , item);
  this.router.navigate(['/profil/liveChat/'], {queryParams:{email:item}})
}

onRecieveConnectedUsers() {
  console.log("get received ");
  this.webSocketService.getConnectedUsers().subscribe((res:any)=> {
    console.log("result : " , res);
    this.connectedUsers = res;
    console.log("connected users : " , res);
    // this.connectedUsers = JSON.parse(res);
    this.connectedUsers = Object.assign({}, res);
    this.userExistInConnected(res);
    this.webSocketService.onRecieveConnectedUsers().subscribe(res=> {
    // alert("new user connected or disconnected")
      console.log("reeees : " , res );
      this.userExistInConnected(res);
    })
  });

}

userExistInConnected(users) {
  console.log(this.productDTOShow);

  this.productDTOShow.forEach((product ,index)=> {
    users.forEach(user => {
      if(product.user.email == user.email) {
        let connected = {isConnected : true}
        this.productDTOShow[index] = {...product, ...connected};
      }
    })
  })
}

pre(carousel : NzCarouselComponent){
  carousel.pre();
}
next(carousel : NzCarouselComponent){
  // console.log("prrrrrod",this.hoveredProd)
  // console.log("prrrrrod",item)
  // if(this.hoveredProd.id==item.id){
    carousel.next();
// }

}
}
