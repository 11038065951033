import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  baseUrl = environment.baseUrl+'/emails';

  constructor(private http: HttpClient) { }

  searchreceivedMessages (data) {
    return this.http.post(this.baseUrl + '/receivedMessages' , data);
  }

  searchsentMessages (data) {
    return this.http.post(this.baseUrl + '/sentMessages' , data);
  }

  searchSpamMessages (data) {
    return this.http.post(this.baseUrl + '/spamMessages' , data);
  }

  searcTrashhMessages (data) {
    return this.http.post(this.baseUrl + '/trashMessages' , data);
  }

  sendMessage (data) {
    return this.http.post(this.baseUrl + '/sendMessage' , data);
  }

  getMessageById (id) {
    return this.http.get(this.baseUrl + '/getMessages/'+id );
  }

  getCountMessages () {
    return this.http.get(this.baseUrl + '/countMsgs' );
  }

  deleteMessage (id) {
    return this.http.delete(this.baseUrl + '/delete/'+id );
  }

  sendToTrash (id) {
    return this.http.put(this.baseUrl + '/toTrash/'+id ,"");
  }
 
  getCountsInquiries () {
    return this.http.get(this.baseUrl + '/countReceivedMsgs' );
  }

  allowInquiry (id, webInquiry, buyLeadInquiry) {
    let formData = new FormData();
    formData.append("webInquiry", webInquiry);
    formData.append("buyLeadInquiry", buyLeadInquiry);
    return this.http.put(this.baseUrl + '/allow-inquiry/'+id ,formData);
  }

}
