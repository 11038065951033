import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AccessToFinanceRoutingModule } from "./access-to-finance-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PostFinanceRequestComponent } from "./pages/post-finance-request/post-finance-request.component";
import { MainComponent } from "./pages/main/main.component";
import { AfCardComponent } from "src/app/modules/shared-module/components/af-card/af-card.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FinancingOpportunitiesComponent } from "./pages/financing-opportunities/financing-opportunities.component";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { FilterComponent } from "./components/filter/filter.component";
import { SearchBuyLeadsComponent } from "./components/search-buy-leads/search-buy-leads.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorProvider } from "src/app/commons/interceptors/http-interceptor";
import { ToastrModule } from "ngx-toastr";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AccessToFinanceComponent } from "./pages/access-to-finance/access-to-finance.component";
import { SharedModuleModule } from "../shared-module/shared-module.module";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
/*  import { MyRequestsComponent } from "./pages/my-requests/my-requests.component";
 import { ChatComponent } from "./pages/chat/chat.component";  */
import { InboxComponent } from "./pages/inbox/inbox.component";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { FinanceProviderDetailsComponent } from './pages/finance-provider-details/finance-provider-details.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { FinancingOpportunitiesDetailsComponent } from './pages/financing-opportunities-details/financing-opportunities-details.component';
import { A2fContributerComponent } from './components/a2f-contributer/a2f-contributer.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
registerLocaleData(en);
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AccessToFinanceRoutingModule,
    SharedModuleModule,
    NgxIntlTelInputModule,
    ToastrModule.forRoot(),
    NzUploadModule,
    InfiniteScrollModule,
    NzEmptyModule
  ],
  declarations: [
    PostFinanceRequestComponent,
    InboxComponent,
    MainComponent,
    AccessToFinanceComponent,
    FinancingOpportunitiesComponent,
    FilterComponent,
    SearchBuyLeadsComponent,
    FinanceProviderDetailsComponent,
    FinancingOpportunitiesDetailsComponent,
    A2fContributerComponent,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorProvider,
      multi: true,
    },
  ],
})
export class AccessToFinanceModule {}
