import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { SourceService } from 'src/app/commons/services/source-service/source.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  public listData=[];
  importers = [];
  constructor(private router: Router,private sourceService : SourceService,private freightService: FreightService,private msg: NzMessageService,private sharedService: SharedService,private buyRequestService: BuyRequestService) { }

  ngOnInit() {
    this.PostRequests();
    this.search();
  }
  PostRequests(){
    this.buyRequestService.allLeads().subscribe((resp: any) => {
      this.listData=resp
      this.listData = this.listData.slice(0, 50);
      console.log("imprt",this.listData)
    })
  }
  search() {
    let data={
      originCountry:'',
      destinationCountry:'',
      shippingMode:'',
      period:'',
    }
    this.freightService.searchPostFreight(data).subscribe((resp: any) => {
      this.importers = resp;
      // this.importers = this.importers.slice(0, 50);
      console.log("imprt",this.importers)
    })
  }  getByID(id){
    this.sharedService.nextSearchProductByID(id);
    this.router.navigate(['/buy-request/product']);

   }



}
