import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from "./pages/main/main.component";
import {PostFinanceRequestComponent} from "./pages/post-finance-request/post-finance-request.component";

import { FinancingOpportunitiesComponent } from './pages/financing-opportunities/financing-opportunities.component';
import { AccessToFinanceComponent } from './pages/access-to-finance/access-to-finance.component';
// import { MyRequestsComponent } from './pages/my-requests/my-requests.component';
// import { ChatComponent } from './pages/chat/chat.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { FinancingOpportunitiesDetailsComponent } from './pages/financing-opportunities-details/financing-opportunities-details.component';
import { FinanceProviderDetailsComponent } from './pages/finance-provider-details/finance-provider-details.component';



const routes: Routes = [
  { path: '', component: MainComponent, pathMatch: 'full' },

  { path: 'subaccesstofinance', component: AccessToFinanceComponent, pathMatch: 'full',data: {
    breadcrumb: 'Access To Finance',
    activeRouteIndex:'2'
  },
},
  {
    path: 'postfinancerequest', component: PostFinanceRequestComponent, pathMatch: 'full'  ,data: {
    breadcrumb: 'Post Finance Request',
    activeRouteIndex:'2'
  },
},
{
  path: 'financingopperunities', component: FinancingOpportunitiesComponent, pathMatch: 'full'  ,data: {
  breadcrumb: 'Financing Opportunities',
  activeRouteIndex:'2'
},

},
// {
//   path: 'myrequests', component: MyRequestsComponent, pathMatch: 'full'  ,data: {
//   breadcrumb: 'My Request',
//     activeRouteIndex: '2'
// },

// },
// {
//   path: 'messageCenter', component: ChatComponent, pathMatch: 'full'  ,data: {
//   breadcrumb: 'chat',
//   activeRouteIndex:'2'
// },

// },
/* {
  path: 'inbox/:id', component: InboxComponent,data: {
  breadcrumb: 'inbox'
},

} */


{ path: 'messageCenter/inbox/:transmitterId/:section/:chatMatricule/:subject', component: InboxComponent },
{ path: 'subaccesstofinance/finance-provider-details', component: FinanceProviderDetailsComponent,   },
{ path: 'financingopperunities/financing-opportunities-details', component: FinancingOpportunitiesDetailsComponent,   }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
   ],
  exports: [RouterModule],
})
export class AccessToFinanceRoutingModule { }
