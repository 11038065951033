import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { IndividualComponent } from './pages/individual/individual.component';
import { BusinessComponent } from './pages/business/business.component';
import { B2bComponent } from './pages/b2b/b2b.component';






const routes: Routes = [
  { path: '', component: MainComponent, pathMatch: 'full'},
  { path: 'individual', component: IndividualComponent, pathMatch: 'full' },
  { path: 'business', component: BusinessComponent, pathMatch: 'full'},
  { path: 'b2b', component: B2bComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
   ],
  exports: [RouterModule],
})
export class SusbcriptionRoutingModule { }
