import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BuyRequestService } from "src/app/commons/services/service buy-request/buy-request.service";
import { environment } from "src/environments/environment";
import { SharedService } from "src/app/commons/shared service/shared-service";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";

@Component({
  selector: "app-all-categories",
  templateUrl: "./all-categories.component.html",
  styleUrls: ["./all-categories.component.css"],
})
export class AllCategoriesComponent implements OnInit {
  public listCategories = [];
  public url = environment.baseUrl + "/";
  public arryClass = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public classes = [
    { background: "#d6cbbf", colorLabel: "#968473", backButton: "#968473" },
    { background: "#ceeaf9", colorLabel: "#4281a2", backButton: "#64a9ce" },
    { background: "#f3a3b6", colorLabel: "#ae556a", backButton: "#d48295" },
    { background: "#8ab3e1", colorLabel: "#4e81b8", backButton: "#598fcc" },
    { background: "#b1d4da", colorLabel: "#5b6169", backButton: "#6b9e9f" },
    { background: "#abcfd0", colorLabel: "#29757c", backButton: "#6b9e9f" },
    { background: "#5c5ffb", colorLabel: "#3336a2", backButton: "#8081e1" },
    { background: "#257097", colorLabel: "#193e53", backButton: "#dfb21e" },
    { background: "#fde181", colorLabel: "#b99316", backButton: "#b99316" },
    { background: "#69ca9e", colorLabel: "#359068", backButton: "#53af85" },
    { background: "#ffb456", colorLabel: "#d77f10", backButton: "#e58915" },
  ];
  constructor(
    private b2bserviceService: B2bProductService,
    private buyRequestService: BuyRequestService,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.getCategories();
  }

  getIconSrc(src) {
    return this.url +  'file/aws/download/' + src;
  }
  getCategories() {
    this.b2bserviceService.getCategories("product").subscribe((resp: any) => {
      resp.forEach((category) => {
        let item;
        item = { ...category, style: this.getrandomItem() };
        this.listCategories.push(item);
      });
    });

    console.log(this.listCategories);
  }
  gotTo(ids) {
    console.log(ids);
    let data = { id: ids.id, name: ids.name };
    this.sharedService.nextSearchsearchByCategories(data);
    this.router.navigate(["/ResultsCategory"]);
    // this.router.navigate(['/ResultsCategory'], { queryParams: { id: ids.id, name: ids.name} });
  }

  // getrandomItem(){
  //   const random = Math.floor(Math.random() * this.arryClass.length);
  //   console.log(random, this.arryClass[random]);
  //   return "catg"+random;
  // }
  getrandomItem() {
    const item = Math.floor(Math.random() * this.classes.length);
    return this.classes[item];
  }
}
