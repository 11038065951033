import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { PayementService } from 'src/app/commons/services/stripe-payement/payement.service';

declare var PaymentSession;
@Component({
  selector: 'app-featured-request-payment',
  templateUrl: './featured-request-payment.component.html',
  styleUrls: ['./featured-request-payment.component.css']
})
export class FeaturedRequestPaymentComponent implements OnInit {

  constructor(
    private msg: NzMessageService,private fb: FormBuilder, private paymentService : PayementService
    ,private b2bProductService : B2bProductService,  private route : ActivatedRoute,
    private  router: Router) { }
  requestId;
  request;
  public formShipping!: FormGroup;
  ngOnInit() {
    this.createOnlinePaymentForm();
    this.route.params.subscribe(data => {
      this.requestId = data.id;
    });
    this.getRequestbyId();
    setTimeout(() => {
      this.createPaymentSession();
  }, 100);

  }
  getRequestbyId() {
    this.b2bProductService.getFeaturedRequest(this.requestId).subscribe((resp:any)=> {
      this.request = resp;
      console.log("request",resp);

    })
  }
  createOnlinePaymentForm() {
    this.formShipping = this.fb.group({
      nameOnCard: [null, [Validators.required]],
      creditCard: [null, [Validators.required]],
      experationMonth: [null, [Validators.required]],
      experationYear: [null, [Validators.required]],
      cvc: [null, [Validators.required]],
    });
  }
  pay() {
    // UPDATE THE SESSION WITH THE INPUT FROM HOSTED FIELDS
    PaymentSession.updateSessionFromForm('card');
}
sessionId ="";
createPaymentSession() {
  console.log("create payment Session");
  let that = this;
  PaymentSession.configure({
    session: this.sessionId,
    fields: {
        // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
        card: {
            number: "#creditCard",
            securityCode: "#cvc",
            expiryMonth: "#experationMonth",
            expiryYear: "#experationYear",
            nameOnCard: "#nameOnCard"
        }
    },
    //SPECIFY YOUR MITIGATION OPTION HERE
    frameEmbeddingMitigation: ["javascript"],
    callbacks: {
        initialized: function(response) {
            // HANDLE INITIALIZATION RESPONSE
            console.log(response);
        },
        formSessionUpdate: function(response) {
            // HANDLE RESPONSE FOR UPDATE SESSION
            console.log("heeeeeeere :", response );

            if (response.status) {
                if ("ok" == response.status) {
                    that.sessionId = response.session.id;
                    console.log("Session updated with data: " + response.session.id);
                    console.log("luuuul : " , that.sessionId);
                    that.passPaymentToBackend();
                    //check if the security code was provided by the user
                    if (response.sourceOfFunds.provided.card.securityCode) {
                        console.log("Security code was provided.");
                    }

                    //check if the user entered a Mastercard credit card
                    if (response.sourceOfFunds.provided.card.scheme == 'MASTERCARD') {
                        console.log("The user entered a Mastercard credit card.")
                    }
                } else if ("fields_in_error" == response.status)  {

                    console.log("Session update failed with field errors.");
                    if (response.errors.cardNumber) {
                        console.log("Card number invalid or missing.");
                    }
                    if (response.errors.expiryYear) {
                        console.log("Expiry year invalid or missing.");
                    }
                    if (response.errors.expiryMonth) {
                        console.log("Expiry month invalid or missing.");
                    }
                    if (response.errors.securityCode) {
                        console.log("Security code invalid.");
                    }
                } else if ("request_timeout" == response.status)  {
                    console.log("Session update failed with request timeout: " + response.errors.message);
                } else if ("system_error" == response.status)  {
                    console.log("Session update failed with system error: " + response.errors.message);
                }
            } else {
                console.log("Session update failed: " + response);
            }
        },
      visaCheckout: function(response) {
          // HANDLE VISA CHECKOUT RESPONSE
          console.log("testing " , response);

      },
      amexExpressCheckout: function(response) {
         // HANDLE AMEX EXPRESS CHECKOUT RESPONSE
      }
    },
    interaction: {
        displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT"
        }
    },
    order: {
        amount: this.request.price,
        currency: "USD" ,
        id: that.requestId
    }
 });
}
passPaymentToBackend() {
  this.paymentService.passCreditCardPayment(this.sessionId, this.requestId).subscribe(res => {
    console.log("returned value " , res);

    this.msg.success('Payment is Successful');
    this.router.navigate(['/profil/featuringRequests']);
  })
}
}
