import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { throwError } from 'rxjs';
import { ContactService } from 'src/app/commons/services/contacts/contact.service';
import { ProfileRouteService } from 'src/app/commons/services/profiles-route/profile-route.service';

@Component({
  selector: 'app-chage-pass',
  templateUrl: './chage-pass.component.html',
  styleUrls: ['./chage-pass.component.css']
})
export class ChagePassComponent implements OnInit {

  passwordVisible = false;
  passwordVisible1 = false;
  currentPassword?: string;
  newPassword?: string;
  rePassword?: string;

  public formPassword:FormGroup;

  constructor(private contactService : ContactService,private fb: FormBuilder,private notification: NzNotificationService,
    private  router: Router, private profileRouteService: ProfileRouteService) { }

  ngOnInit() {
    this.profileRouteService.changeRoute("ChangePassword");
    this.formPassword = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      rePassword: ['', Validators.required],
    });
  }

  checkPasswordsMatch() {
    if(this.newPassword == this.rePassword) {return true }else return false
  }

  updatePassword() {
   let data = {
      currentPassword:this.currentPassword,
      newPassword:this.newPassword
    }
    this.contactService.changePassword(data).subscribe(resp=> {
      this.notification.blank(
        'Password changed successfully ',''
      );

      this.router.navigate(['/']);
      console.log(resp);
    })

  }

}
