import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-categories-home',
  templateUrl: './categories-home.component.html',
  styleUrls: ['./categories-home.component.css']
})
export class CategoriesHomeComponent implements OnInit {

  baseUrl = environment.baseUrl + '/';
  public subCats = [];
  public subCatsLength
  public randomCat;
  public randomSubCat;
  public randomSubCatIndex;
  public cats = [];
  public hovered = false;
  public catName;
  public subCatName;
  public catId;
  isVisible = false;
  public messages: string = null;
  public categoriesList = [];
  public listfilterSub  = [];
  public certificationList = [];
  public countryList = [];
  public productDTOList = [];
  public productDTOShow = [];
  public productCount: number = 0;
  public MinOrder: number = null;
  public minPrice: number = null;
  public maxPrice: number = null;

  public isActive = false;
  public index = null;
  public listfilterCategorie = [];
  public listClassfication = [];
  hasClass = true;
  public listClass = [];
  public listfiltercountry = [];
  public listShowcountry = [];
  public loading: boolean = false;
  public listfilterClass = [];
  public categorieHomes = [];
  public classes =[
    {background:"#d6cbbf",colorLabel:"#968473",backButton:"#968473"},
    {background:"#ceeaf9",colorLabel:"#4281a2",backButton:"#64a9ce"},
    {background:"#f3a3b6",colorLabel:"#ae556a",backButton:"#d48295"},
    {background:"#8ab3e1",colorLabel:"#4e81b8",backButton:"#598fcc"},
    {background:"#b1d4da",colorLabel:"#5b6169",backButton:"#6b9e9f"},
    {background:"#abcfd0",colorLabel:"#29757c",backButton:"#6b9e9f"},
    {background:"#5c5ffb",colorLabel:"#3336a2",backButton:"#8081e1"},
    {background:"#257097",colorLabel:"#193e53",backButton:"#dfb21e"},
    {background:"#fde181",colorLabel:"#b99316",backButton:"#b99316"},
    {background:"#69ca9e",colorLabel:"#359068",backButton:"#53af85"},
    {background:"#ffb456",colorLabel:"#d77f10",backButton:"#e58915"},
  ];
  constructor(private router: Router,private b2bserviceService: B2bProductService) { }

  ngOnInit() {
    this.getCategoryHome();
    // this.getProductsBySubCategoriesIn();
  }
  getProductsBySubCategoriesIn(){
    this.b2bserviceService.getProductsBySubCategoriesIn().subscribe((resp:any)=> {
      this.cats = resp;
      this.cats = this.cats.sort(() => 0.5 - Math.random());

    })
  }

  getCategoryHome() {
    this.b2bserviceService.getCategoryHome().subscribe((resp:any)=> {
      resp.forEach( category => {
        let item;
        item = {...category, style : this.getrandomItem() }
        this.categorieHomes.push(item)
      })
      console.log("this.categorieHomes : " , this.categorieHomes);

    })
  }
  openProdInNewWindow(prod) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/Product/${prod.id}`])
    );

    window.open(url, '_blank');
  }

  getrandomItem(){
    const item = Math.floor(Math.random() * this.classes.length);
    return this.classes[item];
  }

  visitSubcategory(item){
    // this.router.navigateByUrl
    // routerLink]="[ '/Subcategory/',item.subCatImg[0].subCategory]"
    this.router.navigate(['/Subcategory/',item.subCatImg[0].subCategory]);
  }

}
