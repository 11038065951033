import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Opportunitie } from '../../models/opportunitie';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinanceOpportunitiesService {

  baseUrl = environment.baseUrl;

  constructor(private http:HttpClient) {
    this.http = http;
   }
  filterFinanceOpportunities(object) {
    return this.http.post(this.baseUrl + '/access_finance/financeOpportunitiesFiltre', object)
  }
    getAllFinancingOpportunities(){
        return this.http.get<Opportunitie[]>(this.baseUrl+'/financing_opportunities')
    }
    getAllFinancingOpportunitiesByUser(userId){
        return this.http.get<Opportunitie[]>(this.baseUrl+'/financing_opportunities/history/'+userId)
    }

    getFilterByPagination(criteria){
      return this.http.get(this.baseUrl +'/financing_opportunities/filter',{params : criteria})
   }

   getCountries(){
    return this.http.get(this.baseUrl+'/financing_opportunities/filterInput/countries')
  }
  getAll(){
    return this.http.get(this.baseUrl+'/financing_opportunities/getAll')
  }

  getFinanceTypes(){
    return this.http.get(this.baseUrl+'/financing_opportunities/filterInput/financeTypes')
  }

  getFinanceFacility(){
    return this.http.get(this.baseUrl+'/financing_opportunities/filterInput/financeFacility')
  }

  getInstitutionTypes(){
    return this.http.
    get(this.baseUrl+'/financing_opportunities/filterInput/institutionTypes')
  }

  searchBykey(criteria){
    return this.http.get(this.baseUrl+'/financing_opportunities/search',{params : criteria})
  }
  getAllCountries() {
    return this.http.get('./assets/js/countries.json');
  }
  getTitle(id: number) {
    return this.http.get(this.baseUrl + '/title/' + id);
  }

  updateTitle(body:any){
    return this.http.put(this.baseUrl+'/title',body)
  }

  create(financingOpportunities:any){
     return this.http.post(this.baseUrl+'/financing_opportunities',financingOpportunities);
  }

  update(financingOpportunities:any,id){
     return this.http.put(this.baseUrl+'/financing_opportunities/'+id,financingOpportunities);
  }
  delete(id){
    return this.http.delete(this.baseUrl+'/financing_opportunities/'+id);
  }
  getTitleIntroduction(){
    return this.http.get(this.baseUrl+'/title/module/A2F_FO')
  }
  getFinanceType(){
    return this.http.get(this.baseUrl+'/params/finance_type_opportunities');
  }

   searchFinanceOpportunities(object){
     return this.http.post(this.baseUrl+'/params/finance_opportunities',object)
  }

  getFinanceRequester(){
    return this.http.get(this.baseUrl+'/params/finance_requester')
  }

  getNatureBusiness(){
    return this.http.get(this.baseUrl+'/params/naturebusiness')
  }



}
