import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css']
})
export class ComparisonComponent implements OnInit {
  baseUrl = environment.baseUrl + '/';
  public compareIds=[];
  public prods=[];
  constructor(private activatedRoute : ActivatedRoute,
    private b2bserviceService: B2bProductService) { }

  ngOnInit() {
    this.getParams();

  }
  getParams() {
    this.activatedRoute.queryParams.subscribe(res => {
     this.compareIds=res.ids;
     this.getprods();
    })
  }
  getprods(){
    let data = {
      productIds:this.compareIds
    }
    this.b2bserviceService.getProdsByIds(data).subscribe((resp: any) => {
      this.prods=resp;
      console.log("prodsd",this.prods)
    })
  }

}
