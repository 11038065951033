import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  catName;
  category;
  public subCats:any  = [];
  public subCatsWithClass:any  = [];
  public subCatsWithoutClass:any  = [];
  constructor(  private b2bProductService : B2bProductService,private route : ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe(data => {
      this.catName = data.catName;
      this.getAllByCategories();
    });

  }

  getAllByCategories() {
    this.b2bProductService.getAllByCategories(encodeURIComponent(this.catName)).subscribe((resp:any)=> {
      this.category = resp;
      this.subCats=resp.subCategories;
      this.subCatsWithClass = this.subCats.filter(cla => cla.classification.length !== 0);
      this.subCatsWithoutClass = this.subCats.filter(cla => cla.classification.length === 0);
    })
  }

}
