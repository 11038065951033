import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-af-card',
  templateUrl: './af-card.component.html',
  styleUrls: ['./af-card.component.css']
})
export class AfCardComponent implements OnInit {


  @Input()
  title:String;
  
  @Input()
  description:String;
  
  @Input()
  image_url:String;

  @Input()
  backgroundColor:String;

  constructor() { }

  ngOnInit() {
  }

}
