import { Component, HostListener, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { UploadFile } from 'ng-zorro-antd';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/commons/services/mail service/mail.service';
import { TradeValueChainService } from 'src/app/commons/services/service tradeValueChain/trade-value-chain.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-service',
  templateUrl: './search-service.component.html',
  styleUrls: ['./search-service.component.css']
})
export class SearchServiceComponent implements OnInit {
 messages
 public loading: boolean = false;
 public productDTOList = [];
 public productDTOShow = [];
 public productCount: number = 0;
 public selectedProduct;
 public nbrProduct = 9;
 public listCategories = [];
 public listfilterCategorie = [];
 public countryList = [];
 public listfiltercountry = [];
 public listShowcountry = [];
 public isVisible = false;
 effect = 'scrollx';
 public messageToSend = "";
 public subject = "";
 isAllCountry =true;
 isAllCompany =true;
 agree =false;
 public fileList: UploadFile[] = [];
 public listEmplty = true;
 public companyName;

 baseUrl = environment.baseUrl + '/';
  constructor(private sharedService: SharedService,private servService:TradeValueChainService,
    private mailService: MailService,
    private toaster: ToastrService,
    private userService: UserService) { }
  animationCreated(animationItem: AnimationItem) { }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      //  console.log('Load Your Data Here');
       this.slicepProduct();    }
  }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }
  ngOnInit() {
    this.getUser();
    this.sharedService.sharedMessage.subscribe((resp: any) => {
      this.messages = resp;
      this.search();
    });
  }
  search() {
    let data = {
      categoriesId: this.listfilterCategorie,
      serviceName: this.messages,
      countries: this.listfiltercountry,

    }
    this.loading = true;
    this.servService.serviceSearch(data).subscribe((resp: any) => {
      console.log(resp);
       this.listCategories=resp.categories;
      this.countryList = resp.numberByCountryList;
      this.productDTOList = resp.services;
      this.productCount = this.productDTOList.length;
      this.slicepProduct();
      this.loading = false;
    },(err)=>{
      console.log(err);
      this.loading = false;
    })
  }
  slicepProduct() {

    this.nbrProduct = this.nbrProduct + 5;
  this.productDTOShow = this.productDTOList.slice(0, this.nbrProduct);


}
addCategorie(classif) {
  if (!this.listfilterCategorie.includes(classif)) {
    this.listfilterCategorie.push(classif);
    console.log(this.listfilterCategorie);
  }
  else {
    let index: number = this.listfilterCategorie.indexOf(classif);
    if (index !== -1) {
      this.listfilterCategorie.splice(index, 1);
    }
  }
  this.search();
  console.log(this.listfilterCategorie);
}
addCountry(idCountry,item) {
  if (!this.listfiltercountry.includes(idCountry)) {
    this.listfiltercountry.push(idCountry);
    this.listShowcountry.push(item)
    console.log(this.listfiltercountry);
  }
  this.search();
  console.log(this.listfiltercountry);
}

ValidateFile(file){
  return true;
}
beforeUploads = (file: UploadFile): boolean => {
  this.fileList = this.fileList.concat(file);

  this.fileList.forEach((file: any) => {
    let data= this.ValidateFile(file);
    if(!data) {
      this.fileList =[]
      this.listEmplty=true;
      return true;
    } else {
      this.listEmplty = false;
    }
  });
  return false;
};
deleteCountry(idCountry,item){
    let index: number = this.listfiltercountry.indexOf(idCountry);
    let index2: number = this.listShowcountry.indexOf(item);
    if (index !== -1) {
      this.listfiltercountry.splice(index, 1);
  }
  if (index2 !== -1) {
    this.listShowcountry.splice(index2, 1);
}
  this.search();
  console.log(this.listfiltercountry);
}

getUser() {
  let id = localStorage.getItem("USER_ID")
  if(id)
  {
  this.userService.getAuthUser(id).subscribe(
    (res: any) => {
      console.log("user",res);
      this.companyName=res.subscription.companyName;
    })
}
}
handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}
handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isVisible = false;
}

checkExistCountry(id) {
  if (this.listfiltercountry.includes(id)) {
    return true
  } else {
    return false
  }
}
checkExist(id) {
  if (this.listfilterCategorie.includes(id)) {
    return true
  } else {
    return false
  }
}
showModal(item): void {
  this.selectedProduct = item;
  this.isVisible = true;

}
sendMail() {
  console.log("item : ", this.selectedProduct);

 let data = {
  receiver:this.selectedProduct.user.email,
  message: this.messageToSend,
  subject: this.selectedProduct.productName,
  productId:this.selectedProduct.id,
  isBuyLeadInquiry: false,
  isWebInquiry: true ,
  isAllCountry: this.isAllCountry ,
  isAllCompany: this.isAllCompany ,
  location: this.selectedProduct.user.country,

 //  subject: this.subject,
 }
 console.log();

 this.mailService.sendMessage(data).subscribe(resp => {
   console.log("resap : " , resp);
   this.toaster.success(
     "The inquiry has been sent succefully",
     "Success",
     {
       closeButton: true,
       timeOut: 5000
     })
     this.isVisible = false;
 },(err) => {
     // console.log("erorrrrrrrr !" , err);

   // this.toaster.error(
   //   err.error.meesage,
   //   "Error",
   //   {
   //     closeButton: true,
   //     timeOut: 5000
   //   })
 })

}
}
