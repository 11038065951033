import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
import { ContactService } from 'src/app/commons/services/contacts/contact.service';
import { UserService } from 'src/app/commons/services/user.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

  id: any;
  section:any;
  subject:any;
  chatMatricule:any;
  message:any;
  submitted:boolean=false;
  userId:any;
  user:any;
  transmitter:any;
  inboxForm = new FormGroup({});
  key: string;
  accesstoFinance:any={

  };
  criteria:any={

  };
  searchKey:any={

  }
  
  count=0;
  contact:any={

  }
  messages:any[];
  searchingMessage:any[];
  messagefound:any;

  constructor(private contactService:ContactService,
    private activatedRoute:ActivatedRoute,
    private userService:UserService,
    private financeService:FinanceService,
    private fb: FormBuilder,
    private msg: NzMessageService
    ) { }
  

  ngOnInit() {
    this.getAllMessageBetween();
    this.initParams();
    this.getUsernameById();
    this.userId = localStorage.getItem("USER_ID");
    this.initForms();
  }

  initForms(){
    this.inboxForm = this.fb.group({
       msg:[null,[Validators.required]]
    })
  }
  
  initParams(){
    this.activatedRoute.params.subscribe(params => {
    this.id = params['transmitterId'];  
    this.section = params['section'];
    this.subject = params['subject'];
    this.chatMatricule = params['chatMatricule'];
      });
  }


getAllMessageBetween(){
  this.initParams();
  this.criteria.transmitterId =this.id;
  this.criteria.section=this.section;
  this.criteria.subject= this.subject;
  this.criteria.chatMatricule= this.chatMatricule;
      this.contactService.getAllMessageBetween(this.criteria).subscribe(
      (data:any) =>{
        this.messages = data;
        console.log(this.messages)
        this.messages.forEach(message => {
           if(message.userDTO.id!=this.userId){
            this.contact.receiver =message.userDTO;
           }
        });

        this.contact.accessFinance=this.messages[this.messages.length-1].accessFinanceDTO;
        console.log("Acces to finance")
        console.log(this.contact.accessFinance)

        this.contact.financingOpportunities = this.messages[this.messages.length-1].financingOpportunitiesDTO;
        console.log("financing Opportunities")
        console.log(this.contact.financingOpportunities)

        this.contact.fdiOpportunities = this.messages[this.messages.length-1].fdiOpportunitiesDTO;
        console.log("Fdi Opportunities")
        console.log(this.contact.fdiOpportunities)

        this.contact.fdiDTO = this.messages[this.messages.length-1].fdiDTO;
        console.log("Fdi")
        console.log(this.contact.fdiOpportunities)
      }
    )
 }   

 getUsernameById(){
  this.userId = localStorage.getItem("USER_ID")  
  this.userService.findById(this.userId).subscribe(
    (data:any) =>{
      console.log(data)
     this.user = data;
    

  })
 }

 sendMessage(){
   this.submitted=true;
   console.log(this.contact.subject)
   this.contact.subject = this.subject
   this.contact.message = this.inboxForm.value.msg;
   this.contact.section=this.section;
   this.contactService.contactNow(this.contact).subscribe((data:any)=>{
          console.log(data);
          this.getAllMessageBetween();
        
   })
   this.inboxForm.reset();
}


downloadFile(id:any,fileName:any){
  console.log(id);
    this.contactService.download(id).subscribe((data:any)=>{
      console.log(data);
      let dataType = data.type;
      let binaryData = [];
      binaryData.push(data);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      downloadLink.setAttribute('download',fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.msg.success('download successfully.');

})

}


searchByKey(value:string){

    this.key = value;
    this.searchKey.search  = value;
    this.searchKey.chatMatricule =  this.chatMatricule;
    this.contactService.getAllMessageBetweenSearch(this.searchKey).subscribe(
      (data:any)=>{
        this.messages = data;          
      })
  
      if(this.key==null || this.key==''){
        this.getAllMessageBetween();
      }
}

}