import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';
import {UserService} from '../../../../commons/services/user.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  introductionTitle
  introduction: any = {}
  isdisable : boolean = true;
  isdisablePost : boolean = true;
  isdisableFinanceOpp :boolean=true;
  checkAdminUser = true
  data = [
    {
      title: "ACCES TO FINANCE",
      routerLink:"subaccesstofinance",
      description:"This feature enables businesses, consumer and subscribers to search for finance opportunities and services across the continent by type of finance, service provider, institution type and country",
      image_url:"assets/images/icons/Access to finance.png",
      backgroundColor:"item1"
    },
    {
      title: "FINANCING OPPORTUNITIES",
      routerLink:"financingopperunities",
      description:"This feature enables businesses, consumer and subscribers to search for finance opportunities and services across the continent by type of finance, service provider, institution type and country",
      image_url:"assets/images/icons/Finance opportunities.png",
      backgroundColor:"item2"
    },
    {
      title: "POST FINANCE REQUEST", routerLink:"postfinancerequest",
      description:"This feature enables businesses, consumer and subscribers to search for finance opportunities and services across the continent by type of finance, service provider, institution type and country",
      image_url:"assets/images/icons/Post request.png",
      backgroundColor:"item3",
    },
  ]
  role: any[] = [];
  listOfRoutes: any;
  constructor(private financeService: FinanceService, private userService: UserService) {
    this.financeService = financeService;
  }
  saveIntroduction(value) {
    this.introductionTitle = value;
    this.updateIntroduction(this.introductionTitle)
  }

  ngOnInit() {
    this.role = this.userService.currentUser && this.userService.currentUser.role;
    this.checkAdminUser = this.itsUserNorAdmin(this.role)
    this.listOfRoutes = localStorage.getItem("PERMITTED_ROUTE")
    console.log("acc listOfRoutes", this.listOfRoutes)
    console.log("ROLE", this.role)
  }
  itsUserNorAdmin(roles) {
    if (roles.indexOf("ROLE_ADMIN") > -1 || roles.indexOf("ROLE_SUB_ADMIN") > -1) {
      return true
    } else if (roles.indexOf("ROLE_USER") > -1)
      return true
    return false
  }
  getIntroduction() {
    let id: number = 1
    this.financeService.getTitleIntroduction().subscribe(
      (data: any) => {
        this.introductionTitle = data.text
      },
      (error) => console.log(error))
  }

  updateIntroduction(value: any) {
    this.introduction.id = 1
    this.introduction.text = value
    this.financeService.updateTitle(this.introduction).subscribe(
      (data: any) => {
        this.introductionTitle = data.text
      },
      (error) => console.log(error))
  }
  MoreText(){
   this.isdisable=false;
  }
  MoreTextPostFinance(){
    this.isdisablePost=false;
  }
  MoreTextFinanceOpp(){
   this.isdisableFinanceOpp=false;
  }
  LessTextFinanceOpp(){
   this.isdisableFinanceOpp=true;
  }
  LessTextPostFinance(){
    this.isdisablePost=true;
  }
  LessText(){
    this.isdisable=true;
  }
}
