import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})
export class QuotationComponent implements OnInit {

  constructor(private msg: NzMessageService,private route : Router ,private productService: B2bProductService,private sharedService: SharedService
    ,private userService: UserService) { }
  prodname : any;
  quantity: number ;
  public listUnitOfMeasure:any=[];
  public role;
  quantityUnit: any ;
  ngOnInit() {
    this.getAllUnitOfMeasure();
    this.getUser();
  }

  getUser() {
    const id = localStorage.getItem('USER_ID');
    if (id) {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        console.log("Usssssser",res);
        this.role = res.role;
      });
    }
  }
  getAllUnitOfMeasure(){
    this.productService.getUnitOfMeasure().subscribe((resp: any) => {
      console.log(resp);
      let data =resp
      this.listUnitOfMeasure=data.sort();
    })
  }
  toQuote(){
    this.getUser();
    if(this.role !="ROLE_SELLER"&&this.role){
    let data ={
      quantity :this.quantity,
      quantityUnit :this.quantityUnit,
      prodName :this.prodname,
    }
    this.sharedService.nextPostBuy(data);
    this.route.navigateByUrl('/PostRequest');


  }else if(this.role =="ROLE_SELLER"){

    this.msg.error('This feature is available to Buyers');
  }
  else if(!this.role){

    this.msg.error('Please sign in to get quotations');
  }
}
}
