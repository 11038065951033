import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, Event } from "@angular/router";
import { UserService } from "src/app/commons/services/user.service";
import { Mode, Permission } from "src/app/modules/sub-admin/sub-admin.model";
import { environment } from "src/environments/environment";

interface sidenavChildren {
  childName: string;
  route: string;
}

interface sideNav {
  parentName: string;
  route?: string;
  children: sidenavChildren[];
}

@Component({
  selector: "app-b2blayout",
  templateUrl: "./b2blayout.component.html",
  styleUrls: ["./b2blayout.component.css"],
})

// export class B2blayoutComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
export class B2blayoutComponent implements OnInit {
  isCollapsed: boolean = false;
  currentRoute;
  activeRouteNavBar: string = "";
  currentUser = this.userService.currentUser;
  public baseUrl = environment.baseUrl + "/";
  imgUrl = environment.baseUrl + "/file/aws/download/";
  sidenavDynamiqueContent: any[] = [
    // {
    //   id: "PROFILE",
    //   target: "#dashboard",
    //   icon: "dashboard",
    //   toggle: false,
    //   active: false,
    //   parentName: "DASHBOARD",
    //   route: "/B2BManagment",
    //   children: [],
    // },
    {
      id: "dashboard",
      target: "#dashboard",
      icon: "dashboard",
      toggle: false,
      active: false,
      parentName: "DASHBOARD",
      route: "/B2BManagment/dashboard",
      children: [],
    },
    {
      id: "package-management",
      icon: "bank",
      target: "#package-management",
      toggle: false,
      active: false,
      parentName: "PACKAGE MANAGEMENT",
      children: [
        {
          childName: "Package Management",
          route: "/B2BManagment/package-management",
        },
        {
          childName: "Bank Account Management",
          route: "/B2BManagment/BankAcount-Management",
        },
      ],
    },
    {
      id: "discount-management",
      target: "#discount-management",
      icon: "strikethrough",
      toggle: false,
      active: false,
      parentName: "DISCOUNT MANAGEMENT",
      route: "",
      children: [
        { childName: "Promo/Gift", route: "/B2BManagment/discount-management" },
      ],
    },
    {
      id: "evoucher",
      target: "#evoucher",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "EVOUCHER MANAGEMENT",
      route: "/B2BManagment/evoucher",
      children: [],
    },
    {
      id: "AdsManagement",
      target: "#adsManagment",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "ADS MANAGEMENT",
      route: "/B2BManagment/Ads-Management",
      children: [],
    },
    {
      id: "data-management",
      icon: "database",
      target: "#data-management",
      toggle: false,
      active: false,
      parentName: "DATA MANAGEMENT",
      route: "",
      children: [
        {
          childName: "Freight Opportunities",
          route: "/B2BManagment/data-management/freight-opportunities",
          //actions: permissionKeysObject.freightOpportunities,
        },
        {
          childName: "Freight Service Provider",
          route: "/B2BManagment/data-management/freight-service-provider",
          //actions: permissionKeysObject.freightOpportunities,
        },
      ],
    },
    {
      id: "accountManagement",
      target: "#accountManagement",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "Account MANAGEMENT",
      route: "/B2BManagment/account-Management",
      children: [],
    },

    {
      id: "BuyRequestMangement",
      target: "#BuyRequestMangement",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "Buy-Request-Mangement",
      route: "/B2BManagment/Buy-Request-Mangement",
      children: [],
    },

    {
      id: "OfflineManagement",
      target: "#OfflineManagement",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "Off line Management",
      route: "/B2BManagment/Off-line-Management",
      children: [],
    },
    {
      id: "ProductManagement",
      target: "#ProductManagement",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "Product Management",
      route: "/B2BManagment/Product-Management",
      children: [],
    },
    {
      id: "ServiceManagement",
      target: "#ServiceManagement",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "Service Management",
      route: "/B2BManagment/Service-Management",
      children: [],
    },
    // {
    //   id: "BankAcountManagement",
    //   target: "#BankAcountManagement",
    //   icon: "dollar",
    //   toggle: false,
    //   active: false,
    //   parentName: "BankAcount Management",
    //   route: "/B2BManagment/BankAcount-Management",
    //   children: [],
    // },
    {
      id: "FeaturingConfirmedManagement",
      target: "#FeaturingConfirmedManagement",
      icon: "dollar",
      toggle: false,
      active: false,
      parentName: "Featuring Confirmed Management",
      route: "/B2BManagment/Featuring-Confirmed-Management",
      children: [],
    },
  ];

  display: any[];
  sideNaveItems: any = [];
  user: any;
  private _permissionService: any;
  fullName: string;
  emailUser: any;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.getActiveRoute();
  }

  ngOnInit(): void {
    this.display = this.sidenavDynamiqueContent;
    this.user = this.userService.currentUser;

    if (
      this.userService.currentUser &&
      this.userService.currentUser.role.indexOf("ROLE_SUB_ADMIN") !== -1
    ) {
      const permissions = this._permissionService.permissions;

      this.applyPermissionAsideBar(permissions);
      this.applyChildPermissionAsideBar(permissions);
    }

    this.sideNaveItems = this.sidenavDynamiqueContent;
    this.hasActiveRoute();
  }

  applyPermissionAsideBar(permissions: Permission[]) {
    this.display.map((sideNav) => {
      const deletedAsideMenu = permissions.find(
        (perm) =>
          (perm.mode === Mode.EDIT &&
            perm.actions.length === 0 &&
            sideNav.parentName.toLowerCase() === perm.name.toLowerCase()) ||
          (perm.mode === Mode.NONE &&
            perm.name.toLowerCase() === sideNav.parentName.toLowerCase())
      );

      if (deletedAsideMenu) {
        this.sidenavDynamiqueContent = this.sidenavDynamiqueContent.filter(
          (nav) => nav.parentName !== sideNav.parentName
        );
      }
    });
  }

  applyChildPermissionAsideBar(permissions: Permission[]) {
    this.display.map((sideNav) => {
      const editPermission = permissions.find(
        (perm) =>
          sideNav.parentName.toLowerCase() !== "dashboard" &&
          perm.name.toLowerCase() === sideNav.parentName.toLowerCase()
      );
      if (editPermission && editPermission.mode === Mode.EDIT)
        sideNav.children = sideNav.children.filter((child) =>
          child.actions.some((action) =>
            editPermission.actions.includes(action)
          )
        );
    });
  }

  setactivateRoute(item) {
    this.sideNaveItems.forEach((el) => {
      el.active = false;
      el.toggle = false;
    });

    item.active = true;
    item.toggle = true;
  }

  hasActiveRoute() {
    this.sideNaveItems.forEach((item: any) => {
      item.active = false;
      let routeChlids = [];

      if (item.route == this.currentRoute) {
        item.active = true;
      }

      if (item.children.length != 0) {
        routeChlids = item.children.map((el) => el.route);

        if (routeChlids.includes(this.currentRoute)) {
          item.active = true;
          item.toggle = true;
        }
      }
    });
  }

  private getActiveRoute() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
  getUser() {
    let id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log(res);
        this.fullName = res.firstName + " " + res.lastName;
        this.emailUser = res.email;
      });
    }
  }
}
