import { Component, OnInit ,Host, Input, Output, EventEmitter} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FinanceService } from 'src/app/commons/services/access-to-finance/finance.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  size:number;
  @Input() countries : any[];
  @Input() financesTypes : any[];
  @Input() typeOfInstitutions:any[];
  @Input() financeProviders:any[];

  @Output() valueChange = new EventEmitter<{country: any, financesType:any,typeOfInstitution:any,financeProvider:any}>();
  country:any=""
  financesType: any =""
  typeOfInstitution: any =""
  financeProvider: any =""


  constructor( 
) {
 
   }
  
  ngOnInit() {
 
  }

  valueChanged() {
    
    this.valueChange.emit({ country: this.country, financesType:
       this.financesType, typeOfInstitution: this.typeOfInstitution,
       financeProvider:this.financeProvider});

  }
}
