import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.baseUrl+'/subscriptions';

  constructor(private http: HttpClient) { }

  setBusinessProfile(data){
    return this.http.put(this.baseUrl, data);
  }
  getOwnerShipType(){
    return this.http.get(this.baseUrl+"/ownerShipType")
  }
  getByCompanyName(body:any){
    return this.http.post(this.baseUrl+'/getBuyers',body );
  }
  findSellerCompanyByName(body:any){
    return this.http.post(this.baseUrl+'/getSellers',body );
  }
  uploadFile(id,file){
    return this.http.post(this.baseUrl+"/"+id+"/upload",file);
  }


  getAllProduct(id){
    return this.http.get(this.baseUrl+"/myProductService/"+id);
  }

  addMainProductService(id,data){
    return this.http.put(this.baseUrl+"/addMainMyProductService/"+id,data);
  }
  addOtherProductService(id,data){
    return this.http.put(this.baseUrl+"/addOtherMyProductService/"+id,data);
  }
  deleteOtherProductService(id,data){
    return this.http.put(this.baseUrl+"/removeOtherMyProductService/"+id,data);
  }

  buyerMatching(body:any){
    return this.http.post(this.baseUrl+'/matchingBuyer',body );
  }
  sellerMatching(body:any){
    return this.http.post(this.baseUrl+'/matchingSeller',body );
  }
}
