export enum KeyFeature {

  // FOR_BUYERS = "FOR BUYERS",
  POST_BUY_REQUEST = "POST BUY REQUEST",
  REQUEST_FREIGHT_QUOTE = "REQUEST FREIGHT QUOTE",
  SOURCE_FOR_SUPPLIERS_MANUFACTURES = "SOURCE FOR SUPPLIERS/MANUFACTURES",
  SOURCE_FOR_FREIGHT_SERVICES_PROVIDERS = "SOURCE FOR FREIGHT SERVICES PROVIDERS",
  ACCESS_TO_FINANCE = "ACCESS TO FINANCE",

  // FOR_SUPPLIERS = "FOR SUPPLIERS",
  SHOWCASE_YOUR_PRODUCT_SERVICE = "SHOWCASE YOUR PRODUCT SERVICE",
  SEARCH_BUYING_OFFERS = "SEARCH BUYING OFFERS",
  FIND_FREIGHT_REQUESTS = "FIND_FREIGHT_REQUESTS",
  SOURCE_FOR_IMPORTERS_BUYERS = "SOURCE_FOR_IMPORTERS_BUYERS",
  FIND_FREIGHT_QUOTE = "FIND_FREIGHT_QUOTE",
  REQUEST_FREIGHT_QUOTES = "REQUEST_FREIGHT_QUOTES",
  INTRODUCE_PREFERRED_TRADE_AND_FINANCING_PARTNERS = "INTRODUCE PREFERRED TRADE AND FINANCING PARTNERS",

  // MATCHING_SERVICE = "MATCHING SERVICE",
  MATCHING_PROFILER = "MATCHING PROFILER",

  // TRADE_SHOWS = "TRADE SHOWS",
  SEARCH_FOR_TRADE_EXHIBITIONS = "SEARCH FOR TRADE EXHIBITIONS",
  SUBMIT_YOUR_EVENT = "SUBMIT_YOUR_EVENT",
}
