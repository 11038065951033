import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Payment, Plan } from 'src/app/modules/subscription/components/stripe-form/strip/strip.component';
import { PricingPlan, Registration } from 'src/app/modules/subscription/pages/business/business.component';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  public initialPrice: number = null;

  private registrationState$: BehaviorSubject<Registration> = new BehaviorSubject<Registration>(null);
  private offlinePaymentState$: BehaviorSubject<Payment> = new BehaviorSubject<Payment>(null);
  private planState$: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(null);
  private selectedPlanState$: BehaviorSubject<PricingPlan> = new BehaviorSubject<PricingPlan>(null);
  private closeAttribut = new BehaviorSubject(null);
  currentcloseAttribut = this.closeAttribut.asObservable();

  // getters
  public get businessRegistration(): Registration {
    return this.registrationState$.value;
  }

  public get offlinePayment(): Payment {
    return this.offlinePaymentState$.value;
  }

  public get plan(): Plan {
    return this.planState$.value;
  }

  public get selectedPlan(): PricingPlan {
    return this.selectedPlanState$.value;
  }

  // setters
  public set businessRegistration(businessRegistration: Registration) {
    this.registrationState$.next(businessRegistration);
  }

  public set offlinePayment(offlinePayment: Payment) {
    this.offlinePaymentState$.next(offlinePayment);
  }

  public set plan(plan: Plan) {
    this.planState$.next(plan);
  }

  public set selectedPlan(plan: PricingPlan) {
    this.selectedPlanState$.next(plan);
  }

 close(close: any) {
    this.closeAttribut.next(close);
  }
}
