import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { BuyRequestService } from 'src/app/commons/services/service buy-request/buy-request.service';
import { SharedService } from 'src/app/commons/shared service/shared-service';

@Component({
  selector: "app-latest-buy-leads",
  templateUrl: "./latest-buy-leads.component.html",
  styleUrls: ["./latest-buy-leads.component.css"]
})
export class LatestBuyLeadsComponent implements OnInit {

  public loading=false;
  public listData=[];
  public data:any=[];
  public styles: Partial<CSSStyleDeclaration> = {
    width: '50%',
    margin: 'auto',
    height: '100%',
  };
  constructor(private router: Router,private msg: NzMessageService,private sharedService: SharedService,private buyRequestService: BuyRequestService) {
  }
  ngOnInit() {
    this.getSharedData();
  }
  getSharedData(){
    this.sharedService.sharedSearchBuyLeads.subscribe((resp: any) => {
      this.data=resp;

      console.log(this.data);
      this.searchPostRequest(resp);
    });
  }
  searchPostRequest(data){
    this.loading=true;
    this.buyRequestService.postSearch(data).subscribe((resp: any) => {
      this.listData=resp
      // let sortedActivities = this.listData.sort((a, b) => b.creationDate - a.creationDate)
      // console.log("postSearch",this.listData);
      // console.log("sortedActivities",sortedActivities);

      this.loading = false;
    },
    (err) => {
      this.loading = false;
      console.log(err);
      this.msg.error('failed search request ');
    })
  }


  getByID(id){
    this.sharedService.nextSearchProductByID(id);
    this.router.navigate(['/buy-request/product']);
  //   this.buyRequestService.getProductsById(id).subscribe((resp: any) => {
  //     console.log(resp);
  //   },
  //   (err) => {
  //     console.log(err);
  //   })
   }

   checkIsNull(){
     if(this.data.product == ""){
      this.data.product = null
     }

    if(this.data.product === null && this.data.country=== null && this.data.period=== null){
      return true
    }
    else{
      return false;
    }
   }
}
