import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthProvider } from "src/app/commons/services/auth";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";
import { UserService } from "src/app/commons/services/user.service";
import { WebSocketService } from "src/app/commons/services/websocket-service/web-socket.service";
import { SharedService } from "src/app/commons/shared service/shared-service";

@Component({
  selector: "app-slide-b2b",
  templateUrl: "./slide-b2b.component.html",
  styleUrls: ["./slide-b2b.component.css"],
})
export class SlideB2bComponent implements OnInit {
  public testFalse = false;
  selectedSearch = "products";
  motCle: string = null;
  validateForm!: FormGroup;
  validateEmailForm!: FormGroup;
  public role = "";
  public listCatgs = [];
  public formSearch = new FormGroup({});
  isLoggedIn: boolean;
  isvisible = false;
  public firstName = "";
  public lastName = "";
  public country = "";
  public user;
  public visiblecontentTemplate = false;
  public visiblecontentCheck = false;
  public isSubs = false;
  public isForgot = false;
  public isMember = false;

  constructor(
    private route: Router,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private userService: UserService,
    private b2bService: B2bProductService,
    private webSocketService: WebSocketService,
    private auth: AuthProvider,
    private toaster: ToastrService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getUser();
    this.getAllCatgs();
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.validateEmailForm = this.fb.group({
      email: [null, [Validators.required]],
    });
    this.isLoggedIn = this.auth.isLoggedIn();
  }
  signOut(): void {
    if (this.user) {
      this.webSocketService._disconnect(this.user.email);
    }
    this.auth.signOut();
    this.isLoggedIn = this.auth.isLoggedIn();
    this.router.navigate(["/"]);
    this.role = null;
  }
  checkMember() {
    this.isMember = true;
  }
  UncheckMember() {
    this.isMember = false;
  }
  change(value: boolean): void {
    console.log(value);
  }
  getAllCatgs() {
    this.b2bService.getAllCatgs().subscribe((res: any) => {
      this.listCatgs = res;
      // this.listCatgs = this.listCatgs.sort(() => 0.10 - Math.random());
    });
  }
  submitForm(): void {
    this.visiblecontentTemplate = false;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();

      if (this.validateForm.valid) {
        const self = this;

        this.userService
          .login(this.validateForm.value)
          .subscribe((res: any) => {
            const token = "token";
            const role = "role";
            const id = "id";
            this.firstName = res.firstName;
            this.lastName = res.lastName;
            this.country = res.country;
            this.role = res.role;
            this.auth.signIn(res[id], res[token], res[role], res.refreshToken);
            this.isLoggedIn = this.auth.isLoggedIn();
            this.router.navigate(["/"]);
            console.log("admoooooon", res);
            // location.reload();

            this.validateForm.reset();
            // if (res && res[token]) {
            //   this.auth.signIn(res[id],res[token],res[role]);
            //   if (res[role] =="ROLE_ADMIN") {
            //     this.router.navigate(['/dashboard']);
            //   } else if (res[role] == "ROLE_USER"){
            //     this.router.navigate(['/accesstofinance']);
            //     }}
          });
      }
    }
  }
  submitForgottenPassword() {
    for (const i in this.validateEmailForm.controls) {
      this.validateEmailForm.controls[i].markAsDirty();
      this.validateEmailForm.controls[i].updateValueAndValidity();
    }

    if (this.validateEmailForm.valid) {
      this.userService
        .forgotPassword(this.validateEmailForm.value)
        .subscribe((res) => {
          console.log("response : ", res);

          const message = "A new password has been sent to your email";
          this.toaster.success(message, "Success", {
            closeButton: true,
            timeOut: 10000,
          });
        });
    }
  }
  showForgot() {
    this.isForgot = !this.isForgot;
  }
  createForm() {
    this.formSearch = this.fb.group({
      type: [""],
      name: [""],
    });
  }
  getUser() {
    const id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getAuthUser(id).subscribe((res: any) => {
        console.log("Usssssser", res);
        this.role = res.role;
      });
    }
  }
  goToSubscription(): void {
    this.visiblecontentTemplate = false;

    this.subscriptionService
      .checkTipUser(this.validateForm.value)
      .subscribe((res) => {
        this.sharedService.nextUser(res);
        this.router.navigate(["/subscription/business"]);
      });
  }
  goToEmptySubscription(): void {
    this.visiblecontentCheck = false;
    this.sharedService.nextUser(null);
    this.router.navigate(["/subscription/business"]);
  }

  search() {
    if (this.motCle !== null && this.motCle.length > 0) {
      if (this.selectedSearch === "products") {
        console.log("selectedSearch", this.selectedSearch);
        console.log("motCle", this.motCle);
        this.sharedService.nextMessage(this.motCle);
        this.route.navigateByUrl("/search-product");
      } else if (this.selectedSearch === "Companies") {
        this.sharedService.nextMessage(this.motCle);
        this.route.navigateByUrl("/search-company");
      } else if (this.selectedSearch === "services") {
        this.sharedService.nextMessage(this.motCle);
        this.route.navigateByUrl("/search-service");
      }
    }
  }
}
