import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-buy-leads',
  templateUrl: './search-buy-leads.component.html',
  styleUrls: ['./search-buy-leads.component.css']
})
export class SearchBuyLeadsComponent implements OnInit {
  @Input() countries : any[];
  @Input() financesTypes : any[];
  @Input() typeOfInstitutions:any[];
  @Input() financeProviders:any[];
  
  @Output() valueChange = new EventEmitter<{country: any, financesType:any,typeOfInstitution:any,financeProvider:any}>();
  country:any=""
  financesType: any =""
  typeOfInstitution: any =""
  financeProvider: any =""
  formSearch: FormGroup;
  
  constructor( private fb: FormBuilder) { }

  ngOnInit() {
    //  this.createForm();
    // this.getCountries();
    // this.getAllPeriod();
    // this.search();
  }
  createForm() {
    this.formSearch = this.fb.group({  
      country: [''],
      financesType: [''] , 
      typeOfInstitution: [''] ,
      financeProvider: ['']  
    });
  }

  // search(){
  //   let data={
  //     product:this.motCle,
  //     country:this.country,
  //     period:this.month
  //   }
  //   this.sharedService.nextSearchBuyLeads(data);
  // }

  // getAllafricanCountries(){
  //   this.buyRequestService.getafricanCountries().subscribe((resp: any) => {
  //     this.listafricanCountries=resp
  //   })
  // }
  // getCountries(){
  //   this.buyRequestService.getCountries().subscribe((resp: any) => {
  //     this.listafricanCountries=resp
  //     console.log(resp); 
  //   })
  // }
  // getAllPeriod(){
  //   this.buyRequestService.getperiod().subscribe((resp: any) => {
  //     this.listPeriod=resp
  //     console.log(this.listPeriod);
      
  //   })
  // }
  valueChanged() {
    
    this.valueChange.emit({ country: this.country, financesType:
       this.financesType, typeOfInstitution: this.typeOfInstitution,
       financeProvider:this.financeProvider});

  }
}
