import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BankAccountService } from "src/app/commons/services/bankacount-service/bank-account.service";
import { AdminService } from "src/app/commons/services/dashboard-admin/admin.service";
import {
  PdfService,
  PdfTemplates,
} from "src/app/modules/pdf/services/pdf.service";
import { PayementService } from "../../../../../commons/services/stripe-payement/payement.service";
import {
  PricingPlan,
  Registration,
} from "../../../pages/business/business.component";
// import {InvoiceService} from '../../../services/invoice.service';
import { Subject } from "rxjs";
import { InvoiceService } from "src/app/modules/services/invoice.service/invoice.service";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";

export interface Payment {
  email: string;
  pricingPlanId: number;
  bankId?: number;
  planFrequancy: string;
  currency: string;
  planningKey: string;
  cost: number;
  bankName?: string;
  evoucher?: string;
  selectedPlan?: PricingPlan;
}

export interface Plan {
  id: number;
  isActive: boolean;
  planAccess: string;
  keyFeaturs: any[];
  keyFeatursAnnualy?: any[];
  usageLicence: number;
  monthly_price: number;
  monthly_isActive: boolean;
  annual_price: number;
  annual_isActive: boolean;
}

@Component({
  selector: "app-strip",
  templateUrl: "./strip.component.html",
  styleUrls: ["./strip.component.css"],
})
export class StripComponent implements OnInit, AfterViewInit {
  message = "";
  myForm: FormGroup;
  Checkout: any;
  chekcoutSessionId: any;
  @Input()
  cost: any;

  @Input()
  token: any;

  @Input()
  email: any;

  @Input()
  id: any;

  @Input()
  planFrequancy: any;

  @Input()
  evoucherMode: boolean;

  @Input()
  freeMode: "evoucher" | "discount" | null;

  @Input()
  submitSubject: Subject<any>;

  @Input()
  returnUrl: any;

  @Input()
  subscription: any;

  lightbox: ElementRef<any>;
  private chekoutSessionResultIndicator: any;
  private discountCodes: any[] = [];

  @ViewChild("lightbox", { read: ElementRef, static: false }) set content(
    content: ElementRef
  ) {
    if (content) {
      // initially setter gets called with undefined
      this.lightbox = content;
    }
  }

  evoucherCode = "";

  modePayement: any = "online";

  modePayementOffline: any = "stripe";
  order = "";

  handleRequest: any = {};

  paymentsOffline: any = [];

  offlinePaymentHandelRequest: any = {};
  onlinePaymentHandelRequest: any = {};
  EvoucherPaymentHandelRequest: any = {};

  DiscountPaymentHandleRequest: any = {};
  evoucherError: string = null;
  isValidatingEvoucher: boolean = false;
  bankId: any = null;
  private payementService: PayementService;

  constructor(
    private fb: FormBuilder,
    payementService: PayementService,
    private banckAcountService: BankAccountService,
    private subscriptionService: SubscriptionService,
    private zone: NgZone,
    private toaster: ToastrService,
    private pdfService: PdfService,
    private invoiceService: InvoiceService,
    private admin: AdminService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.payementService = payementService;
    this.myForm = this.fb.group({
      cardNumber: ["", [Validators.required]],
      cvc: ["", [Validators.required]],
      expMonth: ["", [Validators.required]],
      expYear: ["", [Validators.required]],
      expDay: ["", [Validators.required]],
      holderName: ["", [Validators.required]],
    });
  }

  scripts: any;
  resultIndicator: any;
  sessionVersion: any;
  isLoadingOne: any;

  changeModePayementOnline(value) {
    if (value == "cib") {
      this.loadMpgs();
    } else if (value == "stripe") this.loadStripe();
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.resultIndicator = params.resultIndicator;
      this.sessionVersion = params.sessionVersion;
    });
    if (this.resultIndicator != null) {
      this.completeCallback(this.resultIndicator, this.sessionVersion);
    }
    this.submitSubject.subscribe((res) => {
      this.isLoadingOne = true;
      this.loadMpgs();
    });
    this.getAllOfflinePaymentUsers();
    /* this.loadMpgs();*/
  }

  cilcked() {
    console.log("master payemnet clicked");
    this.mastercardPay();
  }

  mastercardPay() {
    this.payementService
      .masterdcardPay(this.order, this.handleRequest)
      .subscribe((data: any) => {
        const message = "Payement Completed";
        this.toaster.success(message, "Success", {
          closeButton: true,
          timeOut: 10000,
        });
      });
  }

  loadMpgs() {
    if (this.resultIndicator == null) {
      this.handleRequest.pricingPlanId = this.invoiceService.selectedPlan.id;
      this.handleRequest.cost = this.invoiceService.selectedPlan.cost;
      this.handleRequest.planFrequancy =
        this.invoiceService.selectedPlan.frequency;

      this.handleRequest.email = this.invoiceService.businessRegistration.email;
      this.handleRequest.currency = "USD";
      //
      this.order = "order" + Math.random().toString(36).substring(4);
      localStorage.removeItem("order");
      localStorage.setItem("order", this.order);
      localStorage.removeItem("handleRequest");
      localStorage.setItem("handleRequest", JSON.stringify(this.handleRequest));

      console.log(
        "this.invoiceService.selectedPlan.cost ::>",
        this.invoiceService.selectedPlan.cost
      );
      this.payementService
        .getCheckoutSessionId(
          this.order,
          this.invoiceService.selectedPlan.cost,
          this.returnUrl
        )
        .subscribe((data: any) => {
          this.chekcoutSessionId = data.session.id;
          this.chekoutSessionResultIndicator = data.successIndicator;
          localStorage.setItem(
            "chekoutSessionResultIndicator",
            this.chekoutSessionResultIndicator
          );
          console.log(this.chekcoutSessionId, data);
          (<any>window).Checkout.configure({
            session: {
              id: data.session.id,
            },
            interaction: {
              merchant: {
                name: "Your merchant name",
                address: {
                  line1: "200 Sample St",
                  line2: "1234 Example Town",
                },
              },
            },
          });
        });
      this.isLoadingOne = false;

      /*  function completeCallback(resultIndicator, sessionVersion) {
                  //handle payment completion
                  console.log("payment completed");

              }*/
      // function showLightBox() {
      //     console.log("light box");
      //     window['Checkout'].showLightbox();

      // }

      // function showPaymenetPage() {
      //     console.log("payment page");
      //     (<any>window).Checkout.showPaymentPage();
      // }

      //document.getElementById("lightbox").addEventListener("click", showLightBox);
      // document.getElementById("paypage").addEventListener("click",this.mastercardPay.bind(this.order,this.handleRequest));
    }
  }

  completeCallback = async (resultIndicator, sessionVersion) => {
    this.chekoutSessionResultIndicator = localStorage.getItem(
      "chekoutSessionResultIndicator"
    );
    this.checkDownloadPermission = JSON.parse(
      localStorage.getItem("checkDownloadPermission")
    );
    if (resultIndicator == this.chekoutSessionResultIndicator) {
      const message = "The registration has been completed.";
      this.toaster.success(message, "Success", {
        closeButton: true,
        timeOut: 10000,
      });
      this.onlinePaymentHandelRequest = JSON.parse(
        localStorage.getItem("onlinePaymentHandelRequest")
      );
      console.log(
        "this.EvoucherPaymentHandelRequest =",
        localStorage.getItem("onlinePaymentHandelRequest")
      );
      await this.masterCardPayment();
    }
  };

  showLightBox() {
    this.onlinePaymentHandelRequest.email = this.email;
    this.onlinePaymentHandelRequest.cost = this.cost;
    //this.onlinePaymentHandelRequest.bankId = this.bankId;
    this.onlinePaymentHandelRequest.pricingPlanId = this.id;
    this.onlinePaymentHandelRequest.planFrequancy = this.planFrequancy;
    this.onlinePaymentHandelRequest.currency = "USD";
    this.EvoucherPaymentHandelRequest.bankId = this.bankId;
    this.onlinePaymentHandelRequest.planningKey = "A233EZEZ3";

    localStorage.setItem(
      "onlinePaymentHandelRequest",
      JSON.stringify(this.onlinePaymentHandelRequest)
    );
    localStorage.setItem(
      "businessRegistration",
      JSON.stringify(this.invoiceService.businessRegistration)
    );
    localStorage.setItem(
      "selectedPlanPromotions",
      JSON.stringify(this.invoiceService.selectedPlan.promotions)
    );
    console.log(
      "this.EvoucherPaymentHandelRequest =",
      localStorage.getItem("onlinePaymentHandelRequest")
    );
    console.log("light box", (<any>window).Checkout);
    window["Checkout"].showLightbox();
  }

  errorCallback = (error) => {
    console.log(JSON.stringify(error));
  };

  cancelCallback = () => {
    this.isLoadingOne = false;
  };
  checkDownloadPermission: any = false;

  loadStripe() {
    if (!window.document.getElementById("stripe-custom-form-script")) {
      let s: any = {};
      s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(
          "pk_test_prNuFvWseS1Gy3RMTbbxHg8R00kQV5honL"
        );
      };
      window.document.body.appendChild(s);
    }
  }

  pay() {
    console.log(this.myForm.value);
    (<any>window).Stripe.card.createToken(
      {
        number: this.myForm.value.cardNumber,
        exp_month: this.myForm.value.expMonth,
        exp_year: this.myForm.value.expYear,
        cvc: this.myForm.value.cvc,
      },
      (status: number, response: any) => {
        if (status === 200) {
          this.handleRequest.cost = this.cost;
          this.handleRequest.request = 1;
          console.log(this.email);
          this.handleRequest.email = this.email;
          this.handleRequest.planningKey = "A233EZEZ3";
          this.handleRequest.currency = "EUR";
          this.handleRequest.token = response.id;
          console.log("pricing plan id");
          this.handleRequest.pricingPlanId = this.id;
          console.log("ssssssssss");
          console.log(this.token);
          this.payementService
            .charge(this.handleRequest, this.token)
            .subscribe((data) => {
              console.log(data);
              const message = "Payement Completed";
              this.toaster.success(message, "Success", {
                closeButton: true,
                timeOut: 10000,
              });
            });
          console.log(this.message);
        } else {
        }
      }
    );
  }

  getAllOfflinePaymentUsers() {
    this.banckAcountService.getOfflinePayment().subscribe((data) => {
      this.paymentsOffline = data;
    });
  }

  changeModePayement(value) {
    this.modePayement = value;
    /*if (value == "evoucher")
           this.freeMode="evoucher"*/
  }

  async OfflinePayement() {
    const discountCodes: string[] = [];
    const bankName: string = this.paymentsOffline.find(
      (bank) => bank.id == this.bankId
    ).name;
    const selectedPlan = this.invoiceService.selectedPlan;
    console.log("selectedPlan", selectedPlan);
    this.offlinePaymentHandelRequest.email = this.email;
    this.offlinePaymentHandelRequest.cost = this.cost;
    this.offlinePaymentHandelRequest.pricingPlanId = this.id;
    this.offlinePaymentHandelRequest.bankId = this.bankId;
    this.offlinePaymentHandelRequest.planFrequancy = this.planFrequancy;
    this.offlinePaymentHandelRequest.currency = "USD";
    this.offlinePaymentHandelRequest.planningKey = "A233EZEZ3";

    this.invoiceService.selectedPlan.promotions.forEach((promotion) => {
      discountCodes.push(promotion.code);
    });

    this.offlinePaymentHandelRequest.discountCodes = discountCodes;

    this.invoiceService.plan = (await this.admin
      .getById(this.id)
      .toPromise()) as Plan;
    this.invoiceService.offlinePayment = {
      selectedPlan,
      bankName,
      ...this.offlinePaymentHandelRequest,
    } as Payment;

    // send the file with the request
    const { file, reference } = await this.pdfService.downloadPdf(
      PdfTemplates.OFFLINE,
      this.checkDownloadPermission
    );

    this.offlinePaymentHandelRequest.reference = reference;

    this.subscriptionService
      .create(this.subscription)
      .subscribe((data: any) => {
        this.token = data.token;
        this.banckAcountService
        .offlinePayment(file, this.offlinePaymentHandelRequest, this.token)
        .subscribe((data: any[]) => {
          console.log(data);
          const message =
            "Please check you mail account to complete the registration process\n";
          this.toaster.success(message, "Success", {
            closeButton: true,
            timeOut: 10000,
          });

          if (this.router.url.includes("/dashboardUser")) {
            let close = true;
            this.invoiceService.close(close);
          } else {
            this.router.navigate([""]);
          }
        });
      });
  }

  configurePaymenetSession() {
    (<any>window).PaymentSession.configure({
      fields: {
        // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
        card: {
          number: "#card-number",
          securityCode: "#security-code",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year",
          nameOnCard: "#cardholder-name",
        },
      },
      //SPECIFY YOUR MITIGATION OPTION HERE
      frameEmbeddingMitigation: ["x-frame-options"],
      callbacks: {
        initialized: (response) => {
          // HANDLE INITIALIZATION RESPONSE
        },
        formSessionUpdate: (response) => {
          // HANDLE RESPONSE FOR UPDATE SESSION
          if (response.status) {
            if ("ok" == response.status) {
              console.log("Session updated with data: " + response.session.id);

              //check if the security code was provided by the user
              if (response.sourceOfFunds.provided.card.securityCode) {
                console.log("Security code was provided.");
              }

              //check if the user entered a Mastercard credit card
              if (response.sourceOfFunds.provided.card.scheme == "MASTERCARD") {
                console.log("The user entered a Mastercard credit card.");
              }
            } else if ("fields_in_error" == response.status) {
              console.log("Session update failed with field errors.");
              if (response.errors.cardNumber) {
                console.log("Card number invalid or missing.");
              }
              if (response.errors.expiryYear) {
                console.log("Expiry year invalid or missing.");
              }
              if (response.errors.expiryMonth) {
                console.log("Expiry month invalid or missing.");
              }
              if (response.errors.securityCode) {
                console.log("Security code invalid.");
              }
            } else if ("request_timeout" == response.status) {
              console.log(
                "Session update failed with request timeout: " +
                  response.errors.message
              );
            } else if ("system_error" == response.status) {
              console.log(
                "Session update failed with system error: " +
                  response.errors.message
              );
            }
          } else {
            console.log("Session update failed: " + response);
          }
        },
      },
      interaction: {
        displayControl: {
          formatCard: "EMBOSSED",
          invalidFieldCharacters: "REJECT",
        },
      },
    });
  }

  mastercardpay() {
    // UPDATE THE SESSION WITH THE INPUT FROM HOSTED FIELDS
    console.log("hited");
    (<any>window).PaymentSession.updateSessionFromForm("card");
  }

  onDomChange() {
    this.zone.runOutsideAngular(() => {
      MutationObserver =
        window["MutationObserver"] || window["WebKitMutationObserver"];

      var observer = new MutationObserver(function (mutations, observer) {
        // fired when a mutation occurs
        console.log("mutations", mutations, observer);
        let iframe: any = document.querySelectorAll(
          "[title='Hosted Checkout']"
        );
        if (iframe.length != 0) {
          console.log("elele", iframe);
          if (
            !iframe[0].contentWindow.document.getElementsByClassName("close")
          ) {
            console.log("found it");
            for (
              let i = 0;
              i <
              iframe[0].contentWindow.document.getElementsByClassName("close")
                .length;
              i++
            ) {
              iframe[0].contentWindow.document
                .getElementsByClassName("close")
                [i].removeAttribute("style");
            }
          }
        }

        // ...
      });

      // define what element should be observed by the observer
      // and what types of mutations trigger the callback
      observer.observe(document, {
        subtree: true,
        attributes: true,
        childList: true,
        //...
      });
    });
  }

  async evoucherPayment(): Promise<void> {
    this.EvoucherPaymentHandelRequest.email = this.email;
    this.EvoucherPaymentHandelRequest.cost = this.cost;
    this.EvoucherPaymentHandelRequest.pricingPlanId = this.id;
    this.EvoucherPaymentHandelRequest.bankId = this.bankId;
    this.EvoucherPaymentHandelRequest.planFrequancy = this.planFrequancy;

    this.EvoucherPaymentHandelRequest.currency = "USD";
    this.EvoucherPaymentHandelRequest.planningKey = "A233EZEZ3";
    this.invoiceService.plan = (await this.admin
      .getById(this.id)
      .toPromise()) as Plan;
    this.invoiceService.offlinePayment = {
      evoucher: this.evoucherCode,
      ...this.EvoucherPaymentHandelRequest,
    } as Payment;

    const data = {
      email: this.email,
      evoucherCode: [this.evoucherCode],
      planFrequancy: this.planFrequancy,
      pricingPlanId: this.id,
    };

    this.EvoucherPaymentHandelRequest = data;

    // send the file with the request
    const { file, reference } = await this.pdfService.downloadPdf(
      PdfTemplates.EVOUCHER,
      this.checkDownloadPermission
    );

    this.subscriptionService
      .create(this.subscription)
      .subscribe((data: any) => {
        this.token = data.token;
        this.payementService
        .eVoucherPayement(file, this.EvoucherPaymentHandelRequest, this.token)
        .subscribe((res) => {
          const message =
            "Please check you mail account to complete the registration process\n";
          this.toaster.success(message, "Success", {
            closeButton: true,
            timeOut: 10000,
          });

          if (this.router.url.includes("/accesstofinance")) {
            let close = true;
            this.invoiceService.close(close);
          } else {
            this.router.navigate(["/"]);
          }
          // if (this.router.url.includes("/dashboardUser")) {
          //   let close = true;
          //   this.invoiceService.close(close);
          // } else {
          //   this.router.navigate([""]);
          // }
        });
      })
  }

  async masterCardPayment(): Promise<void> {
    // this.offlinePaymentHandelRequest.email = this.email;
    // this.offlinePaymentHandelRequest.cost = this.cost;
    // this.offlinePaymentHandelRequest.pricingPlanId = this.id;
    // this.offlinePaymentHandelRequest.bankId = this.bankId;
    // this.offlinePaymentHandelRequest.planFrequancy = this.planFrequancy;
    // this.offlinePaymentHandelRequest.currency = "USD";
    // this.offlinePaymentHandelRequest.planningKey = "A233EZEZ3";
    // localStorage.setItem("EvoucherPaymentHandelRequest",JSON.stringify(this.EvoucherPaymentHandelRequest))
    let discountCodes = [];
    const selectedPlan: Partial<PricingPlan> = {
      id: this.onlinePaymentHandelRequest.id,
      cost: this.onlinePaymentHandelRequest.cost,
      frequency: this.onlinePaymentHandelRequest.planFrequency,
    };
    this.onlinePaymentHandelRequest.selectedPlan = selectedPlan;
    let promotions = JSON.parse(localStorage.getItem("selectedPlanPromotions"));
    if (promotions.length != 0) {
      promotions.forEach((promotion) => {
        discountCodes.push(promotion.code);
      });
    }

    this.onlinePaymentHandelRequest.discountCodes = discountCodes;
    this.invoiceService.selectedPlan = {
      ...selectedPlan,
      ...this.invoiceService.selectedPlan,
    };
    this.invoiceService.plan = (await this.admin
      .getById(this.onlinePaymentHandelRequest.pricingPlanId)
      .toPromise()) as Plan;
    this.invoiceService.offlinePayment = {
      evoucher: "code",
      ...this.onlinePaymentHandelRequest,
    } as Payment;
    // const data = {
    //     email: this.email,
    //     planFrequancy: this.planFrequancy,
    //     pricingPlanId: this.id,
    // }

    //this.EvoucherPaymentHandelRequest = data;
    if (this.invoiceService.businessRegistration == null)
      this.invoiceService.businessRegistration = JSON.parse(
        localStorage.getItem("businessRegistration")
      ) as Registration;
    // send the file with the request
    const { file, reference } = await this.pdfService.downloadPdf(
      PdfTemplates.ONLINE,
      this.checkDownloadPermission
    );
    this.onlinePaymentHandelRequest.reference = reference;

    this.subscriptionService
      .create(this.invoiceService.businessRegistration)
      .subscribe((data: any) => {
        this.token = data.token;
        this.payementService
        .onlinePayment(file, this.onlinePaymentHandelRequest, this.token)
        .subscribe((res) => {
          const message = "Congratulations your registration is completed";
          this.toaster.success(message, "Success", {
            closeButton: true,
            timeOut: 10000,
          });
          /*    if (this.router.url.includes('/dashboardUser')) {
                  let close = true;
                  this.invoiceService.close(close);
              } else {
                  setTimeout(()=>{
                      this.router.navigate(['']);
                  },3000)

              }*/
          // localStorage.clear()
        });
      });
  }

  async discountPayment() {
    const promotions: any[] = [];
    const selectedPlan = this.invoiceService.selectedPlan;

    this.DiscountPaymentHandleRequest.email = this.email;
    this.DiscountPaymentHandleRequest.cost = this.cost;
    this.DiscountPaymentHandleRequest.pricingPlanId = this.id;
    this.DiscountPaymentHandleRequest.bankId = this.bankId;
    this.DiscountPaymentHandleRequest.planFrequancy = this.planFrequancy;
    this.DiscountPaymentHandleRequest.currency = "USD";
    this.DiscountPaymentHandleRequest.planningKey = "A233EZEZ3";

    this.invoiceService.plan = (await this.admin
      .getById(this.id)
      .toPromise()) as Plan;
    this.invoiceService.offlinePayment = {
      selectedPlan,
      ...this.DiscountPaymentHandleRequest,
    } as Payment;

    this.invoiceService.selectedPlan.promotions.map((promo) => {
      promotions.push(promo.code);
    });

    const data = {
      email: this.email,
      evoucherCode: promotions,
      planFrequancy: this.planFrequancy,
      pricingPlanId: this.id,
    };

    this.DiscountPaymentHandleRequest = data;

    // send the file with the request
    const { file, reference } = await this.pdfService.downloadPdf(
      PdfTemplates.DISCOUNT,
      this.checkDownloadPermission
    );

    this.subscriptionService
      .create(this.subscription)
      .subscribe((data : any) => {
        this.token = data.token;
        this.payementService
        .discountpayment(file, this.DiscountPaymentHandleRequest, this.token)
        .subscribe((res) => {
          const message =
            "Please check you mail account to complete the registration process\n";
          this.toaster.success(message, "Success", {
            closeButton: true,
            timeOut: 10000,
          });
          if (this.router.url.includes("/dashboardUser")) {
            let close = true;
            this.invoiceService.close(close);
          } else {
            this.router.navigate([""]);
          }
        });
      });
  }

  validateEvoucher(event?: ClipboardEvent) {
    this.freeMode = null;
    this.EvoucherPaymentHandelRequest.email = this.email;

    this.EvoucherPaymentHandelRequest.cost = this.cost;

    this.EvoucherPaymentHandelRequest.pricingPlanId = this.id;

    this.EvoucherPaymentHandelRequest.planFrequancy = this.planFrequancy;

    const obj = {
      email: this.email,
      evoucherCode: event
        ? event.clipboardData.getData("text")
        : this.evoucherCode,
      planFrequancy: this.planFrequancy,
      pricingPlanId: this.id,
    };
    if (this.id) {
      this.isValidatingEvoucher = false;
      this.evoucherError = null;

      this.admin.validateEvoucher(obj, this.token).subscribe(
        (data: any) => {
          setTimeout(() => {
            const message = data.message;
            this.evoucherMode = true;
            this.freeMode = "evoucher";
            this.EvoucherPaymentHandelRequest.cost = 0;
            this.toaster.success(message, "Success", {
              closeButton: true,
              timeOut: 10000,
            });
            this.isValidatingEvoucher = false;
          }, 1000);
        },
        (error) => {
          setTimeout(() => {
            this.isValidatingEvoucher = false;
            this.evoucherError = error.error.message;
          }, 1000);
          if (this.router.url.includes("/dashboardUser")) {
            let close = true;
            this.invoiceService.close(close);
          } else {
            // this.router.navigate([""]);
            const message = "evoucher not valid";
            this.toaster.error(message, "error", {
              closeButton: false,
              timeOut: 1000,
            });
          }
        }
      );
    }
  }

  ngAfterViewInit(): void {}

  checkCHange($event: any) {
    localStorage.setItem(
      "checkDownloadPermission",
      this.checkDownloadPermission
    );
  }
}
