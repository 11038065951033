import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FreightService } from 'src/app/commons/services/service freight/freight.service';

@Component({
  selector: 'app-insurance-company-by-id',
  templateUrl: './insurance-company-by-id.component.html',
  styleUrls: ['./insurance-company-by-id.component.css']
})
export class InsuranceCompanyByIdComponent implements OnInit {

  public companyInfo:any;
  public id;
  public isVisible = false;
  effect = 'scrollx';
  constructor(private freightService: FreightService,
    private route : ActivatedRoute) { }


    ngOnInit() {
      this.route.params.subscribe(data => {
        this.id = data.id;
        this.getByID(this.id);
      });
    }
    scroll(el: HTMLElement) {
      el.scrollIntoView({behavior:"smooth"});
  }
handleOk(): void {
  console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  console.log('Button cancel clicked!');
  this.isVisible = false;
}
showModal(): void {
  this.isVisible = true;
}
    getByID(id){
      this.freightService.getInsuranceCompanyById(id).subscribe((resp: any) => {
        console.log(resp);
         this.companyInfo=resp;
      },
      (err) => {
        console.log(err);
      })
    }


}
